import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const GiftButtonContainer = styled.div<{ opened: boolean }>`
  display: flex;
  width: 100%;

  .change {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px;
    gap: 0px;

    label {
      font-size: ${(p) => p.theme.typography.text3};
    }

    .switchContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      flex: 1;
      min-width: 331px;
      padding: 16px 20px;
      max-width: 331px;

      & > div {
        flex: 1;
      }

      .arrow {
        display: none;
      }
    }

    .hint {
      padding: 16px 16px 16px 20px;
      flex: 2;
      border-left: 1px solid ${(p) => p.theme.colors.divider};
      font-size: ${(p) => p.theme.typography.subText5};
      color: ${(p) => p.theme.colors.text.secondary};
    }
  }

  ${deviceQuery.tabletM} {
    .change {
      flex-direction: column;

      & > div:first-child {
        max-width: unset;
      }
      .switchContainer {
        display: flex;
        align-items: center;

        .arrow {
          display: flex;
          transition: all 0.3s ease-in-out;
          flex: unset;
          transform: rotate(${(p) => (p.opened ? "180deg" : "0deg")});
        }
      }

      .hint {
        padding: ${(p) => (p.opened ? "16px 20px" : "0px 20px")};
        border-left: none;
        display: grid;
        border-top: ${(p) => (p.opened ? `1px solid ${p.theme.colors.divider}` : "none")};
        grid-template-rows: ${(p) => (p.opened ? 1 : 0)}fr;
        transition: all 0.3s ease-in-out;

        .expandable {
          transition: all 0.3s ease-in-out;
          overflow: hidden;
        }
      }
    }
  }
`;
