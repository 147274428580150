import { FC, useState } from "react";

import { useTranslation } from "react-i18next";

import ArrowDownSmall from "assets/svg/ArrowDownSmall";
import { ChatSearchMessageNavigationStyles } from "pages/public/chat/Components/Chat/ChatSearchMessageNavigation/styles";

interface Props {
  elementsLength: number;
  indexChanged: (index: number) => void;
}
export const ChatSearchMessageNavigation: FC<Props> = ({ elementsLength, indexChanged }) => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevIndex = () => {
    const prevIndex = currentIndex - 1;
    if (prevIndex < 0) return;
    setCurrentIndex(prevIndex);
    indexChanged(prevIndex);
  };

  const goToNextIndex = () => {
    const nextIndex = currentIndex + 1;
    if (nextIndex > elementsLength - 1) return;
    setCurrentIndex(nextIndex);
    indexChanged(nextIndex);
  };

  return (
    <ChatSearchMessageNavigationStyles>
      <div>
        {t("new_chat.searching_results")}:{" "}
        <span className="indexes">
          {currentIndex + 1} of {elementsLength}
        </span>
      </div>
      <div className="navigation_arrows">
        <div onClick={() => goToNextIndex()}>
          <ArrowDownSmall
            className={`arrow arrow_up ${currentIndex === elementsLength - 1 && "disabled"}`}
          />
        </div>
        <div onClick={() => goToPrevIndex()}>
          <ArrowDownSmall className={`arrow  ${currentIndex === 0 && "disabled"}`} />
        </div>
      </div>
    </ChatSearchMessageNavigationStyles>
  );
};
