import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const Btn = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;

  svg {
    fill: ${(p) => p.theme.colors.text.primary};
  }

  &:hover {
    svg {
      fill: ${(p) => p.theme.colors.green.primary};
    }
  }

  ${deviceQuery.laptopS} {
    margin: auto;
  }
`;
