import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const PatternContainer = styled.div<{
  profile?: boolean;
  mobile?: boolean;
  iconColor?: string;
  show: boolean;
}>`
  ${(p) =>
    !p.show &&
    css`
      display: none !important;
    `}
  z-index: ${(p) => (p.profile ? "0" : "-1")};
  display: grid;
  min-height: ${(p) => (p.profile ? "100vh" : "calc(65px * 12)")};
  min-width: ${(p) => (p.profile ? "1400px" : "780px")};
  width: ${(p) => (p.profile ? "calc(105vw - 10px)" : "780px")};
  grid-template: ${(p) =>
    p.profile ? '"a a a a a a a a a a a a a a a"' : '"a a a a a a a a a a a a"'};
  position: ${(p) => (p.profile ? "fixed" : "absolute")};
  top: ${(p) => (p.profile ? "-30px" : "0px")};
  color: ${(p) => p.iconColor};
  right: -30px;
  opacity: 0.5;

  .overlay {
    position: absolute;
    width: 110vw;
    height: 100vh;
    top: 0px;
    right: 0px;
  }

  ${deviceQuery.mobileM} {
    z-index: ${(p) => (p.profile ? "0" : p.mobile ? "0" : "-1")};
  }
`;

export const IconContainer = styled.div<{
  profile?: boolean;
  rotate: number;
  size: number;
  position: { left: number; top: number };
}>`
  aspect-ratio: 1 / 1;
  position: relative;

  svg,
  img {
    position: absolute;
    top: ${(p) => p.position.top}%;
    left: ${(p) => p.position.left}%;
    transform: rotate(${(p) => p.rotate}deg);
    width: ${(p) => p.size}%;
    height: ${(p) => p.size}%;
  }
`;
