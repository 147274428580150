import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { getLocalStorageItem, setLocalStorageItem } from "common/helpers/localStorage";

import { ROLES, STORAGES } from "constants/appConstants";
import { AUTH_ROUTES, ROUTES } from "constants/routes";

const FanPrivateRoute = () => {
  const location = useLocation();
  if (location?.search?.includes("feedId")) {
    setLocalStorageItem({
      key: STORAGES?.route,
      value: location?.pathname + location?.search,
    });
  }

  const token = getLocalStorageItem({ key: STORAGES.token });
  const accountType = getLocalStorageItem({ key: STORAGES.accountType });
  if (token && accountType === ROLES.fan) {
    return <Outlet />;
  }
  return <Navigate to={location?.search?.includes("feedId") ? AUTH_ROUTES.login : ROUTES.home} />;
};

export default FanPrivateRoute;
