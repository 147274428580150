import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DetailsContainer } from "./style";

import Chat from "assets/svg/Chat";
import Gift from "assets/svg/Gift";
import { getDaysTillNow } from "common/helpers/helper";
import Avatar from "Components/Avatar";
import Button from "Components/Button";
import { getUserId } from "store/slices/authSlice";
import { getPublicUser } from "store/slices/global/previewProfileSlice";

interface Props {
  mobile?: boolean;
  backgroundSelected?: boolean;
}

const Details: FC<Props> = ({ mobile = false, backgroundSelected }) => {
  const { t } = useTranslation();
  const user = useSelector(getPublicUser);
  const userId = useSelector(getUserId);

  return (
    <DetailsContainer mobile={mobile} gradientTheme={user.wallpaper?.background?.theme}>
      <div className="info">
        <Avatar
          role={user.role}
          border
          size={mobile ? "ml" : "lg"}
          src={user.pictureUrl_100_100}
          alt="user"
        />
      </div>
      <div className="userInfo">
        <div className="name">
          <p className="title">
            {(user.displayname && user.displayname !== "NA" && user.displayname) || user.username}
          </p>
          {user.lastLoginAt && (
            <p className="description">
              {getDaysTillNow({
                time: user.lastLoginAt,
              }) === 1
                ? t("common.today")
                : t("common.recently")}
            </p>
          )}
        </div>
        {user.aboutMe && (
          <div className="about">
            <p>{user.aboutMe}</p>
          </div>
        )}
        {userId !== user?._id && (
          <div className="actions">
            <Button round variant="primary">
              <div className="icon">
                <Chat />
              </div>
              <span>{t("common.message")}</span>
            </Button>
            <Button round variant="secondary">
              <div className="icon">
                <Gift />
              </div>
              <span>{t("fan.gifting.send_gift")}</span>
            </Button>
          </div>
        )}
      </div>
    </DetailsContainer>
  );
};

export default Details;
