const Notify = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.6097 13.6394V13.4194C3.64159 12.7696 3.84894 12.1407 4.2097 11.5994C4.80827 10.9514 5.21956 10.1529 5.39969 9.28937C5.39969 8.61937 5.3997 7.94937 5.4597 7.28937C5.7597 4.07937 8.9297 1.85938 12.0697 1.85938H12.1497C15.2797 1.85938 18.4597 4.07937 18.7697 7.28937C18.8297 7.95937 18.7697 8.62937 18.7697 9.28937C18.964 10.162 19.3888 10.9666 19.9997 11.6194C20.3629 12.1558 20.5706 12.7822 20.5997 13.4294V13.6394C20.6213 14.5119 20.3224 15.3622 19.7597 16.0294C19.0067 16.8158 17.9942 17.3025 16.9097 17.3994C13.7155 17.7419 10.4939 17.7419 7.2997 17.3994C6.21241 17.2901 5.20075 16.7931 4.44969 15.9994C3.8962 15.3386 3.59815 14.5012 3.6097 13.6394Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.67188 20.7109C10.18 21.3381 10.9133 21.7415 11.715 21.8349C12.5167 21.9284 13.3232 21.7044 13.9619 21.2109C14.1554 21.0666 14.3301 20.8987 14.4819 20.7109"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Notify;
