import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import OnboardingSlider from "./OnboardingSlider";

import { OnBoard } from "../style";

import { getLocalStorageItem } from "common/helpers/localStorage";
import OverLayLoader from "Components/OverlayLoader";
import { STORAGES, TEMPLATESTATUS } from "constants/appConstants";
import { getOnBoardingScreenByRole } from "services/adminServices";
import { getAllDefaultLandingPageAPI } from "services/axios";
import { getDefaultLandingPages } from "store/slices/admin/adminSlice";
import { getOnboarding } from "store/slices/admin/onboardingsSlice";
import { getToken } from "store/slices/authSlice";
import { getNftAddloader } from "store/slices/featureSlice";

const OnBoardingList = () => {
  const jwtToken = useSelector(getToken);
  const navigate = useNavigate();
  const isNftLoading = useSelector(getNftAddloader);
  const lRole = getLocalStorageItem({ key: STORAGES.accountType });
  const defaultLandingPages = useSelector(getDefaultLandingPages);
  const onboarding = useSelector(getOnboarding);

  useEffect(() => {
    getAllDefaultLandingPageAPI();
  }, [jwtToken]);

  useEffect(() => {
    const route = defaultLandingPages.find((item: any) => item.role === lRole)?.defaultURl;
    if (onboarding === null || onboarding.status === TEMPLATESTATUS[1].value) {
      route && navigate(`/${route}`, { replace: true });
    }
  }, [onboarding]);

  useEffect(() => {
    getOnBoardingScreenByRole();
  }, []);

  return (
    <OnBoard>
      {isNftLoading ? (
        <OverLayLoader isLoading={isNftLoading} />
      ) : onboarding?.pages.length ? (
        <OnboardingSlider slides={onboarding.pages} />
      ) : (
        <></>
      )}
    </OnBoard>
  );
};

export default OnBoardingList;
