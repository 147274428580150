import { Link, NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";
import { flexCenter, OutfitMedium } from "styles/mixins";

export const LogoutButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.colors.text.primary};
  border-left: 1px solid ${(p) => p.theme.colors.divider};
  gap: 12px;
  padding-left: 20px;

  &:hover {
    color: ${(p) => p.theme.colors.text.third};
  }
`;

export const NotificationLength = styled.span`
  position: absolute;
  display: inline-block;
  color: ${(p) => p.theme.colors.white.primary};
  top: 20px;
  left: 34px;
  font-size: 0.5rem;
  background: ${(p) => p.theme.colors.red.primary};
  border-radius: 10px;
  font-family: "Outfit-ExtraBold" !important;
  padding: 3px 5px 2px;
`;

export const HeaderComp = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 4px;
  max-height: 60px;
  position: sticky;
  top: 0;
  z-index: 20;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
  background: ${(p) => p.theme.colors.background.secondary};

  .content {
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1366px;
    gap: 8px;
  }

  ${deviceQuery.desktopS} {
    padding: 16px 20px;

    .content {
      padding: 0px;
      max-width: 1036px;
    }
  }

  ${deviceQuery.mobileSE} {
    flex-direction: column;
    max-height: unset;
    align-items: start;
  }
`;

export const HeaderHeading = styled.p`
  border-left: 1px solid ${(p) => p.theme.colors.divider};
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: ${(p) => p.theme.typography.subText5};
  padding-left: 20px;
  color: ${(p) => p.theme.colors.text.third};

  span {
    color: ${(p) => p.theme.colors.text.primary};
  }

  ${deviceQuery.laptopM} {
    display: none;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 20px;

  img {
    width: 133px;
    object-fit: contain;
  }

  ${deviceQuery.laptopM} {
    img {
      width: 104px;
      object-fit: contain;
    }

    .beta {
      border-radius: 4px;
      padding: 2px 4px;
      font-size: ${(p) => p.theme.typography.subText};
    }
  }

  ${deviceQuery.mobileS} {
    gap: 6px;
  }

  ${deviceQuery.mobileSE} {
    gap: 12px;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 20px;
  justify-content: flex-end;
  align-self: stretch;

  ${deviceQuery.desktopS} {
    .hide {
      display: none;
    }
  }
`;

export const NavItems = styled.nav`
  display: flex;
  gap: 20px;
`;

export const NavItem = styled(Link)`
  text-decoration: none;
  color: ${(p) => p.theme.colors.text.secondary};
  ${OutfitMedium};
  white-space: nowrap;
  padding: 1.8rem 1rem;

  &:hover {
    color: ${(p) => p.theme.colors.text.primary};
    background: ${(props) => props.theme.colors.dark.primary};
  }
`;

export const LinkStyled = styled(NavLink)`
  text-decoration: none;
  color: ${(p) => p.theme.colors.text.primary};
  ${OutfitMedium};
  ${flexCenter};
  gap: 10px;
  white-space: nowrap;
  position: relative;

  .chatCounter {
    pointer-events: none;
    min-width: 12px;
    justify-content: center;
    align-items: center;
    height: 12px;
    display: flex;
    padding: 2px;
    font-size: ${(p) => p.theme.typography.smallText3};
    background: ${(p) => p.theme.colors.background.fourth};
    color: ${(p) => p.theme.colors.text.primary};
    border-radius: 8px;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  &.active {
    color: ${(p) => p.theme.colors.green.primary};

    p {
      ${OutfitMedium};
    }
  }

  &:hover {
    color: ${(p) => p.theme.colors.text.primary};

    span {
      color: ${(p) => p.theme.colors.text.primary};
      ${OutfitMedium};
    }

    svg {
      color: ${(p) => p.theme.colors.green.primary} !important;
    }
  }
`;

export const MenuStyled = styled.div<{ active?: boolean }>`
  text-decoration: none;
  color: ${(p) => p.theme.colors.text.primary};
  ${flexCenter};
  cursor: pointer;
  position: relative;
  gap: 10px;
  white-space: nowrap;
  position: relative;

  ${(p) =>
    p.active &&
    css`
      color: ${(p) => p.theme.colors.green.primary};
    `}

  &:hover {
    color: ${(p) => p.theme.colors.text.primary};

    & > .icon {
      color: ${(p) => p.theme.colors.green.primary} !important;
    }
  }

  ${deviceQuery.tabletM} {
    color: ${(p) => p.theme.colors.text.third};
  }
`;

export const AuthLink = styled.a`
  text-decoration: none;
  color: ${(p) => p.theme.colors.text.primary};
  ${OutfitMedium};
  ${flexCenter};
  gap: 10px;
  white-space: nowrap;
  padding: 1.8rem 1rem;
  position: relative;

  &.active {
    color: ${(p) => p.theme.colors.green.primary};
    &::before {
      content: "";
      position: absolute;
      background-color: ${(p) => p.theme.colors.green.primary};
      border-radius: 3px;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      border-radius: 2px 2px 0 0;
    }
    p {
      ${OutfitMedium};
    }
  }

  &:hover {
    span {
      color: ${(p) => p.theme.colors.text.primary};
      ${OutfitMedium};
    }

    svg {
      color: ${(p) => p.theme.colors.green.primary} !important;
    }

    .monetization.fill,
    .campaigns.fill {
      fill: ${(p) => p.theme.colors.green.primary};
    }
    .community.stroke {
      stroke: ${(p) => p.theme.colors.green.primary};
    }
    .monetization.flood-color,
    .campaigns.flood-color {
      flood-color: ${(p) => p.theme.colors.green.primary};
    }
  }

  &:hover {
    color: ${(p) => p.theme.colors.text.primary};
    background: ${(props) => props.theme.colors.dark.primary};
  }
`;

export const DrawerBtn = styled.div`
  display: none;
  cursor: pointer;

  ${deviceQuery.desktopS} {
    display: block;
  }
`;
