import { FooterSocialsContainer } from "./style";

import { FOOTERSOCIALS } from "constants/appConstants";

const FooterSocials = () => (
  <FooterSocialsContainer>
    <div className="block toRow">
      {FOOTERSOCIALS.map((item, index) => (
        <a key={index} target="_blank" href={item.link} rel="noreferrer">
          {item.icon}
        </a>
      ))}
    </div>
  </FooterSocialsContainer>
);

export default FooterSocials;
