import styled from "styled-components";

export const ChatReplyContainer = styled.div<{ inChatStyles: boolean }>`
  display: grid;
  align-items: center;

  // calc is padding, gap and icons width
  grid-template-columns: 24px calc(100% - 80px) 24px;
  color: ${(p) => p.theme.colors.text.third};
  font-size: ${(p) => (p.inChatStyles ? p.theme.typography.subText3 : p.theme.typography.subText5)};
  padding: ${(p) => (p.inChatStyles ? "2px 4px" : "12px 16px")};
  cursor: ${(p) => (p.inChatStyles ? "pointer" : "default")};

  gap: 16px;

  .reply_message_info {
    overflow: hidden;
  }

  span {
    color: ${(p) => p.theme.colors.text.primary};
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    display: block;
  }

  .close_reply_btn {
    cursor: pointer;
    margin-left: auto;
    color: ${(p) => p.theme.colors.text.primary};
  }
`;
