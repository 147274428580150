import styled from "styled-components";

export const MessageSubSectionStyles = styled.div`
  display: flex;
  gap: 8px;
  margin-left: auto;
  font-size: ${(p) => p.theme.typography.subText3};
  color: ${(p) => p.theme.colors.text.secondary};
  align-items: center;

  .reply_btn {
    cursor: pointer;
    display: flex;
    gap: 4px;
    align-items: center;
    user-select: none;

    &:hover,
    &:active {
      color: ${(p) => p.theme.colors.text.third};
    }

    .reply_icon {
      margin-top: -2px;
    }
  }

  .divider {
    height: 14px;
    width: 1px;
    background-color: ${(p) => p.theme.colors.divider};
  }
`;
