import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";
import { HideScrollBar } from "styles/mixins";

export const NotificationContainer = styled.div`
  display: flex;
  gap: 20px;
  height: calc(100vh - 100px);
  position: relative;

  & > .left,
  & > .right {
    height: 100%;
    transition: all 0.3s;
    display: flex;
    padding: 0px;
    flex-direction: column;

    .goBack {
      display: none;
      gap: 12px;
      padding-bottom: 16px;
      cursor: pointer;
    }

    .topic {
      flex: unset;
      display: flex;
      padding: 14px 16px;
      gap: 12px;
      font-size: ${(p) => p.theme.typography.text3};
      border-bottom: 1px solid ${(p) => p.theme.colors.divider};

      .number {
        background: ${(p) => p.theme.colors.background.fifth};
      }

      .title {
        opacity: 0;
        pointer-events: none;

        &.show {
          pointer-events: all;
          opacity: 1;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;

      .noContent {
        height: 112px;
        padding: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${(p) => p.theme.colors.text.secondary};
      }

      & > .message {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        font-size: ${(p) => p.theme.typography.subText5};

        .extra {
          display: flex;
          border-radius: 12px;
          gap: 12px;
          background: ${(p) => p.theme.colors.background.fifth};
          padding: 12px;

          .icon {
            color: ${(p) => p.theme.colors.icon.third};
          }

          p {
            color: ${(p) => p.theme.colors.text.secondary};
          }
        }
      }
    }
  }

  & > .left {
    max-width: 332px;
    overflow: hidden;
    flex: 1;
  }

  & > .right {
    flex: 2;
  }

  ${deviceQuery.laptopM} {
    & > .left,
    & > .right {
      max-width: unset;
      flex: unset;
      width: 50%;
    }
  }

  ${deviceQuery.tabletM} {
    & > .left,
    & > .right {
      width: 100%;
      background: ${(p) => p.theme.colors.background.primary};
      box-shadow: none;
    }

    & > .left {
      border-radius: 12px;
      .topic {
        border-bottom: none;
        padding: 0px 0px 16px;

        .number {
          background: ${(p) => p.theme.colors.background.secondary};
        }
      }
      .content {
        ${HideScrollBar}
        gap: 12px;
        padding: 0px 16px;
        margin: 0px -16px;
      }
    }

    & > .right {
      background: ${(p) => p.theme.colors.background.primary};
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      opacity: 0;
      z-index: 2;
      pointer-events: none;
      border-radius: 0px;

      &.show {
        opacity: 1;
        pointer-events: all;
      }

      .topic,
      .content {
        background: ${(p) => p.theme.colors.background.secondary};
        box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
      }

      .topic {
        border-radius: 16px 16px 0px 0px;
      }
      .content {
        border-radius: 0px 0px 16px 16px;
      }

      .goBack {
        display: flex;
        flex: unset;
      }
    }
  }
`;
