/* eslint-disable camelcase */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store/store";
import { IFilterData, IShortPlan, ISubscribers } from "types/fan";

interface IInitialState {
  skipCount: number;
  filterData: IFilterData;
  subscribers: ISubscribers;
}

const initialState: IInitialState = {
  skipCount: 0,
  filterData: {
    searchWord: "",
    category: null,
    contentType: null,
    navigatedTo: null,
  },
  subscribers: {
    data: [],
    total: 0,
  },
};

const fanSlice = createSlice({
  name: "fan",
  initialState,
  reducers: {
    setSkipCount: (state, action: PayloadAction<{ skipCount: number }>) => {
      state.skipCount = action.payload.skipCount;
    },
    setSubscribers: (state, action: PayloadAction<{ result: IShortPlan[]; totalUser: number }>) => {
      state.subscribers.data = action.payload.result;
      state.subscribers.total = action.payload.totalUser;
    },
    setFilterData: (state, action: PayloadAction<{ filterData: IFilterData }>) => {
      state.filterData = action.payload.filterData;
    },
  },
});

export const { setSkipCount, setFilterData, setSubscribers } = fanSlice.actions;

export default fanSlice.reducer;

export const getSkipCount = ({ fan }: RootState) => fan.fan.skipCount;
export const getSubscribers = ({ fan }: RootState) => fan.fan.subscribers;
export const getFilterData = ({ fan }: RootState) => fan.fan.filterData;
