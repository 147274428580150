import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const BioWallContainer = styled.div`
  display: flex;
  gap: 20px;

  .wallContainer {
    display: flex;
    flex: 1;
    max-width: 332px;
    min-width: 332px;
  }

  .settingsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 2;
  }

  .block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 16px;
    border-radius: 16px;
    flex: 1;
    background: ${(p) => p.theme.colors.background.secondary};
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);

    .title {
      font-size: ${(p) => p.theme.typography.text3};
    }

    .boxes {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .divider {
        background: ${(p) => p.theme.colors.divider};
        width: 100%;
        height: 1px;
      }

      .showRow {
        font-size: ${(p) => p.theme.typography.subText5};
        display: flex;
        justify-content: space-between;
        gap: 8px;
        align-items: center;
      }
    }

    .inpunts {
      display: flex;
      gap: 16px;

      .input {
        flex: 1;
      }
    }
  }

  ${deviceQuery.laptopM} {
    .block {
      .inpunts {
        flex-direction: column;
      }
    }
  }

  ${deviceQuery.tabletM} {
    flex-direction: column;

    .wallContainer {
      max-width: unset;
      min-width: 310px;
    }
  }
`;

export const BioWallForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
