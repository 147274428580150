import { FC, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { PhoneAnimationTabsContainer } from "Components/PhoneAnimation/PhoneAnimationTabs/style";

interface Props {
  animationIndex: number;
  goToOtherSlide: (index: number) => void;
}
const PhoneAnimationTabs: FC<Props> = ({ animationIndex, goToOtherSlide }) => {
  const { t, i18n } = useTranslation();

  const tabs = useMemo(
    () => [
      {
        label: "phone_animation.profile_link",
        relatedAnimationIndex: 0,
      },
      {
        label: "phone_animation.customizable_profile",
        subTubs: [
          {
            label: "phone_animation.profile",
            relatedAnimationIndex: 1,
          },
          {
            label: "phone_animation.custom_theme",
            relatedAnimationIndex: 2,
          },
        ],
      },
      {
        label: "phone_animation.bio_links",
        subTubs: [
          {
            label: "phone_animation.links",
            relatedAnimationIndex: 3,
          },
          {
            label: "phone_animation.content_preview",
            relatedAnimationIndex: 4,
          },
        ],
      },
      {
        label: "phone_animation.gifting",
        subTubs: [
          {
            label: "phone_animation.gifts_from_fan",
            relatedAnimationIndex: 5,
          },
          {
            label: "phone_animation.wall_of_fame",
            relatedAnimationIndex: 6,
          },
        ],
      },
      {
        label: "phone_animation.subscriptions",
        relatedAnimationIndex: 7,
      },
    ],
    [i18n.language]
  );

  return (
    <PhoneAnimationTabsContainer>
      <div className="phone_animation_tabs">
        {tabs.map((tab) => {
          const tabIndex = tab.relatedAnimationIndex;

          const hasActiveChild =
            tab.subTubs &&
            tab.subTubs.some((subTub) => subTub.relatedAnimationIndex === animationIndex);

          return (
            <>
              <div
                key={tab.label}
                className={`phone_animation_tab 
                ${animationIndex === tabIndex && "phone_animation_tab_active"}
                ${hasActiveChild && "phone_animation_tab_childActive"}`}
                onClick={() => {
                  if (tabIndex === undefined) {
                    goToOtherSlide(tab?.subTubs?.[0].relatedAnimationIndex);
                    return;
                  }
                  if (tabIndex === animationIndex) return;

                  goToOtherSlide(tabIndex);
                }}
              >
                <span>{t(tab.label)}</span>
              </div>

              {tab.subTubs &&
                tab.subTubs.map((subTub) => {
                  const subTubIndex = subTub.relatedAnimationIndex;
                  return (
                    <div
                      key={subTub.label}
                      className={`phone_animation_tab phone_animation_subTab 
                      ${animationIndex === subTubIndex && "phone_animation_tab_active"}
                      ${!hasActiveChild && "phone_animation_tab_hidden"}
                      `}
                      onClick={() => {
                        if (subTubIndex === animationIndex) return;
                        goToOtherSlide(subTubIndex);
                      }}
                    >
                      <span>{t(subTub.label)}</span>
                    </div>
                  );
                })}
            </>
          );
        })}
      </div>
    </PhoneAnimationTabsContainer>
  );
};

export default PhoneAnimationTabs;
