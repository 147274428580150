const Warn = ({ size = 24 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 2C17.5229 2 22 6.47745 22 12C22 17.5225 17.5229 22 12 22C6.47779 22 2 17.5222 2 12C2 6.47779 6.47779 2 12 2ZM12 3.5C7.30621 3.5 3.5 7.30621 3.5 12C3.5 16.6938 7.30621 20.5 12 20.5C16.6944 20.5 20.5 16.6941 20.5 12C20.5 7.30586 16.6944 3.5 12 3.5ZM12.0051 15.0459C12.4193 15.0459 12.7551 15.3817 12.7551 15.7959C12.7551 16.1756 12.4729 16.4894 12.1069 16.5391L11.9951 16.5459C11.5809 16.5459 11.2451 16.2101 11.2451 15.7959C11.2451 15.4162 11.5273 15.1024 11.8933 15.0527L12.0051 15.0459ZM11.9951 7.4541C12.3748 7.4541 12.6886 7.73625 12.7383 8.10233L12.7451 8.2041V12.6231C12.7451 13.0373 12.4093 13.3731 11.9951 13.3731C11.6154 13.3731 11.3016 13.0909 11.2519 12.7249L11.2451 12.6231V8.2041C11.2451 7.78989 11.5809 7.4541 11.9951 7.4541Z"
      fill="currentColor"
    />
  </svg>
);

export default Warn;
