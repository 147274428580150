import { FC } from "react";

interface Props {
  size?: number;
  className?: string;
}

const Eye: FC<Props> = ({ size = 24, className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15.16 12.05C15.16 12.675 14.9747 13.286 14.6275 13.8056C14.2802 14.3253 13.7867 14.7303 13.2093 14.9695C12.6319 15.2086 11.9965 15.2712 11.3835 15.1493C10.7706 15.0274 10.2075 14.7264 9.76557 14.2845C9.32364 13.8425 9.02268 13.2795 8.90075 12.6665C8.77882 12.0535 8.8414 11.4181 9.08057 10.8407C9.31974 10.2633 9.72477 9.7698 10.2444 9.42257C10.7641 9.07534 11.375 8.89001 12 8.89001C12.8381 8.89001 13.6419 9.22294 14.2345 9.81556C14.8271 10.4082 15.16 11.2119 15.16 12.05V12.05Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 19.35C15.81 19.35 19.29 16.61 21.25 12.05C19.25 7.49 15.81 4.75 12 4.75C8.19 4.75 4.71 7.49 2.75 12.05C4.75 16.61 8.19 19.35 12 19.35Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Eye;
