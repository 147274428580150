import styled from "styled-components";

export const ChatSearchMessageNavigationStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;
  font-size: ${(p) => p.theme.typography.subText5};
  color: ${(p) => p.theme.colors.text.secondary};

  .indexes {
    margin-left: 8px;
    color: ${(p) => p.theme.colors.text.primary};
  }
  .navigation_arrows {
    display: flex;
    gap: 16px;
  }
  .arrow {
    cursor: pointer;
    color: ${(p) => p.theme.colors.text.primary};

    &.disabled {
      color: ${(p) => p.theme.colors.text.secondary};
    }
  }
  .arrow_up {
    transform: rotateZ(180deg);
  }
`;
