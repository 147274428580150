const LinkedinColor = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.8298 11.99C23.8298 15.1 23.8298 18.21 23.8298 21.31C23.8298 21.75 23.7099 22.15 23.4099 22.48C23.0899 22.82 22.6999 23 22.2299 23C15.9599 23 9.69984 23 3.42984 23C2.57984 23 1.85984 22.31 1.83984 21.45C1.83984 21.37 1.83984 21.29 1.83984 21.21C1.83984 15.03 1.83984 8.85999 1.83984 2.67999C1.83984 2.39999 1.86986 2.11999 2.00986 1.85999C2.30986 1.29999 2.78986 0.98999 3.41986 0.98999C9.69986 0.98999 15.9799 0.98999 22.2599 0.98999C23.0499 0.98999 23.8498 1.62999 23.8398 2.62999C23.8298 3.80999 23.8398 4.99999 23.8398 6.17999C23.8398 8.10999 23.8398 10.04 23.8398 11.97L23.8298 11.99Z"
      fill="#0273B2"
    />
    <path
      d="M10.4099 14.4899C10.4099 12.7899 10.4099 11.0799 10.4099 9.37995C10.4099 9.27995 10.4299 9.24995 10.5399 9.24995C11.4899 9.24995 12.4498 9.24995 13.3998 9.24995C13.4998 9.24995 13.5298 9.27995 13.5298 9.37995C13.5298 9.77995 13.5298 10.1899 13.5298 10.5899C13.5298 10.6199 13.5098 10.6699 13.5498 10.6799C13.5998 10.6999 13.5998 10.6499 13.6198 10.6199C13.9098 10.1199 14.3199 9.73995 14.8199 9.45995C15.3599 9.15995 15.9398 9.00995 16.5598 8.99995C17.1698 8.98995 17.7598 9.04995 18.3398 9.24995C19.2198 9.54995 19.8098 10.1499 20.1498 11.0099C20.4298 11.7099 20.5298 12.4499 20.5598 13.1899C20.6098 14.3999 20.5798 15.5999 20.5798 16.8099C20.5798 17.7499 20.5798 18.6899 20.5798 19.6299C20.5798 19.7199 20.5698 19.7499 20.4698 19.7499C19.4498 19.7499 18.4399 19.7499 17.4199 19.7499C17.3199 19.7499 17.3098 19.7199 17.3098 19.6299C17.3098 17.8699 17.3098 16.1099 17.3098 14.3499C17.3098 13.8599 17.2799 13.3699 17.1299 12.8999C16.8999 12.2199 16.3998 11.8699 15.6798 11.8599C15.3698 11.8599 15.0698 11.8899 14.7798 11.9999C14.3098 12.1799 14.0298 12.5299 13.8598 12.9899C13.6798 13.4699 13.6498 13.9799 13.6498 14.4899C13.6498 16.1999 13.6498 17.9099 13.6498 19.6199C13.6498 19.7299 13.6198 19.7499 13.5198 19.7499C12.5198 19.7499 11.5198 19.7499 10.5198 19.7499C10.4198 19.7499 10.3998 19.7199 10.3998 19.6299C10.3998 17.9199 10.3998 16.2099 10.3998 14.4999L10.4099 14.4899Z"
      fill="#FEFEFE"
    />
    <path
      d="M5.09985 14.49C5.09985 12.78 5.09985 11.08 5.09985 9.36999C5.09985 9.26999 5.11985 9.23999 5.21985 9.23999C6.21985 9.23999 7.22986 9.23999 8.22986 9.23999C8.32986 9.23999 8.34985 9.25999 8.34985 9.35999C8.34985 12.78 8.34985 16.2 8.34985 19.62C8.34985 19.71 8.32984 19.73 8.23984 19.73C7.22984 19.73 6.21984 19.73 5.20984 19.73C5.12984 19.73 5.09985 19.71 5.09985 19.62C5.09985 17.91 5.09985 16.19 5.09985 14.48V14.49Z"
      fill="#FEFEFE"
    />
    <path
      d="M4.83981 5.91002C4.83981 4.86002 5.6998 4.02002 6.7398 4.02002C7.7898 4.02002 8.63982 4.89002 8.62982 5.93002C8.62982 6.97002 7.75981 7.82002 6.70981 7.81002C5.66981 7.81002 4.82981 6.95002 4.83981 5.91002Z"
      fill="#FEFEFE"
    />
  </svg>
);

export default LinkedinColor;
