import { FC } from "react";

import { RadioContainer } from "./style";

interface Props {
  disabled?: boolean;
  name: string;
  text: string;
  description?: string;
  checked: boolean;
  onChange: () => void;
}

// Styled radio button component
const Radio: FC<Props> = ({ disabled = false, checked, name, text, description, onChange }) => (
  <RadioContainer
    disabled={disabled}
    className={checked ? "active" : ""}
    onClick={disabled ? () => {} : onChange}
  >
    <input
      disabled={disabled}
      className="input"
      name={name}
      type="radio"
      checked={checked}
      onChange={onChange}
    />
    <div>
      <div className="radio">
        <div className="dot" />
      </div>
    </div>
    <label className="radioLabel" htmlFor={name}>
      {text}
      {description && <span className="description">{description}</span>}
    </label>
  </RadioContainer>
);

export default Radio;
