import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";
import { HideScrollBar, flexCenter, OutfitMedium } from "styles/mixins";
import zIndexs from "styles/zIndex";

export const Wrapper = styled.div<{ show?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(7, 7, 17, 0.5);
  z-index: ${zIndexs.drawer};
  transition-delay: ${(p) => (p.show ? 0 : "0.2s")};
  transition-duration: 0s;
  display: none;

  ${deviceQuery.desktopS} {
    display: block;
  }
`;

export const DrawerContainer = styled.div`
  background-color: ${(p) => p.theme.colors.background.primary};
  width: 400px;
  margin-left: auto;
  height: 100vh;
  overflow: auto;
  transition: all 0.2s linear;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .content {
    display: flex;
    flex-direction: column;
  }

  ${deviceQuery.mobileL} {
    width: 100vw;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  gap: 12px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndexs.drawerHeader};

  &.withBlur {
    background: ${(p) => p.theme.colors.blackWhite.primary80};
    box-shadow: 0px 1px 0px 0px ${(p) => p.theme.colors.divider};
    backdrop-filter: blur(5px);
  }

  a {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    text-decoration: none;
  }

  .times {
    cursor: pointer;
  }
`;

export const DrawerNavLink = styled(NavLink)`
  padding: 0 1rem;
  font-size: 1rem;
  width: 100%;
  height: 45px;
  text-decoration: none;
  ${OutfitMedium};
  color: ${(p) => p.theme.colors.text.primary};
  align-self: stretch;
  ${flexCenter};
  position: relative;

  &:hover,
  &.active {
    color: ${(p) => p.theme.colors.green.primary};
  }
`;

export const AuthDrawerNavLink = styled.a`
  padding: 0 1rem;
  font-size: 1rem;
  width: 100%;
  height: 45px;
  text-decoration: none;
  ${OutfitMedium};
  color: ${(p) => p.theme.colors.text.primary};
  align-self: stretch;
  ${flexCenter};
  position: relative;

  &:hover,
  &.active {
    color: ${(p) => p.theme.colors.green.primary};
  }
`;
