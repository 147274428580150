const Audio = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.71484 10.9336C4.71484 15.0231 8.02913 18.3374 12.1187 18.3374C16.2082 18.3374 19.5234 15.0231 19.5234 10.9336"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1188 21.1484V18.3379"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2454 14.5853H11.993C9.97206 14.5853 8.33301 12.9472 8.33301 10.9253V6.37484C8.33301 4.35389 9.97206 2.71484 11.993 2.71484H12.2454C14.2673 2.71484 15.9054 4.35389 15.9054 6.37484V10.9253C15.9054 12.9472 14.2673 14.5853 12.2454 14.5853Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.002 6.87853H15.9048"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0498 10.0485H15.905"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Audio;
