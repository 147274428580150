import styled, { css } from "styled-components";

import { ButtonSizes, ButtonVariants } from "./types";

export const ButtonContainer = styled.button<{
  fontSize?: string;
  size: ButtonSizes;
  variant: ButtonVariants;
  full: boolean;
  round: boolean;
}>`
  position: relative;
  display: flex;
  gap: 8px;
  border: none;
  outline: none;
  width: ${(p) => (p.full ? "100%" : "fit-content")};
  transition: all 0.3s ease-in-out;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.colors.white.primary};

  ${(p) =>
    p.size === "sm" &&
    css`
      border-radius: ${p.round ? "20px" : "8px"};
      padding: 8px 16px;
      font-size: ${p.fontSize ? p.fontSize : p.theme.typography.subText5};
    `}
  ${(p) =>
    p.size === "md" &&
    css`
      border-radius: ${p.round ? "30px" : "12px"};
      padding: 10px 20px;
      font-size: ${p.fontSize ? p.fontSize : p.theme.typography.text3};
    `}
  ${(p) =>
    p.size === "lg" &&
    css`
      border-radius: ${p.round ? "40px" : "16px"};
      padding: 12px 24px;
      font-size: ${p.fontSize ? p.fontSize : p.theme.typography.title1};
    `}

  ${(p) =>
    p.variant === "primary" &&
    css`
      background: ${p.theme.colors.button.primary.primary};

      &:disabled {
        color: ${p.theme.colors.text.sixth};
        background: ${p.theme.colors.button.primary.inactive};

        svg {
          path {
            stroke: ${p.theme.colors.text.sixth} !important;
          }
        }

        &:hover {
          color: ${p.theme.colors.text.sixth};
          background: ${p.theme.colors.button.primary.inactive};

          svg {
            path {
              stroke: ${p.theme.colors.text.sixth} !important;
            }
          }
        }
      }

      &:hover {
        svg {
          path {
            stroke: ${p.theme.colors.white.primary} !important;
          }
        }
        background: ${p.theme.colors.button.primary.focus};
      }

      &:active {
        background: ${p.theme.colors.button.primary.pressed};
      }
    `}

  ${(p) =>
    p.variant === "secondary" &&
    css`
      color: ${p.theme.colors.text.third};
      border: 1px solid ${p.theme.colors.button.secondary.primary};
      background: transparent;

      &:disabled {
        color: ${p.theme.colors.text.sixth};
        border: 1px solid ${p.theme.colors.button.secondary.inactive};

        svg {
          path {
            stroke: ${p.theme.colors.text.sixth} !important;
          }
        }

        &:hover {
          color: ${p.theme.colors.text.sixth};
          border: 1px solid ${p.theme.colors.button.secondary.inactive};

          svg {
            path {
              stroke: ${p.theme.colors.text.sixth} !important;
            }
          }
        }
      }

      &:hover {
        border: 1px solid transparent;
        background: ${p.theme.colors.button.secondary.focus};

        svg {
          path {
            stroke: ${p.theme.colors.text.third} !important;
          }
        }
      }

      &:active {
        border: 1px solid transparent;
        background: ${p.theme.colors.button.secondary.pressed};
      }
    `}

  ${(p) =>
    p.variant === "third" &&
    css`
      background: ${p.theme.colors.button.third.primary};

      &:disabled {
        color: ${p.theme.colors.text.seventh};
        background: ${p.theme.colors.button.third.inactive};

        svg {
          path {
            stroke: ${p.theme.colors.text.seventh} !important;
          }
        }

        &:hover {
          color: ${p.theme.colors.text.seventh};
          background: ${p.theme.colors.button.third.inactive};

          svg {
            path {
              stroke: ${p.theme.colors.text.seventh} !important;
            }
          }
        }
      }

      &:hover {
        svg {
          path {
            stroke: ${p.theme.colors.text.primary} !important;
          }
        }
        background: ${p.theme.colors.button.third.focus};
      }

      &:active {
        background: ${p.theme.colors.button.third.pressed};
      }
    `}
`;
