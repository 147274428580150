import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { STRINGS } from "constants/appConstants";
import { RootState } from "store/store";
import { IToast } from "types/toast";

interface IInitialState {
  [x: string]: any;
  fullScreenLoader: boolean;
  toast: IToast[];
  wallOfFameLoader: boolean;
  maintenanceLoader: boolean;
  feeLoader: boolean;
  uploadVideoLoader: boolean;
  registrationLoader: boolean;
  configurationLoader: boolean;
  notificationLoader: boolean;
  iniviteCodeSpin: boolean;
  loginLoader: boolean;
  allUsersLoader: boolean;
  summaryTableLoader: boolean;
  searchUserLoader: boolean;
  creatorAllVideosLoader: boolean;
  communityLoader: boolean;
  profileGrowthLoader: boolean;
  uploadingVideoLinkLoader: boolean;
  QRCodeLoader: boolean;
  dashboardMonetizedLoader: boolean;
  newlyRegisteredUsersLoader: boolean;
  submitTrendLoader: boolean;
  stakingLoader: boolean;
  transactionsLoader: boolean;
  creatorEarningReportLoader: boolean;
  challengeParticipateLoader: boolean;
  changePasswordLoader: boolean;
  editProfileLoader: boolean;
  refreshWithdrawLoader: boolean;
  withdrawLoader: boolean;
  judgeVideosLoader: boolean;
  judgeVideosDetailLoader: boolean;
  withdrawalHistoryLoader: boolean;
  unstakeLoader: boolean;
  categoryLoader: boolean;
  nftAddLoader: boolean;
  nftLoader: boolean;
  saleLoader: boolean;
  campaignLoader: boolean;
  contentLoader: boolean;
  creatorVideoLoader: boolean;
  creatorVideoDetailsSubmitLoader: boolean;
  creatorPlanSubmitLoader: boolean;
  adminCreatorVideoLoader: boolean;
  subscriberLoader: boolean;
  subscriptionRefundLoader: boolean;
  subscriptionPlan: boolean;
  paymentHistoryLoader: boolean;
  earningsLoader: boolean;
  giftingLoader: boolean;
  reportLoader: boolean;
  analyticsLoader: boolean;
  rewardLoader: boolean;
  videoLoader: boolean;
  publisherListCampaignLoader: boolean;
  feedLoader: boolean;
  creatorProfileLoader: boolean;
  challengeLoader: boolean;
  participantsLoader: boolean;
  walletLoader: boolean;
  passwordLoader: boolean;
  signUpManageLoader: boolean;
  messageLoader: boolean;
  threadLoader: boolean;
}

const initialState: IInitialState = {
  fullScreenLoader: false,
  toast: [],
  feeLoader: false,
  wallOfFameLoader: false,
  maintenanceLoader: false,
  uploadVideoLoader: false,
  registrationLoader: false,
  configurationLoader: false,
  notificationLoader: false,
  iniviteCodeSpin: false,
  loginLoader: false,
  allUsersLoader: true,
  summaryTableLoader: true,
  searchUserLoader: false,
  creatorAllVideosLoader: true,
  communityLoader: true,
  profileGrowthLoader: true,
  uploadingVideoLinkLoader: false,
  QRCodeLoader: false,
  dashboardMonetizedLoader: true,
  newlyRegisteredUsersLoader: true,
  submitTrendLoader: false,
  stakingLoader: false,
  transactionsLoader: true,
  creatorEarningReportLoader: true,
  challengeParticipateLoader: false,
  changePasswordLoader: false,
  editProfileLoader: false,
  refreshWithdrawLoader: false,
  withdrawLoader: false,
  judgeVideosLoader: true,
  judgeVideosDetailLoader: false,
  withdrawalHistoryLoader: true,
  unstakeLoader: false,
  categoryLoader: false,
  nftAddLoader: false,
  nftLoader: false,
  saleLoader: false,
  campaignLoader: false,
  contentLoader: false,
  creatorVideoLoader: false,
  creatorVideoDetailsSubmitLoader: false,
  creatorPlanSubmitLoader: false,
  adminCreatorVideoLoader: false,
  subscriberLoader: false,
  subscriptionRefundLoader: false,
  subscriptionPlan: false,
  paymentHistoryLoader: false,
  earningsLoader: false,
  giftingLoader: false,
  reportLoader: false,
  analyticsLoader: false,
  rewardLoader: false,
  videoLoader: false,
  publisherListCampaignLoader: false,
  feedLoader: false,
  creatorProfileLoader: false,
  challengeLoader: false,
  participantsLoader: false,
  walletLoader: false,
  passwordLoader: false,
  signUpManageLoader: false,
  messageLoader: false,
  threadLoader: false,
};

const featureSlice = createSlice({
  name: "feature",
  initialState,
  reducers: {
    setLoader: (state, action: PayloadAction<{ name: string; value: boolean }>) => {
      state[action.payload.name] = action.payload.value;
    },
    createToast: (state, action: PayloadAction<{ id: number; message?: string; type: string }>) => {
      state.toast = [
        {
          id: action.payload.id,
          show: false,
          message: action.payload.message || "",
          type: action.payload.type || STRINGS.success,
        },
        ...state.toast,
      ];
    },
    showToast: (state, action: PayloadAction<number>) => {
      state.toast = state.toast.map((item) => {
        if (action.payload === item.id) {
          return {
            ...item,
            show: true,
          };
        }
        return item;
      });
    },
    hideToast: (state, action: PayloadAction<number>) => {
      state.toast = state.toast.map((item) => {
        if (action.payload === item.id) {
          return {
            ...item,
            show: false,
          };
        }
        return item;
      });
    },
    deleteToast: (state, action: PayloadAction<number>) => {
      state.toast = state.toast.filter((item) => item.id !== action.payload);
    },
    resetFeatureSlice: (state) => {
      state = initialState;
    },
    setFullScreenLoader: (state, action: PayloadAction<boolean>) => {
      state.fullScreenLoader = action.payload;
    },
  },
});

export const {
  showToast,
  hideToast,
  deleteToast,
  createToast,
  resetFeatureSlice,
  setFullScreenLoader,
  setLoader,
} = featureSlice.actions;

export default featureSlice.reducer;

export const getToast = ({ feature }: RootState) => feature.toast;
export const getUnstakeLoader = ({ feature }: RootState) => feature.unstakeLoader;
export const getJudgeVideosLoader = ({ feature }: RootState) => feature.judgeVideosLoader;
export const getJudgeVideosDetailLoader = ({ feature }: RootState) =>
  feature.judgeVideosDetailLoader;
export const getWithdrawalHistoryLoader = ({ feature }: RootState) =>
  feature.withdrawalHistoryLoader;
export const getWithdrawLoader = ({ feature }: RootState) => feature.withdrawLoader;
export const getRefreshWithdrawLoader = ({ feature }: RootState) => feature.refreshWithdrawLoader;
export const getEditProfileLoader = ({ feature }: RootState) => feature.editProfileLoader;
export const getChangePasswordLoader = ({ feature }: RootState) => feature.changePasswordLoader;
export const getChallengeParticipateLoader = ({ feature }: RootState) =>
  feature.challengeParticipateLoader;
export const getCreatorEarningReportLoader = ({ feature }: RootState) =>
  feature.creatorEarningReportLoader;
export const getTransactionsLoader = ({ feature }: RootState) => feature.transactionsLoader;
export const getStakingLoader = ({ feature }: RootState) => feature.stakingLoader;
export const getSubmitTrendLoader = ({ feature }: RootState) => feature.submitTrendLoader;
export const getNewlyRegisteredUsersLoader = ({ feature }: RootState) =>
  feature.newlyRegisteredUsersLoader;
export const getDashboardMonetizedLoader = ({ feature }: RootState) =>
  feature.dashboardMonetizedLoader;
export const getQRCodeLoader = ({ feature }: RootState) => feature.QRCodeLoader;
export const getUploadingVideoLinkLoader = ({ feature }: RootState) =>
  feature.uploadingVideoLinkLoader;
export const getProfileGrowthLoader = ({ feature }: RootState) => feature.profileGrowthLoader;
export const getFullScreenLoader = ({ feature }: RootState) => feature.fullScreenLoader;
export const getMaintenanceLoader = ({ feature }: RootState) => feature.maintenanceLoader;
export const getRegistrationLoader = ({ feature }: RootState) => feature.registrationLoader;
export const getUploadVideoLoader = ({ feature }: RootState) => feature.uploadVideoLoader;
export const getConfigurationLoader = ({ feature }: RootState) => feature.configurationLoader;
export const getNotificationLoader = ({ feature }: RootState) => feature.notificationLoader;
export const getIniviteCodeSpin = ({ feature }: RootState) => feature.iniviteCodeSpin;
export const getLoginLoader = ({ feature }: RootState) => feature.loginLoader;
export const getAllUsersLoader = ({ feature }: RootState) => feature.allUsersLoader;
export const getSummaryTableLoader = ({ feature }: RootState) => feature.summaryTableLoader;
export const getSearchUserLoader = ({ feature }: RootState) => feature.searchUserLoader;
export const getCreatorAllVideosLoader = ({ feature }: RootState) => feature.creatorAllVideosLoader;
export const getCommunityLoader = ({ feature }: RootState) => feature.communityLoader;
export const getCategoryloader = ({ feature }: RootState) => feature.categoryLoader;
export const getNftAddloader = ({ feature }: RootState) => feature.nftAddLoader;
export const getNftloader = ({ feature }: RootState) => feature.nftLoader;
export const getSaleloader = ({ feature }: RootState) => feature.saleLoader;
export const getCampaignLoader = ({ feature }: RootState) => feature.campaignLoader;
export const getContentLoader = ({ feature }: RootState) => feature.contentLoader;
export const getCreatorVideoLoader = ({ feature }: RootState) => feature.creatorVideoLoader;
export const getCreatorVideoDetailsSubmitLoader = ({ feature }: RootState) =>
  feature.creatorVideoDetailsSubmitLoader;
export const getCreatorPlanSubmitLoader = ({ feature }: RootState) =>
  feature.creatorPlanSubmitLoader;
export const getAdminCreatorVideoLoader = ({ feature }: RootState) =>
  feature.adminCreatorVideoLoader;
export const getSubscriberLoader = ({ feature }: RootState) => feature.subscriberLoader;
export const getSubscriptionRefundLoader = ({ feature }: RootState) =>
  feature.subscriptionRefundLoader;
export const getSubscriptionPlanLoader = ({ feature }: RootState) => feature.subscriptionPlan;
export const getPaymentHistoryLoader = ({ feature }: RootState) => feature.paymentHistoryLoader;
export const getEarningsLoader = ({ feature }: RootState) => feature.earningsLoader;
export const getGiftingLoader = ({ feature }: RootState) => feature.giftingLoader;
export const getReportLoader = ({ feature }: RootState) => feature.reportLoader;
export const getAnalyticsLoader = ({ feature }: RootState) => feature.analyticsLoader;
export const getRewardLoader = ({ feature }: RootState) => feature.rewardLoader;
export const getVideoLoader = ({ feature }: RootState) => feature.videoLoader;
export const getFeedLoader = ({ feature }: RootState) => feature.feedLoader;
export const getPublisherListCampaignLoader = ({ feature }: RootState) =>
  feature.publisherListCampaignLoader;
export const getCreatorProfileLoader = ({ feature }: RootState) => feature.creatorProfileLoader;
export const getChallengeLoader = ({ feature }: RootState) => feature.challengeLoader;
export const getParticipantsLoader = ({ feature }: RootState) => feature.participantsLoader;
export const getWalletLoader = ({ feature }: RootState) => feature.walletLoader;
export const getFeeLoader = ({ feature }: RootState) => feature.feeLoader;
export const getPasswordLoader = ({ feature }: RootState) => feature.passwordLoader;
export const getSignupManageLoader = ({ feature }: RootState) => feature.signUpManageLoader;
export const getMessageLoader = ({ feature }: RootState) => feature.messageLoader;
export const getThreadLoader = ({ feature }: RootState) => feature.threadLoader;
export const getWallOfFameLoader = ({ feature }: RootState) => feature.wallOfFameLoader;
