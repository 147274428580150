import { DefaultTheme } from "styled-components";

export const HeaderHeight = "95px";

const fw = {
  superLight: 200,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  light: 300,
};

const ff = {
  outfit: {
    bold: "Outfit-Bold, sans-serif",
    semiBold: "Outfit-Semibold, sans-serif",
    light: "Outfit-Light, sans-serif",
    medium: "Outfit-Medium, sans-serif",
    extraBold: "Outfit-ExtraBold, sans-serif",
    black: "Outfit-Black, sans-serif",
    regular: "Outfit-Regular, sans-serif",
    thin: "Outfit-Thin, sans-serif",
  },
};

const typography = {
  title1: "24px",
  title2: "28px",
  title3: "32px",
  title4: "36px",
  title5: "42px",
  title6: "52px",
  subTitle1: "20px",
  subTitle2: "22px",
  subTitle3: "24px",
  subTitle4: "26px",
  subTitle5: "48px",
  text: "14px",
  text1: "16px",
  text2: "18px",
  text3: "19px",
  subText: "10px",
  subText1: "11px",
  subText2: "12px",
  subText3: "13px",
  subText4: "14px",
  subText5: "15px",
  smallText1: "5px",
  smallText2: "6px",
  smallText3: "7px",
};

const red = {
  primary: "#FF6131",
  primary80: "#FF6131CC",
  primary60: "#FF613199",
  primary40: "#FF613166",
  primary20: "#FF613133",
};

const dark = {
  primary: "#070711",
  primary80: "#070711CC",
  primary60: "#07071199",
  primary40: "#07071166",
  primary20: "#07071133",
};

const green = {
  primary: "#25C374",
  primary80: "#25C374CC",
  primary60: "#25C37499",
  primary40: "#25C37466",
  primary20: "#25C37433",
};

const yellow = {
  primary: "#F79E00",
  primary80: "#F79E00CC",
  primary60: "#F79E0099",
  primary40: "#F79E0066",
  primary20: "#F79E0033",
};

const white = {
  primary: "#ffffff",
  primary80: "#ffffffCC",
  primary60: "#ffffff99",
  primary40: "#ffffff66",
  primary20: "#ffffff33",
};

export const graph = {
  green: "#25C374",
  darkGreen: "#468d73",
  blue: "#6A88F4",
  lightBlue: "#5cb1eb",
  yellow: "#FFE68E",
  lightGrey: "#e0e0ee",
  darkGrey: "#68687F",
  silver: "#c4c4c4",
  purple: "#DB8EFF",
};

export const darkColors = {
  white,
  green,
  dark,
  yellow,
  red,
  graph,
  divider: "#2B2B34",
  overlay: "#070711CC",
  links: {
    icon: "#ffffff0A",
  },
  blackWhite: {
    primary: "#000000",
    primary80: "#000000CC",
    primary60: "#00000099",
    primary40: "#00000066",
    primary20: "#00000033",
  },
  whiteBlack: {
    primary: "#ffffff",
    primary80: "#ffffffCC",
    primary60: "#ffffff99",
    primary40: "#ffffff66",
    primary20: "#ffffff33",
  },
  background: {
    primary: "#000000",
    secondary: "#16161F",
    third: "#16161FE5",
    fourth: "#25C374",
    fifth: "#20202B",
  },
  icon: {
    primary: "#FFFFFF",
    secondary: "#68687E",
    third: "#25C374",
    fourth: "#FF6131",
    fifth: "#F79E00",
    buttons: "#FFFFFF",
    disabled: "#2B2B34",
  },
  field: {
    empty: "#404054",
    full: "#77748D",
    mistake: "#FF6131",
    done: "#25C374",
  },
  text: {
    primary: "#FFFFFF",
    secondary: "#8F8FA8",
    third: "#25C374",
    fourth: "#FFFFFF",
    fifth: "#FFFFFF",
    sixth: "#1C5C42",
    seventh: "#6A514F",
    error: "#FF6131",
    links: "#F79E00",
    disabled: "#FFFFFF4D",
    placehorder: "#58586B",
  },
  button: {
    primary: {
      primary: "#25C374",
      focus: "#22A063",
      pressed: "#1C5B41",
      inactive: "#193930",
    },
    secondary: {
      primary: "#25C374",
      focus: "#18302C",
      pressed: "#1B4A38",
      inactive: "#18302C",
    },
    third: {
      primary: "#FF6131",
      focus: "#D0522D",
      pressed: "#A2432A",
      inactive: "#452523",
    },
  },
};

export const lightColors = {
  white,
  green,
  dark,
  yellow,
  graph,
  red,
  divider: "#E8E8E8",
  overlay: "#0707114D",
  links: {
    icon: "#0000000A",
  },
  blackWhite: {
    primary: "#ffffff",
    primary80: "#ffffffCC",
    primary60: "#ffffff99",
    primary40: "#ffffff66",
    primary20: "#ffffff33",
  },
  whiteBlack: {
    primary: "#000000",
    primary80: "#000000CC",
    primary60: "#00000099",
    primary40: "#00000066",
    primary20: "#00000033",
  },
  background: {
    primary: "#F3F4F8",
    secondary: "#FFFFFF",
    third: "#FFFFFFE5",
    fourth: "#25C374",
    fifth: "#F6F7FA",
  },
  icon: {
    primary: "#444454",
    secondary: "#9D9DB0",
    third: "#25C374",
    fourth: "#FF6131",
    fifth: "#F79E00",
    buttons: "#FFFFFF",
    disabled: "#B9B9C8",
  },
  field: {
    empty: "#C6C6D9",
    full: "#77748D",
    mistake: "#FF6131",
    done: "#25C374",
  },
  text: {
    primary: "#444454",
    secondary: "#8F8FA8",
    third: "#25C374",
    fourth: "#444454",
    fifth: "#FFFFFF",
    sixth: "#A0E0C2",
    seventh: "#FBEFEC",
    error: "#FF6131",
    links: "#F79E00",
    disabled: "#FFFFFF4D",
    placehorder: "#B9B9C8",
  },
  button: {
    primary: {
      primary: "#25C374",
      focus: "#4FCD8F",
      pressed: "#A1E0C3",
      inactive: "#C9EADD",
    },
    secondary: {
      primary: "#25C374",
      focus: "#DEF6EA",
      pressed: "#BEEDD5",
      inactive: "#D4EDE4",
    },
    third: {
      primary: "#FF6131",
      focus: "#FD7E59",
      pressed: "#F8B9A8",
      inactive: "#F5D7D0",
    },
  },
};

export const darkTheme: DefaultTheme = {
  fw,
  ff,
  colors: darkColors,
  typography,
  navbar: {
    height: "8rem",
  },
};

export const lightTheme: DefaultTheme = {
  fw,
  ff,
  colors: lightColors,
  typography,
  navbar: {
    height: "8rem",
  },
};
