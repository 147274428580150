import React from "react";

const FullLogo = () => (
  <svg width="120" height="26" viewBox="0 0 120 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.96353 0.00247267L1.37493 3.73998C0.503594 4.3279 -0.0147052 5.36789 0.000317894 6.47703V18.7666L11.0899 25.5969C11.5406 25.8735 12.0463 26.0094 12.5471 25.9995C13.0554 25.9896 13.5587 25.8365 14.0069 25.5351L19.3977 21.9137L6.96103 14.288V0"
      fill="#25C374"
    />
    <path d="M10.5439 1.56641L10.5765 12.8456L19.4001 6.93922L10.5439 1.56641Z" fill="#25C374" />
    <path
      d="M35.1321 4.10547H30.167V17.8055V21.8963H35.1321H46.4095V17.8055H35.1321V4.10547Z"
      fill="#25C374"
    />
    <path
      d="M85.692 16.6766L79.843 4.10547H74.8803L79.1294 13.2405L77.5294 16.6766L71.6804 4.10547H66.7178L74.993 21.8963H75.1032H79.9581H80.0658L81.6107 18.5763L83.1556 21.8963H83.2657H88.1207H88.2284L96.5061 4.10547H91.541L85.692 16.6766Z"
      fill="#25C374"
    />
    <path
      d="M113.65 4.10547L106.822 10.9555H102.711V4.10547H97.748V21.8963H102.711V15.0463H106.822L113.65 21.8963H120L111.134 13.0009L120 4.10547H113.65Z"
      fill="#25C374"
    />
    <path
      d="M48.6523 4.10547V8.19622V10.9555V15.0463V17.8055V21.8963H53.6175H64.8948V17.8055H53.6175V15.0463H59.156V10.9555H53.6175V8.19622H64.8948V4.10547H53.6175H48.6523Z"
      fill="#25C374"
    />
  </svg>
);

export default FullLogo;
