import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ChangeThemeContainer } from "./style";

import ArrowDownSmall from "assets/svg/ArrowDownSmall";
import Switch from "Components/Switch";
import { updateProfileInfoAPI } from "services/UserServices";
import { getUser } from "store/slices/global/userSlice";
import { BlockCard } from "styles/mixins";

interface Props {
  bio?: boolean;
}

const ChangeTheme: FC<Props> = ({ bio = false }) => {
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const [theme, setTheme] = useState(true);
  const [open, setOpen] = useState(false);

  const handleChange = () => {
    setTheme(!theme);
    if (bio) {
      updateProfileInfoAPI({
        data: {
          bioTheme: theme ? "light" : "dark",
        },
        part: "general",
      });
      updateProfileInfoAPI({
        data: {
          wallpaper: null,
        },
        part: "customizations",
      });
    } else {
      updateProfileInfoAPI({
        data: {
          generalTheme: theme ? "light" : "dark",
        },
        part: "general",
      });
    }
  };

  useEffect(() => {
    bio ? setTheme(user?.bioTheme === "dark") : setTheme(user?.generalTheme === "dark");
  }, [user]);

  return (
    <ChangeThemeContainer bio={bio} opened={open}>
      <BlockCard className="change">
        <div className="switchContainer">
          <Switch label={t("common.nightMode")} value={theme} onChange={handleChange} />
          <div className="arrow" onClick={() => setOpen(!open)}>
            <ArrowDownSmall />
          </div>
        </div>
        {bio && (
          <div className="hint">
            <p className="expandable">{t("common.themeHint")}</p>
          </div>
        )}
      </BlockCard>
    </ChangeThemeContainer>
  );
};

export default ChangeTheme;
