import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const HeaderSection = styled.div`
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 750px) {
    display: block;
    .mob-mb {
      margin-bottom: 10px;
    }
    &.header-div {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
    }
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  z-index: 9999;
  left: 45%;
  top: 35%;
`;

export const TableSection = styled.section<{ disableOverflowX?: boolean }>`
  border-radius: 16px;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
  width: 100%;

  .table-responsive {
    overflow-x: ${(p) => (p.disableOverflowX ? "unset" : "auto")};
  }
`;

export const SmallTitle = styled.h5`
  font-weight: 400;
  font-size: 18px;
`;

export const CommonText = styled.p`
  color: ${(p) => p.theme.colors.text.secondary};
`;

export const LinkSection = styled.div`
  display: flex;
  align-items: center;
  a,
  .link {
    margin-left: 5px;
    color: ${(p) => p.theme.colors.green.primary};
    underline: ${(p) => p.theme.colors.green.primary};
  }
`;

export const CommonHeadValueSection = styled.div`
  margin: 2rem 0rem;
`;

export const BackFilterWrapper = styled.div`
  min-height: 35px;
  display: flex;
  justify-content: space-between;
  ${deviceQuery.tabletS} {
    flex-direction: column;
  }
`;

export const DatePickerWrapper = styled.div`
  width: 220px;
  .react-datepicker-wrapper {
    padding: 8px 2px;
    border-radius: 5px;
    border: 1px solid green;
    color: #151529;
    background: #25c374;
    input {
      background: transparent;
      border: none;
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #25c374;
    color: #151529;
  }
`;
