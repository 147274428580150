import { FC, JSX } from "react";

import { TextContainer } from "./style";

interface Props {
  content: string | JSX.Element;
  preview?: boolean;
}

const Text: FC<Props> = ({ content, preview = false }) => (
  <TextContainer preview={preview}>{content}</TextContainer>
);

export default Text;
