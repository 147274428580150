import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import LandingCards from "./components/LandingCards";

import { getLocalStorageItem } from "common/helpers/localStorage";
import AirdropBanner from "Components/AirdropBanner";
import PhoneAnimationSection from "Components/PhoneAnimation/PhoneAnimationSection";
import { STORAGES } from "constants/appConstants";
import titles from "constants/titles";
import useTitle from "customHooks/useTitle";
import { getAllDefaultLandingPageAPI } from "services/axios";
import { getDefaultLandingPages } from "store/slices/admin/adminSlice";
import { getToken, logout } from "store/slices/authSlice";

const Home = () => {
  useTitle(titles.home);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwtToken = useSelector(getToken);
  const defaultLandingPages = useSelector(getDefaultLandingPages);
  const lRole = getLocalStorageItem({ key: STORAGES.accountType });
  const token = getLocalStorageItem({ key: STORAGES.token });

  // fetch only if token exists and we have to redirect user to the main page
  useEffect(() => {
    if (!jwtToken) return;
    getAllDefaultLandingPageAPI();
  }, [jwtToken]);

  useEffect(() => {
    const route = defaultLandingPages.find((item: any) => item.role === lRole)?.defaultURl;
    if (route) navigate(`/${route}`, { replace: true });
  }, [defaultLandingPages]);

  useEffect(() => {
    if (!token) {
      dispatch(logout());
    }
  }, []);

  return (
    <>
      {/* <AirdropBanner /> */}
      <PhoneAnimationSection />
      <LandingCards />
    </>
  );
};

export default Home;
