import styled from "styled-components";

import Button from "Components/Button";

import { deviceQuery } from "styles/mediaSizes";
import {
  flexCenter,
  oneLineText,
  OutfitBold,
  OutfitLight,
  OutfitMedium,
  textGradient,
} from "styles/mixins";

export const RoundButton = styled(Button)`
  background: ${(p) => p.theme.colors.background.primary};
  color: ${(p) => p.theme.colors.text.primary};
  border-radius: 50%;
  padding: 16px;
  border: unset;

  &:hover {
    background: transparent;

    svg {
      path {
        stroke: ${(p) => p.theme.colors.text.primary} !important;
      }
    }
  }
`;

export const Heading = styled.p`
  font-size: 1.5rem;
  ${OutfitBold};
  ${textGradient};
  text-align: center;
  line-height: 1.2;

  ${deviceQuery.mobileL} {
    font-size: 1.3rem;
  }
`;

export const SubHeading = styled.div`
  ${OutfitLight};
  text-align: center;
  font-size: 1rem;
  color: ${(p) => p.theme.colors.text.primary};
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;

  p {
    color: ${(p) => p.theme.colors.text.third};
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    border-radius: 20px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Form = styled.form`
  max-width: 400px;
`;

export const DetailsSection = styled.div`
  border-radius: 12px;
  background: ${(p) => p.theme.colors.background.secondary};
  box-shadow: 21px 21px 42px #cacad4, -21px -21px 42px #ffffff;
  padding: 2rem;
  justify-content: center;
  display: grid;
  color: ${(p) => p.theme.colors.text.secondary};

  .btn-con {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ${deviceQuery.tabletS} {
    padding: 2rem;
  }
`;

export const QRContainer = styled.div`
  width: 256px;
  margin: 1.5rem auto 0;
  height: 256px;
`;

export const AuthStyled = styled.div`
  min-height: 100vh;
  color: ${(p) => p.theme.colors.text.secondary};
  background-color: ${(p) => p.theme.colors.background.fifth};
  ${flexCenter};
  padding: 2rem 1rem;

  button {
    &:hover {
      color: ${(p) => p.theme.colors.text.primary};
    }
  }

  .back-btn-container {
    position: absolute;
    top: 2rem;
    left: 2rem;
    z-index: 3;
  }
`;

export const RecoverCodeBox = styled.div`
  text-align: center;
  padding: 10px 0;
  background: ${(p) => p.theme.colors.background.secondary};
  border: 1px solid ${(p) => p.theme.colors.field.full};
  border-radius: 5px;
  width: 100%;
  min-width: 400px;
  margin: 1rem 0;
  overflow: hidden;
  position: relative;

  .key {
    ${flexCenter};

    span {
      display: inline-block;
      color: ${(p) => p.theme.colors.text.primary};
      text-overflow: ellipsis;
      width: calc(100% - 70px);
    }

    .cursor-pointer {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 50px;
    }
  }

  input {
    background: transparent;
    border: 0;
    outline: 0;
    width: 100%;
    height: 100%;
    padding: 0 5px;
    color: ${(p) => p.theme.colors.text.primary};
    ${OutfitMedium};
    text-align: center;
  }

  ${deviceQuery.mobileL} {
    min-width: 0;
  }
`;

export const KeyBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${(p) => p.theme.colors.field.full};
  padding: 5px 10px;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;

  .icon {
    cursor: pointer;
    color: ${(p) => p.theme.colors.icon.third};
  }

  p {
    ${oneLineText};
    word-break: break-all;
    color: ${(p) => p.theme.colors.text.primary};
  }
`;
