import { FC } from "react";

import { HeaderContainer } from "./style";

import ArrowLeft from "assets/svg/ArrowLeft";

interface Props {
  mobile?: boolean;
  backgroundSelected?: boolean;
  theme: string;
}

const Header: FC<Props> = ({ mobile, backgroundSelected = false, theme }) => (
  <HeaderContainer mobile={mobile} backgroundSelected={backgroundSelected}>
    <ArrowLeft size={mobile ? 16 : 24} />
    <img
      className="cursor-pointer"
      src={theme !== "light" ? "/logoLight.svg" : "/logoDark.svg"}
      alt="LEWK"
    />
  </HeaderContainer>
);

export default Header;
