import styled from "styled-components";

export const LanguageSelectContainer = styled.div<{ show?: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  color: ${(p) => p.theme.colors.text.primary};

  .item {
    display: flex;
    padding: 16px;
    gap: 12px;

    .itemContent {
      display: flex;
      gap: 12px;
      align-items: center;
      flex: 1;
    }

    .action {
      align-items: center;
      gap: 12px;
      display: flex;

      .selected {
        color: ${(p) => p.theme.colors.text.secondary};
      }

      .arrow {
        transition: all 0.3s;
        align-items: center;
        display: flex;
        justify-content: center;
        transform: rotate(${(p) => (p.show ? "180deg" : "0deg")});
      }
    }
  }

  .options {
    padding: ${(p) => (p.show ? "16px" : "0px 16px")};
    display: grid;
    border-top: ${(p) => (p.show ? `1px solid ${p.theme.colors.divider}` : "none")};
    grid-template-rows: ${(p) => (p.show ? 1 : 0)}fr;
    transition: all 0.3s ease-in-out;

    .expandable {
      transition: all 0.3s ease-in-out;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .option {
        display: flex;
        justify-content: space-between;

        .icon {
          display: none;
        }

        &.active {
          color: ${(p) => p.theme.colors.text.third};

          .icon {
            display: flex;
          }
        }

        &:hover {
          color: ${(p) => p.theme.colors.text.third};
        }
      }
    }
  }

  &:hover {
    background: ${(p) => p.theme.colors.background.primary};
  }
`;
