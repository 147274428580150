import { FC } from "react";

interface Props {
  size?: number;
}

const Donut: FC<Props> = ({ size = 32 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.8207 5.23C24.7607 3.15 21.8707 2.01 18.6907 2H18.6507C14.5507 2 10.3407 3.84 7.1107 7.06C1.1507 13 0.280703 21.84 5.1707 26.77C5.4607 27.06 5.7607 27.32 6.0707 27.57C6.0807 27.58 6.1007 27.59 6.1107 27.6C8.0707 29.14 10.5707 29.99 13.2907 30H13.3307C17.4307 30 21.6407 28.16 24.8707 24.94C30.8307 19 31.7007 10.16 26.8107 5.23H26.8207ZM7.8207 7.77C10.8607 4.74 14.8107 3 18.6507 3H18.6907C21.4407 3 23.9207 3.94 25.7707 5.63C25.4207 5.93 25.0207 6.47 24.7607 7.43C24.5407 8.24 24.7507 8.98 24.9307 9.62C25.1507 10.37 25.3207 10.97 24.8807 11.63C24.4807 12.22 23.9607 12.59 23.4607 12.94C22.7807 13.43 22.0707 13.93 21.9607 14.91C21.8407 15.98 22.4807 17.21 23.1007 18.4C23.5607 19.29 24.1907 20.52 23.8907 20.83C23.6007 21.12 22.2907 20.79 21.3207 20.56C19.6707 20.15 17.7907 19.69 16.6907 20.6C15.7507 21.37 15.5707 22.37 15.4107 23.24C15.2407 24.16 15.1107 24.88 14.2507 25.39C13.4807 25.85 12.8907 25.55 11.9107 24.99C11.3407 24.66 10.6907 24.29 9.9507 24.19C7.7907 23.88 6.6407 25.17 6.1507 26.3C6.0707 26.22 5.9807 26.15 5.9007 26.07C1.3907 21.53 2.2607 13.32 7.8307 7.77H7.8207ZM24.1707 24.23C21.1307 27.26 17.1807 29 13.3407 29H13.3007C10.9007 29 8.7007 28.27 6.9507 26.97C7.1607 26.31 7.8407 24.91 9.8007 25.17C10.3507 25.25 10.8607 25.54 11.4007 25.85C12.3507 26.4 13.4307 27.02 14.7507 26.24C16.0007 25.5 16.2107 24.39 16.3807 23.41C16.5307 22.6 16.6607 21.9 17.3107 21.36C18.0307 20.77 19.6407 21.17 21.0707 21.52C22.5807 21.9 23.8907 22.22 24.5807 21.53C25.4107 20.71 24.7107 19.36 23.9807 17.94C23.4607 16.93 22.8707 15.78 22.9507 15.01C23.0007 14.5 23.3807 14.22 24.0307 13.75C24.5807 13.36 25.2007 12.92 25.7007 12.18C26.4007 11.14 26.1307 10.18 25.8907 9.34C25.7307 8.77 25.5707 8.23 25.7207 7.68C25.9407 6.86 26.2607 6.5 26.4707 6.34C30.5507 10.95 29.5907 18.84 24.1807 24.22L24.1707 24.23Z"
      fill="currentColor"
    />
    <path
      d="M11.2505 18.92C11.4005 18.92 11.5705 18.89 11.7205 18.88C11.7405 18.88 11.7605 18.89 11.7905 18.89C11.8505 18.89 11.9105 18.88 11.9705 18.85C12.0705 18.84 12.1605 18.85 12.2605 18.82C13.7505 18.55 15.2605 17.72 16.5005 16.49C17.8405 15.16 18.6505 13.56 18.8705 12.06C18.9005 11.99 18.9005 11.92 18.9005 11.84C19.0505 10.5 18.7305 9.26 17.8705 8.4C17.6805 8.2 17.3605 8.2 17.1605 8.4C16.9605 8.59 16.9605 8.91 17.1605 9.11C17.6705 9.62 17.9105 10.32 17.9405 11.1C17.4405 10.98 16.8505 11.01 16.1805 11.25C15.2105 11.59 14.1705 12.29 13.2405 13.21C11.7705 14.67 10.7105 16.56 10.9905 17.89C10.2405 17.84 9.59051 17.59 9.13051 17.12C8.94051 16.92 8.62051 16.92 8.42051 17.12C8.22051 17.31 8.22051 17.63 8.42051 17.83C9.14051 18.55 10.1205 18.93 11.2605 18.93L11.2505 18.92ZM13.9405 13.92C14.7605 13.1 15.6705 12.49 16.5105 12.2C17.0905 12 17.5605 11.98 17.8605 12.13C17.6205 13.35 16.9205 14.67 15.7905 15.79C14.6905 16.88 13.3805 17.61 12.0805 17.84C12.0605 17.84 12.0505 17.84 12.0305 17.84C11.7205 17.14 12.3505 15.49 13.9305 13.92H13.9405Z"
      fill="currentColor"
    />
    <path
      d="M8.90016 9.65917L10.3302 9.84917C10.3302 9.84917 10.3702 9.84917 10.4002 9.84917C10.6502 9.84917 10.8602 9.66917 10.8902 9.41917C10.9302 9.14917 10.7402 8.88917 10.4602 8.85917L9.03015 8.66917C8.76015 8.62917 8.50016 8.82917 8.47016 9.09917C8.43016 9.36917 8.62016 9.62917 8.90016 9.65917Z"
      fill="currentColor"
    />
    <path
      d="M22.1112 10.829C22.1712 11.049 22.3712 11.189 22.5912 11.189C22.6312 11.189 22.6812 11.189 22.7312 11.169C23.0012 11.089 23.1512 10.819 23.0812 10.549L22.6512 9.03901C22.5812 8.76901 22.3012 8.61901 22.0312 8.68901C21.7612 8.76901 21.6112 9.03901 21.6812 9.30901L22.1112 10.819V10.829Z"
      fill="currentColor"
    />
    <path
      d="M17.8106 18.0662C17.8806 18.2762 18.0706 18.4162 18.2906 18.4162C18.3406 18.4162 18.3906 18.4162 18.4406 18.3962L19.9106 17.9462C20.1706 17.8662 20.3206 17.5862 20.2406 17.3162C20.1606 17.0562 19.8906 16.8962 19.6106 16.9862L18.1406 17.4362C17.8806 17.5162 17.7306 17.7962 17.8106 18.0662Z"
      fill="currentColor"
    />
    <path
      d="M6.5207 15.3714C6.6807 15.1414 6.6207 14.8314 6.3907 14.6714C6.1607 14.5214 5.8507 14.5714 5.6907 14.8014L4.7207 16.2214C4.5607 16.4514 4.6207 16.7614 4.8507 16.9214C4.9407 16.9814 5.0307 17.0114 5.1307 17.0114C5.2907 17.0114 5.4507 16.9314 5.5407 16.7914L6.5107 15.3714H6.5207Z"
      fill="currentColor"
    />
    <path
      d="M12.8406 21.0234C12.7706 20.7534 12.4906 20.6034 12.2206 20.6734C11.9506 20.7534 11.8006 21.0234 11.8706 21.2934L12.3006 22.8034C12.3606 23.0234 12.5606 23.1634 12.7806 23.1634C12.8206 23.1634 12.8706 23.1634 12.9206 23.1434C13.1906 23.0634 13.3406 22.7934 13.2706 22.5234L12.8406 21.0134V21.0234Z"
      fill="currentColor"
    />
    <path
      d="M16.3207 5.84369C16.3207 5.84369 16.4207 5.84369 16.4707 5.82369L17.9406 5.37369C18.2006 5.29369 18.3507 5.01369 18.2707 4.75369C18.1907 4.49369 17.9107 4.33369 17.6507 4.42369L16.1807 4.87369C15.9207 4.95369 15.7706 5.23369 15.8506 5.49369C15.9206 5.71369 16.1107 5.84369 16.3307 5.84369H16.3207Z"
      fill="currentColor"
    />
    <path
      d="M7.37117 22.0624C7.37117 22.0624 7.41117 22.0624 7.44117 22.0624C7.69117 22.0624 7.90117 21.8824 7.93117 21.6324C7.97117 21.3624 7.78117 21.1024 7.50117 21.0724L6.07117 20.8824C5.79117 20.8324 5.54117 21.0424 5.51117 21.3124C5.47117 21.5824 5.66117 21.8424 5.94117 21.8724L7.37117 22.0624Z"
      fill="currentColor"
    />
  </svg>
);

export default Donut;
