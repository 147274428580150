import { ChangeEvent, memo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import BioBlock from "./components/BioBlock";

import GiftButton from "./components/GiftButton";
import { BioCustomizationContainer } from "./style";

import { BioPageContainer } from "../style";

import TrackGoogleAnalyticsEvent from "common/googleAnalytics";
import { dataURLtoFile, makeToast } from "common/helpers/helper";
import CropModal from "Components/CropModal";
import { STRINGS } from "constants/appConstants";
import titles from "constants/titles";
import useTitle from "customHooks/useTitle";
import ChangeTheme from "pages/creator/EditProfile/components/General/components/ChangeTheme";
import { updateProfileInfoAPI, updateProfilePic } from "services/UserServices";
import { setLoader } from "store/slices/featureSlice";
import { getUser } from "store/slices/global/userSlice";
import { NewCommonWrapper } from "styles/mixins";

const BioCustomization = memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useTitle(titles.customization);

  const user = useSelector(getUser);

  const ref = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState("");
  const [showCrop, setShowCrop] = useState(false);

  const handleSubmitBanner = (img: string) => {
    dispatch(setLoader({ name: "editProfileLoader", value: true }));
    const picture = dataURLtoFile(img, Date.now().toString());

    const mediaData = new FormData();
    mediaData.append("image", picture);

    updateProfilePic({ apiData: mediaData }, (res) => {
      updateProfileInfoAPI(
        {
          data: {
            banner: { image: res.key },
          },
          part: "customizations",
        },
        () => navigate("../../../preview/banner")
      );
    });
  };

  const handleDeleteBanner = () => {
    dispatch(setLoader({ name: "editProfileLoader", value: true }));
    TrackGoogleAnalyticsEvent("Bio Customization", "banner_delete");

    updateProfileInfoAPI({
      data: {
        banner: null,
      },
      part: "customizations",
    });
  };
  const handleDeleteWallpaper = () => {
    dispatch(setLoader({ name: "editProfileLoader", value: true }));
    TrackGoogleAnalyticsEvent("Bio Customization", "background_delete");

    updateProfileInfoAPI({
      data: {
        wallpaper: null,
      },
      part: "customizations",
    });
  };

  const handleChangeBanner = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const img = event.target.files[0] as File;
      const eggFileSize = img.size / 1024 / 1024;
      const extension = img.name.split(".").pop()!;
      const fileExtension = extension.toLowerCase();
      if (eggFileSize < 6) {
        if (
          fileExtension === "png" ||
          fileExtension === "jpeg" ||
          fileExtension === "jpg" ||
          fileExtension === "gif"
        ) {
          const reader = new FileReader();
          reader.onload = () => {
            setImage(reader.result as any);
          };
          reader.readAsDataURL(img);
          setShowCrop(true);
        } else {
          makeToast({
            message: t("common.file_upload.pic_type_warning_cap"),
            type: STRINGS.toastType.error,
            duration: 1000,
          });
        }
      } else {
        makeToast({
          message: t("common.file_upload.upload_size_warning_6"),
          type: STRINGS.toastType.error,
          duration: 1000,
        });
      }
      if (ref.current) {
        ref.current.value = "";
      }
    }
  };

  const handleClickBanner = () => {
    ref.current && ref.current.click();
  };

  return (
    <NewCommonWrapper withoutFilter>
      <BioPageContainer>
        <div className="content">
          <p className="pageTitle">{t("common.customization")}</p>
          <BioCustomizationContainer>
            <ChangeTheme bio />
            <GiftButton />
            <BioBlock
              title={t("common.banner")}
              description={t("common.bannerRules")}
              buttonText={t("common.upload_photo")}
              type="banner"
              onClick={handleClickBanner}
              onEdit={handleClickBanner}
              onDelete={handleDeleteBanner}
              onPreview={() => navigate("../../../preview/banner", { state: { preview: true } })}
            />
            <input
              className="inputBanner"
              type="file"
              ref={ref}
              accept="image/png, image/jpeg, image/jpg, image/gif"
              onChange={handleChangeBanner}
            />
            <BioBlock
              title={t("common.background")}
              description={t("common.backgroundRules")}
              buttonText={t("common.select")}
              buttonsDisabled={Boolean(user?.wallpaper?.pattern?.customIconUrl)}
              errorMessage={
                user?.wallpaper?.pattern?.customIconUrl
                  ? t("common.customIconUsedErrorMessage")
                  : ""
              }
              type="wallpaper"
              onClick={() => navigate("../../../preview/wallpaper")}
              onDelete={handleDeleteWallpaper}
              onEdit={() => navigate("../../../preview/wallpaper")}
              onPreview={() => navigate("../../../preview/wallpaper", { state: { preview: true } })}
            />
          </BioCustomizationContainer>
        </div>
      </BioPageContainer>
      {showCrop && (
        <CropModal
          aspectHeight={1}
          aspectWidth={6}
          maxWidth={2048}
          minWidth={1200}
          descriptionHeight={t("common.maxHeightBanner")}
          descriptionWidth={t("common.maxWidthBanner")}
          areaDesc={t("common.desktopBanner")}
          areaDescMobile={t("common.mobileBanner")}
          show={showCrop}
          image={image}
          closeModal={() => setShowCrop(false)}
          onConfirm={(img) => handleSubmitBanner(img)}
        />
      )}
    </NewCommonWrapper>
  );
});

export default BioCustomization;
