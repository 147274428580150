import Header from "./components/Header";
import OnBoardingList from "./components/OnBoardingList";

import { OnboardingContainer } from "./style";

import titles from "constants/titles";
import useTitle from "customHooks/useTitle";

const OnBoarding = () => {
  useTitle(titles.onboarding);

  return (
    <>
      <Header />
      <OnboardingContainer>
        <OnBoardingList />
      </OnboardingContainer>
    </>
  );
};

export default OnBoarding;
