interface ILocalStorageItem {
  key: string;
  value: string | boolean | number;
}

interface ILocalStorageKey {
  key: string;
}

/**
 * sets a value in localStorage under the specified key
 *
 * @param {ILocalStorageItem} item - object containing the key and value to store in localStorage
 * @returns {void}
 */
export const setLocalStorageItem = ({ key, value }: ILocalStorageItem): void => {
  const stringify = JSON.stringify(value);
  localStorage.setItem(key, stringify);
};

/**
 * sets multiple values in localStorage.
 *
 * @param {ILocalStorageItem[]} arr - array of objects, each containing a key and value to store in localStorage
 * @returns {void}
 */
export const setLocalStorageItems = (arr: ILocalStorageItem[]): void => {
  arr.forEach((item) => {
    setLocalStorageItem({ key: item.key, value: item.value });
  });
};

/**
 * removes an item from localStorage by the specified key.
 *
 * @param {ILocalStorageKey} item - object containing the key of the item to remove from localStorage
 * @returns {void}
 */
export const removeLocalStorageItem = ({ key }: ILocalStorageKey): void =>
  localStorage.removeItem(key);

/**
 * retrieves a value from localStorage by the specified key.
 *
 * @param {ILocalStorageKey} item - object containing the key of the item to retrieve from localStorage
 * @returns {string | null} retrieved value as a string or null if the key does not exist
 */
export const getLocalStorageItem = ({ key }: ILocalStorageKey): string => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

/**
 * retrieves multiple values from localStorage by their specified keys.
 *
 * @param {ILocalStorageKey[]} arr - array of objects, each containing a key of the items to retrieve from localStorage
 * @returns {string[]} array of retrieved values as strings. If a key does not exist, its corresponding value in the array will be null
 */
export const getLocalStorageItems = (arr: ILocalStorageKey[]): string[] =>
  arr.map((item) => getLocalStorageItem({ key: item.key }));
