/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import { FieldArray, FormikErrors, FormikProvider, useFormik } from "formik";
import { FC, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";

import * as Yup from "yup";

import { ShowContent } from "./ShowContent";
import { AddMessageContentContainerModalStyles, Content } from "./style";

import InfoCircle from "assets/svg/InfoCircle";
import { getFileSizeInMb, getMediaTypeFromName } from "common/helpers/file-helpers";
import { makeToast } from "common/helpers/helper";
import ConfirmModal from "Components/ConfirmModal";
import { Option } from "Components/SelectField/types";
import { STRINGS } from "constants/appConstants";
import { ContentType, messageContentSupportedExtensions } from "constants/file-extensions";
import {
  MESSAGES_MAX_AUDIO_SIZE,
  MESSAGES_MAX_IMAGE_SIZE,
  MESSAGES_MAX_VIDEO_SIZE,
} from "constants/file-sizes";
import { ContentMessagePreview } from "pages/public/chat/Components/Chat/AddContentModal/ContentMessagePreview";
import { ContentPrice } from "pages/public/chat/Components/Chat/AddContentModal/ContentPrice";
import { EmptyContentInput } from "pages/public/chat/Components/Chat/AddContentModal/EmptyContentInput";
import { UploadContentInput } from "pages/public/chat/Components/Chat/AddContentModal/UploadContentInput";
import {
  AddContentModalSendData,
  ContentItem,
  NewMessageImageMediaContent,
  NewMessagePlayableMediaContent,
} from "types/new-messages.types";

const MAX_CONTENT_AMOUNT = 6;

export interface AddContentModalForm {
  contentPreview: File | undefined;
  content: ContentItem[];
  price: number | undefined;
  currency?: Option;
}

interface Props {
  isPaid?: boolean;
  content?: (NewMessageImageMediaContent | NewMessagePlayableMediaContent)[];
  show: boolean;
  onCloseModal: (resetForm?: () => void, content?: AddContentModalSendData) => void;
  openModal: () => void;
}

export const AddContentModal: FC<Props> = ({
  onCloseModal,
  openModal,
  show,
  content,
  isPaid = false,
}) => {
  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);

  // to do add schema for validation
  // first validation for same file name existence
  // agree on length,
  const schema = Yup.object().shape({
    currency: Yup.mixed().test(
      "currency",
      t("common.required"),
      (item: Option | undefined, ctx) => {
        return !(ctx.parent.price && Number(ctx.parent.price) && !item?.value);
      }
    ),
    contentPreview: Yup.mixed().test(
      "preview",
      t("new_chat.add_content_modal.overAudio"),
      (file: File | undefined) => {
        if (file) {
          return Boolean(file.size < MESSAGES_MAX_IMAGE_SIZE);
        }
        return true;
      }
    ),
    content: Yup.array()
      .of(
        Yup.object().shape({
          file: Yup.mixed()
            .test("file", t("common.required"), (value) => {
              return Boolean(value);
            })
            .test(
              "image",
              t("new_chat.add_content_modal.overAudio"),
              (file: File | undefined, ctx) => {
                if (!file) return false;
                const fileType = (ctx.parent as ContentItem).fileType;

                if (fileType === "image") {
                  return Boolean(file.size < MESSAGES_MAX_IMAGE_SIZE);
                }
                return true;
              }
            )
            .test(
              "video",
              t("new_chat.add_content_modal.overVideo"),
              (file: File | undefined, ctx) => {
                if (!file) return false;
                const fileType = (ctx.parent as ContentItem).fileType;

                if (fileType === "video") {
                  return Boolean(file.size < MESSAGES_MAX_VIDEO_SIZE);
                }
                return true;
              }
            )
            .test(
              "video",
              t("new_chat.add_content_modal.overAudio"),
              (file: File | undefined, ctx) => {
                if (!file) return false;
                const fileType = (ctx.parent as ContentItem).fileType;

                if (fileType === "audio") {
                  return Boolean(file.size < MESSAGES_MAX_AUDIO_SIZE);
                }
                return true;
              }
            ),
        })
      )
      .min(1, "Min 1 file")
      .max(6, "Max 6 files"),
  });

  const form = useFormik<AddContentModalForm>({
    enableReinitialize: true,
    validationSchema: schema,
    initialValues: {
      contentPreview: undefined,
      content: [],
      price: 0,
      currency: undefined,
    },
    onSubmit: (values) => {
      const formattedValues: AddContentModalSendData = {
        ...values,
        currency: values.currency ? (values.currency.value as string) : undefined,
        price: values.price ? +values.price : 0,
        content: values.content.map((content) => content.file),
      };
      onCloseModal(() => {
        resetForm();
      }, formattedValues);
    },
  });

  const { values, setFieldValue, handleSubmit, errors, validateField, resetForm } = form;

  const getErrorMessage = (
    errors: FormikErrors<ContentItem>[],
    index: number
  ): string | undefined => {
    if (errors) {
      return errors[index]?.file as string;
    }
  };

  const getMBSizeSumByType = useCallback(
    (type: ContentType, content: ContentItem[]): string => {
      const sumInBytes = content.reduce((acc, next) => {
        return acc + (next.fileType === type ? next.file.size : 0);
      }, 0);
      const sizeInMb = getFileSizeInMb(sumInBytes);
      return `${sizeInMb.toFixed(2)}`;
    },
    [values.content]
  );

  const getFilesCountByType = useCallback(
    (type: ContentType, content: ContentItem[]): number => {
      return content.filter((content) => content.fileType === type).length;
    },
    [values.content]
  );

  const handleConfirm = () => {
    if (Object.entries(errors).length) {
      if (values.content.length === 0) {
        makeToast({
          message: t("new_chat.add_content_modal.lengthError"),
          type: STRINGS.toastType.error,
        });
      } else if (values.price && +values.price > 0 && !values.currency) {
        makeToast({
          message: t("new_chat.add_content_modal.currencyError"),
          type: STRINGS.toastType.error,
        });
      } else {
        onCloseModal();
        setShowConfirm(true);
      }
    } else {
      handleSubmit();
    }
  };

  return (
    <FormikProvider value={form}>
      <AddMessageContentContainerModalStyles
        show={show}
        onHide={() =>
          onCloseModal(() => {
            resetForm();
          })
        }
        showCloseButton
        padding="20px"
      >
        <div className="title">
          <p>{isPaid ? t("new_chat.details") : t("new_chat.add_content_modal.title")}</p>
        </div>

        <div className="divider" />
        <Content>
          {!isPaid && (
            <>
              <div className="content_preview_block">
                <ContentMessagePreview
                  file={values.contentPreview}
                  onPreviewChange={(file) => {
                    setFieldValue("contentPreview", file);
                  }}
                  onPreviewRemove={() => {
                    setFieldValue("contentPreview", undefined);
                  }}
                  sumOfImagesSize={getMBSizeSumByType("image", values.content)}
                  sumOfVideosSize={getMBSizeSumByType("video", values.content)}
                  sumOfAudiosSize={getMBSizeSumByType("audio", values.content)}
                  imageFilesCount={getFilesCountByType("image", values.content)}
                  videoFilesCount={getFilesCountByType("video", values.content)}
                  audioFilesCount={getFilesCountByType("audio", values.content)}
                />
                <div className="content_preview_description">
                  <p>{t("new_chat.add_content_modal.preview_title")}</p>
                  <span>{t("new_chat.add_content_modal.preview_description")}</span>
                </div>
              </div>
              <div className="divider" />
            </>
          )}
          <div className="uploaded_content_list">
            {isPaid && content ? (
              content.map((item) => <ShowContent key={item._id} item={item} />)
            ) : (
              <FieldArray
                name="content"
                render={(arrayHelpers) => (
                  <>
                    {values.content.map((content, index) => (
                      <UploadContentInput
                        key={`${content.file.name}-${index}`}
                        file={content.file}
                        hasError={Boolean(errors.content?.[index])}
                        error={getErrorMessage(
                          errors.content as FormikErrors<ContentItem>[],
                          index
                        )}
                        onRemove={() => {
                          arrayHelpers.remove(index);
                        }}
                        onFileChange={(file) => {
                          const fileType = getMediaTypeFromName(file.name);
                          if (!fileType) return;
                          const content: ContentItem = { file, fileType };
                          arrayHelpers.replace(index, content);
                          validateField(`content[${index}]`);
                        }}
                      />
                    ))}

                    {values.content.length < MAX_CONTENT_AMOUNT && (
                      <EmptyContentInput
                        className="add_new_content_btn"
                        buttonText={t("new_chat.add_content_modal.add_content")}
                        acceptExtensions={messageContentSupportedExtensions.join(",")}
                        onFileSelected={(file) => {
                          const fileType = getMediaTypeFromName(file.name);
                          if (!fileType) return;
                          const content: ContentItem = { file, fileType };
                          arrayHelpers.push(content);
                        }}
                      />
                    )}
                  </>
                )}
              />
            )}
          </div>

          {!isPaid && (
            <>
              <div className="divider" />
              <ContentPrice sendContent={handleConfirm} />
            </>
          )}
        </Content>
      </AddMessageContentContainerModalStyles>
      <ConfirmModal
        size="sm"
        show={showConfirm}
        Icon={<InfoCircle />}
        onConfirmDelete={() => {
          setShowConfirm(false);
          openModal();
        }}
        onCancel={() => {
          resetForm();
          setShowConfirm(false);
        }}
        cancelBtnText={t("common.cancel")}
        deleteBtnText={t("new_chat.add_content_modal.reload")}
        closeModal={() => setShowConfirm(false)}
        title={t("new_chat.add_content_modal.errorTitle")}
        description={t("new_chat.add_content_modal.errorDesc")}
      />
    </FormikProvider>
  );
};
