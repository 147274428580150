interface Props {
  className?: string;
}
const ArrowDown = ({ className }: Props) => (
  <svg
    width="24"
    height="24"
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.24106 8.7459C4.53326 8.44784 4.99051 8.42074 5.31272 8.66461L5.40503 8.7459L12 15.4734L18.595 8.7459C18.8872 8.44784 19.3444 8.42074 19.6666 8.66461L19.7589 8.7459C20.0511 9.04396 20.0777 9.51037 19.8386 9.83904L19.7589 9.93321L12.582 17.2541C12.2898 17.5522 11.8325 17.5793 11.5103 17.3354L11.418 17.2541L4.24106 9.93321C3.91965 9.60534 3.91965 9.07376 4.24106 8.7459Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowDown;
