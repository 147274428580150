import { useEffect, useState } from "react";

import { LandingCardsContainer } from "./style";

const DELAY_IN_FLIP = 3000;
const DELAY_FOR_VS_ICON = 600;

const LandingCards = () => {
  const [active, setActive] = useState<boolean>(false);
  const [vsActive, setVsActive] = useState<boolean>(false);

  const setVsIconActiveWithTimeout = () => {
    setVsActive((prev) => !prev);
    setTimeout(() => {
      setVsActive((prev) => !prev);
    }, DELAY_FOR_VS_ICON);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setActive((prev) => !prev);
      setVsIconActiveWithTimeout();
    }, DELAY_IN_FLIP);
    return () => {
      clearTimeout(timeout);
    };
  }, [active]);

  return (
    <LandingCardsContainer>
      <div className="bg-cards" />
      <div
        className="box"
        onClick={() => {
          setActive((prev) => !prev);
          setVsIconActiveWithTimeout();
        }}
      >
        <div className="flip-card left_card_container">
          <div className={`flip-card-inner left_card ${active && "left_active"}`}>
            <div className="flip-card-front">
              <img src="/home_card_1_front.png" alt="Avatar" />
            </div>
            <div className="flip-card-back flip-card-back_left">
              <img src="/home_card_1_back.png" alt="Avatar" />
            </div>
          </div>
        </div>

        <div className={`vs_icon ${vsActive && "vs_icon_active"}`}>
          <img src="/vs.svg" alt="vs" />
        </div>

        <div className="flip-card right_card_container">
          <div className={`flip-card-inner right_card ${active && "right_active"}`}>
            <div className="flip-card-front">
              <img src="/home_card_2_front.png" alt="Avatar" />
            </div>
            <div className="flip-card-back">
              <img src="/home_card_2_back.png" alt="Avatar" />
            </div>
          </div>
        </div>
      </div>
    </LandingCardsContainer>
  );
};

export default LandingCards;
