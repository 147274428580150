import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AddContentModal } from "../AddContentModal";

import PayModal from "../PayModal";

import CirclePlay from "assets/svg/CirclePlay";
import { ImageOutlined } from "assets/svg/ImageOutLined";
import Lock from "assets/svg/Lock";
import Sound from "assets/svg/Sound";
import Unlock from "assets/svg/Unlock";
import { getMinutesDurationFromSeconds } from "common/helpers/helpers";
import Button from "Components/Button";
import { ContentInfoItem } from "pages/public/chat/Components/Chat/ContentInfoitem";
import { ContentMessageStyles } from "pages/public/chat/Components/Chat/ContentMessage/styles";
import { getMediaCardData } from "services/newMessagesService";
import { getOpenedChat } from "store/slices/global/chatsSlice";
import { getTokenGroups } from "store/slices/walletSlice";
import {
  NewMessageImageMediaContent,
  NewMessageItem,
  NewMessagePlayableMediaContent,
} from "types/new-messages.types";

interface Props {
  contentMessage: NewMessageItem;
}

export const ContentMessage: FC<Props> = ({ contentMessage }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [showPay, setShowPay] = useState(false);
  const openedChat = useSelector(getOpenedChat);
  const tokenGroups = useSelector(getTokenGroups);
  const [content, setContent] = useState<
    (NewMessageImageMediaContent | NewMessagePlayableMediaContent)[]
  >([]);

  if (!contentMessage.mediaMetadata) return null;

  const handleClick = () => {
    if (openedChat?.anotherUser.role === "fan" || !contentMessage.price || contentMessage.isPaid) {
      getMediaCardData(contentMessage.chatId, contentMessage._id, (content) => {
        setShow(true);
        setContent(content);
      });
    } else {
      setShowPay(true);
    }
  };

  return (
    <>
      <ContentMessageStyles onClick={handleClick}>
        <div
          className="thumbnail"
          style={{ backgroundImage: `url(${contentMessage.mediaCardPreview?.url})` }}
        >
          {!contentMessage.mediaCardPreview?.url && !("price" in contentMessage) ? (
            <ImageOutlined className="empty_icon" />
          ) : "price" in contentMessage && !contentMessage.isPaid ? (
            <Lock className="lock_icon" size={24} />
          ) : "price" in contentMessage && contentMessage.isPaid ? (
            <Unlock className="lock_icon" size={21} />
          ) : (
            <></>
          )}
        </div>
        <div className="content_info">
          <div className="content_items">
            {!!contentMessage.mediaMetadata.filesCount.image && (
              <ContentInfoItem
                icon={<ImageOutlined className="content_info_icon" />}
                contentCount={contentMessage.mediaMetadata.filesCount.image}
              />
            )}
            {!!contentMessage.mediaMetadata.filesCount.video && (
              <ContentInfoItem
                icon={<CirclePlay className="content_info_icon" />}
                contentInfo={getMinutesDurationFromSeconds(
                  contentMessage.mediaMetadata.filesDuration.video
                )}
                contentCount={contentMessage.mediaMetadata.filesCount.video}
              />
            )}
            {!!contentMessage.mediaMetadata.filesCount.audio && (
              <ContentInfoItem
                icon={<Sound className="content_info_icon" />}
                contentInfo={getMinutesDurationFromSeconds(
                  contentMessage.mediaMetadata.filesDuration.audio
                )}
                contentCount={contentMessage.mediaMetadata.filesCount.audio}
              />
            )}
          </div>
          <Button
            variant={
              !("price" in contentMessage) || contentMessage.isPaid ? "secondary" : "primary"
            }
            className="action_button"
          >
            {"price" in contentMessage ? (
              <>
                {contentMessage.isPaid
                  ? t("wallet.paid")
                  : `${t("new_chat.unlockFor")} ${contentMessage.price} 
                ${tokenGroups[contentMessage.tokenGroup!]}`}
              </>
            ) : (
              <>{t("common.free")}</>
            )}
          </Button>
        </div>
      </ContentMessageStyles>
      <AddContentModal
        show={show}
        onCloseModal={() => setShow(false)}
        content={content}
        isPaid
        openModal={() => setShow(true)}
      />
      <PayModal show={showPay} setShowModal={() => setShowPay(false)} message={contentMessage} />
    </>
  );
};
