const Edit = () => (
  <svg width="24" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5898 19.8008H20.9698"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7498 4.85963C13.926 4.65753 14.141 4.49292 14.382 4.37557C14.6231 4.25823 14.8853 4.19055 15.153 4.17655C15.4208 4.16256 15.6886 4.20254 15.9405 4.29412C16.1925 4.38569 16.4235 4.527 16.6198 4.70963L18.0098 5.79963C18.2336 5.94659 18.4257 6.137 18.5746 6.35958C18.7234 6.58216 18.8261 6.83237 18.8765 7.09537C18.9268 7.35837 18.9239 7.6288 18.8678 7.89064C18.8116 8.15247 18.7035 8.40037 18.5498 8.61963L10.2498 19.1896C10.1132 19.3649 9.93888 19.5071 9.7398 19.6058C9.54072 19.7045 9.32197 19.757 9.09979 19.7596H5.89979L5.17979 16.6496C5.13058 16.4336 5.13017 16.2092 5.17861 15.993C5.22705 15.7768 5.32311 15.574 5.45979 15.3996L13.7498 4.85963Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1895 6.83984L16.9795 10.5998"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Edit;
