import { FC } from "react";

interface Props {
  size?: number;
}

const Cat: FC<Props> = ({ size = 32 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.15959 13.0493C5.26959 13.0493 5.36959 13.0193 5.46959 12.9493C5.47959 12.9393 6.80959 11.9193 8.14959 11.4593C8.73959 11.2493 9.25959 11.1293 9.71959 11.0093C10.5796 10.7993 11.1996 10.6393 11.4296 10.1293C11.6396 9.66935 11.3896 9.19935 11.3096 9.03935C10.0596 6.60935 7.98959 3.84935 5.89959 3.39935C5.62959 3.34935 5.35959 3.51935 5.30959 3.77935C5.24959 4.04935 5.42959 4.31935 5.68959 4.36935C7.32959 4.71935 9.24959 7.18935 10.4196 9.47935C10.4896 9.60935 10.4996 9.67935 10.5196 9.68935C10.3996 9.79935 9.88959 9.91935 9.47959 10.0193C9.01959 10.1293 8.44959 10.2693 7.81959 10.4893C6.31959 11.0093 4.90959 12.0893 4.84959 12.1393C4.62959 12.3093 4.58959 12.6193 4.75959 12.8393C4.85959 12.9693 5.00959 13.0393 5.15959 13.0393V13.0493Z"
      fill="currentColor"
    />
    <path
      d="M22.2001 10.9841C22.6901 11.1041 23.2401 11.2341 23.8501 11.4441C25.1901 11.9041 26.5201 12.9241 26.5301 12.9341C26.6201 13.0041 26.7301 13.0341 26.8401 13.0341C26.9901 13.0341 27.1401 12.9641 27.2401 12.8341C27.4101 12.6141 27.3701 12.3041 27.1501 12.1341C27.0901 12.0841 25.6801 11.0041 24.1801 10.4841C23.5201 10.2541 22.9201 10.1141 22.4301 9.9941C21.9801 9.8841 21.4201 9.7541 21.3401 9.6841C21.3401 9.6841 21.3401 9.6241 21.4201 9.46411C22.5801 7.19411 24.6301 4.7041 26.3001 4.3541C26.5701 4.2941 26.7401 4.0341 26.6801 3.7641C26.6201 3.4941 26.3501 3.3341 26.0901 3.3841C24.0101 3.8241 21.8001 6.5341 20.5201 9.0241C20.4401 9.1741 20.2101 9.63411 20.4201 10.0941C20.6601 10.6241 21.3101 10.7741 22.2001 10.9941V10.9841Z"
      fill="currentColor"
    />
    <path
      d="M12.9902 18.6406C13.1402 18.6406 13.2902 18.5706 13.3802 18.4506C13.5402 18.2406 13.5102 17.9406 13.3102 17.7706C11.7902 16.4506 9.50017 15.9806 7.32017 16.5306C6.92017 16.6306 6.69017 17.0106 6.78017 17.4106C6.87017 17.8106 7.25017 18.0606 7.65017 17.9706C7.80017 17.9406 7.97017 17.9006 8.15017 17.8506C9.25017 17.5706 10.7502 17.1906 12.7002 18.5506C12.7902 18.6106 12.8902 18.6406 12.9902 18.6406Z"
      fill="currentColor"
    />
    <path
      d="M8.94044 22.198C8.94044 22.198 6.63044 22.898 4.40044 22.198C4.14044 22.108 3.86044 22.258 3.77044 22.528C3.69044 22.788 3.83044 23.068 4.10044 23.158C4.97044 23.428 5.82044 23.518 6.59044 23.518C8.07044 23.518 9.17044 23.178 9.24044 23.158C9.50044 23.078 9.65044 22.798 9.57044 22.528C9.49044 22.268 9.21044 22.118 8.94044 22.198Z"
      fill="currentColor"
    />
    <path
      d="M4.96063 20.3999C4.83063 20.6399 4.92063 20.9499 5.17063 21.0799C6.12063 21.5799 7.14063 21.6999 7.91063 21.6999C8.58063 21.6999 9.05063 21.6099 9.09063 21.5999C9.36063 21.5499 9.54063 21.2799 9.48063 21.0099C9.43063 20.7399 9.16063 20.5599 8.89063 20.6199C8.87063 20.6199 7.10063 20.9699 5.63063 20.1999C5.39063 20.0699 5.08063 20.1599 4.95063 20.4099L4.96063 20.3999Z"
      fill="currentColor"
    />
    <path
      d="M24.3404 17.9706C24.7304 18.0506 25.1204 17.8106 25.2104 17.4106C25.3004 17.0106 25.0704 16.6306 24.6704 16.5306C22.4904 15.9806 20.2004 16.4506 18.6804 17.7706C18.4804 17.9406 18.4504 18.2406 18.6104 18.4506C18.7104 18.5806 18.8604 18.6406 19.0004 18.6406C19.1004 18.6406 19.2004 18.6106 19.2904 18.5506C21.2404 17.2006 22.7404 17.5806 23.8404 17.8506C24.0204 17.9006 24.1904 17.9406 24.3404 17.9706Z"
      fill="currentColor"
    />
    <path
      d="M27.6104 22.1943C25.3804 22.8943 23.0904 22.1943 23.0704 22.1943C22.8104 22.1143 22.5304 22.2543 22.4404 22.5243C22.3604 22.7843 22.5004 23.0643 22.7704 23.1543C22.8404 23.1743 23.9504 23.5143 25.4204 23.5143C26.1804 23.5143 27.0404 23.4243 27.9104 23.1543C28.1704 23.0743 28.3204 22.7943 28.2404 22.5243C28.1604 22.2643 27.8804 22.1143 27.6104 22.1943Z"
      fill="currentColor"
    />
    <path
      d="M26.3704 20.1943C24.9204 20.9643 23.1304 20.6243 23.1104 20.6143C22.8404 20.5543 22.5804 20.7343 22.5204 21.0043C22.4704 21.2743 22.6404 21.5343 22.9104 21.5943C22.9504 21.5943 23.4204 21.6943 24.0904 21.6943C24.8604 21.6943 25.8804 21.5743 26.8304 21.0743C27.0704 20.9443 27.1704 20.6443 27.0404 20.3943C26.9104 20.1443 26.6104 20.0543 26.3604 20.1843L26.3704 20.1943Z"
      fill="currentColor"
    />
    <path
      d="M16 21.2159C16.36 21.2159 17 20.5859 17 20.2359C17 19.8859 16.28 19.8359 16 19.8359C15.73 19.8359 15 19.8759 15 20.2359C15 20.5959 15.64 21.2159 16 21.2159Z"
      fill="currentColor"
    />
    <path
      d="M18.8599 22.6744C18.5299 23.0344 18.2399 23.1944 17.9399 23.1944C17.5099 23.1944 16.9799 22.8344 16.3799 22.1444C16.3799 22.1444 16.3599 22.1344 16.3499 22.1244C16.3499 22.1244 16.3399 22.1044 16.3299 22.0944C16.3099 22.0844 16.2899 22.0744 16.2699 22.0644C16.2399 22.0444 16.2099 22.0244 16.1799 22.0144C16.1499 22.0044 16.1199 21.9944 16.0899 21.9944C16.0599 21.9944 16.0299 21.9844 15.9899 21.9844C15.9499 21.9844 15.9299 21.9844 15.8899 21.9944C15.8599 21.9944 15.8299 22.0044 15.7999 22.0144C15.7699 22.0244 15.7399 22.0444 15.7099 22.0644C15.6899 22.0744 15.6699 22.0844 15.6499 22.0944C15.6499 22.0944 15.6399 22.1144 15.6299 22.1244C15.6299 22.1244 15.6099 22.1344 15.5999 22.1444C14.9999 22.8344 14.4699 23.1944 14.0399 23.1944C13.7399 23.1944 13.4499 23.0244 13.1199 22.6744C12.9299 22.4744 12.6199 22.4544 12.4099 22.6444C12.2099 22.8344 12.1899 23.1444 12.3799 23.3544C12.8999 23.9244 13.4399 24.2044 14.0299 24.2044H14.0499C14.6699 24.2044 15.3099 23.8544 15.9799 23.1944C16.6499 23.8544 17.2899 24.1944 17.9099 24.2044H17.9299C18.5199 24.2044 19.0599 23.9244 19.5799 23.3544C19.7699 23.1544 19.7499 22.8344 19.5499 22.6444C19.3399 22.4544 19.0299 22.4744 18.8399 22.6744H18.8599Z"
      fill="currentColor"
    />
    <path
      d="M30.0002 17.4898C29.7202 16.2698 29.4202 15.0098 29.4502 13.1898L29.4702 11.9598C29.5602 7.6998 29.6702 2.3898 26.9002 1.5798C24.1802 0.7898 19.5102 6.8898 18.4302 8.3698C16.7502 8.1798 15.1202 8.1798 13.5702 8.3698C12.4902 6.8798 7.8202 0.7798 5.1002 1.5798C2.3302 2.3898 2.4402 7.6998 2.5302 11.9598L2.5502 13.1898C2.5702 15.0098 2.2802 16.2698 2.0002 17.4898C1.6602 18.9498 1.3402 20.3298 1.5902 22.4198C2.2102 27.6298 7.3302 30.4998 16.0002 30.4998C24.6702 30.4998 29.7902 27.6298 30.4102 22.4198C30.6602 20.3298 30.3402 18.9498 30.0002 17.4898ZM29.4202 22.2998C28.8702 26.9398 24.1002 29.4998 16.0002 29.4998C7.9002 29.4998 3.1302 26.9398 2.5802 22.2998C2.3502 20.3798 2.6402 19.1498 2.9702 17.7098C3.2702 16.4298 3.5702 15.1098 3.5502 13.1698L3.5302 11.9298C3.4602 8.4298 3.3502 3.1298 5.3902 2.5298C5.4802 2.4998 5.5802 2.4898 5.6802 2.4898C7.4902 2.4898 11.0302 6.4998 12.9402 9.1798C13.0502 9.3298 13.2302 9.4098 13.4202 9.3898C15.0602 9.1598 16.8102 9.1598 18.6102 9.3898C18.7902 9.4198 18.9702 9.3298 19.0802 9.1798C21.1002 6.3498 24.9302 2.0398 26.6302 2.5298C28.6702 3.1298 28.5602 8.4198 28.4902 11.9298L28.4702 13.1698C28.4402 15.1098 28.7502 16.4298 29.0502 17.7098C29.3802 19.1398 29.6702 20.3798 29.4402 22.2998H29.4202Z"
      fill="currentColor"
    />
  </svg>
);

export default Cat;
