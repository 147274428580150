import styled from "styled-components";

export const ContentInfoItemStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
  color: ${(p) => p.theme.colors.text.primary};
  position: relative;

  .icon_container {
    position: relative;
  }

  .error {
    color: ${(p) => p.theme.colors.text.error};
    font-size: ${(p) => p.theme.typography.subText5};
  }

  .info {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;

    &.withDuration {
      justify-content: space-between;
      flex-direction: row;
    }
  }

  .content_count {
    position: absolute;
    top: 0;
    right: 0;
    width: 12px;
    height: 12px;
    color: ${(p) => p.theme.colors.text.primary};
    font-size: 8px;
    background: ${(p) => p.theme.colors.background.fourth};

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    span {
      height: 10px;
    }
  }

  .content_info_text {
    font-size: ${(p) => p.theme.typography.subText3};
  }
`;
