import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const CurrencySelectorContainer = styled.div<{ show: boolean }>`
  .currency {
    color: ${(p) => p.theme.colors.text.secondary};
    position: absolute;
    right: 12px;
    gap: 6px;
    display: flex;
    top: 9px;
    cursor: pointer;

    .arrow {
      display: flex;
      transition: all 0.3s;
      color: ${(p) => p.theme.colors.text.primary};

      ${(p) =>
        p.show &&
        css`
          transform: rotate(180deg);
        `}
    }

    ${deviceQuery.mobileM} {
      right: 0px;
      width: 100%;
      top: 40px;
      justify-content: center;
    }
  }

  .currencies {
    z-index: 3;
    position: absolute;
    right: 0px;
    bottom: 45px;
    display: flex;
    flex-direction: column;
    padding: 4px;
    border-radius: 12px;
    border: 1px solid ${(p) => p.theme.colors.divider};
    background: ${(p) => p.theme.colors.background.secondary};
    font-size: ${(p) => p.theme.typography.subText5};
    transition: all 0.3s;

    ${(p) =>
      p.show
        ? css`
            pointer-events: auto;
            opacity: 1;
          `
        : css`
            pointer-events: none;
            opacity: 0;
          `}

    .item {
      padding: 8px 16px;
      display: flex;
      border-radius: 12px;
      gap: 12px;
      cursor: pointer;
      align-items: center;
      color: ${(p) => p.theme.colors.text.primary};

      &.active {
        background: ${(p) => p.theme.colors.background.fifth};
        color: ${(p) => p.theme.colors.text.third};
      }

      &:hover {
        background: ${(p) => p.theme.colors.background.fifth};
      }
    }
  }
`;
