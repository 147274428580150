import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const SelectContainer = styled.div<{ disabled?: boolean; left?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${(p) =>
    p.disabled
      ? css`
          cursor: default;
        `
      : css`
          cursor: pointer;
        `}
`;

export const Label = styled.div<{ disabled?: boolean; left?: boolean }>`
  font-size: ${(p) => p.theme.typography.subText5};
  font-family: ${(p) => p.theme.ff.outfit.regular};
  width: 100%;

  ${(p) =>
    p.disabled
      ? css`
          color: ${p.theme.colors.text.secondary};
        `
      : css`
          color: ${p.theme.colors.text.primary};
        `}
`;

export const Area = styled.div<{ disabled?: boolean; left?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(p) => p.theme.colors.background.secondary};
  padding: 12px;
  gap: 12px;
  position: relative;
  overflow: hidden;
  border-radius: 12px;

  ${(p) =>
    p.disabled && !p.left
      ? css`
          border: 1px solid ${p.theme.colors.field.empty};
          color: ${p.theme.colors.text.secondary};
        `
      : p.disabled && p.left
      ? css`
          border-radius: 0px;
          color: ${p.theme.colors.text.secondary};
          border-right: 1px solid ${p.theme.colors.field.empty};
        `
      : p.left
      ? css`
          border-radius: 0px;
          border-right: 1px solid ${p.theme.colors.field.empty};
        `
      : css`
          border: 1px solid ${p.theme.colors.field.full};
        `}

  .title {
    display: none;
  }

  ${deviceQuery.tabletM} {
    .title {
      display: block;
    }
    .arrow {
      align-items: end;
    }
  }
`;
