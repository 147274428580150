const Challenges = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.21875 16.0195C6.11875 18.907 8.81125 20.9995 11.9913 20.9995C15.9063 20.9995 19.0863 17.8195 19.0863 13.9045C19.0863 11.8495 18.2088 9.99703 16.8063 8.70703C16.7013 10.372 15.3587 11.6995 13.6787 11.767"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6875 11.767C14.0025 10.9645 14.1825 10.0945 14.1825 9.17945C14.1825 8.44445 14.07 7.73195 13.86 7.06445"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8619 7.065C13.3219 5.325 12.1219 3.8775 10.5619 3C10.4719 4.92 10.8019 6.15 8.29688 7.86"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.30375 7.85938C7.90625 8.10688 7.53125 8.39187 7.18625 8.70687C5.79125 10.0044 4.90625 11.8494 4.90625 13.9044C4.90625 14.6394 5.01875 15.3519 5.22875 16.0194"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Challenges;
