import { FC, ReactNode, memo } from "react";
import { Col } from "react-bootstrap";

import { Content, Description, DeleteTitle, DeleteBtnWrapper, IconWrapper } from "./style";

import Button from "Components/Button";
import MiniLoader from "Components/MiniLoader";
import Modal from "Components/Modal/Modal";

interface Props {
  show: boolean;
  closeModal: () => void;
  size?: "sm" | "lg" | "xl";
  showCloseButton?: boolean;
  onConfirmDelete: () => void;
  onCancel?: () => void;
  title: string;
  description?: string;
  deleteBtnText: string;
  cancelBtnText: string;
  isGreenBtn?: boolean;
  isLoading?: boolean;
  Icon: ReactNode;
}

// Confirm template modal component
const ConfirmModal: FC<Props> = memo(
  ({
    show,
    closeModal,
    size,
    showCloseButton,
    onConfirmDelete,
    onCancel,
    title,
    description,
    deleteBtnText,
    cancelBtnText,
    isGreenBtn,
    isLoading,
    Icon,
  }) => (
    <Modal
      show={show}
      onHide={() => closeModal()}
      size={size}
      showCloseButton={showCloseButton}
      padding="20px"
    >
      <Col xs={12} md={12}>
        <Content>
          {Icon && (
            <IconWrapper>
              <div className="info_icon">{Icon}</div>
            </IconWrapper>
          )}
          <DeleteTitle>{title}</DeleteTitle>
          {description && <Description>{description}</Description>}
          <DeleteBtnWrapper>
            <Button
              className="w-100"
              variant="secondary"
              onClick={() => {
                onCancel && onCancel();
                closeModal();
              }}
            >
              {cancelBtnText}
            </Button>

            {isGreenBtn ? (
              <Button className="w-100" variant="primary" onClick={() => onConfirmDelete()}>
                {isLoading ? <MiniLoader /> : deleteBtnText}
              </Button>
            ) : (
              <Button className="w-100" variant="third" onClick={() => onConfirmDelete()}>
                {isLoading ? <MiniLoader /> : deleteBtnText}
              </Button>
            )}
          </DeleteBtnWrapper>
        </Content>
      </Col>
    </Modal>
  )
);

export default ConfirmModal;
