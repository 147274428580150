import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const ChatContainer = styled.div<{ read?: boolean; active: boolean }>`
  padding: 16px;
  display: flex;
  gap: 16px;
  border-bottom: 1px solid ${(p) => p.theme.colors.divider};
  font-size: ${(p) => p.theme.typography.subText5};
  cursor: pointer;
  background: ${(p) =>
    p.active ? p.theme.colors.background.fifth : p.theme.colors.background.secondary};

  &:hover {
    background: ${(p) => p.theme.colors.background.fifth};
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: calc(100% - 64px);
    text-overflow: ellipsis;
    overflow: hidden;

    .main {
      display: inline-flex;
      gap: 12px;
      justify-content: space-between;
      align-items: center;

      .author {
        max-width: 155px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .time {
        font-size: ${(p) => p.theme.typography.subText3};
        color: ${(p) => p.theme.colors.text.secondary};
        min-width: fit-content;
      }
    }

    .message {
      display: inline-flex;
      width: 100%;
      gap: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${(p) =>
        p.read ? p.theme.colors.text.secondary : p.theme.colors.text.primary} !important;

      & > span {
        display: inline-flex;
        align-items: center;

        .dot {
          padding: 4px;
          background: ${(p) => p.theme.colors.green.primary};
          border-radius: 50%;
        }
      }
    }

    .searchedCount {
      background: ${(p) => p.theme.colors.background.fifth};
      color: ${(p) => p.theme.colors.text.secondary};
      display: flex;
      align-content: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }

  ${deviceQuery.tabletM} {
    border-bottom: none;
    border-radius: 16px;
  }
`;
