import { useRef } from "react";

/**
 * A custom hook to determine if the current render is the first render of the component
 *
 * @returns {boolean} - Returns `true` if this is the first render, `false` otherwise
 *
 * The hook uses a `useRef` hook to keep track of whether the component has been rendered before
 * On the first render, it returns `true` and sets the ref to `false` for subsequent renders
 */
export function useFirstRender() {
  const ref = useRef(true);
  const firstRender = ref.current;
  ref.current = false;
  return firstRender;
}
