import { FC, ReactNode, useEffect, useState } from "react";

import { Content } from "./style";

interface Props {
  show: boolean;
  className?: string;
  collapsedContent: ReactNode;
  expandedContent: ReactNode;
}

// Accordion2 component that handles toggling between collapsed and expanded content.
const Accordion2: FC<Props> = ({ show, className, collapsedContent, expandedContent }) => {
  const [isLocalVisible, setLocalVisible] = useState(show);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isHasBeenOpened, setHasBeenOpened] = useState(true);

  useEffect(() => {
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (isFirstLoad) return;

    // if we have opened accordion do not remove it from DOM to skip heavy renders for example iframes
    if (isHasBeenOpened) {
      setLocalVisible(true);
      return;
    }

    if (!show) return;

    setHasBeenOpened(true);
    setLocalVisible(true);
  }, [show]);

  return (
    <div className={className}>
      {collapsedContent}
      <Content opened={show}>
        {isLocalVisible && <div className="expandable">{expandedContent}</div>}
      </Content>
    </div>
  );
};

export default Accordion2;
