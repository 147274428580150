import { FC, useMemo } from "react";

import { useDispatch } from "react-redux";

import { ShowContentConteiner } from "./styles";

import { getFileSizeInMb, getIconByContentType } from "common/helpers/file-helpers";
import { formatDuration } from "common/helpers/helpers";
import { ContentInfoItem } from "pages/public/chat/Components/Chat/ContentInfoitem";
import { SingleContentMessageStyles } from "pages/public/chat/Components/Chat/SingleContentMessage/styles";
import { setViewPicture } from "store/slices/global/globalSlice";
import {
  NewMessageImageMediaContent,
  NewMessagePlayableMediaContent,
} from "types/new-messages.types";

interface Props {
  item: NewMessageImageMediaContent | NewMessagePlayableMediaContent;
}

export const ShowContent: FC<Props> = ({ item }) => {
  const dispatch = useDispatch();
  const fileSizeInMB = useMemo(() => {
    const sizeInMb = getFileSizeInMb(item.dimension.file_size);
    return `${sizeInMb.toFixed(2)} MB`;
  }, [item]);

  const getContentIcon = useMemo(() => {
    return getIconByContentType(item.mediaType, "single_content_icon content_info_icon");
  }, [item]);

  const getDuration = useMemo(() => {
    if (!item.dimension.duration) return;
    return formatDuration(item.dimension.duration);
  }, [item]);

  const showImage = () => {
    dispatch(setViewPicture({ src: item.url, show: true, type: item.mediaType }));
  };

  return (
    <ShowContentConteiner onClick={showImage}>
      <SingleContentMessageStyles
        height="114px"
        thumbnailHeight="78px"
        infoPadding="8px"
        iconWidth="24px"
        modal
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="thumbnail" style={{ backgroundImage: `url(${item.url})` }} />
        <div className="content_info">
          <ContentInfoItem
            icon={getContentIcon}
            contentInfo={fileSizeInMB}
            duration={getDuration}
          />
        </div>
      </SingleContentMessageStyles>
    </ShowContentConteiner>
  );
};
