import styled from "styled-components";

import { OutfitSemibold } from "styles/mixins";

export const Wrapper = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  color: ${(p) => p.theme.colors.icon.secondary};
  text-transform: uppercase;
  font-size: 0.85rem;
  font-family: ${(p) => p.theme.ff.outfit.regular};
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  margin-bottom: -5px;

  .green {
    padding-left: 3px;
    color: ${(p) => p.theme.colors.green.primary};
  }
`;

export const InputArea = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;

  > div {
    flex: 1;
  }

  input {
    -webkit-appearance: none;
    width: 100%;
    outline: none;
    height: 15px;
    background-color: ${(p) => p.theme.colors.background.primary};
    border-radius: 15px;

    &::-webkit-slider-thumb {
      position: relative;
      z-index: 2;
      -webkit-appearance: none;
      appearance: none;
      width: 22px;
      height: 22px;
      background: ${(p) => p.theme.colors.green.primary};
      cursor: pointer;
      border-radius: 50%;
    }
  }

  p {
    ${OutfitSemibold};
    font-size: 0.7rem;
  }
`;

export const FilledArea = styled.div<{ width: string }>`
  background: ${(p) => p.theme.colors.green.primary};
  width: ${(p) => p.width};
  height: 15px;
  position: absolute;
  top: 0;
  border-radius: 15px;
`;
