import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const WallOfFameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  height: fit-content;
  transition: 0.3s all;
  background: ${(p) => p.theme.colors.background.secondary};
  border-radius: 16px;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
  flex: 1;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .title {
      font-size: ${(p) => p.theme.typography.text3};
    }

    .show {
      display: none;
      align-items: center;
      gap: 12px;

      .avatars {
        display: flex;
        opacity: 1;
        pointer-events: static;
        transition: 0.3s all;

        &.active {
          opacity: 0;
          pointer-events: none;
        }

        & > div {
          border: 2px solid ${(p) => p.theme.colors.background.secondary};
          border-radius: 50%;
        }
        & > div:nth-child(n + 2) {
          margin-left: -16px;
        }
      }

      .arrow {
        transition: 0.3s all;

        &.active {
          transform: rotate(180deg);
        }
      }
    }
  }

  .fansContainer {
    flex-direction: column;
    gap: 12px;
    display: flex;
  }

  ${deviceQuery.tabletM} {
    cursor: pointer;

    .top {
      .show {
        display: flex;
      }
    }
  }
`;

export const WallFan = styled.div<{ profile: boolean }>`
  display: flex;
  align-items: center;
  gap: 12px;

  .info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow: hidden;

    .name {
      display: inline-flex;
      align-items: center;
      gap: 4px;
      font-size: ${(p) => p.theme.typography.subText5};

      .display {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .description {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      font-size: ${(p) => p.theme.typography.subText3};
      color: ${(p) => p.theme.colors.text.secondary};

      .text {
        flex: 1;
        min-width: ${(p) => (p.profile ? "100px" : "150px")};
        word-break: break-word;
      }

      .amount {
        color: ${(p) => p.theme.colors.green.primary};
        background: ${(p) => p.theme.colors.green.primary20};
        white-space: nowrap;
        padding: 2px 6px;
        line-height: 20px;
        border-radius: 32px;
      }
    }
  }
`;
