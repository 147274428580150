import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import LoginForm from "./components/LoginForm";

import PasswordModal from "./components/PasswordModal";

import { makeToast } from "common/helpers/helper";
import { STRINGS } from "constants/appConstants";
import titles from "constants/titles";
import useTitle from "customHooks/useTitle";

import { AuthWrapper } from "styles/auth";

// users login page
const Login = () => {
  useTitle(titles.login);
  const { t } = useTranslation();
  const { state } = useLocation();
  const [show, setShow] = useState(false);
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    state && setShow(true);
  }, [state]);

  useEffect(() => {
    if (query.get("verification")) {
      makeToast({
        message: t("auth.emailVerify"),
        type: STRINGS.success,
      });
    }
  }, []);

  return (
    <AuthWrapper>
      <div className="content">
        <LoginForm loginFor={STRINGS.user} />
      </div>
      <PasswordModal show={show} closeModal={() => setShow(false)} />
    </AuthWrapper>
  );
};

export default Login;
