import { io, Socket } from "socket.io-client";

import { getLocalStorageItem } from "./localStorage";

import { domain } from "configuration/config";
import { STORAGES } from "constants/appConstants";
import store from "store/store";

const jwtToken: string | null = store.getState().auth.token;
const token: string | null = getLocalStorageItem({ key: STORAGES.token });

const socket: Socket = io(domain, {
  extraHeaders: {
    Authorization: `Bearer ${jwtToken || token}`,
  },
  autoConnect: false,
});

const getNewSocket = (newToken: string): Socket => {
  return io(domain, {
    extraHeaders: {
      Authorization: `Bearer ${newToken}`,
    },
    autoConnect: false,
  });
};

export const CustomSocket = {
  socket,
  setNewSocket(newToken: string): void {
    this.socket = getNewSocket(newToken);
  },
};
