import { FC } from "react";

interface Props {
  size?: number;
}

const EyeSlash: FC<Props> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.76002 14.37C9.46453 14.0788 9.23009 13.7316 9.0704 13.3487C8.91071 12.9658 8.82899 12.5548 8.83003 12.14C8.82981 11.5154 9.01466 10.9048 9.36125 10.3853C9.70784 9.86572 10.2006 9.46052 10.7773 9.22084C11.3541 8.98116 11.9889 8.91776 12.6016 9.03863C13.2143 9.15951 13.7775 9.45924 14.22 9.89998"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1 12.7C14.9846 13.3312 14.6797 13.9123 14.226 14.366C13.7723 14.8197 13.1912 15.1246 12.56 15.24"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.64999 17.47C4.9216 16.0441 3.57745 14.2083 2.73999 12.13C3.58473 10.042 4.93521 8.19669 6.66999 6.76003C8.15987 5.5036 10.046 4.81445 11.995 4.81445C13.9439 4.81445 15.8301 5.5036 17.32 6.76003"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.45 9C20.1723 9.9752 20.7765 11.0326 21.25 12.15C19.25 16.71 15.81 19.45 12 19.45C11.1379 19.4373 10.2835 19.2853 9.46997 19"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.89 4.25L4.10999 20"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EyeSlash;
