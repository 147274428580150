import styled from "styled-components";

import { HideScrollBar } from "styles/mixins";
import zIndexs from "styles/zIndex";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Label = styled.label<{ isDisabled?: boolean }>`
  color: ${(p) => (p.isDisabled ? p.theme.colors.text.secondary : p.theme.colors.text.primary)};
  text-transform: unset;
  font-size: ${(p) => p.theme.typography.subText5};
  font-family: ${(p) => p.theme.ff.outfit.regular};
  width: 100%;

  .green {
    padding-right: 4px;
    color: ${(p) => (p.isDisabled ? p.theme.colors.text.secondary : p.theme.colors.text.primary)};
  }
`;

export const InputArea = styled.div<
  Partial<{
    isMulti: boolean;
    sizeAdjust: boolean;
    highIndex: boolean;
  }>
>`
  display: flex;
  background: ${(p) => p.theme.colors.background.secondary};
  border-radius: 3px;
  border: 1px solid ${(p) => p.theme.colors.field.empty};
  height: ${(p) => (p.isMulti ? "auto" : "46px")};
  min-height: ${(p) => (p.sizeAdjust ? "46px" : "auto")};
  div[class*="-container"] {
    height: 100%;
    z-index: ${(p) => (p.highIndex ? zIndexs.selectField : "1")};
  }

  div[class*="ValueContainer"] {
    max-height: 46px;
    overflow-y: auto;
    ${HideScrollBar};
  }

  input {
    color: ${(p) => p.theme.colors.text.primary} !important;
  }

  div[class*="-container"] {
    width: 100%;
  }
  div[class*="-control"] {
    height: 100%;
    background: transparent;
    border: none;
  }
  div[class*="-menu"] {
    background: ${(p) => p.theme.colors.background.secondary};
  }
`;

export const ErrorMsg = styled.p<{ color?: string }>`
  color: ${(p) => p.color};
  font-size: 0.8rem;
  height: 19px;
`;

export const Select = styled.select`
  background: transparent;
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 3px;
  outline: none;
  border: none;
  color: ${(p) => p.theme.colors.text.primary};
  background: ${(p) => p.theme.colors.background.secondary};
  cursor: pointer;
  text-transform: capitalize;

  .first-option {
    display: none;
  }
`;

export const Item = styled.p`
  padding: 10px;
`;
