const Russian = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.39 19.08C8.38 18.43 7.59 15.88 7.49 12.73H19V12C19 7.04 14.96 3 10 3C5.04 3 1 7.04 1 12C1 16.96 4.71 20.65 9.39 20.97V19.08ZM17.5 11.27H13.97C13.9 8.92 13.47 6.46 12.53 4.91C15.23 5.87 17.22 8.33 17.5 11.27ZM9.9 4.75C9.9 4.75 9.97 4.73 10 4.73C10.03 4.73 10.06 4.75 10.1 4.75C11.35 4.75 12.39 7.61 12.51 11.27H7.49C7.61 7.62 8.65 4.75 9.9 4.75ZM2.5 12.72H6.03C6.1 15.07 6.53 17.53 7.47 19.08C4.77 18.12 2.78 15.66 2.5 12.72ZM6.85 6.27C6.38 7.62 6.11 9.38 6.05 11.27H2.5C2.79 8.31 4.79 5.86 7.49 4.9C7.26 5.27 7.04 5.72 6.85 6.28V6.27Z"
      fill="currentColor"
    />
    <path
      d="M22.88 14H21.71C21.64 14 21.59 14.05 21.59 14.12V18.25C21.59 18.54 21.52 18.81 21.39 19.03C21.26 19.24 21.08 19.42 20.86 19.54C20.63 19.66 20.37 19.72 20.07 19.72C19.77 19.72 19.52 19.66 19.29 19.54C19.06 19.42 18.87 19.24 18.74 19.03C18.61 18.81 18.54 18.54 18.54 18.24V14.12C18.54 14.05 18.48 14 18.42 14H17.25C17.18 14 17.13 14.05 17.13 14.12V18.25C17.13 18.77 17.26 19.25 17.51 19.67C17.77 20.08 18.13 20.41 18.57 20.64C19.01 20.88 19.52 21 20.07 21C20.62 21 21.14 20.88 21.57 20.64C22.01 20.4 22.36 20.07 22.61 19.67C22.86 19.26 22.99 18.79 22.99 18.27V14.13C22.99 14.06 22.93 14.01 22.87 14.01L22.88 14Z"
      fill="currentColor"
    />
    <path
      d="M14.24 18.04C14.54 18 14.79 17.93 15.01 17.83C15.37 17.66 15.65 17.42 15.84 17.12C16.04 16.82 16.14 16.45 16.14 16.04C16.14 15.63 16.04 15.3 15.84 15C15.65 14.69 15.37 14.44 15.01 14.27C14.66 14.09 14.24 14 13.78 14H11.12C11.05 14 11 14.05 11 14.12V20.78C11 20.85 11.06 20.9 11.12 20.9H12.29C12.36 20.9 12.41 20.85 12.41 20.78V18.07H12.53L14.92 20.86C14.92 20.86 14.98 20.9 15.01 20.9H16.48C16.48 20.9 16.57 20.87 16.59 20.83C16.61 20.79 16.6 20.74 16.57 20.7L14.22 18.03L14.24 18.04ZM14.74 16.07C14.74 16.35 14.65 16.56 14.48 16.71C14.31 16.86 14.06 16.94 13.73 16.94H12.42V15.2H13.74C14.07 15.2 14.31 15.28 14.49 15.45C14.66 15.62 14.75 15.82 14.75 16.07H14.74Z"
      fill="currentColor"
    />
  </svg>
);

export default Russian;
