import { FC, Suspense, memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import { Content } from "./style";

import ArrowLeft from "assets/svg/ArrowLeft";
import Button from "Components/Button";
import FallbackLoader from "Components/FallbackLoader";
import { fetchUserDetailsAPI } from "services/axios";
import { fetchFeedsByCreator, fetchPlans, fetchUserDetails } from "services/fanServices";
import { getRole } from "store/slices/authSlice";
import { getUser } from "store/slices/global/userSlice";

interface Props {
  isCustomization?: boolean;
}

const PreviewLayout: FC<Props> = ({ isCustomization = false }) => {
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const accountType = useSelector(getRole);

  useEffect(() => {
    fetchUserDetailsAPI();
  }, []);

  useEffect(() => {
    if (!isCustomization) return;

    if (user) {
      fetchUserDetails({ creatorId: user.username });
      fetchPlans({
        skip: 0,
        limit: 8,
        creatorId: user.username,
        role: accountType,
      });
      fetchFeedsByCreator({
        skip: 0,
        limit: 8,
        id: user._id,
        role: accountType,
      });
    }
  }, [user]);

  return (
    <Content>
      <Suspense fallback={<FallbackLoader />}>
        <Outlet />
      </Suspense>
      <Button className="back" variant="secondary" onClick={() => navigate(-1)}>
        <ArrowLeft />
      </Button>
    </Content>
  );
};

export default PreviewLayout;
