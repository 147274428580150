import { Formik } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { LoginFormValues, Validator } from "./FormikHelpers";
import { SubHeading } from "./style";

import VerifyModal from "../VerifyModal";

import { makeToast } from "common/helpers/helper";
import { changeLanguage } from "common/helpers/language";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from "common/helpers/localStorage";
import Button from "Components/Button";
import { GoogleLoginButton } from "Components/GoogleLoginButton";
import Input from "Components/Input";
import MiniLoader from "Components/MiniLoader";

import Password from "Components/Password";
import SocialIcons from "Components/SocialIcons";
import { REACT_APP_GOOGLE_RECAPTCHA_KEY } from "configuration/config";
import { ROLES, STORAGES, STRINGS } from "constants/appConstants";
import { MESSAGES } from "constants/messages";
import { AUTH_ROUTES } from "constants/routes";
import { MfaModal } from "pages/auth/Login/components/MfaModal";
import { ROUTES } from "routes/routesData";

import {
  getUserLoginInfoByGoogleToken,
  loginAdminAPI,
  loginUserAPI,
  validateTwoFactorCodeAPI,
} from "services/authServices";
import { apiHandler, fetchUserDetailsAPI, getAllDefaultLandingPageAPI } from "services/axios";
import { getWalletAPI } from "services/walletServices";
import { getDefaultLandingPages } from "store/slices/admin/adminSlice";
import {
  getLoginDetailsVerified,
  getToken,
  getVerifiedLoginDetails,
  login,
  setLoginDetailsVerified,
  setToken,
  setUserId,
  setVerifiedLoginDetails,
} from "store/slices/authSlice";
import { getLoginLoader, setLoader } from "store/slices/featureSlice";
import { setUser } from "store/slices/global/userSlice";
import { AuthActions, AuthContainer, AuthForm } from "styles/auth";
import { ILoginFormikData, UserLoginRespData } from "types/auth-types";

interface Props {
  loginFor: string;
}

const LoginForm: FC<Props> = ({ loginFor }) => {
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [isMfaModalShown, setIsMfaModalShown] = useState(false);
  const recaptchaRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [outSideFormValues, setOutsideFormValues] = useState({} as ILoginFormikData);

  const token = useSelector(getToken);
  const loginDetailsVerified = useSelector(getLoginDetailsVerified);
  const verifiedLoginDetails = useSelector(getVerifiedLoginDetails);
  const defaultLandingPages = useSelector(getDefaultLandingPages);
  const jwtToken = useSelector(getToken);
  const loginLoader = useSelector(getLoginLoader);
  const startLoader = () => dispatch(setLoader({ name: "loginLoader", value: true }));
  const stopLoader = () => dispatch(setLoader({ name: "loginLoader", value: false }));

  const sendToRecoveryCode = () => {
    navigate(ROUTES.recoveryCode, {
      state: {
        userInfo: verifiedLoginDetails,
        page: STRINGS.login,
      },
    });
  };

  const LoginSchema = Yup.object().shape({
    username: Yup.string().when("isEmail", {
      is: "1",
      then: Yup.string().email(t("signup.invalid_email")).required(t("common.required")),
      otherwise: Yup.string().required(t("common.required")),
    }),
    password: Yup.string().required(t("common.required")).trim(),
    recaptcha: Yup.string().required(t("common.required")),
    twoFACode:
      loginDetailsVerified && loginFor === STRINGS.admin
        ? Yup.string().required(t("common.required"))
        : Yup.string(),
  });

  const redirectUser = (data?: UserLoginRespData) => {
    let route = "";

    defaultLandingPages.forEach((item: any) => {
      if (
        item.role.toLowerCase() ===
        ((data && data.data.role.toLowerCase()) || verifiedLoginDetails?.role?.toLowerCase())
      ) {
        if (getLocalStorageItem({ key: STORAGES.route })) {
          route = getLocalStorageItem({ key: STORAGES.route }).slice(1);
        } else route = item.defaultURl;
      }
    });

    navigate(`/${route}`, { replace: true });
    removeLocalStorageItem({ key: STORAGES.route });
  };

  const onSuccessRedirectHandler = (data?: UserLoginRespData) => {
    if (data || verifiedLoginDetails) {
      dispatch(
        login({
          accountType: data?.data?.role || verifiedLoginDetails?.role || "",
          token: data?.Auth || jwtToken,
        })
      );
      getWalletAPI();
    }

    if (data?.data?.is_new && loginFor !== STRINGS.admin) {
      navigate("/onboarding");
    } else {
      redirectUser(data);
    }
  };

  const submitGoogleLogin = async (googleToken: string) => {
    startLoader();
    getUserLoginInfoByGoogleToken(
      { googleToken },
      (data) => {
        stopLoader();
        const formattedData = {
          data: data.data,
          Auth: data.Auth,
        };

        handleUserSuccessLogin(formattedData);
      },
      () => {
        stopLoader();
        navigate(ROUTES.register, {
          state: {
            registerAfterGoogleLogin: {
              token: googleToken,
            },
          },
        });
      }
    );
  };

  const submit = async (values: ILoginFormikData) => {
    startLoader();

    const data = {
      username: values.username,
      password: values.password,
    };

    apiHandler(
      () => (loginFor === STRINGS.admin ? loginAdminAPI({ data }) : loginUserAPI({ data })),
      {
        onSuccess: (data) => {
          handleUserSuccessLogin(data);
        },
        onError: (error) => {
          stopLoader();
          error.response?.data.message.includes("not verified") && setShow(true);
          error.message?.includes(STRINGS.networkErr)
            ? makeToast({
                message: MESSAGES.networkErr,
                type: STRINGS.error,
              })
            : error.response?.data.message.includes("Invalid username") ||
              error.response?.data.message.toLowerCase().includes("invalid user")
            ? makeToast({
                message: t("login.login_username_validation"),
                type: STRINGS.error,
              })
            : error.response?.data.message
                .toLowerCase()
                .replace(" ", "")
                .includes("invalidpassword")
            ? makeToast({
                message: t("login.wrong_password"),
                type: STRINGS.error,
              })
            : makeToast({
                message: error?.response?.data?.message,
                type: STRINGS.error,
              });
        },
      }
    );
  };

  const handleUserSuccessLogin = (data: UserLoginRespData) => {
    if (data?.data?.languages) {
      changeLanguage(data?.data?.languages);
    }

    // to check email is verified or not
    if (
      loginFor === STRINGS.user &&
      (data.data.role === ROLES.creator ||
        data.data.role === ROLES.publisher ||
        data.data.role === ROLES.fan) &&
      !data.data.emailverification
    ) {
      stopLoader();
      makeToast({
        message: t("login.login_email_validation"),
        type: STRINGS.toastType.error,
      });
    }
    // to check user active
    else if (loginFor === STRINGS.user && (ROLES.publisher || ROLES.creator) && !data.data.active) {
      stopLoader();
      makeToast({
        message: t("login.login_admin_validation"),
        type: STRINGS.toastType.error,
      });
      return;
    } else if (!data.data.mfaSkip) {
      stopLoader();

      // if form has no values,means we are form google login or redirected, we should ask with mfa with modal
      if (!outSideFormValues.username || !outSideFormValues.password) {
        dispatch(setVerifiedLoginDetails(data.data));
        setIsMfaModalShown(true);
      } else {
        dispatch(setLoginDetailsVerified(true)); // it shows the 2FA input field
      }
    } else {
      stopLoader();
      if (data?.data?.is_new) {
        navigate(`/authentication`, {
          state: { data: data.data, page: STRINGS.login },
        });
      } else {
        onSuccessRedirectHandler(data);
      }
    }

    dispatch(setUser(data.data));
    dispatch(setUserId(data.data._id));
    setLocalStorageItem({
      key: STORAGES.userId,
      value: data.data._id,
    });
    dispatch(setToken(data.Auth));
    dispatch(setVerifiedLoginDetails(data.data));
  };

  const submit2 = (values: ILoginFormikData) => {
    startLoader();

    if (!verifiedLoginDetails) return;

    validateTwoFactorCodeAPI(
      {
        data: {
          id: verifiedLoginDetails._id,
          token: values.twoFACode,
        },
      },
      (status) => {
        if (status) {
          onSuccessRedirectHandler();
          dispatch(setLoginDetailsVerified(false));
        }
        stopLoader();
      }
    );
  };

  const hide2FAInput = () => {
    loginDetailsVerified && dispatch(setLoginDetailsVerified(false));
  };

  useEffect(() => {
    return () => {
      dispatch(setLoginDetailsVerified(false));
    };
  }, []);

  useEffect(() => {
    // if there is defaultLandingPages no request is needed
    if (defaultLandingPages.length) return;
    getAllDefaultLandingPageAPI();
  }, []);

  // logins user after redirection if token is set into a store
  useEffect(() => {
    // there is no pages or token not provided
    if (!defaultLandingPages.length || !token) return;

    fetchUserDetailsAPI((res) => {
      const user = res.user;

      const formattedData = {
        data: user,
        Auth: token,
      };
      handleUserSuccessLogin(formattedData);
    });
  }, [defaultLandingPages, token]);

  return (
    <Formik<ILoginFormikData>
      initialValues={{
        username: "",
        password: "",
        twoFACode: "",
        recaptcha: "",
      }}
      validationSchema={LoginSchema}
      onSubmit={loginDetailsVerified ? submit2 : submit}
    >
      {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue }) => (
        <AuthForm onSubmit={handleSubmit}>
          <LoginFormValues
            valuesChanged={(values) => {
              setOutsideFormValues(values);
            }}
          />
          <Validator />
          <AuthContainer>
            <div className="left">
              <p className="title">
                {t("signup.login_now")}{" "}
                {loginFor === ROLES.admin && <span className="admin">{t("common.admin")}</span>}
              </p>
              <p className="text">
                <span>{t("signup.dont_have_account")} </span>
                <Link to="/register">{t("signup.signup_now")}</Link>
              </p>
              <div className="inputs">
                <Input
                  label={`${t("signup.email")}/${t("common.username")}`}
                  name="username"
                  type="text"
                  required
                  maxLength={50}
                  placeholder={t("signup.enterEmail")}
                  handleBlur={handleBlur}
                  handleChange={(event) => {
                    setFieldValue("username", event.target.value.trimStart());
                    hide2FAInput();
                    setFieldValue("twoFACode", "");
                  }}
                  value={values.username}
                  error={errors.username && touched.username ? errors.username : ""}
                />
                <Password
                  show={passwordVisible}
                  showPassword={() => setPasswordVisible(!passwordVisible)}
                  label={t("signup.pass")}
                  placeholder={t("signup.enterPass")}
                  name="password"
                  maxLength={50}
                  type={passwordVisible ? "text" : STRINGS.password}
                  handleChange={(event) => {
                    setFieldValue("password", event.target.value.trimStart());
                    hide2FAInput();
                    setFieldValue("twoFACode", "");
                  }}
                  handleBlur={handleBlur}
                  value={values.password}
                  required
                  error={errors.password && touched.password ? errors.password : ""}
                />
              </div>
              {loginDetailsVerified ? (
                <>
                  <Input
                    placeholder={t("auth.enter6_digit_code")}
                    label={t("auth.two_factor_auth")}
                    name="twoFACode"
                    type="text"
                    handleChange={(event) => {
                      const rex = /^[0-9]*$/;
                      if (rex.test(event.target.value)) {
                        setFieldValue("twoFACode", event.target.value);
                      }
                    }}
                    maxLength={10}
                    handleBlur={handleBlur}
                    value={values.twoFACode}
                    required
                    autoFocus
                    error={errors.twoFACode && touched.twoFACode ? errors.twoFACode : ""}
                  />
                  <SubHeading className="d-block text-left">
                    <span>{t("auth.lost_your_device")}&nbsp;</span>
                    <p onClick={sendToRecoveryCode}>{t("auth.use_recovery_code")}</p>
                  </SubHeading>
                </>
              ) : (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={REACT_APP_GOOGLE_RECAPTCHA_KEY}
                  onChange={(value) => {
                    setFieldValue("recaptcha", value);
                  }}
                />
              )}
              <Link to="/forgot-password">{t("signup.forgot_pass")}</Link>
              <Button variant="primary" full type="submit" disabled={!values.recaptcha}>
                {loginLoader ? (
                  <MiniLoader />
                ) : loginDetailsVerified ? (
                  t("login.login")
                ) : (
                  t("creator.campaign.submit")
                )}
              </Button>

              {loginFor !== STRINGS.admin && (
                <>
                  <span className="or_divider">{t("common.or")}</span>
                  <GoogleLoginButton
                    onSuccess={(resp) => {
                      if (!resp.credential) return;
                      submitGoogleLogin(resp.credential);
                    }}
                    onError={() => {
                      console.log("google login error");
                    }}
                  />
                </>
              )}
            </div>
            <div className="right">
              <Link to={AUTH_ROUTES.register}>
                <img src="/login.png" alt="login" loading="lazy" />
              </Link>
            </div>
          </AuthContainer>
          <AuthActions>
            <div className="socials">
              <SocialIcons />
            </div>
          </AuthActions>
          <MfaModal
            show={isMfaModalShown}
            userId={verifiedLoginDetails?._id}
            onCloseSuccess={() => {
              onSuccessRedirectHandler();
              dispatch(setLoginDetailsVerified(false));
            }}
            onCloseError={() => {
              setIsMfaModalShown(false);
            }}
          />
          <VerifyModal show={show} closeModal={() => setShow(false)} />
        </AuthForm>
      )}
    </Formik>
  );
};

export default LoginForm;
