import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const ChatInputWrapper = styled.div`
  position: relative;
  overflow: hidden;

  .overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 0px 0px 16px 16px;
  }
`;

export const ChatInputContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 16px;
  gap: 16px;
  font-size: ${(p) => p.theme.typography.subText5};

  .attachment_button {
    color: ${(p) => p.theme.colors.icon.secondary};
    position: relative;
    flex: 0;
    &:hover {
      color: ${(p) => p.theme.colors.icon.third};
    }

    label {
      cursor: pointer;
    }

    input {
      display: none;
    }
  }

  .message_input {
    color: ${(p) => p.theme.colors.text.primary};
    flex: 1;

    & > div {
      min-height: unset;
      background: transparent;
      border: none;
      border-radius: unset;

      & > textarea {
        height: unset;
        line-height: 22px;
        padding: 0;
      }

      & > div.right {
        justify-content: center;
        flex: 0;
      }
    }
  }

  .send_message_btn {
    color: ${(p) => p.theme.colors.icon.secondary};
    padding: unset;
    border: none;
    &:hover {
      background-color: unset;
      border: unset;
      svg {
        path {
          stroke: none !important;
          color: ${(p) => p.theme.colors.icon.third};
        }
      }
    }
  }

  ${deviceQuery.tabletM} {
    margin: 0px -16px;
    padding: 16px 16px 0px;
  }
`;
