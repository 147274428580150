import styled, { keyframes } from "styled-components";

export const highlight = keyframes`
  0% {
    background: transparent;
  }
  50% {
    background: rgba(37, 195, 116, 0.38);
  }
  
  100% {
    background: transparent;
  }
`;

export const MessageContainerWrapper = styled.div<{ highlighted: boolean }>`
  animation-name: ${(p) => (p.highlighted ? highlight : "unset")};
  animation-duration: 2s;
  animation-iteration-count: 1;
`;

export const MessageContainerStyles = styled.div<{ isYours: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 68%;
  width: fit-content;
  margin-left: ${(p) => (p.isYours ? "auto" : "0")};
`;
