import { PulsingSvgWrapper } from "./style";

// Component for Bio links guide indicator
export const PulsingMark = () => (
  <PulsingSvgWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <circle cx="8" cy="8" r="4" fill="#25C374" />
      <circle className="shadow" cx="8" cy="8" r="6" stroke="#25C374" />
    </svg>
  </PulsingSvgWrapper>
);
