import styled from "styled-components";

import { OutfitRegular } from "styles/mixins";

export const BuyContent = styled.div`
  text-align: left;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DeleteTitle = styled.p`
  ${OutfitRegular};
  word-wrap: break-word;
  padding-right: 10px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  color: ${(p) => p.theme.colors.text.primary};
  margin-bottom: 20px;
`;

export const FilterBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  .mr-2 {
    margin-right: 2rem;
  }
  @media (max-width: 445px) {
    display: block;
  }
`;

export const CancelBtnWrapper = styled.p`
  margin-right: 20px;
  @media (max-width: 445px) {
    margin-bottom: 15px;
  }
`;
