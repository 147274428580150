import {
  ChangeEventHandler,
  CSSProperties,
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
} from "react";

import { InpurArea } from "./style";

interface SearchProps {
  handleBlur: FocusEventHandler<HTMLInputElement>;
  sideLabel: string;
  full: boolean;
  wrapperStyle: CSSProperties;
  prefix: string;
  name: string;
  type: string;
  maxLength: number;
  placeholder: string;
  autoFocus: boolean;
  className: string;
  readOnly: boolean;
}

interface Props extends Partial<SearchProps> {
  handleChange: ChangeEventHandler<HTMLInputElement>;
  value: string;
  onSearch?: () => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
}

// TODO - remove this component after replacing all its calls with the Input component

// Old input component for tables
const TableInput: FC<Props> = ({
  handleChange,
  handleBlur,
  sideLabel,
  full,
  wrapperStyle,
  prefix,
  name,
  type,
  placeholder,
  value,
  autoFocus,
  className,
  readOnly,
  maxLength,
}) => (
  <InpurArea
    className={className}
    prefix={prefix}
    sideLabel={sideLabel}
    full={full}
    style={wrapperStyle}
  >
    {sideLabel && <p>{sideLabel}</p>}
    {prefix && <p>{prefix}</p>}
    <input
      readOnly={readOnly}
      name={name}
      type={type}
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      autoFocus={autoFocus}
      maxLength={maxLength}
    />
  </InpurArea>
);

export default TableInput;
