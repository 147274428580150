import styled from "styled-components";

export const SendCollapsedContainer = styled.div<{ showGlobal?: boolean; showCurrent?: boolean }>`
  transition: all 0.3s;
  display: flex;
  flex-direction: ${(p) => (p.showGlobal ? "row" : "column")};
  gap: 12px;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  border-bottom: ${(p) =>
    p.showCurrent ? `1px solid ${p.theme.colors.divider}` : `0px solid ${p.theme.colors.divider}`};

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .title {
      display: inline-flex;
      text-align: ${(p) => (p.showGlobal ? "start" : "center")};
      justify-content: ${(p) => (p.showGlobal ? "space-between" : "center")};
      width: 100%;
      gap: 4px;
      font-size: ${(p) => p.theme.typography.subText5};
      align-items: center;

      .auth {
        background: ${(p) => p.theme.colors.white.primary20};
        padding: 2px 8px;
        border-radius: 6px;
        font-size: ${(p) => p.theme.typography.subText3};
        color: ${(p) => p.theme.colors.text.secondary};
      }
    }
    .description {
      font-size: ${(p) => p.theme.typography.subText1};

      &.hide {
        display: none;
      }
    }
  }
`;
