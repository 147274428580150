import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store/store";

interface EventBusSliceState {
  userLogoutEvent: number | null; // null no logout event has been emitted
}
const initialState = {
  userLogoutEvent: null,
};

const eventBusSlice = createSlice({
  name: "eventBus",
  initialState,
  reducers: {
    emitUserLogoutEvent: (
      state: Draft<EventBusSliceState>,
      action: PayloadAction<{ randomId: number }>
    ) => {
      state.userLogoutEvent = action.payload.randomId;
    },
    resetEventBusSlice: (state: Draft<EventBusSliceState>) => {
      state.userLogoutEvent = null;
    },
  },
});

export const { resetEventBusSlice, emitUserLogoutEvent } = eventBusSlice.actions;

export default eventBusSlice.reducer;

export const getUserLogoutEvent = (state: RootState) => state.eventBus.userLogoutEvent;
