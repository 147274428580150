const commonRoutes = {
  home: "/",
};

export const AUTH_ROUTES = {
  ...commonRoutes,
  login: "/login",
  register: "/register",
  forgotPassword: "/forgotPassword",
};
export const ROUTES = {
  ...commonRoutes,
  dashBoard: "/dashBoard",
  wallet: "/wallet",
  creatorsProfile: "/creatorsProfile",
  editProfile: "/editProfile",
  collectionDetails: "collectionDetails/:id",
  placebid: "/placeBid/*",
  approveImportedCollection: "/approveCollection/:collectionId/*",
};
