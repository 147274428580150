import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { GiftButtonContainer } from "./style";

import ArrowDownSmall from "assets/svg/ArrowDownSmall";
import Switch from "Components/Switch";
import { updateProfileInfoAPI } from "services/UserServices";
import { getUser } from "store/slices/global/userSlice";
import { BlockCard } from "styles/mixins";

const GiftButton = () => {
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(false);

  const handleChange = () => {
    setShow(!show);
    updateProfileInfoAPI({
      data: {
        bioShowSendGift: !show,
      },
      part: "general",
    });
  };

  useEffect(() => {
    if (!user) return;
    if ("bioShowSendGift" in user) {
      setShow(user.bioShowSendGift);
    } else {
      setShow(true);
    }
  }, [user]);

  return (
    <GiftButtonContainer opened={open}>
      <BlockCard className="change">
        <div className="switchContainer">
          <Switch label={t("common.showSendGift")} value={show} onChange={handleChange} />
          <div className="arrow" onClick={() => setOpen(!open)}>
            <ArrowDownSmall />
          </div>
        </div>
        <div className="hint">
          <p className="expandable">{t("common.showSendGiftDesc")}</p>
        </div>
      </BlockCard>
    </GiftButtonContainer>
  );
};

export default GiftButton;
