import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store/store";
import {
  ICampaignFilterData,
  IEarningsData,
  IEarningReport,
  ISubscribersData,
  ISocialGrown,
  IFeedContent,
  IEarning,
  ISubscribers,
} from "types/creator";
import { IPlans } from "types/previewProfile";

interface UniqueVideosProps {
  append: boolean;
  oldData: { _id: string }[];
  newData: { _id: string }[];
}

const getUniqueVideos = ({ append, oldData, newData }: UniqueVideosProps) => {
  if (append) {
    // creates new array of new videos
    const newArr = [];

    // loop over new videos
    for (let j = 0; j < newData.length; j++) {
      let obj = true;

      // loop over old videos
      for (let i = 0; i < oldData.length; i++) {
        // check in every old video ID is equal to any new video ID or not
        if (oldData[i]._id === newData[j]._id) {
          obj = false;
          break;
        }
      }

      // if any id does not matche of new and old people then only add people from the new data array
      if (obj) newArr.push(newData[j]);
    }
    return [...oldData, ...newArr];
  }
  return newData;
};

interface State {
  earningReport: IEarningReport;
  summaryTableVideos: { data: any[]; totalVideos: string };
  approvedVideosOfCurrentWeek: any;
  creators: { totalCreators: string; data: any[] };
  community: { total: string; data: any[] };
  profileGrowth: ISocialGrown[];
  campaignFilterData: ICampaignFilterData;
  myFeed: { data: IFeedContent[]; total: number };
  subscriptionManage: { data: IPlans[]; total: number };
  subscribersList: ISubscribersData;
  subscriptionRefundReqList: any[];
  subscriptionRefundReqCount: number;
  skipSubscriptionRefundReqCount: number;
  earnings: IEarningsData;
  totalEarning: number;
  monthlyEarning: number;
}

const initialState: State = {
  earningReport: {
    data: [],
    Last14days: 0,
    Last30days: 0,
    Last7days: 0,
  },
  summaryTableVideos: {
    totalVideos: "",
    data: [],
  },
  approvedVideosOfCurrentWeek: null,
  creators: {
    totalCreators: "",
    data: [],
  },
  community: {
    total: "",
    data: [],
  },
  profileGrowth: [],
  campaignFilterData: {
    searchWord: "",
    sortType: null,
    sortFrom: null,
    navigatedTo: null,
    period: null,
    status: null,
    category: null,
  },
  myFeed: {
    data: [],
    total: 0,
  },
  subscriptionManage: {
    data: [],
    total: 0,
  },
  subscribersList: {
    data: [],
    total: 0,
  },
  subscriptionRefundReqList: [],
  subscriptionRefundReqCount: 0,
  skipSubscriptionRefundReqCount: 0,
  earnings: {
    data: [],
    total: 0,
  },
  totalEarning: 0,
  monthlyEarning: 0,
};

const creatorSlice = createSlice({
  name: "creator",
  initialState,
  reducers: {
    setSubscriptionPlan: (
      state,
      action: PayloadAction<{ result: { data: IPlans[] }; totalPlans: number }>
    ) => {
      state.subscriptionManage.data = [
        ...state.subscriptionManage.data,
        ...action.payload?.result?.data,
      ];
      state.subscriptionManage.total = action.payload.totalPlans;
    },
    resetSubscriptionPlan: (state) => {
      state.subscriptionManage.data = [];
      state.subscriptionManage.total = 0;
    },
    editEnableSubscriptionPlan: (state, action: PayloadAction<{ id: string; active: 0 | 1 }>) => {
      state.subscriptionManage.data = state.subscriptionManage.data.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active: action.payload.active,
          };
        }
        return item;
      });
    },
    setEarnings: (
      state,
      action: PayloadAction<{
        data: {
          records: IEarning[];
          totalDocument: number;
          totalEarned: number;
          totalLast30daysEarned: number;
        };
      }>
    ) => {
      state.earnings.data = action.payload?.data?.records;
      state.earnings.total = action.payload?.data?.totalDocument;
      state.totalEarning = action?.payload?.data?.totalEarned;
      state.monthlyEarning = action?.payload?.data?.totalLast30daysEarned;
    },
    setMyFeed: (state, action: PayloadAction<{ result: IFeedContent[]; totalCount: number }>) => {
      state.myFeed.data = [...state.myFeed.data, ...action.payload.result];
      state.myFeed.total = action.payload.totalCount;
    },
    deletePost: (state, action: PayloadAction<string>) => {
      state.myFeed.data = state.myFeed.data.filter((item) => item._id !== action.payload);
    },
    changeStatusPost: (
      state,
      action: PayloadAction<{ id: string; enabled?: boolean; description?: string }>
    ) => {
      state.myFeed.data = state.myFeed.data.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            enabled: action.payload.enabled!,
          };
        }
        return item;
      });
    },
    resetMyFeed: (state) => {
      state.myFeed.data = [];
      state.myFeed.total = 0;
    },
    setSubscriptionRefundReq: (
      state,
      action: PayloadAction<{ result: any; totalCount: number }>
    ) => {
      state.subscriptionRefundReqList = action.payload.result;
      state.subscriptionRefundReqCount = action.payload.totalCount;
    },
    setSkipSubscriptionRefundReqCount: (state, action: PayloadAction<{ skipCount: number }>) => {
      state.skipSubscriptionRefundReqCount = action.payload.skipCount;
    },
    setSubscribers: (state, action: PayloadAction<{ data: ISubscribers[]; count: number }>) => {
      state.subscribersList.data = action.payload.data;
      state.subscribersList.total = action.payload.count;
    },
    setEarningReport: (state, action: PayloadAction<IEarningReport>) => {
      state.earningReport = action.payload;
    },
    setProfileGrowth: (state, action: PayloadAction<ISocialGrown[]>) => {
      state.profileGrowth = action.payload;
    },
    setCommunity: (state, action: PayloadAction<{ total: string; append: any; data: any }>) => {
      const { total, append, data } = action.payload;

      state.community.total = total;
      state.community.data = getUniqueVideos({
        append,
        oldData: state.community.data,
        newData: data,
      });
    },
    setApprovedVideosOfCurrentWeek: (state, action: PayloadAction<any>) => {
      state.approvedVideosOfCurrentWeek = action.payload;
    },
    resetCreatorSlice: (state) => {
      state = initialState;
    },
    setCampaignFilterData: (state, action: PayloadAction<{ filterData: ICampaignFilterData }>) => {
      state.campaignFilterData = action.payload.filterData;
    },
    setVideos: (state, action: PayloadAction<{ data: any; totalVideos: string; page: any }>) => {
      state.summaryTableVideos.totalVideos = action.payload.totalVideos;
      state.summaryTableVideos.data = {
        ...state.summaryTableVideos.data,
        [`page${[action.payload.page]}`]: action.payload.data,
      };
    },
  },
});

export const {
  setEarningReport,
  setMyFeed,
  changeStatusPost,
  resetMyFeed,
  resetCreatorSlice,
  editEnableSubscriptionPlan,
  setApprovedVideosOfCurrentWeek,
  setCommunity,
  setProfileGrowth,
  setCampaignFilterData,
  setSubscribers,
  setSubscriptionRefundReq,
  setSkipSubscriptionRefundReqCount,
  setSubscriptionPlan,
  resetSubscriptionPlan,
  setEarnings,
  setVideos,
  deletePost,
} = creatorSlice.actions;
export default creatorSlice.reducer;

export const getVideos = ({ creator }: RootState) => creator.main.summaryTableVideos;
export const getEarningReport = ({ creator }: RootState) => creator.main.earningReport;
export const getProfileGrowth = ({ creator }: RootState) => creator.main.profileGrowth;
export const getCreators = ({ creator }: RootState) => creator.main.creators;
export const getApprovedVideosOfCurrentWeek = ({ creator }: RootState) =>
  creator.main.approvedVideosOfCurrentWeek;
export const getCommunity = ({ creator }: RootState) => creator.main.community;
export const getCampaignFilterData = ({ creator }: RootState) => creator.main.campaignFilterData;
export const getMyFeed = ({ creator }: RootState) => creator.main.myFeed;
export const getSubscribersList = ({ creator }: RootState) => creator.main.subscribersList;
export const getSkipSubscriptionRefundReqCount = ({ creator }: RootState) =>
  creator.main.skipSubscriptionRefundReqCount;
export const getSubscriptionRefundReqList = ({ creator }: RootState) =>
  creator.main.subscriptionRefundReqList;
export const getSubscriptionRefundReqCount = ({ creator }: RootState) =>
  creator.main.subscriptionRefundReqCount;
export const getSubscriptionManage = ({ creator }: RootState) => creator.main.subscriptionManage;
export const getEarnings = ({ creator }: RootState) => creator.main.earnings;
export const getTotalEarnings = ({ creator }: RootState) => creator.main.totalEarning;
export const getMonthlyEarnings = ({ creator }: RootState) => creator.main.monthlyEarning;
