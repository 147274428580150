import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";
import { hidePhoneInputArrows, HideScrollBar, ShowScrollBar } from "styles/mixins";

export const Wrapper = styled.div<{ textarea: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Label = styled.label<{ fontSize?: string; readonly?: boolean }>`
  display: inline-flex;
  gap: 4px;
  color: ${(p) => (p.readonly ? p.theme.colors.text.secondary : p.theme.colors.text.primary)};
  font-size: ${(p) => p.theme.typography.subText5};
  width: 100%;
  justify-content: space-between;
  text-overflow: ellipsis;

  .right {
    color: ${(p) => p.theme.colors.text.secondary};
  }
`;

export const InputArea = styled.div<{
  textarea: boolean;
  error: boolean;
  isValue: string | number | readonly string[] | undefined;
  readonly?: boolean;
  color?: string;
  link?: boolean;
}>`
  display: flex;
  align-items: center;
  background: ${(p) => (p.color ? p.color : p.theme.colors.background.secondary)};
  border-radius: 12px;

  ${(p) =>
    p.readonly
      ? css`
          border: 1px solid ${p.theme.colors.field.empty};
        `
      : css`
          border: 1px solid
            ${p.isValue && p.link && !p.error
              ? p.theme.colors.green.primary
              : p.isValue && !p.error
              ? p.theme.colors.field.full
              : p.error
              ? p.theme.colors.text.error
              : p.theme.colors.field.empty};
        `}

  ${(p) =>
    p.link &&
    css`
      &:focus-within {
        border: 1px solid ${p.error ? p.theme.colors.text.error : p.theme.colors.text.third};
      }
    `}

  position: relative;
  overflow: hidden;

  & > .right {
    display: flex;
    padding: 12px 12px 12px 0px;
  }

  input[readonly],
  textarea[readonly] {
    &::placeholder {
      color: ${(p) => p.theme.colors.text.secondary};
    }
    color: ${(p) => p.theme.colors.text.secondary};
  }

  input {
    width: 100%;
    &::placeholder {
      color: ${(p) => p.theme.colors.text.placehorder};
    }
  }

  input {
    ${hidePhoneInputArrows};
    min-width: 0;
    padding: 12px 16px;
    caret-color: ${(p) => p.theme.colors.text.primary};
    flex: 1;
    background: transparent;
    outline: none;
    border: none;
    color: ${(p) => p.theme.colors.text.primary};
    font-family: ${(p) => p.theme.ff.outfit.regular};

    &::placeholder {
      font-family: ${(p) => p.theme.ff.outfit.regular};
      font-weight: normal;
    }
  }

  textarea {
    ${hidePhoneInputArrows};
    ${HideScrollBar};
    display: flex;
    flex-direction: column;
    resize: none;
    min-width: 0;
    padding: 12px 16px;
    height: 90px;
    caret-color: ${(p) => p.theme.colors.text.primary};
    flex: 1;
    background: transparent;
    outline: none;
    border: none;
    color: ${(p) => p.theme.colors.text.primary};
    font-family: ${(p) => p.theme.ff.outfit.regular};

    &::placeholder {
      color: ${(p) => p.theme.colors.text.placehorder};
      font-family: ${(p) => p.theme.ff.outfit.regular};
      font-weight: normal;
    }
  }

  &.textareaWrap {
    textarea {
      ${ShowScrollBar}
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px
      ${(p) => (p.color ? p.color : p.theme.colors.background.secondary)} inset;
    box-shadow: 0 0 0 30px ${(p) => (p.color ? p.color : p.theme.colors.background.secondary)} inset;
    -webkit-text-fill-color: ${(p) => p.theme.colors.text.primary};
  }

  ${deviceQuery.tabletS} {
    min-height: ${(p) => (p.textarea ? "90px" : "unset")};
  }
`;

export const ErrorMsg = styled.p`
  color: ${(p) => p.theme.colors.text.error};
  font-size: ${(p) => p.theme.typography.subText3};
`;

export const Info = styled.p`
  color: ${(p) => p.theme.colors.text.secondary};
  font-size: ${(p) => p.theme.typography.subText3};
`;
