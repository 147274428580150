import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import ArrowLeft from "assets/svg/ArrowLeft";
import Lock from "assets/svg/Lock";
import QuestionBig from "assets/svg/QuestionBig";
import Unlock from "assets/svg/Unlock";
import { changeTotalCounts } from "common/helpers/helpers";
import Avatar from "Components/Avatar";
import ConfirmModal from "Components/ConfirmModal";
import { UploadProgressBar } from "Components/UploadProgressBar";
import { ROLES } from "constants/appConstants";
import {
  ChatHeaderStyles,
  StatusContainer,
  UploadProgressBarWrapperStyles,
} from "pages/public/chat/Components/Chat/ChatHeader/styles";
import { updateChatStatusById } from "services/newMessagesService";
import {
  getChatsCount,
  getOpenedChat,
  removeOneChat,
  setCurrentStatusTab,
  setOpenedChat,
} from "store/slices/global/chatsSlice";
import { getUser } from "store/slices/global/userSlice";
import {
  removeAllMessages,
  selectContentUploadProgress,
} from "store/slices/newMessages/messagesSlice";
import { ChatStatus } from "types/new-messages.types";
import { UserRole } from "types/user-role";

interface Props {
  role: UserRole;
}

export const ChatHeader: FC<Props> = ({ role }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const openedChat = useSelector(getOpenedChat);
  const contentUploadProgress = useSelector(selectContentUploadProgress);
  const user = useSelector(getUser);
  const total = useSelector(getChatsCount);
  const [show, setShow] = useState(false);

  const updateChatStatus = (status: ChatStatus, tabIndex: number) => {
    if (!openedChat) return;

    updateChatStatusById(openedChat._id, status, () => {
      changeTotalCounts(openedChat, status, total);
      dispatch(setOpenedChat({ ...openedChat, status }));

      if (status === "blocked") {
        dispatch(removeOneChat({ id: openedChat._id }));
      } else {
        dispatch(setCurrentStatusTab({ status, tabIndex }));
      }

      setShow(false);
    });
  };

  const handleBack = () => {
    dispatch(removeAllMessages());
    dispatch(setOpenedChat(null));
  };

  const StatusSettings = useMemo(() => {
    if (!openedChat || !user || role === ROLES.creator) return <></>;
    switch (openedChat?.status) {
      case "active":
        return (
          <StatusContainer isActive onClick={() => setShow(true)}>
            {t("new_chat.blockUser")} <Lock size={24} />
          </StatusContainer>
        );

      case "blocked":
        return (
          <StatusContainer isActive={false} onClick={() => setShow(true)}>
            {t("new_chat.unblockUser")} <Unlock />
          </StatusContainer>
        );

      case "pending_approval":
      default:
        return <></>;
    }
  }, [openedChat, user, t]);

  return (
    <ChatHeaderStyles>
      {openedChat && (
        <>
          <div className="info">
            <div className="arrow" onClick={handleBack}>
              <ArrowLeft />
            </div>
            <Avatar
              size="xs"
              role={openedChat.anotherUser.role}
              src={openedChat.anotherUser.pictureUrl_80_80}
              alt="user avatar"
            />
            <span className="name">
              {openedChat.anotherUser.displayname || openedChat.anotherUser.username}
            </span>
          </div>
          {StatusSettings}

          <ConfirmModal
            show={show}
            showCloseButton
            closeModal={() => setShow(false)}
            title={`${t(openedChat.status === "active" ? "new_chat.block" : "new_chat.unblock")} ${
              openedChat.anotherUser.displayname || openedChat.anotherUser.username
            }`}
            Icon={<QuestionBig />}
            isGreenBtn={openedChat.status === "blocked"}
            deleteBtnText={t(
              openedChat.status === "active" ? "new_chat.yesBlock" : "new_chat.yesUnblock"
            )}
            cancelBtnText={t("common.cancel")}
            onConfirmDelete={() =>
              openedChat.status === "active"
                ? updateChatStatus("blocked", 2)
                : updateChatStatus("active", 0)
            }
          />
        </>
      )}

      <UploadProgressBarWrapperStyles
        isHidden={contentUploadProgress === 0 || contentUploadProgress === 100}
      >
        <UploadProgressBar
          className="progress_absolute"
          progress={contentUploadProgress}
          message="Content is uploading"
        />
      </UploadProgressBarWrapperStyles>
    </ChatHeaderStyles>
  );
};
