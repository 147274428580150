import { createGlobalStyle } from "styled-components";

import { OutfitMedium, OutfitRegular, rotate } from "./mixins";

const GlobalStyle = createGlobalStyle`
  html, body, p, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 0;
    padding: 0;
  }

  .backDrop-transparent.show {
    opacity: 0;
  }

  *::-webkit-scrollbar {
    width: 3px;
    height: 5px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
    
  }
  
  *::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.colors.text.secondary};
    border-radius: 2px;
  }
  *::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .lewkIcon {
    fill: ${(p) => p.theme.colors.background.secondary};
  }

  ul {
    padding: 0;
    margin: 0;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .flex-1 {
    flex: 1;
  }

  .spin-animation {
    animation: ${rotate} 0.5s infinite ease-in;
  }

  .label {
    color: ${(p) => p.theme.colors.icon.secondary};
    text-transform: uppercase;
    font-size: 0.85rem;
    font-family: ${(p) => p.theme.ff.outfit.regular};
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
  }

  .container{
    padding: 0px 30px;
  }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left !important;
  }

  .PhoneInput {
    background: ${(p) => p.theme.colors.background.secondary};
    border-radius: 3px;
    border: 1px solid ${(p) => p.theme.colors.icon.secondary};
    height: 46px;
  }

  .PhoneInputInput {
    border: none;
    outline: none;
    height: 100%;
    background: transparent;
  }

  .select__menu-list::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }

  .select__menu-list::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .select__menu-list::-webkit-scrollbar-thumb {
    background: #888;
  }
  .select__menu-list::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .font-14{
    font-size:14px;
  }
  .color-gray-primary {
    color: ${(p) => p.theme.colors.text.secondary}
  }
  .color-blue-priamry {
    color: ${(p) => p.theme.colors.background.secondary};
  }
  .outfit-medium {
    ${OutfitMedium};
  }

  :root {
    --coin-size: 120px;
    --middle-of-coin-translateX: 52px;
  }
  /* loading coin */
  .purse {
    height: var(--coin-size);
    width: var(--coin-size);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -80px;
    margin-left: -80px;
    -webkit-perspective: 1000;
    -webkit-box-reflect: below 0
      linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0) 45%,
        rgba(255, 255, 255, 0.2)
      );
    -webkit-filter: saturate(1.45) hue-rotate(2deg);
  }
  .coin {
    height: var(--coin-size);
    width: var(--coin-size);
    position: absolute;
    animation-name: spin;
    animation-timing-function: linear;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-transform-style: preserve-3d;
    -webkit-transform-origin: 50%;
    -webkit-animation: spin 1.5s infinite;
    -webkit-animation-timing-function: linear;
  }
  .coin .front,
  .coin .back {
    position: absolute;
    height: var(--coin-size);
    width: var(--coin-size);
    border-radius: 50%;
    background-size: cover;
  }
  .coin .front {
    -webkit-transform: translateZ(7px);
  }
  .coin .back {
    -webkit-transform: translateZ(-8px) rotateY(-180deg);
  }
  .coin .side {
    -webkit-transform: translateX(var(--middle-of-coin-translateX));
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
  }
  .coin .side .spoke {
    height: var(--coin-size);
    width: 16px;
    position: absolute;
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
  }
  .coin .side .spoke:before,
  .coin .side .spoke:after {
    content: "";
    display: block;
    height: 15.68274245273px;
    width: 16px;
    position: absolute;
    -webkit-transform: rotateX(84.375deg);
    background: #25c374;
    background: linear-gradient(
      to bottom,
      #6BC676 0%,
      #25c374 74%,
      #25c374 75%,
      #68C9B8 100%
    );
    background-size: 100% 3.4850538783844px;
  }
  .coin .side .spoke:before {
    -webkit-transform-origin: top center;
  }
  .coin .side .spoke:after {
    bottom: 0;
    -webkit-transform-origin: center bottom;
  }
  .coin .side .spoke:nth-child(16) {
    -webkit-transform: rotateY(90deg) rotateX(180deg);
  }
  .coin .side .spoke:nth-child(15) {
    -webkit-transform: rotateY(90deg) rotateX(168.75deg);
  }
  .coin .side .spoke:nth-child(14) {
    -webkit-transform: rotateY(90deg) rotateX(157.5deg);
  }
  .coin .side .spoke:nth-child(13) {
    -webkit-transform: rotateY(90deg) rotateX(146.25deg);
  }
  .coin .side .spoke:nth-child(12) {
    -webkit-transform: rotateY(90deg) rotateX(135deg);
  }
  .coin .side .spoke:nth-child(11) {
    -webkit-transform: rotateY(90deg) rotateX(123.75deg);
  }
  .coin .side .spoke:nth-child(10) {
    -webkit-transform: rotateY(90deg) rotateX(112.5deg);
  }
  .coin .side .spoke:nth-child(9) {
    -webkit-transform: rotateY(90deg) rotateX(101.25deg);
  }
  .coin .side .spoke:nth-child(8) {
    -webkit-transform: rotateY(90deg) rotateX(90deg);
  }
  .coin .side .spoke:nth-child(7) {
    -webkit-transform: rotateY(90deg) rotateX(78.75deg);
  }
  .coin .side .spoke:nth-child(6) {
    -webkit-transform: rotateY(90deg) rotateX(67.5deg);
  }
  .coin .side .spoke:nth-child(5) {
    -webkit-transform: rotateY(90deg) rotateX(56.25deg);
  }
  .coin .side .spoke:nth-child(4) {
    -webkit-transform: rotateY(90deg) rotateX(45deg);
  }
  .coin .side .spoke:nth-child(3) {
    -webkit-transform: rotateY(90deg) rotateX(33.75deg);
  }
  .coin .side .spoke:nth-child(2) {
    -webkit-transform: rotateY(90deg) rotateX(22.5deg);
  }
  .coin .side .spoke:nth-child(1) {
    -webkit-transform: rotateY(90deg) rotateX(11.25deg);
  }
  .coin.skeleton .front,
  .coin.skeleton .back {
    display: none;
  }
  .coin.skeleton .side,
  .coin.skeleton .side .spoke,
  .coin.skeleton .side .spoke:before,
  .coin.skeleton .side .spoke:after {
    -webkit-backface-visibility: visible;
  }
  .coin.skeleton .side .spoke {
    background: rgba(170, 170, 170, 0.1);
  }
  .coin.skeleton .side .spoke:before {
    background: rgba(255, 170, 170, 0.2);
  }
  .coin.skeleton .side .spoke:after {
    background: rgba(204, 204, 255, 0.2);
  }
  body {
    background-color: ${(p) => p.theme.colors.background.primary};
  }
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotateY(0deg);
    }
    to {
      -webkit-transform: rotateY(360deg);
    }
  }

  .coin .front {
    background-image: url("/img/logo.png"), linear-gradient(45deg, #1ac6b9, #25c374);
  }
  .coin .back {
    background-image: url("/img/logo.png"), linear-gradient(45deg, #1ac6b9, #25c374);
  }

  .height-fit {
    height:fit-content;
  }

  .text-left{
    text-align:left;
  }
  .css-1e6coao:hover,.list__item:hover,.css-vfmw6p,.css-90flt1-CometChatUserListItem{
    .item__details__name,.css-18fmwpd{
      color: #25c374;
    }
  }

  @media (min-width: 320px) and (max-width: 767px){
    .css-2t6469,.css-vpnasf {
      background-color:#151529 !important;
    }
  }
  
  .collapse-box{
    background: #151529;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
  }

  .collapse-field{
    background: #2e2e44;
    border-radius: 4px;
    padding: 5px;
    margin-top: 4px;
    color: #68687e;
  }

  .collapse-field-criteria{
    border-radius: 4px;
    padding: 5px;
    margin-top: 4px;
    color: #68687e;
  }
  
  .filterDatePicker .react-datetimerange-picker__wrapper {
    padding: 9px 2px;
    border-radius: 5px;
    border: 1px solid #47475c;
    background: #2e2e44;
}
@media (max-width: 575px){
.pr-sm-0{
  padding-right:0px;
}
}
.uniqueListing {
  --circle-size: 10px;
  margin: 5px 0;
  padding-left: calc(var(--circle-size) + 7px);
  position: relative;
  ${OutfitRegular};
  color: #74748d;
  font-size: 0.85rem;

  span {
    position: absolute;
    content: "";
    top: calc(var(--circle-size) / 2);
    left: 0;
    width: var(--circle-size);
    height: var(--circle-size);
    border-radius: 50%;
    &.list-dot_0 {
      background: ${(p) => p.theme.colors.graph.green};
    }

    &.list-dot_1 {
      background: ${(p) => p.theme.colors.graph.darkGreen};
    }

    &.list-dot_2 {
      background: ${(p) => p.theme.colors.graph.blue};
    }

    &.list-dot_3 {
      background: ${(p) => p.theme.colors.graph.lightBlue};
    }
  }
}
@media (max-width: 1331px){
.half-part .react-datetimerange-picker__wrapper{
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  font-size: 14px; 
  .react-datetimerange-picker__inputGroup__leadingZero{
    margin-top:1px;
  }
  .react-datetimerange-picker__button{
    width:19px;
    svg{
      width:15px;
    }
  }

  .react-datetimerange-picker__inputGroup{
    flex-grow:0 !important;
  }
}
}
@media (max-width: 767px){
 
  .half-part .react-datetimerange-picker__wrapper .react-datetimerange-picker__inputGroup{
      flex-grow:1 !important;
    }
    .half-part .react-datetimerange-picker__wrapper  .react-datetimerange-picker__button{
      width:31px;
      svg{
        width:19px;
      }
    }
  }
@media (max-width: 590px){
  .react-datetimerange-picker__wrapper,.half-part .react-datetimerange-picker__wrapper{
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    font-size: 14px;
    .react-datetimerange-picker__inputGroup__leadingZero{
      margin-top:1px;
    }
    .react-datetimerange-picker__button{
      width:19px;
      svg{
        width:15px;
      }
    }
  
    .react-datetimerange-picker__inputGroup{
      flex-grow:0 !important;
    }
  }
  }
  .cursor-not-allowed{
    cursor:not-allowed;
  }
  .ml-1{
    margin-left:0.5rem !important;
  }
  .m-w-150{
    min-width:150px;
  }
  .color-white{
    color:#fff !important;
  }
  .desc-style{
    span{
    color: #6B6B86 !important;
    }
  }
  .cursor-auto{
    cursor:auto;
  }
  .white-space-normal{
    white-space: normal !important;
  }
  .flex-1{
    flex:1
  }
  .hover-div{
    .toolTip{
      display:none;
      position: absolute;
      top: -28px;
      left: -44px;
      font-size: 12px;
      background: ${(p) => p.theme.colors.background.primary};
      color: ${(p) => p.theme.colors.text.primary};
      padding: 4px;
      border:1px solid ${(p) => p.theme.colors.field.empty};
      z-index:9;
    }
    &:hover .toolTip{
      display:inline-block;
    }

    .toolTipNft{
      display:none;
      position: absolute;
      top: -28px;
      right: 0px;
      font-size: 12px;
      background: ${(p) => p.theme.colors.background.primary};
      color: ${(p) => p.theme.colors.text.primary};
      padding: 4px;
      border:1px solid ${(p) => p.theme.colors.field.empty};
      z-index:9;
    }
    &:hover .toolTipNft{
      display:inline-block;
    }
  }
  .no-data{
    height: 128.3px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
  }

  .align-center {
    align-items: center;
  }
  .text-initial {
    text-transform: initial;
  }
  .cursor-auto {
    cursor:auto !important;
  }
  .font-15{
    font-size :15px !important;
  }
  .max-h-440{
    height:100% !important;
    height :440px !important;
  }
  .max-h-5-5{
    max-height: 5.5rem !important;
  }
 .margin-left-auto{
  margin-left : auto
 }
 .visibility-hidden {
  visibility:hidden;
 }
 .direction-column{
  flex-direction : column;
 }
 progress {
  width : 100%;
  border-radius: 10px;
  overflow: hidden;
  background:#808080;
  height: 8px;
  border: none;
  -webkit-appearance: none;
 }
 /* For Firefox */
 progress::-moz-progress-bar {
  background: ${(p) => p.theme.colors.green.primary};
 }
 progress::-moz-progress-value {
  background: ${(p) => p.theme.colors.green.primary};
 }
progress::-webkit-progress-bar {
  background: transparent;
}

 /* For Chrome or Safari */
 progress::-webkit-progress-value {
     background: ${(p) => p.theme.colors.green.primary};
 }
.split-skeleton{
  .react-loading-skeleton{
    --base-color: #cbcbe0;
    --highlight-color: #eeeef9;
  }
}
.no-data-split{
  font-size:20px;
  color: rgb(104, 104, 126)
}
.justify-content-left{
  justify-content:left;
}
.pl-0{
  padding-left:0px;
}
.pr-0{
  padding-right:0px;
}
svg.trends {
  color: ${(p) => p.theme.colors.text.primary} !important;
 }
 svg.campaigns {
  color: ${(p) => p.theme.colors.graph.blue} !important;
 }
 svg.idea-campaigns {
  color: ${(p) => p.theme.colors.yellow.primary} !important;
 }
 .mr-0.5{
  margin-right:3px !important;
}
 .about-it{
  display:inline-block;
  position:relative;
  background: ${(p) => p.theme.colors.icon.secondary};
  padding: 2px 9px 0px 5px;
  &.input-it{
    padding: 2px 5px 0px 5px; 
  }
  border-radius: 12px;
  margin-left: 4px;
  margin-bottom: 2px;
   .toolTip{
  display:none;
  position: absolute;
  font-size: 12px;
  text-transform:initial;
  background: ${(p) => p.theme.colors.background.primary};
  color: ${(p) => p.theme.colors.text.primary};
  transform: translate(-39%,-111%);
  padding: 1px 4px 0px 4px;
  border:1px solid ${(p) => p.theme.colors.field.empty};
  z-index:9;
  width:max-content;
  max-width:265px;
  &.left-tool{
    transform: translate(-92%,-111%);
    @media(max-width:768px){
      transform: translate(-39%,-111%);
    }
  }
}
&:hover .toolTip{
  display:inline-block;
}
}
.new-link {
  color: ${(p) => p.theme.colors.green.primary};
    font-weight: bold;
    -webkit-text-decoration: none;
    text-decoration: none;
  &:hover{
  text-decoration: underline;
  color: ${(p) => p.theme.colors.green.primary};
  -webkit-text-decoration-color:${(p) => p.theme.colors.green.primary};
  text-decoration-color:${(p) => p.theme.colors.green.primary};
  }

}
.pro-level{
  -webkit-animation: pro-level 1s ease-in-out infinite alternate;
  -moz-animation: pro-level 1s ease-in-out infinite alternate;
  animation: pro-level 1s ease-in-out infinite alternate;
}
.pro-image-wrapper{
  -webkit-animation: pro-image-wrapper 1s ease-in-out infinite alternate;
  -moz-animation: pro-image-wrapper 1s ease-in-out infinite alternate;
  animation: pro-image-wrapper 1s ease-in-out infinite alternate;
}
.desc-wrapper{
  color:#fff !important;
  span{
    color:#6B6B86 !important;
  }
}
.text-md-right{
  @media (min-width: 768px) {
    text-align :right !important;
    }  
}
.ml-lg-3 {
  @media (min-width: 980px) {
  margin-left:1.5rem;
  }
}

  
`;

export default GlobalStyle;
