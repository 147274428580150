import { ROLES } from "constants/appConstants";

export const PREFIX_ROUTES = {
  admin: `/${ROLES.admin}`,
  creator: `/${ROLES.creator}`,
  publisher: `/${ROLES.publisher}`,
  judge: `/${ROLES.judge}`,
  fan: `/${ROLES.fan}`,
};

export const ROUTES = {
  creatorPublicProfile: "/creator/community/profile",
  dashboard: "/dashboard",
  trends: "/trending",
  home: "/",
  commonCreatorProfile: "/creator/profile",
  fanDashBoard: `${PREFIX_ROUTES.fan}/dashBoard`,
  fanFeeds: `${PREFIX_ROUTES.fan}/feeds`,
  fanCreators: `${PREFIX_ROUTES.fan}/creators`,
  fanCreatorsProfile: `${PREFIX_ROUTES.fan}/creatorsProfile`,
  fanWallet: `${PREFIX_ROUTES.fan}/wallet`,
  fanEditProfile: `${PREFIX_ROUTES.fan}/editprofile/general`,
  fanPaymentHistory: `${PREFIX_ROUTES.fan}/paymentHistory`,
  fanSubscribers: `${PREFIX_ROUTES.fan}/subscribers`,
  fanAllSubscriptions: `${PREFIX_ROUTES.fan}/all-subscriptions`,
  login: "/login",
  register: "/register",
  forgotPassword: "/forgot-password",
  authentication: "/authentication",
  adminLogin: `${PREFIX_ROUTES.admin}/login`,
  recoveryCode: "/recovercode",
  adminConfiguration: `${PREFIX_ROUTES.admin}/configuration`,
  adminChallengeReward: `${PREFIX_ROUTES.admin}/challenge-reward`,
  adminSearchPeople: `${PREFIX_ROUTES.admin}/people`,
  adminSignuprequests: `${PREFIX_ROUTES.admin}/signup-requests`,
  adminReports: `${PREFIX_ROUTES.admin}/report`,
  adminCampaigns: `${PREFIX_ROUTES.admin}/campaign`,
  adminRefundRequests: `${PREFIX_ROUTES.admin}/refund-requests`,
  adminMessages: `${PREFIX_ROUTES.admin}/messages`,
  adminNFTs: `${PREFIX_ROUTES.admin}/nft?page=1`,
  adminNFTDetail: `${PREFIX_ROUTES.admin}/nftdetail`,
  judgeVideos: `${PREFIX_ROUTES.judge}/videos`,
  judgeSearchCreators: `${PREFIX_ROUTES.judge}/creators`,
  creatorDashBoard: `${PREFIX_ROUTES.creator}/dashboard`,
  creatorWallet: `${PREFIX_ROUTES.creator}/wallet`,
  creatorEditProfile: `${PREFIX_ROUTES.creator}/editprofile`,
  creatorMonetization: `${PREFIX_ROUTES.creator}/monetization`,
  creatorCampaign: `${PREFIX_ROUTES.creator}/campaigns`,
  creatorCommunity: `${PREFIX_ROUTES.creator}/community`,
  creatorNFTDetail: `${PREFIX_ROUTES.creator}/nftdetail`,
  creatorMyNFTDetail: `${PREFIX_ROUTES.creator}/my-collectables-detail`,
  publisherDashBoard: `${PREFIX_ROUTES.publisher}/dashboard`,
  publisherWallet: `${PREFIX_ROUTES.publisher}/wallet`,
  publisherStandaloneCampaign: `${PREFIX_ROUTES.publisher}/campaigns?page1&type=standalone`,
  collectionDetails: "collectionDetails/:id",
  placebid: "/placeBid/*",
  approveImportedCollection: "/approveCollection/:collectionId/*",
  campaignDetails: "/creator/campaigns/standard/details/campaign-details",
  campaignCreatorVideos: "/creator/campaigns/standard/details/creator-videos",
  adminEmails: `${PREFIX_ROUTES.admin}/email-templates?page=1`,
  adminOnboardingScreen: `${PREFIX_ROUTES.admin}/onboarding?page=1`,
};
