const WhatsappColor = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.01 22.99C9.56001 22.99 7.12001 22.99 4.67001 22.99C4.15001 22.99 3.65 22.91 3.16 22.72C2.44 22.44 1.89999 21.94 1.51999 21.28C1.24999 20.81 1.09999 20.31 1.01999 19.78C1.00999 19.69 1 19.6 1 19.51C1 14.51 1 9.50999 1 4.50999C1 3.89999 1.13 3.31 1.44 2.77C1.76 2.22 2.20999 1.80999 2.76999 1.50999C3.29999 1.22999 3.85999 1.07 4.45999 1C4.50999 1 4.56002 1 4.61002 1C9.57002 1 14.52 1 19.48 1C20.02 1 20.55 1.07 21.05 1.27C21.95 1.62 22.52 2.26 22.82 3.17C22.96 3.59 23.02 4.03 23 4.48C22.99 4.79 23 5.1 23 5.41C23 10.11 23 14.8 23 19.5C23 19.96 22.93 20.4 22.77 20.83C22.51 21.49 22.07 22 21.47 22.38C20.96 22.7 20.39 22.9 19.79 23.01C19.72 23.02 19.66 23.01 19.59 23.01C17.07 23.01 14.55 23.01 12.03 23.01L12.01 22.99Z"
      fill="#2AB218"
    />
    <path
      d="M4.53003 19.38C4.92003 18.01 5.29003 16.7 5.66003 15.4C5.72003 15.19 5.71005 15.02 5.61005 14.82C3.44005 10.31 6.65002 4.94003 11.68 4.63003C16.18 4.36003 19.83 8.05003 19.42 12.45C18.96 17.35 13.79 20.35 9.23004 18.35C8.95004 18.23 8.72002 18.21 8.43002 18.29C7.24002 18.64 6.04004 18.96 4.85004 19.29C4.76004 19.32 4.67002 19.33 4.52002 19.37L4.53003 19.38ZM6.58005 17.36C7.35005 17.14 8.06002 16.95 8.77002 16.75C8.96002 16.69 9.11004 16.7 9.29004 16.81C10.39 17.43 11.57 17.67 12.84 17.55C16.54 17.18 18.99 13.33 17.71 9.87003C16.52 6.65003 12.89 5.12004 9.77002 6.53004C6.64002 7.95004 5.43002 11.69 7.15002 14.69C7.25002 14.86 7.26002 15 7.21002 15.17C7.00002 15.87 6.81005 16.58 6.58005 17.36Z"
      fill="#F3F6F3"
    />
    <path
      d="M14 14.94C13.2 14.86 12.39 14.54 11.65 14.07C10.45 13.31 9.53003 12.28 8.88003 11.03C8.42003 10.13 8.57003 9.21002 9.22003 8.55002C9.65003 8.12002 10.29 8.23001 10.52 8.79001C10.64 9.09001 10.74 9.41001 10.9 9.68001C11.11 10.05 11.01 10.32 10.76 10.62C10.39 11.07 10.4 11.07 10.71 11.54C11.25 12.36 12.04 12.86 12.9 13.27C13.06 13.35 13.19 13.31 13.3 13.18C13.46 12.99 13.63 12.8 13.77 12.6C13.91 12.4 14.06 12.36 14.27 12.46C14.71 12.67 15.15 12.88 15.6 13.09C15.78 13.17 15.8 13.31 15.8 13.49C15.79 14.4 15.03 14.79 14.37 14.91C14.27 14.93 14.17 14.92 13.99 14.93L14 14.94Z"
      fill="#EBEEEB"
    />
  </svg>
);

export default WhatsappColor;
