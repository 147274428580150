import { FC } from "react";

interface Props {
  size?: number;
}

const MenuBurger: FC<Props> = ({ size = 32 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 8H26" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M6 16H26" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M6 24H26" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default MenuBurger;
