import { memo, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { ChatReplyContainer } from "./styles";

import Close from "assets/svg/Close";
import { Reply } from "assets/svg/Reply";
import { RepliedMessage } from "types/new-messages.types";

interface Props {
  messageToReply: RepliedMessage;
  className?: string;
  inChatStyles?: boolean;
  removeRepliedMessage?: () => void;
  goToReply?: (messageToReplyId: string) => void;
}

export const ChatReply = memo(
  ({ messageToReply, className, removeRepliedMessage, goToReply, inChatStyles = false }: Props) => {
    const { t } = useTranslation();
    const textOfMessage = useMemo(() => {
      if (messageToReply.messageType === "text") return messageToReply.text;
      if (messageToReply.messageType === "media") return t("new_chat.media");
      if (messageToReply.messageType === "media_card") return t("new_chat.media_content");
    }, [messageToReply]);

    return (
      <ChatReplyContainer
        className={className}
        inChatStyles={inChatStyles}
        onClick={() => goToReply && goToReply(messageToReply._id)}
      >
        <Reply />

        <div className="reply_message_info">
          <div>{`${t("new_chat.reply_to")} ${
            messageToReply.user.displayname || messageToReply.user.username
          }`}</div>
          <span>{textOfMessage}</span>
        </div>

        {!inChatStyles && (
          <div
            className="close_reply_btn"
            onClick={() => removeRepliedMessage && removeRepliedMessage()}
          >
            <Close size={24} />
          </div>
        )}
      </ChatReplyContainer>
    );
  }
);
