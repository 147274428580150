import styled from "styled-components";

export const Content = styled.div<{ opened: boolean }>`
  display: grid;
  grid-template-rows: ${(p) => (p.opened ? 1 : 0)}fr;
  transition: grid-template-rows 0.3s ease-in-out;

  & > .expandable {
    transition: padding-top 0.3s ease-in-out;
    padding-top: ${(p) => (p.opened ? 16 : 0)}px;
    overflow: hidden;
  }
`;
