import styled, { css } from "styled-components";

export const HeaderMenuContainer = styled.div<{ show: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 66px;
  right: 0px;
  width: 328px;
  padding: 8px;
  border-radius: 16px;
  background: ${(p) => p.theme.colors.background.secondary};
  font-size: ${(p) => p.theme.typography.subText5};
  ${(p) =>
    p.show
      ? css`
          pointer-events: all;
          opacity: 1;
        `
      : css`
          pointer-events: none;
          opacity: 0;
        `};
`;

export const MenuItem = styled.div`
  display: flex;
  padding: 16px;
  gap: 12px;
  border-radius: 12px;
  color: ${(p) => p.theme.colors.text.primary};

  &.hide {
    display: none;
  }

  .itemContent {
    display: flex;
    gap: 12px;
    align-items: center;
    flex: 1;

    .icon {
      position: relative;

      .counter {
        pointer-events: none;
        min-width: 12px;
        justify-content: center;
        align-items: center;
        height: 12px;
        display: flex;
        padding: 2px;
        font-size: ${(p) => p.theme.typography.smallText3};
        background: ${(p) => p.theme.colors.background.fourth};
        color: ${(p) => p.theme.colors.text.primary};
        border-radius: 8px;
        position: absolute;
        top: 0px;
        right: 0px;
      }
    }
  }

  .action {
    align-items: center;
    gap: 12px;
    display: flex;

    .arrow {
      transition: all 0.3s;
      align-items: center;
      display: flex;
      justify-content: center;
      transform: rotate(-90deg);
    }
  }

  &:hover {
    background: ${(p) => p.theme.colors.background.primary};
  }
`;
