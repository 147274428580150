const QuestionBig = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.2398 16.33C21.3298 15.24 21.9998 13.72 21.9998 12.05C21.9998 8.71 19.3098 6 15.9998 6C14.3398 6 12.8398 6.68 11.7598 7.77"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.24 16.3301C19.6 16.9801 18.81 17.4801 17.93 17.7801C16.77 18.1801 16 19.2701 16 20.5001"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 12.05C10 8.71 12.69 6 16 6"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 26C16.4142 26 16.75 25.6642 16.75 25.25C16.75 24.8358 16.4142 24.5 16 24.5C15.5858 24.5 15.25 24.8358 15.25 25.25C15.25 25.6642 15.5858 26 16 26Z"
      fill="currentColor"
    />
  </svg>
);

export default QuestionBig;
