import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";
import { HideScrollBar } from "styles/mixins";

export const TabsContainer = styled.div<{
  withLine: boolean;
  orientation: "vertial" | "horizontal";
  round: boolean;
}>`
  position: relative;
  width: 100%;
  display: flex;

  ${(p) =>
    !p.round &&
    css`
      overflow-x: scroll;
      min-height: 64px;
    `}

  ${HideScrollBar};

  .line {
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%;
    background: ${(p) => p.theme.colors.divider};
  }

  .tabs {
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    gap: ${(p) => (p.withLine ? "0px" : "16px")};
    width: 100%;
    min-width: fit-content;
    ${(p) =>
      p.round &&
      css`
        width: fit-content;
        border-radius: 30px;
        box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
        background: ${p.theme.colors.background.third};
        gap: 0px;
      `}

    ${(p) =>
      p.orientation === "vertial" &&
      css`
        flex-direction: column;

        .tab {
          width: 100%;
          flex-direction: column;

          .button {
            transition: all 0.5s;
            padding: 0px;
            background: none;
            color: ${p.theme.colors.text.primary};
          }
        }
      `}

    ${(p) =>
      p.orientation === "horizontal" &&
      css`
        flex-direction: row;
        ${p.round
          ? css`
              justify-content: flex-start;
            `
          : css`
              justify-content: center;
            `}

        .tab {
          width: fit-content;
          flex-direction: row;

          .button {
            transition: all 0.5s;

            ${p.round
              ? css`
                  background: transparent;
                  color: ${p.theme.colors.text.secondary};
                  padding: 12px 12px 12px 20px;
                  border-radius: 30px;
                `
              : css`
                  background: none;
                  color: ${p.theme.colors.text.primary};
                  padding: 16px 20px;
                `}
          }
        }

        ${deviceQuery.tabletM} {
          justify-content: flex-start;
        }
      `} 

    .tab {
      display: flex;
      gap: 10px;
      position: relative;
      min-width: fit-content;

      .line {
        position: absolute;
        bottom: 0px;
        left: 0px;
        height: 1px;
        width: 100%;
        background: ${(p) => p.theme.colors.divider};
        z-index: 1;
        transition: all 0.3s ease-out;
      }

      &.active {
        .button {
          ${(p) =>
            p.round
              ? css`
                  background: ${p.theme.colors.background.fourth};
                  color: ${p.theme.colors.white.primary};

                  .number {
                    background: rgba(255, 255, 255, 0.2);
                  }
                `
              : css`
                  color: ${p.theme.colors.text.third};
                `}
        }

        .line {
          background: ${(p) => p.theme.colors.green.primary};
        }
      }

      .button {
        display: flex;
        gap: 8px;
        align-items: center;
        border: none;

        .text {
          margin: 0px;
          text-align: start;
          font-size: ${(p) => p.theme.typography.subText5};
        }

        .number {
          border-radius: 16px;
          font-size: ${(p) => p.theme.typography.subText3};
          line-height: 20px;
          ${(p) =>
            p.round
              ? css`
                  width: 24px;
                  height: 24px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: ${p.theme.colors.background.fifth};
                `
              : css`
                  padding: 4px 8px;
                  background: ${p.theme.colors.background.secondary};
                `}
        }
      }
    }
  }
`;
