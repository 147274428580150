import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const ChatActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0;
  padding: 12px 16px;
  gap: 12px;
  border-bottom: 1px solid ${(p) => p.theme.colors.divider};

  .close {
    color: ${(p) => p.theme.colors.icon.secondary};
    cursor: pointer;

    &.active {
      color: ${(p) => p.theme.colors.icon.primary};
    }
  }

  .oneTab {
    .selected {
      left: 4px !important;
      width: calc(100% - 8px) !important;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    flex: 1;

    & > p {
      font-size: ${(p) => p.theme.typography.text3};
    }

    .icons {
      display: flex;
      align-items: center;
      gap: 12px;

      & > div {
        cursor: pointer;
        color: ${(p) => p.theme.colors.icon.secondary};

        &:hover,
        &.active {
          color: ${(p) => p.theme.colors.icon.primary};
        }
      }

      .setting {
        position: relative;
      }
    }
  }

  ${deviceQuery.tabletM} {
    padding: 0px 0px 16px;
  }
`;
