import { useEffect } from "react";

/**
 * A custom hook for updating the document title.
 *
 * @param {string | undefined} title - The title to set for the document.
 *
 * Updates the document's title whenever the `title` parameter changes.
 * If `title` is undefined or empty, it does nothing.
 */
export default (title: string | undefined): void => {
  useEffect(() => {
    if (!title) return;
    document.title = title;
  }, [title]);
};
