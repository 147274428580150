import { useFormikContext } from "formik";
import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { CurrencySelectorContainer } from "./styles";

import { AddContentModalForm } from "..";

import ArrowDownSmall from "assets/svg/ArrowDownSmall";
import Check from "assets/svg/Check";
import { getTokenGroupsOption } from "store/slices/walletSlice";

interface Props {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

const CurrencySelector: FC<Props> = ({ show, setShow }) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<AddContentModalForm>();
  const tokenGroups = useSelector(getTokenGroupsOption);

  return (
    <CurrencySelectorContainer show={show} onClick={() => setShow(!show)}>
      <div className="currency">
        <span>
          {values.currency ? values.currency.label : t("new_chat.add_content_modal.currency")}
        </span>
        <div className="arrow">
          <ArrowDownSmall />
        </div>
      </div>
      <div className="currencies">
        {tokenGroups.map((item, index) => (
          <div
            key={index}
            className={`item ${
              values.currency && item.value === values.currency.value ? "active" : ""
            }`}
            onClick={() => {
              setFieldValue("currency", item);
              setShow(false);
            }}
          >
            <span>{item.label}</span>
            {values.currency && item.value === values.currency.value && <Check />}
          </div>
        ))}
      </div>
    </CurrencySelectorContainer>
  );
};

export default CurrencySelector;
