import { colors } from "styles/theme";

/**
 * highlights occurrences of the search value within the original string by wrapping
 * the matching text in a span with a specific color
 * @param {string} originalString - original text to be searched
 * @param {string} searchValue - value to search for within the original string
 * @returns {JSX.Element} - span element containing the original string with highlighted search matches
 */
export const getHighlightedSearchedText = (originalString: string, searchValue: string) => {
  if (!searchValue) return originalString;

  // Escape special characters in the search value
  const escapedSearchValue = searchValue.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  // Create a regular expression with the escaped search value and case-insensitive flag
  const regex = new RegExp(`(${escapedSearchValue})`, "gi");

  // Split the original string by the search value and join it back with the search value wrapped in a span for highlighting
  const highlightedString = originalString.replace(
    regex,
    (match) => `<span style="color: ${colors.green.primary}">${match}</span>`
  );

  return <span dangerouslySetInnerHTML={{ __html: highlightedString }} />;
};
