import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import AdminPrivateRoute from "./AdminPrivateRoute";
import AuthRoute from "./AuthRoute";
import CreatorPrivateRoute from "./CreatorPrivateRoute";
import CreatorProfileRoute from "./CreatorProfileRoute";
import FanPrivateRoute from "./FanPrivateRoute";
import PrivateRoute from "./PrivateRoute";

import { NEW_ROLES, ROLES } from "constants/appConstants";
import { AuthLayout } from "layouts/AuthLayout";
import CommonLayout from "layouts/CommonLayout";
import HomeLayout from "layouts/HomeLayout";
import PreviewLayout from "layouts/PreviewLayout";
import ProfileLayout from "layouts/ProfileLayout";
import AuthenticationPage from "pages/auth/2FAAuth/AuthenticationPage";
import RecoveryCode from "pages/auth/2FAAuth/RecoveryCode";
import AdminLogin from "pages/auth/adminLogin";
import ForgotPassword from "pages/auth/ForgotPassword";
import Login from "pages/auth/Login";
import Register from "pages/auth/Register";
import BioCustomization from "pages/creator/Bio/BioCustomization";
import Banner from "pages/creator/Bio/BioCustomization/components/Banner";
import Wallpaper from "pages/creator/Bio/BioCustomization/components/Wallpaper";
import { BioLinkContainer } from "pages/creator/Bio/BioLink/BioLinkContainer";
import BioWall from "pages/creator/Bio/BioWall";
import General from "pages/creator/EditProfile/components/General";
import MFA from "pages/creator/EditProfile/components/MFA";
import Notification from "pages/creator/EditProfile/components/Notification";
import ProfileCredentials from "pages/creator/EditProfile/components/ProfileCredentials";
import VerifySocials from "pages/creator/EditProfile/components/VerifySocials";
import FanGeneral from "pages/fan/EditProfile/components/General";
import OnBoarding from "pages/onboarding";
import { ChatContainer } from "pages/public/chat";
import Home from "pages/public/home";
import NotFound from "pages/public/notFound";
import Privacy from "pages/public/Privacy";
import Terms from "pages/public/Terms";

// creators pages
const CreatorDashboard = lazy(() => import("../pages/creator/Dashboard"));
const CreatorWallet = lazy(() => import("../pages/creator/Wallet"));
const CreatorProfile = lazy(() => import("../pages/creator/EditProfile"));
const CreatorMessages = lazy(() => import("../pages/creator/messages"));
const FanMessages = lazy(() => import("../pages/creator/messages"));
const CreatorSubscribers = lazy(() => import("../pages/creator/subscription/Subscribers"));
const CreatorEarnings = lazy(() => import("../pages/creator/subscription/Earnings"));
const CreatorSubscriptionManage = lazy(() => import("../pages/creator/subscription/Manage"));
const CreatorSubscriptionMyFeed = lazy(() => import("../pages/creator/subscription/MyFeed"));
const CreatorCommunity = lazy(() => import("../pages/creator/community"));
const CreatorChallengingVideos = lazy(
  () => import("../pages/creator/Challenging/ChallengingVideos")
);
const CreatorChallengeById = lazy(() => import("../pages/creator/ChallengeDetails"));
const CreatorChallengingVideosCreate = lazy(
  () => import("../pages/creator/Challenging/components/CreateChallenge")
);
const CreatorCreateSubscription = lazy(() => import("../pages/creator/subscription/CreatePlan"));
const CreatorCreateContent = lazy(() => import("../pages/creator/subscription/CreateContent"));
const CreatorGifting = lazy(() => import("../pages/creator/gifting"));
const CreatorAnalytics = lazy(() => import("../pages/creator/Bio/Analytics"));
const AccpetedTrends = lazy(() => import("../pages/creator/CreatorSubmissions"));
const ChallengesRewards = lazy(() => import("../pages/creator/Challenging/Rewards"));
// fan pages
const FanFeeds = lazy(() => import("../pages/fan/ActivityFeed"));
const FanCreators = lazy(() => import("../pages/fan/Creators"));
const FanEditProfile = lazy(() => import("../pages/fan/EditProfile"));
const FanSubscribers = lazy(() => import("../pages/fan/subscribers"));
const FanAllSubscriptions = lazy(() => import("../pages/fan/AllSubscriptions"));
const FanGifting = lazy(() => import("../pages/fan/MyTransactionHistory"));
// admin pages
const AdminConfiguration = lazy(() => import("../pages/admin/configuration/Configuration"));
const AdminPeople = lazy(() => import("../pages/admin/people"));
const AdminUserRole = lazy(() => import("../pages/admin/userByRole"));
const AdminSignupRequests = lazy(() => import("../pages/admin/signUpRequests"));
const AdminReport = lazy(() => import("../pages/admin/report"));
const AdminChallengeReward = lazy(() => import("../pages/admin/challengeReward"));
const AdminMessageReport = lazy(() => import("../pages/admin/message/report"));
const AdminMessage = lazy(() => import("../pages/admin/message"));
const AdminNft = lazy(() => import("../pages/admin/nft"));
const AdminNftDetail = lazy(() => import("../pages/admin/nft/NftDetail"));
const AdminCampaign = lazy(() => import("../pages/admin/campaign"));
const AdminEmailTemplates = lazy(() => import("../pages/admin/emails"));
const AdminOnBoarding = lazy(() => import("../pages/admin/onboarding"));
const AdminEmailTemplateDetail = lazy(() => import("../pages/admin/emails/EmailTemplateDetail"));
const AdminOnBoardingDetail = lazy(() => import("../pages/admin/onboarding/EmailTemplateDetail"));
const AdminEmailCreate = lazy(() => import("../pages/admin/emails/components/CreateTemplateModal"));
const AdminOnBoardingCreate = lazy(
  () => import("../pages/admin/onboarding/components/CreateTemplate")
);
const AdminCampaignDetails = lazy(() => import("../pages/admin/campaign/CampaignTabSection"));
const AdminAnalyticsTransactionExplore = lazy(
  () => import("../pages/admin/analytics/transactionExplore")
);
const AdminAnalyticsFeeInformation = lazy(() => import("../pages/admin/analytics/feeInformation"));
const AdminAnalyticsBalance = lazy(() => import("../pages/admin/analytics/balance"));
const AdminAnalyticsSweep = lazy(() => import("../pages/admin/analytics/sweep"));
const AdminEditCampaign = lazy(() => import("../pages/admin/campaign/CreateCampaign"));
const AdminGifting = lazy(() => import("../pages/admin/gifting"));
const AdminRefundRequest = lazy(() => import("../pages/admin/refundRequest"));
const AdminCreatorAnalytics = lazy(() => import("../pages/admin/analytics/creatorAnalytics"));
// common
const Notifications = lazy(() => import("pages/public/notifications"));
const NewPublicProfile = lazy(() => import("../pages/public/PublicProfile"));
const Airdrop = lazy(() => import("../pages/public/airdrop"));

const AppRoutes = () => (
  <Routes>
    <Route path="/terms" element={<Terms />} />
    <Route path="/privacy" element={<Privacy />} />
    <Route element={<HomeLayout />}>
      <Route path="/" element={<Home />} />
    </Route>
    <Route element={<CreatorProfileRoute />}>
      <Route element={<ProfileLayout />}>
        <Route path="/*" element={<NewPublicProfile />} />
      </Route>
    </Route>
    <Route element={<ProfileLayout withoutBanner />}>
      <Route path="airdrop" element={<Airdrop />} />
    </Route>

    <Route element={<PrivateRoute />}>
      <Route path="/onboarding" element={<OnBoarding />} />
    </Route>

    <Route element={<AuthRoute />}>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
      </Route>
      <Route path="authentication" element={<AuthenticationPage />} />
      <Route path="recovercode" element={<RecoveryCode />} />
      <Route path="admin">
        <Route path="login" element={<AdminLogin />} />
      </Route>
    </Route>

    <Route element={<CreatorPrivateRoute />}>
      {/* Monetization page removed due to lack of use for a year */}
      <Route path="preview" element={<PreviewLayout isCustomization />}>
        <Route path="wallpaper" element={<Wallpaper />} />
        <Route path="banner" element={<Banner />} />
      </Route>
      <Route path="create" element={<PreviewLayout />}>
        <Route path="subscriptions" element={<CreatorCreateSubscription />} />
        <Route path="myfeed" element={<CreatorCreateContent />} />
        <Route path="challenges" element={<CreatorChallengingVideosCreate />} />
      </Route>
      <Route path="edit" element={<PreviewLayout />}>
        <Route path="subscriptions" element={<CreatorCreateSubscription />} />
        <Route path="myfeed" element={<CreatorCreateContent />} />
        <Route path="challenges/:id" element={<CreatorChallengingVideosCreate />} />
      </Route>
      <Route path="creator" element={<CommonLayout />}>
        <Route path="notifications" element={<Notifications />} />
        <Route path="dashboard" element={<CreatorDashboard />} />
        <Route path="wallet" element={<CreatorWallet />} />
        <Route path="chat" element={<ChatContainer />} />
        <Route path="editprofile" element={<CreatorProfile />}>
          <Route path="general" element={<General />} />
          <Route path="verifysocials" element={<VerifySocials />} />
          <Route path="profilecredentials" element={<ProfileCredentials />} />
          <Route path="notifications" element={<Notification />} />
          <Route path="mfa" element={<MFA />} />
        </Route>
        <Route path="bio">
          <Route path="links" element={<BioLinkContainer />} />
          <Route path="customization" element={<BioCustomization />} />
          <Route path="wall-of-fame" element={<BioWall />} />
          <Route path="analytics" element={<CreatorAnalytics />} />
        </Route>
        <Route path="community" element={<CreatorCommunity />} />
        <Route path="challenges/all" element={<CreatorChallengingVideos />} />
        <Route path="challenges/:id" element={<CreatorChallengeById />} />
        <Route path="messages" element={<CreatorMessages />} />
        <Route path="subscriptions/manage" element={<CreatorSubscriptionManage />} />
        <Route path="subscriptions/myfeed" element={<CreatorSubscriptionMyFeed />} />
        <Route path="subscriptions/subscribers" element={<CreatorSubscribers />} />
        <Route path="subscriptions/earnings" element={<CreatorEarnings />} />
        <Route path="gifting" element={<CreatorGifting />} />
        <Route path="challenges/my" element={<AccpetedTrends />} />
        <Route path="challenges/rewards" element={<ChallengesRewards />} />
      </Route>
    </Route>

    <Route element={<FanPrivateRoute />}>
      <Route path="fan" element={<CommonLayout />}>
        <Route path="notifications" element={<Notifications />} />
        <Route path="feeds" element={<FanFeeds />} />
        <Route path="creators" element={<FanCreators />} />
        <Route path="wallet" element={<CreatorWallet />} />
        <Route path="chat" element={<ChatContainer />} />
        <Route path="editprofile" element={<FanEditProfile />}>
          <Route path="general" element={<FanGeneral />} />
          <Route path="profilecredentials" element={<ProfileCredentials />} />
          <Route path="mfa" element={<MFA />} />
        </Route>
        <Route path="subscribers" element={<FanSubscribers />} />
        <Route path="all-subscriptions" element={<FanAllSubscriptions />} />
        <Route path="paymentHistory" element={<FanGifting />} />
        <Route path="messages" element={<FanMessages />} />
      </Route>
    </Route>

    <Route element={<AdminPrivateRoute />}>
      <Route path="admin" element={<CommonLayout />}>
        <Route path="people" element={<AdminPeople />} />
        <Route path="role/creators" element={<AdminUserRole role={ROLES.creator} />} />
        <Route path="role/fans" element={<AdminUserRole role={ROLES.fan} />} />
        <Route path="role/brands" element={<AdminUserRole role={NEW_ROLES.brand} />} />
        <Route path="signup-requests" element={<AdminSignupRequests />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="configuration" element={<AdminConfiguration />} />
        <Route path="report" element={<AdminReport />} />
        <Route path="message/report" element={<AdminMessageReport />} />
        <Route path="message/notification" element={<AdminMessage />} />
        <Route
          path="analytics/transaction-explore"
          element={<AdminAnalyticsTransactionExplore />}
        />
        <Route path="analytics/fee-information" element={<AdminAnalyticsFeeInformation />} />
        <Route path="analytics/balance" element={<AdminAnalyticsBalance />} />
        <Route path="analytics/sweep" element={<AdminAnalyticsSweep />} />
        <Route path="nft" element={<AdminNft />} />
        <Route path="campaign" element={<AdminCampaign />} />
        <Route path="challenge-reward" element={<AdminChallengeReward />} />
        <Route path="refund-requests" element={<AdminRefundRequest />} />
        <Route path="creator-analytics/:id" element={<AdminCreatorAnalytics />} />
        <Route path="campaigns/details/:id" element={<AdminCampaignDetails />} />
        <Route path="nftdetail" element={<AdminNftDetail />} />
        <Route path="campaign/edit" element={<AdminEditCampaign />} />
        <Route path="gifting" element={<AdminGifting />} />
        <Route path="email-templates" element={<AdminEmailTemplates />} />
        <Route path="email-templates/details" element={<AdminEmailTemplateDetail />} />
        <Route path="email-templates/edit" element={<AdminEmailCreate />} />
        <Route path="email-templates/create" element={<AdminEmailCreate />} />
        <Route path="onboarding" element={<AdminOnBoarding />} />
        <Route path="onboarding/details/:id" element={<AdminOnBoardingDetail />} />
        <Route path="onboarding/create" element={<AdminOnBoardingCreate />} />
        <Route path="onboarding/edit/:id" element={<AdminOnBoardingCreate />} />
      </Route>
    </Route>

    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default AppRoutes;
