import { FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { Content, SelectModal } from "./style";

import { IconOption } from "Components/SelectField/types";
import { SocialIcons } from "constants/appConstants";

interface Props {
  show: boolean;
  closeModal: () => void;
  onConfirm: (value: IconOption) => void;
}

const SocialModal: FC<Props> = memo(({ show, closeModal, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <SelectModal show={show} size="lg" onHide={() => closeModal()} showCloseButton>
      <p className="topic">{t("creator.profile.social")}</p>
      <Content>
        {SocialIcons.map((item) => (
          <div key={item.value} className="social" onClick={() => onConfirm(item)}>
            <div className="icon">{item.label}</div>
            <p className="title">{item.title ? item.title : t("creator.profile.defaultSocial")}</p>
          </div>
        ))}
      </Content>
    </SelectModal>
  );
});

export default SocialModal;
