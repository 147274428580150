import { memo } from "react";
import { Outlet } from "react-router-dom";

import NewHeader from "Components/NewHeader";
import { AuthLayoutStyled } from "layouts/AuthLayout/style";

export const AuthLayout = memo(() => (
  <AuthLayoutStyled>
    <NewHeader blackLogo hasButtons={false} className="auth_header" />
    <Outlet />
  </AuthLayoutStyled>
));
