import { useEffect } from "react";
import { useDispatch } from "react-redux";

import RegistrationForm from "./components/RegistrationForm";

import titles from "constants/titles";
import useTitle from "customHooks/useTitle";
import { getRegistrationStatusAPI } from "services/adminServices";
import { setLoader } from "store/slices/featureSlice";
import { AuthWrapper } from "styles/auth";

// registration page
const Register = () => {
  useTitle(titles.registration);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoader({ name: "registrationLoader", value: true }));
    getRegistrationStatusAPI({
      type: "registration",
      callback: () => dispatch(setLoader({ name: "registrationLoader", value: false })),
    });
  }, []);

  return (
    <AuthWrapper>
      <div className="content">
        <RegistrationForm />
      </div>
    </AuthWrapper>
  );
};

export default Register;
