import styled from "styled-components";

export const TikTokContentPreviewCardContainer = styled.div<{
  height: string;
  iframeScale: string;
}>`
  height: ${(p) => p.height};

  .iframe {
    width: 323px;
    height: 559px;
    margin-top: -2px;
    overflow: hidden;
    transform: ${(p) => p.iframeScale};
    transform-origin: left top;
  }
`;
