const DefaultIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.21 7.899V16.049C21.21 19.069 19.32 21.199 16.3 21.199H7.65C4.63 21.199 2.75 19.069 2.75 16.049V7.899C2.75 4.879 4.64 2.75 7.65 2.75H16.3C19.32 2.75 21.21 4.879 21.21 7.899Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.28125 16.4307L6.81025 14.8177C7.36125 14.2357 8.28625 14.2327 8.84225 14.8097L9.72725 15.7127C10.3243 16.3217 11.3173 16.2777 11.8583 15.6187L14.0872 12.9077C14.7272 12.1287 15.9013 12.0807 16.6023 12.8037L18.6783 14.9457"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3136 9.13291C10.3136 10.1009 9.52764 10.8869 8.55964 10.8869C7.59164 10.8869 6.80664 10.1009 6.80664 9.13291C6.80664 8.16491 7.59164 7.37891 8.55964 7.37891C9.52764 7.37891 10.3136 8.16491 10.3136 9.13291Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DefaultIcon;
