import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const AuthLayoutStyled = styled.div`
  .auth_header {
    .content {
      max-width: 1180px;
      div {
        .dark {
        }
      }
    }
  }

  ${deviceQuery.tabletM} {
    .auth_header {
      .content {
        div {
          .light {
            display: none;
          }
          .dark {
            display: block;
          }
        }
      }
    }
  }
`;
