import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  LegacyRef,
  memo,
  ReactNode,
} from "react";

import { ErrorMsg, Info, InputArea, Label, Wrapper } from "./style";

interface Props {
  id?: string;
  refArea?: LegacyRef<HTMLTextAreaElement>;
  ref?: LegacyRef<HTMLInputElement>;
  textarea?: boolean;
  className?: string;
  label?: string;
  required?: boolean;
  error?: string;
  info?: string;
  type?: string;
  inputMode?: string;
  pattern?: string;
  disabled?: boolean;
  readOnly?: boolean;
  row?: number;
  name?: string;
  maxLength?: number;
  value: string | number | readonly string[] | undefined;
  placeholder?: string;
  autoFocus?: boolean;
  autoComplete?: string;
  min?: string | number;
  max?: string | number;
  labelSize?: string;
  right?: ReactNode;
  left?: ReactNode;
  color?: string;
  link?: boolean;
  rightLabel?: string;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  keyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
  keyUp?: KeyboardEventHandler<HTMLTextAreaElement>;
  handleChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  handleBlur?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  handleFocus?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

// Main input component of project
const Input: FC<Props> = memo(
  ({
    id,
    labelSize,
    ref,
    left,
    refArea,
    textarea = false,
    className,
    label,
    required,
    error,
    type = "text",
    info,
    readOnly = false,
    row,
    name,
    maxLength,
    value,
    placeholder,
    autoFocus,
    link,
    autoComplete,
    min,
    max,
    color,
    right,
    rightLabel,
    onKeyDown,
    keyDown,
    keyUp,
    handleChange,
    handleBlur,
    handleFocus,
  }) => (
    <Wrapper textarea={textarea} className={className || ""}>
      {label && (
        <Label htmlFor={label} fontSize={labelSize} readonly={readOnly}>
          {required ? <span>* {label}</span> : <span>{label}</span>}
          {rightLabel && <span className="right">{rightLabel}</span>}
        </Label>
      )}
      <InputArea
        className={`${textarea && "textareaWrap"} inputWrapper`}
        isValue={value}
        error={!!error}
        textarea={textarea}
        readonly={readOnly}
        color={color}
        link={link}
      >
        {textarea ? (
          <>
            {left && <div className="left">{left}</div>}
            <textarea
              id={id}
              ref={refArea}
              readOnly={readOnly}
              rows={row || 6}
              onBlur={handleBlur}
              onFocus={handleFocus}
              name={name}
              maxLength={maxLength}
              value={value}
              onChange={handleChange}
              onKeyDown={keyDown}
              onKeyUp={keyUp}
              autoFocus={autoFocus || false}
              placeholder={placeholder}
            />
            {right && <div className="right">{right}</div>}
          </>
        ) : (
          <>
            {left && <div className="left">{left}</div>}
            <input
              id={id ?? label}
              ref={ref}
              readOnly={readOnly}
              type={type}
              placeholder={placeholder}
              value={value}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              name={name}
              autoFocus={autoFocus || false}
              min={min}
              max={max}
              maxLength={maxLength}
              autoComplete={autoComplete}
              onKeyDown={onKeyDown}
            />
            {right && <div className="right">{right}</div>}
          </>
        )}
      </InputArea>
      {error && <ErrorMsg>{error}</ErrorMsg>}
      {info && <Info>{info}</Info>}
    </Wrapper>
  )
);

export default Input;
