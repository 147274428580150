import { FC } from "react";

import { SelectContainer, Label, Area } from "./style";

import SocialModal from "../SocialsModal";

import ArrowDown from "assets/svg/ArrowDown";
import { IconOption } from "Components/SelectField/types";

interface Props {
  onConfirm: (value: IconOption) => void;
  handleClick: () => void;
  handleClose: () => void;
  disabled?: boolean;
  left?: boolean;
  isShow: boolean;
  label: string; // don't know what is this props about, is it icon?
  title: string;
  item: IconOption;
}

const SelectSocial: FC<Props> = ({
  label,
  item,
  left,
  title,
  disabled,
  onConfirm,
  handleClick,
  isShow,
  handleClose,
}) => {
  const handleConfirm = (value: IconOption) => {
    handleClose();
    onConfirm(value);
  };

  return (
    <>
      <SelectContainer disabled={disabled} onClick={() => !disabled && handleClick()}>
        <Label disabled={disabled}>
          <span>* </span>
          {label}
        </Label>
        <Area left={left} disabled={disabled}>
          {item.label}
          <div className="title">{title}</div>
          <div className="arrow">
            <ArrowDown />
          </div>
        </Area>
      </SelectContainer>
      <SocialModal
        show={isShow}
        closeModal={handleClose}
        onConfirm={(value) => handleConfirm(value)}
      />
    </>
  );
};

export default SelectSocial;
