import styled from "styled-components";

export const NoChatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100%;
  color: ${(p) => p.theme.colors.text.secondary};
  font-size: ${(p) => p.theme.typography.subText5};
  justify-content: center;
  align-items: center;
`;
