import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { getLocalStorageItem } from "common/helpers/localStorage";

import { ROLES, STORAGES } from "constants/appConstants";
import { ROUTES } from "constants/routes";
import { lightTheme } from "styles/newThemes";

const AuthRoute = () => {
  const token = getLocalStorageItem({ key: STORAGES.token });
  const isRegister = getLocalStorageItem({ key: "register" });
  const accountType = getLocalStorageItem({ key: STORAGES.accountType });
  const location = useLocation();

  if (
    token &&
    !isRegister &&
    accountType !== (ROLES.admin || ROLES.judge) &&
    location?.pathname !== "/authentication"
  ) {
    return <Navigate to={ROUTES.home} />;
  }
  return (
    <ThemeProvider theme={lightTheme}>
      <Outlet />
    </ThemeProvider>
  );
};

export default AuthRoute;
