import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const RollingTabsContainer = styled.div<{ full?: boolean }>`
  position: relative;
  padding: 4px;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.text.secondary};
  background: ${(p) => p.theme.colors.background.secondary};
  border: 1px solid ${(p) => p.theme.colors.divider};
  border-radius: 8px;

  .item {
    display: flex;
    gap: 6px;
    font-size: ${(p) => p.theme.typography.subText3};
    position: relative;
    line-height: 20px;
    align-items: center;
    z-index: 2;
    transition: all 0.3s;
    cursor: pointer;
    padding: 4px 12px;

    .number {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 8px;
      line-height: 20px;
      background: ${(p) => p.theme.colors.background.fifth};
      border-radius: 16px;
      color: ${(p) => p.theme.colors.text.secondary};
    }

    &.active {
      color: ${(p) => p.theme.colors.green.primary};

      .number {
        background: ${(p) => p.theme.colors.green.primary20};
        color: ${(p) => p.theme.colors.text.third};
      }
    }
  }

  .selected {
    z-index: 1;
    transition: all 0.3s;
    position: absolute;
    border-radius: 6px;
    background: ${(p) => p.theme.colors.background.fifth};
  }

  ${(p) =>
    p.full &&
    css`
      .item {
        flex: 1;
        text-align: center;
        justify-content: center;
      }
    `}

  ${deviceQuery.mobileM} {
    ${(p) =>
      p.full &&
      css`
        width: 100%;
      `}
  }
`;
