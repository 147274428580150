import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { SidebarLabel, TabWithSubTab } from "../../style";

import TabLink from "../TabLink";

import ArrowDownSmall from "assets/svg/ArrowDownSmall";
import Accordion2 from "Components/Accordion2";
import { PulsingMark } from "Components/PulsingMark";
import { getUser, setUserIsNew } from "store/slices/global/userSlice";
import { ISidebarData } from "types/sidebar";

interface ISubTab {
  title: string;
  status: boolean;
  keyPath?: string;
}
interface IMainTab {
  keyPath: string;
}

interface Props {
  drawer: boolean;
  closeDrawer: () => void;
  item: ISidebarData;
  sidebarData: ISidebarData[];
  subTab: ISubTab;
  setSubTab: Dispatch<SetStateAction<ISubTab>>;
  mainTab: IMainTab;
  setMainTab: Dispatch<SetStateAction<IMainTab>>;
  className?: string;
}

const SubMenu: FC<Props> = ({
  item,
  closeDrawer,
  drawer,
  sidebarData = [],
  subTab,
  setSubTab,
  mainTab,
  setMainTab,
  className,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const user = useSelector(getUser);

  useEffect(() => {
    setMainTab({
      keyPath: location?.pathname?.split("/")[2],
    });
    const pathTrue = sidebarData?.some((val) => val?.keyPath === location?.pathname?.split("/")[2]);

    if (!pathTrue) {
      setShow(false);
      setSubTab({
        title: "",
        status: false,
        keyPath: "",
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    setShow(mainTab.keyPath === item.keyPath);
  }, [mainTab, item]);

  const onClickHandler = (val: ISidebarData) => {
    setShow((prev) => !prev);
    if (user && user.is_new && user.role === "fan") dispatch(setUserIsNew(false));

    if (val.subTab?.length) {
      const firstSubTab = val.subTab[0];
      navigate(firstSubTab.path);
    }

    setSubTab({
      title: val.title,
      status: val.title === subTab.title ? !subTab.status : true,
      keyPath: val.keyPath,
    });
  };

  const isChildMarkedAsHighlighted = useMemo(() => {
    return item?.subTab?.some((sub) => sub.highlightedToLook);
  }, [item]);

  return (
    <>
      {!item?.subTab ? (
        <TabLink
          className={className}
          drawer={drawer}
          clickHandler={closeDrawer}
          item={item}
          onClick={onClickHandler}
        />
      ) : (
        <>
          <TabWithSubTab
            drawer={drawer}
            active={mainTab.keyPath === item.keyPath}
            onClick={(event) => {
              event.stopPropagation();
              onClickHandler(item);
            }}
          >
            <Accordion2
              className="link"
              show={show}
              collapsedContent={
                <div className="item">
                  <div>
                    <div className="icon">{item.icon}</div>
                    <SidebarLabel drawer={drawer}>{t(item.title)}</SidebarLabel>
                  </div>
                  <div>
                    {isChildMarkedAsHighlighted && <PulsingMark />}
                    <div className={`arrow ${show ? "show" : ""}`}>
                      <ArrowDownSmall />
                    </div>
                  </div>
                </div>
              }
              expandedContent={
                <>
                  {item.subTab.map((element, i) => (
                    <TabLink
                      key={i}
                      className={`withoutActive ${className}`}
                      drawer={drawer}
                      clickHandler={closeDrawer}
                      item={element}
                    />
                  ))}
                </>
              }
            />
          </TabWithSubTab>
        </>
      )}
    </>
  );
};
export default SubMenu;
