import styled, { css } from "styled-components";

import { hidePhoneInputArrows, OutfitRegular } from "styles/mixins";

export const InpurArea = styled.div<{ full?: boolean; sideLabel?: string; prefix?: string }>`
  background: ${(p) => p.theme.colors.background.secondary};
  border: 1px solid ${(p) => p.theme.colors.field.full};
  border-radius: 5px;
  overflow: hidden;
  width: ${(p) => (p.full ? "100%" : "auto")};
  max-width: ${(p) => (p.full ? "100%" : "130px")};

  ${(p) =>
    (p.sideLabel || p.prefix) &&
    css`
      display: flex;
      align-items: center;

      p {
        ${OutfitRegular};
        font-size: 0.9rem;
        color: ${p.theme.colors.text.primary};
        white-space: nowrap;
        padding-left: 10px;
      }
    `};

  ${(p) =>
    p.sideLabel &&
    css`
      p {
        min-width: 60%;
      }
    `};

  input {
    border: none;
    outline: none;
    background: transparent;
    min-width: 0;
    width: 100%;
    height: 100%;
    ${OutfitRegular};
    font-size: 0.9rem;
    color: ${(p) => p.theme.colors.text.primary};
    padding: 10px;
    padding-left: ${(p) => (p.prefix ? 0 : "10px")};
    ${hidePhoneInputArrows};

    &::placeholder {
      ${OutfitRegular};
      font-size: 0.9rem;
      color: ${(p) => p.theme.colors.text.primary};
    }
  }
`;
