import styled from "styled-components";

export const PulsingSvgWrapper = styled.div`
  .shadow {
    stroke-opacity: 0.2;
    animation: pulse 1.5s infinite ease-in;

    @keyframes pulse {
      0% {
        stroke-width: 0;
      }
      50% {
        stroke-width: 4;
      }
      100% {
        stroke-width: 0;
      }
    }
  }
`;
