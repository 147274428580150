import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const FooterCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ${(p) => p.theme.colors.text.primary};

  &.small {
    max-width: 218px;
  }

  p.pointer {
    cursor: pointer;
  }

  .block {
    display: flex;
    gap: 8px;
    flex-direction: column;

    a {
      cursor: pointer;
      color: ${(p) => p.theme.colors.text.primary};
      text-decoration: none;
    }

    .title {
      color: ${(p) => p.theme.colors.text.primary};
      font-size: ${(p) => p.theme.typography.text3};
    }
    .subTitle {
      font-size: ${(p) => p.theme.typography.subText5};
      color: ${(p) => p.theme.colors.text.secondary};
    }
  }

  ${deviceQuery.desktop} {
    &.small {
      width: 45%;
    }
  }

  ${deviceQuery.laptopM} {
    width: 45%;

    &.small {
      width: 45%;
    }
  }

  ${deviceQuery.mobileL} {
    width: 100%;
    max-width: 250px;
    align-items: center;

    &.small {
      width: 100%;
      max-width: 250px;
    }

    .block {
      align-items: center;

      .subTitle {
        text-align: center;
      }
    }
  }
`;
