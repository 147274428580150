const Wallet = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.5681 14.5597H17.5181C16.0281 14.5597 14.8281 13.3597 14.8281 11.8697C14.8281 10.3797 16.0381 9.17969 17.5181 9.17969H21.5681"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9819 11.8105H17.6719"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.67969 3.16016H16.3197C19.2197 3.16016 21.5697 5.51016 21.5697 8.41016V15.5902C21.5697 18.4902 19.2197 20.8402 16.3197 20.8402H7.67969C4.77969 20.8402 2.42969 18.4902 2.42969 15.5902V8.41016C2.42969 5.51016 4.77969 3.16016 7.67969 3.16016V3.16016Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.96094 7.69922H12.3609"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Wallet;
