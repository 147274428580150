import React, { FC, useCallback } from "react";

import "./style.css";
// import BannerSide from "assets/svg/phone_animation/BannerSide";
// import BottomLinksSide from "assets/svg/phone_animation/BottomLinksSide";
// import ProfileSide from "assets/svg/phone_animation/ProfileSide";
// import SendGiftSide from "assets/svg/phone_animation/SendGiftSide";
// import SubscriptionsSide from "assets/svg/phone_animation/SubscriptionsSide";
// import UrlBlockSide from "assets/svg/phone_animation/UrlBlockSide";
// import WallOfFameSide from "assets/svg/phone_animation/WallOfFameSide";
// import YoutubeSide from "assets/svg/phone_animation/YoutubeSide";

interface PhoneProps {
  animationIndex: number;
  turnFinished: boolean;
}

export const SidePhoneComponent: FC<PhoneProps> = ({ animationIndex, turnFinished }) => {
  const isCurrentAnimationIndexAndFinished = useCallback(
    (index: number) => {
      return animationIndex === index && turnFinished;
    },
    [animationIndex, turnFinished]
  );

  return (
    <div className="side_animations">
      <div>
        <div
          className={`side_image url_block_side_image 
                  ${isCurrentAnimationIndexAndFinished(0) && "side_image_animation"}
                  `}
        >
          {/* <UrlBlockSide className="side_image_w-100" /> */}
          <img className="side_image_w-100" src="/home/profileLink.png" alt="profileLink" />
        </div>
        <img
          className={`side_arrow url_block_side_arrow
                  ${isCurrentAnimationIndexAndFinished(0) && "side_arrow_animation"}
                  `}
          src="/url_block_arrow.png"
          alt="url_block_arrow"
        />
      </div>

      <div>
        <div
          className={`side_image profile_block_side_image 
                  ${isCurrentAnimationIndexAndFinished(1) && "side_image_animation"}
                  `}
        >
          {/* <ProfileSide className="side_image_w-100" /> */}
          <img className="side_image_w-100" src="/home/profile.png" alt="profile" />
        </div>
        <img
          className={`side_arrow profile_block_side_arrow
                  ${isCurrentAnimationIndexAndFinished(1) && "side_arrow_animation"}
                  `}
          src="/profile_arrow.png"
          alt="profile_arrow"
        />
      </div>

      <div>
        <div
          className={`side_image banners_side_image 
                  ${isCurrentAnimationIndexAndFinished(2) && "side_image_animation"}
                  `}
        >
          <img className="side_image_w-100" src="/home/customTheme.png" alt="customTheme" />
          {/* <BannerSide className="side_image_w-100" /> */}
        </div>
        <img
          className={`side_arrow banners_side_arrow
                  ${isCurrentAnimationIndexAndFinished(2) && "side_arrow_animation"}
                  `}
          src="/youtube_arrow.png"
          alt="youtube_arrow"
        />
      </div>

      <div>
        <div
          className={`side_image bottom_links_side_image 
                  ${isCurrentAnimationIndexAndFinished(3) && "side_image_animation"}
                  `}
        >
          <img className="side_image_w-100" src="/home/link.png" alt="links" />
          {/* <BottomLinksSide className="side_image_w-100" /> */}
        </div>
        <img
          className={`side_arrow bottom_links_block_side_arrow
                  ${isCurrentAnimationIndexAndFinished(3) && "side_arrow_animation"}
                  `}
          src="/bottom_links_arrow.png"
          alt="bottom_links_arrow"
        />
      </div>

      <div>
        <div
          className={`side_image youtube_side_image 
                  ${isCurrentAnimationIndexAndFinished(4) && "side_image_animation"}
                  `}
        >
          {/* <YoutubeSide className="side_image_w-100" /> */}
          <img className="side_image_w-100" src="/home/preview.png" alt="preview" />
        </div>
        <img
          className={`side_arrow youtube_side_arrow
                  ${isCurrentAnimationIndexAndFinished(4) && "side_arrow_animation"}`}
          src="/youtube_arrow.png"
          alt="youtube_arrow"
        />
      </div>

      <div>
        <div
          className={`side_image send_gift_side_image 
                  ${isCurrentAnimationIndexAndFinished(5) && "side_image_animation"}
                  `}
        >
          {/* <SendGiftSide className="side_image_w-100" /> */}
          <img className="side_image_w-100" src="/home/gifts.png" alt="gifts" />
        </div>
        <img
          className={`side_arrow send_gift_side_arrow
                  ${isCurrentAnimationIndexAndFinished(5) && "side_arrow_animation"}`}
          src="/send_gift_arrow.png"
          alt="send_gift_arrow"
        />
      </div>

      <div>
        <div
          className={`side_image youtube_side_image 
                  ${isCurrentAnimationIndexAndFinished(6) && "side_image_animation"}
                  `}
        >
          <img className="side_image_w-100" src="/home/wall.png" alt="wall" />
          {/* <WallOfFameSide className="side_image_w-100" /> */}
        </div>
        <img
          className={`side_arrow wall_of_fame_side_arrow
                  ${isCurrentAnimationIndexAndFinished(6) && "side_arrow_animation"}`}
          src="/youtube_arrow.png"
          alt="youtube_arrow"
        />
      </div>

      <div>
        <div
          className={`side_image bottom_links_side_image 
                  ${isCurrentAnimationIndexAndFinished(7) && "side_image_animation"}
                  `}
        >
          {/* <SubscriptionsSide className="side_image_w-100" /> */}
          <img className="side_image_w-100" src="/home/subscription.png" alt="subscription" />
        </div>
        <img
          className={`side_arrow bottom_links_block_side_arrow
                  ${isCurrentAnimationIndexAndFinished(7) && "side_arrow_animation"}
                  `}
          src="/bottom_links_arrow.png"
          alt="bottom_links_arrow"
        />
      </div>
    </div>
  );
};
