import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";

import { Wrapper } from "../home/style";

import Footer from "Components/Footer";

import NewHeader from "Components/NewHeader";
import titles from "constants/titles";
import useTitle from "customHooks/useTitle";
import { Content } from "layouts/HomeLayout/style";
import { lightTheme } from "styles/newThemes";

const Privacy = () => {
  const { t } = useTranslation();
  useTitle(titles.privacy);

  return (
    <ThemeProvider theme={lightTheme}>
      <NewHeader blackLogo />
      <Wrapper>
        <Content>
          <div className="content">
            <div className="hero-area">
              <div className="row">
                <div className="col-lg-6 col-sm-12" style={{ zIndex: 1 }}>
                  <div className="hero-content">
                    <h1>{t("home.privacy.privacy")}</h1>
                    <h4>{t("home.privacy.clicked_our_privacy")}</h4>
                    <h2>{t("home.privacy.owner")}</h2>
                    <h4>{t("home.privacy.lewk")}</h4>
                    <h4>{t("home.privacy.contact")}</h4>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
              <div className="banner-img">
                <img src="/img/home/privacybanner.png" alt="privacybanner" loading="lazy" />
              </div>
            </div>

            <div className="why-us">
              <div className="fon" />
              <div className="row">
                <div className="col-lg-12" style={{ zIndex: 1 }}>
                  <div className="content-l">
                    <h2>{t("home.privacy.privacy_labs")}</h2>
                    <div className="row">
                      <div className="col-md">
                        <p>
                          <b>1. {t("home.privacy.data_collected")}</b>
                        </p>
                        <hr />
                        <p>{t("home.privacy.data_collected_1")}</p>
                        <br />
                        <p>{t("home.privacy.data_collected_2")}</p>
                        <br />
                        <p>{t("home.privacy.data_collected_3")}</p>
                        <br />
                        <p>{t("home.privacy.data_collected_4")}</p>
                        <br />
                        <p>{t("home.privacy.data_collected_5")}</p>
                        <br />
                        <p>{t("home.privacy.data_collected_6")}</p>
                        <br />
                        <p>{t("home.privacy.data_collected_7")}</p>
                        <br />
                        <br />

                        <p>
                          <b>2. {t("home.privacy.processing")}</b>
                        </p>
                        <hr />
                        <p>
                          <b>{t("home.privacy.methods_processing")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.methods_processing_1")}</p>
                        <br />
                        <p>{t("home.privacy.methods_processing_2")}</p>
                        <br />
                        <p>
                          <b>{t("home.privacy.legal_processing")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.legal_processing_1")}</p>
                        <br />
                        <p>{`1) ${t("home.privacy.legal_processing_1_1")}`}</p>
                        <br />
                        <p>{`2) ${t("home.privacy.legal_processing_1_2")}`}</p>
                        <br />
                        <p>{`3) ${t("home.privacy.legal_processing_1_3")}`}</p>
                        <br />
                        <p>{`4) ${t("home.privacy.legal_processing_1_4")}`}</p>
                        <br />
                        <p>{`5) ${t("home.privacy.legal_processing_1_5")}`}</p>
                        <br />
                        <p>{t("home.privacy.legal_processing_2")}</p>
                        <br />
                        <p>
                          <b>{t("home.privacy.place_processing")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.place_processing_1")}</p>
                        <br />
                        <p>{t("home.privacy.place_processing_2")}</p>
                        <br />
                        <p>{t("home.privacy.place_processing_3")}</p>
                        <br />
                        <p>{t("home.privacy.place_processing_4")}</p>
                        <br />
                        <p>
                          <b>{t("home.privacy.time_processing")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.time_processing_1")}</p>
                        <br />
                        <p>{t("home.privacy.time_processing_2")}</p>
                        <br />
                        <p>{`1) ${t("home.privacy.time_processing_2_1")}`}</p>
                        <br />
                        <p>{`2) ${t("home.privacy.time_processing_2_2")}`}</p>
                        <br />
                        <p>{t("home.privacy.time_processing_3")}</p>
                        <br />
                        <p>{t("home.privacy.time_processing_4")}</p>
                        <br />
                        <br />

                        <p>
                          <b>3. {t("home.privacy.rights")}</b>
                        </p>
                        <hr />
                        <p>{t("home.privacy.rights_users")}</p>
                        <br />
                        <p>{t("home.privacy.rights_particular")}</p>
                        <br />
                        <p>
                          <b>{`1) ${t("home.privacy.rights_withdraw.bold")}`}</b>
                          {` ${t("home.privacy.rights_withdraw.regular")}`}
                        </p>
                        <br />
                        <p>
                          <b>{`2) ${t("home.privacy.rights_object.bold")}`}</b>
                          {` ${t("home.privacy.rights_object.regular")}`}
                        </p>
                        <br />
                        <p>
                          <b>{`3) ${t("home.privacy.rights_access.bold")}`}</b>
                          {` ${t("home.privacy.rights_access.regular")}`}
                        </p>
                        <br />
                        <p>
                          <b>{`4) ${t("home.privacy.rights_verify.bold")}`}</b>
                          {` ${t("home.privacy.rights_verify.regular")}`}
                        </p>
                        <br />
                        <p>
                          <b>{`5) ${t("home.privacy.rights_restrict.bold")}`}</b>
                          {` ${t("home.privacy.rights_restrict.regular")}`}
                        </p>
                        <br />
                        <p>
                          <b>{`6) ${t("home.privacy.rights_personal.bold")}`}</b>
                          {` ${t("home.privacy.rights_personal.regular")}`}
                        </p>
                        <br />
                        <p>
                          <b>{`7) ${t("home.privacy.rights_receive.bold")}`}</b>
                          {` ${t("home.privacy.rights_receive.regular")}`}
                        </p>
                        <br />
                        <p>
                          <b>{`8) ${t("home.privacy.rights_lodge.bold")}`}</b>
                          {` ${t("home.privacy.rights_lodge.regular")}`}
                        </p>
                        <br />
                        <p>
                          <b>{t("home.privacy.rights_details")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.rights_details_1")}</p>
                        <br />
                        <p>{t("home.privacy.rights_details_2")}</p>
                        <br />
                        <p>
                          <b>{t("home.privacy.rights_exercise")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.rights_exercise_1")}</p>
                        <br />
                        <br />

                        <p>
                          <b>4. {t("home.privacy.cookie")}</b>
                        </p>
                        <hr />
                        <p>{t("home.privacy.cookie_1")}</p>
                        <br />
                        <br />

                        <p>
                          <b>5. {t("home.privacy.additional")}</b>
                        </p>
                        <hr />
                        <p>
                          <b>{t("home.privacy.additional_legal")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.additional_legal_1")}</p>
                        <br />
                        <p>{t("home.privacy.additional_legal_2")}</p>
                        <br />
                        <p>
                          <b>{t("home.privacy.additional_information")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.additional_information_1")}</p>
                        <br />
                        <p>
                          <b>{t("home.privacy.additional_system")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.additional_system_1")}</p>
                        <br />
                        <p>
                          <b>{t("home.privacy.additional_not")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.additional_not_1")}</p>
                        <br />
                        <p>
                          <b>{t("home.privacy.additional_track")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.additional_track_1")}</p>
                        <br />
                        <p>{t("home.privacy.additional_track_2")}</p>
                        <br />
                        <p>
                          <b>{t("home.privacy.additional_changes")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.additional_changes_1")}</p>
                        <br />
                        <p>{t("home.privacy.additional_changes_2")}</p>
                        <br />
                        <p>
                          <b>{t("home.privacy.google_api")}</b>
                        </p>
                        <br />
                        <p>
                          {t("home.privacy.google_api_1")}{" "}
                          <a href="https://developers.google.com/terms/api-services-user-data-policy">
                            {t("home.privacy.google_api_2")}
                          </a>
                          {t("home.privacy.google_api_3")}
                        </p>
                        <br />
                        <br />

                        <p>
                          <b>6. {t("home.privacy.definitions")}</b>
                        </p>
                        <hr />
                        <p>
                          <b>{t("home.privacy.definitions_personal")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.definitions_personal_1")}</p>
                        <br />
                        <p>
                          <b>{t("home.privacy.definitions_usage")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.definitions_usage_1")}</p>
                        <br />
                        <p>
                          <b>{t("home.privacy.definitions_user")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.definitions_user_1")}</p>
                        <br />
                        <p>
                          <b>{t("home.privacy.definitions_data")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.definitions_data_1")}</p>
                        <br />
                        <p>
                          <b>{t("home.privacy.definitions_processor")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.definitions_processor_1")}</p>
                        <br />
                        <p>
                          <b>{t("home.privacy.definitions_owner")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.definitions_owner_1")}</p>
                        <br />
                        <p>
                          <b>{t("home.privacy.definitions_application")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.definitions_application_1")}</p>
                        <br />
                        <p>
                          <b>{t("home.privacy.definitions_service")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.definitions_service_1")}</p>
                        <br />
                        <p>
                          <b>{t("home.privacy.definitions_european")}</b>
                        </p>
                        <br />
                        <p>{t("home.privacy.definitions_european_1")}</p>
                        <br />
                        <br />

                        <p>
                          <b>7. {t("home.privacy.legal_information")}</b>
                        </p>
                        <hr />
                        <p>{t("home.privacy.legal_information_1")}</p>
                        <br />
                        <p>{t("home.privacy.legal_information_2")}</p>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>
        <Footer />
      </Wrapper>
    </ThemeProvider>
  );
};

export default Privacy;
