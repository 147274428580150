import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const SingleContentMessageStyles = styled.div<{
  width?: string;
  height?: string;
  thumbnailHeight?: string;
  infoPadding?: string;
  iconWidth?: string;
  modal?: boolean;
}>`
  font-size: ${(p) => p.theme.typography.subText5};
  background-color: ${(p) => p.theme.colors.background.fifth};
  border-radius: 12px;
  width: ${(p) => p.width || "100%"};
  min-height: ${(p) => p.height || "148px"};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  .thumbnail {
    height: ${(p) => p.thumbnailHeight || "100px"};
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .single_content_icon {
      position: absolute;
      color: ${(p) => p.theme.colors.icon.secondary};
      height: ${(p) => p.iconWidth || "32px"};
      width: ${(p) => p.iconWidth || "32px"};
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    input {
      display: none;
    }
  }

  .content_info {
    padding: ${(p) => p.infoPadding || "12px"};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid ${(p) => p.theme.colors.divider};
    gap: 12px;
    font-size: ${(p) => p.theme.typography.subText3};
  }

  .single_message_left_info {
    width: fit-content;
  }

  .action_button {
    width: 100%;
    font-size: ${(p) => p.theme.typography.subText5};
    padding: 9px 16px;
  }

  .content_info_icon {
    width: 24px;
    height: 24px;
    color: ${(p) => p.theme.colors.icon.secondary};
  }

  ${deviceQuery.tabletM} {
    background-color: ${(p) =>
      p.modal ? p.theme.colors.background.fifth : p.theme.colors.background.secondary};
  }
`;
