import styled from "styled-components";

import Modal from "Components/Modal/Modal";
import { deviceQuery } from "styles/mediaSizes";

export const PayModalContainer = styled(Modal)`
  .modal-content {
    background: ${(p) => p.theme.colors.background.secondary};
    width: 460px;
    border-radius: 20px;

    .modal-body {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin: 0px;

      & > .title {
        text-align: start;
        font-size: ${(p) => p.theme.typography.text3};
        padding-bottom: 16px;
        border-bottom: 1px solid ${(p) => p.theme.colors.divider};
      }
    }
  }
`;

export const Content = styled.div`
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .types {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .pay {
      border-radius: 12px;
      background: ${(p) => p.theme.colors.background.fifth};

      .expandable {
        overflow: visible;
      }
    }
  }

  .payContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 12px 12px;

    .creator {
      display: flex;
      gap: 12px;
      align-items: center;
    }

    .payActions {
      padding: 0px 0px 12px;
      text-align: start;
      display: flex;
      gap: 16px;

      & > div {
        flex: 1;
      }

      .price {
        & > div {
          background: ${(p) => p.theme.colors.background.primary};
        }
      }

      ${deviceQuery.mobileM} {
        flex-direction: column;
      }
    }

    .action {
      padding: 12px;
      margin: -12px;
      border-top: 1px solid ${(p) => p.theme.colors.divider};
    }
  }
`;
