import styled from "styled-components";

import Button from "Components/Button";
import { ProfileForm } from "pages/creator/EditProfile/style";
import { deviceQuery } from "styles/mediaSizes";

export const LinksContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;

  .video {
    display: none;
    // flex: 1;
    border-radius: 12px;
    background: ${(p) => p.theme.colors.background.secondary};
    max-height: 600px;
  }

  .formContainer {
    display: flex;
    width: 100%;
    max-width: 740px;
    // flex: 2;

    form {
      flex: 1;

      .saveContainer {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  ${deviceQuery.tabletM} {
    flex-direction: column;
    .video {
      min-height: 300px;
    }
    .formContainer {
      form {
        .saveContainer {
          left: 0px;
          width: 100vw;
          padding: 12px 20px;
          border-top: 1px solid ${(p) => p.theme.colors.divider};
          background: ${(p) => p.theme.colors.background.secondary};
          bottom: 0vh;
          position: fixed;
        }
      }
    }
  }
`;

export const AddLinkContainer = styled.div<{ hasGap?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .textBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;

    .title {
      font-size: ${(p) => p.theme.typography.text3};
    }
  }

  .link {
    background-color: ${(p) => p.theme.colors.background.secondary};
    padding: 16px;
    border-radius: 16px;

    display: flex;
    gap: 16px;

    .url_section {
      position: relative;

      .reset_btn {
        position: absolute;
        right: 0;
        top: 0;

        color: ${(p) => p.theme.colors.text.sixth};

        &:hover {
          background-color: unset;
        }

        &.active {
          color: ${(p) => p.theme.colors.text.third};
        }

        font-size: ${(p) => p.theme.typography.subText5};
        padding: 0;
        border: 0;
      }
    }
  }

  .description {
    color: ${(p) => p.theme.colors.text.secondary};
    font-size: ${(p) => p.theme.typography.subText5};
  }

  .divider {
    display: none;
    width: 100%;
    height: 1px;
    background-color: ${(p) => p.theme.colors.divider};
  }

  ${deviceQuery.tabletM} {
    .link {
      flex-direction: column;
      gap: ${(p) => (p.hasGap ? "16px" : 0)};
    }
    .sensitive {
      display: none;
    }
    .textBlock {
      flex-wrap: wrap;
    }
    .divider {
      margin: 0 -16px;
      width: calc(100% + 32px);
      display: block;
    }
  }
`;

export const MobileAppearContainer = styled.div<{ hasLink: boolean }>`
  display: grid;
  grid-template-rows: 1fr;
  transition: grid-template-rows 0.3s ease-in-out;

  .expandable {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  ${deviceQuery.tabletM} {
    .expandable {
      flex-direction: column;
    }

    grid-template-rows: ${(p) => (p.hasLink ? "1fr" : "0fr")};
  }
`;

export const AddLinkButton = styled(Button)`
  align-self: flex-start;
  margin-top: 30px;
  height: 50px;
  min-width: max-content;

  ${deviceQuery.tabletM} {
    width: 100%;
    margin-top: 0px;
    padding: 12px;
  }
`;
