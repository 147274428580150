import styled from "styled-components";

export const GoogleButtonContainer = styled.div<{ text: string }>`
  .google_custom_button {
    cursor: pointer;
    position: relative;
    border: 1px solid ${(p) => p.theme.colors.field.full}; // todo change
    border-radius: 12px;
    height: 48px !important;
    overflow: hidden;

    &:before {
      pointer-events: all;
      --scale: 0.4;
      position: absolute;
      z-index: 2;

      top: calc(50% * 0.35);
      left: calc((50%) - 110px);

      content: url("/google.png");
      display: block;
      transform: scale(var(--scale)) translate(-50%, -50%);
    }

    &:after {
      pointer-events: all;
      position: absolute;
      z-index: 2;
      width: max-content;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: "${(p) => p.text}";
    }

    & > div {
      width: 100%;
      height: 100%;
      position: absolute !important;
      top: 0;
      opacity: 0.0000001;
      z-index: 3;

      iframe {
        transform: scale(10) translateX(27%);
        margin: -2px 0 !important;
        width: 100% !important;
      }
    }
  }
`;
