/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import { Content, PayModalContainer } from "./styles";

import Lewk from "assets/svg/Lewk";
import { makeToast } from "common/helpers/helper";
import Accordion2 from "Components/Accordion2";
import Avatar from "Components/Avatar";
import Button from "Components/Button";
import SendCollapsed from "Components/Gifting/components/SendCollapsed";
import Input from "Components/Input";
import { Option } from "Components/SelectField/types";
import SingleSelect from "Components/SingleSelect";
import { STRINGS } from "constants/appConstants";
import { payContentMessage } from "services/newMessagesService";
import { getOpenedChat } from "store/slices/global/chatsSlice";
import { getGroups, getTokens, getWallet } from "store/slices/walletSlice";
import { SendGiftType } from "types/gifting";
import { NewMessageItem } from "types/new-messages.types";

interface Props {
  message: NewMessageItem;
  show: boolean;
  setShowModal: () => void;
}

const PayModal: FC<Props> = ({ message, show, setShowModal }) => {
  const { t } = useTranslation();
  const openedChat = useSelector(getOpenedChat);
  const groups = useSelector(getGroups);
  const tokens = useSelector(getTokens);
  const wallet = useSelector(getWallet);
  const [token, setToken] = useState({} as unknown as Option);
  const [error, setError] = useState(false);
  const options = useMemo(
    () =>
      groups
        .find((item) => item.key === message.tokenGroup)
        ?.tokens.map<Option>((item) => {
          return {
            value: item,
            label: tokens[item],
          };
        }),
    []
  );

  const handleClick = () => {
    payContentMessage(
      { chatId: openedChat!._id, messageId: message._id },
      { token: token.value as string, amount: message.price! },
      () => setShowModal()
    );
  };

  const checkBalanceError = () => {
    const asset = wallet.find((item) => item.assetId === token.value);

    if (message.price && asset && asset?.balance < message.price) {
      makeToast({
        message: t("new_chat.balanceError"),
        type: STRINGS.error,
      });
      setError(true);
      return;
    }
    setError(false);
  };

  useEffect(() => {
    if (options) {
      setToken(options[0]);
    }
  }, []);

  useEffect(() => {
    show && checkBalanceError();
  }, [token, show]);

  return (
    <PayModalContainer show={show} onHide={setShowModal} showCloseButton size="md">
      <p className="title">{t("new_chat.payModal.pay")}</p>
      <Content>
        <div className="types">
          <Accordion2
            className="pay"
            show
            expandedContent={
              <div className="payContainer">
                <div className="creator">
                  <Avatar size="sm" src={openedChat?.anotherUser.pictureUrl_80_80} alt="creator" />
                  <p>{openedChat?.anotherUser.displayname || openedChat?.anotherUser.username}</p>
                </div>
                <div className="payActions">
                  <SingleSelect
                    label={t("common.selectNetwork")}
                    required
                    isSearchable={false}
                    value={token || { value: "", label: "" }}
                    handleChange={(value) => {
                      if (value) {
                        setToken(value);
                      }
                    }}
                    options={options}
                  />
                  <Input
                    className="price"
                    label={t("common.amount")}
                    value={message.price}
                    handleChange={() => {}}
                    readOnly
                  />
                </div>
                <div className="action">
                  <Button disabled={error} variant="primary" size="sm" full onClick={handleClick}>
                    {t("new_chat.payModal.paySubmit")}
                  </Button>
                </div>
              </div>
            }
            collapsedContent={
              <SendCollapsed
                isAuth={false}
                name={SendGiftType.CARD}
                onClick={() => {}}
                show={SendGiftType.CARD}
                icon={<Lewk size={32} />}
                title={t("new_chat.payModal.title")}
              />
            }
          />
        </div>
      </Content>
    </PayModalContainer>
  );
};

export default PayModal;
