import { FC } from "react";
import { useTranslation } from "react-i18next";

import Social from "./components/Social";
import { VerifySocialsContainer } from "./style";

import { VERIFYSOCIALS } from "constants/appConstants";
import { verifySocialApi } from "services/UserServices";

const VerifySocials: FC = () => {
  const { t } = useTranslation();

  const handleClickSocial = (value: string) => {
    verifySocialApi(value);
  };

  return (
    <VerifySocialsContainer>
      <div className="title">
        <span>{t("common.user_profile.verify_social_account")}</span>
        <span className="click">{t("common.click_on_icon")}</span>
      </div>
      <div className="socials">
        {VERIFYSOCIALS.map((item, index) => (
          <Social key={index} social={item} onClick={() => handleClickSocial(item.value)} />
        ))}
      </div>
    </VerifySocialsContainer>
  );
};

export default VerifySocials;
