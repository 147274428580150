import { FC, ReactNode } from "react";

import { TabPanelContainer } from "./style";

interface Props {
  children: ReactNode;
  activeTab: number;
  index: number;
}

// Component for display selected tab content
const TabPanel: FC<Props> = ({ children, activeTab, index }) => (
  <TabPanelContainer className={activeTab === index ? "isShow" : ""}>{children}</TabPanelContainer>
);

export default TabPanel;
