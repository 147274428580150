const Airdrop = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.64001 17.4H13.76C13.76 15.52 12.13 14 10.12 14H5.97001C5.58001 14 5.20001 14.11 4.88001 14.32L2.01001 16.18"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.42 17.4H14.85L18.89 16.26C20.06 15.93 21.32 16.38 22.01 17.38L15.74 21H7.05005"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 9C12.6569 9 14 7.65685 14 6C14 4.34315 12.6569 3 11 3C9.34315 3 8 4.34315 8 6C8 7.65685 9.34315 9 11 9Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4399 12.75C15.4399 11.75 17.0599 11.75 18.0599 12.75C19.0599 13.75 19.0599 15.37 18.0599 16.37"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Airdrop;
