import styled from "styled-components";

export const EmptyContentInputStyles = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: ${(p) => p.theme.colors.text.third};

  &.error {
    color: ${(p) => p.theme.colors.text.error};
  }

  input {
    display: none;
  }
`;
