const Gift = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.8398 2.75H8.16977C5.14977 2.75 3.25977 4.89 3.25977 7.92V16.09C3.25977 19.12 5.13977 21.26 8.16977 21.26H16.8398C19.8698 21.26 21.7598 19.12 21.7598 16.09V7.92C21.7598 4.89 19.8698 2.75 16.8398 2.75Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2793 3V21"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.5 9.73047H3.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2799 9.7293C11.2799 9.7293 10.2999 8.30929 9.3399 6.96929C8.5099 5.80929 6.70989 6.0893 6.26989 7.4393C5.89989 8.5693 6.7399 9.7193 7.9199 9.7193H11.2799V9.7293Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2793 9.7293C11.2793 9.7293 12.2593 8.30929 13.2193 6.96929C14.0493 5.80929 15.8493 6.0893 16.2893 7.4393C16.6593 8.5693 15.8193 9.7193 14.6393 9.7193H11.2793V9.7293Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2193 6.98047L7.2793 15.0405"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33984 6.98047L15.2798 15.0405"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Gift;
