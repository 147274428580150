import { debounce } from "lodash";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { validateAndExtractTikTokID, validateIfShortenedTiktokUrl } from "common/helpers/helper";
import { TikTokContentPreviewCardContainer } from "pages/creator/Bio/BioLink/components/Social/SocialExpanded/TiktokContentPreviewCard/style";
import { getUnShortenedUrl } from "services/UserServices";

interface Props {
  url: string;
  videoId: string;
  errorStateChanged?: (hasError: boolean) => void;
  className?: string;
  iframeClassName?: string;
}

const TiktokContentPreviewCard: FC<Props> = ({ url, videoId, errorStateChanged }) => {
  const ref = useRef<HTMLIFrameElement>(null);
  const [scale, setScale] = useState<number>(1);
  const [iframeHeight, setHeight] = useState<string>("unset");

  const [urlFromShortened, setUrlFromShortened] = useState<string>("");

  const memoVideoId = useMemo(() => {
    if (videoId) return videoId;
    if (urlFromShortened) {
      return validateAndExtractTikTokID(urlFromShortened);
    }
  }, [urlFromShortened, videoId]);

  useEffect(() => {
    errorStateChanged && errorStateChanged(false);
  }, [videoId]);

  const getScale = () => {
    const iframe = ref.current;
    if (!iframe) return;

    const parentWidth = iframe.parentElement?.clientWidth || 0;

    const scale = parentWidth / iframe.clientWidth;

    setScale(scale);
  };

  const setIframeHeight = useCallback(() => {
    if (!ref.current) return "unset";
    const height = !ref.current ? "unset" : `${ref.current.clientHeight * scale}px`;

    setHeight(height);
  }, [scale]);

  useEffect(() => {
    setIframeHeight();
  }, [scale, setIframeHeight]);

  useEffect(() => {
    getScale();
  }, []);

  useEffect(() => {
    const debouncedResize = debounce(getScale, 300, { maxWait: 1000 });
    debouncedResize();

    window.addEventListener("resize", debouncedResize);
    return () => window.removeEventListener("resize", debouncedResize);
  }, []);

  const urlChangedWithDebounce = useCallback((url: string) => {
    getUnShortenedUrl(
      url,
      (resp) => {
        if (!resp.status) {
          errorStateChanged && errorStateChanged(true);
          return;
        }

        const fullUrl = resp.data.url;
        if (!fullUrl) return;

        setUrlFromShortened(fullUrl);
      },
      () => {
        errorStateChanged && errorStateChanged(true);
      }
    );
  }, []);

  useEffect(() => {
    // If url isn't shortened, skip server request, means url already full size
    if (!validateIfShortenedTiktokUrl(url)) return;
    const debouncedUrl = debounce(urlChangedWithDebounce, 400);

    debouncedUrl(url);

    return () => {
      debouncedUrl.cancel();
    };
  }, [url, urlChangedWithDebounce]);

  return (
    <TikTokContentPreviewCardContainer height={iframeHeight} iframeScale={`scale(${scale})`}>
      <iframe
        ref={ref}
        title={memoVideoId}
        className="iframe"
        frameBorder={0}
        src={`https://www.tiktok.com/embed/${memoVideoId}`}
        allowFullScreen
        scrolling="no"
        allow="encrypted-media;"
      />
    </TikTokContentPreviewCardContainer>
  );
};

export default TiktokContentPreviewCard;
