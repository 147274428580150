import { FC } from "react";

interface Props {
  size?: number;
}

const Copy: FC<Props> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.21973 5.82812C6.42408 5.82812 5.66101 6.1442 5.0984 6.7068C4.53579 7.26941 4.21973 8.03248 4.21973 8.82812V17.1781C4.21973 18.239 4.64115 19.2564 5.3913 20.0066C6.14144 20.7567 7.15886 21.1781 8.21973 21.1781H13.7697C14.5654 21.1781 15.3284 20.8621 15.891 20.2994C16.4537 19.7368 16.7697 18.9738 16.7697 18.1781"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7097 2.82812H10.2197C9.42408 2.82812 8.66101 3.1442 8.0984 3.7068C7.53579 4.26941 7.21973 5.03248 7.21973 5.82812V15.1781C7.21973 15.9738 7.53579 16.7368 8.0984 17.2994C8.66101 17.8621 9.42408 18.1781 10.2197 18.1781H16.7697C17.5654 18.1781 18.3284 17.8621 18.891 17.2994C19.4537 16.7368 19.7697 15.9738 19.7697 15.1781V8.10812L14.7097 2.82812Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4502 2.82031V5.73031C14.4502 6.40847 14.7182 7.05914 15.1958 7.54054C15.6735 8.02194 16.3221 8.29504 17.0002 8.30031H19.7502"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Copy;
