import i18n from "i18next";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { LanguageSelectorContainer, MenuItem } from "./style";

import Check from "assets/svg/Check";
import { changeLanguage } from "common/helpers/language";
import { MenuStyled } from "Components/Header/style";
import { LANGUAGES } from "constants/appConstants";
import { LANGUAGES as LANGUAGESTYPE } from "types/language.types";

const LanguageSelector = () => {
  const { t } = useTranslation();
  const [menu, setMenu] = useState(false);
  const [language, setLanguage] = useState(LANGUAGES[0]);

  const handleChange = (value: LANGUAGESTYPE) => {
    changeLanguage(value);
    setMenu(false);
  };

  useEffect(() => {
    setLanguage(LANGUAGES.find((item) => item.shortValue === i18n.language) || LANGUAGES[0]);
  }, [i18n.language]);

  return (
    <MenuStyled active={menu} onClick={() => setMenu(!menu)}>
      <div className="icon">{language.icon}</div>
      <LanguageSelectorContainer show={menu} top={66} width={328}>
        {LANGUAGES.map((item) => (
          <MenuItem
            key={item.value}
            active={item.value === language.value}
            onClick={() => handleChange(item.value)}
          >
            <span>{t(item.label)}</span>
            <div className="icon">
              <Check />
            </div>
          </MenuItem>
        ))}
      </LanguageSelectorContainer>
    </MenuStyled>
  );
};

export default LanguageSelector;
