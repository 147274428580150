import { FormikErrors } from "formik";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import Close from "assets/svg/Close";
import Restore from "assets/svg/Restore";
import {
  getFileSizeInMb,
  getIconByContentType,
  getMediaTypeFromName,
  isSupportedExtension,
} from "common/helpers/file-helpers";
import {
  messageContentSupportedExtensions,
  messageSupportedImageExtensions,
} from "constants/file-extensions";
import { UploadContentInputContainerStyles } from "pages/public/chat/Components/Chat/AddContentModal/UploadContentInput/styles";
import { ContentInfoItem } from "pages/public/chat/Components/Chat/ContentInfoitem";
import { SingleContentMessageStyles } from "pages/public/chat/Components/Chat/SingleContentMessage/styles";
import { ContentItem } from "types/new-messages.types";

interface Props {
  onRemove: () => void;
  onFileChange: (file: File) => void;
  hasError: boolean;
  error?: string | FormikErrors<ContentItem>;
  file: File;
}

export const UploadContentInput = memo(
  ({ onRemove, file, onFileChange, error, hasError }: Props) => {
    const [imagePreview, setImagePreview] = useState<string | undefined>(undefined);
    const inputRef = useRef<HTMLInputElement>(null);

    const fileSizeInMB = useMemo(() => {
      const sizeInMb = getFileSizeInMb(file.size);
      return `${sizeInMb.toFixed(2)} MB`;
    }, [file]);

    const transformFileToImagePreview = useCallback((file: File) => {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }, []);

    useEffect(() => {
      if (!isSupportedExtension(file.name, messageSupportedImageExtensions)) return;
      transformFileToImagePreview(file);
    }, [file]);

    const contentType = useMemo(() => {
      return getMediaTypeFromName(file.name);
    }, [file]);

    const getContentIcon = useMemo(() => {
      if (!contentType) return;
      return getIconByContentType(contentType, "single_content_icon content_info_icon");
    }, [file]);

    return (
      <UploadContentInputContainerStyles hasError={hasError}>
        <SingleContentMessageStyles
          height="114px"
          thumbnailHeight="78px"
          infoPadding="8px"
          iconWidth="24px"
          modal
        >
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="thumbnail" style={{ backgroundImage: `url(${imagePreview})` }}>
            {/*  displaying icon only for non image files as we don't do preview for them */}
            {contentType !== "image" && getContentIcon}
            {!error ? (
              <div
                className="remove_content"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  onRemove();
                }}
              >
                <Close size={24} />
              </div>
            ) : (
              <div
                className="remove_content"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  inputRef.current && inputRef.current.click();
                }}
              >
                <Restore />
              </div>
            )}
            <input
              type="file"
              ref={inputRef}
              accept={messageContentSupportedExtensions.join(",")}
              onChange={(event) => {
                const file = event.target.files![0] as File;
                onFileChange(file);
              }}
            />
          </label>
          <div className="content_info">
            <ContentInfoItem icon={getContentIcon} contentInfo={fileSizeInMB} error={error} />
          </div>
        </SingleContentMessageStyles>
      </UploadContentInputContainerStyles>
    );
  }
);
