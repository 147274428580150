import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const ContentPriceBlockStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  color: ${(p) => p.theme.colors.text.third};

  .overlay {
    position: absolute;
    z-index: 3;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &.show {
      pointer-events: all;
    }
  }

  .price {
    color: ${(p) => p.theme.colors.text.primary};
    font-size: ${(p) => p.theme.typography.subText5};
  }

  .price-input-wr {
    flex: 1;
    position: relative;
    text-align: start;

    .price_input {
      color: ${(p) => p.theme.colors.text.primary};
      width: 100%;

      & > div {
        border: none;
        border-radius: unset;

        & > input {
          background-color: ${(p) => p.theme.colors.background.fifth};
          border-radius: 8px;
          height: unset;
          line-height: 22px;
          padding: 9px 40px 9px 12px;

          ${deviceQuery.mobileM} {
            padding: 9px 12px 40px;
          }
        }

        & > div.right {
          justify-content: center;
          flex: 0;
        }
      }
    }
  }

  .send_message_btn {
    color: ${(p) => p.theme.colors.icon.secondary};
    padding: unset;
    border: none;
    &:hover {
      background-color: unset;
      border: unset;
      svg {
        path {
          stroke: none !important;
          color: ${(p) => p.theme.colors.icon.third};
        }
      }
    }
  }
`;
