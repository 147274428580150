import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const ChatWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 0 1fr 0 auto;
  height: 100%;

  .divider {
    border-top: 1px solid ${(p) => p.theme.colors.divider};
  }

  ${deviceQuery.tabletM} {
    & > .divider {
      margin: 0px -16px;
    }
  }
`;
