import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const BioLinkContainer = styled.div<{ mobile: boolean }>`
  display: flex;
  padding: ${(p) => (p.mobile ? "8px" : "12px")};
  gap: ${(p) => (p.mobile ? "8px" : "12px")};
  border-radius: ${(p) => (p.mobile ? "8px" : "12px")};
  background: ${(p) => p.theme.colors.background.secondary};
  color: ${(p) => p.theme.colors.text.primary};
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
  align-items: center;
  width: 100%;

  .icon {
    padding: ${(p) => (p.mobile ? "8px" : "16px")};
    border-radius: 50%;
    background: ${(p) => p.theme.colors.links.icon};
  }

  .info {
    display: flex;
    gap: 4px;
    flex: 1;
    flex-direction: column;
    font-size: ${(p) => (p.mobile ? p.theme.typography.subText3 : p.theme.typography.subText5)};

    .copy {
      text-decoration: none;
      align-items: center;
      font-size: ${(p) => (p.mobile ? p.theme.typography.smallText3 : p.theme.typography.subText3)};
      color: ${(p) => p.theme.colors.green.primary};
      gap: 4px;
      display: inline-flex;
      cursor: pointer;
    }
  }

  .spoiler {
    color: ${(p) => p.theme.colors.text.secondary};
    display: flex;
    gap: ${(p) => (p.mobile ? "8px" : "16px")};

    .divider {
      background: ${(p) => p.theme.colors.divider};
      width: 1px;
    }

    .arrow {
      color: ${(p) => p.theme.colors.divider};
    }
  }

  .subscribers {
    display: flex;
    align-items: center;
    padding-right: ${(p) => (p.mobile ? "0px" : "12px")};
  }

  ${deviceQuery.laptopL} {
    padding: ${(p) => (p.mobile ? "4px" : "8px")};
    gap: ${(p) => (p.mobile ? "4px" : "8px")};

    .icon {
      padding: ${(p) => (p.mobile ? "8px" : "12px")};
    }

    .spoiler {
      gap: ${(p) => (p.mobile ? "4px" : "8px")};
    }
  }

  ${deviceQuery.tabletM} {
    .spoiler {
      transform: unset;
    }
  }
`;
