import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import adminSlice from "./slices/admin/adminSlice";
import challengeRewardSlice from "./slices/admin/challengeRewardSlice";
import onboardingsSlice from "./slices/admin/onboardingsSlice";
import signupRequestsSlice from "./slices/admin/signupRequestsSlice";
import authSlice from "./slices/authSlice";
import analyticsSlice from "./slices/creator/analyticsSlice";
import challengeDetailSlice from "./slices/creator/challengeDetailSlice";
import challengeSlice from "./slices/creator/challengesSlice";
import creatorSlice from "./slices/creator/creatorSlice";
import earningsSlice from "./slices/creator/earningsSlice";
import giftingSlice from "./slices/creator/giftingSlice";
import eventBusSlice from "./slices/eventBusSlice";
import activityFeedSlice from "./slices/fan/activityFeedSlice";
import fanSlice from "./slices/fan/fanSlice";
import myTransactionsSlice from "./slices/fan/myTransactionsSlice";
import subscriptionsSlice from "./slices/fan/subscriptionsSlice";
import featureSlice from "./slices/featureSlice";
import chatsSlice from "./slices/global/chatsSlice";
import globalSlice from "./slices/global/globalSlice";
import notificationSlice from "./slices/global/notificationSlice";
import previewProfileSlice from "./slices/global/previewProfileSlice";
import userSlice from "./slices/global/userSlice";
import userVisitedPagesSlice from "./slices/userVisitedPagesSlice";
import walletSlice from "./slices/walletSlice";

import { listenerMiddleware } from "store/middwares/listenerMiddware";
import { messagesSlice } from "store/slices/newMessages/messagesSlice";

const rootReducer = combineReducers({
  global: combineReducers({
    global: globalSlice,
    user: userSlice,
    publicUser: previewProfileSlice,
    chats: chatsSlice,
    notification: notificationSlice,
  }),
  newMessages: messagesSlice.reducer,
  auth: authSlice,
  eventBus: eventBusSlice,
  userVisitedPages: userVisitedPagesSlice,
  feature: featureSlice,
  admin: combineReducers({
    admin: adminSlice,
    onboarding: onboardingsSlice,
    manageChallengeRewards: challengeRewardSlice,
    signupRequests: signupRequestsSlice,
  }),
  creator: combineReducers({
    main: creatorSlice,
    gifting: giftingSlice,
    analytics: analyticsSlice,
    challenging: combineReducers({
      challenges: challengeSlice,
      details: challengeDetailSlice,
      earnings: earningsSlice,
    }),
  }),
  wallet: walletSlice,
  fan: combineReducers({
    fan: fanSlice,
    subscriptions: subscriptionsSlice,
    activity: activityFeedSlice,
    myTransactions: myTransactionsSlice,
  }),
});

export type RootState = ReturnType<typeof rootReducer>;
export const store = configureStore({
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).prepend(listenerMiddleware.middleware);
  },
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;

export default store;
