const Sound = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5377 3.32643C20.3024 2.98156 19.8375 2.89639 19.4992 3.13619C19.1609 3.37599 19.0773 3.84995 19.3126 4.19482C20.7164 6.25284 21.5078 8.98228 21.5078 11.9422C21.5078 14.9023 20.7162 17.6327 19.3126 19.6906C19.0773 20.0354 19.1609 20.5094 19.4992 20.7492C19.8375 20.989 20.3024 20.9038 20.5377 20.5589C22.1203 18.2387 23 15.2045 23 11.9422C23 8.68003 22.1205 5.64684 20.5377 3.32643ZM10.6861 3.2546L10.5311 3.26065C10.0241 3.30794 9.40026 3.60486 8.98222 3.96585L5.52909 6.83428L3.70741 6.83485C2.34629 6.83485 1.3396 7.8715 1.14681 9.27666C0.954152 10.8519 0.947973 13.4723 1.14689 14.9137L1.17341 15.0992C1.40832 16.5009 2.36468 17.4197 3.70741 17.4197L5.53009 17.4192L9.07152 20.3519C9.52098 20.7372 10.1671 21.0124 10.6976 20.999C11.74 21.0311 12.6826 20.252 13.0242 19.0891C13.1034 18.789 13.1517 18.5275 13.1941 18.1876L13.3012 17.1796C13.4738 15.774 13.479 8.95588 13.3167 7.22015L13.236 6.47137C13.1754 5.90001 13.1234 5.54903 13.0313 5.19016C12.7007 4.06459 11.8372 3.30555 10.8272 3.25613L10.6861 3.2546ZM16.086 6.23974C16.4243 5.99996 16.8893 6.08516 17.1245 6.43004C18.1407 7.92009 18.7034 9.86075 18.7034 11.9427C18.7034 14.0244 18.1409 15.9641 17.1245 17.4543C16.8893 17.7992 16.4243 17.8844 16.086 17.6446C15.7477 17.4048 15.6641 16.9309 15.8993 16.586C16.7368 15.3581 17.2112 13.7222 17.2112 11.9427C17.2112 10.163 16.7366 8.52602 15.8993 7.29836C15.6641 6.95348 15.7477 6.47952 16.086 6.23974Z"
      fill="currentColor"
    />
  </svg>
);

export default Sound;
