import styled from "styled-components";

import Modal from "Components/Modal/Modal";
import { deviceQuery } from "styles/mediaSizes";

export const SelectModal = styled(Modal)`
  .modal-content {
    background: ${(p) => p.theme.colors.background.secondary};
    width: 550px;

    .modal-body {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .topic {
        font-size: ${(p) => p.theme.typography.text3};
        border-bottom: 1px solid ${(p) => p.theme.colors.divider};
        padding-bottom: 16px;
        text-align: start;
      }
    }
  }

  ${deviceQuery.tabletM} {
    .modal-content {
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
  text-align: start;
  flex-wrap: wrap;

  .social {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border: 1px solid ${(p) => p.theme.colors.divider};
    border-radius: 6px;
    color: ${(p) => p.theme.colors.text.secondary};

    .title {
      font-size: ${(p) => p.theme.typography.subText3};
      max-width: 84px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      background: ${(p) => p.theme.colors.green.primary};
      color: ${(p) => p.theme.colors.text.primary};
    }
  }

  ${deviceQuery.tabletM} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${deviceQuery.mobileM} {
    grid-template-columns: repeat(2, 1fr);

    .social {
      .title {
        max-width: 78px;
      }
    }
  }
`;
