import styled from "styled-components";

import Button from "Components/Button";
import { deviceQuery } from "styles/mediaSizes";

export const Info = styled.div`
  display: grid;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  background: ${(p) => p.theme.colors.background.secondary};
  column-gap: 16px;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  grid-template-rows: auto;
  grid-template-areas:
    "title button"
    "description button";

  .title {
    grid-area: title;
    color: ${(p) => p.theme.colors.text.primary};
    font-size: ${(p) => p.theme.typography.text3};
  }

  .description {
    grid-area: description;
    color: ${(p) => p.theme.colors.text.secondary};
    font-size: ${(p) => p.theme.typography.subText5};
  }

  ${deviceQuery.mobileL} {
    row-gap: 12px;
    grid-template-areas:
      "title button"
      "description description";
  }
`;

export const MFAButton = styled(Button)`
  grid-area: button;
  align-self: flex-start;
  border-radius: 8px;
  padding: 9px 16px;
  min-width: 82px;

  ${deviceQuery.mobileL} {
    margin-left: auto;
  }
`;
