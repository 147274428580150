import { FC } from "react";

interface Props {
  size?: number;
}

const LinkClick: FC<Props> = ({ size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6666 11.0416C16.3249 11.0416 16.0416 11.3249 16.0416 11.6666V13.6583C16.0416 14.9249 14.9999 15.9666 13.7249 15.9749L6.31659 16.0416H6.29159C5.66659 16.0416 5.08325 15.7999 4.64159 15.3666C4.19992 14.9249 3.95825 14.3499 3.95825 13.7249V6.27492C3.95825 4.99992 5.00825 3.95825 6.29992 3.95825H8.33325C8.67492 3.95825 8.95825 3.67492 8.95825 3.33325C8.95825 2.99159 8.67492 2.70825 8.33325 2.70825H6.29992C4.32492 2.70825 2.70825 4.30825 2.70825 6.27492V13.7249C2.70825 14.6833 3.08325 15.5749 3.76659 16.2499C4.44159 16.9249 5.34159 17.2916 6.29992 17.2916H6.33325L13.7416 17.2249C15.6999 17.2083 17.2999 15.6083 17.2999 13.6583V11.6666C17.2999 11.3249 17.0166 11.0416 16.6749 11.0416H16.6666Z"
      fill="currentColor"
    />
    <path
      d="M17.2417 3.09162C17.175 2.94162 17.0583 2.81662 16.9 2.74995C16.825 2.71662 16.7416 2.69995 16.6583 2.69995H11.6583C11.3167 2.69995 11.0333 2.98328 11.0333 3.32495C11.0333 3.66662 11.3167 3.94995 11.6583 3.94995H15.15L8.71665 10.3833C8.47498 10.625 8.47498 11.025 8.71665 11.2666C8.84165 11.3916 8.99998 11.45 9.15832 11.45C9.31665 11.45 9.47498 11.3916 9.59998 11.2666L16.0333 4.83328V8.32495C16.0333 8.66662 16.3166 8.94995 16.6583 8.94995C17 8.94995 17.2833 8.66662 17.2833 8.32495V3.33328C17.2833 3.24995 17.2667 3.17495 17.2333 3.09162H17.2417Z"
      fill="currentColor"
    />
  </svg>
);

export default LinkClick;
