import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const HeaderContainer = styled.div<{ mobile?: boolean; backgroundSelected: boolean }>`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: ${(p) => (p.mobile ? "42px 16px 12px" : "16px 16px 12px")};

  ${(p) =>
    p.mobile &&
    css`
      z-index: 5;
      color: ${p.theme.colors.text.primary};
    `}

  img {
    width: ${(p) => (p.mobile ? "56px" : "84px")};
    object-fit: contain;
  }

  ${deviceQuery.laptopL} {
    padding: ${(p) => (p.mobile ? "30px 16px 12px" : "16px 16px 12px")};
  }

  ${deviceQuery.tabletM} {
    padding: ${(p) => (p.mobile ? "42px 16px 12px" : "16px 16px 12px")};
  }

  ${deviceQuery.mobileS} {
    padding: ${(p) => (p.mobile ? "42px 16px 12px" : "16px 16px 12px")};
  }
`;
