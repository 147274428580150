import styled, { css } from "styled-components";

import Modal from "Components/Modal/Modal";
import { deviceQuery } from "styles/mediaSizes";
import { HideScrollBar } from "styles/mixins";

export const CropModalContainer = styled(Modal)`
  .modal-dialog {
    font-family: Outfit-Regular;
    margin: 0px;
    max-height: 100vh;

    .modal-content {
      background: ${(p) => p.theme.colors.background.secondary};
      max-width: 1200px;
      max-height: calc(100vh - 64px);

      .modal-body {
        .title {
          text-align: start;
          font-size: ${(p) => p.theme.typography.text3};
          padding-bottom: 16px;
          border-bottom: 1px solid ${(p) => p.theme.colors.divider};
        }
      }
    }
  }

  ${deviceQuery.mobileL} {
    .modal-dialog {
      padding: 0;
      min-height: 100vh;
      max-height: 100vh;
      // use if supported
      min-height: 100svh;
      max-height: 100svh;

      .modal-content {
        min-height: 100vh;
        min-height: 100svh;
        max-height: unset;
        border-radius: 0;

        .modal-body {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  ${deviceQuery.laptopM} {
    .modal-dialog {
      .modal-content {
        max-height: unset;
      }
    }
  }
`;

export const Content = styled.div<{ lines: boolean }>`
  padding-top: 16px;
  width: 100%;
  display: flex;
  gap: 20px;

  .editor {
    height: calc(100vh - 234px);
    width: 75%;
    max-width: 800px;

    ${(p) =>
      p.lines &&
      css`
        .dashed-v {
          border-width: 2px;
          border-color: #00ff00;
          left: calc(100% * 0.3);
          width: calc(100% / 2.5);
        }
      `}
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: ${(p) => p.theme.typography.subText5};

    .info {
      display: flex;
      gap: 8px;
      align-items: center;

      .rect {
        width: 15px;
        height: 15px;
        border-radius: 4px;

        &.desktop {
          background-color: #6a88f4;
        }

        &.mobile {
          background-color: #25c374;
        }
      }
    }
  }

  .info_block_child {
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1;
  }

  .actions {
    ${HideScrollBar}
    max-height: calc(100vh - 234px);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 25%;
    text-align: start;

    .label {
      font-size: ${(p) => p.theme.typography.subText5};
      color: ${(p) => p.theme.colors.text.primary};
    }

    .width_block {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .value_info {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }

    .width_info {
      font-size: ${(p) => p.theme.typography.subText3};
      color: ${(p) => p.theme.colors.text.secondary};
    }

    .reset_btn {
      border: none;
      font-size: ${(p) => p.theme.typography.subText5};
      color: ${(p) => p.theme.colors.text.third};
      padding: 0;
      &:hover {
        background-color: unset;
      }
    }
    .buttons {
      margin-top: auto;
      gap: 20px;
      display: flex;
    }
  }

  ${deviceQuery.laptopM} {
    flex-direction: column;
    padding: 0;
    flex: 1;

    .info_block {
      display: flex;
      gap: 16px;
    }

    .editor {
      // 175 is a magic number
      height: calc(60vh - 175px);
      margin: 0 -16px;
      max-width: unset;
      // 32px is to go out of paddings and make editor fullscreen
      width: calc(100% + 32px);
    }
  }

  ${deviceQuery.mobileL} {
    .info_block {
      flex-direction: column;
    }
  }
`;

export const ValueBlock = styled.div<{ error: boolean }>`
  min-width: 80px;
  padding: 9px 12px;
  background-color: ${(p) => p.theme.colors.background.primary};
  border-radius: 12px;
  text-align: center;
  color: ${(p) => p.theme.colors.text.primary};
  border: ${(p) => (p.error ? `1px solid ${p.theme.colors.red.primary}` : "none")};
`;
