import { Outlet } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { Content, PageContainer } from "./style";

import Footer from "Components/Footer";

import NewHeader from "Components/NewHeader";
import { lightTheme } from "styles/newThemes";

const HomeLayout = () => (
  <ThemeProvider theme={lightTheme}>
    <PageContainer>
      <NewHeader />
      <div className="banner">
        <div className="overlay" />
        <img src="/banner.png" alt="banner" loading="lazy" />
      </div>
      <Content>
        <div className="content">
          <Outlet />
        </div>
      </Content>
      <Footer />
    </PageContainer>
  </ThemeProvider>
);

export default HomeLayout;
