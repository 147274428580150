const Attach = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 13.6803L12.03 4.56031C14.08 2.49031 17.41 2.49031 19.46 4.56031C21.51 6.63031 21.51 10.0003 19.46 12.0703L11.5 20.1203C10.33 21.3103 8.43 21.3103 7.25 20.1203C6.08 18.9303 6.08 17.0103 7.25 15.8303L14.68 8.32031"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Attach;
