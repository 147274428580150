import { useFormik } from "formik";
import { debounce } from "lodash";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import * as Yup from "yup";

import { AddLinkButton, AddLinkContainer, LinksContainer, MobileAppearContainer } from "./style";

import { SocialsForm } from "../../../style";
import SelectSocial from "../SelectSocial";

import { autoSelectBioLink, getImageTagFromFaviconBase64 } from "common/helpers/helpers";
import Button from "Components/Button";
import Input from "Components/Input";
import { SocialIcons } from "constants/appConstants";
import { useFirstRender } from "customHooks/useFirstRender";
import { getFaviconAsBase64ByUrl } from "services/UserServices";
import { IFormikAddPublicLinkForm, IPublicLink, Platform } from "types/user";

interface Props {
  onNewLinkAdded: (publicLink: IPublicLink) => void;
}
export const AddLinkForm = memo(({ onNewLinkAdded }: Props) => {
  const { t } = useTranslation();
  const [showSelect, setShowSelect] = useState(false);
  const [fetchedCustomFaviconBase64, setFetchedCustomFaviconBase64] = useState<string>("");
  const isFirstRender = useFirstRender();

  const schema = Yup.object().shape({
    link: Yup.string().url(t("creator.profile.urlError")),
  });

  const { values, setFieldValue, setFieldTouched, handleSubmit, errors, isValid, resetForm } =
    useFormik<IFormikAddPublicLinkForm>({
      enableReinitialize: true,
      validationSchema: schema,
      initialValues: {
        icon: SocialIcons[SocialIcons.length - 1],
        title: SocialIcons[SocialIcons.length - 1].title,
        value: SocialIcons[SocialIcons.length - 1].value,
        link: "",
      },
      onSubmit: () => {},
    });

  useEffect(() => {
    if (isFirstRender) return;

    const debouncedSearch = debounce(
      () => {
        const socialIconObj = autoSelectBioLink(values.icon, values.link);

        if (!isValid) return;

        setFieldValue("title", socialIconObj.title);
        setFieldValue("value", socialIconObj.value);
        setFieldValue("icon", socialIconObj);

        // we found icon locally
        if (socialIconObj.value !== "default1") {
          return;
        }

        if (!values.link) return;

        getFaviconAsBase64ByUrl(values.link, (res) => {
          setFetchedCustomFaviconBase64(res.favicon);
        });
      },
      500,
      { maxWait: 1000 }
    );

    debouncedSearch();

    return () => {
      debouncedSearch.cancel();
    };
  }, [values.link, isValid]);

  return (
    <LinksContainer>
      <div className="formContainer">
        <SocialsForm onSubmit={handleSubmit}>
          <AddLinkContainer hasGap={Boolean(values.link && !errors.link)}>
            <div className="textBlock">
              <p className="title">{t("creator.profile.addNewLink")}</p>
            </div>
            <div className="link">
              <div className="url_section">
                <Input
                  className="full"
                  required
                  placeholder={t("creator.profile.urlPlaceholder")}
                  label={t("creator.profile.url")}
                  value={values.link}
                  handleChange={(event) => {
                    setFieldValue("link", event.target.value);
                  }}
                  error={values.link && errors.link ? errors.link : ""}
                />
                <Button
                  variant="secondary"
                  className={`reset_btn ${values.link && "active"}`}
                  onClick={() => resetForm()}
                >
                  {t("common.clear")}
                </Button>
              </div>

              <MobileAppearContainer hasLink={Boolean(values.link && !errors.link)}>
                <div className="expandable">
                  <SelectSocial
                    disabled={
                      values.value !== SocialIcons[SocialIcons.length - 1].value &&
                      values.link !== ""
                    }
                    title={values.title}
                    isShow={showSelect}
                    handleClick={() => setShowSelect(true)}
                    handleClose={() => setShowSelect(false)}
                    label={t("creator.profile.icon")}
                    item={{
                      ...values.icon,
                      label:
                        values.icon.value === "default1" && fetchedCustomFaviconBase64
                          ? getImageTagFromFaviconBase64(fetchedCustomFaviconBase64)
                          : values.icon.label,
                    }}
                    onConfirm={(option) => {
                      if (fetchedCustomFaviconBase64 && option.value === "default1") {
                        setFetchedCustomFaviconBase64("");
                      }
                      setFieldValue("title", option.title);
                      setFieldValue("value", option.value);
                      setFieldValue("icon", option);
                    }}
                  />
                  <Input
                    className="full"
                    placeholder={t("creator.profile.titlePlaceholder")}
                    required
                    maxLength={40}
                    label={t("creator.profile.title")}
                    value={values.title}
                    handleChange={(event) => setFieldValue("title", event.target.value)}
                  />

                  <div className="divider" />
                  <AddLinkButton
                    variant="primary"
                    disabled={!values.link || !values.title || !!errors.link}
                    onClick={() => {
                      const newPublicLink: IPublicLink = {
                        _id: Date.now().toString(),
                        icon: fetchedCustomFaviconBase64
                          ? getImageTagFromFaviconBase64(fetchedCustomFaviconBase64)
                          : values.icon.label,
                        title: values.title,
                        platform: values.value as Platform,
                        link: values.link,
                        contents: [],
                        customIcon: fetchedCustomFaviconBase64 || undefined,
                      };
                      setFieldValue("title", "");
                      setFieldValue("icon", SocialIcons[SocialIcons.length - 1]);
                      setFieldValue("link", "");
                      setFieldTouched("publicLinks", true);
                      onNewLinkAdded(newPublicLink);
                      setFetchedCustomFaviconBase64("");
                    }}
                  >
                    {t("creator.profile.addLink")}
                  </AddLinkButton>
                </div>
              </MobileAppearContainer>
            </div>
          </AddLinkContainer>
        </SocialsForm>
      </div>
    </LinksContainer>
  );
});
