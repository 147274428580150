import { FC } from "react";

interface Props {
  size?: number;
}

const RocketThin: FC<Props> = ({ size = 32 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9802 0.0100066C15.8102 0.0100066 15.3502 0.0100066 12.1102 5.50829C11.7502 6.12809 11.5602 6.82787 11.5602 7.54764V11.2165C11.5602 13.7357 9.5102 15.5751 7.7802 16.6648C7.7302 16.6948 7.7002 16.7448 7.7002 16.8047V21.0234C7.7002 21.0234 7.7202 21.1134 7.7502 21.1434C7.7802 21.1734 7.8202 21.1934 7.8702 21.1934H11.7302H13.6202C13.6602 21.5233 13.8002 21.8332 14.0402 22.0731C14.3202 22.353 14.7002 22.513 15.0902 22.503H16.8902C17.1202 22.503 17.3502 22.443 17.5402 22.353C17.9902 22.1331 18.3002 21.6932 18.3602 21.1834H20.2402H24.1002C24.1002 21.1834 24.1502 21.1734 24.1702 21.1634C24.2302 21.1334 24.2602 21.0834 24.2602 21.0134V16.7947C24.2602 16.7348 24.2302 16.6848 24.1802 16.6548C22.4502 15.5651 20.3902 13.7257 20.3902 11.2065V7.53765C20.3902 6.81788 20.1902 6.11809 19.8302 5.49828C16.5702 0 16.1202 0 15.9502 0L15.9802 0.0100066ZM8.0402 16.9047C9.3902 16.035 10.9102 14.7254 11.5702 13.0059V20.8635H8.0502V16.9047H8.0402ZM16.9002 22.1731H15.1002C14.7902 22.1731 14.5002 22.0531 14.2802 21.8332C14.1002 21.6532 13.9902 21.4333 13.9502 21.1834H18.0402C17.9602 21.7332 17.4802 22.1631 16.9002 22.1631V22.1731ZM23.9502 16.8947V20.8535H20.4202V12.9959C21.0702 14.7254 22.5902 16.025 23.9502 16.8847V16.8947ZM20.0802 7.54764V11.2165V20.8535H18.2202H13.7802H11.9102V11.2165V7.54764C11.9102 6.88785 12.0802 6.24805 12.4202 5.67823C14.6702 1.85943 15.7702 0.409868 15.9902 0.33989C16.2402 0.409868 17.3302 1.84942 19.5902 5.66822C19.9202 6.22805 20.1002 6.87785 20.1002 7.52764L20.0802 7.54764Z"
      fill="currentColor"
    />
    <path
      d="M15.9999 24.1741C15.0999 24.1741 14.4099 24.474 13.9999 25.0438C13.5499 25.6736 13.4899 26.5933 13.8399 27.623C14.4899 29.5524 15.8499 31.8916 15.8599 31.9116C15.8899 31.9616 15.9499 31.9916 15.9999 31.9916C16.0299 31.9916 16.0499 31.9916 16.0699 31.9716C16.0999 31.9616 16.1199 31.9316 16.1399 31.9016C16.1499 31.8817 17.5099 29.5424 18.1599 27.613C18.4999 26.5733 18.4499 25.6536 17.9899 25.0338C17.5799 24.464 16.8899 24.1641 15.9899 24.1641L15.9999 24.1741ZM17.8499 27.523C17.3399 29.0525 16.3699 30.842 16.0099 31.5018C15.6399 30.852 14.6699 29.0625 14.1599 27.533C13.8499 26.6033 13.8899 25.7936 14.2799 25.2537C14.4299 25.0438 14.6399 24.8739 14.8799 24.7539C15.1899 24.6039 15.5699 24.524 16.0099 24.524C16.7999 24.524 17.3899 24.7739 17.7399 25.2537C18.1299 25.7936 18.1699 26.6033 17.8599 27.533L17.8499 27.523Z"
      fill="currentColor"
    />
    <path
      d="M15.9898 10.4298C15.4598 10.4298 14.9598 10.6397 14.5898 11.0096C14.2198 11.3895 14.0098 11.8793 14.0098 12.4191C14.0098 13.5188 14.8998 14.4085 15.9998 14.3985C16.3098 14.3985 16.6098 14.3285 16.8698 14.1986C17.0598 14.0986 17.2398 13.9787 17.3998 13.8187C17.7698 13.4388 17.9798 12.949 17.9798 12.4091C17.9798 11.3095 17.0898 10.4198 15.9898 10.4298ZM17.6398 12.4091C17.6398 12.849 17.4698 13.2689 17.1598 13.5788C16.8498 13.8887 16.4298 14.0586 15.9898 14.0586C15.0798 14.0586 14.3398 13.3189 14.3398 12.4091C14.3398 11.9693 14.5098 11.5494 14.8198 11.2395C14.9498 11.1096 15.0998 10.9996 15.2598 10.9196C15.4798 10.8096 15.7298 10.7497 15.9898 10.7497C16.4298 10.7497 16.8498 10.9196 17.1598 11.2295C17.4698 11.5394 17.6398 11.9593 17.6498 12.3992L17.6398 12.4091Z"
      fill="currentColor"
    />
  </svg>
);

export default RocketThin;
