import { Formik } from "formik";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ProfileForm, SaveButton, SubPageContainer } from "../../style";

import CheckboxField from "Components/CheckboxField";
import { updateProfileInfoAPI } from "services/UserServices";
import { setIsProfileUpdated } from "store/slices/global/globalSlice";
import { getUser } from "store/slices/global/userSlice";
import { BlockCard } from "styles/mixins";
import { INotifications } from "types/user";

const Notification = memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userDetails = useSelector(getUser);

  const isDisabled = useCallback((dirty: boolean) => {
    dispatch(setIsProfileUpdated(!dirty));
    return dirty;
  }, []);

  const handleSubmit = (values: INotifications) => {
    updateProfileInfoAPI({
      data: {
        message: values.message,
        video: values.video,
        newsletter: values.newsletter,
        gift: values.gift,
      },
      part: "notification",
    });
  };

  return (
    <SubPageContainer>
      <p className="title">{t("creator.profile.notifications")}</p>
      <Formik
        enableReinitialize
        initialValues={{
          message: !!userDetails?.notification?.message,
          video: !!userDetails?.notification?.video,
          gift: !!userDetails?.notification?.gift,
          newsletter: !!userDetails?.notification?.newsletter,
        }}
        onSubmit={handleSubmit}
      >
        {({ values, dirty, handleChange, handleSubmit }) => (
          <ProfileForm onSubmit={handleSubmit}>
            <BlockCard className="card">
              <div className="boxes">
                <CheckboxField
                  name="message"
                  handleChange={handleChange}
                  value={values.message}
                  errMsg={false}
                  label={t("common.user_profile.notification_setting1")}
                />
                <CheckboxField
                  handleChange={handleChange}
                  value={values.newsletter}
                  name="newsletter"
                  errMsg={false}
                  label={t("common.user_profile.notification_setting2")}
                />
                <CheckboxField
                  name="video"
                  value={values.video}
                  handleChange={handleChange}
                  errMsg={false}
                  label={t("common.user_profile.notification_setting3")}
                />
                <CheckboxField
                  value={values.gift}
                  name="gift"
                  handleChange={handleChange}
                  errMsg={false}
                  label={t("common.user_profile.notification_setting4")}
                />
              </div>
              <div className="saveContainer">
                <SaveButton disabled={isDisabled(!dirty)} variant="primary" type="submit">
                  {t("creator.profile.updateNotifications")}
                </SaveButton>
              </div>
            </BlockCard>
          </ProfileForm>
        )}
      </Formik>
    </SubPageContainer>
  );
});

export default Notification;
