const Exit = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M14.7898 7.35047V6.42047C14.7898 5.44355 14.4024 4.5065 13.7126 3.81477C13.0227 3.12305 12.0867 2.73312 11.1098 2.73047H6.2298C5.25288 2.73312 4.31689 3.12305 3.62704 3.81477C2.9372 4.5065 2.5498 5.44355 2.5498 6.42047V17.5405C2.55244 18.5157 2.94101 19.4501 3.63057 20.1397C4.32013 20.8293 5.25461 21.2178 6.2298 21.2205H11.1198C11.6026 21.2218 12.0809 21.1278 12.5273 20.944C12.9737 20.7601 13.3795 20.49 13.7213 20.1491C14.0632 19.8082 14.3344 19.4031 14.5195 18.9572C14.7045 18.5113 14.7998 18.0333 14.7998 17.5505V16.6105"
      stroke="#FF6131"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.5801 12H9.58008"
      stroke="#FF6131"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.6504 9.0625L21.5804 12.0025L18.6504 14.9225"
      stroke="#FF6131"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Exit;
