import styled from "styled-components";

import Modal from "Components/Modal/Modal";

export const MfaModalContainer = styled(Modal)`
  .modal-content {
    max-width: 460px;
    padding: 20px;
    border-radius: 20px;
    background: ${(p) => p.theme.colors.background.secondary};

    .modal-body {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 0;

      .close-button {
        top: 0;
        right: 0;
        background-color: unset;
        svg {
          transform: scale(1.5);
        }
      }

      .title {
        font-family: ${(p) => p.theme.ff.outfit.regular};
        text-align: start;
        font-size: ${(p) => p.theme.typography.text3};

        padding: 0 20px 20px;
        margin: 0 -20px;
        border-bottom: 1px solid ${(p) => p.theme.colors.divider};
      }

      .description {
        text-align: start;
        margin-top: 4px;
        font-size: ${(p) => p.theme.typography.subText5};
        color: ${(p) => p.theme.colors.text.secondary};
      }
    }
  }
`;

export const Content = styled.div`
  .auth_code_input {
    margin-top: 16px;
  }

  .buttons_container {
    display: flex;
    gap: 16px;
    padding: 20px 20px 0;
    margin: 20px -20px 0;
    border-top: 1px solid ${(p) => p.theme.colors.divider};
  }
`;
