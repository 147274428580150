export const Picture = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20 1.25H4C3.04 1.25 2.25 2.04 2.25 3V11C2.25 11.96 3.04 12.75 4 12.75H20C20.96 12.75 21.75 11.96 21.75 11V3C21.75 2.04 20.96 1.25 20 1.25ZM4.89 11.25L10.25 6.98L15.15 11.25H4.89ZM20.25 11C20.25 11.14 20.14 11.25 20 11.25H17.43L10.76 5.43C10.49 5.19 10.08 5.18 9.8 5.41L3.74 10.24V3C3.74 2.86 3.85 2.75 3.99 2.75H19.99C20.13 2.75 20.24 2.86 20.24 3V11H20.25Z" />
    <path d="M8.71012 15.3399C8.01012 15.1399 7.13012 15.2799 6.49012 15.7899C5.84012 15.2799 4.97012 15.1499 4.30012 15.3399C3.53012 15.5599 2.92012 16.0599 2.57012 16.7399C2.21012 17.4499 2.16012 18.3099 2.45012 19.1099C2.71012 19.7999 3.14012 20.4299 3.71012 20.9399C4.42012 21.5699 5.21012 22.1299 6.06012 22.6099L6.15012 22.6599C6.24012 22.7099 6.35012 22.7299 6.47012 22.7299C6.64012 22.7299 6.83012 22.6799 6.96012 22.6099C7.80012 22.1399 8.60012 21.5799 9.32012 20.9399C9.88012 20.4299 10.3101 19.7999 10.5801 19.0999C10.8601 18.3099 10.8101 17.4499 10.4501 16.7499C10.1001 16.0699 9.49012 15.5699 8.72012 15.3499L8.71012 15.3399ZM9.16012 18.5799C8.98012 19.0399 8.69012 19.4799 8.31012 19.8199C7.75012 20.3099 7.15012 20.7499 6.50012 21.1399C5.85012 20.7599 5.25012 20.3099 4.70012 19.8299C4.32012 19.4799 4.02012 19.0499 3.85012 18.5999C3.71012 18.1999 3.73012 17.7699 3.90012 17.4299C4.06012 17.1199 4.34012 16.8999 4.71012 16.7899C4.78012 16.7699 4.85011 16.7599 4.92011 16.7599C5.25011 16.7599 5.65012 16.9499 5.82012 17.2999C5.95012 17.5599 6.20012 17.7199 6.52012 17.7199C6.81012 17.7199 7.08012 17.5299 7.19012 17.2599C7.37012 16.8299 7.96012 16.6899 8.29012 16.7899C8.66012 16.8999 8.95012 17.1199 9.11012 17.4399C9.28012 17.7799 9.30012 18.2099 9.17011 18.5899L9.16012 18.5799Z" />
    <path d="M20 15.25H14C13.59 15.25 13.25 15.59 13.25 16C13.25 16.41 13.59 16.75 14 16.75H20C20.41 16.75 20.75 16.41 20.75 16C20.75 15.59 20.41 15.25 20 15.25Z" />
    <path d="M17 19.25H14C13.59 19.25 13.25 19.59 13.25 20C13.25 20.41 13.59 20.75 14 20.75H17C17.41 20.75 17.75 20.41 17.75 20C17.75 19.59 17.41 19.25 17 19.25Z" />
    <path d="M17 7C17.5523 7 18 6.55228 18 6C18 5.44772 17.5523 5 17 5C16.4477 5 16 5.44772 16 6C16 6.55228 16.4477 7 17 7Z" />
  </svg>
);
