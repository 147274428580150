// import { DefaultTheme } from "styled-components";

// const light = "#B7B7B7";
// const red = "#F86D6D";
// const mainBronze = "#EDAC75";
// export const HeaderHeight = "95px";

// const fw = {
//   superLight: 200,
//   regular: 400,
//   medium: 500,
//   semiBold: 600,
//   bold: 700,
//   light: 300,
// };

// const ff = {
//   outfit: {
//     bold: "Outfit-Bold",
//     semiBold: "Outfit-Semibold",
//     light: "Outfit-Light",
//     medium: "Outfit-Medium",
//     extraBold: "Outfit-ExtraBold",
//     black: "Outfit-Black",
//     regular: "Outfit-Regular",
//     thin: "Outfit-Thin",
//   },
// };

export const colors = {
  graph: {
    green: "#25c374",
    darkGreen: "#468d73",
    blue: "#4879ae",
    lightBlue: "#5cb1eb",
    yellow: "#ffd616",
    lightGrey: "#B4EA97",
    darkGrey: "#68687F",
    silver: "#FFB474",
  },
  divider: "#232342",
  dividerLight: "#2B2B34",
  dividerGray: "#E8E8E8",
  dividerSecondary: "#353555",
  divider3: "#2B2B34",
  blue: {
    alfaDark: "#07071133",
    dark: "#070711",
    primary: "#151529",
    primaryRGBA: "rgba(28, 28, 45, 1)",
    secondary: "#2e2e44",
    third: "#27273b",
    border: "#3e3e53",
    light: "#45455C",
    disabled: "#505075",
    fullScreenLoaderBG: "rgba(28,28,45,0.6)",
    msg: "#0081ff",
    card: "#222238",
    cardInput: "#2A2A42",
    newLight: "#6A88F4",
    fourth: "#27283B",
  },
  buttons: {
    secondary: "#2D2D4A",
  },
  gray: {
    primary: "#68687F",
    secondary: "#8F8FA8",
    light: "#eeeef9",
    fifth: "#a3a3b5",
    bluesh: "#DBDBEB",
    medium: "#68687e",
    dark: "#47475c",
    border: "#cbcbe0",
    third: "#e0e0ee",
    fourth: "#74748d",
    light2: "#8291a9",
    empty: "#404054",
    full: "#77748D",
    sixth: "#444454",
  },
  twitter: "#026dff",
  youtube: "#ff0000",
  tiktok: "#c90770",
  red: {
    primary: "#ed0a0a",
    mistake: "#FF6131",
    alfaRed: "#FF613133",
    alfaRedSecondary: "#FF613199",
  },
  yellow: {
    primary: "#ffd616",
    secondary: "#F79E00",
    light: "#FFE68E",
    alfaYellow: "#F79E0033",
  },
  purple: {
    primary: "#DB8EFF",
  },
  green: {
    primary: "#25c374",
    secondary: "#25c374",
    alfaGreen: "#25C37433",
    alfaGreenSecondary: "#25C37499",
    newGreen: "#34ff72c2",
    light: "#ffffff",
    gradient: "linear-gradient(45deg ,#6BC676, #68C9B8)",
    third: "#68C9B8",
    inactive: "#C9EADD",
    inactiveText: "#A0E0C2",
  },
  white: {
    primary: "#ffffff",
    background: "#F3F4F8",
    alfaPrimary: "#FFFFFF33",
    maxAlfaPrimary: "#FFFFFF0D",
    secondary: "#F6F7FA",
  },
  black: "#000000",
  light: {
    primary: "#eeeef9",
    secondary: "#EEEFF9",
    border: "#cdcde0",
  },
  dark: {
    primary: "#101018",
    secondary: "#0A0A15",
    alfa: "#07071199",
    alfaBlack: "#0000000a",
    main: "#16161F",
    third: "#20202B",
  },
};

// const darkTheme: DefaultTheme = {
//   fw,
//   ff,
//   colors,
//   palette: {
//     common: {
//       border: {
//         dark: "#282828",
//         light: "#363636",
//         gray: "#B7B7B7",
//         avatar: "#151516",
//       },
//       light,
//       red,
//       yellow: "#d5b514",
//       bgConnectBtn: "rosybrown",
//       gray: "rgba(32, 33, 33, 0.2)",
//       lightBlue: "#4BC6FB",
//       lightGray: "#EEF0F2",
//       gray2: "#B7B7B7",
//       error: "#f44336",
//       warning: "#ff9800",
//       success: "#4caf50",
//       info: "#2196f3",
//       errorLight: "#e57373",
//       warningLight: "#ffb74d",
//       successLight: "#81c784",
//       infoLight: "#64b5f6",
//       // yellow: "#f2a92c",
//       shadows0: "0px 6px 10px rgba(255, 255, 255, 0.05)",
//       shadows1: "0px 5px 10px rgba(255, 255, 255, 0.1)",
//       white: "#fff",
//       carosuelIndicatorGray: "#707070",
//       backgroundColor: "#101010",
//       gradientBackground: `linear-gradient(270deg, #1a1a1c 0%, #121213 100%)`,
//       animationBg: "#080b35",
//     },
//     primary: {
//       main: "#feefa4",
//       mainrgba: "rgba(254,239,164, 0.5)",
//       light: "rgba(237, 172, 117, 0.2)",
//     },
//     secondary: {
//       main: "#080b35",
//       light: "#2A2A2B",
//     },
//     tertiary: {
//       main: "#1a1a1c",
//       light: "#121213",
//     },
//     white: {
//       main: "#ffffff",
//       light: "#dddddd",
//     },
//     black: {
//       main: "#000000",
//       light: "#101018",
//       light2: "#131314",
//       light3: "#16161F",
//     },
//     text: {
//       primary: "#070711",
//       secondary: "#3E3E4F",
//       gold: "#feefa4",
//       tertiary: mainBronze,
//       light: "#ffffff",
//       green: "#25c374",
//       white: "#ffffff",
//       themegray: "#eeeef9",
//       gray: "#b5b6c7",
//       pink: "#fff0cc",
//       dark: "#151529",
//       error: red,
//       black: "#101018",
//       darkgray: "#74748d",
//       graylight: "#cdcde0",
//       blue: "#080b35",
//     },
//     selectMenu: { bg: "#fff", hover: "#EEF0F2" },
//   },
//   typography: {
//     title1: "24px",
//     title2: "28px",
//     title3: "32px",
//     title4: "36px",
//     title5: "42px",
//     title6: "52px",
//     subTitle1: "20px",
//     subTitle2: "22px",
//     subTitle3: "24px",
//     subTitle4: "26px",
//     subTitle5: "48px",
//     text: "14px",
//     text1: "16px",
//     text2: "18px",
//     text3: "19px",
//     subText: "10px",
//     subText1: "11px",
//     subText2: "12px",
//     subText3: "13px",
//     subText4: "14px",
//     subText5: "15px",
//     smallText1: "5px",
//     smallText2: "6px",
//     smallText3: "7px",
//   },
//   navbar: {
//     height: "8rem",
//   },
// };

// export default darkTheme;
