import { debounce } from "lodash";
import { useRef, useState, useLayoutEffect, FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  BackButton,
  ContinueButton,
  SkipButton,
  SlideContainer,
  SliderActions,
  SliderContainer,
} from "./style";

import { getLocalStorageItem } from "common/helpers/localStorage";
import { STORAGES } from "constants/appConstants";
import { getDefaultLandingPages } from "store/slices/admin/adminSlice";
import { getUser } from "store/slices/global/userSlice";
import { IPageTemplate } from "types/admin/onboarding";

interface ISlides extends Omit<IPageTemplate, "image"> {
  image: string;
}

interface Props {
  slides: ISlides[];
}

const OnboardingSlider: FC<Props> = ({ slides }) => {
  const { t } = useTranslation();
  const lRole = getLocalStorageItem({ key: STORAGES.accountType });
  const defaultLandingPages = useSelector(getDefaultLandingPages);
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const parentContainer = useRef<HTMLDivElement>(null);
  const parentCards = useRef<HTMLDivElement>(null);
  const sliderCards = useRef<HTMLDivElement[]>([]);
  const [selected, setSelected] = useState(0);

  const handleClick = (toItem: number) => {
    setSelected(Math.max(0, Math.min(slides.length - 1, toItem)));
  };

  const redirectRoute = () => {
    if (!user) return;
    let route;
    const role = user.role.toLowerCase() || lRole;
    defaultLandingPages?.forEach((item: any) => {
      if (item.role.toLowerCase() === role) route = item.defaultURl;
    });
    navigate(`/${route}`, { replace: true });
  };

  useLayoutEffect(() => {
    if (parentCards.current) {
      parentCards.current.style.left = `-${sliderCards.current[selected].offsetLeft}px`;
    }
  }, [selected]);

  useEffect(() => {
    const ChangeSize = debounce(() => {
      sliderCards.current.forEach((item) => {
        if (parentContainer.current) {
          item.style.width = `${parentContainer.current.offsetWidth}px`;
        }
      });
    }, 500);
    ChangeSize();

    window.addEventListener("resize", ChangeSize, true);

    return () => {
      window.removeEventListener("resize", ChangeSize, true);
    };
  }, []);

  return (
    <>
      <SliderContainer ref={parentContainer}>
        <div ref={parentCards} className="slides-container">
          {slides.map((item, index) => (
            <SlideContainer
              ref={(element) => {
                if (element && sliderCards.current) {
                  sliderCards.current[index] = element;
                }
              }}
              key={index}
            >
              <div className="left">
                <p className="title">{item.title}</p>
                <p className="text">{item.description}</p>
              </div>
              <div className="right">
                <img src={item.imageUrl} alt={`onboarging${index + 1}`} loading="lazy" />
              </div>
            </SlideContainer>
          ))}
        </div>
      </SliderContainer>
      <SliderActions>
        <div className="buttons">
          <BackButton
            variant="secondary"
            disabled={selected === 0}
            onClick={() => {
              handleClick(selected - 1);
            }}
          >
            {t("common.back_1")}
          </BackButton>
          <ContinueButton
            variant="primary"
            onClick={() => {
              selected === slides.length - 1 ? redirectRoute() : handleClick(selected + 1);
            }}
          >
            {selected === slides.length - 1 ? t("auth.get_started") : t("common.continue")}
          </ContinueButton>
          <SkipButton variant="primary" onClick={redirectRoute}>
            {t("common.skip")}
          </SkipButton>
        </div>
        <div className="dots">
          {slides.map((item, index) => (
            <div key={index} className={`dot ${index === selected ? " active" : ""}`} />
          ))}
        </div>
      </SliderActions>
    </>
  );
};

export default OnboardingSlider;
