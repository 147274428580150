import { useTranslation } from "react-i18next";

import { NoChatsContainer } from "./style";

import Info from "assets/svg/Info";

const NoChats = () => {
  const { t } = useTranslation();

  return (
    <NoChatsContainer>
      <Info />
      <p>{t("new_chat.noChats")}</p>
    </NoChatsContainer>
  );
};

export default NoChats;
