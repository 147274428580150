import styled, { css } from "styled-components";

export const OverlayContainer = styled.div<{ show: boolean }>`
  pointer-events: none;
  top: 0px;
  position: fixed;
  z-index: 15;
  width: calc(100vw - 3px);
  height: 100vh;
  background: transparent;
  transition: all 0.3s;

  ${(p) =>
    p.show &&
    css`
      pointer-events: all;
      background: ${p.theme.colors.overlay};
    `}
`;
