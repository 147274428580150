import { FC } from "react";

interface Props {
  size?: number;
}

const Profile: FC<Props> = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size || "17"}
    height={size || "20"}
    viewBox="0 0 17 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.75429 0C5.82027 0 3.44177 2.38864 3.44177 5.33517C3.44177 8.28171 5.82027 10.6703 8.75429 10.6703C11.6883 10.6703 14.0668 8.28171 14.0668 5.33517C14.0668 2.38864 11.6883 0 8.75429 0ZM8.75429 1.44767C10.8922 1.44767 12.6253 3.18816 12.6253 5.33517C12.6253 7.48218 10.8922 9.22268 8.75429 9.22268C6.6164 9.22268 4.8833 7.48218 4.8833 5.33517C4.8833 3.18816 6.6164 1.44767 8.75429 1.44767ZM6.58005 12.8209C5.80233 12.8749 5.01621 12.9859 4.2408 13.1521C2.74418 13.4604 1.54685 14.0763 1.03724 15.0999C0.845027 15.5002 0.74839 15.9288 0.75002 16.3627C0.749444 16.7935 0.845299 17.2227 1.03062 17.6153C1.51994 18.6271 2.5778 19.1997 4.00624 19.5171L4.26213 19.5705C5.01648 19.7407 5.80284 19.8553 6.59446 19.909C6.66189 19.9288 6.82258 19.9472 6.99796 19.9561L7.14222 19.9615C7.21639 19.9633 7.30058 19.9637 7.42601 19.9637C8.56385 20.0263 9.74342 20.0081 10.9175 19.9081C11.5432 19.8653 12.1731 19.7835 12.7977 19.6636L13.2651 19.5666C14.8076 19.2623 15.9626 18.6836 16.4686 17.6164C16.8437 16.8241 16.8437 15.9047 16.4688 15.1127C15.964 14.0483 14.8237 13.4744 13.2534 13.1509C12.6373 13.0194 12.0112 12.922 11.3807 12.8599L10.9197 12.8209C9.47593 12.6935 8.02382 12.6935 6.58005 12.8209ZM10.7936 14.263L10.8062 14.264C11.5299 14.3149 12.2491 14.4165 12.9587 14.568C14.1251 14.8083 14.9167 15.2067 15.1671 15.7348C15.3557 16.133 15.3557 16.5958 15.1669 16.9944C14.9329 17.4879 14.222 17.8691 13.1945 18.1021L12.9695 18.1498C12.246 18.3112 11.5291 18.4152 10.8076 18.4647C9.68793 18.5599 8.57495 18.5771 7.46477 18.5171L7.07116 18.5103C6.9619 18.5048 6.86981 18.4942 6.78447 18.4771C6.10872 18.4261 5.50066 18.3454 4.91027 18.2283L4.55775 18.1537C3.38771 17.9244 2.58915 17.5243 2.33024 16.989C2.23964 16.797 2.19125 16.5803 2.19154 16.3609C2.19073 16.1428 2.23848 15.931 2.33118 15.7379C2.58293 15.2324 3.42919 14.7971 4.53606 14.569C5.25073 14.4159 5.96962 14.3144 6.69294 14.264C8.06603 14.143 9.43372 14.143 10.7936 14.263Z"
      fill="currentColor"
    />
  </svg>
);

export default Profile;
