import styled from "styled-components";

import Modal from "Components/Modal/Modal";

export const RemoveModal = styled(Modal)`
  .modal-content {
    background: ${(p) => p.theme.colors.background.secondary};

    .modal-body {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .title {
        width: 100%;
        text-align: center;
        color: ${(p) => p.theme.colors.text.primary};
        font-size: ${(p) => p.theme.typography.text3};
        padding-bottom: 16px;
        border-bottom: 1px solid ${(p) => p.theme.colors.divider};
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: start;

  .description {
    text-align: center;
    font-size: ${(p) => p.theme.typography.subText5};
  }

  .actions {
    display: flex;
    gap: 16px;
  }
`;
