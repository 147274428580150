import { memo, useCallback } from "react";

import { getFileSizeInMb, getIconByContentType } from "common/helpers/file-helpers";
import { getMinutesDurationFromSeconds } from "common/helpers/helpers";
import { ContentInfoItem } from "pages/public/chat/Components/Chat/ContentInfoitem";
import { SingleContentMessageStyles } from "pages/public/chat/Components/Chat/SingleContentMessage/styles";
import {
  MessageMediaFile,
  NewMessageImageMediaContent,
  NewMessageItem,
} from "types/new-messages.types";

interface Props {
  message: NewMessageItem;
  onContentClick: (message: MessageMediaFile) => void;
}
export const SingleContentMessage = memo(({ message, onContentClick }: Props) => {
  const content: MessageMediaFile = message.mediaFiles[0]!;

  const isContentImage = useCallback(
    (content: MessageMediaFile): content is NewMessageImageMediaContent => {
      return content.mediaType === "image";
    },
    []
  );

  return (
    <SingleContentMessageStyles onClick={() => onContentClick(content)} width="200px">
      <div
        className="thumbnail"
        style={{ backgroundImage: isContentImage(content) ? `url(${content.url})` : "unset" }}
      >
        {!isContentImage(content) && getIconByContentType(content.mediaType, "single_content_icon")}
      </div>
      <div className="content_info">
        <ContentInfoItem
          className="single_message_left_info"
          icon={getIconByContentType(content.mediaType, "content_info_icon")}
          contentInfo={
            !isContentImage(content)
              ? `${getMinutesDurationFromSeconds(Math.floor(content.dimension.duration!))}`
              : ""
          }
        />
        <div>{`${getFileSizeInMb(content.dimension.file_size).toFixed(2)} MB`}</div>
      </div>
    </SingleContentMessageStyles>
  );
});
