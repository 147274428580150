import { ChangeEvent, FC, MouseEvent, useEffect, useMemo, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  BigButton,
  Button,
  Controls,
  ErrorMessage,
  ExpandedContainer,
  Poster,
  ProgressBar,
  ProgressBarContainer,
  Time,
  VideoElement,
  VideoPlayerContainer,
} from "./style";

import FullscreenAlt from "assets/svg/FullscreenAlt";
import FullscreenExit from "assets/svg/FullscreenExit";
import Pause from "assets/svg/Pause";
import PlayIcon from "assets/svg/Play";
import Volume from "assets/svg/Volume";
import VolumeOff from "assets/svg/VolumeOff";
import { getBrowserInfo } from "common/helpers/helper";
import Play from "Components/Play";
import { getVolume, setVolume } from "store/slices/global/globalSlice";
import { BROWSER } from "types/common.types";

interface Props {
  src: string;
  poster?: string;
  contentType?: string;
}

// Custom video player component
const Video: FC<Props> = ({ src, poster, contentType }) => {
  const dispatch = useDispatch();
  const volume = useSelector(getVolume);
  const browser = getBrowserInfo();

  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);
  const volumeRef = useRef<HTMLDivElement>(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [move, setMove] = useState(false);
  const [isStart, setIsStart] = useState(false);
  const [showVolume, setShowVolume] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration);
    }
  };

  const handleVolumeChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (videoRef.current) {
      videoRef.current.volume = Number(event.target.value);
      dispatch(setVolume(event.target.value));
    }
  };

  const separeteTime = (time: number) => {
    // eslint-disable-next-line no-bitwise
    const hours = (time / 3600) | 0;
    // eslint-disable-next-line no-bitwise
    const minutes = ((time % 3600) / 60) | 0;
    const seconds = time % 60;
    return [Math.floor(hours), Math.floor(minutes), Math.floor(seconds)];
  };

  const time = useMemo(() => {
    const [hours, minutes, seconds] = separeteTime(duration - currentTime);
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds < 0 ? 0 : seconds}` : `${seconds}`;
    return hours > 0
      ? `${hours}:${formattedMinutes}:${formattedSeconds}`
      : `${minutes}:${formattedSeconds}`;
  }, [duration, currentTime]);

  const handleFullscreenToggle = () => {
    if (containerRef.current) {
      if (!isFullscreen) {
        if (containerRef.current.requestFullscreen) {
          containerRef.current.requestFullscreen();
        }
        setIsFullscreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
        setIsFullscreen(false);
      }
    }
  };

  const handleProgressBarClick = (event: MouseEvent<HTMLDivElement>) => {
    if (progressBarRef.current && videoRef.current) {
      const rect = progressBarRef.current.getBoundingClientRect();
      const clickX = event.clientX - rect.left;
      const newTime = (clickX / rect.width) * duration;
      videoRef.current.currentTime = newTime;
    }
  };

  const handleError = () => {
    setError("An error occurred while playing the video.");
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.disablePictureInPicture = true;
    }
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = Number(volume);
    }
  }, [volume]);

  return (
    <VideoPlayerContainer ref={containerRef}>
      <VideoElement
        ref={videoRef}
        src={src}
        controls={browser === BROWSER.SAFARI_IOS}
        poster={poster}
        preload="none"
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
        onError={handleError}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {browser !== BROWSER.SAFARI_IOS && (
        <BigButton
          onClick={(event) => {
            event.stopPropagation();
            handlePlayPause();
            setIsStart(true);
          }}
          onMouseMove={() => setMove(true)}
          onMouseLeave={() => setMove(false)}
          onDoubleClick={handleFullscreenToggle}
          show={!isPlaying}
        >
          {!isStart && <Play />}
        </BigButton>
      )}
      {isStart && contentType === "Audio" && poster && (
        <Poster>
          <img src={poster} alt="poster" />
        </Poster>
      )}
      {isStart && !error && browser !== BROWSER.SAFARI_IOS && (
        <Controls
          className={`${move ? "move" : ""}`}
          show={!isPlaying}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Button
            className="play"
            onClick={(event) => {
              event.stopPropagation();
              handlePlayPause();
            }}
          >
            {isPlaying ? <Pause /> : <PlayIcon />}
          </Button>
          <ExpandedContainer
            onMouseEnter={(event) => {
              event.stopPropagation();
              setShowVolume(true);
            }}
          >
            <Button
              onClick={(event) => {
                event.stopPropagation();
                if (volume === "0" && videoRef.current) {
                  dispatch(setVolume("1"));
                  videoRef.current.volume = Number(1);
                } else if (videoRef.current) {
                  dispatch(setVolume("0"));
                  videoRef.current.volume = Number(0);
                }
              }}
            >
              {volume === "0" ? <VolumeOff /> : <Volume />}
            </Button>
            <div
              ref={volumeRef}
              onMouseLeave={() => setShowVolume(false)}
              className={`setting ${showVolume ? "show" : ""}`}
            >
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume.toString()}
                onChange={handleVolumeChange}
              />
            </div>
          </ExpandedContainer>
          <ProgressBarContainer ref={progressBarRef} onClick={handleProgressBarClick}>
            <ProgressBar progress={(currentTime / duration) * 100} />
          </ProgressBarContainer>
          <Time>-{time}</Time>
          <Button onClick={handleFullscreenToggle}>
            {isFullscreen ? <FullscreenExit /> : <FullscreenAlt />}
          </Button>
        </Controls>
      )}
    </VideoPlayerContainer>
  );
};

export default Video;
