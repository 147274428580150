import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ChatStatusTabs from "./Components/ChatActions";

import { Chat } from "pages/public/chat/Components/Chat";
import { ChatList } from "pages/public/chat/Components/ChatList";
import { NotificationContainer } from "pages/public/notifications/style";
import {
  getOpenedChat,
  removeAllChats,
  resetChatCounters,
  resetChatSearch,
  setOpenedChat,
} from "store/slices/global/chatsSlice";
import { removeAllMessages } from "store/slices/newMessages/messagesSlice";
import { BlockCard, NewCommonWrapper } from "styles/mixins";

export const ChatContainer = memo(() => {
  const dispatch = useDispatch();
  const openedChat = useSelector(getOpenedChat);

  useEffect(() => {
    return () => {
      dispatch(setOpenedChat(null));
      dispatch(removeAllMessages());
      dispatch(removeAllChats());
      dispatch(resetChatSearch());
      dispatch(resetChatCounters());
    };
  }, []);

  return (
    <NewCommonWrapper>
      <NotificationContainer>
        <BlockCard className="left">
          <ChatStatusTabs />
          <ChatList />
        </BlockCard>
        <BlockCard className={`right ${openedChat ? "show" : ""}`}>
          <Chat role="fan" />
        </BlockCard>
      </NotificationContainer>
    </NewCommonWrapper>
  );
});
