const MessengerColor = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 1C5.8 1 1 5.54 1 11.67C1 14.88 2.31 17.65 4.45 19.56C4.63 19.72 4.74001 19.94 4.74001 20.19L4.8 22.15C4.81 22.64 5.22001 23.02 5.71001 23C5.82001 23 5.93001 22.97 6.04001 22.93L8.22 21.97C8.4 21.89 8.61 21.87 8.81 21.93C9.81 22.2 10.88 22.35 11.99 22.35C18.19 22.35 22.99 17.81 22.99 11.68C22.99 5.55 18.19 1.01 11.99 1.01L12 1Z"
      fill="url(#paint0_radial_17049_85814)"
    />
    <path
      d="M5.39001 14.79L8.62002 9.65998C9.11002 8.88998 10.12 8.65998 10.9 9.13998C10.94 9.15998 10.98 9.18998 11.01 9.21998L13.58 11.15C13.82 11.33 14.14 11.33 14.37 11.15L17.84 8.51998C18.3 8.16998 18.91 8.71998 18.6 9.21998L15.37 14.34C14.88 15.11 13.87 15.34 13.09 14.86C13.05 14.84 13.01 14.81 12.98 14.78L10.41 12.85C10.17 12.67 9.85002 12.67 9.62002 12.85L6.15002 15.48C5.69002 15.83 5.08001 15.28 5.39001 14.79Z"
      fill="white"
    />
    <defs>
      <radialGradient
        id="paint0_radial_17049_85814"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(3.58 10.2046) scale(24 23.9994)"
      >
        <stop stopColor="#0099FF" />
        <stop offset="0.6" stopColor="#A033FF" />
        <stop offset="0.9" stopColor="#FF5280" />
        <stop offset="1" stopColor="#FF7061" />
      </radialGradient>
    </defs>
  </svg>
);

export default MessengerColor;
