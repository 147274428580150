import styled from "styled-components";

export const FacebookContentPreviewContainer = styled.div`
  width: 100%;
  aspect-ratio: 346 / 605;
  .iframe {
    width: 100%;
    height: 100%;
  }
`;
