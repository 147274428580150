import { FC } from "react";
import { useTranslation } from "react-i18next";

import Info from "assets/svg/Info";
import Button from "Components/Button";
import {
  SensitiveModalContainer,
  Content,
} from "pages/public/PublicProfile/components/SensitiveModal/style";

interface Props {
  show: boolean;
  closeModal: () => void;
}

const VerifyModal: FC<Props> = ({ show, closeModal }) => {
  const { t } = useTranslation();

  return (
    <SensitiveModalContainer show={show} size="md" onHide={closeModal} showCloseButton>
      <Content>
        <div className="info">
          <div className="icon">
            <Info />
          </div>
          <div className="text">
            <p className="title">{t("common.attention")}</p>
            <p className="description">{t("common.application")}</p>
          </div>
        </div>
        <div className="actions">
          <Button variant="secondary" onClick={() => closeModal()}>
            {t("common.ok")}
          </Button>
        </div>
      </Content>
    </SensitiveModalContainer>
  );
};

export default VerifyModal;
