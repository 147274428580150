import styled from "styled-components";

import { OutfitRegular } from "styles/mixins";

export const TimerText = styled.p<{ disabled: boolean }>`
  ${OutfitRegular};
  color: ${(p) => (p.disabled ? p.theme.colors.text.secondary : p.theme.colors.text.third)};
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  font-size: 0.85rem;
`;
