import { FormikProvider, useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import * as Yup from "yup";

import {
  getYoutubeVideoIdFromLink,
  makeToast,
  validateAndExtractInstagramPostID,
  validateAndExtractReelPostID,
  validateAndExtractTikTokID,
  validateFaceBookPostUrl,
  validateFaceBookReelUrl,
  validateIfShortenedFaceBookUrl,
  validateIfShortenedTiktokUrl,
} from "common/helpers/helper";
import { getImageTagFromFaviconBase64 } from "common/helpers/helpers";
import { SocialIcons, STRINGS } from "constants/appConstants";
import { getLetterByIndex } from "constants/letters";
import titles from "constants/titles";
import { useFirstRender } from "customHooks/useFirstRender";
import useTitle from "customHooks/useTitle";
import { BioLinksContainer } from "pages/creator/Bio/BioLink/BioLinkContainer/style";
import { AddLinkForm } from "pages/creator/Bio/BioLink/components/AddLinkForm";
import { BioLinkListForm } from "pages/creator/Bio/BioLink/components/BioLinksListForm";
import { CheckBoxesForm } from "pages/creator/Bio/BioLink/components/CheckBoxesForm";
import { TutorialBlock } from "pages/creator/Bio/BioLink/components/TutorialBlock";
import { updateProfileInfoAPI } from "services/UserServices";
import { getUser } from "store/slices/global/userSlice";
import { IFormikPublikLinks, IPublicLink } from "types/user";

export const BioLinkContainer = () => {
  const { t } = useTranslation();
  const userDetails = useSelector(getUser);
  const isFirstRender = useFirstRender();
  useTitle(titles.bioLinks);

  const links = useMemo(() => {
    if (!userDetails) return [];
    return userDetails.publicLinks.map((item) => {
      return {
        ...item,
        contents:
          item.contents?.map((content, index) => ({
            ...content,
            letterId: getLetterByIndex(index),
          })) || [],
        icon: item.customIcon
          ? getImageTagFromFaviconBase64(item.customIcon)
          : SocialIcons.find((element) => element.value === item.platform)?.label,
      };
    });
  }, [userDetails]);

  // in order to fix this issue https://github.com/jaredpalmer/formik/issues/706
  // we cannot disable validation on change, so we have to store separate player error state
  const [previewErrors, setPreviewErrors] = useState<Record<string, boolean>>({});

  const schema = Yup.object().shape({
    publicLinks: Yup.array().of(
      Yup.object().shape({
        contents: Yup.array()
          .of(
            Yup.object().shape({
              url: Yup.mixed()
                .test("url", t("common.required"), (value) => {
                  return Boolean(value);
                })
                .test("url", t("common.invalid_url"), (value: string, ctx: Yup.TestContext) => {
                  console.log(ctx);

                  return validateContentLink(value, ctx);
                }),
            })
          )
          .max(6, "Max 6 links"),
      })
    ),
  });

  const publicLinksForm = useFormik<IFormikPublikLinks>({
    enableReinitialize: true,
    validationSchema: schema,
    initialValues: {
      publicLinks: links,
    },
    onSubmit: (values) => {
      handlePublicLinksUpdate(values);
    },
  });

  useEffect(() => {
    if (isFirstRender) return;

    // reset Form after submission
    publicLinksForm.resetForm({
      values: {
        publicLinks: links,
      },
    });
  }, [links]);

  const validateContentLink = (value: string, ctx: any): boolean => {
    if (!value) return false;

    const id = ctx.parent._id;
    const hasPreviewError = previewErrors[id];

    if (hasPreviewError) return false;

    const platform = ctx.from[1]?.value?.platform;
    if (!platform) return true; // if no platform found do no trigger url validation

    if (platform === "youtube") {
      const videoId = getYoutubeVideoIdFromLink(value);
      return Boolean(videoId);
    }

    if (platform === "tiktok") {
      // this is shortened url, and we will make another request in  tiktok component to get full size url
      if (validateIfShortenedTiktokUrl(value)) return true;

      const videoId = validateAndExtractTikTokID(value);
      return Boolean(videoId);
    }

    if (platform === "instagram") {
      const postVideoId = validateAndExtractInstagramPostID(value);
      const reelVideoId = validateAndExtractReelPostID(value);
      return Boolean(postVideoId || reelVideoId);
    }

    if (platform === "facebook") {
      const isShortenedFaceBookUrl = validateIfShortenedFaceBookUrl(value);
      const isPostValid = validateFaceBookPostUrl(value);
      const isReelValid = validateFaceBookReelUrl(value);
      return Boolean(isPostValid || isReelValid || isShortenedFaceBookUrl);
    }
    return true;
  };

  const handlePublicLinksUpdate = (values: IFormikPublikLinks) => {
    updateProfileInfoAPI(
      {
        data: {
          publicLinks: values.publicLinks.map((item) => {
            delete item.icon;
            delete item._id;
            return {
              ...item,
              contents: item.contents.map((content) => ({ url: content.url })),
            };
          }),
        },
        part: "publicLinks",
      },
      () => {
        // if addPublicLinkPopup already false do nothing
        if (userDetails && !userDetails.addPublicLinkPopup) return;
        updateProfileInfoAPI({
          data: {
            addPublicLinkPopup: false,
          },
          part: "general",
        });
        makeToast({
          message: t("common.addLinks"),
          type: STRINGS.toastType.success,
        });
      }
    );
  };

  const addNewPublicLink = (newPublicLink: IPublicLink) => {
    publicLinksForm.setFieldValue("publicLinks", [
      ...publicLinksForm.values.publicLinks,
      newPublicLink,
    ]);
    publicLinksForm.setFieldTouched("publicLinks", true);
  };

  return (
    <BioLinksContainer>
      <div className="content">
        <div className="left">
          <AddLinkForm onNewLinkAdded={addNewPublicLink} />
          <FormikProvider value={publicLinksForm}>
            <BioLinkListForm setPreviewErrors={setPreviewErrors} />
          </FormikProvider>
        </div>

        <div className="right">
          <div className="title">{t("common.settings")}</div>
          <CheckBoxesForm />
          <TutorialBlock />
        </div>
      </div>
    </BioLinksContainer>
  );
};
