import styled from "styled-components";

export const VerifySocialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: ${(p) => p.theme.colors.text.primary};

  .title {
    font-size: ${(p) => p.theme.typography.text3};
    display: flex;
    flex-direction: column;
    gap: 8px;

    .click {
      color: ${(p) => p.theme.colors.text.secondary};
      font-size: ${(p) => p.theme.typography.subText3};
    }
  }

  .socials {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
`;
