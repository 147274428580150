import { memo } from "react";

import { HeaderContainer } from "./style";

import { Logo } from "Components/Header/style";

const Header = memo(() => (
  <HeaderContainer>
    <Logo className="logo-text">
      <img className="cursor-pointer" src="/headerLogo.png" alt="LEWK" />
    </Logo>
  </HeaderContainer>
));

export default Header;
