import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store/store";
import { IMyTransactionFan } from "types/gifting";

interface IInitialState {
  data: IMyTransactionFan[];
  total: number;
}

const initialState: IInitialState = {
  data: [],
  total: 0,
};

const myTransactionsSlice = createSlice({
  name: "myTransactions",
  initialState,
  reducers: {
    setMyTransactions: (
      state,
      action: PayloadAction<{ result: IMyTransactionFan[]; totalTransactions: number }>
    ) => {
      state.data = action.payload.result;
      state.total = action.payload.totalTransactions;
    },
    resetMyTransactions: () => {
      return { ...initialState };
    },
  },
});

export const { setMyTransactions, resetMyTransactions } = myTransactionsSlice.actions;

export default myTransactionsSlice.reducer;

export const getMyTransactions = ({ fan }: RootState) => fan.myTransactions;
