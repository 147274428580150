export const ImageOutlined = ({ className }: { className?: string }) => (
  <svg
    width="32"
    height="32"
    className={className}
    viewBox="0 0 32 32"
    fill="none"
    stroke="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.2774 10.5333V21.4C28.2774 25.4266 25.7574 28.2666 21.7307 28.2666H10.1974C6.17073 28.2666 3.66406 25.4266 3.66406 21.4V10.5333C3.66406 6.50664 6.18406 3.66797 10.1974 3.66797H21.7307C25.7574 3.66797 28.2774 6.50664 28.2774 10.5333Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.03906 21.9102L9.07773 19.7595C9.8124 18.9835 11.0457 18.9795 11.7871 19.7489L12.9671 20.9529C13.7631 21.7649 15.0871 21.7062 15.8084 20.8275L18.7804 17.2129C19.6337 16.1742 21.1991 16.1102 22.1337 17.0742L24.9017 19.9302"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7463 12.1785C13.7463 13.4692 12.6983 14.5172 11.4076 14.5172C10.117 14.5172 9.07031 13.4692 9.07031 12.1785C9.07031 10.8878 10.117 9.83984 11.4076 9.83984C12.6983 9.83984 13.7463 10.8878 13.7463 12.1785Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
