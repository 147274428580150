import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const HeaderComp = styled.header<{ isLogin?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(p) => (p.isLogin ? "13px 0px" : "8px 0px")};
  max-height: 82px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 20;
  transition: all 0.3s;

  &.withBlur {
    background: ${(p) => p.theme.colors.blackWhite.primary80};
    box-shadow: 0px 1px 0px 0px ${(p) => p.theme.colors.divider};
    backdrop-filter: blur(5px);
  }

  .back {
    color: ${(p) => p.theme.colors.icon.secondary};
    cursor: pointer;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 24px;
    width: 100%;
    max-width: 1012px;
    gap: 8px;

    .authButton {
      display: flex;
      gap: 8px;

      button {
        border-radius: 30px;
        line-height: 22px;
        padding: 10px 20px;
      }
    }
  }

  ${deviceQuery.laptopM} {
    .content {
      padding: 0px 20px;
      max-width: 1004px;
    }
  }

  ${deviceQuery.tabletM} {
    .content {
      padding: 0px 16px;
      max-width: 996px;

      .authButton {
        button {
          padding: 10px 12px;
        }
      }
    }
    .back {
      color: ${(p) => p.theme.colors.text.primary};
      cursor: pointer;
    }

    &.withBlur {
      .back {
        color: ${(p) => p.theme.colors.text.primary};
        cursor: pointer;
      }
      img {
        &.light {
          display: none;
        }
        &.dark {
          display: block;
        }
      }
    }
  }

  ${deviceQuery.mobileM} {
    .content {
      gap: 6px;

      .authButton {
        button {
          padding: 10px 10px;
          white-space: nowrap;
        }
      }
    }
  }

  ${deviceQuery.mobileSE} {
    flex-direction: column;
    max-height: unset;
    align-items: start;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 16px;

  img {
    width: 133px;
    object-fit: contain;

    &.light {
      display: none;
    }
  }

  ${deviceQuery.tabletM} {
    img {
      &.light {
        display: block;
      }
      &.dark {
        display: none;
      }
    }
  }
`;
