import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const ContentMessageStyles = styled.div`
  font-size: ${(p) => p.theme.typography.subText5};
  background-color: ${(p) => p.theme.colors.background.fifth};
  border-radius: 12px;
  width: 200px;
  min-height: 200px;
  overflow: hidden;
  cursor: pointer;

  .thumbnail {
    background-color: transparent;
    border-bottom: 1px solid ${(p) => p.theme.colors.divider};
    height: 100px;
    position: relative;
    background-position: center;
    background-size: cover;

    .empty_icon,
    .lock_icon {
      position: absolute;
      color: ${(p) => p.theme.colors.white.primary};
      height: 32px;
      width: 32px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .empty_icon {
      color: ${(p) => p.theme.colors.icon.secondary};
    }
  }

  .content_info {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }

  .content_items {
    display: flex;
    gap: 16px;
    justify-content: space-between;
  }

  .action_button {
    width: 100%;
    font-size: ${(p) => p.theme.typography.subText5};
    padding: 9px 16px;
  }

  .content_info_icon {
    width: 24px;
    height: 24px;
    color: ${(p) => p.theme.colors.icon.secondary};
  }

  ${deviceQuery.tabletM} {
    background-color: ${(p) => p.theme.colors.background.secondary};
  }
`;
