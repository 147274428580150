import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import {
  Hero,
  Image,
  Main,
  DivSection,
  MainInner,
  SubHeading,
  Para,
  Heading,
  Wrapper,
} from "./style";

import SocialIcons from "Components/SocialIcons";

const NotFound = () => (
  <Row className="gx-0 min-vh-100">
    <Col xs={12} lg={6} className="d-none d-lg-block">
      <Hero className="h-100 py-5 d-flex flex-column justify-content-center align-items-center">
        <Image hero src="/img/hero.png" />
      </Hero>
    </Col>
    <Col xs={12} lg={6}>
      <Wrapper className="min-vh-100">
        <Main className="h-100">
          <MainInner className="mx-auto mx-lg-0">
            <div className="d-lg-none mb-4">
              <Image logo src="/img/logo.png" />
            </div>
            <SubHeading>????</SubHeading>
            <Heading>Whoops, we don’t have it!</Heading>
            <Para>
              <span>
                The content or page you’re lewking for doesn’t seem to exist or be accessible
                anymore.
              </span>
            </Para>
            <Para>
              <span>Please verify the correctness of the URL and try again.</span>
            </Para>
            <DivSection>
              <Link to="/" className="light">
                Homepage
              </Link>
              |
              <Link to="/HelpCenter" className="green">
                Help Center
              </Link>
            </DivSection>
            <div className="mt-5">
              <SocialIcons />
            </div>
          </MainInner>
        </Main>
      </Wrapper>
    </Col>
  </Row>
);

export default NotFound;
