export default {
  bioLinks: "Bio link - Links | Lewk",
  customization: "Bio link - Customization | Lewk",
  wallOfFame: "Bio link - Wall of Fame | Lewk",
  airdrop: "Airdrop | Lewk",
  profile: "Profile | Lewk",
  privacy: "Privacy Policy | Lewk",
  terms: "Terms of Service | Lewk",
  home: "Lewk - Monetize, Engage, Collaborate",
  registration: "Register | Lewk",
  signUpRequests: "Sign up requests | Lewk",
  login: "Login | Lewk",
  dashboard: "Dashboard | Lewk",
  editProfile: "Edit Profile | Lewk",
  community: "Community | Lewk",
  messages: "Messages | Lewk",
  analytics: "Analytics | Lewk",
  myTransactions: "My Payment History | Lewk",
  messagesReport: "Messages - Reports | Lewk",
  marketPlace: "Marketplace | Lewk",
  marketPlace_: "Marketplace",
  notification: "Notification | Lewk",
  wallet: "Wallet | Lewk",
  campaign_ideas: "Campaign Ideas",
  campaigns: "Campaigns | Lewk",
  onboarding: "Onboarding | Lewk",
  campaignIdea: "Campaign Ideas | Lewk",
  publisherProfile: "Publisher",
  campaignIdeasProposal: "Campaign Ideas - Proposal | Lewk",
  createProposal: "Create Proposal | Lewk",
  campaign: "Campaign",
  campaignEdit: "Campaign - Edit | Lewk",
  content: "Content | Lewk",
  authentication: "2FA Authenticator | Lewk",
  recovery2FA: "2FA Recovery | Lewk",
  videos: "Videos | Lewk",
  challenges: "Challenges | Lewk",
  subscribers: "Subscribers | Lewk",
  refundRequests: "Refund Requests | Lewk",
  earnings: "Earnings | Lewk",
  gifting: "Gifting | Lewk",
  rewards: "Rewards | Lewk",
  campaigns_rewards: "Campaigns Rewards | Lewk",
  createFreeChallenges: "Create Free For All Challenges | Lewk",
  createSelfChallenges: "Create Self Moderated Challenges | Lewk",
  creatorSubmissions: "My Challenges | Lewk",
  subscriptions: "Subscriptions | Lewk",
  allSubscriptions: "All Subscriptions | Lewk",
  subscriptionsEdit: "Subscriptions - Edit | Lewk",
  subscriptionsCreate: "Subscriptions - Create | Lewk",
  subscriptionsMyFeed: "Subscriptions - My Feed | Lewk",
  subscriptionsMyFeedCreate: "Subscriptions - Create Content | Lewk",
  subscriptionsSubscribers: "Subscriptions - Subscribers | Lewk",
  subscriptionsEarnings: "Subscriptions - Earnings | Lewk",
  subscriptionsRefundRequest: "Subscriptions - Refund Requests | Lewk",
  transactionExplore: "Analytics - Transaction Explore | Lewk",
  sweep: "Analytics - Sweep | Lewk",
  feeInformation: "Analytics - Fee Information | Lewk",
  balance: "Analytics - Balance | Lewk",
  myfts: "My Collectables | Lewk",
  myfts_: "My Collectables",
  allnfts: "All NFTs | Lewk",
  allnfts_: "All NFTs",
  creatorSearch: "Creator Search | Lewk",
  mySubscription: "My Subscriptions | Lewk",
  myPaymentHistory: "My Payment History | Lewk",
  forgotPassword: "Forgot Password | Lewk",
  monetization: "Monetization | Lewk",
  profile_: "Profile",
  allPeople: "Peoples | Lewk",
  configuration: "Configuration | Lewk",
  report: "Reports | Lewk",
  creators: "Creators | Lewk",
  creator: "Creators | Lewk",
  fan: "Fans | Lewk",
  brand: "Brands | Lewk",
  createCampaign: "Campaign - Create | Lewk",
  createContent: "Create Content | Lewk",
  campaignDetail: "Campaign Details | Lewk",
  feeds: "Feeds | Lewk",
  lewk: " Lewk",
  campaignVideos: "My Campaign Videos | Lewk",
  emailTemplates: "Email Templates | Lewk",
  campaignIdeas: "My Campaign Ideas | Lewk",
  onBoardingScreens: "Onboarding Screens | Lewk",
  challengeReward: "Challenges Reward | Lewk",
};
