import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";

import { Color, Icon, WallpaperContainer } from "./style";

import ProfilePreview from "../ProfilePreview";

import TrackGoogleAnalyticsEvent from "common/googleAnalytics";
import Button from "Components/Button";
import OverlayLoader from "Components/OverlayLoader";
import RollingTabs from "Components/RollingTabs";
import {
  GradientDarkColors,
  GradientLightColors,
  PatternDarkColors,
  PatternIcons,
  PatternLightColors,
} from "constants/appConstants";
import { updateProfileInfoAPI } from "services/UserServices";
import { getCreatorProfileLoader, setLoader } from "store/slices/featureSlice";
import { getPublicUser, resetPublicUser } from "store/slices/global/previewProfileSlice";
import { TabItem } from "types/tabs.types";

const Wallpaper = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const isLoading = useSelector(getCreatorProfileLoader);
  const creator = useSelector(getPublicUser);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedGradient, setSelectedGradient] = useState<
    | {
        color1: string;
        color2: string;
        theme: string;
      }
    | undefined
  >(undefined);
  const [showColors, setShowColors] = useState(false);
  const [selectedAction, setSelectedAction] = useState(0);
  const patternColors = useMemo(() => {
    switch (creator.bioTheme) {
      case "dark":
        return PatternDarkColors;
      case "light":
        return PatternLightColors;
      default:
        return PatternLightColors;
    }
  }, [creator.bioTheme]);
  const gradientColors = useMemo(() => {
    switch (creator.bioTheme) {
      case "dark":
        return GradientDarkColors;
      case "light":
        return GradientLightColors;
      default:
        return GradientLightColors;
    }
  }, [creator.bioTheme]);

  const handleSubmitPattern = () => {
    if (state) {
      TrackGoogleAnalyticsEvent("Bio Customization", "pattern_edit");
    } else {
      TrackGoogleAnalyticsEvent("Bio Customization", "pattern_set");
    }
    dispatch(setLoader({ name: "editProfileLoader", value: true }));

    updateProfileInfoAPI(
      {
        data: {
          wallpaper: {
            pattern: {
              color: selectedColor,
              icon: selectedIcon,
            },
          },
        },
        part: "customizations",
      },
      () => navigate("../../creator/bio/customization")
    );
  };

  const handleSubmitBackground = () => {
    if (state) {
      TrackGoogleAnalyticsEvent("Bio Customization", "background_edit");
    } else {
      TrackGoogleAnalyticsEvent("Bio Customization", "background_set");
    }
    dispatch(setLoader({ name: "editProfileLoader", value: true }));

    if (!selectedGradient) return;

    updateProfileInfoAPI(
      {
        data: {
          wallpaper: {
            background: {
              color1: selectedGradient.color1,
              color2: selectedGradient.color2,
              theme: selectedGradient.theme,
            },
          },
        },
        part: "customizations",
      },
      () => navigate("../../creator/bio/customization")
    );
  };

  const wallpaperActions: TabItem[] = [
    {
      name: t("common.pattern"),
    },
    {
      name: t("common.background"),
    },
  ];

  useEffect(() => {
    if (creator._id && creator.wallpaper && creator.wallpaper?.pattern) {
      setSelectedColor(creator.wallpaper?.pattern.color);
      setSelectedIcon(creator.wallpaper?.pattern.icon);
    } else if (creator._id && creator.wallpaper && creator.wallpaper?.background) {
      setSelectedGradient(creator.wallpaper?.background);
      setSelectedAction(1);
    }
  }, [creator]);

  useEffect(
    () => () => {
      dispatch(resetPublicUser());
    },
    []
  );

  const isDoneButtonDisabled = useMemo(() => {
    if (selectedAction === 0) {
      const prevUserColor = creator.wallpaper?.pattern?.color;
      const prevUserIcon = creator.wallpaper?.pattern?.icon;

      // if no initial state, enable only if both is selected
      if (!prevUserColor && !prevUserIcon) {
        return !selectedColor || !selectedIcon;
      }

      const isNothingSelected = !prevUserColor && !prevUserIcon && !selectedColor && !selectedIcon;

      const hasItemsChanged = prevUserColor !== selectedColor || prevUserIcon !== selectedIcon;

      return Boolean(!hasItemsChanged || isNothingSelected);
    }

    const prevUserGradient = creator.wallpaper?.background;

    const hasGradientChanged = prevUserGradient !== selectedGradient;

    const isNothingSelected = !prevUserGradient && !selectedGradient;

    return Boolean(!hasGradientChanged || isNothingSelected);
  }, [creator, selectedAction, selectedGradient, selectedColor, selectedIcon]);

  return (
    <WallpaperContainer>
      <div className="pageTitle">
        <p className="title">{t("common.wallpaper")}</p>
        <div className="tabsContainer">
          <RollingTabs
            options={wallpaperActions}
            selectedAction={selectedAction}
            onTabClicked={(tabIndex, item) => setSelectedAction(tabIndex)}
          />
        </div>
      </div>
      {isLoading && !creator._id ? (
        <OverlayLoader isLoading={isLoading} />
      ) : creator._id ? (
        <div className="content">
          <div className="preview">
            <div className="block mobile">
              <img className="imgBorder" src="/phone.png" alt="phone" />
              <ProfilePreview
                mobile
                selectedAction={selectedAction}
                wallpaper={{
                  pattern: { color: selectedColor, icon: selectedIcon },
                  background: selectedGradient,
                }}
              />
            </div>
            <div className="block desktop">
              <img className="imgBorder" src="/desctop.png" alt="desctop" />
              <ProfilePreview
                selectedAction={selectedAction}
                wallpaper={{
                  pattern: { color: selectedColor, icon: selectedIcon },
                  background: selectedGradient,
                }}
              />
            </div>
          </div>
          {!state && selectedAction === 0 && (
            <>
              <div className="actions">
                <div className="selectedColor">
                  <Color color={selectedColor} active onClick={() => setShowColors(!showColors)} />
                </div>
                <div className="patterns">
                  {PatternIcons.map((item) => (
                    <Icon
                      key={item.name}
                      active={selectedIcon === item.name}
                      onClick={() => {
                        setSelectedIcon(item.name);
                        if (!selectedColor) {
                          setSelectedColor(Object.values(patternColors)[0]);
                        }
                      }}
                    >
                      {item.icon}
                    </Icon>
                  ))}
                </div>
                <div className="colors">
                  {Object.values(patternColors).map((item) => (
                    <Color
                      key={item}
                      color={item}
                      active={selectedColor === item}
                      onClick={() => setSelectedColor(item)}
                    />
                  ))}
                </div>
                <div className="patternsSmall">
                  <div className="scrollContainer">
                    {PatternIcons.map((item) => (
                      <Icon
                        key={item.name}
                        active={selectedIcon === item.name}
                        onClick={() => setSelectedIcon(item.name)}
                      >
                        {item.icon}
                      </Icon>
                    ))}
                  </div>
                </div>
                <div className={`colorsSmall ${showColors ? "show" : ""}`}>
                  <div className="scrollContainer">
                    {Object.values(patternColors).map((item) => (
                      <Color
                        key={item}
                        color={item}
                        active={selectedColor === item}
                        onClick={() => {
                          setSelectedColor(item);
                          setShowColors(false);
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
          {!state && selectedAction === 1 && (
            <>
              <div className="actions">
                <div className="colors">
                  {gradientColors.map((item) => (
                    <Color
                      key={`${item.color1}${item.color2}`}
                      gradient={item}
                      active={
                        selectedGradient?.color1 === item.color1 &&
                        selectedGradient?.color2 === item.color2
                      }
                      onClick={() => setSelectedGradient(item)}
                    />
                  ))}
                </div>
                <div className="colorsSmall show">
                  <div className="scrollContainer">
                    {gradientColors.map((item) => (
                      <Color
                        key={`${item.color1}${item.color2}`}
                        gradient={item}
                        active={
                          selectedGradient?.color1 === item.color1 &&
                          selectedGradient?.color2 === item.color2
                        }
                        onClick={() => setSelectedGradient(item)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <></>
      )}
      {!state && (
        <div className="mainActions">
          <Button variant="secondary" onClick={() => navigate("../../creator/bio/customization")}>
            {t("common.cancel")}
          </Button>
          <Button
            variant="primary"
            disabled={isDoneButtonDisabled}
            onClick={() =>
              selectedAction === 0 ? handleSubmitPattern() : handleSubmitBackground()
            }
          >
            {t("common.done")}
          </Button>
        </div>
      )}
    </WallpaperContainer>
  );
};

export default Wallpaper;
