import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const LandingCardsContainer = styled.div`
  background: ${(p) => p.theme.colors.background.secondary};
  width: 100%;
  height: 880px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .bg-cards {
    top: 0px;
    position: absolute;
    width: 100vw;
    padding: 0px 24px;
    left: calc((-100vw + 961px) / 2);
    background: ${(p) => p.theme.colors.background.secondary};
    height: 100%;
  }

  .box {
    justify-content: center;
    display: flex;
    width: 100%;
    height: 60%;
    max-height: 510px;
    position: relative;

    .vs_icon {
      width: 15%;
      position: absolute;
      z-index: 100;
      top: 20%;

      img {
        width: 100%;
      }

      &.vs_icon_active {
        animation: vs_icon_anim_flip_forward 0.6s ease-out;
      }
    }

    .flip-card {
      display: flex;
      align-items: center;
      background-color: transparent;
      height: 100%;
      width: 50%;
      perspective: 1000px;

      &.left_card_container {
        position: absolute;
        left: 2.5%;
      }
      &.right_card_container {
        position: absolute;
        right: 2.5%;
      }

      .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transform-box: fill-box;
        transform-origin: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;

        img {
          width: 100%;
        }

        .flip-card-front,
        .flip-card-back {
          position: absolute;
          width: 100%;
          height: 100%;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
        }

        .flip-card-back {
          transform: rotateY(180deg);
        }

        &.left_card {
          animation-delay: 2s;
          transform-style: preserve-3d;
          transform: rotateX(13deg) rotateY(18deg) rotateZ(-8deg);
        }
        &.left_active {
          transform: rotateX(13deg) rotateY(198deg) rotateZ(8deg);
        }

        &.right_card {
          animation-delay: 2.1s;
          transform: rotateX(-1deg) rotateY(-13deg) rotateZ(1deg);
        }
        &.right_active {
          transform: rotateX(-1deg) rotateY(-193deg) rotateZ(-1deg);
        }
      }
    }
  }

  @keyframes vs_icon_anim_flip_forward {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
      transform: scale(0);
    }

    80% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  ${deviceQuery.laptopM} {
    height: 760px;
    .bg-cards {
      padding: 0px 20px;
      left: -20px;
    }
  }

  ${deviceQuery.tabletM} {
    height: 640px;
    .bg-cards {
      padding: 0px 16px;
      left: -16px;
    }
  }

  ${deviceQuery.mobileSM} {
    padding: 40px 0px 20px;
    height: 660px;

    .bg-cards {
      width: calc(100vw - 3px);
    }

    .box {
      height: 100%;
      max-height: unset;

      .vs_icon {
        width: 80px;
        top: 40%;
      }

      .flip-card {
        position: static;
        width: 100%;
        height: 50%;

        &.left_card_container {
          justify-content: center;
          top: 0px;
          left: unset;
        }
        &.right_card_container {
          justify-content: center;
          bottom: 0px;
          right: unset;
        }

        .flip-card-inner {
          width: 275px;
        }
      }
    }
  }
`;
