import { FC } from "react";

interface Props {
  size?: number;
}

const Gifting: FC<Props> = ({ size = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M14.3398 0.75H5.66977C2.64977 0.75 0.759766 2.89 0.759766 5.92V14.09C0.759766 17.12 2.63977 19.26 5.66977 19.26H14.3398C17.3698 19.26 19.2598 17.12 19.2598 14.09V5.92C19.2598 2.89 17.3698 0.75 14.3398 0.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.7793 1V19"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 7.73047H1"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.7799 7.7293C8.7799 7.7293 7.7999 6.30929 6.8399 4.96929C6.0099 3.80929 4.20989 4.0893 3.76989 5.4393C3.39989 6.5693 4.2399 7.7193 5.4199 7.7193H8.7799V7.7293Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.7793 7.7293C8.7793 7.7293 9.75928 6.30929 10.7193 4.96929C11.5493 3.80929 13.3493 4.0893 13.7893 5.4393C14.1593 6.5693 13.3193 7.7193 12.1393 7.7193H8.7793V7.7293Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7193 4.98047L4.7793 13.0405"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.83984 4.98047L12.7798 13.0405"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Gifting;
