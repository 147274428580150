import { Formik } from "formik";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import WallOfFame from "./components/WallOfFame";
import { BioWallContainer, BioWallForm } from "./style";

import { SaveButton } from "../BioLink/components/BioLinksListForm/style";
import { BioPageContainer } from "../style";

import TrackGoogleAnalyticsEvent from "common/googleAnalytics";
import Input from "Components/Input";
import Radio from "Components/Radio";
import Switch from "Components/Switch";
import titles from "constants/titles";
import useTitle from "customHooks/useTitle";
import { updateProfileInfoAPI } from "services/UserServices";
import { setLoader } from "store/slices/featureSlice";
import { setIsProfileUpdated } from "store/slices/global/globalSlice";
import { resetWallOfFame } from "store/slices/global/previewProfileSlice";
import { getUser } from "store/slices/global/userSlice";
import { NewCommonWrapper } from "styles/mixins";
import { IFormikBioWall } from "types/user";

const BioWall = memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userDetails = useSelector(getUser);
  const [activeInput, setActiveInput] = useState("");
  useTitle(titles.wallOfFame);

  const isDisabled = useCallback((dirty: boolean) => {
    dispatch(setIsProfileUpdated(!dirty));
    return !dirty;
  }, []);

  const handleSubmit = (values: IFormikBioWall) => {
    dispatch(setLoader({ name: "editProfileLoader", value: true }));
    TrackGoogleAnalyticsEvent("Bio Customization", "background_delete");

    updateProfileInfoAPI({
      data: {
        show: values.show,
        showAmount: values.showAmount,
        period: values.period,
        topSupportersNames: {
          gold: values.topSupportersNames.gold || t("common.topSupporter"),
          silver: values.topSupportersNames.silver || t("common.topSupporter"),
          bronze: values.topSupportersNames.bronze || t("common.topSupporter"),
        },
      },
      part: "wallOfFameSettings",
    });
  };

  useEffect(
    () => () => {
      dispatch(resetWallOfFame());
    },
    []
  );

  return (
    <NewCommonWrapper withoutFilter>
      <BioPageContainer>
        <div className="content">
          {userDetails && (
            <Formik<IFormikBioWall>
              enableReinitialize
              initialValues={{
                show: userDetails.wallOfFameSettings.show,
                showAmount: userDetails.wallOfFameSettings.showAmount,
                period: userDetails.wallOfFameSettings.period || "monthly",
                topSupportersNames: userDetails.wallOfFameSettings.topSupportersNames
                  ? {
                      gold: userDetails.wallOfFameSettings.topSupportersNames.gold,
                      silver: userDetails.wallOfFameSettings.topSupportersNames.silver,
                      bronze: userDetails.wallOfFameSettings.topSupportersNames.bronze,
                    }
                  : {
                      gold: "",
                      silver: "",
                      bronze: "",
                    },
              }}
              onSubmit={handleSubmit}
            >
              {({ values, errors, dirty, handleChange, handleSubmit, setFieldValue }) => (
                <BioWallForm onSubmit={handleSubmit}>
                  <BioWallContainer>
                    <div className="wallContainer">
                      <WallOfFame
                        period={values.period}
                        showAmount={values.showAmount}
                        names={values.topSupportersNames}
                      />
                    </div>
                    <div className="settingsContainer">
                      <div className="block">
                        <p className="title">{t("common.wallOfFame")}</p>
                        <div className="boxes">
                          <div className="showRow">
                            <p>{t("common.showWall")}</p>
                            <Switch
                              value={values.show}
                              onChange={() => setFieldValue("show", !values.show)}
                            />
                          </div>
                          <div className="divider" />
                          <div className="showRow">
                            <p>{t("common.showAmount")}</p>
                            <Switch
                              value={values.showAmount}
                              onChange={() => setFieldValue("showAmount", !values.showAmount)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="block">
                        <p className="title">{t("common.wallPeriod")}</p>
                        <div className="boxes">
                          <Radio
                            name="period"
                            text={t("common.monthly")}
                            checked={values.period === "monthly"}
                            onChange={() => setFieldValue("period", "monthly")}
                            description={t("common.monthlyDesc")}
                          />
                          <div className="divider" />
                          <Radio
                            name="period"
                            text={t("common.permanent")}
                            checked={values.period === "permanent"}
                            onChange={() => setFieldValue("period", "permanent")}
                            description={t("common.permanentDesc")}
                          />
                        </div>
                      </div>
                      <div className="block">
                        <p className="title">{t("common.wallTop")}</p>
                        <div className="inpunts">
                          <Input
                            className="input"
                            name="topSupportersNames.gold"
                            label={t("common.gold")}
                            value={values.topSupportersNames.gold}
                            placeholder={t("common.topSupporter")}
                            handleChange={handleChange}
                            handleFocus={() => setActiveInput("first")}
                            handleBlur={() => setActiveInput("")}
                            rightLabel={
                              activeInput === "first"
                                ? `${values.topSupportersNames.gold.length}/20`
                                : ""
                            }
                            maxLength={20}
                          />
                          <Input
                            className="input"
                            name="topSupportersNames.silver"
                            label={t("common.silver")}
                            value={values.topSupportersNames.silver}
                            placeholder={t("common.topSupporter")}
                            handleChange={handleChange}
                            handleFocus={() => setActiveInput("second")}
                            handleBlur={() => setActiveInput("")}
                            rightLabel={
                              activeInput === "second"
                                ? `${values.topSupportersNames.silver.length}/20`
                                : ""
                            }
                            maxLength={20}
                          />
                          <Input
                            className="input"
                            name="topSupportersNames.bronze"
                            label={t("common.bronze")}
                            value={values.topSupportersNames.bronze}
                            placeholder={t("common.topSupporter")}
                            handleChange={handleChange}
                            handleFocus={() => setActiveInput("third")}
                            handleBlur={() => setActiveInput("")}
                            rightLabel={
                              activeInput === "third"
                                ? `${values.topSupportersNames.bronze.length}/20`
                                : ""
                            }
                            maxLength={20}
                          />
                        </div>
                      </div>
                    </div>
                  </BioWallContainer>
                  <SaveButton disabled={isDisabled(dirty)} variant="primary" type="submit">
                    {t("creator.profile.save")}
                  </SaveButton>
                </BioWallForm>
              )}
            </Formik>
          )}
        </div>
      </BioPageContainer>
    </NewCommonWrapper>
  );
});

export default BioWall;
