import { FormikErrors, useFormikContext } from "formik";
import { FC, useCallback, useMemo } from "react";

import ContentPreviewCard from "pages/creator/Bio/BioLink/components/Social/SocialExpanded/ContentPreviewCard";
import SocialContentLinksForm from "pages/creator/Bio/BioLink/components/Social/SocialExpanded/SocialContentLinksForm";
import { SocialExpandedContainer } from "pages/creator/Bio/BioLink/components/Social/SocialExpanded/style";
import { IFormikPublikLinks, IPublicLink, IPublicLinkContent } from "types/user";

interface BioExpandedLinksProps {
  links: IPublicLinkContent[];
  socialItemIndex: number;
  previewErrorStateChanged: (key: string, hasError: boolean) => void;
}

const BioExpandedLinks: FC<BioExpandedLinksProps> = ({
  socialItemIndex,
  previewErrorStateChanged,
}) => {
  const { values, errors } = useFormikContext<IFormikPublikLinks>();

  const contentLinks = useMemo<any>(() => {
    return values.publicLinks[socialItemIndex]?.contents || [];
  }, [values]);

  const platform = useMemo(() => {
    return values.publicLinks[socialItemIndex]?.platform;
  }, [values]);

  const getRenderItemError = useCallback(
    (errors: FormikErrors<IFormikPublikLinks>, index: number): boolean => {
      if (!errors.publicLinks) return false;
      const publicLinksErrorObject = errors.publicLinks![
        socialItemIndex
      ] as FormikErrors<IPublicLink>;

      return Boolean(publicLinksErrorObject?.contents?.[index]);
    },
    [errors]
  );

  const errorStateChanged = (hasError: boolean, _id: string) => {
    previewErrorStateChanged(_id, hasError);
  };

  if (!values.publicLinks[socialItemIndex]) return null;
  return (
    <SocialExpandedContainer>
      <SocialContentLinksForm socialItemIndex={socialItemIndex} platform={platform} />

      <div className="preview_cards">
        {contentLinks.map((publicLinkContent: IPublicLinkContent, index: number) => (
          <ContentPreviewCard
            key={publicLinkContent._id}
            url={publicLinkContent.url}
            // TODO: set platform from public link
            platform={platform}
            letterId={publicLinkContent.letterId}
            isValid={!getRenderItemError(errors, index)}
            errorStateChanged={(hasError) => errorStateChanged(hasError, publicLinkContent._id)}
          />
        ))}
      </div>
    </SocialExpandedContainer>
  );
};

export default BioExpandedLinks;
