import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import Check from "assets/svg/Check";
import LinkClick from "assets/svg/LinkClick";
import Button from "Components/Button";
import Radio from "Components/Radio";
import { CreatorDesc, FanDesc } from "constants/appConstants";
import {
  ChooseRoleModalContainer,
  Content,
} from "pages/auth/Register/components/RegistrationForm/components/ChooseRoleModal/style";
import { BlockCard } from "styles/mixins";
import { lightColors } from "styles/newThemes";
import { colors } from "styles/theme";
import { UserRole } from "types/user-role";

interface ChooseRoleModalProps {
  show: boolean;
  onCloseSuccess: (role: UserRole) => void;
  onCloseError: () => void;
}

export const ChooseRoleModal: FC<ChooseRoleModalProps> = ({
  show,
  onCloseSuccess,
  onCloseError,
}) => {
  const { t } = useTranslation();
  const [role, setSelectedRole] = useState<null | "creator" | "fan">(null);

  return (
    <ChooseRoleModalContainer
      show={show}
      size="md"
      showCloseButton
      onHide={() => {
        onCloseError();
      }}
    >
      <div className="title">
        <p>{t("signup.choose_role_modal_title")}</p>
        <p className="description">{t("signup.choose_role_modal_desc")}</p>
      </div>
      <Content>
        <div className="radio_container">
          <BlockCard borderColor={role === "creator" ? colors.green.primary : lightColors.divider}>
            <div className="aboutCard">
              <p className="titleCard">
                <Radio
                  disabled={false}
                  key="creator"
                  checked={role === "creator"}
                  onChange={() => {
                    setSelectedRole("creator");
                  }}
                  text=""
                  name="reward"
                />
                <div>
                  <span className="titleRole">{t("common.creator")}</span> <br />
                  {t("common.roleFor")}
                </div>
              </p>
              <div className="desc">
                {CreatorDesc.map((item) => (
                  <div className="item" key={item}>
                    <div className="icon">
                      <Check />
                    </div>{" "}
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </BlockCard>
          <BlockCard borderColor={role === "fan" ? colors.green.primary : lightColors.divider}>
            <div className="aboutCard">
              <p className="titleCard">
                <Radio
                  disabled={false}
                  key="fan"
                  checked={role === "fan"}
                  text=""
                  onChange={() => {
                    setSelectedRole("fan");
                  }}
                  name="reward"
                />
                <div>
                  <span className="titleRole">{t("common.fan")}</span> <br />
                  {t("common.roleFor")}
                </div>
              </p>
              <div className="desc">
                {FanDesc.map((item) => (
                  <div className="item" key={item}>
                    <div className="icon">
                      <Check />
                    </div>{" "}
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </BlockCard>
        </div>
        <a
          className="read_more_container"
          href="https://docs.lewk.com/"
          target="_blank"
          rel="noreferrer"
        >
          <span> {t("common.readMore")}</span>
          <LinkClick size={24} />
        </a>

        {role && (
          <div className="buttons_container">
            <Button
              variant="secondary"
              full
              type="button"
              onClick={() => {
                onCloseError();
              }}
            >
              {t("common.cancel")}
            </Button>
            <Button
              variant="primary"
              full
              type="button"
              onClick={() => {
                onCloseSuccess(role);
              }}
            >
              {t("common.continue")}
            </Button>
          </div>
        )}
      </Content>
    </ChooseRoleModalContainer>
  );
};
