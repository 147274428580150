import { FC, KeyboardEventHandler, memo, ReactNode } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

import { ErrorMsg, Info, InputArea, Label, Wrapper } from "./style";

interface Props {
  id?: string;
  textarea?: boolean;
  className?: string;
  label?: string;
  required?: boolean;
  error?: string;
  info?: string;
  type?: string;
  readOnly?: boolean;
  row?: number;
  placeholder?: string;
  autoFocus?: boolean;
  autoComplete?: string;
  labelSize?: string;
  right?: ReactNode;
  left?: ReactNode;
  color?: string;
  link?: boolean;
  rightLabel?: string;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  register: UseFormRegisterReturn;
}

// Input component for use in react hook forms
const HookFormInput: FC<Props> = memo(
  ({
    labelSize,
    left,
    textarea = false,
    className,
    label,
    required,
    error,
    type = "text",
    info,
    readOnly = false,
    row,
    placeholder,
    autoFocus,
    link,
    autoComplete,
    color,
    right,
    rightLabel,
    onKeyDown,
    register,
  }) => (
    <Wrapper textarea={textarea} className={className || ""}>
      {label && (
        <Label htmlFor={label} fontSize={labelSize} readonly={readOnly}>
          {required ? <span>* {label}</span> : <span>{label}</span>}
          {rightLabel && <span className="right">{rightLabel}</span>}
        </Label>
      )}

      <InputArea
        className={`${textarea && "textareaWrap"} inputWrapper`}
        error={!!error}
        textarea={textarea}
        readonly={readOnly}
        color={color}
        link={link}
      >
        {textarea ? (
          <>
            {left && <div className="left">{left}</div>}
            <textarea
              {...register}
              readOnly={readOnly}
              rows={row || 6}
              onKeyDown={onKeyDown}
              autoFocus={autoFocus || false}
              placeholder={placeholder}
            />
            {right && <div className="right">{right}</div>}
          </>
        ) : (
          <>
            {left && <div className="left">{left}</div>}
            <input
              {...register}
              readOnly={readOnly}
              type={type}
              placeholder={placeholder}
              autoFocus={autoFocus || false}
              autoComplete={autoComplete}
              onKeyDown={onKeyDown}
            />
            {right && <div className="right">{right}</div>}
          </>
        )}
      </InputArea>
      {error && <ErrorMsg>{error}</ErrorMsg>}
      {info && <Info>{info}</Info>}
    </Wrapper>
  )
);

export default HookFormInput;
