import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const ChatHeaderStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;
  height: 60px;
  gap: 12px;
  font-size: ${(p) => p.theme.typography.subText5};
  position: relative;

  .info {
    display: flex;
    gap: 12px;
    align-items: center;

    .arrow {
      display: none;
    }
  }

  ${deviceQuery.tabletL} {
    .info {
      .name {
        max-width: 125px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  ${deviceQuery.tabletM} {
    padding: 0px 16px 12px;
    height: 44px;
    margin: 0px -16px;

    .info {
      .arrow {
        display: flex;
        cursor: pointer;
      }
      .name {
        max-width: unset;
      }
    }
  }

  ${deviceQuery.mobileM} {
    .info {
      .name {
        max-width: 95px;
      }
    }
  }
`;

export const StatusContainer = styled.div<{ isActive: boolean }>`
  align-items: center;
  display: flex;
  gap: 8px;
  cursor: pointer;
  ${(p) =>
    p.isActive
      ? css`
          color: ${p.theme.colors.text.error};
        `
      : css`
          color: ${p.theme.colors.text.third};
        `}
`;

export const UploadProgressBarWrapperStyles = styled.div<{ isHidden: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  clip-path: ${(p) => `inset(0 0 ${p.isHidden ? 100 : 0}% 0)`};
  transform: translateY(100%);
  transition: 0.2s all linear;
  z-index: 2;
`;
