import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store/store";
import { IMySubscription, IPlans } from "types/previewProfile";

interface IInitialState {
  allSubscriptions: {
    data: IPlans[];
    total: number;
  };
  subscriptionForYou: {
    data: IPlans[];
    total: number;
  };
}

const initialState: IInitialState = {
  allSubscriptions: {
    data: [],
    total: 0,
  },
  subscriptionForYou: {
    data: [],
    total: 0,
  },
};

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    setAllSubscriptions: (state, action: PayloadAction<{ data: IPlans[]; total: number }>) => {
      state.allSubscriptions.data = [...state.allSubscriptions.data, ...action.payload.data];
      state.allSubscriptions.total = action.payload.total;
    },
    setSubscriptionsForYou: (state, action: PayloadAction<{ data: IPlans[]; total: number }>) => {
      state.subscriptionForYou.data = [...state.subscriptionForYou.data, ...action.payload.data];
      state.subscriptionForYou.total = action.payload.total;
    },
    resetAllSubscriptions: (state) => {
      state.allSubscriptions.data = [];
      state.allSubscriptions.total = 0;
    },
    subscribePlan: (
      state,
      action: PayloadAction<{ id: string; subscription: IMySubscription }>
    ) => {
      state.allSubscriptions.data = state.allSubscriptions.data.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            subscribed: true,
            mySubscription: action.payload.subscription,
          };
        }
        return item;
      });
    },
    resetFanSubscriptions: (state) => {
      return { ...initialState };
    },
  },
});

export const {
  setAllSubscriptions,
  setSubscriptionsForYou,
  resetAllSubscriptions,
  subscribePlan,
  resetFanSubscriptions,
} = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;

export const getAllSubscriptions = ({ fan }: RootState) => fan.subscriptions.allSubscriptions;
export const getSubscriptionsForYou = ({ fan }: RootState) => fan.subscriptions.subscriptionForYou;
