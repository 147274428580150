const Read = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.6178 6.81933L12.9956 2.30704C12.4 1.89765 11.6178 1.89765 11.0222 2.30704L4.4 6.81933C4.15111 6.99218 4 7.2742 4 7.57441V16.1805C4 17.1812 4.8 18 5.77778 18H18.2222C19.2 18 20 17.1812 20 16.1805V7.57441C20 7.2742 19.8489 6.98308 19.6 6.81933H19.6178Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.6889 7.31954L13.0045 11.8773C12.4089 12.2867 11.6267 12.2867 11.0311 11.8773L4.25781 7.25586"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Read;
