import styled, { css } from "styled-components";

export const TextContainer = styled.p<{ preview?: boolean }>`
  padding: ${(p) => (p.preview ? "0px" : "8px 12px")};
  background: ${(p) => (p.preview ? "unset" : p.theme.colors.background.third)};
  color: ${(p) => (p.preview ? "inherit" : p.theme.colors.text.secondary)};
  border-radius: 8px;
  width: fit-content;

  ${(p) =>
    p.preview &&
    css`
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;
