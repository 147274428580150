import styled, { css } from "styled-components";

export const LanguageSelectorContainer = styled.div<{ show: boolean; top: number; width: number }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: ${(p) => p.top}px;
  right: 0px;
  width: ${(p) => p.width}px;
  padding: 8px;
  border-radius: 16px;
  background: ${(p) => p.theme.colors.background.secondary};
  font-size: ${(p) => p.theme.typography.subText5};
  border: 1px solid ${(p) => p.theme.colors.divider};
  z-index: 16;

  ${(p) =>
    p.show
      ? css`
          pointer-events: all;
          opacity: 1;
        `
      : css`
          pointer-events: none;
          opacity: 0;
        `};
`;

export const MenuItem = styled.div<{ active: boolean }>`
  display: flex;
  padding: 13px 16px;
  gap: 12px;
  border-radius: 12px;
  color: ${(p) => p.theme.colors.text.primary};
  justify-content: space-between;

  .icon {
    display: none;
  }

  ${(p) =>
    p.active &&
    css`
      color: ${p.theme.colors.text.third};
      background: ${p.theme.colors.background.primary};

      .icon {
        display: flex;
      }
    `}

  &:hover {
    color: ${(p) => p.theme.colors.text.third};
  }
`;
