/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import { BsEnvelope } from "react-icons/bs";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { IoSettingsOutline } from "react-icons/io5";
import {
  MdInsertChartOutlined,
  MdOutlineCampaign,
  MdOutlineCurrencyExchange,
  MdPermMedia,
  MdSpaceDashboard,
  MdTypeSpecimen,
} from "react-icons/md";

import { PiUserSwitchLight } from "react-icons/pi";
import { TbReportMoney } from "react-icons/tb";
import { TiGift } from "react-icons/ti";

import Airdrop from "assets/svg/Airdrop";
import BioIcon from "assets/svg/BioIcon";
import Campaigns from "assets/svg/Campaigns";
import Community from "assets/svg/Community";
import { DollarCoin } from "assets/svg/DollarCoin";
import Gifting from "assets/svg/Gifting";
import Challenges from "assets/svg/header/Challenges";
import Dashboard from "assets/svg/header/Dashboard";
import Message from "assets/svg/header/Message";
import Notify from "assets/svg/header/Notify";
import Profile from "assets/svg/header/Profile";
import Wallet from "assets/svg/header/Wallet";
import NewChat from "assets/svg/NewChat";
import Nft from "assets/svg/Nft";

import { PersonChecked } from "assets/svg/PersonChecked";
import { Picture } from "assets/svg/Picture";
import { ROLES } from "constants/appConstants";
import { ROUTES } from "routes/routesData";
import { IHeaderData, ISidebarData } from "types/sidebar";

const notification = (accountType: string): IHeaderData[] => {
  let notify: IHeaderData[] = [];
  if (accountType === ROLES.creator) {
    notify = [
      ...notify,
      // {
      //   to: `/${accountType}/challenges/all`,
      //   className: "position-relative hide",
      //   type: "trends",
      //   text: "common.challenges",
      //   icon: <Challenges />,
      // },
    ];
    // notify.push({
    //   to: `/${accountType}/campaigns/standard`,
    //   className: "position-relative",
    //   type: "campaigns",
    //   text: "common.standard_campaigns",
    //   icon: <Campaigns className="campaign" />,
    // });
    // notify.push({
    //   to: `/${accountType}/campaigns/ideas`,
    //   className: "position-relative",
    //   type: "ideaCampaigns",
    //   text: "common.idea_campaigns",
    //   icon: <MdOutlineCampaign className="campaign-ideas" size={25} />,
    // });
  }
  notify.push({
    to: `/${accountType}/notifications`,
    className: "position-relative",
    text: "header.notifications",
    icon: <Notify />,
  });
  return notify;
};

export const getSideBarData = (
  accountType: string,
  highlightedToLook: string | undefined = undefined
): ISidebarData[] =>
  accountType === ROLES.creator
    ? [
        {
          path: `/airdrop`,
          icon: <Airdrop />,
          title: "common.competition",
        },
        {
          path: `/${accountType}/dashboard`,
          icon: <Dashboard />,
          title: "common.dashboard",
        },
        {
          title: "creator.profile.bio",
          icon: <BioIcon />,
          path: "",
          keyPath: "bio",
          subTab: [
            {
              title: "common.links",
              highlightedToLook: highlightedToLook === "common.links", // todo implement for all of the tabs and make proper identification
              path: `/${accountType}/bio/links`,
            },
            {
              title: "common.customization",
              path: `/${accountType}/bio/customization`,
            },
            {
              title: "common.wallOfFame",
              path: `/${accountType}/bio/wall-of-fame`,
            },
            {
              title: "common.techInsights",
              path: `/${accountType}/bio/analytics`,
            },
          ],
        },
        {
          title: "common.challenging",
          icon: <Challenges />,
          path: "",
          keyPath: "challenges",
          subTab: [
            {
              title: "creator.challenges.all_challenges",
              path: `/${accountType}/challenges/all`,
            },
            {
              title: "creator.challenges.my",
              path: `/${accountType}/challenges/my`,
            },
            {
              title: "creator.gifting.rewards",
              path: `/${accountType}/challenges/rewards`,
            },
          ],
        },
        {
          title: "common.subscriptions",
          icon: <Community />,
          path: "",
          keyPath: "subscriptions",
          subTab: [
            {
              title: "creator.subscription.manage",
              path: `/${accountType}/subscriptions/manage`,
            },
            {
              title: "common.my_feed",
              path: `/${accountType}/subscriptions/myfeed`,
            },
            {
              title: "common.subscribers",
              path: `/${accountType}/subscriptions/subscribers`,
            },
            {
              title: "creator.challenges.earnings",
              path: `/${accountType}/subscriptions/earnings`,
            },
          ],
        },
        {
          title: "creator.gifting.gifting",
          path: `/${accountType}/gifting`,
          icon: <Gifting size={25} />,
        },
      ]
    : accountType === ROLES.fan
    ? [
        {
          path: `/airdrop`,
          icon: <Airdrop />,
          title: "common.competition",
        },
        {
          title: "common.feeds",
          path: ROUTES.fanFeeds,
          icon: <Picture />,
        },
        {
          title: "common.allSubscription",
          path: ROUTES.fanAllSubscriptions,
          icon: <Community />,
        },
        {
          title: "common.subscriptions",
          path: ROUTES.fanSubscribers,
          icon: <PersonChecked />,
        },
        {
          title: "fan.gifting.payment_history",
          path: `${ROUTES.fanPaymentHistory}`,
          icon: <DollarCoin />,
        },
      ]
    : accountType === ROLES.admin
    ? [
        {
          title: "All Peoples",
          path: ROUTES.adminSearchPeople,
          icon: <Community />,
        },
        {
          title: "common.roles",
          path: "",
          keyPath: "role",
          icon: <PiUserSwitchLight size={25} />,
          subTab: [
            {
              title: "common.creator",
              path: `/${accountType}/role/creators`,
            },
            {
              title: "common.fan",
              path: `/${accountType}/role/fans`,
            },
            {
              title: "common.brand",
              path: `/${accountType}/role/brands`,
            },
          ],
        },
        // {
        //   title: "Sign up Requests",
        //   path: ROUTES.adminSignuprequests,
        //   icon: <PiUserSwitchLight size={25} />,
        // },
        {
          title: "Analytics",
          icon: <MdInsertChartOutlined size={25} />,
          path: "",
          keyPath: "analytics",
          subTab: [
            {
              title: "Transaction Explore",
              path: `/${accountType}/analytics/transaction-explore`,
            },
            {
              title: "Fee Information",
              path: `/${accountType}/analytics/fee-information`,
            },
            {
              title: "Balance",
              path: `/${accountType}/analytics/balance`,
            },
            {
              title: "Sweep",
              path: `/${accountType}/analytics/sweep?page=1`,
            },
          ],
        },
        {
          title: "Message",
          icon: <BsEnvelope size={23} />,
          path: "",
          keyPath: "message",
          subTab: [
            {
              title: "Notification",
              path: `/${accountType}/message/notification`,
            },
            {
              title: "Reports",
              path: `/${accountType}/message/report`,
            },
          ],
        },
        {
          title: "Configuration",
          path: ROUTES.adminConfiguration,
          icon: <IoSettingsOutline size={25} />,
        },
        {
          title: "Challenge Rewards",
          path: ROUTES.adminChallengeReward,
          icon: <TbReportMoney size={25} />,
        },
        {
          title: "Reports",
          path: ROUTES.adminReports,
          icon: <HiOutlineDocumentReport size={25} />,
        },
        {
          title: "All NFTs",
          path: ROUTES.adminNFTs,
          icon: <Nft />,
        },
        {
          path: `${ROUTES.adminCampaigns}?page=1`,
          icon: <Campaigns />,
          title: "Campaigns",
        },
        {
          path: ROUTES.adminRefundRequests,
          icon: <MdOutlineCurrencyExchange size={25} />,
          title: "Refund Requests",
        },
        {
          path: `/${accountType}/gifting`,
          icon: <TiGift size={25} />,
          title: "Gifting",
        },
        {
          title: "Email Templates",
          path: ROUTES.adminEmails,
          icon: <MdPermMedia size={25} />,
        },
        {
          title: "Onboarding Screen",
          path: ROUTES.adminOnboardingScreen,
          icon: <MdTypeSpecimen size={25} />,
        },
      ]
    : accountType === ROLES.publisher
    ? [
        {
          path: `/${accountType}/dashboard`,
          icon: <MdSpaceDashboard size={25} />,
          title: "common.dashboard",
        },
        {
          title: "common.campaigns",
          path: `/${accountType}/campaigns?page=1&type=standalone`,
          icon: <MdOutlineCampaign size={25} />,
        },
        {
          title: "creator.gifting.rewards",
          path: `/${accountType}/rewards`,
          icon: <HiOutlineDocumentReport size={25} />,
        },
      ]
    : [];

export const getDrawerData = (accountType: string): IHeaderData[] =>
  accountType === ROLES.creator
    ? [
        ...notification(accountType),
        // {
        //   to: `/${accountType}/community`,
        //   icon: <Community />,
        //   text: "creator.community_text",
        // },
        {
          to: `/${accountType}/wallet`,
          icon: <Wallet />,
          text: "common.wallet",
        },
        {
          to: `/${accountType}/chat`,
          icon: <NewChat />,
          text: "creator.messages",
        },
      ]
    : accountType === ROLES.fan
    ? [
        ...notification(accountType),
        {
          to: `/${accountType}/wallet`,
          icon: <Wallet />,
          text: "common.wallet",
        },
        {
          to: `/${accountType}/chat`,
          icon: <NewChat />,
          text: "creator.messages",
        },
        // {
        //   to: `/${accountType}/messages`,
        //   icon: <Message />,
        //   text: "creator.messages",
        // },
      ]
    : accountType === ROLES.publisher
    ? [
        ...notification(accountType),
        {
          to: `/${accountType}/wallet`,
          icon: <Wallet />,
          text: "common.wallet",
        },
        {
          to: `/${accountType}/editprofile`,
          text: "common.edit_profile",
          icon: <Profile />,
        },
        {
          to: `/${accountType}/messages`,
          icon: <Message />,
          text: "creator.messages",
        },
      ]
    : accountType === ROLES.admin
    ? [...notification(accountType)]
    : accountType === ROLES.judge
    ? [...notification(accountType)]
    : [];

export const authPaths = [
  {
    to: "login",
    text: "login.login",
  },
  {
    to: "register",
    text: "header.register",
  },
];
