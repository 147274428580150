import { FC } from "react";

import { useTranslation } from "react-i18next";

import { BioLinkContainer } from "./style";

import ArrowDownSmall from "assets/svg/ArrowDownSmall";
import Infinite from "assets/svg/Infinite";
import LinkClick from "assets/svg/LinkClick";
import Profile from "assets/svg/Profile";
import { IPublicLink } from "types/user";

interface Props {
  link: IPublicLink;
  mobile: boolean;
}

const BioLink: FC<Props> = ({ link, mobile }) => {
  const { t } = useTranslation();

  return (
    <BioLinkContainer mobile={mobile}>
      <div className="icon">{link.icon}</div>
      <div className="info">
        <p>{link.title}</p>
        <a className="copy" href={`${link.link}`} target="_blank" rel="noreferrer">
          {t("common.toVisit")}
          <LinkClick size={mobile ? 12 : 20} />
        </a>
      </div>
      <div className="spoiler">
        <div className="subscribers">
          <Profile size={20} />
          <Infinite />
        </div>
        <div className="divider" />
        <div className="arrow">
          <ArrowDownSmall />
        </div>
      </div>
    </BioLinkContainer>
  );
};

export default BioLink;
