import { FC } from "react";
import { useSelector } from "react-redux";

import { SidebarFooterContainer } from "./style";

import { getUser } from "store/slices/global/userSlice";

interface Props {
  show?: boolean;
}

const SidebarFooter: FC<Props> = ({ show = false }) => {
  const user = useSelector(getUser);

  return (
    <SidebarFooterContainer show={show}>
      {user && user.generalTheme === "dark" ? (
        <img src="/sloganLight.svg" alt="take a lewk" loading="lazy" />
      ) : (
        <img src="/sloganDark.svg" alt="take a lewk" loading="lazy" />
      )}
    </SidebarFooterContainer>
  );
};

export default SidebarFooter;
