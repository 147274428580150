import { FC } from "react";

interface Props {
  size?: number;
}

const Threads: FC<Props> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.1797 29.6628C46.904 29.5306 46.624 29.4035 46.3403 29.2817C45.8463 20.1794 40.8727 14.9683 32.5213 14.915C32.4835 14.9148 32.4458 14.9148 32.408 14.9148C27.4128 14.9148 23.2584 17.047 20.7013 20.9269L25.2943 24.0776C27.2045 21.1794 30.2024 20.5616 32.4102 20.5616C32.4357 20.5616 32.4613 20.5616 32.4865 20.5618C35.2363 20.5794 37.3113 21.3789 38.6543 22.938C39.6317 24.0731 40.2853 25.6417 40.609 27.6213C38.171 27.2069 35.5343 27.0795 32.7157 27.2411C24.7756 27.6985 19.671 32.3293 20.0139 38.764C20.1878 42.028 21.8139 44.836 24.5923 46.6703C26.9415 48.221 29.967 48.9793 33.1114 48.8077C37.264 48.58 40.5217 46.9957 42.7943 44.0987C44.5203 41.8987 45.612 39.0477 46.094 35.4553C48.073 36.6497 49.5396 38.2213 50.3497 40.1107C51.727 43.3223 51.8073 48.6 47.501 52.9027C43.728 56.672 39.1926 58.3027 32.3385 58.353C24.7354 58.2967 18.9853 55.8583 15.2467 51.1057C11.7458 46.6553 9.93655 40.2273 9.86905 32C9.93655 23.7726 11.7458 17.3445 15.2467 12.8942C18.9853 8.14163 24.7353 5.70333 32.3384 5.64683C39.9967 5.70377 45.847 8.1538 49.7287 12.9293C51.632 15.2712 53.067 18.2163 54.013 21.6501L59.3953 20.2141C58.2487 15.9874 56.4443 12.3452 53.989 9.32467C49.0126 3.20223 41.7347 0.0650493 32.3572 0H32.3196C22.9612 0.0648233 15.7647 3.21393 10.9301 9.35977C6.62795 14.8288 4.40878 22.4386 4.33422 31.9775L4.33398 32L4.33422 32.0225C4.40878 41.5613 6.62795 49.1713 10.9301 54.6403C15.7647 60.786 22.9612 63.9353 32.3196 64H32.3572C40.6773 63.9423 46.542 61.764 51.3733 56.937C57.6943 50.622 57.504 42.7063 55.4207 37.847C53.926 34.3623 51.0763 31.5321 47.1797 29.6628ZM32.8142 43.169C29.3342 43.365 25.7188 41.803 25.5405 38.4573C25.4084 35.9767 27.3059 33.2087 33.0277 32.8789C33.683 32.8411 34.326 32.8227 34.9577 32.8227C37.036 32.8227 38.9803 33.0246 40.748 33.411C40.0886 41.645 36.2213 42.982 32.8142 43.169Z"
      fill="currentColor"
    />
  </svg>
);

export default Threads;
