const InfoCircle = ({ className }: { className?: string }) => (
  <svg
    width="24"
    className={className}
    height="24"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66699 15.9993C2.66699 8.6364 8.63737 2.66602 16.0003 2.66602C23.3642 2.66602 29.3337 8.63595 29.3337 15.9993C29.3337 23.3627 23.3642 29.3327 16.0003 29.3327C8.63737 29.3327 2.66699 23.3623 2.66699 15.9993ZM27.3337 15.9993C27.3337 9.74049 22.2596 4.66602 16.0003 4.66602C9.74194 4.66602 4.66699 9.74097 4.66699 15.9993C4.66699 22.2577 9.74194 27.3327 16.0003 27.3327C22.2596 27.3327 27.3337 22.2582 27.3337 15.9993ZM15.9938 9.93815C16.5001 9.93815 16.9184 10.3144 16.9847 10.8025L16.9938 10.9381V16.8301C16.9938 17.3824 16.5461 17.8301 15.9938 17.8301C15.4875 17.8301 15.0691 17.4539 15.0029 16.9658L14.9938 16.8301V10.9381C14.9938 10.3859 15.4415 9.93815 15.9938 9.93815ZM17.0071 21.0605C17.0071 20.5083 16.5594 20.0605 16.0071 20.0605L15.8581 20.0697C15.37 20.1359 14.9938 20.5543 14.9938 21.0605C14.9938 21.6128 15.4415 22.0605 15.9938 22.0605L16.1428 22.0514C16.6309 21.9852 17.0071 21.5668 17.0071 21.0605Z"
      fill="currentColor"
    />
  </svg>
);

export default InfoCircle;
