import { FC, memo } from "react";
import { Col } from "react-bootstrap";

import { BuyContent, DeleteTitle, FilterBtnWrapper, CancelBtnWrapper } from "./style";

import Button from "Components/Button";
import MiniLoader from "Components/MiniLoader";
import Modal from "Components/Modal/Modal";

interface Props {
  show: boolean;
  closeModal: () => void;
  size: "sm" | "lg" | "xl";
  showCloseButton: boolean;
  onConfirmDelete: () => void;
  title: string;
  deleteBtnText?: string;
  cancelBtnText?: string;
  isLoading?: boolean;
}

// Modal component for buy subscription
const BuySubscriptionModal: FC<Props> = memo(
  ({
    show,
    closeModal,
    size,
    showCloseButton,
    onConfirmDelete,
    title,
    deleteBtnText,
    cancelBtnText,
    isLoading = false,
  }) => (
    <Modal show={show} onHide={() => closeModal()} size={size} showCloseButton={showCloseButton}>
      <BuyContent>
        <DeleteTitle className={!cancelBtnText || !deleteBtnText ? "text-center" : ""}>
          {title}
        </DeleteTitle>
        <FilterBtnWrapper
          className={!cancelBtnText || !deleteBtnText ? "d-flex w-100 justify-content-center" : ""}
        >
          <CancelBtnWrapper>
            <Button variant="primary" disabled={isLoading} onClick={() => onConfirmDelete()}>
              {isLoading ? <MiniLoader /> : deleteBtnText}
            </Button>
          </CancelBtnWrapper>
          {cancelBtnText && (
            <Button variant="secondary" onClick={() => closeModal()}>
              {cancelBtnText}
            </Button>
          )}
        </FilterBtnWrapper>
      </BuyContent>
    </Modal>
  )
);

export default BuySubscriptionModal;
