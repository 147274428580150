const TikTokDarkColor = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.34 9.21952C17.6533 10.1422 19.2622 10.685 20.9999 10.685V7.3988C20.6711 7.39887 20.343 7.36517 20.0213 7.29818V9.88491C18.2838 9.88491 16.6751 9.34204 15.3614 8.41947V15.1257C15.3614 18.4805 12.5941 21.1999 9.18074 21.1999C7.90713 21.1999 6.72335 20.8215 5.73999 20.1725C6.86234 21.3003 8.42753 22 10.1591 22C13.5727 22 16.3401 19.2805 16.3401 15.9256V9.21952H16.34ZM17.5472 5.90412C16.876 5.18348 16.4354 4.25219 16.34 3.22261V2.79993H15.4126C15.6461 4.10853 16.4423 5.22652 17.5472 5.90412ZM7.89896 17.5981C7.52396 17.1149 7.32131 16.5238 7.32222 15.916C7.32222 14.3816 8.58795 13.1376 10.1495 13.1376C10.4405 13.1375 10.7298 13.1813 11.0072 13.2678V9.90811C10.683 9.86445 10.3559 9.84592 10.029 9.85272V12.4677C9.75141 12.3812 9.46199 12.3373 9.1709 12.3376C7.60933 12.3376 6.34367 13.5815 6.34367 15.1161C6.34367 16.2011 6.97633 17.1405 7.89896 17.5981Z"
      fill="#FF004F"
    />
    <path
      d="M15.3613 8.4194C16.675 9.34198 18.2837 9.88484 20.0213 9.88484V7.29811C19.0514 7.09508 18.1928 6.59697 17.5472 5.90412C16.4422 5.22645 15.646 4.10846 15.4126 2.79993H12.9766V15.9255C12.9711 17.4556 11.7075 18.6947 10.1493 18.6947C9.2311 18.6947 8.41535 18.2645 7.89871 17.5981C6.97615 17.1405 6.3435 16.2011 6.3435 15.1162C6.3435 13.5817 7.60916 12.3377 9.17072 12.3377C9.46991 12.3377 9.75828 12.3834 10.0288 12.4678V9.85279C6.67536 9.92088 3.97839 12.6137 3.97839 15.9256C3.97839 17.5788 4.65 19.0775 5.74002 20.1726C6.72338 20.8215 7.90716 21.2 9.18077 21.2C12.5942 21.2 15.3614 18.4804 15.3614 15.1257V8.4194H15.3613Z"
      fill="black"
    />
    <path
      d="M20.0214 7.29817V6.59874C19.1468 6.60005 18.2894 6.35933 17.5473 5.90411C18.2042 6.61089 19.0692 7.09823 20.0214 7.29817ZM15.4127 2.79999C15.3905 2.67493 15.3734 2.54904 15.3615 2.42268V2H11.9981V15.1257C11.9927 16.6557 10.7292 17.8947 9.17087 17.8947C8.71336 17.8947 8.2814 17.7879 7.89886 17.5983C8.4155 18.2645 9.23125 18.6946 10.1495 18.6946C11.7076 18.6946 12.9713 17.4558 12.9768 15.9256V2.79999H15.4127ZM10.0291 9.85284V9.10825C9.74803 9.0705 9.46469 9.05155 9.18099 9.05169C5.76726 9.05162 3 11.7712 3 15.1257C3 17.2288 4.08758 19.0822 5.74024 20.1725C4.65022 19.0775 3.97861 17.5787 3.97861 15.9255C3.97861 12.6138 6.67551 9.92093 10.0291 9.85284Z"
      fill="#00F2EA"
    />
  </svg>
);

export default TikTokDarkColor;
