import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

import { HideScrollBar } from "styles/mixins";

export const ChatListContainer = styled.div`
  ${HideScrollBar}
  display: flex;
  flex-direction: column;
  overflow: auto;

  ${deviceQuery.tabletM} {
    gap: 12px;
    padding: 16px 0px 0px;
  }
`;
