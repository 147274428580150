import { JSX, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

type StringKeysOnly<T> = {
  [K in keyof T]: T[K] extends string | undefined ? K : never;
}[keyof T];

interface DraggableListProps<RenderItemInfo> {
  items: RenderItemInfo[];
  renderItem: (
    item: RenderItemInfo,
    index: number,
    elementToDragFor: DraggableProvidedDragHandleProps | null | undefined
  ) => JSX.Element;
  orderChanged: (newList: RenderItemInfo[], prevIndex: number, newIndex: number) => void;
  trackByKey: StringKeysOnly<RenderItemInfo>; // uniq key to pass as key and id while mapping
  className?: string;
  isDragDisabled?: boolean;
}

// Component of Drag and Drop container for Bio links
function DraggableList<RenderItemInfo>({
  items,
  renderItem,
  orderChanged,
  className,
  trackByKey,
  isDragDisabled = false,
}: DraggableListProps<RenderItemInfo>) {
  const [list, setList] = useState<RenderItemInfo[]>(items);

  useEffect(() => {
    setList(items);
  }, [items]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newList = Array.from(list);
    const [removed] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, removed);

    setList(newList);
    orderChanged(newList, result.source.index, result.destination.index);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className={className}>
            {list.map((item, index) => (
              <Draggable
                /* eslint-disable @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                key={(item[trackByKey] as string) || index}
                /* eslint-disable @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                draggableId={item[trackByKey] || String(index)}
                isDragDisabled={isDragDisabled}
                index={index}
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps}>
                    {renderItem(item, index, provided.dragHandleProps)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DraggableList;
