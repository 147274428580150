import { FC, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import { WallFan, WallOfFameContainer } from "./style";

import ArrowDownSmall from "assets/svg/ArrowDownSmall";
import BronzeCrown from "assets/svg/BronzeCrown";
import GoldCrown from "assets/svg/GoldCrown";
import SilverCrown from "assets/svg/SilverCrown";
import Accordion2 from "Components/Accordion2";
import Avatar from "Components/Avatar";
import { useWindowDimensions } from "customHooks/useWindowDimension";
import { getWallPreview } from "services/UserServices";
import { getWallOfFame } from "store/slices/global/previewProfileSlice";

interface Props {
  profile?: boolean;
  period: string;
  showAmount: boolean;
  names: {
    gold: string;
    silver: string;
    bronze: string;
  };
}

const WallOfFame: FC<Props> = ({ profile = false, period, showAmount, names }) => {
  const { t } = useTranslation();
  const wallOfFame = useSelector(getWallOfFame);
  const [show, setShow] = useState(false);
  const { width } = useWindowDimensions();

  const CrownMap: { [x: number]: ReactNode } = {
    0: <GoldCrown />,
    1: <SilverCrown />,
    2: <BronzeCrown />,
  };
  const TextMap: { [x: string]: string } = {
    0: names?.gold || t("common.topSupporter"),
    1: names?.silver || t("common.topSupporter"),
    2: names?.bronze || t("common.topSupporter"),
  };

  useEffect(() => {
    !profile && getWallPreview({ period });
  }, [period]);

  if (!width) return null;

  return (
    <WallOfFameContainer onClick={() => setShow(!show)}>
      <Accordion2
        show={width > 767 ? true : show}
        collapsedContent={
          <div className="top">
            <p className="title">
              {t(period === "monthly" ? "common.monthlyWallOfFame" : "common.wallOfFame")}
            </p>
            <div className="show">
              <div className={`avatars ${show ? "active" : ""}`}>
                {wallOfFame.map((item, index) => (
                  <Avatar
                    key={item.donatorId}
                    size="xs"
                    src={item.pictureUrl_100_100}
                    role={item.role}
                    alt={`fan${index}`}
                  />
                ))}
              </div>
              <div className={`arrow ${show ? "active" : ""}`}>
                <ArrowDownSmall />
              </div>
            </div>
          </div>
        }
        expandedContent={
          <div className="fansContainer">
            {wallOfFame.map((item, index) => (
              <WallFan profile={profile} key={item.donatorId}>
                <Avatar
                  size="sm"
                  src={item.pictureUrl_100_100}
                  role={item.role}
                  alt={`fan${index}`}
                />
                <div className="info">
                  <div className="name">
                    <div>{CrownMap[index]}</div>
                    <p className="display">{item.displayname || item.username}</p>
                  </div>
                  <p className="description">
                    <span className="text">{TextMap[index]}</span>
                    {showAmount && <span className="amount">~ $ {item.totalAmountDonated}</span>}
                  </p>
                </div>
              </WallFan>
            ))}
          </div>
        }
      />
    </WallOfFameContainer>
  );
};

export default WallOfFame;
