const Restore = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Restore">
      <path
        id="Vector"
        d="M5.29688 7.6285C6.23874 6.18495 7.62164 5.08421 9.23967 4.49015C10.8577 3.8961 12.6243 3.84051 14.2765 4.33167C15.9287 4.82283 17.378 5.83446 18.4088 7.21594C19.4395 8.59742 19.9965 10.2749 19.9969 11.9985"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M18.86 16.12C17.9597 17.6206 16.5922 18.7845 14.9671 19.4336C13.342 20.0826 11.5489 20.1808 9.8626 19.7133C8.17629 19.2458 6.68979 18.2382 5.63089 16.845C4.57199 15.4519 3.9991 13.7499 4 12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M9 8H5V4"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M15 16H19V20"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default Restore;
