import { MouseEvent, KeyboardEvent, memo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Wrapper } from "./style";

import Close from "assets/svg/Close";
import Video from "Components/Video";
import { MESSAGETYPES } from "constants/appConstants";
import { getViewPicture, setViewPicture } from "store/slices/global/globalSlice";

// Component for display full page images, video and audio
const ViewContent = memo(() => {
  const dispatch = useDispatch();
  const viewPicture = useSelector(getViewPicture);
  const ref = useRef<HTMLDivElement>(null);

  const hidePicture = () => dispatch(setViewPicture({ show: false, src: "" }));

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.code === "Escape") {
      hidePicture();
    }
  };

  const onWrapperClick = (event: MouseEvent<HTMLDivElement>): void => {
    const target = event.target as HTMLElement;
    if (target.nodeName === "IMG" || target.nodeName === "VIDEO" || target.nodeName === "AUDIO") {
      // Check if click is outside of content, then close the modal window
      return;
    }
    hidePicture();
  };

  useEffect(() => {
    viewPicture.show && ref.current?.focus();
  }, [viewPicture]);

  return (
    <Wrapper
      ref={ref}
      tabIndex={0}
      onKeyDown={(event) => handleKeyPress(event)}
      onClick={(event) => onWrapperClick(event)}
      style={{
        transform: viewPicture.show ? "translateY(0)" : "translateY(-100%)",
      }}
    >
      <div
        className="times"
        onClick={(event) => {
          event.stopPropagation();
          hidePicture();
        }}
      >
        <Close size={32} />
      </div>
      {viewPicture.type === MESSAGETYPES.image && <img src={viewPicture.src} alt="thumbnail" />}
      {viewPicture.type === MESSAGETYPES.video && <Video src={viewPicture.src} />}
      {viewPicture.type === MESSAGETYPES.audio && (
        <Video src={viewPicture.src} contentType="Audio" />
      )}
    </Wrapper>
  );
});

export default ViewContent;
