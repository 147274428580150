import getAxiosInst, { endpoints, apiHandler } from "./axios";

import { getLocalStorageItem } from "common/helpers/localStorage";
import { STORAGES } from "constants/appConstants";
import { setLoader } from "store/slices/featureSlice";
import {
  setNotificationCount,
  setNotifications,
  readNotification,
  setUnreadNotification,
} from "store/slices/global/notificationSlice";
import store from "store/store";
import { INotification } from "types/notification";
import { UserRole } from "types/user-role";

export const getNotficationCountAPI = (lastnotificationReadAt: string) => {
  const role = getLocalStorageItem({ key: STORAGES.accountType });

  apiHandler(
    () =>
      getAxiosInst().get(endpoints.getNotificationCount, {
        params: {
          date: lastnotificationReadAt,
          role,
        },
      }),
    {
      onSuccess: (data: { count: number }) => {
        store.dispatch(setNotificationCount(data.count));
      },
      onError: (error) => {
        console.log("catch error ===>>>", error.response);
      },
    }
  );
};

export const getUnreadNotificationApi = (role: UserRole) => {
  apiHandler(
    () =>
      getAxiosInst().get(endpoints.getNotificationCount, {
        params: {
          role,
        },
      }),
    {
      onSuccess: (res: { count: number }) => {
        store.dispatch(setUnreadNotification(res.count));
      },
      onError: (error) => {
        console.log("catch error ===>>>", error, error.response);
      },
    }
  );
};

export const updateNotficationsAPI = (date: Date) => {
  apiHandler(
    () =>
      getAxiosInst().post(endpoints.updateNotification, {
        params: {
          date,
        },
      }),
    {
      onError: (error) => {
        console.log("catch error ===>>>", error.response);
      },
    }
  );
};

export const readNotficationAPI = (id: string) => {
  apiHandler(() => getAxiosInst().put(`/notification/${id}/read`), {
    onSuccess: () => {
      store.dispatch(readNotification(id));
    },
    onError: (error) => {
      console.log("catch error ===>>>", error.response);
    },
  });
};

export const getNotficationsAPI = (
  { limit, skip }: { limit: number; skip: number },
  createdAt: string
) => {
  const role = getLocalStorageItem({ key: STORAGES.accountType });
  store.dispatch(setLoader({ name: "notificationLoader", value: true }));

  apiHandler(
    () =>
      getAxiosInst().get(endpoints.getNotifications, {
        params: {
          role,
          limit,
          skip,
          date: createdAt,
        },
      }),
    {
      onSuccess: (res: { data: INotification[]; Totalnotification: number }) => {
        store.dispatch(
          setNotifications({
            data: res.data,
            total: res.Totalnotification,
          })
        );
      },
      onError: (error) => {
        console.log("catch error ===>>>", error, error.response);
      },
      final: () => {
        store.dispatch(setLoader({ name: "notificationLoader", value: false }));
      },
    }
  );
};
