import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const ChangeAvatarContainer = styled.div`
  .preview {
    display: flex;
    gap: 16px;

    .image {
      flex: 1;
      display: flex;
      padding: 12px;
      background: ${(p) => p.theme.colors.background.fifth};
      border-radius: 12px;
      gap: 16px;
      font-size: ${(p) => p.theme.typography.subText3};

      & > .title {
        font-size: ${(p) => p.theme.typography.subText5};
      }

      .actions {
        gap: 12px;
        display: flex;
        align-items: center;
      }
    }

    .infoContainer {
      flex: 1;
      color: ${(p) => p.theme.colors.text.secondary};
      display: flex;
      padding: 14px 16px;
      background: ${(p) => p.theme.colors.background.fifth};
      border-radius: 12px;
      font-size: ${(p) => p.theme.typography.subText3};
      flex-direction: column;
      gap: 12px;

      .infoBlock {
        gap: 12px;
        display: flex;
        align-items: center;

        .rule {
          display: flex;
          flex-direction: column;

          p:last-child {
            color: ${(p) => p.theme.colors.text.primary};
          }
        }
      }

      .divider {
        width: 100%;
        height: 1px;
        background: ${(p) => p.theme.colors.divider};
      }
    }
  }

  ${deviceQuery.tabletM} {
    .preview {
      flex-direction: column;

      .image {
        .actions {
          flex-direction: column;
          justify-content: center;
          width: 100%;
        }
      }
    }
  }
`;
