import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaRegCopy } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import QR from "react-qr-code";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import {
  AuthStyled,
  Heading,
  DetailsSection,
  QRContainer,
  SubHeading,
  KeyBox,
  Form,
  RoundButton,
} from "./style";

import ArrowLeft from "assets/svg/ArrowLeft";
import Copy from "assets/svg/Copy";
import { copyToClipboard, makeToast } from "common/helpers/helper";
import Button from "Components/Button";
import BuySubscriptionModal from "Components/BuySubscriptionModal";
import MiniLoader from "Components/MiniLoader";
import TableInput from "Components/TableInput";
import { STRINGS } from "constants/appConstants";
import titles from "constants/titles";
import useTitle from "customHooks/useTitle";

import { ROUTES } from "routes/routesData";
import { apiHandler, fetchUserDetailsAPI, getAllDefaultLandingPageAPI } from "services/axios";
import { skipMFA, skipMFAApi, twoFactorAuthQR, twoFactorAuthVerify } from "services/UserServices";
import { getDefaultLandingPages } from "store/slices/admin/adminSlice";
import { getQrDetails, getToken, login, setQrDetails } from "store/slices/authSlice";
import { getQRCodeLoader, setLoader } from "store/slices/featureSlice";
import { setUser, getUser } from "store/slices/global/userSlice";
import { UploadWarning } from "styles/admin";

import { LoaderWrapper } from "styles/campaign";
import { colors } from "styles/theme";

interface FormikAuthValues {
  passCode: "";
}

// set two factor auth page
const AuthenticationPage = () => {
  useTitle(titles.authentication);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [emailverifyModal, setEmailverifyModal] = useState(false);
  const [skipLoader, setSkipLoader] = useState(false);
  const defaultLandingPages = useSelector(getDefaultLandingPages);
  const jwtToken = useSelector(getToken);
  const qrdetails = useSelector(getQrDetails);
  const QRCodeLoader = useSelector(getQRCodeLoader);
  const startLoader = () => dispatch(setLoader({ name: "QRCodeLoader", value: true }));
  const stopLoader = () => dispatch(setLoader({ name: "QRCodeLoader", value: false }));
  const userDetails = useSelector(getUser);

  useEffect(() => {
    getAllDefaultLandingPageAPI();
  }, [jwtToken]);

  useEffect(() => {
    if (!state?.data && !userDetails?._id) {
      navigate(ROUTES.login);
    }
  }, [state, userDetails]);

  useEffect(() => {
    if (state?.page && state?.page === STRINGS.register) {
      setEmailverifyModal(true);
    }
  }, [state?.page]);

  const closeEmailVerifyModal = () => {
    setEmailverifyModal(false);
  };

  // get secret key and qr key to show QR
  const getAuthenticationDetails = async () => {
    const data = { id: state?.data?._id || userDetails?._id };

    apiHandler(() => twoFactorAuthQR({ data }), {
      onSuccess: (data) => {
        dispatch(setQrDetails({ secret: data.secret, secretKey: data.secretKey }));
      },
    });
  };

  useEffect(() => {
    !qrdetails && getAuthenticationDetails();
  }, []);

  // it redirect user based on role
  const redirectRoute = () => {
    let route;
    const role = state?.data?.role?.toLowerCase() || userDetails?.role?.toLowerCase();
    defaultLandingPages?.forEach((item: any) => {
      if (item.role.toLowerCase() === role) route = item.defaultURl;
    });
    navigate(`/${route}`, { replace: true });
  };

  const redirectBackToSettings = (role: string) => {
    // have to refetch data to get actual info
    fetchUserDetailsAPI(() => {
      const route = `/${role}/editProfile/mfa`;
      navigate(route, { replace: true });
    });
  };

  const redirectUser = () => {
    const role = state?.data?.role?.toLowerCase() || userDetails?.role?.toLowerCase();
    if (state?.afterFirstEnable) {
      redirectBackToSettings(role);
      return;
    }
    if (role === STRINGS.admin || role === STRINGS.judge || state?.page === STRINGS.loggedin) {
      redirectRoute();
    } else {
      navigate("/onboarding");
    }
  };

  const successHandler = () => {
    if (state?.page !== STRINGS.loggedin) {
      dispatch(
        login({
          accountType: state?.data?.role,
          token: jwtToken,
          userId: state?.data?._id,
        })
      );
    }
    redirectUser();
  };

  const onSkipHandler = () => {
    setSkipLoader(true);
    const apiData = { skip: true, _id: state?.data?._id };
    apiHandler(() => skipMFA({ apiData }), {
      onSuccess: () => {
        setSkipLoader(false);
        if (state?.page === STRINGS.register) {
          navigate(`/login`);
        } else {
          successHandler();
          dispatch(setUser({ ...userDetails, ...state?.data, mfaSkip: true }));
        }
      },
      onError: (error) => {
        setSkipLoader(false);
        makeToast({
          message: error.response?.data.message,
          type: STRINGS.toastType.error,
        });
      },
    });
  };

  const handleSubmit = async (values: FormikAuthValues) => {
    startLoader();
    const apiData = {
      id: state?.data?._id || userDetails?._id,
      token: values.passCode,
    };

    apiHandler(() => twoFactorAuthVerify({ apiData }), {
      onSuccess: () => {
        if (userDetails) {
          skipMFAApi({ skip: false, _id: userDetails._id });
          dispatch(setUser({ ...userDetails, ...state?.data }));
          successHandler();
          stopLoader();
        }
      },
      onError: (error) => {
        stopLoader();
        makeToast({
          message: error.response?.data.message,
          type: STRINGS.toastType.error,
        });
      },
    });
  };

  const twoFASchema = Yup.object().shape({
    passCode: Yup.string().required(t("common.required")),
  });

  if (!userDetails) return null;

  return (
    <>
      <AuthStyled>
        <DetailsSection className="position-relative">
          {!userDetails.is_new && (
            <RoundButton variant="primary" onClick={() => navigate(-1)}>
              <ArrowLeft />
            </RoundButton>
          )}
          <div className="top">
            <Heading className="mt-2">{t("auth.two_factor_auth")}</Heading>
            <Heading>({t("auth.2FA")})</Heading>
            <SubHeading className="mb-0">{t("auth.scan_with_2FA")}</SubHeading>
            <SubHeading className="my-0 text-uppercase">{t("common.or")}</SubHeading>
            <SubHeading className="mt-0">{t("auth.use_the_key")}</SubHeading>
            <QRContainer>
              {qrdetails ? (
                <QR value={qrdetails.secret} />
              ) : (
                <div className="text-center">
                  <Skeleton
                    baseColor={colors.gray.bluesh}
                    highlightColor={colors.gray.light}
                    width={256}
                    height={256}
                  />
                </div>
              )}
            </QRContainer>
          </div>
          <div className="bottom">
            <Formik<FormikAuthValues>
              initialValues={{
                passCode: "",
              }}
              validationSchema={twoFASchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, handleBlur, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="my-3">
                    <TableInput
                      full
                      readOnly={QRCodeLoader || skipLoader}
                      name="passCode"
                      type="text"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      placeholder={t("auth.code_from_2fa")}
                      value={values.passCode}
                      autoFocus
                    />
                  </div>
                  {qrdetails?.secretKey ? (
                    <>
                      <KeyBox>
                        <p>{qrdetails?.secretKey}</p>
                        <div
                          className="icon"
                          onClick={() =>
                            copyToClipboard(
                              () => {},
                              () => {},
                              qrdetails?.secretKey
                            )
                          }
                        >
                          <Copy size={25} />
                        </div>
                      </KeyBox>
                      <UploadWarning>{t("publisher.campaign.code_warning")}</UploadWarning>
                    </>
                  ) : (
                    <Skeleton
                      width="100%"
                      height={30}
                      baseColor={colors.gray.border}
                      highlightColor={colors.gray.light}
                    />
                  )}
                  <div className="btn-con">
                    <Button
                      full
                      variant="primary"
                      type="submit"
                      disabled={QRCodeLoader || !values.passCode || skipLoader}
                    >
                      {QRCodeLoader ? <MiniLoader /> : t("creator.campaign.submit")}
                    </Button>
                    {userDetails.is_new &&
                      (state?.data?.role?.toLowerCase() || userDetails?.role?.toLowerCase()) !==
                        STRINGS.admin &&
                      (state?.data?.role?.toLowerCase() || userDetails?.role?.toLowerCase()) !==
                        STRINGS.judge && (
                        <Button variant="secondary" full type="button" onClick={onSkipHandler}>
                          {t(
                            state?.page === STRINGS.register
                              ? "auth.skip_and_login"
                              : "auth.skip_for_now"
                          )}
                        </Button>
                      )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {skipLoader && (
            <LoaderWrapper>
              <MiniLoader size="40px" color="grey" />{" "}
            </LoaderWrapper>
          )}
        </DetailsSection>
      </AuthStyled>
      <BuySubscriptionModal
        show={emailverifyModal}
        closeModal={closeEmailVerifyModal}
        showCloseButton
        size="sm"
        onConfirmDelete={closeEmailVerifyModal}
        title={`${t("auth.email_verification")}`}
        deleteBtnText={t("common.okay")}
      />
    </>
  );
};

export default AuthenticationPage;
