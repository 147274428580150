import styled from "styled-components";

export const OnboardingContainer = styled.div`
  display: flex;
  padding: 20px 7% 60px;
  background: ${(p) => p.theme.colors.background.primary};
  min-height: calc(100vh - 82.58px);
  justify-content: center;
  position: relative;
`;

export const OnBoard = styled.div`
  display: flex;
  overflow: hidden;
  color: ${(p) => p.theme.colors.text.primary};
  flex-direction: column;
  flex: 1;
  max-width: 1180px;
  gap: 20px;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  z-index: 9999;
  left: 45%;
  top: 35%;
`;
