import React, { memo } from "react";

import { FilledArea, InputArea, Wrapper } from "./style";

interface Props {
  min: number;
  max: number;
  value: number;
  valueChange: (value: number) => void;
  step: number;
}

// SimpleRangeInput component that renders a range input with a visual indicator of the selected value
export const SimpleRangeInput = memo(({ max, valueChange, value, min, step }: Props) => {
  const calcPercent = () => {
    const fullRange = max - min;

    const valuePercentage = (value * 100) / fullRange;

    const minusThumbWidth = valuePercentage <= 0 ? "+ 11px" : "";

    const minValuePercentage = Math.abs((min * 100) / fullRange);

    return `calc(${valuePercentage + minValuePercentage}% ${minusThumbWidth})`;
  };

  return (
    <Wrapper>
      <InputArea>
        <input
          type="range"
          min={min}
          max={max}
          value={value}
          step={step}
          onChange={(event) => {
            valueChange(Number(event.target.value));
          }}
        />
        <FilledArea width={calcPercent()} />
      </InputArea>
    </Wrapper>
  );
});
