import { FC } from "react";

interface Props {
  className?: string;
  size?: number;
}

const Unlock: FC<Props> = ({ className, size = 21 }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Unlock">
      <path
        id="Stroke 1"
        d="M13.4267 4.56204C12.8097 2.78004 11.1167 1.50004 9.12469 1.50004C6.61169 1.49004 4.56569 3.51804 4.55469 6.03104V6.05104V8.19804"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Stroke 3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.933 19.999H5.292C3.198 19.999 1.5 18.301 1.5 16.206V11.918C1.5 9.823 3.198 8.125 5.292 8.125H12.933C15.027 8.125 16.725 9.823 16.725 11.918V16.206C16.725 18.301 15.027 19.999 12.933 19.999Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Stroke 5"
        d="M9.10938 12.9531V15.1751"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default Unlock;
