import i18n from "i18next";

import {
  LANGUAGES,
  LANGUAGES_TO_I18N,
  LANGUAGES_SHIRT_TO_I18N,
  LANGUAGES_SHIRT,
} from "types/language.types";

/**
 * changes the language of the application using a predefined mapping.
 *
 * @param {LANGUAGES} language - language identifier from the LANGUAGES enum.
 */
export const changeLanguage = (language: LANGUAGES) => {
  const i18Language = LANGUAGES_TO_I18N[language] || LANGUAGES_TO_I18N.english;
  i18n.changeLanguage(i18Language);
};

/**
 * changes the language of the application based on a short language code.
 *
 * @param {LANGUAGES_SHIRT} language - short language identifier from the LANGUAGES_SHIRT enum.
 */
export const changeLanguageByShort = (language: LANGUAGES_SHIRT) => {
  const i18Language = LANGUAGES_SHIRT_TO_I18N[language] || LANGUAGES_SHIRT_TO_I18N.en;

  i18n.changeLanguage(i18Language);
};
