import { useState, FC } from "react";
import { useTranslation } from "react-i18next";

import Check from "assets/svg/Check";
import Settings from "assets/svg/Settings";
import {
  LanguageSelectorContainer,
  MenuItem,
} from "Components/NewHeader/components/LanguageSelector/style";
import { CHAT_SETTINGS } from "constants/appConstants";
import FilterOverlay from "pages/creator/Bio/Analytics/components/AnalyticsFilter/components/FilterOverlay";
import { AllowedMessagesFrom } from "types/user";

interface Props {
  value: string;
  handleChange: (value: AllowedMessagesFrom) => void;
}

const SettingMenu: FC<Props> = ({ value, handleChange }) => {
  const { t } = useTranslation();
  const [menu, setMenu] = useState(false);

  const handleChangeSetting = (value: AllowedMessagesFrom) => {
    handleChange(value);
    setMenu(false);
  };

  return (
    <div className="setting" onClick={() => setMenu(!menu)}>
      <Settings />
      <LanguageSelectorContainer show={menu} top={36} width={260}>
        {CHAT_SETTINGS.map((item, index) => (
          <MenuItem
            key={index}
            active={item.value === value}
            onClick={() => handleChangeSetting(item.value)}
          >
            <span>{t(item.label)}</span>
            <div className="icon">
              <Check />
            </div>
          </MenuItem>
        ))}
      </LanguageSelectorContainer>
      <FilterOverlay show={menu} onClick={() => setMenu(false)} />
    </div>
  );
};

export default SettingMenu;
