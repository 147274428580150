import styled from "styled-components";

import Button from "Components/Button";
import { deviceQuery } from "styles/mediaSizes";

export const ProfileContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 20px;

  & > .actions {
    max-width: 332px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: fit-content;

    .deleteButton {
      width: 100%;
    }

    .delete {
      cursor: pointer;
      color: ${(p) => p.theme.colors.text.error};
      font-size: ${(p) => p.theme.typography.subText5};
    }
  }

  .selectTab {
    position: relative;
    display: none;
    background: ${(p) => p.theme.colors.background.secondary};
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    z-index: 3;

    [class*="container"] {
      border: none;
    }
  }

  .content {
    flex: 2;
  }

  ${deviceQuery.desktopS} {
    & > .actions {
      max-width: 300px;
    }
  }

  ${deviceQuery.laptopM} {
    flex-direction: column;

    .selectTab {
      display: flex;

      & > * {
        flex: 1;
      }
    }

    & > .actions {
      display: none;
    }
  }
`;

export const SaveButton = styled(Button)`
  align-self: flex-end;

  ${deviceQuery.tabletM} {
    flex: 1;
  }
`;

export const ProfileForm = styled.form`
  display: flex;
  gap: 16px;
  flex-direction: column;

  .card {
    gap: 16px;
    flex-direction: column;

    & > .title {
      font-size: ${(p) => p.theme.typography.text3};
    }
  }

  .boxes {
    display: flex;
    gap: 16px;
    flex-direction: column;
    margin-bottom: 16px;
  }

  ${deviceQuery.mobileM} {
    padding-bottom: 73px;
  }
`;

export const SubPageContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;

  .saveContainer {
    display: flex;
    justify-content: flex-end;
  }

  & > .title {
    color: ${(p) => p.theme.colors.text.primary};
    font-size: ${(p) => p.theme.typography.text3};
  }

  .description {
    color: ${(p) => p.theme.colors.text.secondary};
    font-size: ${(p) => p.theme.typography.subText5};
  }

  .divider {
    width: 100%;
    height: 1px;
    background: ${(p) => p.theme.colors.divider};
  }

  ${deviceQuery.laptopM} {
    & > .title {
      display: none;
    }
  }

  ${deviceQuery.tabletM} {
    .saveContainer {
      left: 0px;
      width: 100vw;
      padding: 12px 20px;
      border-top: 1px solid ${(p) => p.theme.colors.divider};
      background: ${(p) => p.theme.colors.background.secondary};
      bottom: 0vh;
      position: fixed;
    }
  }
`;
