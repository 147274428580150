import { FC } from "react";

import { useTranslation } from "react-i18next";

import { SocialContentLinkContainer } from "./style";

import Plus from "assets/svg/Plus";

const AddSocialContentLink: FC<{ hasTopRadius: boolean }> = ({ hasTopRadius }) => {
  const { t } = useTranslation();
  return (
    <SocialContentLinkContainer hasTopRadius={hasTopRadius}>
      <Plus /> <span>{t("creator.profile.addLink")}</span>
    </SocialContentLinkContainer>
  );
};

export default AddSocialContentLink;
