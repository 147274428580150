import styled from "styled-components";

import Button from "Components/Button";
import { deviceQuery } from "styles/mediaSizes";

export const LinksContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;

  .title {
    font-size: ${(p) => p.theme.typography.text3};
  }
  .video {
    display: none;
    border-radius: 12px;
    background: ${(p) => p.theme.colors.background.secondary};
    max-height: 600px;
  }

  .formContainer {
    display: flex;
    width: 100%;
    max-width: 740px;

    form {
      flex: 1;

      .saveContainer {
        display: flex;
        width: max-content;
        margin-left: auto;
      }
    }
  }

  ${deviceQuery.tabletM} {
    flex-direction: column;

    .video {
      min-height: 300px;
    }
  }

  ${deviceQuery.mobileL} {
    .formContainer {
      form {
        .saveContainer {
          width: unset;
          margin-left: unset;
        }
      }
    }
  }
`;

export const SaveButton = styled(Button)`
  align-self: flex-end;

  ${deviceQuery.tabletM} {
    flex: 1;
  }
`;
