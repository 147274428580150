import styled, { css } from "styled-components";

import { OutfitMedium, OutfitRegular } from "styles/mixins";

export const FilterTitle = styled.p`
  ${OutfitRegular};
  font-size: 24px;
  color: ${(p) => p.theme.colors.text.primary};
`;

export const BtnBlock = styled.div`
  display: block;
  justfy-content: flex-start;
  align-items: flex-start;
  padding-left: 0;
  margin-left: 0;
  text-align: start;
`;

export const CampaignHead = styled.div<{ details?: boolean }>`
  width: 100%;
  display: flex;
  justfy-content: center;
  align-items: center;
  overflow: ${(p) => (p.details ? "auto" : "hidden")};
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: 750px) {
    margin-bottom: 10px;
  }
`;

export const CampaignHeadRight = styled.div`
  display: flex;
  justfy-content: center;
  align-items: center;
  @media (max-width: 600px) {
    display: block;
  }
`;

export const CampaignHeadInnerWrapper = styled.div`
  display: flex;
  justfy-content: center;
  align-items: center;
  @media (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

export const TotalCount = styled.p`
  ${OutfitRegular};
  font-size: 14px;
  margin-left: 10px;
  color: ${(p) => p.theme.colors.text.primary};
  background: ${(p) => p.theme.colors.text.secondary};
  padding: 0px 10px;
  border-radius: 10px;
`;

export const CloseBtn = styled.div`
  margin-right: 8px;
`;

export const VideoBtnWrapper = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 991px) {
    width: 50%;
  }
  @media (max-width: 445px) {
    display: block;
  }
`;

export const TableTitle = styled.div<{ newStyle?: boolean; isColor?: boolean; minWidth?: number }>`
  ${(p) =>
    p.newStyle
      ? css`
          display: flex;
          gap: 4px;

          &.pointer {
            cursor: pointer;
          }
        `
      : css`
          font-weight: 500;
          font-size: 0.9rem;
          ${OutfitMedium};
        `}

  ${(p) =>
    p.minWidth &&
    css`
      min-width: ${p.minWidth}px;
    `}

  .icon {
    color: ${(p) => (p.isColor ? p.theme.colors.icon.primary : p.theme.colors.icon.secondary)};
  }
`;

export const CreateCampaignHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const CreateDetailedCampaignHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const CalendarContainer = styled.div<{ timeError?: boolean }>`
  margin-bottom: 22px;
  ${(p) =>
    p.timeError &&
    css`
      margin-bottom: 1px;
    `};
`;

export const DetailedTitleContent = styled.h1`
  margin: 20px 0px;
  font-weight: 400;
`;

export const CalendarWrapper = styled.div`
  display: flex;
  justfy-content: center;
  align-items: center;

  .filterDatePicker .react-datetimerange-picker__wrapper {
    padding: 9px 2px;
    border-radius: 5px;
    border: 1px solid #47475c;
    color: ${(p) => p.theme.colors.text.primary};
    background: ${(p) => p.theme.colors.background.secondary};
  }
  .react-datetimerange-picker {
    border-radius: 5px;
    width: 100%;
  }
  .react-date-picker__inputGroup__divider {
    color: ${(p) => p.theme.colors.text.primary};
  }

  .react-datetimerange-picker__inputGroup__input {
    color: #ffffff !important;
  }
  .react-datetimerange-picker__inputGroup__amPm {
    background-color: ${(p) => p.theme.colors.background.secondary};
  }
  input {
    color: #ffffff !important;
  }

  .react-datetimerange-picker__inputGroup__day {
    color: #ffffff !important;
  }
`;

export const ErrorMsg = styled.label<{ right?: boolean; left?: boolean }>`
  color: ${(p) => p.theme.colors.red.primary};
  font-size: 0.8rem;
  height: 19px;

  ${(p) =>
    p.right &&
    css`
      height: unset;
      position: absolute;
      bottom: -18px;
      right: 3px;
    `};

  ${(p) =>
    p.left &&
    css`
      height: unset;
      position: absolute;
      bottom: -18px;
      left: 3px;
    `};
`;
export const Label = styled.label`
  color: ${(p) => p.theme.colors.icon.secondary};
  text-transform: uppercase;
  font-size: 0.85rem;
  font-family: ${(p) => p.theme.ff.outfit.regular};
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  margin-bottom: -5px;

  .green {
    padding-left: 3px;
    color: ${(p) => p.theme.colors.green.primary};
  }
`;

export const UploadWarning = styled.p`
  ${OutfitRegular};
  font-size: 13px;
  color: #969696;
  margin-bottom: 10px;
`;
export const BudgetWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const SubmitBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  .ml-10 {
    margin-left: 10px;
  }
`;

export const SubmitWrapper = styled.div`
  margin-left: 10px;
`;

export const PaginationWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    list-style-type: none;
    padding: 0px;
    flex-wrap: wrap;
  }

  li {
    list-style: none;
    margin: 3px;
    margin-top: 1rem;
  }

  li a {
    border-radius: 20px;
    padding: 0.4rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border: gray 1px solid;
    text-decoration: none;
  }
  li.active a {
    background-color: ${(p) => p.theme.colors.text.primary};
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
    text-decoration: none;
  }
  li.disable,
  li.disabled a {
    cursor: default;
    text-decoration: none;
  }
  li.selected a {
    cursor: default;
  }
  li.selected a {
    background-color: ${(p) => p.theme.colors.background.secondary};
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
`;

export const DetailsWrapper = styled.div`
  padding-bottom: 2rem;

  .section-left-top {
    justify-content: space-between;
  }

  .statusSection {
    position: relative;
    padding: 5px 25px;
    border: 1px solid ${(p) => p.theme.colors.text.secondary};
    font-weight: 500;
    border-radius: 20px;
    color: ${(p) => p.theme.colors.text.secondary};
    justify-content: center;
    align-items: center;
  }
  .statusSection.active {
    border: 1px solid ${(p) => p.theme.colors.green.primary};
    color: ${(p) => p.theme.colors.green.primary};
  }

  .statusSection::before {
    position: absolute;
    content: "";
    top: 14px;
    left: 11px;
    border-radius: 50%;
    background: ${(p) => p.theme.colors.text.secondary};
    width: 7px;
    height: 7px;
  }
  .statusSection.active::before {
    background: ${(p) => p.theme.colors.green.primary};
  }

  .participants-no {
    font-size: 20px;
    font-weight: 700;
  }

  .width-fit-content {
    width: fit-content;
  }

  .mr-15 {
    margin-right: 15px;
  }
`;

export const CategoryWrapper = styled.div`
  display: flex;

  @media (max-width: 550px) {
    display: block;
  }

  @media (min-width: 991px) and (max-width: 1200px) {
    display: block;
  }
`;

export const CreatorVidosHeaderWrapper = styled.div`
  margin-left: 20px;
  color: ${(p) => p.theme.colors.text.secondary};
  margin-right: 10px;
  cursor: pointer;
`;

export const CreatorVidosHeaderArrow = styled.div`
  color: ${(p) => p.theme.colors.text.secondary};
  margin-right: 10px;
  cursor: pointer;
`;

export const CreatorVidosHeaderTitle = styled.div`
  color: ${(p) => p.theme.colors.text.primary};
`;
