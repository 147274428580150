import { FC, useMemo, useRef } from "react";

import { PatternContainer, IconContainer } from "./style";

import { PatternIconsThin } from "constants/appConstants";

interface Props {
  profile?: boolean;
  mobile?: boolean;
  pattern: {
    icon: string;
    color: string;
    customIconUrl?: string;
  };
  show?: boolean;
}

const Pattern: FC<Props> = ({ profile, pattern, mobile, show = true }) => {
  const ref = useRef<HTMLDivElement>(null);

  const Icon = useMemo(() => {
    if (pattern.customIconUrl) return <img src={pattern.customIconUrl} alt="pattern icon" />;
    return PatternIconsThin.find((item) => item.name === pattern.icon)?.icon;
  }, [pattern]);

  const Matrix = useMemo(
    () =>
      [...Array(225)].map(() => {
        const size = Math.floor(Math.random() * (50 - 30) + 30);
        const rotate = Math.floor(Math.random() * (30 - -30) + -30);
        const left = Math.floor(Math.random() * (94 - size - 0) + 0);
        const top = Math.floor(Math.random() * (94 - size - 0) + 0);
        return {
          size,
          rotate,
          position: { left, top },
        };
      }),
    []
  );

  return (
    <PatternContainer
      show={show}
      ref={ref}
      profile={profile}
      mobile={mobile}
      iconColor={pattern.color}
    >
      {Matrix.map((item, index) => (
        <IconContainer
          key={index}
          profile={profile}
          rotate={item.rotate}
          size={item.size}
          position={item.position}
        >
          {Icon}
        </IconContainer>
      ))}
    </PatternContainer>
  );
};

export default Pattern;
