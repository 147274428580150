import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

import { OutfitRegular } from "styles/mixins";

export const PageContainer = styled.div<{
  gradient?: {
    color1: string;
    color2: string;
    theme: string;
  };
}>`
  min-height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  ${OutfitRegular}
  ${(p) =>
    p.gradient?.color1
      ? css`
          background: linear-gradient(90deg, ${p.gradient.color1} 0%, ${p.gradient.color2} 100%);
          color: ${p.theme.colors.text.primary};
        `
      : css`
          background: ${p.theme.colors.background.primary};
          color: ${p.theme.colors.text.primary};
        `}


  .banner {
    position: relative;
    z-index: 1;
    max-width: 1248px;
    width: 100%;
    padding: 0px 24px;
    display: flex;
    aspect-ratio: 6 / 1;
    justify-content: center;
    position: relative;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      border-radius: 16px;
      object-fit: cover;
    }

    .overlay {
      border-radius: 16px;
      position: absolute;
      top: 0px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
      height: 100%;
      width: calc(100% - 48px);
    }
  }

  ${deviceQuery.laptopM} {
    .banner {
      padding: 0px 20px;
      max-width: 1004px;

      .overlay {
        width: calc(100% - 40px);
      }
    }
  }

  ${deviceQuery.tabletM} {
    .banner {
      margin-top: -60px;
      height: 160px;
      padding: 0px;

      img,
      .overlay {
        border-radius: 0px;
        width: 100%;
      }
    }
  }

  ${deviceQuery.mobileMS} {
    .banner {
      height: 140px;
    }
  }
`;

export const Content = styled.main<{ userId?: boolean }>`
  position: relative;
  padding: 0;
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  z-index: 2;

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1012px;
    padding: 0px 24px;
  }

  ${deviceQuery.laptopM} {
    .content {
      padding: 0px 20px;
      max-width: 1004px;
    }
  }

  ${deviceQuery.tabletM} {
    .content {
      padding: 0px 16px;
      max-width: 996px;
    }
  }
`;
