const LogoLewk = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.82919 0.999756V13.5633L20 19.8222L15.5027 22.6446C14.7589 23.112 13.8335 23.112 13.0897 22.6538L4 17.0364V6.08566C4 5.19677 4.44108 4.37203 5.16757 3.91384L9.82919 0.999756Z"
      fill="#25C374"
    />
    <path d="M12.6919 2.7041V11.7304L20 7.04774L12.6919 2.7041Z" fill="#25C374" />
  </svg>
);

export default LogoLewk;
