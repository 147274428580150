import { env } from "../../public/env";

export const domain = env.REACT_APP_BE_URL;
export const frontendDomain = env.REACT_APP_FE_URL;

export const REACT_APP_GOOGLE_RECAPTCHA_KEY = env.REACT_APP_GOOGLE_RECAPTCHA_KEY;
export const REACT_APP_DSN = env.REACT_APP_DSN;
export const REACT_APP_GOOGLE_CLIENT_ID = env.REACT_APP_GOOGLE_CLIENT_ID;

export const REACT_APP_ARI10_API_BASE_URL = env.REACT_APP_ARI10_API_BASE_URL;
export const REACT_APP_ARI10_WIDGET_ID = env.REACT_APP_ARI10_WIDGET_ID;
export const REACT_APP_ARI10_JS_SDK_URL = env.REACT_APP_ARI10_JS_SDK_URL;
export const REACT_APP_ARI10_BSC_ASSET_ID = env.REACT_APP_ARI10_BSC_ASSET_ID;
export const REACT_APP_ENVIRONMENT = env.NODE_ENV;

const defaultConfig = { apiBaseUrl: `${domain}/api` };

export default defaultConfig;
