import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const GroupedMessagesDateLabelStyles = styled.div`
  padding: 5px 8px;
  font-size: ${(p) => p.theme.typography.subText4};
  color: ${(p) => p.theme.colors.text.primary};
  background-color: ${(p) => p.theme.colors.background.fifth};
  border-radius: 6px;
  width: fit-content;
  margin: 0 auto;
  text-transform: capitalize;

  ${deviceQuery.tabletM} {
    background-color: ${(p) => p.theme.colors.background.secondary};
  }
`;
