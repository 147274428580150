import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const TextMessageStyles = styled.span<{ isYours: boolean }>`
  padding: 7px 12px 9px 12px;
  font-size: ${(p) => p.theme.typography.subText5};
  background-color: ${(p) =>
    p.isYours ? "rgba(37, 195, 116, 0.20)" : p.theme.colors.background.fifth};
  text-align: ${(p) => (p.isYours ? "right" : "left")};
  border-radius: 12px;
  word-break: break-word;
  white-space: pre-wrap;
  width: fit-content;
  margin-left: ${(p) => (p.isYours ? "auto" : "unset")};

  ${deviceQuery.tabletM} {
    background-color: ${(p) =>
      p.isYours ? "rgba(37, 195, 116, 0.20)" : p.theme.colors.background.secondary};
  }
`;
