import { FC } from "react";

interface Props {
  size?: number;
}

const Close: FC<Props> = ({ size = 32 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.49154 6.30005C6.09369 6.70012 6.09369 7.34876 6.49154 7.74883L14.6005 15.9029L6.29839 24.2511C5.90054 24.6512 5.90054 25.2998 6.29839 25.6999C6.69625 26.1 7.3413 26.1 7.73915 25.6999L16.0412 17.3516L24.2609 25.6171C24.6588 26.0172 25.3038 26.0172 25.7017 25.6171C26.0995 25.217 26.0995 24.5684 25.7017 24.1683L17.482 15.9029L25.5085 7.83164C25.9064 7.43157 25.9064 6.78293 25.5085 6.38286C25.1107 5.98279 24.4656 5.98279 24.0678 6.38286L16.0412 14.4541L7.9323 6.30005C7.53445 5.89998 6.8894 5.89998 6.49154 6.30005Z"
      fill="currentColor"
    />
  </svg>
);

export default Close;
