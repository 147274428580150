const MediumDarkColor = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.30859 11.9901C1.30859 9.33006 1.30859 6.67006 1.30859 4.01006C1.30859 2.67006 2.07859 1.57006 3.31859 1.17006C3.63859 1.06006 3.99859 1.01006 4.33859 1.01006C9.64859 1.01006 14.9586 1.00006 20.2686 1.01006C22.0486 1.01006 23.3086 2.28006 23.3086 4.06006C23.3086 9.36006 23.3086 14.6601 23.3086 19.9601C23.3086 21.7401 22.0386 23.0001 20.2686 23.0001C14.9686 23.0001 9.66859 23.0001 4.36859 23.0001C2.57859 23.0001 1.31859 21.7301 1.31859 19.9401C1.31859 17.2901 1.31859 14.6401 1.31859 11.9901H1.30859ZM9.17859 7.88006C6.88859 7.88006 5.03859 9.73006 5.04859 12.0201C5.04859 14.2901 6.89859 16.1301 9.16859 16.1401C11.4486 16.1401 13.3086 14.3001 13.3186 12.0101C13.3186 9.73006 11.4686 7.87006 9.17859 7.88006ZM13.9286 10.4601C13.7486 11.2901 13.7486 12.1501 13.8786 12.9901C13.9186 13.2601 13.9586 13.5301 14.0186 13.7901C14.1586 14.4401 14.4186 15.0501 14.9286 15.5101C15.3686 15.9201 16.0386 15.8801 16.4586 15.4401C16.6886 15.1901 16.9086 14.9001 17.0286 14.5901C17.7286 12.8601 17.7186 11.1101 17.0286 9.38005C16.8586 8.95005 16.5986 8.57006 16.1886 8.30006C15.8086 8.06006 15.3286 8.10006 15.0086 8.41006C14.7386 8.67006 14.4986 8.98005 14.3286 9.31005C14.1386 9.68005 14.0086 10.0601 13.9286 10.4601ZM19.5686 11.7701C19.5386 11.3601 19.5186 10.7301 19.4386 10.1201C19.3686 9.65006 19.2386 9.18006 19.0786 8.74006C18.9686 8.43006 18.7286 8.44006 18.5886 8.74006C18.4886 8.94006 18.3986 9.17006 18.3586 9.39006C18.0186 11.0601 18.0286 12.7501 18.3186 14.4201C18.3686 14.7201 18.4886 15.0201 18.6186 15.2901C18.7286 15.5201 18.9086 15.5101 19.0486 15.2901C19.1386 15.1401 19.2086 14.9701 19.2586 14.8001C19.5186 13.8801 19.5486 12.9301 19.5786 11.7701H19.5686Z"
      fill="black"
    />
    <path
      d="M9.18859 7.87012C11.4786 7.87012 13.3286 9.72011 13.3286 12.0001C13.3286 14.2801 11.4686 16.1301 9.17859 16.1301C6.89859 16.1301 5.05859 14.2801 5.05859 12.0101C5.05859 9.72012 6.90859 7.87012 9.18859 7.87012Z"
      fill="white"
    />
    <path
      d="M13.8886 12.9801C13.7686 12.1401 13.7586 11.2801 13.9386 10.4501C14.0286 10.0501 14.1486 9.67009 14.3386 9.30009C14.5086 8.97009 14.7486 8.66009 15.0186 8.40009C15.3386 8.09009 15.8186 8.05009 16.1986 8.29009C16.6086 8.55009 16.8686 8.93009 17.0386 9.37009C17.7286 11.1101 17.7286 12.8501 17.0386 14.5801C16.9086 14.8901 16.6986 15.1801 16.4686 15.4301C16.0586 15.8701 15.3886 15.9101 14.9386 15.5001C14.4286 15.0301 14.1786 14.4201 14.0286 13.7801C13.9686 13.5101 13.9286 13.2501 13.8886 12.9801Z"
      fill="white"
    />
    <path
      d="M19.5786 11.7701C19.5486 12.9301 19.5186 13.8801 19.2586 14.8001C19.2086 14.9701 19.1386 15.1401 19.0486 15.2901C18.9186 15.5101 18.7386 15.5101 18.6186 15.2901C18.4886 15.0101 18.3686 14.7201 18.3186 14.4201C18.0186 12.7401 18.0186 11.0601 18.3586 9.39006C18.3986 9.17006 18.4886 8.94006 18.5886 8.74006C18.7386 8.45006 18.9686 8.44006 19.0786 8.74006C19.2386 9.19006 19.3686 9.65007 19.4386 10.1201C19.5286 10.7301 19.5386 11.3601 19.5686 11.7701H19.5786Z"
      fill="white"
    />
  </svg>
);

export default MediumDarkColor;
