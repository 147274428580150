import React from "react";

import CirclePlay from "assets/svg/CirclePlay";
import { ImageOutlined } from "assets/svg/ImageOutLined";
import Sound from "assets/svg/Sound";
import {
  ContentType,
  FILE_EXTENSIONS,
  messageSupportedAudioExtensions,
  messageSupportedImageExtensions,
  messageSupportedVideoExtensions,
} from "constants/file-extensions";

/**
 *
 * @param {number} fileSizeInBytes - size of file
 * @returns return mb file size
 */
export const getFileSizeInMb = (fileSizeInBytes: number): number => {
  return fileSizeInBytes / (1024 * 1024);
};

/**
 *
 * @param {string} fileName - file name
 * @param {FILE_EXTENSIONS} extensions - supported file types
 * @returns return boolean value of supported file or not
 */
export const isSupportedExtension = (fileName: string, extensions: FILE_EXTENSIONS[]): boolean => {
  const fileExtension = `.${fileName.split(".").pop()!}` as unknown as FILE_EXTENSIONS;
  return extensions.includes(fileExtension);
};

/**
 *
 * @param {string} fileName - file name
 * @returns return content type of file
 */
export const getMediaTypeFromName = (fileName: string): ContentType | undefined => {
  const fileExtension = `.${fileName
    .split(".")
    .pop()!}`.toLowerCase() as unknown as FILE_EXTENSIONS;
  if (messageSupportedImageExtensions.includes(fileExtension)) return "image";
  if (messageSupportedVideoExtensions.includes(fileExtension)) return "video";
  if (messageSupportedAudioExtensions.includes(fileExtension)) return "audio";
  return undefined;
};

/**
 *
 * @param {ContentType} contentType - content type
 * @param {string} className - icon class name
 * @returns return icon of content type
 */
export const getIconByContentType = (
  contentType: ContentType,
  className?: string
): React.JSX.Element | undefined => {
  if (contentType === "image") return <ImageOutlined className={className} />;
  if (contentType === "video") return <CirclePlay className={className} />;
  if (contentType === "audio") return <Sound className={className} />;
};
