import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store/store";
import { ISocial, IUser, IUserCompetition } from "types/user";

const initialState: IUser | null = null;

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state: Draft<IUser | null>, action) => {
      return {
        ...action.payload,
        is_new: state ? state.is_new : action.payload.is_new,
        languages: action.payload.languages.toString().toLowerCase(),
        pictureUrl: action.payload.pictureUrl_100_100,
        cardBackgroundUrl: action.payload.cardBackgroundUrl_332_112,
      };
    },
    setUserIsNew: (state: Draft<IUser | null>, action: PayloadAction<boolean>) => {
      if (!state) return state;
      state.is_new = action.payload;
    },
    setUserSocials: (state: Draft<IUser | null>, action: PayloadAction<ISocial[]>) => {
      if (!state) return state;
      state.socialmedia = action.payload;
    },
    setSkipMFA: (state: Draft<IUser | null>, action: PayloadAction<boolean>) => {
      if (!state) return state;
      state.mfaSkip = action.payload;
    },
    setCompetition: (state: Draft<IUser | null>, action: PayloadAction<IUserCompetition>) => {
      if (!state) return state;
      state.competition = action.payload;
    },
    editUsername: (state: Draft<IUser | null>, action: PayloadAction<string>) => {
      if (!state) return state;
      state.allowUpdateUsername = false;
      state.username = action.payload;
    },
    removeProfilePicture: (state: Draft<IUser | null>) => {
      if (!state) return state;
      state.pictureUrl = "";
    },
    removeCardBackgroundUrl: (state: Draft<IUser | null>) => {
      if (!state) return state;
      state.cardBackgroundUrl = "";
    },
    resetUser: () => {
      return null;
    },
  },
});

export const {
  setUser,
  setUserIsNew,
  setSkipMFA,
  removeCardBackgroundUrl,
  setUserSocials,
  editUsername,
  resetUser,
  removeProfilePicture,
  setCompetition,
} = userSlice.actions;

export default userSlice.reducer;

export const getUser = ({ global }: RootState): IUser | null => global.user;
