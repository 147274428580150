export enum LANGUAGES {
  english = "english",
  russian = "russian",
  ukrainian = "ukrainian",
}

export enum LANGUAGES_SHIRT {
  en = "en",
  ru = "ru",
  uk = "uk",
}

export const LANGUAGES_TO_I18N = {
  [LANGUAGES.english]: "en",
  [LANGUAGES.russian]: "ru",
  [LANGUAGES.ukrainian]: "uk",
} as const;

export const LANGUAGES_SHIRT_TO_LONG: { [x: string]: string } = {
  [LANGUAGES_SHIRT.en]: LANGUAGES.english,
  [LANGUAGES_SHIRT.ru]: LANGUAGES.russian,
  [LANGUAGES_SHIRT.uk]: LANGUAGES.ukrainian,
} as const;

export const LANGUAGES_SHIRT_TO_I18N = {
  [LANGUAGES_SHIRT.en]: "en",
  [LANGUAGES_SHIRT.ru]: "ru",
  [LANGUAGES_SHIRT.uk]: "uk",
} as const;

export const MOMENT_TO_I18N = {
  [LANGUAGES.english]: "en",
  [LANGUAGES.russian]: "ru",
  [LANGUAGES.ukrainian]: "uk",
} as const;
