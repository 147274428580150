import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const VideoPlayerContainer = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  border-radius: 12px;
  overflow: hidden;
  backdrop-filter: blur(10px) brightness(0.5);
  position: relative;

  ${deviceQuery.mobileL} {
    height: 100%;
  }
`;

export const VideoElement = styled.video`
  width: 100%;
  height: auto;
`;

export const BigButton = styled.button<{ show: boolean }>`
  position: absolute;
  z-index: 2;
  color: ${(p) => p.theme.colors.white.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: ${(p) => p.theme.colors.dark.primary20};
  }

  &:hover {
    opacity: 1;
  }

  ${(p) =>
    p.show
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
`;

export const Controls = styled.div<{ show: boolean }>`
  position: absolute;
  border-radius: 8px;
  z-index: 3;
  bottom: 8px;
  left: 8px;
  right: 8px;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  background: ${(p) => p.theme.colors.dark.primary20};
  padding: 8px 12px;
  transition: all 0.3s;

  .play {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px !important;
    height: 24px;
  }

  &.move,
  &:hover {
    opacity: 1;
  }

  ${(p) =>
    p.show
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0.01;
        `}
`;

export const Poster = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const ExpandedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .setting {
    position: absolute;
    bottom: 86.5px;
    opacity: 0;
    width: 125px;
    pointer-events: none;
    background: ${(p) => p.theme.colors.dark.primary20};
    padding: 4px;
    height: 16px;
    display: flex;
    border-radius: 0px 4px 4px 0px;
    transition: all 0.3s;
    transform: rotate(270deg);

    input[type="range"] {
      border-radius: 4px;
      width: 100%;
      -webkit-appearance: none;
      background: transparent;
      overflow: hidden;

      &:focus {
        outline: none;
      }
      &::-webkit-slider-runnable-track {
        width: 100%;
        cursor: pointer;
        background: ${(p) => p.theme.colors.white.primary};
      }
      &::-webkit-slider-thumb {
        transition: all 0.3s;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: ${(p) => p.theme.colors.white.primary};
        box-shadow: -204px 0 0 200px ${(p) => p.theme.colors.green.primary};
        border: 1px solid ${(p) => p.theme.colors.green.primary};

        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;
      }
      &::-moz-range-track {
        width: 100%;
        height: 8px;
        cursor: pointer;
        background: ${(p) => p.theme.colors.white.primary};
        border-radius: 4px;
      }
      &::-moz-range-thumb {
        transition: all 0.3s;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: ${(p) => p.theme.colors.white.primary};
        box-shadow: -204px 0 0 200px ${(p) => p.theme.colors.green.primary};
        border: 1px solid ${(p) => p.theme.colors.green.primary};
        cursor: pointer;
        appearance: none;
      }
    }

    &.show {
      pointer-events: all;
      opacity: 1;
    }
  }
`;

export const Button = styled.button`
  background: none;
  border: none;
  display: flex;
  color: white;
  align-items: center;
  font-size: 16px;
  padding: 0px;
  cursor: pointer;

  &:hover {
    color: #ddd;
  }
`;

export const Time = styled.span`
  color: white;
  font-size: 14px;
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 4px;
  background: #555;
  cursor: pointer;
  position: relative;
  transition: all 0.3s;

  &:hover {
    height: 8px;
  }
`;

export const ProgressBar = styled.div<{ progress: number }>`
  width: ${(p) => p.progress}%;
  height: 100%;
  background: ${(p) => p.theme.colors.white.primary};
`;

export const ErrorMessage = styled.div`
  color: ${(p) => p.theme.colors.text.error};
  background: ${(p) => p.theme.colors.dark.primary40};
  border: 1px solid ${(p) => p.theme.colors.text.error};
  position: absolute;
  font-size: ${(p) => p.theme.typography.text3};
  top: 0px;
  width: 100%;
  backdrop-filter: blur(10px) brightness(0.5);
  height: 100%;
  left: 0px;
  display: flex;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 12px;
`;
