export enum FILE_EXTENSIONS {
  PNG = ".png",
  JPEG = ".jpeg",
  JPG = ".jpg",
  MP3 = ".mp3",
  MP4 = ".mp4",
  MOV = ".mov",
  AAC = ".aac",
  OGG = ".ogg",
}
export type ContentType = "image" | "video" | "audio";

export const messageContentSupportedExtensions = [
  FILE_EXTENSIONS.PNG,
  FILE_EXTENSIONS.JPEG,
  FILE_EXTENSIONS.JPG,
  FILE_EXTENSIONS.MP3,
  FILE_EXTENSIONS.MP4,
  FILE_EXTENSIONS.MOV,
  FILE_EXTENSIONS.AAC,
  FILE_EXTENSIONS.OGG,
];

export const messageSupportedImageExtensions: FILE_EXTENSIONS[] = [
  FILE_EXTENSIONS.PNG,
  FILE_EXTENSIONS.JPG,
  FILE_EXTENSIONS.JPEG,
];

export const messageSupportedVideoExtensions: FILE_EXTENSIONS[] = [
  FILE_EXTENSIONS.MP4,
  FILE_EXTENSIONS.MOV,
];

export const messageSupportedAudioExtensions: FILE_EXTENSIONS[] = [
  FILE_EXTENSIONS.MP3,
  FILE_EXTENSIONS.AAC,
  FILE_EXTENSIONS.OGG,
];
