import { Modal as RectBootstrapModal } from "react-bootstrap";
import styled, { css } from "styled-components";

import { flexCenter } from "styles/mixins";

export const ModalStyled = styled(RectBootstrapModal)`
  ${(p) => css`
    a {
      &:hover {
        color: ${p.theme.colors.text.secondary};
      }
    }

    &.img-min-width-height .modal-content {
      min-width: 360px;
      min-height: 360px;
    }
    .modal-dialog {
      min-height: 100vh;
      height: fit-content;
      ${flexCenter};
      max-width: 100vw;
      padding: 2rem;
      opacity: ${p?.opacityzero ? "0" : "1"};
      margin: 0;
    }

    .modal-content {
      border: none;
      color: ${p.theme.colors.text.primary};
      border-radius: ${p.borderRadius ? p.borderRadius : "16px"};
      padding: 0;
      background: ${p.theme.colors.background.secondary};
      position: relative;
      height: 100%;
      max-width: ${p.full ? "1300px" : p.size === "sm" ? "400px" : "650px"};

      .modal-body {
        text-align: center;
        width: 100%;
        padding: ${p.padding ? p.padding : "16px"};
        margin: auto;

        .close-button {
          position: absolute;
          top: ${p.closBtnPosition === "top-right-outside" ? "-2.5rem" : "16px"};
          right: ${p.closBtnPosition === "top-right-outside" ? 0 : "16px"};
          overflow: hidden;
          border-radius: 50%;
          text-align: center;
          ${flexCenter};
          width: 24px;
          height: 24px;
          cursor: pointer;
          background: ${p.theme.colors.background.secondary};
          flex-shrink: 0;
          z-index: 1;
        }
      }
    }
  `}
`;
