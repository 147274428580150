import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const BioLinksContainer = styled.div<{ center?: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;

  .content {
    display: flex;
    gap: 20px;
    width: 100%;
    ${(p) =>
      p.center &&
      css`
        justify-content: center;
      `}

    .topic {
      display: flex;
      gap: 12px;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      &.suggest {
        padding: 0px;

        .info {
          display: inline-flex;
          align-items: center;
        }
      }

      .info {
        display: flex;
        gap: 12px;

        .title {
          font-size: ${(p) => p.theme.typography.text3};
        }
      }
    }

    .left {
      flex: 2;
      display: flex;
      flex-direction: column;
      gap: 32px;
      max-width: 684px;
    }

    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .title {
        font-size: ${(p) => p.theme.typography.text3};
        color: ${(p) => p.theme.colors.text.primary};
      }
    }
  }

  ${deviceQuery.tabletM} {
    .content {
      flex-direction: column;
      .topic {
        &.suggest {
          padding-left: 20px;
        }
      }

      .left {
        flex: 1;
        border-right: none;
        max-width: unset;
      }
    }
  }

  ${deviceQuery.tabletM} {
    .content {
      gap: 24px;

      .left {
        gap: 32px;
      }
    }
  }
`;
