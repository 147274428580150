export const DollarCoin = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group">
      <path
        id="Vector"
        d="M9.99976 0.25H9.74976C9.33976 0.26 9.00975 0.61 9.01975 1.02C9.02975 1.43 9.35975 1.75 9.76975 1.75H9.99976C14.5498 1.75 18.2498 5.45 18.2498 10C18.2498 11.73 17.7198 13.39 16.7098 14.8C16.4698 15.14 16.5498 15.6 16.8798 15.85C17.0098 15.94 17.1598 15.99 17.3198 15.99C17.5498 15.99 17.7798 15.88 17.9298 15.68C19.1198 14.02 19.7498 12.06 19.7498 10.01C19.7498 4.63 15.3798 0.259998 9.99976 0.259998V0.25Z"
      />
      <path
        id="Vector_2"
        d="M14.21 17.0901C12.94 17.8501 11.48 18.2501 9.99997 18.2501C9.42997 18.2501 8.85997 18.1901 8.29997 18.0801C7.89997 17.9901 7.49997 18.2601 7.40997 18.6601C7.32997 19.0701 7.58996 19.4601 7.98996 19.5501C8.64996 19.6901 9.31996 19.7601 9.98996 19.7601C11.75 19.7601 13.47 19.2901 14.97 18.3901C15.33 18.1801 15.44 17.7201 15.23 17.3601C15.02 17.0001 14.56 16.8901 14.2 17.1001L14.21 17.0901Z"
      />
      <path
        id="Vector_3"
        d="M3.38006 14.9199C3.13006 14.5899 2.66006 14.5199 2.33006 14.7699C2.00006 15.0199 1.93006 15.4899 2.18006 15.8199C2.58006 16.3599 3.04006 16.8599 3.54006 17.2999C3.68006 17.4299 3.86006 17.4899 4.04006 17.4899C4.25006 17.4899 4.45006 17.3999 4.60006 17.2399C4.87006 16.9299 4.85006 16.4599 4.54006 16.1799C4.12006 15.7999 3.73006 15.3799 3.39006 14.9199H3.38006Z"
      />
      <path
        id="Vector_4"
        d="M1.75 9.79C1.76 9.38 1.43 9.03 1.02 9.02C0.59 9.01 0.26 9.34 0.25 9.75V10C0.25 10.4 0.27 10.79 0.32 11.19C0.37 11.57 0.690001 11.85 1.06 11.85C1.09 11.85 1.12 11.85 1.15 11.85C1.56 11.8 1.85 11.43 1.8 11.02C1.76 10.69 1.74 10.35 1.74 10.01V9.8L1.75 9.79Z"
      />
      <path
        id="Vector_5"
        d="M1.72017 6.39998C1.83017 6.45998 1.96017 6.48998 2.08017 6.48998C2.35017 6.48998 2.60017 6.34998 2.74017 6.09998C2.82017 5.95998 2.90017 5.81998 2.98017 5.67998C3.20017 5.32998 3.09017 4.85998 2.73017 4.64998C2.38017 4.43998 1.92017 4.53998 1.70017 4.89998C1.60017 5.05998 1.51017 5.22998 1.42017 5.38998C1.22017 5.75998 1.36017 6.20998 1.72017 6.40998V6.39998Z"
      />
      <path
        id="Vector_6"
        d="M5.38006 1.41007C5.21006 1.50007 5.05007 1.60008 4.89007 1.70008C4.54007 1.92008 4.43007 2.38007 4.64007 2.73007C4.78007 2.96007 5.03007 3.09007 5.28007 3.09007C5.41007 3.09007 5.55007 3.05007 5.67007 2.98007C5.81007 2.90007 5.94006 2.82008 6.09006 2.74008C6.46006 2.54008 6.59007 2.09008 6.40007 1.72008C6.20007 1.36008 5.75006 1.22007 5.38006 1.41007Z"
      />
      <path
        id="Vector_7"
        d="M9.99992 12.5601C8.95992 12.5601 8.63993 12.2201 8.61993 12.0501C8.61993 11.6401 8.27993 11.3001 7.86993 11.3001C7.45993 11.3001 7.11993 11.6401 7.11993 12.0501C7.11993 12.6601 7.58992 13.7501 9.24992 14.0001V14.3701C9.24992 14.7801 9.58992 15.1201 9.99992 15.1201C10.4099 15.1201 10.7499 14.7801 10.7499 14.3701V13.9901C12.1899 13.7701 13.0799 12.9001 13.0799 11.6401C13.0799 10.7401 12.6799 9.2301 9.99992 9.2301C8.41992 9.2301 8.41992 8.6401 8.41992 8.3201C8.41992 7.4801 9.62992 7.4101 9.99992 7.4101C11.0099 7.4101 11.3499 7.6901 11.3799 7.6601C11.3799 8.0701 11.7199 8.4101 12.1299 8.4101C12.5399 8.4101 12.8799 8.0701 12.8799 7.6601C12.8799 7.6001 12.8499 6.2301 10.7499 5.9601V5.6001C10.7499 5.1901 10.4099 4.8501 9.99992 4.8501C9.58992 4.8501 9.24992 5.1901 9.24992 5.6001V5.9801C7.80992 6.2001 6.91992 7.0701 6.91992 8.3301C6.91992 9.0501 7.21992 10.7401 9.99992 10.7401C11.5799 10.7401 11.5799 11.2901 11.5799 11.6501C11.5799 12.4901 10.3699 12.5601 9.99992 12.5601Z"
      />
    </g>
  </svg>
);
