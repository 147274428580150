const tokens = {
  shirtUsdt: "usdt",
  shirtUsdc: "usdc",
  shirtPyusd: "pyusd",
  LEWK: "LEWK_BSC_TEST",
  USDT: "USDT_BSC_TEST",
  USDT_BSC: "USDT_BSC",
  USDC: "USDC_BSC_TEST",
  BUSD: "BUSD_BSC_TEST",
  ETH: "ETH_TEST5",
  TRX: "TRX_USDC_SKL5",
  TRX_PROD: "TRX_USDT_S2UZ",
  USDT_ETH: "USDT_ETH_TEST5_JBUH",
  USDT_ETH_2: "USDT_ETH_TEST5_LHH8",
  PYUSD_ETH: "PYUSD_ETH",
  USDT_ERC20: "USDT_ERC20",
  FAKET: "FAKET",
};

export const currency: { [x: string]: string } = {
  [tokens.LEWK]: "LEWK",
  [tokens.USDT]: "USDT (BSC)",
  [tokens.USDC]: "USDC",
  [tokens.BUSD]: "BUSD",
  [tokens.ETH]: "ETH",
  [tokens.TRX]: "USDT (TRC20)",
  [tokens.USDT_ETH]: "USDT (ERC20)",
  [tokens.USDT_ETH_2]: "USDT (ERC20)",
  [tokens.FAKET]: "FAKET",
};

export const shortToken: { [x: string]: string } = {
  [tokens.TRX]: "USDT",
  [tokens.TRX_PROD]: "USDT",
  [tokens.USDT]: "USDT",
  [tokens.USDT_BSC]: "USDT",
  [tokens.USDT_ETH]: "USDT",
  [tokens.USDT_ETH_2]: "USDT",
  [tokens.USDT_ERC20]: "USDT",
  [tokens.PYUSD_ETH]: "PYUSD",

  [tokens.shirtUsdt]: "USDT",
  [tokens.shirtUsdc]: "USDC",
  [tokens.shirtPyusd]: "PYUSD",
};

export default tokens;
