import { FC } from "react";

import { Loader, StyledLoader } from "./style";

interface Props {
  color: string;
  vertical: boolean;
  size: string;
  label: string;
  nomargin: boolean;
}

// Styled mini loader component
const MiniLoader: FC<Partial<Props>> = ({
  color,
  vertical = false,
  size,
  label = null,
  nomargin,
}) => (
  <StyledLoader nomargin={nomargin} vertical={vertical}>
    <Loader color={color} size={size} />
    {label && <span className="text">{label}</span>}
  </StyledLoader>
);
export default MiniLoader;
