import styled from "styled-components";

export const SocialContentLinkContainer = styled.div<{ hasTopRadius: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${(p) => p.theme.colors.text.secondary};
  font-size: ${(p) => p.theme.typography.subText5};
  cursor: pointer;

  border-radius: ${(p) => (p.hasTopRadius ? "12px" : "0 0 12px 12px")};
  padding: 12px;
  border: 1px solid ${(p) => p.theme.colors.divider};
`;
