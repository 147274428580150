import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const BioBlockContainer = styled.div`
  display: flex;
  background: ${(p) => p.theme.colors.background.secondary};
  border-radius: 16px;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);

  .previewContainer {
    display: flex;
    justify-content: center;
    flex: 1;
    border-right: 1px solid ${(p) => p.theme.colors.divider};
    min-width: 332px;
    max-width: 332px;

    .preview {
      overflow: hidden;
      padding: 40px 16px 0px;
      position: relative;
      max-height: 280px;

      .banner {
        background: ${(p) => p.theme.colors.background.secondary};
        position: absolute;
        object-fit: cover;
        z-index: 4;
        top: 85px;
        left: 25px;
        width: 221px;
        height: 83px;
      }

      .owl {
        z-index: 5;
        position: absolute;
        top: 150px;
        left: 33px;

        .avatar {
          width: 62px;
          height: 62px;
        }
      }

      img {
        position: relative;
        z-index: 3;

        &.overlay {
          position: absolute;
          right: 30px;
          width: 240px;
        }
      }
    }
  }

  .bio {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 16px 16px 20px;

    .mobile {
      display: none;
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title {
        font-size: ${(p) => p.theme.typography.text3};
      }
      .description {
        font-size: ${(p) => p.theme.typography.subText5};
        color: ${(p) => p.theme.colors.text.secondary};
      }
    }

    .blockActions {
      display: flex;
      justify-content: space-between;
      gap: 16px;

      .buttons {
        display: flex;
        gap: 16px;

        button {
          min-width: 112px;
        }
      }
    }
  }

  ${deviceQuery.tabletM} {
    flex-direction: column;

    .bio {
      padding: 16px;
    }

    .previewContainer {
      border-right: none;
      border-bottom: 1px solid ${(p) => p.theme.colors.divider};
      min-width: unset;
      max-width: unset;

      .preview {
        padding: 40px 40px 0px;

        .banner {
          left: 49px;
        }

        .owl {
          left: 57px;
        }
      }
    }
  }

  ${deviceQuery.mobileM} {
    .previewContainer {
      display: none;
    }
    .bio {
      .mobile {
        display: flex;
        width: 100%;

        .banner {
          object-fit: cover;
          width: 100%;
          height: 80px;
          border-radius: 12px;
        }
        .wallpaper {
          width: 100%;
          height: 80px;
          border-radius: 12px;
          position: relative;
          overflow: hidden;
        }
      }
      .blockActions {
        gap: 8px;
        flex-direction: column;

        .icon {
          display: none;
        }
        button {
          min-width: 86px;
          width: 100%;
        }
        .buttons {
          gap: 8px;
          flex-direction: column;
        }
      }
    }
  }
`;

export const Color = styled.div<{
  gradient?: {
    color1: string;
    color2: string;
    theme: string;
  } | null;
}>`
  position: absolute;
  width: calc(100% - 36px);
  aspect-ratio: 1 / 1;
  bottom: 0px;
  border-radius: 40px 40px 0px 0px;
  z-index: 2;
  overflow: hidden;

  ${(p) =>
    p.gradient?.color1
      ? css`
          background: linear-gradient(90deg, ${p.gradient.color1} 0%, ${p.gradient.color2} 100%);
        `
      : css`
          background: ${p.theme.colors.background.primary};
        `}

  ${deviceQuery.tabletM} {
    width: calc(100% - 85px);
    left: 42px;
  }

  ${deviceQuery.mobileM} {
    height: 80px;
    border-radius: 0px;
    position: static;
    width: 100%;
  }
`;
