const TwitterDarkColor = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5781 23C18.6533 23 23.5781 18.0751 23.5781 12C23.5781 5.92487 18.6533 1 12.5781 1C6.50299 1 1.57812 5.92487 1.57812 12C1.57812 18.0751 6.50299 23 12.5781 23Z"
      fill="black"
    />
    <path
      d="M14.4581 12L14.3281 11.82L13.7881 11.1L16.5581 8.14998L18.2281 6.35999H17.0481L15.6681 7.82999L13.2581 10.4L12.9081 9.92998L10.2381 6.35999H6.6181L11.2081 12.48L11.2581 12.55L6.4881 17.64H7.6681L11.7781 13.25L15.0681 17.64H18.6881L14.4581 12ZM12.3681 12.61L11.7981 11.85L11.4681 11.41L11.3481 11.24L8.3281 7.20998H9.7981L12.4781 10.78L13.1881 11.72L16.9681 16.76H15.4981L12.3781 12.59L12.3681 12.61Z"
      fill="white"
    />
  </svg>
);

export default TwitterDarkColor;
