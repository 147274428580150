import { FC } from "react";

import { getHighlightedSearchedText } from "common/helpers/highlight-searched-text";
import { TextMessageStyles } from "pages/public/chat/Components/Chat/TextMessage/styles";

interface Props {
  isYours: boolean;
  text: string;
  search: string;
}

export const TextMessage: FC<Props> = ({ isYours, text, search }) => (
  <TextMessageStyles isYours={isYours}>
    {getHighlightedSearchedText(text, search)}
  </TextMessageStyles>
);
