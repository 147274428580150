import styled from "styled-components";

export const SwitchContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
`;

export const CircleContainer = styled.div<{
  state: boolean;
  disabled?: boolean;
}>`
  background: ${(p) => (p.state ? p.theme.colors.green.primary : p.theme.colors.green.primary20)};
  border-radius: 14px;
  width: 50px;
  padding: 4px 0px;
  cursor: ${(p) => (p.disabled ? "no-drop" : "pointer")};
  position: relative;
  transition: 0.1s linear;
  min-width: 50px;

  .toolTip {
    display: none;
    position: absolute;
    top: -28px;
    left: -44px;
    font-size: 12px;
    background: ${(p) => p.theme.colors.background.primary};
    color: ${(p) => p.theme.colors.text.primary};
    padding: 4px;
    border: 1px solid ${(p) => p.theme.colors.text.primary};
    z-index: 9;
  }

  &:hover .toolTip {
    display: inline-block;
  }
`;

export const Circle = styled.div<{
  state: boolean;
}>`
  width: 20px;
  height: 20px;
  background: ${(p) => p.theme.colors.white.primary};
  border-radius: 50%;
  transition: 0.1s linear;
  transform: ${(p) => (p.state ? "translateX(26px)" : "translateX(4px)")};
`;

export const Label = styled.label`
  color: ${(p) => p.theme.colors.text.primary};
  font-size: ${(p) => p.theme.typography.subText5};
  margin-top: 3px;

  .green {
    color: ${(p) => p.theme.colors.green.primary};
  }
`;
