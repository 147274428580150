import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

/**
 * tracks an event Google Analytics 4
 *
 * @param {string} category category of the event
 * @param {string} action action performed by the user
 */
const TrackGoogleAnalyticsEvent = (category: string, action: string) => {
  // Send UA Event
  ReactGA.event({
    category,
    action,
  });
  // Send GA4 Event
  ReactGA4.event({
    category,
    action,
  });
};

export default TrackGoogleAnalyticsEvent;
