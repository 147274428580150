import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store/store";
import { getChatMessagesByChatId } from "store/thunks/messages-thunks";
import { NewMessageItem } from "types/new-messages.types";

export const MESSAGES_LIMIT = 50;

export interface ExtraState {
  loading: boolean;
  contentUploadProgress: number;
  canLoadMorePrev: boolean;
  canLoadMoreNext: boolean;
  searchMessageId: null | string;
  repliedMessage: null | NewMessageItem;
}

const messagesAdapter = createEntityAdapter({
  selectId: (message: NewMessageItem) => message._id,
  sortComparer: (a, b) => {
    if (a.createdAt > b.createdAt) {
      return 1;
    }
    if (a.createdAt < b.createdAt) {
      return -1;
    }
    return 0;
  },
});

export const messagesSlice = createSlice({
  name: "newMessages",
  initialState: messagesAdapter.getInitialState<ExtraState>({
    loading: false,
    contentUploadProgress: 0,
    canLoadMorePrev: false,
    canLoadMoreNext: false,
    searchMessageId: "",
    repliedMessage: null,
  }),

  reducers: {
    addMessage: (state, action: PayloadAction<NewMessageItem>) => {
      messagesAdapter.addOne(state, action.payload);
      // have to clear replyMessage after message is sent
      state.repliedMessage = null;
    },
    addMessages: messagesAdapter.addMany,
    removeAllMessages: messagesAdapter.removeAll,
    updateOneMessage: messagesAdapter.updateOne,

    contentUploadProgressChanged(state, action: PayloadAction<number>) {
      state.contentUploadProgress = action.payload;
    },

    setRepliedMessage(state, action: PayloadAction<NewMessageItem | null>) {
      state.repliedMessage = action.payload;
    },

    setSearchedMessageId(state, action: PayloadAction<string>) {
      state.searchMessageId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChatMessagesByChatId.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getChatMessagesByChatId.fulfilled, (state, { payload }) => {
      state.loading = false;
      const { data, requestData } = payload;

      // all last messages loaded only scroll to the previous messages
      if (!requestData.prevOffsetId && !requestData.nextOffsetId && !requestData.aroundOffsetId) {
        state.canLoadMorePrev = MESSAGES_LIMIT === data.length;
      }

      if (requestData.prevOffsetId) {
        state.canLoadMorePrev = MESSAGES_LIMIT === data.length;
      }

      if (requestData.nextOffsetId) {
        state.canLoadMoreNext = MESSAGES_LIMIT === data.length;
      }

      if (requestData.aroundOffsetId) {
        state.searchMessageId = requestData.aroundOffsetId;
        state.canLoadMorePrev = true;
        state.canLoadMoreNext = true;
      }

      messagesAdapter.addMany(state, data);
    });

    builder.addCase(getChatMessagesByChatId.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  addMessages,
  addMessage,
  updateOneMessage,
  removeAllMessages,
  contentUploadProgressChanged,
  setRepliedMessage,
  setSearchedMessageId,
} = messagesSlice.actions;

export const messagesSelectors = messagesAdapter.getSelectors(
  (state: RootState) => state.newMessages
);

export const selectMessagesLoading = ({ newMessages }: RootState): boolean => newMessages.loading;

export const selectCanLoadMorePrev = ({ newMessages }: RootState): boolean =>
  newMessages.canLoadMorePrev;

export const selectCanLoadMoreNext = ({ newMessages }: RootState): boolean =>
  newMessages.canLoadMoreNext;

export const selectSearchMessageId = ({ newMessages }: RootState): string | null =>
  newMessages.searchMessageId;

export const selectContentUploadProgress = ({ newMessages }: RootState): number =>
  newMessages.contentUploadProgress;

export const selectRepliedMessage = ({ newMessages }: RootState): null | NewMessageItem =>
  newMessages.repliedMessage;
