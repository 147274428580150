import styled from "styled-components";

import Modal from "Components/Modal/Modal";
import { deviceQuery } from "styles/mediaSizes";

export const AddMessageContentContainerModalStyles = styled(Modal)`
  font-family: ${(p) => p.theme.ff.outfit.regular};
  .modal-content {
    max-width: 510px;
    border-radius: 20px;
    background: ${(p) => p.theme.colors.background.secondary};

    .modal-body {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;

      .close-button {
        background-color: unset;
      }

      .title {
        text-align: start;
        font-size: ${(p) => p.theme.typography.text3};
      }
    }
  }

  .divider {
    background-color: ${(p) => p.theme.colors.divider};
    height: 1px;
    width: calc(100% + 32px);
    margin: 0 -16px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .content_preview_block {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 16px;
    width: 100%;
  }

  .content_preview_description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
    text-align: start;
    span {
      color: ${(p) => p.theme.colors.text.secondary};
      font-size: ${(p) => p.theme.typography.subText3};
    }
  }

  .uploaded_content_list {
    width: 100%;
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, 1fr);
  }

  .add_new_content_btn {
    border-radius: 12px;
    background-color: ${(p) => p.theme.colors.background.fifth};
    width: 100%;
    height: 119px;
  }

  .error_message {
    color: ${(p) => p.theme.colors.text.error};
  }

  ${deviceQuery.tabletM} {
    .uploaded_content_list {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  ${deviceQuery.mobileM} {
    .content_preview_block {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;
