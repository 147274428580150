import React, { Dispatch, SetStateAction } from "react";

import { Option } from "Components/SelectField/types";
import { STRINGS, EARNINGREPORT } from "constants/appConstants";
import { EMAIL_REGEX, URLRegex, YOUTUBE_VIDEO_LINK } from "constants/regex";
import tokens, { currency } from "constants/tokens";
import { resetAdminSlice } from "store/slices/admin/adminSlice";
import { resetCreatorSlice } from "store/slices/creator/creatorSlice";
import {
  createToast,
  deleteToast,
  hideToast,
  resetFeatureSlice,
  setFullScreenLoader,
  showToast,
} from "store/slices/featureSlice";
import { resetGlobalSlice } from "store/slices/global/globalSlice";
import { resetUser } from "store/slices/global/userSlice";
import { resetUserVisitedPagesSlice } from "store/slices/userVisitedPagesSlice";
import { resetWallet } from "store/slices/walletSlice";
import store from "store/store";
import { BROWSER, IErrorState } from "types/common.types";
import { IEarningType } from "types/creator";
import { IAvailableCurrencies } from "types/currencies";

/**
 * clears data from various Redux slices to reset the application state
 */
export const clearSlicesData = () => {
  store.dispatch(resetAdminSlice());
  store.dispatch(resetCreatorSlice());
  store.dispatch(resetFeatureSlice());
  store.dispatch(resetGlobalSlice());
  store.dispatch(resetWallet());
  store.dispatch(resetUser());
  store.dispatch(resetUserVisitedPagesSlice());
};

/**
 * detects and returns the name of the browser based on the user agent string
 * @returns {BROWSER} return name of the browser
 */
export const getBrowserInfo = (): BROWSER => {
  const userAgent = navigator.userAgent;

  if (/chrome|crios|crmo/i.test(userAgent)) {
    return BROWSER.CHROME;
  }
  if (/firefox|fxios/i.test(userAgent)) {
    return BROWSER.FIREFOX;
  }
  if (/safari/i.test(userAgent) && !/chrome|crios|crmo/i.test(userAgent)) {
    if (/iphone|ipad|ipod/i.test(userAgent)) {
      return BROWSER.SAFARI_IOS;
    }
    return BROWSER.SAFARI;
  }
  if (/opr|opera/i.test(userAgent)) {
    return BROWSER.OPERA;
  }
  if (/msie|trident/i.test(userAgent)) {
    return BROWSER.INTERNET_EXPLORER;
  }
  if (/edg/i.test(userAgent)) {
    return BROWSER.EDGE;
  }

  return BROWSER.UNKNOWN;
};

/**
 * emits registration events for Facebook, Twitter, Google Analytics, and TikTok
 * tracks user registration for analytics purposes
 */
export const emitAnalyticsRegisterEvent = () => {
  // eslint-disable-next-line no-undef
  fbq("track", "CompleteRegistration");
  // eslint-disable-next-line no-undef
  twq("event", "tw-o8xab-ojl5q", {});
  (window as any).dataLayer.push({ event: "CompleteRegistration" });
  (window as any).gtag("event", "CompleteRegistration");
  (window as any).ttq.track("CompleteRegistration");
};

/**
 * converts a data URL to a File object
 * @param {string} dataurl - base64 data URL to convert
 * @param {string} filename - name for the resulting file
 * @returns {File} resulting File object
 */
export const dataURLtoFile = (dataurl: string, filename: string): File => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  if (!mime) {
    throw new Error("Invalid data URL");
  }

  return new File([u8arr], filename, { type: mime });
};

/**
 * validates a URL against a regular expression
 * @param {string} url - URL to validate
 * @returns {boolean} true if the URL is valid, false otherwise
 */
export const isURLValid = (url: string) => URLRegex.test(url);

/**
 * start full screen loader
 */
export const startFullScreenLoader = () => store.dispatch(setFullScreenLoader(true));

/**
 * stop full screen loader
 */
export const stopFullScreenLoader = () => store.dispatch(setFullScreenLoader(false));

/**
 * converts an array of strings into an array of select options for select
 * @param {string[]} arr - array of strings to convert
 * @returns {Option[]} resulting array of select options
 */
export const convertArrForReactSelect = (arr: string[]) =>
  arr.map((item) => ({
    value: item,
    label: item,
  }));

/**
 * converts an array of objects with label and value properties into select options for select
 * @param {Array<{ label: string; value: string }>} arr - array of objects to convert
 * @returns {Option[]} resulting array of select options
 */
export const convertLabelandValArrForReactSelect = (
  arr: {
    label: string;
    value: string;
  }[]
) =>
  arr &&
  arr.map((item) => ({
    value: item.value,
    label: item.label,
  }));

/**
 * creates and displays a toast notification with the specified message, duration, and type
 * @param {string} message - message in toast
 * @param {number} duration - duration of toast
 * @param {string} type - type of toast
 */
export const makeToast = ({
  message,
  duration = 5000,
  type,
}: {
  message?: string;
  duration?: number;
  type: string;
}) => {
  const id = Date.now();

  if (!store.getState().feature.toast.find((item) => item.message === message)) {
    store.dispatch(createToast({ id, message, type }));

    setTimeout(() => {
      store.dispatch(showToast(id));
    }, 0);

    setTimeout(() => {
      store.dispatch(hideToast(id));
    }, duration);

    setTimeout(() => {
      store.dispatch(deleteToast(id));
    }, duration + 150);
  }
};

export const setAndRemoveErr = ({
  setErr,
  err,
  msg,
  name,
}: {
  setErr: Dispatch<SetStateAction<IErrorState>>;
  err: IErrorState;
  msg: string;
  name: string;
}) => {
  setErr((oldVal) => ({ ...oldVal, [name]: msg }));
  setTimeout(
    () =>
      setErr((oldVal) => {
        const obj: IErrorState = err;
        for (const prop in oldVal) {
          obj[prop] = "";
        }
        return obj;
      }),
    4000
  );
};

/**
 * shortens a string if it exceeds the specified length, appending ellipsis ("...")
 * @param {string} string - string to shorten
 * @param {number} length - maximum allowed length of the string
 * @returns return shortened string with ellipsis if applicable
 */
export const substringLongWord = ({ string, length }: { string: string; length: number }) => {
  if (!string) return "";
  return string.length > length ? `${string.substring(0, length)}...` : string;
};

/**
 * calculates the number of days between the provided date and the current date
 * @param {string} time - date to compare with the current date
 * @returns number of days between the provided date and now
 */
export const getDaysTillNow = ({ time }: { time: string }) => {
  const date = new Date(time);
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate.valueOf() - date.valueOf());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

/**
 * scroll to top of page
 */
export const scrollTop = () => window.scrollTo(0, 0);

/**
 * converts a string into a select option object for select
 * @param {string} data - data to option
 * @returns {Option} select option object
 */
export const convertValForReactSelect = (data: string) => ({
  value: data,
  label: data,
});

/**
 * converts a label and value pair into a select option object for select
 * @param {string} label - label for option
 * @param {string} value - value for option
 * @returns {Option} return select option
 */
export const convertValAndLabelReactSelect = (label: string, value: string) => ({
  value,
  label,
});

/**
 * validates an email address against a regular expression
 * @param {string} email - email address to validate
 * @returns {boolean} true if the email is valid, false otherwise
 */
export const validateEmail = ({ email }: { email: string }) =>
  String(email).toLowerCase().match(EMAIL_REGEX);

/**
 * extracts the video ID from a youtube URL
 * @param {string} url - youtube URL to extract the video id from
 * @returns {string | undefined} return youtube video id
 */
export const getYoutubeVideoIdFromLink = (url: string) => {
  const match = url.match(YOUTUBE_VIDEO_LINK);
  return match && match[7].length === 11 ? match[7] : "";
};

/**
 * validates if the given URL is a shortened tiktok URL
 * @param {string} url - tiktok URL to validate
 * @returns {boolean} true if the URL matches the shortened tiktok url pattern, false otherwise
 */
export const validateIfShortenedTiktokUrl = (url: string) => {
  const shortenedTiktokUrlRegexp = /^https:\/\/vm\.tiktok\.com\/[a-zA-Z0-9]{9}\/?$/;
  return shortenedTiktokUrlRegexp.test(url);
};

/**
 * extracts the tiktok video id from a valid tiktok url
 * @param {string} url - tiktok url
 * @returns {string} extracted tiktok video id, or an empty string if the url is invalid
 */
export const validateAndExtractTikTokID = (url: string) => {
  const tikTokUrlPattern = /^https:\/\/www\.tiktok\.com\/@[\w.-]+\/video\/(\d+)/;
  const match = url.match(tikTokUrlPattern);

  if (match) {
    return match[1].length === 19 ? match[1] : ""; // Returns the ID part of the URL
  }
  return ""; // Indicates the URL is not a valid TikTok URL
};

/**
 * extracts the instagram post id from a valid instagram url
 * @param {string} url - instagram url
 * @returns {string} extracts the instagram post id from a valid Instagram url
 */
export const validateAndExtractInstagramPostID = (url: string) => {
  const instagramUrlPattern = /^https:\/\/www\.instagram\.com\/p\/([\w-]+)\/?/;
  const match = url.match(instagramUrlPattern);

  if (match) {
    const postID = match[1] || "";
    return postID.length === 11 ? postID : "";
  }
  return ""; // Indicates the URL is not a valid Instagram post url
};

/**
 * validates if the given url is a valid facebook post url
 * @param {string} url - facebook url
 * @returns {boolean} true if the URL matches the facebook post url pattern, false otherwise
 */
export const validateFaceBookPostUrl = (url: string) => {
  const facebookUrlRegex =
    /^https:\/\/www\.facebook\.com\/(?:[a-zA-Z0-9.]+\/)?posts\/pfbid\d+|https:\/\/www\.facebook\.com\/\d+\/posts\/\d+$/;

  return facebookUrlRegex.test(url);
};

/**
 * validates if the given url is a valid facebook reel url
 * @param {string} url - facebook reel url
 * @returns {boolean} true if the URL matches the Facebook reel URL pattern, false otherwise
 */
export const validateFaceBookReelUrl = (url: string) => {
  const facebookUrlPattern = /^https:\/\/www\.facebook\.com\/reel\/([\w-]+)\/?/;
  return facebookUrlPattern.test(url);
};

/**
 * validates if the given url is a shortened facebook url
 * @param {string} url - facebook url
 * @returns {boolean} true if the url matches the shortened аacebook url pattern, false otherwise
 */
export const validateIfShortenedFaceBookUrl = (url: string) => {
  const shortenedTiktokUrlRegexp =
    /^https:\/\/www\.facebook\.com\/share\/[a-zA-Z]\/[A-Za-z0-9]{16}\/?/;
  return shortenedTiktokUrlRegexp.test(url);
};

/**
 * Extracts the instagram reel id from a valid instagram reel url
 * @param {string} url - instagram reel url
 * @returns extracted instagram reel ud, or an empty string if the url is invalid
 */
export const validateAndExtractReelPostID = (url: string) => {
  const instagramUrlPattern = /^https:\/\/www\.instagram\.com\/reel\/([\w-]+)\/?/;
  const match = url.match(instagramUrlPattern);

  if (match) {
    const postID = match[1] || "";
    return postID.length === 11 ? postID : "";
  }
  return ""; // Indicates the URL is not a valid Instagram reel URL
};

/**
 * copy text to clipboard
 */
export const copyToClipboard = async (onSuccess: () => void, onError: () => void, text = "") => {
  try {
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      await navigator.clipboard.writeText(text);
      makeToast({ message: "Copied ", type: STRINGS.toastType.success });
      onSuccess && onSuccess();
    } else {
      // text area method
      const textArea = document.createElement("textarea");
      textArea.value = text;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand("copy") && onSuccess && onSuccess();
      textArea.remove();
      makeToast({ message: "Copied ", type: STRINGS.toastType.success });
    }
  } catch (error) {
    onError && onError();
  }
};

/**
 * function for download video
 * @param {string} fileName - file name
 * @param {string} fileUrl - file url
 */
export const download = async (fileName: string, fileUrl: string): Promise<void> => {
  const a = document.createElement("a");
  a.href = fileUrl;
  a.setAttribute("download", fileName);
  a.click();
};

/**
 * returns the token name corresponding to the given asset id
 * @param {string} assetId - asset id
 * @returns return token name
 */
export const getToken = (assetId: string) => {
  switch (assetId) {
    case tokens.LEWK:
      return "LEWK";
    case tokens.BUSD:
      return "BUSD";
    case tokens.USDC:
      return "USDC";
    case tokens.USDT:
      return "USDT";
    case tokens.ETH:
      return "ETH";
    case tokens.TRX:
      return "TRX";
    case tokens.USDT_ETH:
      return "USDT_ETH";
    case tokens.FAKET:
      return "FAKET";
    default:
      break;
  }
};

/**
 * converts a base64 data URI into a File object
 * @param {string} base64Uri - file in base 64 format
 * @returns {File} created File object
 */
export const createFileFromBase64 = async (base64Uri: string): Promise<File> => {
  const createdFile = await fetch(base64Uri)
    .then((res) => res.blob())
    .then((blob) => {
      const file = new File([blob], "thumbnail.png", { type: "image/png" });
      return file;
    });
  return createdFile;
};

/**
 * captures a screenshot from a video element
 * @param {string} videoRef - ref to video element
 * @returns data url of the screenshot
 */
export const getScreenShot = async (
  videoRef: React.RefObject<HTMLVideoElement>
): Promise<string> => {
  if (!videoRef.current) {
    throw new Error("Video reference is not valid");
  }

  const canvas = document.createElement("canvas");
  // get the width and height of snapshot
  const ratio = videoRef.current.videoWidth / videoRef.current.videoHeight;
  const myWidth = videoRef.current.videoWidth - 100;
  const myHeight = parseInt((myWidth / ratio).toString(), 10);
  canvas.width = myWidth;
  canvas.height = myHeight;

  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("Failed to get 2D context");
  }

  ctx.fillRect(0, 0, myWidth, myHeight);
  ctx.drawImage(videoRef.current, 0, 0, myWidth, myHeight);

  return canvas.toDataURL("image/png", 0.5);
};

/**
 * converts an array of enabled tokens into a list of select options
 * @param {IAvailableCurrencies} enabledTokens - enabled tokens array
 * @returns return currency select options
 */
export const setTokenvalues = (enabledTokens: IAvailableCurrencies) => {
  if (enabledTokens && enabledTokens?.reference_value) {
    const tokenArray: Option[] = [];
    enabledTokens?.reference_value?.map((singleToken: string) => {
      const tokenRow = {
        label: currency[singleToken],
        value: singleToken,
      };
      tokenArray.push(tokenRow);
    });
    return tokenArray;
  }
};

/**
 * localizes the earning report by translating the category names
 * @param {IEarningType} earningReport - earning report array
 * @returns translation for earning report category name
 */
export const localizeEarningReport = (earningReport: IEarningType[]) => {
  return earningReport.map((val) => {
    switch (val.type.toLowerCase()) {
      case EARNINGREPORT.GIFT.toLowerCase():
        return { ...val, title: "creator.dashboard.gift" };
      case EARNINGREPORT.SUBSCRIPTION.toLowerCase():
        return { ...val, title: "creator.dashboard.subscription" };
      case EARNINGREPORT.CHALLENGE_REWARD.toLowerCase():
        return { ...val, title: "creator.dashboard.trend_reward" };
      case EARNINGREPORT.PAID_MESSAGE.toLowerCase():
        return { ...val, title: "creator.dashboard.paidMessage" };
      default:
        return val;
    }
  });
};
