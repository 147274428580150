import { FC } from "react";

interface Props {
  size?: number;
  className?: string;
}

const Infinite: FC<Props> = ({ size = 24, className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="currentColor"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.9098 7.25C14.1998 7.25 12.9098 8.81 11.9498 10.51C10.8398 8.82 9.45977 7.25 7.09977 7.25C4.42977 7.25 2.25977 9.38 2.25977 12C2.25977 14.62 4.42977 16.75 7.09977 16.75C9.76977 16.75 11.0998 15.19 12.0598 13.49C13.1698 15.18 14.5498 16.75 16.9098 16.75C19.5798 16.75 21.7498 14.62 21.7498 12C21.7498 9.38 19.5798 7.25 16.9098 7.25ZM7.08977 15.25C5.24977 15.25 3.74977 13.79 3.74977 12C3.74977 10.21 5.24977 8.75 7.08977 8.75C8.92977 8.75 10.0298 10.24 11.1398 12.03C10.2298 13.79 9.33977 15.25 7.08977 15.25ZM16.9098 15.25C14.9998 15.25 13.9698 13.76 12.8598 11.97C13.7698 10.21 14.6598 8.75 16.9098 8.75C18.7498 8.75 20.2498 10.21 20.2498 12C20.2498 13.79 18.7498 15.25 16.9098 15.25Z" />
  </svg>
);

export default Infinite;
