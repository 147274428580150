import { useFormikContext } from "formik";
import { FC, useEffect } from "react";

import { ILoginFormikData } from "types/auth-types";

interface Props {
  valuesChanged: (values: ILoginFormikData) => void;
}

export const LoginFormValues: FC<Props> = ({ valuesChanged }) => {
  const { values } = useFormikContext<ILoginFormikData>();

  useEffect(() => {
    valuesChanged(values);
  }, [values]);
  return null;
};

export const Validator = () => {
  const { values, validateForm } = useFormikContext();
  useEffect(() => {
    validateForm(values);
  }, [values]);
  return null;
};
