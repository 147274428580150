const BioIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_17037_57752)">
      <path
        d="M4.54818 15.993C3.13919 15.993 2 14.8536 2 13.4443V4.54873C2 3.13943 3.13919 2 4.54818 2H13.4518C14.8608 2 16 3.13943 16 4.54873V13.4543C16 14.8636 14.8608 16.003 13.4518 16.003"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4518 8C20.8608 8 22 9.13943 22 10.5487V19.4543C22 20.8636 20.8608 22.003 19.4518 22.003H10.5482C9.13919 22.003 8 20.8636 8 19.4543V10.5487C8 9.13943 9.13919 8 10.5482 8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_17037_57752">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BioIcon;
