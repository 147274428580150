import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 16px;
  gap: 20px;
  position: relative;
  flex: 1;

  .pageTitle {
    position: absolute;
    top: 54px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    font-size: ${(p) => p.theme.typography.text3};
  }

  .bannerActions {
    position: absolute;
    bottom: 40px;
    z-index: 6;
    display: flex;
    bottom: 40px;
    gap: 16px;

    button {
      width: 160px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    height: calc(100vh - 450px);
    max-height: 404px;
    overflow: visible;

    .preview {
      width: 100%;
      padding: 0px;
      display: flex;
      justify-content: center;
      position: relative;
      border-bottom: 1px solid ${(p) => p.theme.colors.divider};
      flex: 1;
      height: 100%;
      max-height: 534px;

      .block {
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 0px 3px;

        .imgBorder {
          width: 100%;
          z-index: 2;
          position: absolute;
          pointer-events: none;
        }

        &.desktop {
          width: 59%;

          .imgBorder {
            max-height: 404px;
            aspect-ratio: 1.6 / 1;
          }
        }

        &.mobile {
          margin-right: -50px;
          width: 18.5%;

          .imgBorder {
            z-index: 4;
            max-height: 360px;
            aspect-ratio: 1 / 1.8;
          }
        }
      }
    }
  }

  ${deviceQuery.tabletM} {
    .content {
      max-height: unset;

      .preview {
        height: 100%;
        padding: 0px 20px;
        justify-content: center;

        .block {
          &.mobile {
            margin-right: 0px;
            width: 250px;

            .imgBorder {
              max-height: unset;
            }
          }
          &.desktop {
            display: none;
          }
        }
      }
    }
  }

  ${deviceQuery.mobileM} {
    .content {
      align-items: flex-start;

      .preview {
        padding: 0px;
      }
    }
    .bannerActions {
      bottom: 20px;
    }
  }

  ${deviceQuery.mobileMS} {
    .content {
      align-items: flex-start;

      .preview {
        padding: 0px;

        .block {
          &.mobile {
            width: 200px;
          }
        }
      }
    }
  }

  ${deviceQuery.mobileS} {
    .content {
      align-items: flex-start;

      .preview {
        padding: 0px;

        .block {
          &.mobile {
            width: 180px;
          }
        }
      }
    }
  }
`;
