import { FormikErrors, FormikTouched } from "formik";
import { ChangeEventHandler, FC, FocusEventHandler, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Input from "Components/Input";
import Password from "Components/Password";
import { ROLES, STRINGS } from "constants/appConstants";
import { getRegistrationLoader } from "store/slices/featureSlice";
import { getPublicUser } from "store/slices/global/previewProfileSlice";
import { darkTheme, lightTheme } from "styles/newThemes";
import { IRegisterFormikData } from "types/auth-types";

interface Props {
  values: IRegisterFormikData;
  errors: FormikErrors<IRegisterFormikData>;
  touched: FormikTouched<IRegisterFormikData>;
  setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void;
  handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  withoutLabel?: boolean;
}

const Fields: FC<Props> = ({
  values,
  errors,
  touched,
  setFieldValue,
  handleChange,
  handleBlur,
  withoutLabel = false,
}) => {
  const { t } = useTranslation();

  const registrationLoader = useSelector(getRegistrationLoader);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const user = useSelector(getPublicUser);

  const theme = useMemo(() => {
    switch (user.bioTheme) {
      case "light":
        return lightTheme.colors.background.fifth;
      case "dark":
        return darkTheme.colors.background.fifth;
      default:
        return lightTheme.colors.background.fifth;
    }
  }, [user.bioTheme]);

  return (
    <>
      {(values.accountType === ROLES.admin || values.accountType === ROLES.judge) && (
        <Input
          labelSize={darkTheme.typography.subText4}
          readOnly={registrationLoader}
          label={withoutLabel ? "" : t("common.user_profile.display_name")}
          name="name"
          maxLength={30}
          type="text"
          placeholder="Display Name"
          handleChange={(event) => {
            setFieldValue("name", event.target.value.trimStart());
          }}
          handleBlur={handleBlur}
          value={values.name}
          required
          color={theme}
          error={errors.name && touched.name ? errors.name : ""}
        />
      )}
      <Input
        labelSize={darkTheme.typography.subText4}
        readOnly={registrationLoader}
        label={withoutLabel ? "" : t("signup.email")}
        name={STRINGS.email}
        type="text"
        color={theme}
        placeholder="Email"
        handleChange={(event) => setFieldValue("email", event.target.value.replace(" ", ""))}
        handleBlur={handleBlur}
        value={values.email}
        required
        maxLength={60}
        error={errors.email && touched.email ? errors.email : ""}
      />
      <div className="passwordContainer">
        <Password
          labelSize={darkTheme.typography.subText4}
          readOnly={registrationLoader}
          label={withoutLabel ? "" : t("signup.pass")}
          show={passwordVisible}
          showPassword={() => setPasswordVisible(!passwordVisible)}
          color={theme}
          name={STRINGS.password}
          type={passwordVisible ? "text" : "password"}
          placeholder={t("common.createPass")}
          maxLength={30}
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values.password}
          required
          autoComplete="new-password"
          error={errors.password && touched.password ? errors.password : ""}
        />
        <Password
          labelSize={darkTheme.typography.subText4}
          color={theme}
          readOnly={registrationLoader}
          label={withoutLabel ? "" : t("common.confirmPass")}
          show={confirmPasswordVisible}
          showPassword={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
          name={STRINGS.confirmPassword}
          type={confirmPasswordVisible ? "text" : "password"}
          placeholder={t("common.confirmPass")}
          handleChange={handleChange}
          handleBlur={handleBlur}
          maxLength={30}
          value={values.confirmPassword}
          required
          error={errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : ""}
        />
      </div>
    </>
  );
};

export default Fields;
