import { FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { Content, PasswordModalContainer } from "./style";

import Check from "assets/svg/Check";
import Button from "Components/Button";

interface Props {
  show: boolean;
  closeModal: () => void;
}

const PasswordModal: FC<Props> = memo(({ show, closeModal }) => {
  const { t } = useTranslation();

  return (
    <PasswordModalContainer show={show} showCloseButton size="md" onHide={() => closeModal()}>
      <Content>
        <div className="info">
          <div className="icon">
            <Check />
          </div>
          <div className="text">
            <p className="title">{`${t("common.done")}!`}</p>
            <p className="description">{t("auth.updatedPassword")}</p>
          </div>
        </div>
        <div className="actions">
          <Button variant="secondary" onClick={() => closeModal()}>
            {t("common.ok")}
          </Button>
        </div>
      </Content>
    </PasswordModalContainer>
  );
});

export default PasswordModal;
