const OnlyfansColor = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 20.54V3.48002C1.02 3.38002 1.02 3.27002 1.03 3.17002C1.08 2.73002 1.29002 2.36002 1.55002 2.02002C1.87002 1.61002 2.29002 1.32002 2.77002 1.13002C2.96002 1.05002 3.17 1.04002 3.37 1.03002C3.41 1.03002 3.44001 1.04002 3.48001 1.02002H20.54C20.64 1.04002 20.75 1.04002 20.85 1.05002C21.29 1.10002 21.66 1.31002 22 1.57002C22.41 1.89002 22.7 2.31002 22.89 2.79002C22.97 2.98002 22.98 3.19002 22.99 3.39002C22.99 3.43002 22.98 3.46002 23 3.50002V20.56C22.98 20.66 22.98 20.77 22.97 20.87C22.92 21.31 22.71 21.68 22.45 22.02C22.13 22.43 21.71 22.72 21.23 22.91C21.04 22.99 20.83 23 20.63 23.01C20.59 23.01 20.56 23 20.52 23.02H3.46002C3.36002 23 3.25002 23 3.15002 22.99C2.71002 22.94 2.34 22.73 2 22.47C1.59 22.15 1.30002 21.73 1.11002 21.25C1.03002 21.06 1.02001 20.85 1.01001 20.65C1.01001 20.61 1.02 20.58 1 20.54Z"
      fill="#00ADED"
    />
    <path
      d="M12.79 8.44006C12.98 8.24006 13.1701 8.04006 13.4001 7.88006C13.8901 7.53006 14.45 7.38006 15.04 7.29006C15.69 7.20006 16.3401 7.19006 16.9901 7.19006C17.8701 7.19006 18.74 7.19006 19.62 7.19006C19.72 7.19006 19.75 7.21006 19.72 7.32006C19.32 8.86006 18.33 9.89006 16.91 10.5501C16.43 10.7701 15.93 10.9201 15.41 11.0301C15.37 11.0301 15.33 11.0501 15.26 11.0701C15.51 11.1501 15.74 11.1801 15.97 11.2001C16.64 11.2601 17.3001 11.2201 17.9601 11.0901C18.0101 11.0901 18.09 11.0301 18.06 11.1601C17.76 12.3501 17.13 13.2901 16.03 13.8901C15.51 14.1701 14.9501 14.3301 14.3601 14.3901C14.2201 14.4001 14.1501 14.4501 14.0801 14.5801C13.2501 16.2201 11.93 17.1901 10.12 17.4701C10.04 17.4801 9.95003 17.4901 9.87003 17.4901C9.75003 17.5101 9.63004 17.4901 9.50004 17.4901C9.41004 17.4901 9.39004 17.4401 9.41004 17.3701C9.43004 17.2601 9.47004 17.1601 9.50004 17.0501C9.76004 16.2301 10.02 15.4001 10.28 14.5801C10.39 14.2401 10.5 13.9101 10.6 13.5701C10.63 13.4701 10.6601 13.3801 10.7101 13.2901C10.7401 13.2201 10.76 13.1501 10.75 13.0801C10.8 12.9201 10.8701 12.7701 10.9001 12.6001C11.0601 12.1901 11.16 11.7601 11.31 11.3501C11.48 10.8901 11.64 10.4201 11.84 9.97006C12.01 9.58006 12.2001 9.20006 12.4301 8.83006C12.5001 8.72006 12.5701 8.62006 12.6501 8.52006C12.6901 8.48006 12.72 8.43006 12.79 8.42006V8.44006Z"
      fill="#FEFEFE"
    />
    <path
      d="M12.79 8.44007C12.63 8.67007 12.46 8.90007 12.32 9.15007C12.04 9.66007 11.81 10.1901 11.61 10.7301C11.38 11.3401 11.17 11.9601 10.98 12.5901C10.98 12.6001 10.97 12.6101 10.96 12.6201C10.89 12.5801 10.91 12.5201 10.91 12.4601C10.94 12.0501 10.82 11.6901 10.56 11.3901C10.25 11.0301 9.84002 10.8501 9.36002 10.8701C8.64002 10.9101 8.06002 11.4301 7.96002 12.1601C7.88002 12.7501 8.11002 13.2201 8.58002 13.5701C9.11002 13.9701 9.96 13.9101 10.44 13.4401C10.53 13.3501 10.62 13.2601 10.69 13.1501C10.72 13.1101 10.75 13.0601 10.81 13.0701C10.77 13.3101 10.68 13.5301 10.61 13.7601C10.24 14.9601 9.86001 16.1601 9.48001 17.3601C9.45001 17.4501 9.48001 17.4901 9.57001 17.4801C9.67001 17.4801 9.77 17.4801 9.87 17.4801C9.62 17.5301 9.37001 17.5101 9.13001 17.4901C7.43001 17.3601 6.08001 16.6001 5.13001 15.1801C3.34001 12.5201 4.46002 8.80007 7.43002 7.60007C9.37002 6.81007 11.17 7.12007 12.8 8.43007L12.79 8.44007Z"
      fill="#CBEEFB"
    />
    <path
      d="M10.8 13.07C10.63 13.35 10.41 13.58 10.12 13.73C9.49002 14.04 8.73004 13.89 8.26004 13.36C7.80004 12.83 7.75005 12.06 8.15005 11.48C8.55005 10.9 9.29004 10.65 9.95004 10.89C10.61 11.13 11.0301 11.79 10.9601 12.49C10.9601 12.53 10.9601 12.57 10.9601 12.61C10.9201 12.77 10.87 12.92 10.81 13.07H10.8Z"
      fill="#00ADEE"
    />
  </svg>
);

export default OnlyfansColor;
