/* eslint-disable import/no-duplicates */
import "moment/locale/ru";
import "moment/locale/uk";
import ru from "date-fns/locale/ru";
import uk from "date-fns/locale/uk";

import { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { changeLanguage } from "common/helpers/language";
import { getLocalStorageItem, getLocalStorageItems } from "common/helpers/localStorage";
import CoinLoading from "Components/CoinLoading";
import FullCoinLoader from "Components/FullCoinLoader";
import Toast from "Components/Toast";
import { STORAGES, STRINGS } from "constants/appConstants";
import AppRoutes from "routes/AppRoutes";
import { login } from "store/slices/authSlice";
import { getUserLogoutEvent } from "store/slices/eventBusSlice";
import { getFullScreenLoader } from "store/slices/featureSlice";
import { getUser } from "store/slices/global/userSlice";

registerLocale("ru", ru);
registerLocale("uk", uk);

const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const fullScreenLoader = useSelector(getFullScreenLoader);
  const userDetails = useSelector(getUser);
  const userLogoutEvent = useSelector(getUserLogoutEvent);
  const navigate = useNavigate();

  useEffect(() => {
    if ("scrollRestoration" in history) {
      history.scrollRestoration = "manual";
    }
    !loading && setLoading(true);
    const isLoggedIn = getLocalStorageItem({ key: STRINGS.isLoggedIn });

    if (isLoggedIn) {
      const [accountType, token, userId] = getLocalStorageItems([
        { key: STORAGES.accountType },
        { key: STORAGES.token },
        { key: STORAGES.userId },
      ]);
      dispatch(login({ accountType, token, userId }));
    }
    loading && setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    if (!userDetails) return;
    changeLanguage(userDetails.languages);
  }, [userDetails]);

  useEffect(() => {
    if (userLogoutEvent === null) return;
    navigate("/login");
  }, [userLogoutEvent]);

  return (
    <>
      <Toast />
      {fullScreenLoader && <FullCoinLoader type="transparent" />}
      {loading ? <CoinLoading /> : <AppRoutes />}
    </>
  );
};

export default App;
