/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */ import {
  Formik,
  FormikHelpers,
  FormikState,
} from "formik";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import * as Yup from "yup";

import { ProfileForm, SaveButton, SubPageContainer } from "../../style";

import { makeToast } from "common/helpers/helper";
import Input from "Components/Input";
import Password from "Components/Password";
import { STRINGS } from "constants/appConstants";
import { NUMBER_REX, PASSWORD_REGEX } from "constants/regex";
import { validateTwoFactorCodeAPI } from "services/authServices";
import { changePasswordAPI } from "services/UserServices";
import { setLoader } from "store/slices/featureSlice";
import { setIsProfileUpdated } from "store/slices/global/globalSlice";
import { getUser } from "store/slices/global/userSlice";
import { BlockCard } from "styles/mixins";
import { IFormikChangePassword } from "types/user";

const ProfileCredentials = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userDetails = useSelector(getUser);
  const [passwordVisible, setPasswordVisible] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const schema = Yup.object().shape({
    current: Yup.string().required(t("common.required")),
    new: Yup.string()
      .required(t("common.required"))
      .matches(PASSWORD_REGEX, t("auth.min_8_characters")),
    confirm: Yup.string()
      .required(t("common.required"))
      .oneOf([Yup.ref("new"), null], t("auth.passwords_should_match")),
    twoFACode: !userDetails?.mfaSkip ? Yup.string().required(t("common.required")) : Yup.string(),
  });

  const isDisabled = useCallback((dirty: boolean) => {
    dispatch(setIsProfileUpdated(!dirty));
    return dirty;
  }, []);

  const startLoader = () => dispatch(setLoader({ name: "changePasswordLoader", value: true }));
  const stopLoader = () => dispatch(setLoader({ name: "changePasswordLoader", value: false }));

  const changePassword = (
    values: IFormikChangePassword,
    resetForm: (nextState?: Partial<FormikState<IFormikChangePassword>> | undefined) => void
  ) => {
    changePasswordAPI(
      {
        apiData: { password: values.current, newpassword: values.new },
      },
      (res) => {
        stopLoader();
        if (res?.status === true) {
          resetForm();
          makeToast({
            message: res?.data || "Password changed",
            type: STRINGS.toastType.success,
          });
        }
      }
    );
  };

  const validate2FA = (
    values: IFormikChangePassword,
    { resetForm }: FormikHelpers<IFormikChangePassword>
  ) => {
    if (!userDetails) return;
    startLoader();
    userDetails.mfaSkip
      ? changePassword(values, resetForm)
      : validateTwoFactorCodeAPI(
          {
            data: {
              id: userDetails._id,
              token: values.twoFACode,
            },
          },
          (status) => {
            if (status === false) stopLoader();
            else changePassword(values, resetForm);
          }
        );
  };

  return (
    <SubPageContainer>
      <p className="title">{t("creator.profile.credentials")}</p>
      {userDetails && (
        <Formik<IFormikChangePassword>
          enableReinitialize
          initialValues={{
            current: "",
            new: "",
            confirm: "",
            twoFACode: "",
          }}
          validationSchema={schema}
          onSubmit={validate2FA}
        >
          {({ values, errors, touched, dirty, handleBlur, handleSubmit, setFieldValue }) => (
            <ProfileForm onSubmit={handleSubmit}>
              <BlockCard className="card">
                <Input
                  name="name"
                  label={t("common.user_profile.email_address")}
                  readOnly
                  value={userDetails.email}
                  handleChange={() => {}}
                />
                <Password
                  label={t("common.user_profile.current_pass")}
                  name="current"
                  required
                  placeholder={t("common.user_profile.current_pass")}
                  maxLength={60}
                  show={passwordVisible.current}
                  type={passwordVisible.current ? "text" : STRINGS.password}
                  showPassword={() =>
                    setPasswordVisible((old) => ({ ...old, current: !passwordVisible.current }))
                  }
                  handleChange={(event) =>
                    setFieldValue("current", event.target.value.replace(" ", ""))
                  }
                  handleBlur={handleBlur}
                  value={values.current}
                  error={errors.current && touched.current ? errors.current : ""}
                />
                <Password
                  label={t("common.user_profile.new_pass")}
                  required
                  maxLength={60}
                  placeholder={t("common.user_profile.new_pass")}
                  type={passwordVisible.new ? "text" : STRINGS.password}
                  show={passwordVisible.new}
                  showPassword={() =>
                    setPasswordVisible((old) => ({ ...old, new: !passwordVisible.new }))
                  }
                  name="new"
                  handleChange={(event) =>
                    setFieldValue("new", event.target.value.replace(" ", ""))
                  }
                  handleBlur={handleBlur}
                  value={values.new}
                  error={errors.new && touched.new ? errors.new : ""}
                />
                <Password
                  label={t("common.user_profile.confirm_new_pass")}
                  required
                  maxLength={60}
                  placeholder={t("common.user_profile.confirm_new_pass")}
                  type={passwordVisible.confirm ? "text" : STRINGS.password}
                  show={passwordVisible.confirm}
                  showPassword={() =>
                    setPasswordVisible((old) => ({ ...old, confirm: !passwordVisible.confirm }))
                  }
                  name="confirm"
                  handleChange={(event) =>
                    setFieldValue("confirm", event.target.value.replace(" ", ""))
                  }
                  handleBlur={handleBlur}
                  value={values.confirm}
                  error={errors.confirm && touched.confirm ? errors.confirm : ""}
                />
                {!userDetails.mfaSkip && (
                  <Input
                    label={t("common.2fa_code")}
                    type="text"
                    name="twoFACode"
                    placeholder={t("common.small_2fa_code")}
                    maxLength={6}
                    required
                    handleChange={(event) => {
                      if (NUMBER_REX?.test(event.target.value) || event.target.value === "") {
                        setFieldValue("twoFACode", event.target.value.replace(" ", ""));
                      }
                    }}
                    handleBlur={handleBlur}
                    value={values.twoFACode}
                    error={errors.twoFACode && touched.twoFACode ? errors.twoFACode : ""}
                  />
                )}
                <div className="saveContainer">
                  <SaveButton variant="primary" disabled={isDisabled(!dirty)} type="submit">
                    {t("creator.profile.change")}
                  </SaveButton>
                </div>
              </BlockCard>
            </ProfileForm>
          )}
        </Formik>
      )}
    </SubPageContainer>
  );
};

export default ProfileCredentials;
