import { FC } from "react";

interface Props {
  size?: number;
}

const Delete: FC<Props> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.8504 9.46992C19.8504 9.46992 19.3104 16.1999 18.9904 19.0399C18.9815 19.3385 18.913 19.6323 18.7888 19.904C18.6647 20.1758 18.4874 20.4199 18.2675 20.6221C18.0476 20.8243 17.7895 20.9805 17.5084 21.0814C17.2272 21.1824 16.9287 21.2261 16.6304 21.2099C14.0204 21.2099 11.4104 21.2599 8.80039 21.2099C8.50351 21.2207 8.2075 21.1715 7.93007 21.0652C7.65264 20.959 7.39947 20.7979 7.18571 20.5916C6.97194 20.3853 6.80196 20.138 6.68593 19.8645C6.5699 19.5911 6.5102 19.297 6.51039 18.9999C6.19039 16.1399 5.65039 9.41992 5.65039 9.41992"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.2305 6.24023H4.23047"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.96 6.24C17.5809 6.23847 17.2138 6.10639 16.9206 5.86599C16.6273 5.62558 16.4259 5.29152 16.35 4.92L16.1 3.7C16.0272 3.427 15.8661 3.18576 15.6418 3.01392C15.4175 2.84208 15.1426 2.74928 14.86 2.75H10.63C10.3475 2.74928 10.0726 2.84208 9.84832 3.01392C9.62402 3.18576 9.46288 3.427 9.39004 3.7L9.15004 4.92C9.07421 5.29152 8.87273 5.62558 8.57951 5.86599C8.28628 6.10639 7.91921 6.23847 7.54004 6.24"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Delete;
