/* eslint-disable prettier/prettier */
/* eslint-disable func-names */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable react/react-in-jsx-scope */

import i18next, { t } from "i18next";

import { ReactNode } from "react";
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin, AiFillYoutube } from "react-icons/ai";
import {
  FaDiscord,
  FaOdnoklassnikiSquare,
  FaPaperPlane,
  FaSnapchat,
  FaTelegramPlane,
  FaTiktok,
  FaTwitter,
  FaWeibo,
  FaYoutube,
} from "react-icons/fa";

import { ImPinterest } from "react-icons/im";
import { IoLogoReddit, IoLogoTumblr, IoLogoTwitter, IoLogoWhatsapp } from "react-icons/io";
import { IoShareSocial } from "react-icons/io5";
import { LiaEtsy } from "react-icons/lia";
import { RiMessengerFill } from "react-icons/ri";
import { SlSocialVkontakte } from "react-icons/sl";
import { TbBrandOnlyfans } from "react-icons/tb";

import { StylesConfig } from "react-select";

import Audio from "assets/svg/Audio";
import CirclePlay from "assets/svg/CirclePlay";
import DiscordColor from "assets/svg/colorSocials/Discord";
import EtsyColor from "assets/svg/colorSocials/Etsy";
import FacebookColor from "assets/svg/colorSocials/Facebook";
import InstagramColor from "assets/svg/colorSocials/Instagram";
import LinkedinColor from "assets/svg/colorSocials/Linkedin";
import MediumDarkColor from "assets/svg/colorSocials/MediumDark";
import MessengerColor from "assets/svg/colorSocials/Messenger";
import OdnoklassnikiColor from "assets/svg/colorSocials/Odnoklassniki";
import OnlyfansColor from "assets/svg/colorSocials/Onlyfans";
import PinterestColor from "assets/svg/colorSocials/Pinterest";
import RedditColor from "assets/svg/colorSocials/Reddit";
import SnapchatColor from "assets/svg/colorSocials/Snapchat";
import TelegramColor from "assets/svg/colorSocials/Telegram";
import ThreadsColor from "assets/svg/colorSocials/Threads";
import TikTokDarkColor from "assets/svg/colorSocials/TikTokDark";
import TumblrDarkColor from "assets/svg/colorSocials/TumblrDark";
import TwitterDarkColor from "assets/svg/colorSocials/TwitterDark";
import VKColor from "assets/svg/colorSocials/VK";
import WeiboColor from "assets/svg/colorSocials/Weibo";
import WhatsappColor from "assets/svg/colorSocials/Whatsapp";
import YouTubeColor from "assets/svg/colorSocials/YouTube";
import DefaultIcon from "assets/svg/DefaultIcon";
import English from "assets/svg/languages/English";
import Russian from "assets/svg/languages/Russian";
import Ukrainian from "assets/svg/languages/Ukrainian";
import Cat from "assets/svg/patterns/Cat";
import Crown from "assets/svg/patterns/Crown";
import Donut from "assets/svg/patterns/Donut";
import Flower from "assets/svg/patterns/Flower";
import Legs from "assets/svg/patterns/Legs";
import Light from "assets/svg/patterns/Light";
import Love from "assets/svg/patterns/Love";
import CatThin from "assets/svg/patterns/patterns copy/CatThin";
import CrownThin from "assets/svg/patterns/patterns copy/CrownThin";
import DonutThin from "assets/svg/patterns/patterns copy/DonutThin";
import FlowerThin from "assets/svg/patterns/patterns copy/FlowerThin";
import LegsThin from "assets/svg/patterns/patterns copy/LegsThin";
import LightThin from "assets/svg/patterns/patterns copy/LightThin";
import LoveThin from "assets/svg/patterns/patterns copy/LoveThin";
import PawThin from "assets/svg/patterns/patterns copy/PawThin";
import RocketThin from "assets/svg/patterns/patterns copy/RocketThin";
import SmileThin from "assets/svg/patterns/patterns copy/SmileThin";
import Paw from "assets/svg/patterns/Paw";
import Rocket from "assets/svg/patterns/Rocket";
import Smile from "assets/svg/patterns/Smile";
import Read from "assets/svg/Read";
import Threads from "assets/svg/Threads";
import TikTokColor from "assets/svg/TikTokColor";
import Unread from "assets/svg/Unread";
import YoutubeColor from "assets/svg/YoutubeColor";
import { IconOption, Option } from "Components/SelectField/types";
import { ITabs } from "Components/Tabs/types";
import i18n from "i18n";
import { darkColors, darkTheme, lightColors } from "styles/newThemes";
import { colors } from "styles/theme";
import { IFilterOption } from "types/common.types";
import { LANGUAGES as LANGUAGESTYPE } from "types/language.types";
import { AllowedMessagesFrom } from "types/user";

export const SUPPORTED_IMAGE_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "image/webp",
];

export const SUPPORTED_VIDEO_FORMATS = ["video/mp4", "video/webm"];

export const SUPPORTED_ASSET_FORMATS = [...SUPPORTED_IMAGE_FORMATS, ...SUPPORTED_VIDEO_FORMATS];

export const VIDEOS_CATEGORIES = [
  "justice",
  "Entertainment",
  "Motivational",
  "Influencer",
  "Enterprenuer",
  "Coding",
  "Comedy",
];

export const EARNINGREPORT = {
  GIFT: "Gift",
  SUBSCRIPTION: "Subscription",
  CHALLENGE_REWARD: "Challenge Reward",
  PAID_MESSAGE: "Paid message",
};

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const TIMEPERIOD = ["24h", "48h", "72h", "7d", "14d", "30d"];

export const STORAGES = {
  id: "id",
  walletType: "walletType",
  route: "route",
  token: "JWT",
  userData: "userData",
  isLoggedIn: "isLoggedIn",
  accountType: "accountType",
  userId: "userId",
  commonId: "commonId",
  videoUploadAllowance: "videoUploadAllowance",
};

export const WALLET_TYPE = {
  metamask: "METAMASK",
};

export const CURRENCY_SYMBOLS = {
  ether: "ETH",
  wrappedEther: "WETH",
};

export const SUPPORTED_FILE_SIZE = 10 * 1024 * 1024;
export const PalleteColorTypes = ["primary", "secondary", "tertiary", "white", "black"];

export const CONTENT_TYPE = {
  TEXT: "Text",
  IMAGE: "Image",
  VIDEO: "Video",
  AUDIO: "Audio",
};

export const IDEA_CONTENT_TYPE = {
  TEXT: "text",
  IMAGE: "image",
  VIDEO: "video",
  AUDIO: "audio",
};

export const ButtonSizes = ["x-small", "small", "medium", "large"];

export const ONE_ETHER = 10 ** 18;
export const MIN_ETHER = 1 / ONE_ETHER;

export const NETWORK = {
  mainnet: "mainnet",
  rinkeby: "rinkeby",
};

export const CONTRACT_NAME = {
  minting: "minting",
  auction: "auction",
};

export const ASSET_TYPES = {
  image: "image",
  video: "video",
  audio: "audio",
  "3d": "3d",
};

export const AUCTION_STATUS = {
  created: "CREATED",
  open: "OPEN",
  close: "CLOSE",
  completed: "COMPLETED",
};

export const NEW_ROLES = {
  brand: "brand",
};

export const ROLES = {
  fan: "fan",
  publisher: "publisher",
  creator: "creator",
  admin: "admin",
  judge: "judge",
};

export const CREATABLE_ROLES = [ROLES.judge, ROLES.fan, /* ROLES.publisher, */ ROLES.creator];
export const USER_ROLES = [ROLES.creator, /* ROLES.publisher, */ ROLES.fan];
export const ALL_ROLES = [
  ROLES.fan,
  /* ROLES.publisher, */ ROLES.creator,
  ROLES.admin,
  ROLES.judge,
];

const page = {
  login: "login",
  signup: "signup",
};

export const userLevelInterface = {
  rewardMultiplier: "rewardMultiplier",
  lewkstakeLimit: "lewkstakeLimit",
  VideoLimit: "VideoLimit",
  campaignLimit: "campaignLimit",
};

export const customizePlatformsInterface = {
  active: "active",
};

export const STRINGS = {
  ...userLevelInterface,
  ...customizePlatformsInterface,
  percent: "percent",
  number: "number",
  noData: "no data",
  attributes: "attributes",
  lewk: "lewk",
  enabled: "Enabled",
  disabled: "Disabled",
  usdc: "usdc",
  ...STORAGES,
  page,
  youtube: "youtube",
  instagram: "instagram",
  mostActiveOn: "mostActiveOn",
  sendGift: "sendGift",
  language: "language",
  favouriteTopic: "favouriteTopic",
  hideProfileCreator: "hideProfileCreator",
  hideProfilePublishers: "hideProfilePublishers",
  receiveNotification: "receiveNotification",
  AddEmail: "AddEmail",
  iwantReceive: "iwantReceive",
  iwantNotReceive: "iwantNotReceive",
  table: "table",
  cards: "cards",
  submitButtonDisabled: "submitButtonDisabled",
  role: "role",
  login: "login",
  loggedin: "loggedin",
  password: "password",
  newpassword: "newpassword",
  image: "image",
  pictureUrl: "pictureUrl",
  invitationCode: "invitationCode",
  user: "user",
  address: "address",
  amount: "amount",
  picture: "picture",
  topic: "Choose a topic",
  register: "register",
  verifyCaptcha: "verifyCaptcha",
  countryPhoneCode: "countryPhoneCode",
  confirmPassword: "confirmPassword",
  email: "email",
  link: "link",
  url: "url",
  platform: "platform",
  platformName: "platformName",
  displayname: "displayname",
  color: "color",
  emailAndUsername: "emailAndUsername",
  name: "name",
  aboutMe: "aboutMe",
  id: "id",
  inviteCode: "inviteCode",
  UserId: "userId",
  userName: "userName",
  country: "country",
  phone: "phone",
  passCode: "passCode",
  companyName: "companyName",
  companyNumber: "companyNumber",
  companyWebsite: "companyWebsite",
  industry: "industry",
  profilelink: "profilelink",
  favouriteCreator: "favouriteCreator",
  accountType: "accountType",
  newsLetter: "newsLetter",
  pendingReview: "Pending review",
  approved: "Approved",
  rejected: "Rejected",
  twitter: "twitter",
  tiktok: "tiktok",
  status: "status",
  search: "search",
  targetPlatform: "targetPlatform",
  reason: "reason",
  hashtags: "hashtags",
  text: "text",
  select: "select",
  active: "active",
  inActive: "inactive",
  play: "play",
  showProfile: "showProfile",
  token: "JWT",
  isLoggedIn: "isLoggedIn",
  networkErr: "Network Error",
  error: "error",
  success: "success",
  mainSocialProfileLink: "mainSocialProfileLink",
  toastType: {
    error: "error",
    success: "success",
  },
  usernameAlreadyRegistered: "username already exist",
  emailAlreadyRegistered: "email already exist",
  IT: "Software Industory",
  wallet: "wallet",
  coins: "coins",
  speaker: "speaker",
  singleUser: "singleUser",
  users: "users",
  videoLink: "videoLink",
  brush: "brush",
  video: "video",
  setting: "setting",
  peoples: "peoples",
  report: "report",
  envelope: "envelope",
  pending: "pending",
  ...ROLES,
  nameOrEmail: "nameOrEmail",
  onSale: "on_sale",
  availableforSale: "available_to_sale",
  creatorVideos: "Creator Videos",
  payments: "Payments",
  campaignDetails: "Campaign Details",
  assetId: "assetId",
  private: "Private",
  public: "Public",
  subscribed: "Subscribed",
};

export const TYPE = [STRINGS.percent];
export const NUMBERTYPE = [STRINGS.number];
export const CURRENCIES = [STRINGS.lewk, STRINGS.usdc];

export const VIDEO_CATEGORY = ["blockchain", "cryptocurrency", "entertainment", "drama", "comedy"];

export const reactSelectCustomStyle: StylesConfig<Option> = {
  option: (provided, state) => ({
    ...provided,
    textTransform: "capitalize",
    backgroundColor: state.isSelected ? colors.green.primary : colors.blue.secondary,
    color: colors.white.primary,
    ":hover": {
      background: state.isSelected ? colors.green.primary : colors.blue.primary,
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: colors.white.primary,
    textTransform: "capitalize",
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: "none",
  }),
  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar": {
      display: "none",
    },
  }),
};

export const selectStyleLight: StylesConfig<Option> = {
  container: (base, state) => ({
    ...base,
    borderRadius: "12px",
    border: `1px solid ${
      state.isDisabled || !state.getValue() ? lightColors.field.empty : lightColors.field.full
    }`,
    margin: "0px",
    height: "50px",
    minHeight: "50px",
  }),
  control: (base, state) => {
    const value = state.getValue();
    return {
      ...base,
      backgroundColor: "transparent",
      border: "none",
      padding: !state.isMulti
        ? "12px 8px 12px 16px"
        : value.length !== 0
        ? "6px 8px 10px 8px"
        : "8px",
      height: "100%",
      borderRadius: "13px",
      margin: "0px",
      borderColor: "none",
      boxShadow: "none",
    };
  },
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    backgroundColor: "transparent",
    margin: "0px",
  }),
  multiValue: (base) => ({
    ...base,
    padding: "4px 4px 4px 8px",
    borderRadius: "8px",
    backgroundColor: lightColors.green.primary20,
    color: lightColors.text.third,
    margin: "0px 4px 0px 0px",
  }),
  multiValueLabel: (base) => ({
    ...base,
    padding: 0,
    paddingLeft: 0,
    color: lightColors.text.third,
    fontSize: darkTheme.typography.subText5,
    margin: "0px",
  }),
  multiValueRemove: (base) => ({
    ...base,
    ":hover": {
      backgroundColor: "transparent",
    },
    margin: "0px",
  }),
  singleValue: (base, state) => ({
    ...base,
    color: state.isDisabled ? lightColors.text.secondary : lightColors.text.primary,
    fontSize: "15px",
    margin: "0px",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    paddingRight: "8px",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isDisabled ? lightColors.text.secondary : lightColors.text.primary,
    padding: "0px",
    margin: "0px",
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "",
  }),
  input: (base) => ({
    ...base,
    fontSize: "15px",
    margin: "0px",

    ":after": {
      display: "none",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (base) => ({
    ...base,
    background: lightColors.background.secondary,
    padding: "8px",
    borderRadius: "12px",
    border: `1px solid ${lightColors.divider}`,
  }),
  menuList: (base) => ({
    ...base,
    padding: "0px",
  }),
  option: (base, state) => ({
    ...base,
    padding: "8px 24px",
    backgroundColor: state.isSelected
      ? lightColors.background.fifth
      : lightColors.background.secondary,
    color:
      state.isFocused && !state.isSelected
        ? lightColors.text.primary
        : state.data.value === "delete"
        ? lightColors.text.error
        : state.data.value === "cancelDelete"
        ? lightColors.text.third
        : state.isSelected
        ? lightColors.text.third
        : lightColors.text.primary,
    borderRadius: "8px",
    ":hover": {
      color: lightColors.text.third,
    },
    ":active": {
      background: lightColors.background.fifth,
    },
  }),
};

export const selectStyleDark: StylesConfig<Option> = {
  container: (base, state) => ({
    ...base,
    borderRadius: "12px",
    border: `1px solid ${
      state.isDisabled || !state.getValue() ? darkColors.field.empty : darkColors.field.full
    }`,
    margin: "0px",
    height: "50px",
    minHeight: "50px",
  }),
  control: (base, state) => {
    const value = state.getValue();
    return {
      ...base,
      backgroundColor: "transparent",
      border: "none",
      padding: !state.isMulti
        ? "12px 8px 12px 16px"
        : value.length !== 0
        ? "6px 8px 10px 8px"
        : "8px",
      height: "100%",
      borderRadius: "13px",
      margin: "0px",
      borderColor: "none",
      boxShadow: "none",
    };
  },
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    backgroundColor: "transparent",
    margin: "0px",
  }),
  multiValue: (base) => ({
    ...base,
    padding: "4px 4px 4px 8px",
    borderRadius: "8px",
    backgroundColor: darkColors.green.primary20,
    color: darkColors.text.third,
    margin: "0px 4px 0px 0px",
  }),
  multiValueLabel: (base) => ({
    ...base,
    padding: 0,
    paddingLeft: 0,
    color: darkColors.text.third,
    fontSize: darkTheme.typography.subText5,
    margin: "0px",
  }),
  multiValueRemove: (base) => ({
    ...base,
    ":hover": {
      backgroundColor: "transparent",
    },
    margin: "0px",
  }),
  singleValue: (base, state) => ({
    ...base,
    color: state.isDisabled ? darkColors.text.secondary : darkColors.text.primary,
    fontSize: "15px",
    margin: "0px",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    paddingRight: "8px",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isDisabled ? darkColors.text.secondary : darkColors.text.primary,
    padding: "0px",
    margin: "0px",
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "",
  }),
  input: (base) => ({
    ...base,
    fontSize: "15px",
    margin: "0px",

    ":after": {
      display: "none",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (base) => ({
    ...base,
    background: darkColors.background.secondary,
    padding: "8px",
    borderRadius: "12px",
    border: `1px solid ${darkColors.divider}`,
  }),
  menuList: (base) => ({
    ...base,
    padding: "0px",
  }),
  option: (base, state) => ({
    ...base,
    padding: "8px 24px",
    backgroundColor: state.isSelected
      ? darkColors.background.fifth
      : darkColors.background.secondary,
    color:
      state.isFocused && !state.isSelected
        ? darkColors.text.primary
        : state.data.value === "delete"
        ? darkColors.text.error
        : state.data.value === "cancelDelete"
        ? darkColors.text.third
        : state.isSelected
        ? darkColors.text.third
        : darkColors.text.primary,
    borderRadius: "8px",
    ":hover": {
      color: darkColors.text.third,
    },
    ":active": {
      background: darkColors.background.fifth,
    },
  }),
};

export const sortChallengeStyleLight: StylesConfig<Option> = {
  container: (base) => ({
    ...base,
    border: "none",
    minHeight: "24px",
    width: "100%",
    height: "24px",
    margin: "0px",
  }),
  control: (base) => ({
    ...base,
    backgroundColor: "transparent",
    padding: 0,
    border: "none",
    minHeight: "24px",
    height: "24px",
    margin: "0px",
    borderColor: "none",
    boxShadow: "none",
  }),
  valueContainer: (base) => ({
    ...base,
    pointerEvents: "none",
    backgroundColor: "transparent",
    padding: 0,
    minHeight: "24px",
    height: "24px",
    margin: "0px",
  }),
  singleValue: (base) => ({
    ...base,
    color: lightColors.text.primary,
    userSelect: "none",
    minHeight: "24px",
    height: "24px",
    fontSize: "15px",
    margin: "0px",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: lightColors.text.primary,
    padding: "0px",
    minHeight: "24px",
    height: "24px",
    margin: "0px",
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "",
  }),
  input: (base) => ({
    ...base,
    minHeight: "24px",
    height: "24px",
    fontSize: "15px",
    margin: "0px",
    padding: "0px",

    ":after": {
      display: "none",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (base) => ({
    ...base,
    background: lightColors.background.secondary,
    width: "155px",
    padding: "8px",
    left: "-50px",
    borderRadius: "12px",
    border: `1px solid ${lightColors.divider}`,
  }),
  menuList: (base) => ({
    ...base,
    padding: "0px",
  }),
  option: (base, state) => ({
    ...base,
    padding: "8px 16px",
    textAlign: "center",
    backgroundColor: state.isSelected
      ? lightColors.background.fifth
      : lightColors.background.secondary,
    color: state.isSelected ? lightColors.text.third : lightColors.text.primary,
    borderRadius: "8px",
    ":hover": {
      color: lightColors.text.third,
    },
    ":active": {
      background: lightColors.background.fifth,
    },
  }),
};

export const sortChallengeStyleDark: StylesConfig<Option> = {
  container: (base) => ({
    ...base,
    border: "none",
    minHeight: "24px",
    height: "24px",
    margin: "0px",
    width: "100%",
  }),
  control: (base) => ({
    ...base,
    backgroundColor: "transparent",
    padding: 0,
    border: "none",
    minHeight: "24px",
    height: "24px",
    margin: "0px",
    borderColor: "none",
    boxShadow: "none",
  }),
  valueContainer: (base) => ({
    ...base,
    pointerEvents: "none",
    backgroundColor: "transparent",
    padding: 0,
    minHeight: "24px",
    height: "24px",
    margin: "0px",
  }),
  singleValue: (base) => ({
    ...base,
    color: darkColors.text.primary,
    userSelect: "none",
    minHeight: "24px",
    height: "24px",
    fontSize: "15px",
    margin: "0px",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: darkColors.text.primary,
    padding: "0px",
    minHeight: "24px",
    height: "24px",
    margin: "0px",
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "",
  }),
  input: (base) => ({
    ...base,
    minHeight: "24px",
    height: "24px",
    fontSize: "15px",
    margin: "0px",
    padding: "0px",

    ":after": {
      display: "none",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (base) => ({
    ...base,
    background: darkColors.background.secondary,
    width: "155px",
    padding: "8px",
    left: "-50px",
    borderRadius: "12px",
    border: `1px solid ${darkColors.divider}`,
  }),
  menuList: (base) => ({
    ...base,
    padding: "0px",
  }),
  option: (base, state) => ({
    ...base,
    padding: "8px 16px",
    textAlign: "center",
    backgroundColor: state.isSelected
      ? darkColors.background.fifth
      : darkColors.background.secondary,
    color: state.isSelected ? darkColors.text.third : darkColors.text.primary,
    borderRadius: "8px",
    ":hover": {
      color: darkColors.text.third,
    },
    ":active": {
      background: darkColors.background.fifth,
    },
  }),
};

export const sortSubmissionStyleDark: StylesConfig<Option> = {
  container: (base) => ({
    ...base,
    border: "none",
    minHeight: "24px",
    height: "24px",
    margin: "0px",
  }),
  control: (base) => ({
    ...base,
    backgroundColor: "transparent",
    padding: 0,
    border: "none",
    minHeight: "24px",
    height: "24px",
    margin: "0px",
    borderColor: "none",
    boxShadow: "none",
  }),
  valueContainer: (base) => ({
    ...base,
    pointerEvents: "none",
    backgroundColor: "transparent",
    padding: 0,
    minHeight: "24px",
    height: "24px",
    margin: "0px",
  }),
  singleValue: (base) => ({
    ...base,
    color: darkColors.text.primary,
    userSelect: "none",
    minHeight: "24px",
    height: "24px",
    fontSize: "15px",
    margin: "0px",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: darkColors.text.primary,
    padding: "0px",
    minHeight: "24px",
    height: "24px",
    margin: "0px",
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "",
  }),
  input: (base) => ({
    ...base,
    minHeight: "24px",
    height: "24px",
    fontSize: "15px",
    margin: "0px",
    padding: "0px",

    ":after": {
      display: "none",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (base) => ({
    ...base,
    background: darkColors.background.secondary,
    width: "168px",
    padding: "8px",
    left: "-83px",
    borderRadius: "12px",
    border: `1px solid ${darkColors.divider}`,
  }),
  menuList: (base) => ({
    ...base,
    padding: "0px",
  }),
  option: (base, state) => ({
    ...base,
    padding: "8px 24px",
    backgroundColor: state.isSelected
      ? darkColors.background.fifth
      : darkColors.background.secondary,
    color: state.isSelected ? darkColors.text.third : darkColors.text.primary,
    borderRadius: "8px",
    ":hover": {
      color: darkColors.text.third,
    },
    ":active": {
      background: darkColors.background.fifth,
    },
  }),
};

export const sortSubmissionStyleLight: StylesConfig<Option> = {
  container: (base) => ({
    ...base,
    border: "none",
    minHeight: "24px",
    height: "24px",
    margin: "0px",
  }),
  control: (base) => ({
    ...base,
    backgroundColor: "transparent",
    padding: 0,
    border: "none",
    minHeight: "24px",
    height: "24px",
    margin: "0px",
    borderColor: "none",
    boxShadow: "none",
  }),
  valueContainer: (base) => ({
    ...base,
    pointerEvents: "none",
    backgroundColor: "transparent",
    padding: 0,
    minHeight: "24px",
    height: "24px",
    margin: "0px",
  }),
  singleValue: (base) => ({
    ...base,
    color: lightColors.text.primary,
    userSelect: "none",
    minHeight: "24px",
    height: "24px",
    fontSize: "15px",
    margin: "0px",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: lightColors.text.primary,
    padding: "0px",
    minHeight: "24px",
    height: "24px",
    margin: "0px",
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "",
  }),
  input: (base) => ({
    ...base,
    minHeight: "24px",
    height: "24px",
    fontSize: "15px",
    margin: "0px",
    padding: "0px",

    ":after": {
      display: "none",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (base) => ({
    ...base,
    background: lightColors.background.secondary,
    width: "168px",
    padding: "8px",
    left: "-83px",
    borderRadius: "12px",
    border: `1px solid ${lightColors.divider}`,
  }),
  menuList: (base) => ({
    ...base,
    padding: "0px",
  }),
  option: (base, state) => ({
    ...base,
    padding: "8px 24px",
    backgroundColor: state.isSelected
      ? lightColors.background.fifth
      : lightColors.background.secondary,
    color: state.isSelected ? lightColors.text.third : lightColors.text.primary,
    borderRadius: "8px",
    ":hover": {
      color: lightColors.text.third,
    },
    ":active": {
      background: lightColors.background.fifth,
    },
  }),
};

export const reactSortCustomStyle: StylesConfig<Option> = {
  option: (provided, state) => ({
    ...provided,
    textTransform: "capitalize",
    backgroundColor:
      state.isFocused && !state.isSelected
        ? colors.blue.primary
        : state.isSelected
        ? colors.green.primary
        : colors.blue.secondary,
    color: colors.white.primary,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: colors.white.primary,
    textTransform: "capitalize",
    opacity: state.isDisabled ? 0.5 : 1,
    marginTop: "-5px",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: "none",
  }),
  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar": {
      display: "none",
    },
  }),
};

export const VIDEO_UPLOAD_ALLOWANCE = {
  level0: 1,
  level1: 2,
  level2: 2,
  level3: 3,
};

export const ALL_COUNTRY_PHONE_CODES = [
  "+7 840",
  "+93",
  "+355",
  "+213",
  "+1 684",
  "+376",
  "+244",
  "+1 264",
  "+1 268",
  "+54",
  "+374",
  "+297",
  "+247",
  "+61",
  "+672",
  "+43",
  "+994",
  "+1 242",
  "+973",
  "+880",
  "+1 246",
  "+1 268",
  "+375",
  "+32",
  "+501",
  "+229",
  "+1 441",
  "+975",
  "+591",
  "+387",
  "+267",
  "+55",
  "+246",
  "+1 284",
  "+673",
  "+359",
  "+226",
  "+257",
  "+855",
  "+237",
  "+1",
  "+238",
  "+ 345",
  "+236",
  "+235",
  "+56",
  "+86",
  "+61",
  "+61",
  "+57",
  "+269",
  "+242",
  "+243",
  "+682",
  "+506",
  "+385",
  "+53",
  "+599",
  "+537",
  "+420",
  "+45",
  "+246",
  "+253",
  "+1 767",
  "+1 809",
  "+670",
  "+56",
  "+593",
  "+20",
  "+503",
  "+240",
  "+291",
  "+372",
  "+251",
  "+500",
  "+298",
  "+679",
  "+358",
  "+33",
  "+596",
  "+594",
  "+689",
  "+241",
  "+220",
  "+995",
  "+49",
  "+233",
  "+350",
  "+30",
  "+299",
  "+1 473",
  "+590",
  "+1 671",
  "+502",
  "+224",
  "+245",
  "+595",
  "+509",
  "+504",
  "+852",
  "+36",
  "+354",
  "+91",
  "+62",
  "+98",
  "+964",
  "+353",
  "+972",
  "+39",
  "+225",
  "+1 876",
  "+81",
  "+962",
  "+7 7",
  "+254",
  "+686",
  "+965",
  "+996",
  "+856",
  "+371",
  "+961",
  "+266",
  "+231",
  "+218",
  "+423",
  "+370",
  "+352",
  "+853",
  "+389",
  "+261",
  "+265",
  "+60",
  "+960",
  "+223",
  "+356",
  "+692",
  "+596",
  "+222",
  "+230",
  "+262",
  "+52",
  "+691",
  "+1 808",
  "+373",
  "+377",
  "+976",
  "+382",
  "+1664",
  "+212",
  "+95",
  "+264",
  "+674",
  "+977",
  "+31",
  "+599",
  "+1 869",
  "+687",
  "+64",
  "+505",
  "+227",
  "+234",
  "+683",
  "+672",
  "+850",
  "+1 670",
  "+47",
  "+968",
  "+92",
  "+680",
  "+970",
  "+507",
  "+675",
  "+595",
  "+51",
  "+63",
  "+48",
  "+351",
  "+1 787",
  "+974",
  "+262",
  "+40",
  "+7",
  "+250",
  "+685",
  "+378",
  "+966",
  "+221",
  "+381",
  "+248",
  "+232",
  "+65",
  "+421",
  "+386",
  "+677",
  "+27",
  "+500",
  "+82",
  "+34",
  "+94",
  "+249",
  "+597",
  "+268",
  "+46",
  "+41",
  "+963",
  "+886",
  "+992",
  "+255",
  "+66",
  "+670",
  "+228",
  "+690",
  "+676",
  "+1 868",
  "+216",
  "+90",
  "+993",
  "+1 649",
  "+688",
  "+1 340",
  "+256",
  "+380",
  "+971",
  "+44",
  "+1",
  "+598",
  "+998",
  "+678",
  "+58",
  "+84",
  "+1 808",
  "+681",
  "+967",
  "+260",
  "+255",
  "+263",
];

export const INDUSTRIES = [STRINGS.IT];
export const VIDEO_MONETIZED_STATUS = [STRINGS.pending, STRINGS.approved, STRINGS.rejected];

export const TRANSACTION_TYPES = {
  NFT_LEVEL_UPGRADE: "nft_level_upgrade",
  POST_REQARDS: "post_rewards",
  BUY_NFT: "buy_nft",
  ADMIN_SHARE: "admin_share",
  CAMPAIGN_PUBLISH: "campaign_publish",
  INCREASE_CAMPAIGN_BUDGET: "increase_campaign_budget",
  CAMPAIGN_VIDEO_PAYOU: "campaign_video_payout",
  CAMPAIGN_CANCEL_SHARE: "campaign_cancel_share",
  CAMPAIGN_VIDEO_REMAIN_PAYOUT: "campaign_video_remain_payout",
  CONTENT_SUBSCRIPTION: "content_subscription",
  CONTENT_SUBSCRIPTION_REFUND: "content_subscription_refund",
  ADMIN_SEND_GIFT: "admin_send_gift",
  CREATOR_SEND_GIFT: "creator_send_gift",
  WALLET_DEPOSIT: "wallet_deposit",
  WALLET_WITHDRAW: "wallet_withdraw",
  SEND_GIFT: "send_gift",
  OTHERS: "others",
};

export const FEE_TYPE = {
  WALLET_TRANSFER_BASE_AMOUNT: "Wallet transfer base amount",
};

export const STATUS = [STRINGS.active, STRINGS.inActive];
export const NFTSTATUS = [{ label: "On Sale", value: STRINGS.onSale }];
export const NFTUPDATESTATUS = [
  { label: "Select Status", value: null },
  { label: "On Sale", value: STRINGS.onSale },
  { label: "Available for sale", value: STRINGS.availableforSale },
];
export const LEVELTYPES = [
  { label: "Select Level", value: null },
  { label: "Level 0", value: 0 },
  { label: "Level 1", value: 1 },
  { label: "Level 2", value: 2 },
];
export const DATESORT = [
  { label: "Sort By", value: null },
  { label: "Low to High", value: "asc" },
  { label: "High to Low", value: "desc" },
];
export const SORTTYPES = [
  { label: "Select Sort Type", value: null },
  { label: "Price", value: "price" },
  { label: "Date", value: "createdAt" },
  { label: "Total Budget", value: "total_budget" },
];
export const STATUSOBJ = [
  { label: "Select Status", value: null },
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
  { label: "Completed", value: "completed" },
];
export const CREATORVIDEOSSTATUS = [
  { label: "Select Status", value: null },
  { label: "Pending", value: "Pending" },
  { label: "Approved", value: "Approved" },
  { label: "Rejected", value: "Rejected" },
];
export const MAXPARTICIPANTS = [
  { label: 100, value: 100 },
  { label: 200, value: 200 },
];
export const CONTENTPRIVACY = [
  { label: t("fan.subscriptions.public"), value: "Public" },
  { label: t("fan.subscriptions.private"), value: "Private" },
  { label: t("fan.subscriptions.subscribed"), value: "Subscribed" },
];
export const CONTENTPRIVACYMYFEED = [
  { label: t("fan.subscriptions.public"), value: "Public" },
  { label: t("fan.subscriptions.subscribed"), value: "Subscribed" },
];
export const CONTENTDATATYPE: { [x: string]: string } = {
  Image: "Image",
  Video: "Video",
  Audio: "Audio",
};
export const ADMINCREATORVIDEOSSTATUS = [
  { label: "Select Status", value: null },
  { label: "Pending", value: "Pending" },
  { label: "Accepted", value: "Accepted" },
  { label: "Approved", value: "Approved" },
  { label: "Reported", value: "Reported" },
  { label: "Rejected", value: "Rejected" },
];
export const SOCIALMEDIAS = [
  { label: "Youtube", value: STRINGS.youtube },
  { label: "Tiktok", value: STRINGS.tiktok },
  // { label: "Twitter", value: STRINGS.twitter },
  // { label: "Instagram", value: STRINGS.instagram },
];
export const AIRDROP_CONDITIONS = [
  "common.conditions1",
  "common.conditions2",
  "common.conditions3",
];
export const CURRENCYLIST = [{ label: STRINGS.lewk, value: STRINGS.lewk }];
export const LANGUAGES: {
  icon: ReactNode;
  label: string;
  value: LANGUAGESTYPE;
  shortValue: string;
}[] = [
  { icon: <English />, label: "common.english", value: LANGUAGESTYPE.english, shortValue: "en" },
  { icon: <Russian />, label: "common.russian", value: LANGUAGESTYPE.russian, shortValue: "ru" },
  {
    icon: <Ukrainian />,
    label: "common.ukrainian",
    value: LANGUAGESTYPE.ukrainian,
    shortValue: "uk",
  },
];
export const CHAT_SETTINGS: { label: string; value: AllowedMessagesFrom }[] = [
  { label: "new_chat.settings.all", value: "allow_all" },
  { label: "new_chat.settings.subscribers", value: "only_subscribers" },
  { label: "new_chat.settings.block", value: "block_all" },
];
export const TARGET_PLATFORMS_OPTIONS = [
  { label: "Select Platform", value: "" },
  { label: "Twitter", value: STRINGS.twitter },
  { label: "Youtube", value: STRINGS.youtube },
  { label: "Tiktok", value: STRINGS.tiktok },
  { label: "Instagram", value: STRINGS.instagram },
];
export const SORTFILTERTYPES = [
  { label: "Sort By", value: null },
  { label: "Date: New to Old", value: "createdAt,desc" },
  { label: "Date: Old to New", value: "createdAt,asc" },
  { label: "Price: Low to High", value: "price,asc" },
  { label: "Price: High to low", value: "price,desc" },
];
export const CAMPAIGNSORTFILTERTYPES = [
  { label: "Sort By", value: null },
  { label: "Date: New to Old", value: "createdAt,desc" },
];
export const CONTENTSORTFILTERTYPES = [
  { label: "Sort By", value: null },
  { label: "Date: New to Old", value: "createdAt,desc" },
  { label: "Date: Old to New", value: "createdAt,asc" },
];
export const CAMPAIGNSTATUS = [
  { label: "Select Status", value: null },
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
  { label: "Completed", value: "Completed" },
];
export const TARGET_PLATFORMS = [STRINGS.tiktok, STRINGS.twitter, STRINGS.youtube];
export const VIDEOSTATUS = [
  { label: "Select Status", value: null },
  { label: "Pending", value: "Pending" },
  { label: "Approved", value: "Approved" },
  { label: "Rejected", value: "Rejected" },
];
export const CURRENCYTYPE = [
  { label: STRINGS.lewk, value: STRINGS.lewk },
  { label: STRINGS.usdc, value: STRINGS.usdc },
];
export const PAYMENTSTATUS = [
  { label: "Select Status", value: null },
  { label: "Pending", value: "PENDING" },
  { label: "Failed", value: "FAILED" },
  { label: "Submitted", value: "SUBMITTED" },
  { label: "Completed", value: "COMPLETED" },
];
export const PAYMENTSEARCHTYPES = [
  { label: "Search By", value: null },
  { label: "Creator Username", value: "username" },
  { label: "Transaction ID", value: "payment_id" },
];
export const CONTENTTYPES = [
  { label: "Select Type", value: null },
  { label: "Image", value: "Image" },
  { label: "Video", value: "Video" },
  { label: "Audio", value: "Audio" },
  { label: "Text", value: "Text" },
];
export const TRANSACTIONTYPES = [
  { label: "Select Transaction Type", value: "" },
  { label: "Deposit", value: "Deposit" },
  { label: "Withdraw", value: "Withdraw" },
  { label: "Gift", value: "Gift" },
  { label: "Fee", value: "Fee" },
];
export const FEECATEGORY = [
  { label: "Select Fee Category", value: "" },
  { label: "Campaign fee", value: "campaign_cancel_share" },
  { label: "Gift fee", value: "send_gift" },
  { label: "NFT Reselling fee", value: "admin_share" },
];
export const FANCREATORROLE = [
  { label: "Select Role", value: "" },
  { label: "Creator", value: "creator" },
  { label: "Fan", value: "fan" },
];
export const PROPOSALDATATYPE = [
  { label: "Image", value: "Image" },
  { label: "Video", value: "Video" },
  { label: "Audio", value: "Audio" },
  { label: "Document", value: "Document" },
];
export const ACTIVECAMPAIGNIDEASSORTFILTERTYPES = [
  { label: "Sort By", value: null },
  { label: "Date: New to Old", value: "createdAt,desc" },
  { label: "Date: Old to New", value: "createdAt,asc" },
  { label: "Reward: Low to High", value: "min_reward_per_view,asc" },
  { label: "Reward: High to Low", value: "min_reward_per_view,desc" },
];
export const PUBLISHERACCOUNTTYPE = [
  { label: "Select Account Type", value: "" },
  { label: "Agency", value: "Agency" },
  { label: "Freelancer", value: "Freelancer" },
  { label: "Single Brand", value: "Single Brand" },
];
export const CAMPAIGNTYPE = [
  { label: "Select Campaign Type", value: "" },
  { label: "Campaign Ideas", value: "ideaBased" },
  { label: "Standalone", value: "standAlone" },
];
export const CAMPAIGNFOCUS = [
  { label: "Brand awareness", value: "Brand awareness" },
  { label: "Product awareness", value: "Product awareness" },
  { label: "Promotion", value: "Promotion" },
  { label: "Challenge", value: "Challenge" },
  { label: "Leads Generation", value: "Leads Generation" },
  { label: "Mention", value: "Mention" },
  { label: "Review", value: "Review" },
];
export const IDEACAMPAIGNSORTTYPES = [
  { label: "Date: New to Old", value: -1 },
  { label: "Date: Old to New", value: 1 },
];
export const CAMPAIGNIDEASSORTFILTERTYPES = [
  { label: "Sort By", value: null },
  { label: "Date: New to Old", value: "createdAt,desc" },
  { label: "Date: Old to New", value: "createdAt,asc" },
];
export const TRENDSFILTERTYPE = [
  { label: "All", value: "All" },
  { label: "Me", value: "Me" },
  { label: "Others", value: "Others" },
];
export const PROFILEVERIFICATIONSTATUS = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];
export const TRENDSFILTERTYPEJUDGE = [{ label: "All", value: "All" }];
export const TEMPLATESTATUS: Option[] = [
  { label: "Enabled", value: "enabled" },
  { label: "Disabled", value: "disabled" },
];
export const SORTCHALLENGE = [
  { label: t("creator.challenges.high"), value: "priceHighToLow" },
  { label: t("creator.challenges.low"), value: "priceLowToHigh" },
];
export const SORTPLANS: Option[] = [
  { label: t("creator.subscription.subHigh"), value: "SubsHighToLow" },
  { label: t("creator.subscription.subLow"), value: "SubsLowToHigh" },
  { label: t("creator.subscription.new"), value: "NewToOld" },
  { label: t("creator.subscription.old"), value: "OldToNew" },
  { label: t("creator.challenges.high"), value: "PriceHighToLow" },
  { label: t("creator.challenges.low"), value: "PriceLowToHigh" },
];
export const SORTFEED: Option[] = [
  { label: t("creator.subscription.new"), value: "NewToOld" },
  { label: t("creator.subscription.old"), value: "OldToNew" },
  { label: t("common.highViews"), value: "ViewsHighToLow" },
  { label: t("common.lowViews"), value: "ViewsLowToHigh" },
];
export const SORTFANPLANS: Option[] = [
  { label: t("creator.subscription.postsHigh"), value: "PostsHighToLow" },
  { label: t("creator.subscription.postsLow"), value: "PostsLowToHigh" },
  { label: t("creator.subscription.new"), value: "NewToOld" },
  { label: t("creator.subscription.old"), value: "OldToNew" },
  { label: t("creator.challenges.high"), value: "PriceHighToLow" },
  { label: t("creator.challenges.low"), value: "PriceLowToHigh" },
];
export const STATUSFILTER = [
  { label: t("creator.challenges.all"), value: undefined },
  { label: t("creator.challenges.active"), value: true },
  { label: t("creator.challenges.closed"), value: false },
];
export const STATUSFILTERPLANS = [
  { label: t("creator.challenges.all"), value: undefined },
  { label: t("creator.challenges.active"), value: 1 },
  { label: t("creator.challenges.closed"), value: 0 },
];
export const PRIVACYFILTER = [
  { label: t("creator.challenges.all"), value: undefined },
  { label: t("fan.subscriptions.subscriptions"), value: "Subscribed" },
  { label: t("fan.subscriptions.public"), value: "Public" },
];
export const ENABLEDFILTER = [
  { label: t("creator.challenges.all"), value: undefined },
  { label: t("creator.challenges.active"), value: 1 },
  { label: t("common.archived"), value: 0 },
];

export const VISIBILITY_FILTER = [
  { label: t("creator.challenges.all"), value: undefined },
  { label: t("common.visible"), value: 1 },
  { label: t("common.hidden"), value: 0 },
];
export const GIFTING_TYPE_FILTER = [
  { label: t("creator.challenges.all"), value: undefined },
  { label: t("common.sent"), value: "sent" },
  { label: t("common.received"), value: "received" },
];
export const MY_TRANSACTION_TYPE_FILTER = [
  { label: t("creator.challenges.all"), value: undefined },
  { label: t("creator.gifting.gifting"), value: "gifting" },
  { label: t("common.subscription"), value: "subscription" },
];
export const SUBSCRIBEDFILTER = [
  { label: t("creator.challenges.all"), value: undefined },
  { label: t("fan.subscriptions.subscribeFilt"), value: 1 },
  { label: t("fan.subscriptions.unsubscribeFilt"), value: 0 },
];
export const COSTFILTER = [
  { label: t("creator.challenges.all"), value: "all" },
  { label: t("creator.challenges.free"), value: "free" },
  { label: t("creator.challenges.paid"), value: "paid" },
];
export const USERSTATUS = [
  { label: t("common.allUsers"), value: undefined },
  { label: t("common.latest"), value: "latest" },
  { label: t("common.Active"), value: STRINGS.active },
  { label: t("common.Inactive"), value: STRINGS.inActive },
];
export const AriCurrencies = [
  { label: "https://ari10-widget.s3.amazonaws.com/prd/icons/USDIcon.svg", value: "USD" },
  { label: "https://ari10-widget.s3.amazonaws.com/prd/icons/EURIcon.svg", value: "EUR" },
];
export const SOCIALSFILTER: IFilterOption[] = [
  {
    name: "all",
    icon: t("creator.challenges.all") as string,
  },
  {
    name: STRINGS.tiktok,
    icon: <TikTokDarkColor />,
  },
  // {
  //   name: STRINGS.instagram,
  //   icon: <InstagramFilter />,
  // },
  // {
  //   name: STRINGS.twitter,
  //   icon: <TwitterFilter />,
  // },
  {
    name: STRINGS.youtube,
    icon: <YouTubeColor />,
  },
];
export const CONTENTFILTER: IFilterOption[] = [
  {
    name: "all",
    icon: t("creator.challenges.all") as string,
  },
  {
    name: "Video",
    icon: <CirclePlay />,
  },
  {
    name: "Audio",
    icon: <Audio />,
  },
  {
    name: "Image",
    icon: <DefaultIcon />,
  },
];

export const MESSAGESTATUSFILTER: IFilterOption[] = [
  {
    name: "all",
    icon: t("creator.challenges.all") as string,
  },
  {
    name: "unread",
    icon: <Unread />,
  },
  {
    name: "read",
    icon: <Read />,
  },
];

export const MESSAGETYPES = {
  text: "text",
  video: "video",
  image: "image",
  audio: "audio",
};

export const FOLLOWERSFILTER: Option[] = [
  { label: t("creator.challenges.all"), value: 0 },
  // { label: "10K", value: 10000 },
  // { label: "50K", value: 50000 },
  { label: "100K", value: 100000 },
  { label: "1M", value: 1000000 },
  { label: "3M", value: 3000000 },
];

export const ASSIGNEDTO: Option[] = [
  { label: "None", value: "none" },
  { label: "Fan", value: "fan" },
  { label: "Creator", value: "creator" },
  { label: "Publisher", value: "publisher" },
];

export const REWARDTYPES = {
  free: "free",
  joining: "joining",
  milestone: "views",
};
export const REWARDS = [
  { value: REWARDTYPES.free, label: t("creator.challenges.free") },
  { value: REWARDTYPES.joining, label: t("creator.challenges.rewardJoining") },
  { value: REWARDTYPES.milestone, label: t("creator.challenges.rewardViews") },
];
export const REWARDSSUBMISSION = [
  { value: REWARDTYPES.free, label: t("creator.challenges.free") },
  { value: REWARDTYPES.joining, label: t("creator.challenges.byJoining") },
  { value: REWARDTYPES.milestone, label: t("creator.challenges.byViews1") },
];
export const MILESTONELEGEND = [
  { color: colors.yellow.secondary, text: t("creator.challenge_details.min") },
  { color: colors.green.primary, text: t("creator.challenge_details.max") },
];
export const VERIFYSOCIALS = [
  {
    name: t("common.user_profile.ver_youtube"),
    value: STRINGS.youtube,
    icon: <YoutubeColor />,
  },
  {
    name: t("common.user_profile.ver_tiktok"),
    value: STRINGS.tiktok,
    icon: <TikTokColor />,
  },
];
export const FOOTERSOCIALS = [
  {
    icon: <FaTwitter size={24} />,
    label: t("common.user_profile.twitter"),
    link: "https://twitter.com/lewkcom",
  },
  {
    icon: <FaPaperPlane size={24} />,
    label: t("common.user_profile.telegram"),
    link: "https://t.me/Lewkcom",
  },
  {
    icon: <FaTiktok size={24} />,
    label: t("common.user_profile.tiktok"),
    link: "https://www.tiktok.com/@lewkcom",
  },
  {
    icon: <FaYoutube size={24} />,
    label: t("common.user_profile.youtube"),
    link: "https://www.youtube.com/channel/UC2povWwjsT8MKS1NVd-xqug",
  },
];
export const FOOTERCONTACT = [
  {
    label: "contact@lewk.com",
  },
  {
    label: "pr@lewk.com",
  },
  {
    label: "hr@lewk.com",
  },
];

export const CreatorDesc = [
  t("common.influencers"),
  t("common.contentCreators"),
  t("common.celebrities"),
];

export const FanDesc = [t("common.Followers"), t("common.fans"), t("common.contentViewers")];

export const FOOTERRESOURCES = [
  {
    label: t("home.terms_service"),
    link: "/terms",
  },
  {
    label: t("home.privacy_policy"),
    link: "/privacy",
  },
];

export const PROFILEACTIONS: ITabs[] = [
  {
    label: t("creator.profile.general"),
    link: "/general",
    key: 0,
  },
  {
    label: t("creator.profile.profileVerification"),
    link: "/verifysocials",
    key: 1,
  },
  {
    label: t("creator.profile.credentials"),
    link: "/profilecredentials",
    key: 2,
  },
  {
    label: t("creator.profile.notifications"),
    link: "/notifications",
    key: 3,
  },
  {
    label: t("creator.profile.mfa"),
    link: "/mfa",
    key: 4,
  },
];

export const PROFILEFANACTIONS: ITabs[] = [
  {
    label: t("creator.profile.general"),
    link: "/general",
    key: 0,
  },
  {
    label: t("creator.profile.credentials"),
    link: "/profilecredentials",
    key: 1,
  },
  {
    label: t("creator.profile.mfa"),
    link: "/mfa",
    key: 2,
  },
];

export const PROFILEACTIONSSELECT: Option[] = [
  {
    label: t("creator.profile.general"),
    value: "/general",
  },
  {
    label: t("creator.profile.profileVerification"),
    value: "/verifysocials",
  },
  {
    label: t("creator.profile.credentials"),
    value: "/profilecredentials",
  },
  {
    label: t("creator.profile.notifications"),
    value: "/notifications",
  },
  {
    label: t("creator.profile.mfa"),
    value: "/mfa",
  },
];

export const PROFILEFANACTIONSSELECT: Option[] = [
  {
    label: t("creator.profile.general"),
    value: "/general",
  },
  {
    label: t("creator.profile.credentials"),
    value: "/profilecredentials",
  },
  {
    label: t("creator.profile.mfa"),
    value: "/mfa",
  },
];

export const DETAILSPROFILETABS: ITabs[] = [
  {
    label: t("common.links"),
    key: 0,
  },
  {
    label: t("common.subscription_plans"),
    key: 1,
  },
];

export const PayStatus = {
  pay_pending: "pay_pending",
  declined: "declined",
  pay_pending_for_all: "pay_pending_for_all",
};

export const AdminChallengeStatus = [
  {
    value: PayStatus.declined,
    label: t("common.rejectAll"),
    className: "red",
  },
  {
    value: PayStatus.pay_pending,
    label: t("common.manually"),
    className: "white",
  },
  {
    value: PayStatus.pay_pending_for_all,
    label: t("common.payAll"),
    className: "green",
  },
];

export const AdminParticipateStatus = [
  {
    value: "",
    label: "",
    className: "",
  },
  {
    value: PayStatus.declined,
    label: t("common.reject"),
    className: "red",
  },
  {
    value: PayStatus.pay_pending,
    label: t("common.pay"),
    className: "green",
  },
];

export const SocialIcons: IconOption[] = [
  { label: <DiscordColor />, title: "Discord", value: "discord" },
  { label: <EtsyColor />, title: "Etsy", value: "etsy" },
  { label: <FacebookColor />, title: "Facebook", value: "facebook" },
  { label: <InstagramColor />, title: "Instagram", value: "instagram" },
  { label: <LinkedinColor />, title: "Linkedin", value: "linkedin" },
  { label: <MediumDarkColor />, title: "Medium", value: "medium" },
  { label: <MessengerColor />, title: "Messenger", value: "messenger" },
  { label: <OdnoklassnikiColor />, title: "Odnoklassniki", value: "odnoklassniki" },
  { label: <OnlyfansColor />, title: "Onlyfans", value: "onlyfans" },
  { label: <PinterestColor />, title: "Pinterest", value: "pinterest" },
  { label: <RedditColor />, title: "Reddit", value: "reddit" },
  { label: <SnapchatColor />, title: "Snapchat", value: "snapchat" },
  { label: <TelegramColor />, title: "Telegram", value: "telegram" },
  { label: <ThreadsColor />, title: "Threads", value: "threads" },
  { label: <TikTokDarkColor />, title: "Tiktok", value: "tiktok" },
  { label: <TumblrDarkColor />, title: "Tumblr", value: "tumblr" },
  { label: <TwitterDarkColor />, title: "Twitter", value: "twitter" },
  { label: <VKColor />, title: "Vkontakte", value: "vkontakte" },
  { label: <WeiboColor />, title: "Weibo", value: "weibo" },
  { label: <WhatsappColor />, title: "Whatsapp", value: "whatsapp" },
  { label: <YouTubeColor />, title: "Youtube", value: "youtube" },
  { label: <IoShareSocial size={24} />, title: "", value: "default1" },
];

export const PreviewSocialIconsSmall: IconOption[] = [
  { label: <FaDiscord size={16} />, title: "Discord", value: "discord" },
  { label: <LiaEtsy size={16} />, title: "Etsy", value: "etsy" },
  { label: <AiFillFacebook size={16} />, title: "Facebook", value: "facebook" },
  { label: <AiFillInstagram size={16} />, title: "Instagram", value: "instagram" },
  { label: <AiFillLinkedin size={16} />, title: "Linkedin", value: "linkedin" },
  { label: <RiMessengerFill size={16} />, title: "Messenger", value: "messenger" },
  { label: <FaOdnoklassnikiSquare size={16} />, title: "Odnoklassniki", value: "odnoklassniki" },
  { label: <TbBrandOnlyfans size={16} />, title: "Onlyfans", value: "onlyfans" },
  { label: <ImPinterest size={16} />, title: "Pinterest", value: "pinterest" },
  { label: <IoLogoReddit size={16} />, title: "Reddit", value: "reddit" },
  { label: <FaSnapchat size={16} />, title: "Snapchat", value: "snapchat" },
  { label: <FaTelegramPlane size={16} />, title: "Telegram", value: "telegram" },
  { label: <Threads size={16} />, title: "Threads", value: "threads" },
  { label: <FaTiktok size={16} />, title: "Tiktok", value: "tiktok" },
  { label: <IoLogoTumblr size={16} />, title: "Tumblr", value: "tumblr" },
  { label: <IoLogoTwitter size={16} />, title: "Twitter", value: "twitter" },
  { label: <SlSocialVkontakte size={16} />, title: "Vkontakte", value: "vkontakte" },
  { label: <FaWeibo size={16} />, title: "Weibo", value: "weibo" },
  { label: <IoLogoWhatsapp size={16} />, title: "Whatsapp", value: "whatsapp" },
  { label: <AiFillYoutube size={16} />, title: "Youtube", value: "youtube" },
  { label: <IoShareSocial size={16} />, title: "", value: "default1" },
];

export const SocialIconsSmall: IconOption[] = [
  { label: <FaDiscord size={12} />, title: "Discord", value: "discord" },
  { label: <LiaEtsy size={12} />, title: "Etsy", value: "etsy" },
  { label: <AiFillFacebook size={12} />, title: "Facebook", value: "facebook" },
  { label: <AiFillInstagram size={12} />, title: "Instagram", value: "instagram" },
  { label: <AiFillLinkedin size={12} />, title: "Linkedin", value: "linkedin" },
  { label: <RiMessengerFill size={12} />, title: "Messenger", value: "messenger" },
  { label: <FaOdnoklassnikiSquare size={12} />, title: "Odnoklassniki", value: "odnoklassniki" },
  { label: <TbBrandOnlyfans size={12} />, title: "Onlyfans", value: "onlyfans" },
  { label: <ImPinterest size={12} />, title: "Pinterest", value: "pinterest" },
  { label: <IoLogoReddit size={12} />, title: "Reddit", value: "reddit" },
  { label: <FaSnapchat size={12} />, title: "Snapchat", value: "snapchat" },
  { label: <FaTelegramPlane size={12} />, title: "Telegram", value: "telegram" },
  { label: <Threads size={12} />, title: "Threads", value: "threads" },
  { label: <FaTiktok size={12} />, title: "Tiktok", value: "tiktok" },
  { label: <IoLogoTumblr size={12} />, title: "Tumblr", value: "tumblr" },
  { label: <IoLogoTwitter size={12} />, title: "Twitter", value: "twitter" },
  { label: <SlSocialVkontakte size={12} />, title: "Vkontakte", value: "vkontakte" },
  { label: <FaWeibo size={12} />, title: "Weibo", value: "weibo" },
  { label: <IoLogoWhatsapp size={12} />, title: "Whatsapp", value: "whatsapp" },
  { label: <AiFillYoutube size={12} />, title: "Youtube", value: "youtube" },
  { label: <IoShareSocial size={12} />, title: "", value: "default1" },
];

export const PatternIcons = [
  { icon: <Cat />, name: "cat" },
  { icon: <Crown />, name: "crown" },
  { icon: <Donut />, name: "donut" },
  { icon: <Flower />, name: "flower" },
  { icon: <Legs />, name: "legs" },
  { icon: <Light />, name: "light" },
  { icon: <Love />, name: "love" },
  { icon: <Paw />, name: "paw" },
  { icon: <Rocket />, name: "rocket" },
  { icon: <Smile />, name: "smile" },
];

export const PatternIconsThin = [
  { icon: <CatThin />, name: "cat" },
  { icon: <CrownThin />, name: "crown" },
  { icon: <DonutThin />, name: "donut" },
  { icon: <FlowerThin />, name: "flower" },
  { icon: <LegsThin />, name: "legs" },
  { icon: <LightThin />, name: "light" },
  { icon: <LoveThin />, name: "love" },
  { icon: <PawThin />, name: "paw" },
  { icon: <RocketThin />, name: "rocket" },
  { icon: <SmileThin />, name: "smile" },
];

export const PatternDarkColors = {
  red: "#F43F5E",
  purple: "#EF82FF",
  pink: "#FF5EAD",
  orange: "#F97316",
  yellow: "#FFD11C",
  skyBlue: "#2FBEFF",
  lime: "#84CC16",
  teal: "#00FFC2",
};

export const PatternLightColors = {
  pink: "#FF5EAD",
  green: "#25C374",
  purple: "#5865F2",
  orange: "#F97316",
  pinkLight: "#EF82FF",
  red: "#F43F5E",
  gray: "#8F8FA8",
  blue: "#2FBEFF",
};

export const GradientDarkColors = [
  { color1: "#667EEA", color2: "#764BA2", theme: "dark" },
  { color1: "#6A11CB", color2: "#2575FC", theme: "dark" },
  { color1: "#09203F", color2: "#537895", theme: "dark" },
  { color1: "#13547A", color2: "#80D0C7", theme: "dark" },
  { color1: "#1E3C72", color2: "#2A5298", theme: "dark" },
];

export const GradientLightColors = [
  { color1: "#FF9A9E", color2: "#FAD0C4", theme: "light" },
  { color1: "#A18CD1", color2: "#FBC2EB", theme: "light" },
  { color1: "#FFECD2", color2: "#FCB69F", theme: "light" },
  { color1: "#F6D365", color2: "#FDA085", theme: "light" },
  { color1: "#A1C4FD", color2: "#C2E9FB", theme: "light" },
  { color1: "#D4FC79", color2: "#96E6A1", theme: "light" },
  { color1: "#84FAB0", color2: "#8FD3F4", theme: "light" },
  { color1: "#CFD9DF", color2: "#E2EBF0", theme: "light" },
  { color1: "#A6C0FE", color2: "#F68084", theme: "light" },
];

export const PAGINATIONLIMITS: Option[] = [
  {
    label: t("common.10lines"),
    value: 10,
  },
  {
    label: t("common.20lines"),
    value: 20,
  },
  {
    label: t("common.50lines"),
    value: 50,
  },
  {
    label: t("common.100lines"),
    value: 100,
  },
];

export enum TransactionStatus {
  SUBMITTED = "SUBMITTED",
  QUEUED = "QUEUED",
  PENDING_SIGNATURE = "PENDING_SIGNATURE",
  PENDING_AUTHORIZATION = "PENDING_AUTHORIZATION",
  PENDING_3RD_PARTY_MANUAL_APPROVAL = "PENDING_3RD_PARTY_MANUAL_APPROVAL",
  PENDING_3RD_PARTY = "PENDING_3RD_PARTY",

  PENDING = "PENDING",
  BROADCASTING = "BROADCASTING",
  CONFIRMING = "CONFIRMING",

  CONFIRMED = "CONFIRMED",
  COMPLETED = "COMPLETED",
  PENDING_AML_SCREENING = "PENDING_AML_SCREENING",
  PARTIALLY_COMPLETED = "PARTIALLY_COMPLETED",
  CANCELLING = "CANCELLING",
  CANCELLED = "CANCELLED",
  REJECTED = "REJECTED",
  FAILED = "FAILED",
  TIMEOUT = "TIMEOUT",
  BLOCKED = "BLOCKED",
}

export enum AriTransactionStatus {
  STARTED = "STARTED",
  PAID = "PAID",
  FINISHED = "FINISHED",
  CANCELLED = "CANCELLED",
}

function updateTranslations() {
  CONTENTPRIVACY.splice(
    0,
    3,
    { label: t("fan.subscriptions.public"), value: "Public" },
    { label: t("fan.subscriptions.private"), value: "Private" },
    { label: t("fan.subscriptions.subscribed"), value: "Subscribed" }
  );
  CONTENTPRIVACYMYFEED.splice(
    0,
    2,
    { label: t("fan.subscriptions.public"), value: "Public" },
    { label: t("fan.subscriptions.subscribed"), value: "Subscribed" }
  );
  PAGINATIONLIMITS.splice(
    0,
    4,
    {
      label: t("common.10lines"),
      value: 10,
    },
    {
      label: t("common.20lines"),
      value: 20,
    },
    {
      label: t("common.50lines"),
      value: 50,
    },
    {
      label: t("common.100lines"),
      value: 100,
    }
  );
  SORTCHALLENGE.splice(
    0,
    2,
    { label: t("creator.challenges.high"), value: "priceHighToLow" },
    { label: t("creator.challenges.low"), value: "priceLowToHigh" }
  );
  CreatorDesc.splice(
    0,
    3,
    t("common.influencers"),
    t("common.contentCreators"),
    t("common.celebrities")
  );
  FanDesc.splice(0, 3, t("common.Followers"), t("common.fans"), t("common.contentViewers"));
  FOOTERRESOURCES.splice(
    0,
    2,
    {
      label: t("home.terms_service"),
      link: "/terms",
    },
    {
      label: t("home.privacy_policy"),
      link: "/privacy",
    }
  );
  AdminChallengeStatus.splice(
    0,
    3,
    {
      value: PayStatus.declined,
      label: t("common.rejectAll"),
      className: "red",
    },
    {
      value: PayStatus.pay_pending,
      label: t("common.manually"),
      className: "white",
    },
    {
      value: PayStatus.pay_pending_for_all,
      label: t("common.payAll"),
      className: "green",
    }
  );
  AdminParticipateStatus.splice(
    0,
    3,
    {
      value: "",
      label: "",
      className: "",
    },
    {
      value: PayStatus.declined,
      label: t("common.reject"),
      className: "red",
    },
    {
      value: PayStatus.pay_pending,
      label: t("common.pay"),
      className: "green",
    }
  );
  FOOTERCONTACT.splice(
    0,
    3,
    {
      label: "contact@lewk.com",
    },
    {
      label: "pr@lewk.com",
    },
    {
      label: "hr@lewk.com",
    }
  );
  DETAILSPROFILETABS.splice(
    0,
    2,
    {
      label: t("common.links"),
      key: 0,
    },
    {
      label: t("common.subscription_plans"),
      key: 1,
    }
  );
  MILESTONELEGEND.splice(
    0,
    2,
    { color: colors.yellow.secondary, text: t("creator.challenge_details.min") },
    { color: colors.green.primary, text: t("creator.challenge_details.max") }
  );
  COSTFILTER.splice(
    0,
    3,
    { label: t("creator.challenges.all"), value: "all" },
    { label: t("creator.challenges.free"), value: "free" },
    { label: t("creator.challenges.paid"), value: "paid" }
  );
  USERSTATUS.splice(
    0,
    4,
    { label: t("common.allUsers"), value: undefined },
    { label: t("common.latest"), value: "latest" },
    { label: t("common.Active"), value: STRINGS.active },
    { label: t("common.Inactive"), value: "inactive" }
  );
  SUBSCRIBEDFILTER.splice(
    0,
    3,
    { label: t("creator.challenges.all"), value: undefined },
    { label: t("fan.subscriptions.subscribeFilt"), value: 1 },
    { label: t("fan.subscriptions.unsubscribeFilt"), value: 0 }
  );
  SORTPLANS.splice(
    0,
    6,
    { label: t("creator.subscription.subHigh"), value: "SubsHighToLow" },
    { label: t("creator.subscription.subLow"), value: "SubsLowToHigh" },
    { label: t("creator.subscription.new"), value: "NewToOld" },
    { label: t("creator.subscription.old"), value: "OldToNew" },
    { label: t("creator.challenges.high"), value: "PriceHighToLow" },
    { label: t("creator.challenges.low"), value: "PriceLowToHigh" }
  );
  SORTFEED.splice(
    0,
    4,
    { label: t("creator.subscription.new"), value: "NewToOld" },
    { label: t("creator.subscription.old"), value: "OldToNew" },
    { label: t("common.highViews"), value: "ViewsHighToLow" },
    { label: t("common.lowViews"), value: "ViewsLowToHigh" }
  );
  SORTFANPLANS.splice(
    0,
    6,
    { label: t("creator.subscription.postsHigh"), value: "PostsHighToLow" },
    { label: t("creator.subscription.postsLow"), value: "PostsLowToHigh" },
    { label: t("creator.subscription.new"), value: "NewToOld" },
    { label: t("creator.subscription.old"), value: "OldToNew" },
    { label: t("creator.challenges.high"), value: "PriceHighToLow" },
    { label: t("creator.challenges.low"), value: "PriceLowToHigh" }
  );
  STATUSFILTER.splice(
    0,
    3,
    { label: t("creator.challenges.all"), value: undefined },
    { label: t("creator.challenges.active"), value: true },
    { label: t("creator.challenges.closed"), value: false }
  );
  STATUSFILTERPLANS.splice(
    0,
    3,
    { label: t("creator.challenges.all"), value: undefined },
    { label: t("creator.challenges.active"), value: 1 },
    { label: t("creator.challenges.closed"), value: 0 }
  );
  PRIVACYFILTER.splice(
    0,
    3,
    { label: t("creator.challenges.all"), value: undefined },
    { label: t("fan.subscriptions.subscriptions"), value: "Subscribed" },
    { label: t("fan.subscriptions.public"), value: "Public" }
  );
  ENABLEDFILTER.splice(
    0,
    3,
    { label: t("creator.challenges.all"), value: undefined },
    { label: t("creator.challenges.active"), value: 1 },
    { label: t("common.archived"), value: 0 }
  );
  SOCIALSFILTER.splice(0, 1, {
    name: "all",
    icon: t("creator.challenges.all") as string,
  });
  CONTENTFILTER.splice(0, 1, {
    name: "all",
    icon: t("creator.challenges.all") as string,
  });
  MESSAGESTATUSFILTER.splice(0, 1, {
    name: "all",
    icon: t("creator.challenges.all") as string,
  });
  FOLLOWERSFILTER.splice(0, 1, {
    value: 0,
    label: t("creator.challenges.all"),
  });
  VERIFYSOCIALS.splice(
    0,
    2,
    {
      name: t("common.user_profile.ver_youtube"),
      value: STRINGS.youtube,
      icon: <YoutubeColor />,
    },
    {
      name: t("common.user_profile.ver_tiktok"),
      value: STRINGS.tiktok,
      icon: <TikTokColor />,
    }
  );
  REWARDS.splice(
    0,
    3,
    { value: REWARDTYPES.free, label: t("creator.challenges.free") },
    { value: REWARDTYPES.joining, label: t("creator.challenges.rewardJoining") },
    { value: REWARDTYPES.milestone, label: t("creator.challenges.rewardViews") }
  );
  REWARDSSUBMISSION.splice(
    0,
    3,
    { value: REWARDTYPES.free, label: t("creator.challenges.free") },
    { value: REWARDTYPES.joining, label: t("creator.challenges.byJoining") },
    { value: REWARDTYPES.milestone, label: t("creator.challenges.byViews1") }
  );
  FOOTERSOCIALS.splice(
    0,
    4,
    {
      icon: <FaTwitter size={24} />,
      label: t("common.user_profile.twitter"),
      link: "https://twitter.com/lewkcom",
    },
    {
      icon: <FaPaperPlane size={24} />,
      label: t("common.user_profile.telegram"),
      link: "https://t.me/Lewkcom",
    },
    {
      icon: <FaTiktok size={24} />,
      label: t("common.user_profile.tiktok"),
      link: "https://www.tiktok.com/@lewkcom",
    },
    {
      icon: <FaYoutube size={24} />,
      label: t("common.user_profile.youtube"),
      link: "https://www.youtube.com/channel/UC2povWwjsT8MKS1NVd-xqug",
    }
  );
  PROFILEACTIONS.splice(
    0,
    7,
    {
      label: t("creator.profile.general"),
      link: "/general",
      key: 0,
    },
    {
      label: t("creator.profile.profileVerification"),
      link: "/verifysocials",
      key: 1,
    },
    {
      label: t("creator.profile.credentials"),
      link: "/profilecredentials",
      key: 2,
    },
    {
      label: t("creator.profile.notifications"),
      link: "/notifications",
      key: 3,
    },
    {
      label: t("creator.profile.mfa"),
      link: "/mfa",
      key: 4,
    }
  );
  PROFILEFANACTIONS.splice(
    0,
    3,
    {
      label: t("creator.profile.general"),
      link: "/general",
      key: 0,
    },
    {
      label: t("creator.profile.credentials"),
      link: "/profilecredentials",
      key: 1,
    },
    {
      label: t("creator.profile.mfa"),
      link: "/mfa",
      key: 2,
    }
  );
  PROFILEACTIONSSELECT.splice(
    0,
    8,
    {
      label: t("creator.profile.general"),
      value: "/general",
    },
    {
      label: t("creator.profile.profileVerification"),
      value: "/verifysocials",
    },
    {
      label: t("creator.profile.credentials"),
      value: "/profilecredentials",
    },
    {
      label: t("creator.profile.notifications"),
      value: "/notifications",
    },
    {
      label: t("creator.profile.mfa"),
      value: "/mfa",
    },
    {
      label: t("creator.profile.delete"),
      value: "delete",
    }
  );
  PROFILEFANACTIONSSELECT.splice(
    0,
    4,
    {
      label: t("creator.profile.general"),
      value: "/general",
    },
    {
      label: t("creator.profile.credentials"),
      value: "/profilecredentials",
    },
    {
      label: t("creator.profile.mfa"),
      value: "/mfa",
    },
    {
      label: t("creator.profile.delete"),
      value: "delete",
    }
  );
}

if (i18n.isInitialized) {
  updateTranslations();
}

i18next.on("languageChanged", (lng) => {
  updateTranslations();
});

i18next.on("loaded", (lng) => {
  updateTranslations();
});
