import { debounce } from "lodash";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import { makeToast } from "common/helpers/helper";
import CheckboxField from "Components/CheckboxField";
import { STRINGS } from "constants/appConstants";
import {
  CheckBoxBlock,
  CheckBoxedForm,
} from "pages/creator/Bio/BioLink/components/CheckBoxesForm/style";
import { fetchUserDetailsAPI } from "services/axios";
import { updateProfileInfoAPI } from "services/UserServices";
import { getUser } from "store/slices/global/userSlice";

interface IFormikCheckboxes {
  sensitive: boolean;
  addPublicLinkPopup: boolean;
}
export const CheckBoxesForm = () => {
  const { t } = useTranslation();
  const userDetails = useSelector(getUser);
  const [sensitive, setSensitive] = useState<boolean>(false);
  const [addPublicLinkPopup, setAddPublicLinkPopup] = useState<boolean>(false);

  useEffect(() => {
    if (!userDetails) return;
    setSensitive(userDetails.sensitive);
    setAddPublicLinkPopup(!userDetails.addPublicLinkPopup);
  }, [userDetails]);

  const debouncedRequestAfterChange = useCallback(
    debounce((sensitive) => {
      handlePublicLinksUpdate({ sensitive }, t("creator.profile.sensitiveUpdated"));
    }, 2000),
    []
  );

  const handlePublicLinksUpdate = (values: Partial<IFormikCheckboxes>, message: string) => {
    updateProfileInfoAPI(
      {
        data: {
          ...values,
        },
        part: "general",
      },
      () => {
        fetchUserDetailsAPI();
        makeToast({
          message,
          type: STRINGS.toastType.success,
        });
      }
    );
  };

  return (
    <CheckBoxedForm>
      <CheckBoxBlock>
        <CheckboxField
          name="sensitive"
          value={sensitive}
          handleChange={(event: ChangeEvent<HTMLInputElement>) => {
            const checked = event.target.checked;
            setSensitive(checked);
            debouncedRequestAfterChange(checked);
          }}
          errMsg={false}
        />
        <div className="info_block">
          <div className="checkbox_title">{t("creator.profile.sensitive")}</div>
          <div className="checkbox_description">{t("creator.profile.sensitiveDesc")}</div>
        </div>
      </CheckBoxBlock>

      <div className="divider" />
      <CheckBoxBlock>
        <CheckboxField
          name="123"
          value={addPublicLinkPopup}
          handleChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (addPublicLinkPopup) return;
            const checked = event.target.checked;
            setAddPublicLinkPopup(checked);
            handlePublicLinksUpdate(
              { addPublicLinkPopup: !checked },
              t("creator.profile.dontShowReminderUpdated")
            );
          }}
          disabled={addPublicLinkPopup}
          errMsg={false}
        />
        <div className="info_block">
          <div className="checkbox_title">{t("creator.profile.dontShowReminder")}</div>
          <div className="checkbox_description">{t("creator.profile.dontShowReminderDesc")}</div>
        </div>
      </CheckBoxBlock>
    </CheckBoxedForm>
  );
};
