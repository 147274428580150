import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const FooterSocialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  color: ${(p) => p.theme.colors.text.primary};

  .block {
    display: flex;
    gap: 12px;
    flex-direction: column;

    .title {
      text-align: end;
      font-size: ${(p) => p.theme.typography.text3};
    }

    button {
      position: relative;
      padding: 0px;
      background: none;
      border: none;

      img {
        width: 100%;

        &.soon {
          position: absolute;
          right: 16px;
          width: fit-content;
        }
      }
    }

    a {
      cursor: pointer;
      color: ${(p) => p.theme.colors.text.primary};
      text-decoration: none;
    }

    &.images {
      max-width: 185px;
    }

    &.toRow {
      flex-direction: row;
      gap: 18px;
    }
  }

  ${deviceQuery.laptopM} {
    width: 45%;
  }

  ${deviceQuery.mobileL} {
    align-items: center;
    max-width: 250px;
    width: 100%;

    .block {
      .title {
        text-align: center;
      }
    }
  }
`;
