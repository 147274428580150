const zIndexs = {
  messagesTypingArea: 12,
  drawer: 20,
  drawerHeader: 24,
  header: 15,
  selectField: 14,
  profileLogoSvg: 6,
  profileLogoImgCon: 6,
  profileLogoImg: 3,
  profileLogoGreenArea: 5,
  profileLogoGreenLevel: 7,
  profileLogoGreenVerify: 7,
  imagePreview: 50,
};

export default zIndexs;
