const CirclePlay = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C17.5228 2 22 6.47716 22 12C22 17.5228 17.5228 22 12 22C6.47716 22 2 17.5228 2 12C2 6.47716 6.47716 2 12 2Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0497 12.4673C14.3207 13.2533 12.3367 14.5833 11.3217 15.0103C11.1597 15.0783 10.7467 15.2223 10.6577 15.2243C10.4687 15.2303 10.2867 15.1243 10.1987 14.9543C10.1647 14.8883 10.0647 14.4573 10.0327 14.2653C9.93766 13.6813 9.88866 12.7743 9.88966 11.8623C9.88866 10.9053 9.94166 9.95532 10.0477 9.37732C10.0757 9.22132 10.1577 8.86232 10.1817 8.80432C10.2267 8.69632 10.3087 8.61132 10.4077 8.55832C10.4837 8.51732 10.5707 8.49532 10.6577 8.49832C10.7467 8.50032 11.1087 8.62732 11.2327 8.67632C12.2107 9.05632 14.2797 10.4343 15.0397 11.2443C15.1077 11.3173 15.2947 11.5133 15.3257 11.5533C15.3967 11.6433 15.4317 11.7523 15.4317 11.8623C15.4317 11.9643 15.4007 12.0683 15.3367 12.1553C15.3037 12.2003 15.1127 12.4003 15.0497 12.4673Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CirclePlay;
