import { FC } from "react";

import { useTranslation } from "react-i18next";

import { BioLinkContainer } from "../BioLink/style";

import ArrowDownSmall from "assets/svg/ArrowDownSmall";
import Copy from "assets/svg/Copy";
import Infinite from "assets/svg/Infinite";
import LogoLewk from "assets/svg/LogoLewk";
import Profile from "assets/svg/Profile";

interface Props {
  mobile: boolean;
}

const DefaultBioLink: FC<Props> = ({ mobile }) => {
  const { t } = useTranslation();

  return (
    <BioLinkContainer mobile={mobile}>
      <div className="icon">
        <LogoLewk />
      </div>
      <div className="info">
        <p>{t("common.lewk")}</p>
        <p className="copy">
          {t("common.copyShare")}
          <Copy size={mobile ? 12 : 20} />
        </p>
      </div>
      <div className="spoiler">
        <div className="subscribers">
          <Profile size={mobile ? 12 : 20} />
          <Infinite size={mobile ? 12 : 20} />
        </div>
        <div className="divider" />
        <div className="arrow">
          <ArrowDownSmall />
        </div>
      </div>
    </BioLinkContainer>
  );
};

export default DefaultBioLink;
