import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "store/store";

interface IInitialState {
  available: number;
  reached: number;
}

const initialState: IInitialState = {
  available: 0,
  reached: 0,
};

const earningsSlice = createSlice({
  name: "earnings",
  initialState,
  reducers: {
    setEarnings: (state, action: PayloadAction<{ available: number; reached: number }>) => {
      const { available, reached } = action.payload;
      state.available = available;
      state.reached = reached;
    },
  },
});

export const { setEarnings } = earningsSlice.actions;

export default earningsSlice.reducer;

export const getEarnings = ({ creator: { challenging } }: RootState) => {
  return challenging.earnings;
};
