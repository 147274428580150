import styled from "styled-components";

export const ContentPreviewCardContainer = styled.div<{ profile: boolean; isValid: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;

  border: 1px solid
    ${(p) =>
      p.profile && p.isValid
        ? p.theme.colors.divider
        : p.isValid
        ? p.theme.colors.divider
        : p.theme.colors.text.error};

  .main_preview_content {
    width: 100%;
    height: 100%;
    min-height: 200px;

    .invalid_content_container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  .bottom_content {
    padding: 13px 16px;

    font-size: 15px;
    font-weight: 500;
    line-height: 22px;

    display: flex;
    gap: 12px;

    background-color: ${(p) =>
      p.profile ? p.theme.colors.text.primary : p.theme.colors.background.secondary};

    .letter_id {
      color: ${(p) => p.theme.colors.text.secondary};
    }

    .url {
      color: ${(p) => (p.profile ? p.theme.colors.text.links : p.theme.colors.text.primary)};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
