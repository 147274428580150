import { memo } from "react";

import { useTranslation } from "react-i18next";

import Button from "Components/Button";
import { ChatRequestActionsStyles } from "pages/public/chat/Components/Chat/ChatRequestActions/styles";
import { ChatStatus } from "types/new-messages.types";

interface Props {
  status: ChatStatus;
  onStatusUpdate: (status: ChatStatus) => void;
}
export const ChatRequestActions = memo(({ status, onStatusUpdate }: Props) => {
  const { t } = useTranslation();

  return (
    <ChatRequestActionsStyles>
      <Button
        className="chat_request_action_btn"
        size="sm"
        variant="third"
        onClick={() => onStatusUpdate("blocked")}
      >
        {t("new_chat.block_user")}
      </Button>
      <Button
        className="chat_request_action_btn"
        size="sm"
        variant="primary"
        onClick={() => onStatusUpdate("active")}
      >
        {t("new_chat.allow_chatting")}
      </Button>
    </ChatRequestActionsStyles>
  );
});
