const Ukrainian = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.39 19.08C8.38 18.43 7.59 15.88 7.49 12.73H19V12C19 7.04 14.96 3 10 3C5.04 3 1 7.04 1 12C1 16.96 4.71 20.65 9.39 20.97V19.08ZM17.5 11.27H13.97C13.9 8.92 13.47 6.46 12.53 4.91C15.23 5.87 17.22 8.33 17.5 11.27ZM9.9 4.75C9.9 4.75 9.97 4.73 10 4.73C10.03 4.73 10.06 4.75 10.1 4.75C11.35 4.75 12.39 7.61 12.51 11.27H7.49C7.61 7.62 8.65 4.75 9.9 4.75ZM2.5 12.72H6.03C6.1 15.07 6.53 17.53 7.47 19.08C4.77 18.12 2.78 15.66 2.5 12.72ZM6.85 6.27C6.38 7.62 6.11 9.38 6.05 11.27H2.5C2.79 8.31 4.79 5.86 7.49 4.9C7.26 5.27 7.04 5.72 6.85 6.28V6.27Z"
      fill="currentColor"
    />
    <path
      d="M16.22 14H15.16C15.09 14 15.04 14.05 15.04 14.12V18.25C15.04 18.55 14.98 18.81 14.86 19.03C14.74 19.24 14.58 19.41 14.38 19.54C13.98 19.77 13.39 19.77 12.98 19.54C12.77 19.42 12.61 19.25 12.48 19.03C12.36 18.81 12.3 18.54 12.3 18.24V14.12C12.3 14.05 12.24 14 12.18 14H11.12C11.05 14 11 14.05 11 14.12V18.25C11 18.77 11.12 19.24 11.35 19.66C11.59 20.07 11.91 20.4 12.31 20.64C12.71 20.88 13.18 21 13.68 21C14.18 21 14.65 20.88 15.05 20.64C15.45 20.4 15.77 20.07 16 19.66C16.23 19.25 16.35 18.78 16.35 18.26V14.12C16.35 14.05 16.29 14 16.23 14H16.22Z"
      fill="currentColor"
    />
    <path
      d="M20.3601 14.08C20.3601 14.08 20.3001 14 20.2501 14H19.5101C19.4601 14 19.4101 14.03 19.4001 14.08L16.7501 20.74C16.7501 20.74 16.7401 20.82 16.7601 20.85C16.7801 20.88 16.8201 20.9 16.8601 20.9H17.9801C18.0301 20.9 18.0801 20.87 18.0901 20.82L18.5601 19.61H21.1601L21.6201 20.82C21.6201 20.82 21.6801 20.9 21.7301 20.9H22.8701C22.8701 20.9 22.9501 20.88 22.9701 20.85C22.9901 20.82 23.0001 20.78 22.9801 20.74L20.3501 14.08H20.3601ZM20.7101 18.43H19.0301L19.8801 16.24L20.7201 18.43H20.7101Z"
      fill="currentColor"
    />
  </svg>
);

export default Ukrainian;
