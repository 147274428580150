import styled from "styled-components";

export const ChatRequestActionsStyles = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 8px;

  .chat_request_action_btn {
    min-width: 140px;
  }
`;
