import { memo, useCallback, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import ChatItem from "./components/ChatItem";
import NoChats from "./components/NoChats";
import { ChatListContainer } from "./style";

import MiniLoader from "Components/MiniLoader";
import {
  CHATS_LIMIT,
  chatsSelectors,
  getCanLoadMoreChats,
  getChatsSearch,
  getIsChatsLoading,
  getOpenedChat,
  setOpenedChat,
} from "store/slices/global/chatsSlice";
import { getUser } from "store/slices/global/userSlice";
import {
  MESSAGES_LIMIT,
  removeAllMessages,
  setRepliedMessage,
} from "store/slices/newMessages/messagesSlice";
import { getChatMessagesByChatId, getChatsByStatusAndRole } from "store/thunks/messages-thunks";
import { colors } from "styles/theme";
import { NewChatItem } from "types/new-messages.types";

const CHATS_BEFORE_TO_TRIGGER_LOAD = 3;

export const ChatList = memo(() => {
  const intRef = useRef<IntersectionObserver>();
  const dispatch = useDispatch();

  const chats = useSelector(chatsSelectors.selectAll);
  const openedChat = useSelector(getOpenedChat);
  const isLoading = useSelector(getIsChatsLoading);
  const canLoadMoreChats = useSelector(getCanLoadMoreChats);
  const search = useSelector(getChatsSearch);
  const user = useSelector(getUser);

  const lastChatRef = useCallback(
    (chat: HTMLDivElement) => {
      if (isLoading) return;

      if (intRef.current) intRef.current.disconnect();

      intRef.current = new IntersectionObserver((intersection) => {
        const entry = intersection[0];
        if (entry.isIntersecting) {
          const lastChat: NewChatItem | undefined = chats[chats.length - 1];
          if (!lastChat || !user) return;
          dispatch(
            getChatsByStatusAndRole({
              status: lastChat.status,
              role: user.role === "fan" ? "creator" : "fan",
              paginationData: {
                limit: CHATS_LIMIT,
                // if we have searched for a value, as an offset we need to pass chat ID cause list is sorted by chat creation date
                offsetId: search ? lastChat._id : lastChat.lastMessage._id,
              },
            })
          );
        }
      });

      if (chat) intRef.current.observe(chat);
    },
    [chats]
  );

  const onChatClicked = (chat: NewChatItem) => {
    if (
      openedChat &&
      chat._id === openedChat._id &&
      openedChat.firstUnreadMessage?._id === chat.firstUnreadMessage?._id
    )
      return;

    dispatch(setRepliedMessage(null));
    dispatch(setOpenedChat(chat));
    dispatch(removeAllMessages());

    // id might be null in case we did search and no messages were found but chat has been found by userName
    if (chat.firstUnreadMessage && chat.firstUnreadMessage._id) {
      goToFirstUnreadMessage(chat);
      return;
    }

    dispatch(
      getChatMessagesByChatId({
        chatId: chat._id,
        limit: MESSAGES_LIMIT,
      })
    );
  };

  const goToFirstUnreadMessage = (chat: NewChatItem) => {
    dispatch(
      getChatMessagesByChatId({
        chatId: chat._id,
        limit: MESSAGES_LIMIT,
        aroundOffsetId: chat.firstUnreadMessage?._id || undefined,
      })
    );
  };

  const getLastChatRefByIndex = (index: number) => {
    if (!canLoadMoreChats) return;
    if (chats.length < CHATS_BEFORE_TO_TRIGGER_LOAD) return;
    if (index === chats.length - CHATS_BEFORE_TO_TRIGGER_LOAD) return lastChatRef;
  };

  return (
    <ChatListContainer>
      {!chats.length && isLoading && <MiniLoader color={colors.green.primary} />}

      {chats.length ? (
        <>
          {chats.map((item, index) => (
            <ChatItem
              ref={getLastChatRefByIndex(index)}
              key={item._id}
              item={item}
              onChatClicked={onChatClicked}
              search={search}
              active={item._id === openedChat?._id}
            />
          ))}
        </>
      ) : (
        !isLoading && <NoChats />
      )}
    </ChatListContainer>
  );
});
