import styled from "styled-components";

export const EditBlockContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  border-radius: 12px;
  font-size: ${(p) => p.theme.typography.subText3};
  justify-content: space-between;

  .info {
    display: flex;
    color: ${(p) => p.theme.colors.text.secondary};
    flex-direction: column;
    font-size: ${(p) => p.theme.typography.subText3};

    .title {
      color: ${(p) => p.theme.colors.text.primary};
      font-size: ${(p) => p.theme.typography.subText5};
    }
  }

  .actions {
    display: flex;
    gap: 12px;
    justify-content: space-between;
    align-items: center;

    .buttons {
      border-radius: 8px;
      display: flex;
      gap: 8px;
      padding: 8px;
      background: ${(p) => p.theme.colors.background.secondary};

      .delete {
        color: ${(p) => p.theme.colors.icon.fourth};
      }

      .divider {
        width: 1px;
        height: 100%;
        background: ${(p) => p.theme.colors.divider};
      }
    }
  }
`;
