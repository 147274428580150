import { FC } from "react";

interface Props {
  size?: number;
}

const Rocket: FC<Props> = ({ size = 32 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.2797 2.71894C29.0097 2.44894 28.6397 2.08894 21.3197 3.96894C20.4597 4.18894 19.6797 4.63894 19.0597 5.25894L16.0497 8.26894C14.0797 10.2289 11.0097 10.0489 8.77973 9.54894C8.60973 9.50894 8.43974 9.55894 8.31974 9.67894L4.86973 13.1289C4.66973 13.3289 4.66973 13.6389 4.86973 13.8389L8.02973 17.0089L9.38973 18.3789C8.92973 19.1589 9.01973 20.1789 9.68973 20.8489L11.1597 22.3289C11.5397 22.7089 12.0497 22.9189 12.5897 22.9189C12.9597 22.9189 13.3097 22.8189 13.6197 22.6289L14.9797 23.9989L18.1397 27.1689C18.2297 27.2589 18.3597 27.3189 18.4897 27.3189C18.6197 27.3189 18.7497 27.2689 18.8397 27.1689L22.2897 23.7189C22.4097 23.5989 22.4597 23.4289 22.4197 23.2589C21.9197 21.0189 21.7397 17.9389 23.6997 15.9789L26.7097 12.9689C27.3397 12.3389 27.7797 11.5589 27.9997 10.7089C29.8897 3.36894 29.5197 3.00894 29.2597 2.73894L29.2797 2.71894ZM8.82973 10.5789C10.2897 10.8789 12.0397 11.0289 13.6897 10.6389L8.37974 15.9489L5.92972 13.4889L8.83973 10.5789H8.82973ZM12.5997 21.9089C12.3297 21.9089 12.0697 21.7989 11.8697 21.6089L10.3997 20.1289C10.1297 19.8589 10.0397 19.4689 10.1397 19.1189L11.5097 20.4889L12.8897 21.8689C12.7997 21.8989 12.6997 21.9089 12.6097 21.9089H12.5997ZM21.4097 23.1889L18.4997 26.0989L16.0497 23.6389L21.3597 18.3289C20.9697 19.9789 21.1097 21.7289 21.4097 23.1889ZM27.0597 10.4389C26.8797 11.1189 26.5297 11.7489 26.0297 12.2489L15.3497 22.9289L14.0397 21.6089L13.1997 20.7589L9.09974 16.6489L19.7797 5.96894C20.2797 5.46894 20.8997 5.11894 21.5797 4.93894C25.7297 3.86894 27.8397 3.49894 28.5197 3.49894C28.5197 4.17894 28.1397 6.28894 27.0697 10.4489L27.0597 10.4389Z"
      fill="currentColor"
    />
    <path
      d="M6.87973 21.5021C5.89973 21.6621 5.01972 22.4221 4.39972 23.6421C3.32972 25.7821 2.54973 28.7421 2.51973 28.8721C2.47973 29.0421 2.51972 29.2221 2.64972 29.3521C2.73972 29.4421 2.86973 29.5021 2.99973 29.5021C3.03973 29.5021 3.07973 29.5021 3.12973 29.4821C3.25973 29.4521 6.21973 28.6721 8.34973 27.6021C9.56973 26.9921 10.3297 26.1121 10.4897 25.1321C10.6397 24.2421 10.2797 23.3121 9.47972 22.5121C8.67972 21.7121 7.74972 21.3521 6.86972 21.5021H6.87973ZM9.50972 24.9721C9.39972 25.6321 8.82973 26.2521 7.90973 26.7121C6.53973 27.3921 4.76972 27.9721 3.72972 28.2721C4.03972 27.2321 4.60973 25.4621 5.29973 24.0921C5.75973 23.1621 6.37972 22.5921 7.03972 22.4921C7.11972 22.4821 7.18973 22.4721 7.26973 22.4721C7.76973 22.4721 8.28973 22.7321 8.77973 23.2221C9.34973 23.7921 9.59972 24.4021 9.50972 24.9721Z"
      fill="currentColor"
    />
    <path
      d="M18.9097 10.4844C18.2097 10.4844 17.5597 10.7544 17.0697 11.2444C16.0497 12.2644 16.0497 13.9144 17.0697 14.9344C17.5597 15.4244 18.2197 15.6944 18.9097 15.7044C19.6097 15.7044 20.2597 15.4344 20.7497 14.9444C21.2397 14.4544 21.5097 13.7944 21.5097 13.1044C21.5097 12.4044 21.2397 11.7544 20.7497 11.2644C20.2597 10.7744 19.6097 10.5044 18.9097 10.4944V10.4844ZM20.0397 14.2344C19.7397 14.5344 19.3297 14.7044 18.9097 14.7044C18.4797 14.7044 18.0797 14.5344 17.7697 14.2344C17.1397 13.6044 17.1397 12.5844 17.7697 11.9644C18.0697 11.6644 18.4797 11.4944 18.8997 11.4944C19.3297 11.4944 19.7297 11.6644 20.0297 11.9644C20.6597 12.5944 20.6597 13.6144 20.0297 14.2344H20.0397Z"
      fill="currentColor"
    />
  </svg>
);

export default Rocket;
