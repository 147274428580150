const OdnoklassnikiColor = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 23C9.38001 23 6.75001 23 4.13001 23C3.56001 23 3.03 22.83 2.54 22.53C2.03 22.21 1.64001 21.79 1.36001 21.25C1.17001 20.87 1.06001 20.47 1.02001 20.04C1.00001 19.78 1.01 19.51 1.01 19.25C1.01 14.27 1.01 9.3 1.01 4.32C1.01 3.57 1.2 2.89 1.65 2.3C2.16 1.62 2.83 1.21 3.65 1.06C3.89 1.02 4.13 1 4.37 1C9.5 1 14.63 1 19.75 1C20.52 1 21.22 1.23 21.82 1.72C22.46 2.24 22.84 2.91 22.97 3.73C23 3.92 23.01 4.1 23.01 4.29C23.01 9.43 23.01 14.57 23.01 19.71C23.01 20.47 22.8 21.16 22.33 21.76C21.84 22.39 21.2 22.79 20.41 22.94C20.2 22.98 20 23 19.79 23C17.2 23 14.6 23 12.01 23H12Z"
      fill="#F5911E"
    />
    <path
      d="M8.00003 8.22001C8.02003 5.99001 9.80003 4.21001 12 4.22001C14.21 4.23001 15.99 6.04001 15.97 8.24001C15.95 10.47 14.16 12.25 11.96 12.24C9.76002 12.22 7.98003 10.42 7.99003 8.22001H8.00003Z"
      fill="#FEFEFE"
    />
    <path
      d="M10.53 15.44C10.25 15.38 9.99 15.32 9.73 15.23C9.08 15.02 8.47 14.75 7.92 14.35C7.54 14.08 7.37 13.69 7.47 13.23C7.56 12.77 7.86 12.48 8.32 12.38C8.61 12.32 8.89 12.38 9.14 12.55C9.74 12.98 10.42 13.22 11.14 13.33C12.14 13.48 13.1 13.36 14.03 12.95C14.26 12.85 14.47 12.73 14.67 12.58C15.16 12.21 15.84 12.31 16.2 12.8C16.56 13.29 16.45 13.96 15.96 14.33C15.61 14.59 15.23 14.8 14.83 14.97C14.4 15.16 13.96 15.3 13.5 15.4C13.47 15.4 13.45 15.41 13.42 15.42C13.43 15.46 13.46 15.48 13.49 15.51C14.26 16.28 15.04 17.05 15.81 17.83C16.19 18.21 16.23 18.81 15.92 19.24C15.53 19.77 14.77 19.84 14.29 19.38C13.53 18.64 12.77 17.9 12.02 17.15C11.95 17.08 11.92 17.09 11.86 17.15C11.19 17.87 10.52 18.58 9.86 19.3C9.57 19.61 9.22 19.74 8.81 19.65C8.41 19.56 8.13 19.31 8.01 18.92C7.89 18.52 7.96 18.15 8.25 17.85C8.91 17.15 9.57 16.46 10.23 15.77C10.33 15.66 10.43 15.56 10.55 15.44H10.53Z"
      fill="#FEFEFE"
    />
    <path
      d="M11.99 9.87994C11.08 9.87994 10.34 9.13995 10.34 8.22995C10.34 7.30995 11.08 6.56995 11.99 6.56995C12.9 6.56995 13.63 7.31995 13.63 8.23995C13.63 9.14995 12.89 9.87994 11.98 9.87994H11.99Z"
      fill="#F6921E"
    />
  </svg>
);

export default OdnoklassnikiColor;
