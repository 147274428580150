import { FC, ReactNode } from "react";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import { SendCollapsedContainer } from "./style";

import { getLoggedInStatus } from "store/slices/authSlice";
import { SendGiftType } from "types/gifting";

interface Props {
  name: SendGiftType;
  show: SendGiftType;
  icon: ReactNode;
  title: string;
  isAuth: boolean;
  description?: string | undefined;
  onClick: () => void;
}

const SendCollapsed: FC<Props> = ({ show, name, isAuth, icon, title, description, onClick }) => {
  const { t } = useTranslation();
  const isLogin = useSelector(getLoggedInStatus);

  return (
    <SendCollapsedContainer
      showGlobal={show !== SendGiftType.CLOSE}
      showCurrent={show === name}
      onClick={onClick}
    >
      <div className="icon">{icon}</div>
      <div className="text">
        <p className="title">
          {title}
          {!isLogin && show === name && name === SendGiftType.WALLET && (
            <span className="auth">
              {t(isAuth ? "common.please_login" : "common.registration")}
            </span>
          )}
        </p>
        {description && (
          <p className={`description ${show === SendGiftType.CLOSE ? "" : "hide"}`}>
            {description}
          </p>
        )}
      </div>
    </SendCollapsedContainer>
  );
};

export default SendCollapsed;
