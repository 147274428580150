import CoinLoading from "../CoinLoading";

import { NewCommonWrapper } from "styles/mixins";

const FallbackLoader = () => (
  <NewCommonWrapper>
    <CoinLoading />
  </NewCommonWrapper>
);

export default FallbackLoader;
