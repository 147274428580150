import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const SocialContainer = styled.div<{ isVerify: boolean }>`
  padding: 16px;
  align-items: center;
  display: flex;
  flex: 1;
  border-radius: 16px;
  gap: 16px;
  background: ${(p) => p.theme.colors.background.secondary};
  font-size: ${(p) => p.theme.typography.text3};
  cursor: ${(p) => (!p.isVerify ? "pointer" : "unset")};
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);

  .info {
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 16px;

    .icon {
      background: ${(p) => p.theme.colors.background.fifth};
      border-radius: 12px;
      padding: 12px;
    }

    & > div {
      display: flex;
      align-items: center;
      width: 200px;
      gap: 16px;
    }

    a {
      display: inline-flex;
      align-items: center;
      flex-grow: 1;
      gap: 16px;
      text-decoration: none;
      color: ${(p) => p.theme.colors.green.primary};

      .social {
        display: flex;
        align-items: center;
        flex-grow: 1;
        gap: 16px;
        font-size: ${(p) => p.theme.typography.subText5};

        .name {
          color: ${(p) => p.theme.colors.text.primary};
        }
      }
    }
  }

  .connect {
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${(p) => (p.isVerify ? "transparent" : p.theme.colors.green.primary)};
      border: 1px solid ${(p) => p.theme.colors.divider};
      border-radius: 50%;
      padding: 8px;
    }
  }

  ${deviceQuery.mobileSM} {
    .info {
      .icon {
        background: none;
        border-radius: 2px;
        padding: 0px;
      }

      .socialName {
        display: ${(p) => (!p.isVerify ? "inline" : "none")};
      }

      & > div {
        width: fit-content;
      }
    }
  }

  ${deviceQuery.mobileSE} {
    ${(p) =>
      p.isVerify &&
      css`
        .connect {
          border-left: none;
          padding-left: 0px;
        }
      `}
    .info {
      a {
        .social {
          flex-direction: column;
          padding-right: 16px;
          border-right: 1px solid ${(p) => p.theme.colors.divider};

          .socialInfo {
            flex-direction: column;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
`;
