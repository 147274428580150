import styled from "styled-components";

export const RadioContainer = styled.div<{ active?: boolean; disabled?: boolean }>`
  display: flex;
  gap: 8px;
  cursor: pointer;
  align-items: center;

  .radioLabel {
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
    color: ${(p) => p.theme.colors.icon.secondary};
    font-size: ${(p) => p.theme.typography.subText5};

    .description {
      font-size: ${(p) => p.theme.typography.subText3};
      color: ${(p) => p.theme.colors.icon.secondary};
    }
  }

  .input {
    display: none;
  }

  .radio {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid ${(p) => p.theme.colors.field.empty};
    width: 24px;
    height: 24px;
    background: trasparent;
    transition: all 0.3s ease-in-out;

    .dot {
      background: ${(p) => (p.disabled ? p.theme.colors.field.full : p.theme.colors.green.primary)};
      border-radius: 50%;
      width: 12px;
      height: 12px;
      transition: all 0.3s ease-in-out;
      opacity: 0;
    }
  }

  &.active {
    .radio {
      border: 1px solid ${(p) => p.theme.colors.field.full};

      .dot {
        opacity: 1;
      }
    }

    .radioLabel {
      color: ${(p) => p.theme.colors.text.primary};
    }
  }
`;
