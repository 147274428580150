import { AxiosError } from "axios";

import { getMediaTypeFromName } from "common/helpers/file-helpers";
import { makeToast } from "common/helpers/helper";
import { STRINGS } from "constants/appConstants";
import getAxiosInst, { apiHandler, endpoints } from "services/axios";
import { uploadFileWithProgress } from "services/UserServices";
import { updateOneMessage } from "store/slices/newMessages/messagesSlice";
import store from "store/store";
import {
  GetChatMessagesResp,
  GetChatsResp,
  GetChatStatusCounterResp,
  GetChatsWithSearchResp,
  SendChatMessagesResp,
} from "types/api-response-types";
import {
  ChatStatus,
  GetNewChatsRequestData,
  GetNewMessagesRequestData,
  GetNewMessagesSearchRequestData,
  InChatMessageToSend,
  NewMessageImageMediaContent,
  NewMessageItem,
  NewMessagePlayableMediaContent,
  PlainMessageToSend,
  SearchChatItem,
  TotalChatCount,
} from "types/new-messages.types";
import { UserRole } from "types/user-role";

export const getChatsApi = (
  { status, role, paginationData }: GetNewChatsRequestData,
  onSuccess = (data: GetChatsResp) => {},
  onError = (error: AxiosError) => {}
) => {
  apiHandler(
    () =>
      getAxiosInst().get<GetChatsResp>(`${endpoints.chats}`, {
        params: {
          status,
          role,
          limit: paginationData.limit,
          offsetId: paginationData.offsetId,
        },
      }),
    {
      onSuccess: (res) => {
        onSuccess(res);
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message,
          type: STRINGS.toastType.error,
        });
        onError(error);
      },
    }
  );
};

export const getChatStatusesCounter = (
  onSuccess: (data: TotalChatCount) => void,
  onError?: () => void
) => {
  apiHandler(
    () => getAxiosInst().get<GetChatStatusCounterResp>(`${endpoints.chatStatusesCounter}`),
    {
      onSuccess: (res) => {
        onSuccess(res.data);
      },
      onError: (error) => {
        onError && onError();
        makeToast({
          message: error.response?.data.message,
          type: STRINGS.toastType.error,
        });
      },
    }
  );
};

export const getChatMessagesApi = (
  { chatId, limit, prevOffsetId, nextOffsetId, aroundOffsetId }: GetNewMessagesRequestData,
  onSuccess = (data: GetChatMessagesResp) => {},
  onError = (error: AxiosError) => {}
) => {
  apiHandler(
    () =>
      getAxiosInst().get<GetChatMessagesResp>(`${endpoints.chats}/${chatId}/messages`, {
        params: {
          limit,
          prevOffsetId,
          nextOffsetId,
          aroundOffsetId,
        },
      }),
    {
      onSuccess: (res) => {
        onSuccess(res);
      },
      onError: (error) => {
        makeToast({
          message: error.response?.data.message,
          type: STRINGS.toastType.error,
        });
        onError(error);
      },
    }
  );
};

export const sendMessageToUser = (
  message: PlainMessageToSend | InChatMessageToSend,
  callback = (message: NewMessageItem) => {}
) => {
  apiHandler(() => getAxiosInst().post<SendChatMessagesResp>(`${endpoints.sendMessage}`, message), {
    onSuccess: (res) => {
      callback(res.message);
    },
    onError: (error) => {
      makeToast({
        message: error?.response?.data?.message,
        type: STRINGS.toastType.error,
      });
    },
  });
};

export const markMessagesAsReadById = (
  chatId: string,
  messageIds: string[],
  onSuccess = () => {}
) => {
  apiHandler(
    () =>
      getAxiosInst().post(`${endpoints.chats}/${chatId}/${endpoints.markMessagesAsRead}`, {
        messageIds,
      }),
    {
      onSuccess: (res) => {
        // todo add types of neaded
        onSuccess();
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message,
          type: STRINGS.toastType.error,
        });
      },
    }
  );
};

export const updateChatStatusById = (
  chatId: string,
  status: ChatStatus,
  onSuccess = () => {},
  onError = () => {}
) => {
  apiHandler(
    () =>
      getAxiosInst().put(`${endpoints.chats}/${chatId}/${endpoints.updateStatus}`, {
        status,
      }),
    {
      onSuccess: (res) => {
        onSuccess();
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message,
          type: STRINGS.toastType.error,
        });
        onError();
      },
    }
  );
};

export const getChatsWithSearch = (
  { status, search, role, limit, offsetId }: GetNewMessagesSearchRequestData,
  onSuccess = (data: SearchChatItem[]) => {},
  onError = () => {}
) => {
  apiHandler(
    () =>
      getAxiosInst().get<GetChatsWithSearchResp>(`${endpoints.searchInChat}`, {
        params: {
          status,
          search,
          role,
          limit,
          offsetId,
        },
      }),
    {
      onSuccess: (res) => {
        onSuccess(res.data);
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message,
          type: STRINGS.toastType.error,
        });
        onError();
      },
    }
  );
};

export const uploadMessageContent = async (
  content: File[],
  uploadProgressChanges: (progressInPercent: number) => void
) => {
  const totalSize = content.reduce((acc, nextFile) => acc + nextFile.size, 0);
  let currentlyUploadedInBytes = 0;

  const SIZE_UPLOADED_MAP: Record<string, number> = {};

  const uploadPromises: ReturnType<typeof uploadFileWithProgress>[] = [];

  content.forEach((file: File) => {
    const media = new FormData();
    const mediaType = getMediaTypeFromName(file.name);
    if (!mediaType) return;

    media.append("reason", "chat");
    media.append(mediaType, file);
    media.append("prefix", `chats/`);

    try {
      const uploadPromise = uploadFileWithProgress({ apiData: media }, (uploadedInBytes) => {
        const dif = SIZE_UPLOADED_MAP[file.name]
          ? uploadedInBytes - SIZE_UPLOADED_MAP[file.name]
          : uploadedInBytes;

        SIZE_UPLOADED_MAP[file.name] = uploadedInBytes;

        currentlyUploadedInBytes += dif;

        const progressInPercent = Math.floor((currentlyUploadedInBytes / totalSize) * 100);
        uploadProgressChanges(progressInPercent <= 100 ? progressInPercent : 100);
      });

      uploadPromises.push(uploadPromise);
    } catch (error) {
      console.log(error, "err");
    }
  });

  try {
    const uploadedMediaList = await Promise.all(uploadPromises);
    return uploadedMediaList;
  } catch (error) {
    console.log(error, "upload errors");
  }
};

export const getMediaCardData = (
  chatId: string,
  messageId: string,
  onSuccess = (content: (NewMessageImageMediaContent | NewMessagePlayableMediaContent)[]) => {}
) => {
  apiHandler(
    () =>
      getAxiosInst().get(
        `${endpoints.chats}/${chatId}/${endpoints.getMessages}/${messageId}/mediaCard`
      ),
    {
      onSuccess: (res) => {
        onSuccess(res.data);
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message,
          type: STRINGS.toastType.error,
        });
      },
    }
  );
};

export const getUnreadChatsCountApi = (
  role: UserRole,
  statuses: ChatStatus[],
  onSuccess?: (res: number) => void
) => {
  apiHandler(
    () =>
      getAxiosInst().get<{ data: { unreadMessagesCount: number } }>(
        `${endpoints.unreadMessagesCounter}`,
        {
          params: {
            statuses,
            role,
          },
        }
      ),
    {
      onSuccess: (res) => {
        onSuccess && onSuccess(res.data.unreadMessagesCount);
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message,
          type: STRINGS.toastType.error,
        });
      },
    }
  );
};

export const payContentMessage = (
  details: {
    chatId: string;
    messageId: string;
  },
  body: {
    amount: number;
    token: string;
  },
  callback: () => void
) => {
  apiHandler(
    () =>
      getAxiosInst().post<SendChatMessagesResp>(
        `${endpoints.chats}/${details.chatId}/${endpoints.getMessages}/${details.messageId}/pay`,
        { ...body }
      ),
    {
      onSuccess: (res) => {
        store.dispatch(
          updateOneMessage({
            id: res.message._id,
            changes: {
              isPaid: true,
            },
          })
        );
        callback();
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message,
          type: STRINGS.toastType.error,
        });
      },
    }
  );
};
