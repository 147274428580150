import getAxiosInst, { apiHandler, endpoints } from "./axios";

import { makeToast } from "common/helpers/helper";
import { STRINGS } from "constants/appConstants";
import i18n from "i18n";
import { setToken } from "store/slices/authSlice";
import { setLoader } from "store/slices/featureSlice";
import store from "store/store";
import {
  IRegisterFormikData,
  IRegistrationInfo,
  LoginWithCredentialData,
  UserLoginRespData,
} from "types/auth-types";
import { UserRole } from "types/user-role";

export const loginUserAPI = async ({ data }: { data: LoginWithCredentialData }) => {
  return getAxiosInst().post<UserLoginRespData>("/user/userlogin", data);
};

export const loginAdminAPI = async ({ data }: { data: LoginWithCredentialData }) => {
  return getAxiosInst().post<UserLoginRespData>("/user/adminlogin", data);
};

export const createUserAPI = async ({ data }: { data: IRegisterFormikData }) => {
  store.dispatch(setLoader({ name: "registrationLoader", value: true }));

  apiHandler(() => getAxiosInst().post(endpoints.createUser, data), {
    onSuccess: () => {
      makeToast({
        message: i18n.t("auth.registration_successful"),
        type: STRINGS.success,
      });
    },
    onError: (error) => {
      makeToast({
        message: error.response?.data.message,
        type: STRINGS.error,
      });
    },
    final: () => {
      store.dispatch(setLoader({ name: "registrationLoader", value: false }));
    },
  });
};

export const createNewUserAPI = async (
  { data }: { data: IRegistrationInfo },
  callback = () => {}
) => {
  store.dispatch(setLoader({ name: "registrationLoader", value: true }));

  apiHandler(
    () => getAxiosInst().post<{ data: { authToken: string } }>(endpoints.createNewUser, data),
    {
      onSuccess: (res) => {
        makeToast({
          message: i18n.t("auth.registration_successful"),
          type: STRINGS.success,
        });
        store.dispatch(setToken(res.data.authToken));
        callback();
      },
      onError: (error) => {
        makeToast({
          message: error.response?.data.message,
          type: STRINGS.error,
        });
      },
      final: () => {
        store.dispatch(setLoader({ name: "registrationLoader", value: false }));
      },
    }
  );
};

export const getUserLoginInfoByGoogleToken = (
  { googleToken }: { googleToken: string },
  successCallBack = (data: UserLoginRespData) => {},
  errorCallBack = () => {}
) => {
  apiHandler(
    () =>
      getAxiosInst().post<UserLoginRespData>("/oauth/google/signIn", {
        ga: googleToken,
      }),
    {
      onSuccess: (data) => {
        successCallBack(data);
      },
      onError: (error) => {
        errorCallBack();
        console.log("catch error ===>>>", error.response);
      },
    }
  );
};

export const registerUserByGoogleToken = (
  {
    googleToken,
    userRegisterInfo,
  }: {
    googleToken: string;
    userRegisterInfo: {
      ga: string;
      role: Omit<UserRole, "admin">;
      mobile: string;
    };
  },
  successCallBack = (data: UserLoginRespData) => {},
  errorCallBack = () => {}
) => {
  apiHandler(
    () =>
      getAxiosInst().post<UserLoginRespData>("/oauth/google/signUp", {
        ...userRegisterInfo,
        ga: googleToken,
      }),
    {
      onSuccess: (data) => {
        successCallBack(data);
      },
      onError: (error) => {
        errorCallBack();
        console.log("catch error ===>>>", error.response);
      },
    }
  );
};

export const createUserByAdminAPI = async ({ data }: { data: any }) => {
  return getAxiosInst().post(endpoints.createUserByAdmin, data);
};

export const validateTwoFactorCodeAPI = (
  {
    data,
  }: {
    data: {
      id?: string;
      token: string;
    };
  },
  callback = (status: boolean) => {}
) => {
  apiHandler(() => getAxiosInst().post(endpoints.validate2FA, data), {
    onSuccess: () => {
      callback(true);
    },
    onError: (error) => {
      callback(false);
      makeToast({
        message: error.response?.data.message,
        type: STRINGS.toastType.error,
      });
    },
  });
};
