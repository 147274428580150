import { FC, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

import { SocialContainer } from "./style";

import LinkConnect from "assets/svg/LinkConnect";
import LinkDisconnect from "assets/svg/LinkDisconnect";
import Avatar from "Components/Avatar";
import { disconnectSocialAPI } from "services/UserServices";
import { getUser } from "store/slices/global/userSlice";

interface Props {
  social: { icon: ReactNode; name: string; value: string };
  onClick: () => void;
}

const Social: FC<Props> = ({ social, onClick }) => {
  const { t } = useTranslation();
  const userDetails = useSelector(getUser);
  const currentSocial = useMemo(() => {
    if (!userDetails) return;
    return userDetails.socialmedia.find((item) => item.platform === social.value);
  }, [userDetails]);
  const isVerify = useMemo(() => {
    if (!userDetails) return false;
    return userDetails.socialmedia.some((item) => item.platform === social.value && item.verified);
  }, [userDetails]);

  const handleDisconnect = () => {
    disconnectSocialAPI(social.value);
  };

  return (
    <SocialContainer isVerify={isVerify} onClick={!isVerify ? onClick : () => {}}>
      <div className="info">
        <div>
          <div className="icon">{social.icon}</div>
          <p className="socialName">{social.name}</p>
        </div>
        {currentSocial && currentSocial.thumbnail_url && (
          <Link to={currentSocial.platformLink!} target="_blank">
            <div className="social">
              <Avatar src={currentSocial.thumbnail_url} alt="Social Avatar" />
              <div className="socialInfo">
                <p className="name">{currentSocial.display_name}</p>
                <p className="click">{t("common.toVisit")}</p>
              </div>
            </div>
          </Link>
        )}
      </div>
      <div className="connect" onClick={isVerify ? handleDisconnect : () => {}}>
        <div className="icon">{isVerify ? <LinkDisconnect /> : <LinkConnect />}</div>
      </div>
    </SocialContainer>
  );
};

export default Social;
