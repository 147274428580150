import styled from "styled-components";

import { OutfitRegular } from "styles/mixins";

export const LogoutButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  color: ${(p) => p.theme.colors.text.error};
  font-size: ${(p) => p.theme.typography.subText5};
  ${OutfitRegular};
  padding: 22px 12px;
  align-items: center;
  margin: 0px auto;
  width: 90%;
  display: flex;
  gap: 14px;

  &:hover {
    color: ${(p) => p.theme.colors.text.error};
  }
`;

export const LogoutBtn = styled.p`
  color: ${(p) => p.theme.colors.text.primary} !important;
  ${OutfitRegular};
`;
