import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { getLocalStorageItem, setLocalStorageItem } from "common/helpers/localStorage";

import { ROLES, STORAGES } from "constants/appConstants";
import { AUTH_ROUTES, ROUTES } from "constants/routes";

const CreatorPrivateRoute = () => {
  const location = useLocation();
  if (location?.pathname?.includes("campaigns/created-video-details/")) {
    setLocalStorageItem({ key: STORAGES?.route, value: location?.pathname });
  }

  const token = getLocalStorageItem({ key: STORAGES.token });
  const accountType = getLocalStorageItem({ key: STORAGES.accountType });
  if (token && accountType === ROLES.creator) {
    return <Outlet />;
  }
  return (
    <Navigate
      to={
        location?.pathname?.includes("campaigns/created-video-details/")
          ? AUTH_ROUTES.login
          : ROUTES.home
      }
    />
  );
};

export default CreatorPrivateRoute;
