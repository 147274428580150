import styled from "styled-components";

import { deviceQuery } from "./mediaSizes";

export const AuthWrapper = styled.main`
  display: flex;
  padding: 20px 7%;
  background: ${(p) => p.theme.colors.background.primary};
  min-height: calc(100vh - 44px);
  justify-content: center;
  position: relative;

  .content {
    display: flex;
    overflow: hidden;
    color: ${(p) => p.theme.colors.text.primary};
    flex-direction: column;
    flex: 1;
    max-width: 1180px;
    gap: 20px;

    span.admin {
      background: ${(p) => p.theme.colors.green.primary};
      color: ${(p) => p.theme.colors.white.primary};
      padding: 6px 12px;
      border-radius: 8px;
    }
  }

  ${deviceQuery.tabletM} {
    max-height: unset;
  }

  ${deviceQuery.mobileMS} {
    padding: 20px;
  }
`;

export const AuthContainer = styled.div`
  display: flex;
  gap: 40px;
  padding-bottom: 20px;
  height: calc(100% - 50px);
  border-bottom: 1px solid ${(p) => p.theme.colors.divider};
  position: relative;
  flex: 1;

  & > div {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  a {
    color: ${(p) => p.theme.colors.green.primary};
    text-align: left;
    font-size: ${(p) => p.theme.typography.subText5};
  }

  .left {
    gap: 20px;
    flex-direction: column;
    overflow: hidden;
    position: relative;

    .title {
      text-align: center;
      font-size: ${(p) => p.theme.typography.title3};
    }
    .timer {
      margin-top: -20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .step3 {
      display: flex;
      justify-content: center;

      .infoIcon {
        padding: 16px;
        border-radius: 50%;
        background: ${(p) => p.theme.colors.icon.secondary};
      }
    }
    .text {
      width: 100%;
      display: inline-flex;
      flew-wrap: wrap;
      gap: 4px;
      justify-content: center;
      font-size: ${(p) => p.theme.typography.subText5};
      color: ${(p) => p.theme.colors.text.secondary};
    }
    .choose {
      display: inline-flex;
      gap: 8px;
      justify-content: center;
      align-items: center;
      font-size: ${(p) => p.theme.typography.text3};
    }
    .inputs {
      display: flex;
      flex-direction: column;
      gap: 16px;
      text-align: start;
    }

    .or_divider {
      text-align: center;
      color: ${(p) => p.theme.colors.text.secondary};
    }

    .role {
      display: flex;
      gap: 20px;
      justify-content: center;
    }

    .about {
      display: flex;
      gap: 20px;
      justify-content: center;

      .aboutCard {
        display: flex;
        flex-direction: column;
        gap: 16px;

        br {
          display: none;
        }

        .titleCard {
          border-bottom: 1px solid ${(p) => p.theme.colors.divider};
          padding-bottom: 16px;
          text-align: center;
          font-size: ${(p) => p.theme.typography.text3};

          .titleRole {
            color: ${(p) => p.theme.colors.green.primary};
          }
        }

        .desc {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .item {
            display: flex;
            gap: 10px;
          }
        }
      }
    }

    .passwordContainer {
      text-align: start;
      display: flex;
      gap: 20px;

      & > div {
        flex: 1;
      }
    }

    .resetActions {
      display: flex;
      gap: 20px;

      .back {
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        padding: 16px;
      }
    }
  }

  .right {
    align-items: center;
    background: ${(p) => p.theme.colors.background.secondary};
    border-radius: 20px;
    overflow: hidden;

    a {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .lines {
    position: absolute;
    bottom: -1px;
    display: flex;
    height: 1px;
    width: 100%;

    .line {
      flex: 1;
      background: ${(p) => p.theme.colors.divider};

      &.active {
        background: ${(p) => p.theme.colors.green.primary};
      }
    }
  }

  ${deviceQuery.tabletM} {
    flex-direction: column-reverse;
    justify-content: flex-end;

    .right {
      display: none;
    }

    .left {
      flex: 1;
    }
  }

  ${deviceQuery.mobileM} {
    .left {
      .passwordContainer {
        flex-direction: column;
      }
      .resetActions {
        .back {
          padding: 8px;
        }
      }
      .about {
        .aboutCard {
          gap: 12px;

          br {
            display: block;
          }
          .desc {
            .item {
              justify-content: center;
              text-align: center;
            }
            .icon {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export const AuthForm = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  button {
    padding: 10px 24px;
  }

  .socials {
    button {
      padding: 0px;
    }
  }
`;

export const AuthActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;

  .buttons {
    display: flex;
    gap: 20px;
  }

  .socials {
    display: flex;
    gap: 24px;

    button {
      padding: 0px;
    }
  }

  .step {
    text-align: end;
    font-size: ${(p) => p.theme.typography.subText5};
  }

  ${deviceQuery.tabletM} {
    flex-direction: column-reverse;
    gap: 20px;

    .buttons {
      width: 100%;
      flex-direction: column;

      > * {
        width: 100%;
      }
    }
  }
`;
