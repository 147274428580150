const TumblrDarkColor = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 1C14.18 1 16.37 1 18.55 1C19.75 1 20.78 1.41 21.64 2.23C22.37 2.93 22.81 3.8 22.95 4.81C22.98 5.05 22.99 5.27999 22.99 5.50999C22.99 9.82999 22.99 14.14 22.99 18.46C22.99 19.68 22.61 20.73 21.79 21.61C21.09 22.36 20.22 22.8 19.2 22.95C18.94 22.99 18.68 22.99 18.42 22.99C14.11 22.99 9.8 22.99 5.49 22.99C4.28 22.99 3.24 22.6 2.37 21.78C1.63 21.09 1.19 20.22 1.04 19.21C1 18.92 1 18.63 1 18.33C1 14.06 1 9.79 1 5.52C1 4.3 1.38 3.25 2.2 2.37C2.9 1.62 3.77 1.18 4.79 1.03C5.05 0.989999 5.31 0.98999 5.57 0.98999C7.71 0.98999 9.85 0.98999 12 0.98999V1ZM9.82 13.59C9.84 14.3 9.78 15.15 9.88 15.99C10.06 17.47 11.05 18.51 12.52 18.76C13.56 18.93 14.6 18.82 15.64 18.85C15.8 18.85 15.78 18.75 15.78 18.66C15.78 17.88 15.78 17.1 15.78 16.31C15.78 16.15 15.74 16.1 15.57 16.1C15.16 16.1 14.76 16.11 14.35 16.1C13.65 16.07 13.24 15.66 13.17 14.97C13.16 14.84 13.15 14.71 13.15 14.58C13.15 13.49 13.15 12.4 13.15 11.31C13.15 11.11 13.22 11.08 13.39 11.09C14.08 11.09 14.77 11.09 15.47 11.09C15.63 11.09 15.7 11.06 15.69 10.88C15.68 10.16 15.68 9.42999 15.69 8.70999C15.69 8.52999 15.63 8.5 15.47 8.5C14.79 8.5 14.1 8.5 13.42 8.5C13.19 8.5 13.15 8.43 13.16 8.23C13.17 7.24 13.16 6.24 13.16 5.25C13.16 5.04 13.12 4.94999 12.89 4.95999C12.35 4.97999 11.8 4.97999 11.25 4.95999C11.04 4.95999 10.98 5.02 10.95 5.22C10.7 6.98 9.83 8.25 8.07 8.8C7.94 8.84 7.91 8.91 7.92 9.03C7.92 9.65 7.92 10.27 7.92 10.89C7.92 11.07 7.99 11.09 8.13 11.09C8.61 11.09 9.09 11.09 9.57 11.09C9.76 11.09 9.82 11.13 9.82 11.33C9.81 12.04 9.82 12.75 9.82 13.59Z"
      fill="#1C3562"
    />
    <path
      d="M9.82 13.59C9.82 12.75 9.82 12.04 9.82 11.33C9.82 11.13 9.76 11.09 9.57 11.09C9.09 11.1 8.61 11.09 8.13 11.09C7.98 11.09 7.91 11.07 7.92 10.89C7.93 10.27 7.92 9.65004 7.92 9.03004C7.92 8.91004 7.95 8.84004 8.07 8.80004C9.82 8.24004 10.7 6.97004 10.95 5.22004C10.98 5.02004 11.03 4.95003 11.25 4.96003C11.8 4.98003 12.34 4.98003 12.89 4.96003C13.13 4.96003 13.17 5.04004 13.16 5.25004C13.15 6.24004 13.16 7.24004 13.16 8.23004C13.16 8.44004 13.2 8.51004 13.42 8.50004C14.1 8.48004 14.79 8.50004 15.47 8.50004C15.63 8.50004 15.7 8.53003 15.69 8.71003C15.68 9.43003 15.68 10.16 15.69 10.88C15.69 11.06 15.63 11.09 15.47 11.09C14.78 11.09 14.09 11.09 13.39 11.09C13.22 11.09 13.15 11.12 13.15 11.31C13.16 12.4 13.15 13.49 13.15 14.58C13.15 14.71 13.15 14.84 13.17 14.97C13.24 15.66 13.65 16.06 14.35 16.1C14.76 16.12 15.16 16.12 15.57 16.1C15.73 16.1 15.78 16.15 15.78 16.31C15.78 17.09 15.78 17.87 15.78 18.66C15.78 18.76 15.8 18.86 15.64 18.85C14.6 18.81 13.56 18.93 12.52 18.76C11.05 18.51 10.06 17.48 9.88 15.99C9.78 15.15 9.84 14.3 9.82 13.59Z"
      fill="#FBFBFB"
    />
  </svg>
);

export default TumblrDarkColor;
