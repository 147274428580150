import getAxiosInst, { apiHandler, endpoints } from "./axios";

import { makeToast } from "common/helpers/helper";
import { STRINGS } from "constants/appConstants";
import { setFanFeed, setSuggestedCreators } from "store/slices/fan/activityFeedSlice";
import { setSubscribers } from "store/slices/fan/fanSlice";
import { setMyTransactions } from "store/slices/fan/myTransactionsSlice";
import { setAllSubscriptions } from "store/slices/fan/subscriptionsSlice";
import { setLoader } from "store/slices/featureSlice";
import { setFeed, setPlans, setPublicUser } from "store/slices/global/previewProfileSlice";
import store from "store/store";
import { IFeedContent, ISuggestedCreators } from "types/creator";
import { IShortPlan } from "types/fan";
import { IGiftingFilter, IMyFeedFilter, ISubscriptionFilter } from "types/filters";
import { IMyTransactionFan } from "types/gifting";
import { IMySubscription, IPlans, IPost, IPublicUser } from "types/previewProfile";

export const fetchFeeds = (apiData: { limit: number; skip: number; filter: IMyFeedFilter }) => {
  const { skip, limit, filter } = apiData;
  store.dispatch(setLoader({ name: "contentLoader", value: true }));
  apiHandler(
    () =>
      getAxiosInst().get<{
        result: IFeedContent[];
        totalData: number;
      }>(`${endpoints.fanFeeds}`, {
        params: {
          skip,
          limit,
          sort: filter.sort,
          search: filter.search,
          contentTypes: filter.content,
          privacy: filter.privacy,
        },
      }),
    {
      onSuccess: (res) => {
        store.dispatch(setFanFeed({ data: res.result, total: res.totalData }));
      },
      onError: (error) => {
        console.log(error);
      },
      final: () => {
        store.dispatch(setLoader({ name: "contentLoader", value: false }));
      },
    }
  );
};

export const fetchFeedsByCreator = (apiData: {
  skip: number;
  limit: number;
  id: string;
  role: string;
}) => {
  store.dispatch(setLoader({ name: "campaignLoader", value: true }));

  apiHandler(
    () =>
      getAxiosInst().get<{ result: IPost[]; feedCounts: number }>(
        `${endpoints.creatorFeedsByCreator}?creatorId=${apiData.id}&limit=${apiData.limit}&skip=${apiData.skip}`
      ),
    {
      onSuccess: (res) => {
        store.dispatch(
          setFeed({
            data: res.result.reverse(),
            total: res.feedCounts,
            skip: apiData.skip + apiData.limit,
          })
        );
      },
      onError: (error) => {
        console.log(error);
      },
      final: () => {
        store.dispatch(setLoader({ name: "campaignLoader", value: false }));
      },
    }
  );
};

export const fetchCreators = (apiData: any, callback = (res: any) => {}) => {
  apiHandler(
    () =>
      getAxiosInst().post<any>(
        `${endpoints.findCreators}?limit=${apiData.limit}&skip=${apiData.skip}`,
        apiData.filterParams
      ),
    {
      onSuccess: (res) => {
        callback(res);
      },
      onError: (error) => {
        callback(error);
      },
    }
  );
};

export const deleteSubscription = (
  apiData: {
    id?: string;
    data: {
      cancelSubscription: string;
    };
  },
  callback = () => {}
) => {
  apiHandler(
    () =>
      getAxiosInst().post(
        `${endpoints.deleteSubscriptionPlanAsFan}?plan_id=${apiData.id}`,
        apiData.data
      ),
    {
      onSuccess: () => {
        makeToast({
          message: "Subscription canceled successfully",
          type: STRINGS.toastType.success,
        });
        callback();
      },
      onError: (error) => {
        makeToast({
          message: error.response?.data.message || "Unable to Cancel Subscription",
          type: STRINGS.toastType.error,
        });
        callback();
      },
    }
  );
};

export const fetchPlans = (
  apiData: {
    skip: number;
    limit: number;
    creatorId: string;
    role: string;
  },
  replaceAllPlans = false
) => {
  const { limit, skip, creatorId } = apiData;
  store.dispatch(setLoader({ name: "campaignLoader", value: true }));
  const apiPoint = apiData.role === "fan" ? endpoints.planList : endpoints.creatorPlanList;
  const creatorid = apiData.role === "fan" ? "creatorId" : "creator_id";

  apiHandler(
    () =>
      getAxiosInst().get<{ result: IPlans[]; planCount: number; subscribedFeedCount: number }>(
        `${apiPoint}`,
        {
          params: {
            limit,
            skip,
            [creatorid]: creatorId,
          },
        }
      ),
    {
      onSuccess: (res) => {
        store.dispatch(
          setPlans({
            data: res.result,
            total: res.planCount,
            subscribedFeedCount: res.subscribedFeedCount,
            skip: apiData.skip + apiData.limit,
            replaceAllPlans,
          })
        );
      },
      onError: (error) => {
        console.log(error);
      },
      final: () => {
        store.dispatch(setLoader({ name: "campaignLoader", value: false }));
      },
    }
  );
};

export const fetchUserDetails = (
  apiData: {
    creatorId: string;
    role?: string;
  },
  callback = (res: { status: boolean; result: IPublicUser }) => {}
) => {
  store.dispatch(setLoader({ name: "creatorProfileLoader", value: true }));

  apiHandler(
    () =>
      getAxiosInst().get<{ status: boolean; result: IPublicUser }>(
        `/creator${endpoints.creatorProfile}?creatorId=${apiData.creatorId}`
      ),
    {
      onSuccess: (res) => {
        store.dispatch(setPublicUser(res.result));
        callback(res);
      },
      onError: (error) => {
        makeToast({
          message: error.response?.data.message,
          type: STRINGS.toastType.error,
        });
      },
      final: () => {
        store.dispatch(setLoader({ name: "creatorProfileLoader", value: false }));
      },
    }
  );
};

export const fetchFeedUpdating = (apiData: { feedId: string }) => {
  apiHandler(() => getAxiosInst().post(`${endpoints.feedCount}?feedId=${apiData.feedId}`));
};

export const purchaseSubscriptionPlan = (
  apiData: {
    id: string;
    params: { renew: boolean; token: string };
  },
  callback = (data: IMySubscription) => {}
) => {
  store.dispatch(setLoader({ name: "giftingLoader", value: true }));
  apiHandler(
    () =>
      getAxiosInst().post<{ message: string; data: { subscription: IMySubscription } }>(
        `${endpoints.buySubscription}?subscriptionId=${apiData.id}`,
        apiData.params
      ),
    {
      onSuccess: (data) => {
        makeToast({
          message: data?.message,
          type: STRINGS.toastType.success,
        });
        callback(data.data.subscription);
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message || "Unable to purchase subscription plan!",
          type: STRINGS.toastType.error,
        });
      },
      final: () => {
        store.dispatch(setLoader({ name: "giftingLoader", value: false }));
      },
    }
  );
};

export const getPaymentHistoryList = ({
  skip,
  limit,
  filter,
}: {
  skip: number;
  limit: number;
  filter: IGiftingFilter;
}) => {
  store.dispatch(setLoader({ name: "paymentHistoryLoader", value: true }));
  apiHandler(
    () =>
      getAxiosInst().get<{
        result: IMyTransactionFan[];
        totalTransactions: number;
      }>(`${endpoints.getFanPaymentHistory}`, {
        params: {
          limit,
          skip,
          isGifting: filter.type === "gifting" ? 1 : undefined,
          isSubscription: filter.type === "subscription" ? 1 : undefined,
          search: filter.search,
          amountLte: filter.to,
          amountGte: filter.from,
        },
      }),
    {
      onSuccess: (res) => {
        store.dispatch(setMyTransactions(res));
      },
      onError: (error) => {
        console.log("Error:", error.response);
      },
      final: () => {
        store.dispatch(setLoader({ name: "paymentHistoryLoader", value: false }));
      },
    }
  );
};

export const getSuggestedCreatorsApi = (apiData: { skip: number; limit: number }) => {
  store.dispatch(setLoader({ name: "nftLoader", value: true }));
  apiHandler(
    () =>
      getAxiosInst().get<{ result: ISuggestedCreators[] }>(
        `${endpoints.suggestedCreators}?limit=${apiData.limit}&skip=${apiData.skip}`
      ),
    {
      onSuccess: (res) => {
        store.dispatch(setSuggestedCreators({ data: res.result, total: res.result.length }));
      },
      onError: (error) => {
        console.log(error);
      },
      final: () => {
        store.dispatch(setLoader({ name: "nftLoader", value: false }));
      },
    }
  );
};

export const getSubscriptionList = (apiData: { skip: number; limit: number }) => {
  store.dispatch(setLoader({ name: "subscriberLoader", value: true }));
  apiHandler(
    () =>
      getAxiosInst().get<{
        result: IShortPlan[];
        totalUser: number;
      }>(`${endpoints.fanSubscriptionList}?limit=${apiData.limit}&skip=${apiData.skip}`),
    {
      onSuccess: (res) => {
        store.dispatch(setSubscribers(res));
      },
      onError: (error) => {
        console.log("Error:", error.response);
      },
      final: () => {
        store.dispatch(setLoader({ name: "subscriberLoader", value: false }));
      },
    }
  );
};

export const getAllSubscriptionApi = (apiData: {
  skip: number;
  limit: number;
  filter: ISubscriptionFilter;
}) => {
  const { skip, limit, filter } = apiData;
  store.dispatch(setLoader({ name: "subscriptionPlan", value: true }));
  apiHandler(
    () =>
      getAxiosInst().get<{ result: { data: IPlans[] }; totalPlans: number }>(
        `${endpoints.allSubscriptions}`,
        {
          params: {
            skip,
            limit,
            sort: filter.sort,
            search: !filter.search ? undefined : filter.search,
            IsSubscribed: filter.isSubscribe,
          },
        }
      ),
    {
      onSuccess: (res) => {
        store.dispatch(setAllSubscriptions({ data: res.result.data, total: res.totalPlans }));
      },
      onError: (error) => {
        makeToast({
          message: error.response?.data.message || "Failed to load Subscriction plans",
          type: STRINGS.toastType.error,
        });
      },
      final: () => {
        store.dispatch(setLoader({ name: "subscriptionPlan", value: false }));
      },
    }
  );
};
