import React, { FC, useCallback } from "react";
import "./style.css";

interface PhoneProps {
  animationIndex: number;
  turnFinished: boolean;
  hidden: boolean;
  className?: string;
}

export const Phone: FC<PhoneProps> = ({ animationIndex, className, hidden, turnFinished }) => {
  const isCurrentAnimationIndexAndFinished = useCallback(
    (index: number) => {
      return animationIndex === index && turnFinished;
    },
    [animationIndex, turnFinished]
  );

  const isCurrentAnimationIndex = useCallback(
    (index: number) => {
      return animationIndex === index;
    },
    [animationIndex]
  );

  const isGirlProfileAnimationIndex = useCallback(() => {
    return animationIndex === 1 || animationIndex === 2;
  }, [animationIndex]);

  return (
    <div className={className} hidden={hidden}>
      <img src="/empty_phone_2.png" className="empty_phone" alt="Avatar" />

      <img
        className={`url_card ${isCurrentAnimationIndexAndFinished(0) && "url_card_active"}`}
        src="/url_block.svg"
        alt="Avatar"
      />

      <>
        <img
          className={`
          profile_info profile_info_owl
        ${isCurrentAnimationIndex(1) && "hidden"}`}
          src="/profile_owl.svg"
          alt="Avatar"
        />

        <img
          className={`profile_info 
          ${!isGirlProfileAnimationIndex() && "hidden"}
          ${isCurrentAnimationIndexAndFinished(1) && "profile_info_active"}
          `}
          src="/profile_girl.svg"
          alt="Avatar"
        />
      </>

      <img
        className={`
        phone_link_icon
        ${(isCurrentAnimationIndex(5) || isCurrentAnimationIndex(7)) && "hidden"}
        ${isCurrentAnimationIndex(6) && "phone_link_icon_after_wall_of_fame"}
        `}
        src="/phone_link_icon.png"
        alt="Avatar"
      />

      <img
        className={`
        phone_link_subs_icon
        ${!isCurrentAnimationIndex(7) && "hidden"}
        `}
        src="/phone_link_&_subs.png"
        alt="Avatar"
      />

      <div className="bottom_links_wrapper">
        <img
          className={`bottom_links 
              ${
                (isCurrentAnimationIndex(4) ||
                  isCurrentAnimationIndex(5) ||
                  isCurrentAnimationIndex(6) ||
                  isCurrentAnimationIndex(7)) &&
                "hidden"
              }
              ${isCurrentAnimationIndexAndFinished(3) && "bottom_links_active"}`}
          src="/bottom_section.svg"
          alt="Avatar"
        />

        <img
          className={`youtube_link 
              ${!isCurrentAnimationIndex(4) && "hidden"}
              ${isCurrentAnimationIndexAndFinished(4) && "youtube_link_active"}
              `}
          src="/youtube_link.svg"
          alt="Avatar"
        />

        <img
          className={`send_gift_btn_links_collapsed 
          ${!isCurrentAnimationIndex(5) && "hidden"}
          ${isCurrentAnimationIndexAndFinished(5) && "send_gift_btn_links_collapsed_active"}
          `}
          src="/send_gift_links_collapsed.png"
          alt="Avatar"
        />

        <img
          className={`send_gift_btn_links_expanded 
          ${!isCurrentAnimationIndex(6) && "hidden"}
          ${isCurrentAnimationIndexAndFinished(6) && "send_gift_btn_links_expanded_active"}
          `}
          src="/send_gift_links_expanded.png"
          alt="Avatar"
        />

        <img
          className={`subscription_card 
          ${!isCurrentAnimationIndex(7) && "hidden"}
          ${isCurrentAnimationIndexAndFinished(7) && "subscription_card_active"}
          `}
          src="/phone_subscription_card.png"
          alt="Avatar"
        />
      </div>

      <>
        <img
          className={`phone_banner default_banner 
              ${isCurrentAnimationIndex(2) && "hidden"}`}
          src="/default_banner.png"
          alt="Avatar"
        />

        <img
          className={`phone_banner first_banner 
              ${isCurrentAnimationIndexAndFinished(2) && "banner_animation"}`}
          src="/first_banner.png"
          alt="Avatar"
        />

        <img
          className={`phone_banner second_banner 
              ${isCurrentAnimationIndexAndFinished(2) && "banner_animation"}`}
          src="/second_banner.png"
          alt="Avatar"
        />

        <img
          className={`phone_banner third_banner 
              ${isCurrentAnimationIndexAndFinished(2) && "banner_animation"}`}
          src="/third_banner.png"
          alt="Avatar"
        />
      </>

      <>
        <img
          className={`pattern first_pattern 
              ${isCurrentAnimationIndexAndFinished(2) && "pattern_animation"}`}
          src="/first_pattern.png"
          alt="Avatar"
        />
        <img
          className={`pattern second_pattern 
              ${isCurrentAnimationIndexAndFinished(2) && "pattern_animation"}`}
          src="/second_pattern.png"
          alt="Avatar"
        />
        <img
          className={`pattern third_pattern 
              ${isCurrentAnimationIndexAndFinished(2) && "pattern_animation"}`}
          src="/third_pattern.png"
          alt="Avatar"
        />
      </>

      <>
        <img
          className={`send_gift_btn 
          ${isCurrentAnimationIndexAndFinished(5) && "send_gift_btn_active"}
          `}
          src="/send_gift_btn.svg"
          alt="Avatar"
        />
      </>
    </div>
  );
};
