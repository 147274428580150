import styled from "styled-components";

export const SocialContentLinksFormContainer = styled.div`
  display: flex;
  flex-direction: column;

  .draggable_link {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0 12px;
    color: ${(p) => p.theme.colors.text.secondary};

    border: 1px solid ${(p) => p.theme.colors.divider};
    border-bottom: none;

    .inline {
      color: ${(p) => p.theme.colors.text.primary};
      flex: 1;

      & > div {
        border: none;
        & > input {
          padding: 12px 0;
        }
      }
    }
  }

  .first_draggable_link {
    border-radius: 12px 12px 0 0;
  }

  .error_border {
    border-bottom: 1px solid ${(p) => p.theme.colors.text.error};
  }
`;
