import React, { FC, KeyboardEventHandler, ReactNode, memo } from "react";

import { ErrorMsg, InputArea, Label, Wrapper, Info } from "./style";

import Eye from "assets/svg/Eye";
import EyeSlash from "assets/svg/EyeSlash";

interface Props {
  id?: string;
  textarea?: boolean;
  className?: string;
  label?: string;
  required?: boolean;
  error?: string;
  info?: string;
  type?: string;
  readOnly?: boolean;
  row?: number;
  name?: string;
  color?: string;
  maxLength?: number;
  value: string | number | readonly string[] | undefined;
  placeholder?: string;
  autoFocus?: boolean;
  autoComplete?: string;
  min?: string | number;
  labelSize?: string;
  show?: boolean;
  showPassword?: () => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  keyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
  handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  handleBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  handleFocus?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

// Input password component
const Password: FC<Props> = memo(
  ({
    id,
    labelSize,
    show,
    textarea = false,
    className,
    label,
    required,
    error,
    type = "text",
    info,
    color,
    readOnly = false,
    row,
    name,
    maxLength,
    value,
    placeholder,
    autoFocus,
    autoComplete,
    min,
    showPassword,
    onKeyDown,
    keyDown,
    handleChange,
    handleBlur,
    handleFocus,
  }) => (
    <Wrapper textarea={textarea} className={className || ""}>
      {label && (
        <Label htmlFor={label} fontSize={labelSize}>
          {required && <span>* </span>}
          {label}
        </Label>
      )}

      <InputArea
        className={`${textarea && "textareaWrap"} inputWrapper`}
        isValue={value}
        error={!!error}
        textarea={textarea}
        color={color}
      >
        {textarea ? (
          <textarea
            id={id}
            readOnly={readOnly}
            rows={row || 6}
            onBlur={handleBlur}
            name={name}
            maxLength={maxLength}
            value={value}
            onChange={handleChange}
            onFocus={handleFocus}
            onKeyDown={keyDown}
            autoFocus={autoFocus || false}
            placeholder={placeholder}
          />
        ) : (
          <>
            <input
              id={id ?? label}
              readOnly={readOnly}
              type={type}
              placeholder={placeholder}
              value={value}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name={name}
              autoFocus={autoFocus || false}
              min={min}
              maxLength={maxLength}
              autoComplete={autoComplete}
              onKeyDown={onKeyDown}
            />
            <div onClick={showPassword} className="eye">
              {show ? <Eye /> : <EyeSlash />}
            </div>
          </>
        )}
      </InputArea>
      {error && <ErrorMsg>{error}</ErrorMsg>}
      {info && <Info>{info}</Info>}
    </Wrapper>
  )
);

export default Password;
