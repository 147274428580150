import { FC, KeyboardEvent, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Attach from "assets/svg/Attach";
import Send from "assets/svg/Send";
import Button from "Components/Button";
import Input from "Components/Input";
import { messageContentSupportedExtensions } from "constants/file-extensions";
import { SIGNS } from "constants/signs";
import {
  ChatInputContainer,
  ChatInputWrapper,
} from "pages/public/chat/Components/Chat/ChatInput/styles";
import { ChatReply } from "pages/public/chat/Components/Chat/ChatReply";
import { getOpenedChat } from "store/slices/global/chatsSlice";
import { getUser } from "store/slices/global/userSlice";
import { selectRepliedMessage } from "store/slices/newMessages/messagesSlice";

const LINE_HEIGHT = 22; // px

interface Props {
  blocked?: boolean;
  sendTextMessage: (textMessage: string) => void;
  sendFileMessage: (files: File) => void;
  removeRepliedMessage: () => void;
  sendMediaCard: () => void;
}

export const ChatInput: FC<Props> = ({
  blocked,
  sendTextMessage,
  sendFileMessage,
  removeRepliedMessage,
  sendMediaCard,
}) => {
  const { t } = useTranslation();
  const [textMessage, setTextMessage] = useState<string>("");
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const openedChat = useSelector(getOpenedChat);
  const user = useSelector(getUser);
  const repliedMessage = useSelector(selectRepliedMessage);

  useEffect(() => {
    if (!textAreaRef.current) return;
    textAreaRef.current.focus();
  }, [openedChat]);

  const textAreaRows = useMemo<number>(() => {
    const textAreaElement = textAreaRef.current;
    if (!textAreaElement || !textMessage) return 1;

    const scrollHeight = textAreaElement.scrollHeight;
    const rows = Math.floor(scrollHeight / LINE_HEIGHT);
    if (rows <= 6) return rows;
    return 6;
  }, [textMessage]);

  const sendMessage = () => {
    if (!textMessage.trim()) return;
    sendTextMessage(textMessage);
    setTextMessage("");
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      setTextMessage((textMessage) => `${textMessage}${SIGNS.NEW_LINE}`);
      return;
    }
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  return (
    <ChatInputWrapper>
      {blocked && <div className="overlay" />}
      {repliedMessage && (
        <ChatReply messageToReply={repliedMessage} removeRepliedMessage={removeRepliedMessage} />
      )}
      <ChatInputContainer>
        <div className="attachment_button">
          <label
            htmlFor="file_upload"
            onClick={(event) => {
              if (!user || !openedChat) return;
              if (user.role === "creator" && openedChat.anotherUser.role === "fan") {
                event.stopPropagation();
                event.preventDefault();
                sendMediaCard();
              }
            }}
          >
            <Attach />
            <input
              id="file_upload"
              type="file"
              accept={messageContentSupportedExtensions.join(",")}
              onChange={(event) => {
                const file = event.target.files![0] as File;
                if (!file) return;
                sendFileMessage(file);
              }}
            />
          </label>
        </div>

        <Input
          readOnly={blocked}
          refArea={textAreaRef}
          className="message_input"
          textarea
          autoFocus
          row={textAreaRows}
          placeholder={t("new_chat.type_a_message")}
          value={textMessage}
          keyDown={handleKeyDown}
          handleChange={(event) => {
            setTextMessage(event.target.value);
          }}
        />
        <Button className="send_message_btn" variant="secondary" onClick={() => sendMessage()}>
          <Send />
        </Button>
      </ChatInputContainer>
    </ChatInputWrapper>
  );
};
