/**
 * downscales a base64 image to a specified width while maintaining the aspect ratio
 * the downscaled image is returned as a base64 string via a callback function
 *
 * @param {string} base64Image base64 encoded string of the image to be downscaled
 * @param {number} targetWidth desired width of the downscaled image
 * @param {function} callback callback function that receives the downscaled image as a base64 string
 */
export function downscaleImage(
  base64Image: string,
  targetWidth: number,
  callback: (img: string) => void
) {
  const img = new Image();
  img.src = base64Image;

  img.onload = function () {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) return;
    // Calculate the aspect ratio
    const aspectRatio = img.width / img.height;

    // Calculate the target height based on the aspect ratio and target width
    const targetHeight = targetWidth / aspectRatio;

    // Set the canvas dimensions to the target width and height
    canvas.width = targetWidth;
    canvas.height = targetHeight;

    // Draw the downscaled image on the canvas
    ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

    // Get the base64 representation of the downscaled image
    const downscaledBase64 = canvas.toDataURL("image/jpeg");

    // Invoke the callback with the downscaled base64 string
    callback(downscaledBase64);
  };
}
