import React, { useEffect, useState } from "react";

import { PhoneAnimationContainer } from "Components/PhoneAnimation/PhoneAnimationSection/style";
import PhoneAnimationTabs from "Components/PhoneAnimation/PhoneAnimationTabs";
import { Phone } from "Components/PhoneAnimation/PhoneComponent";
import { SidePhoneComponent } from "Components/PhoneAnimation/SidePhoneComponent";

// eslint-disable-next-line no-promise-executor-return
const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
const delayBeforePhoneSwap = 5000; // ms

const PhoneAnimationSection = () => {
  const [animationIndex, setAnimationIndex] = useState<number>(0);
  const [turnFinished, setTurnFinished] = useState<boolean>(true);

  const [rotateBy, setRotateBy] = useState(0);
  const [scale, setScale] = useState<-1 | 1>(1);

  const [currentSide, setCurrentSide] = useState<0 | 1>(0);

  const startRotation = async (index?: number) => {
    setTurnFinished(false);
    await sleep(200);
    setRotateBy((prev) => prev + 180);
    // invert backside from left to right
    setScale((prev) => (prev === 1 ? -1 : 1));

    await sleep(130);
    // changes visible side on half of turn
    await changeVisibleSide();
    await startBlocksAnimation(index);

    await sleep(130);

    setTurnFinished(true);
  };

  const changeVisibleSide = async () => {
    setCurrentSide((prev) => (prev === 0 ? 1 : 0));
  };

  const startBlocksAnimation = async (userClickIndex?: number) => {
    if (userClickIndex !== undefined) {
      setAnimationIndex(userClickIndex);
      return;
    }
    setAnimationIndex((index) => {
      if (index === null) return 0;
      if (index >= 7) return 0;
      return ++index;
    });
  };

  const goToOtherSlide = async (index?: number) => {
    // starts phone rotation
    await startRotation(index);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      goToOtherSlide();
    }, delayBeforePhoneSwap);

    return () => {
      clearTimeout(timeout);
    };
  }, [animationIndex]);

  return (
    <PhoneAnimationContainer>
      <div className="phone_animation_container">
        <PhoneAnimationTabs animationIndex={animationIndex} goToOtherSlide={goToOtherSlide} />

        <div className="flip-card">
          <div
            className={`flip-card-inner ${"clicked"}`}
            style={{
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              "--rotate": `${rotateBy}deg`,
              "--scale": `${scale}`,
            }}
            onClick={() => {
              goToOtherSlide();
            }}
          >
            <SidePhoneComponent animationIndex={animationIndex} turnFinished={turnFinished} />
            <Phone
              className="flip-card-front"
              animationIndex={animationIndex}
              turnFinished={turnFinished}
              hidden={Boolean(currentSide)}
            />
            <Phone
              className="flip-card-back"
              animationIndex={animationIndex}
              turnFinished={turnFinished}
              hidden={Boolean(!currentSide)}
            />
          </div>
        </div>
      </div>
    </PhoneAnimationContainer>
  );
};

export default PhoneAnimationSection;
