import { Suspense, memo } from "react";
import { Outlet } from "react-router-dom";

import { Content, DashBoardStyled, Sidebar, Wrapper } from "./style";

import FallbackLoader from "Components/FallbackLoader";
import Header from "Components/Header";
import SideBarMenu from "Components/SideBar";

const CommonLayout = memo(() => (
  <DashBoardStyled>
    <Header />
    <Wrapper>
      <div className="mainContent">
        <Sidebar>
          <SideBarMenu />
        </Sidebar>
        <Content>
          <Suspense fallback={<FallbackLoader />}>
            <Outlet />
          </Suspense>
        </Content>
      </div>
    </Wrapper>
  </DashBoardStyled>
));

export default CommonLayout;
