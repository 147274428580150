import styled from "styled-components";

import Modal from "Components/Modal/Modal";
import { deviceQuery } from "styles/mediaSizes";

export const PasswordModalContainer = styled(Modal)`
  .modal-dialog {
    margin: 0px;

    .modal-content {
      background: ${(p) => p.theme.colors.background.secondary};

      .modal-body {
        padding: 40px 16px 16px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: start;

  .info {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;

    .icon {
      border-radius: 50%;
      padding: 16px;
      background: ${(p) => p.theme.colors.background.fifth};
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;

      .title {
        font-size: ${(p) => p.theme.typography.text3};
      }

      .description {
        text-align: center;
        font-size: ${(p) => p.theme.typography.subText5};
        color: ${(p) => p.theme.colors.text.secondary};
      }
    }
  }

  .actions {
    display: flex;
    gap: 16px;

    button {
      flex: 1;
    }
  }

  ${deviceQuery.mobileM} {
    .actions {
      flex-direction: column;

      button {
        width: 100%;
      }
    }
  }
`;
