import styled, { css } from "styled-components";

import { AvatarSizes } from "./types";

import { deviceQuery } from "styles/mediaSizes";

export const AvatarContainer = styled.div<{ size: AvatarSizes; border?: boolean }>`
  display: flex;
  align-items: center;

  .avatar {
    object-fit: cover;
    border-radius: 50%;

    ${(p) =>
      p.border &&
      css`
        border: 2px solid ${p.theme.colors.background.secondary};
      `}

    ${(p) =>
      p.size === "xs" &&
      css`
        width: 32px;
        height: 32px;
      `}

    ${(p) =>
      p.size === "36" &&
      css`
        width: 36px;
        height: 36px;
      `}

    ${(p) =>
      p.size === "40" &&
      css`
        width: 40px;
        height: 40px;
      `}

    ${(p) =>
      p.size === "sm" &&
      css`
        width: 48px;
        height: 48px;
      `}

    ${(p) =>
      p.size === "md" &&
      css`
        width: 56px;
        height: 56px;
      `}

    ${(p) =>
      p.size === "ml" &&
      css`
        width: 80px;
        height: 80px;
      `}

    ${(p) =>
      p.size === "lg" &&
      css`
        width: 100px;
        height: 100px;
      `}

    ${(p) =>
      p.size === "xl" &&
      css`
        width: 144px;
        height: 144px;
      `}
  }

  ${deviceQuery.mobileSM} {
    .avatar {
      ${(p) =>
        p.size === "xl" &&
        css`
          width: 100px;
          height: 100px;
        `}
    }
  }

  ${deviceQuery.mobileM} {
    .avatar {
      ${(p) =>
        p.size === "md" &&
        css`
          width: 48px;
          height: 48px;
        `}
    }
  }
`;
