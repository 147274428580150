const LinkConnect = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.0205 7.11969L13.9405 4.19969C15.5505 2.58969 18.1705 2.58969 19.7805 4.19969C21.3905 5.80969 21.3905 8.42969 19.7805 10.0397L16.8605 12.9597"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9704 16.8595L10.0504 19.7795C8.44043 21.3895 5.82043 21.3895 4.21043 19.7795C2.60043 18.1695 2.60043 15.5495 4.21043 13.9395L7.13043 11.0195"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9203 9.07031L9.07031 14.9103"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LinkConnect;
