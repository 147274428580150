export const PersonChecked = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke="currentColor"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.89 19.23C7.71 19.23 5 18.63 5 16.25C5 13.87 7.7 13.24 10.89 13.24C14.08 13.24 16.78 13.84 16.78 16.22"
      strokeWidth="1.59"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8899 10.5C8.78986 10.5 7.10986 8.81996 7.10986 6.72996C7.10986 4.63996 8.78986 2.95996 10.8899 2.95996C12.9899 2.95996 14.6699 4.63996 14.6699 6.72996C14.6699 8.81996 12.9899 10.5 10.8899 10.5Z"
      strokeWidth="1.59"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9302 18.59L16.2902 20.95L21.0102 16.24"
      strokeWidth="1.59"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
