import { FC, useEffect } from "react";

import { InstagramContentPreviewContainer } from "pages/creator/Bio/BioLink/components/Social/SocialExpanded/InstagramContentPreviewCard/style";

interface Props {
  url: string;
  errorStateChanged?: (hasError: boolean) => void;
  className?: string;
  iframeClassName?: string;
}

const InstagramContentPreviewCard: FC<Props> = ({ url, errorStateChanged }) => {
  useEffect(() => {
    errorStateChanged && errorStateChanged(false);
  }, [url]);

  return (
    <InstagramContentPreviewContainer>
      <iframe
        title={url}
        className="iframe"
        frameBorder={0}
        src={`/instagram_iframe.html?url=${url}`}
        allowFullScreen
        scrolling="no"
        allow="encrypted-media;"
      />
    </InstagramContentPreviewContainer>
  );
};

export default InstagramContentPreviewCard;
