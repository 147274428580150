import styled from "styled-components";

import { OutfitRegular } from "styles/mixins";

export const DeleteTitle = styled.p`
  ${OutfitRegular};
  word-wrap: break-word;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 19px;
  line-height: 28px;
  color: ${(p) => p.theme.colors.text.primary};
`;

export const IconWrapper = styled.div`
  background-color: ${(p) => p.theme.colors.background.fifth};
  color: ${(p) => p.theme.colors.icon.primary};
  border-radius: 50%;
  padding: 16px;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 24px;

  .info_icon {
    width: 32px;
    height: 32px;
  }
`;

export const Content = styled.div`
  margin-top: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const Description = styled.p`
  flex: 1;
  margin: 8px 0 0;
  font-size: 14px;
`;
export const DeleteBtnWrapper = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  margin-top: 40px;
  font-size: 19px;
  line-height: 28px;

  @media (max-width: 445px) {
    grid-template-columns: auto;
  }
`;
