import styled from "styled-components";

import Button from "Components/Button";
import { deviceQuery } from "styles/mediaSizes";

export const CheckBoxedForm = styled.div`
  padding: 16px;
  background: ${(p) => p.theme.colors.background.secondary};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 14px;

  .divider {
    height: 1px;
    width: 100%;
    background: ${(p) => p.theme.colors.divider};
  }

  ${deviceQuery.tabletM} {
    flex-direction: row;

    .divider {
      height: 100%;
      width: 1px;
    }
  }

  ${deviceQuery.mobileL} {
    flex-direction: column;

    .divider {
      height: 1px;
      width: 100%;
    }
  }
`;

export const CheckBoxBlock = styled.div`
  display: flex;
  gap: 12px;

  .info_block {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .checkbox_title {
      color: ${(p) => p.theme.colors.text.primary};
      font-size: ${(p) => p.theme.typography.subText5};
      font-weight: 500;
      line-height: 22px;
    }

    .checkbox_description {
      color: ${(p) => p.theme.colors.text.secondary};
      font-size: ${(p) => p.theme.typography.subText5};
      font-weight: 500;
      line-height: 22px;
    }
  }
`;
