import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const Wrapper = styled.div`
  background-color: ${(p) => p.theme.colors.background.third};
  height: 100%;
`;
export const Hero = styled.div`
  background-color: ${(p) => p.theme.colors.text.secondary};
`;
export const DivSection = styled.div`
  display: flex;
  margin: 0 auto;
  font-size: 1rem;

  .green {
    color: ${(p) => p.theme.colors.green.primary};
    font-weight: 300;
    margin-left: 20px;
    text-decoration: none;
  }
  .light {
    margin-right: 20px;
    color: ${(p) => p.theme.colors.green.primary};
    font-weight: 300;
    text-decoration: none;
  }

  ${deviceQuery.laptopS} {
    font-size: 0.9rem;
    justify-content: center;
  }
`;

export const Image = styled.img<{ hero?: boolean; logo?: boolean }>`
  width: ${(p) => (p.hero && "75%") || (p.logo && "80px")};
  object-fit: contain;
  margin: 0 auto;
`;

export const SubHeading = styled.h4`
  font-size: 1rem;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.icon.secondary};
  font-family: ${(p) => p.theme.ff.outfit.bold};
`;
export const Heading = styled.h2`
  font-size: 3rem;
  text-transform: capitalize;
  color: ${(p) => p.theme.colors.text.primary};
  position: relative;
  width: fit-content;
  font-family: my;
  margin: 0 auto 0 0;
  font-family: ${(p) => p.theme.ff.outfit.bold};
  letter-spacing: -1px;

  ${deviceQuery.laptopS} {
    font-size: 2rem;
    margin: 0 auto;
  }
`;

export const Para = styled.p`
  margin: 2rem 0;
  color: ${(p) => p.theme.colors.text.primary};
  font-family: ${(p) => p.theme.ff.outfit.light};
  font-size: 1rem;

  .green {
    color: ${(p) => p.theme.colors.green.primary};
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ${deviceQuery.laptopS} {
    font-size: 0.9rem;
    margin: 1rem 0;
  }
`;
export const Main = styled.div`
  padding: 3rem 4rem;
  background: #1c1c2d url("/img/login-second.png");
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-position: left 100% bottom 0px;
  background-size: 50%;

  ${deviceQuery.tabletM} {
    padding: 2rem;
    background: ${(p) => p.theme.colors.background.secondary};
  }
`;

export const MainInner = styled.div`
  width: fit-content;
  text-align: left;

  ${deviceQuery.laptopS} {
    text-align: center;
  }
`;
