import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Warn from "assets/svg/Warn";
import {
  getYoutubeVideoIdFromLink,
  validateAndExtractInstagramPostID,
  validateAndExtractReelPostID,
  validateAndExtractTikTokID,
  validateFaceBookPostUrl,
  validateFaceBookReelUrl,
  validateIfShortenedFaceBookUrl,
  validateIfShortenedTiktokUrl,
} from "common/helpers/helper";
import { ContentPreviewCardContainer } from "pages/creator/Bio/BioLink/components/Social/SocialExpanded/ContentPreviewCard/style";
import { FacebookContentPreviewCard } from "pages/creator/Bio/BioLink/components/Social/SocialExpanded/FacebookContentPreviewCard";
import InstagramContentPreviewCard from "pages/creator/Bio/BioLink/components/Social/SocialExpanded/InstagramContentPreviewCard";
import TiktokContentPreviewCard from "pages/creator/Bio/BioLink/components/Social/SocialExpanded/TiktokContentPreviewCard";
import YoutubeContentPreviewCard from "pages/creator/Bio/BioLink/components/Social/SocialExpanded/YoutubeContentPreviewCard";
import { Platform } from "types/user";

interface Props {
  url: string;
  platform: Platform;
  isValid: boolean;
  errorStateChanged: (hasError: boolean) => void;
  letterId: string;
}

const ContentPreviewCard: FC<Props> = ({ platform, url, isValid, errorStateChanged, letterId }) => {
  const { t } = useTranslation();

  const invalidPlatFormUrlContent = useMemo(() => {
    return (
      <div className="invalid_content_container">
        <Warn />
        <div>{t("creator.profile.bioLinkContentInvalid")}</div>
      </div>
    );
  }, []);

  const getYoutubeContent = useMemo(() => {
    const videoId = getYoutubeVideoIdFromLink(url);
    if (!videoId) return invalidPlatFormUrlContent;

    return (
      <YoutubeContentPreviewCard
        videoId={videoId}
        className="w-100 h-100"
        errorStateChanged={errorStateChanged}
        iframeClassName="w-100 h-100 youtube_iframe"
      />
    );
  }, [url]);

  const getInstagramContent = useMemo(() => {
    const postVideoId = validateAndExtractInstagramPostID(url);
    const reelVideoId = validateAndExtractReelPostID(url);

    if (!postVideoId && !reelVideoId) return invalidPlatFormUrlContent;

    return (
      <InstagramContentPreviewCard
        url={url}
        className="w-100 h-100"
        errorStateChanged={errorStateChanged}
        iframeClassName="w-100 h-100 youtube_iframe"
      />
    );
  }, [url]);

  const getFacebookContent = useMemo(() => {
    if (validateIfShortenedFaceBookUrl(url)) {
      return (
        <FacebookContentPreviewCard
          url={url}
          className="w-100 h-100"
          errorStateChanged={errorStateChanged}
          iframeClassName="w-100 h-100 youtube_iframe"
        />
      );
    }

    const faceBookPostUrl = validateFaceBookPostUrl(url);
    const faceBookReelUrl = validateFaceBookReelUrl(url);

    if (!faceBookPostUrl && !faceBookReelUrl) return invalidPlatFormUrlContent;

    return (
      <FacebookContentPreviewCard
        url={url}
        className="w-100 h-100"
        errorStateChanged={errorStateChanged}
        iframeClassName="w-100 h-100 youtube_iframe"
      />
    );
  }, [url]);

  const getTiktokContent = useMemo(() => {
    if (validateIfShortenedTiktokUrl(url))
      return (
        <TiktokContentPreviewCard
          url={url}
          videoId=""
          className="w-100 h-100"
          errorStateChanged={errorStateChanged}
          iframeClassName="w-100 h-100 youtube_iframe"
        />
      );

    const videoId = validateAndExtractTikTokID(url);
    if (!videoId) return invalidPlatFormUrlContent;

    return (
      <TiktokContentPreviewCard
        url={url}
        videoId={videoId}
        className="w-100 h-100"
        errorStateChanged={errorStateChanged}
        iframeClassName="w-100 h-100 youtube_iframe"
      />
    );
  }, [url]);

  const cardContent = useMemo(() => {
    if (platform === "youtube") return getYoutubeContent;
    if (platform === "tiktok") return getTiktokContent;
    if (platform === "instagram") return getInstagramContent;
    if (platform === "facebook") return getFacebookContent;
  }, [getTiktokContent, getYoutubeContent, getInstagramContent, getFacebookContent, platform]);

  return (
    <ContentPreviewCardContainer isValid={isValid} profile={false}>
      <div className="main_preview_content">{cardContent}</div>
      <div className="bottom_content">
        <div className="letter_id">{letterId}</div>
        <div className="url">{url}</div>
      </div>
    </ContentPreviewCardContainer>
  );
};

export default ContentPreviewCard;
