import { FC } from "react";

interface Props {
  strokeColor?: string;
  currentColor?: boolean;
}

const Check: FC<Props> = ({ strokeColor, currentColor }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 12.2857L10.069 18L20 6"
      // eslint-disable-next-line no-unneeded-ternary
      stroke={currentColor ? "currentColor" : strokeColor ? strokeColor : "#25C374"}
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Check;
