import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const DetailsContainer = styled.div<{ mobile: boolean; gradientTheme?: string }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  max-width: 260px;
  position: relative;
  padding-top: ${(p) => (p.mobile ? "52px" : "76px")};

  .info {
    position: absolute;
    top: -36px;
    left: ${(p) => (p.mobile ? "0px" : "calc(50% - 50px)")};
    z-index: 2;
  }

  .userInfo {
    display: flex;
    border-radius: ${(p) => (p.mobile ? "8px" : "12px")};
    background: ${(p) => p.theme.colors.background.secondary};
    color: ${(p) => p.theme.colors.text.primary};
    padding: ${(p) => (p.mobile ? "8px" : "12px")};
    gap: ${(p) => (p.mobile ? "8px" : "12px")};
    flex-direction: column;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);

    .about {
      font-size: ${(p) => (p.mobile ? p.theme.typography.subText2 : p.theme.typography.subText5)};
    }

    .name {
      flex: 1;
      justify-content: center;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .actions {
      display: flex;
      flex-direction: column;
      gap: 8px;

      button {
        padding: 8px 20px 8px 8px;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: ${(p) => p.theme.colors.white.primary20};
        }
      }
    }

    .title {
      font-size: ${(p) => (p.mobile ? p.theme.typography.subText3 : p.theme.typography.subText5)};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .description {
      font-size: ${(p) => (p.mobile ? p.theme.typography.subText1 : p.theme.typography.subText3)};
      color: ${(p) => p.theme.colors.text.secondary};

      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-width: 548px;
    }
  }

  ${deviceQuery.laptopM} {
    padding-top: ${(p) => (p.mobile ? "50px" : "57px")};
    gap: 20px;

    .info {
      top: ${(p) => (p.mobile ? "-36px" : "-50px")};
    }
  }

  ${deviceQuery.tabletM} {
    width: 100%;
    max-width: unset;
    flex: unset;
    gap: 16px;

    .userInfo {
      .actions {
        display: none;
      }
    }
  }
`;
