const VolumeOff = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_21564_160644"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_21564_160644)">
      <path
        d="M19.8 22.6L16.775 19.575C16.3584 19.8417 15.9167 20.0708 15.45 20.2625C14.9834 20.4542 14.5 20.6083 14 20.725V18.675C14.2334 18.5917 14.4625 18.5083 14.6875 18.425C14.9125 18.3417 15.125 18.2417 15.325 18.125L12 14.8V20L7.00002 15H3.00002V8.99999H6.20002L1.40002 4.19999L2.80002 2.79999L21.2 21.2L19.8 22.6ZM19.6 16.8L18.15 15.35C18.4334 14.8333 18.6459 14.2917 18.7875 13.725C18.9292 13.1583 19 12.575 19 11.975C19 10.4083 18.5417 9.00832 17.625 7.77499C16.7084 6.54165 15.5 5.70832 14 5.27499V3.22499C16.0667 3.69165 17.75 4.73749 19.05 6.36249C20.35 7.98749 21 9.85832 21 11.975C21 12.8583 20.8792 13.7083 20.6375 14.525C20.3959 15.3417 20.05 16.1 19.6 16.8ZM16.25 13.45L14 11.2V7.94999C14.7834 8.31666 15.3959 8.86665 15.8375 9.59999C16.2792 10.3333 16.5 11.1333 16.5 12C16.5 12.25 16.4792 12.4958 16.4375 12.7375C16.3959 12.9792 16.3334 13.2167 16.25 13.45ZM12 9.19999L9.40003 6.59999L12 3.99999V9.19999Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default VolumeOff;
