import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";
import { OutfitRegular } from "styles/mixins";

export const MovingCard = styled.div`
  overflow: hidden;
  width: 200%;
  display: flex;
  height: 100%;

  > div {
    transition: all 0.3s ease;
    width: 100%;
  }
`;

export const SendEmailCard = styled.div`
  gap: 20px;
  flex-direction: column;
  display: flex;
  justify-content: center;
`;

export const NewPasswordBox = styled.div`
  gap: 20px;
  flex-direction: column;
  display: flex;
  justify-content: center;

  ${deviceQuery.mobileM} {
    padding-top: 40px;
  }
`;

export const ResendMsg = styled.p<{ disabled: boolean }>`
  ${OutfitRegular};
  color: ${(p) => (p.disabled ? p.theme.colors.text.secondary : p.theme.colors.text.third)};
  font-size: 0.85rem;
  margin: 5px 0;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  width: fit-content;

  ${(p) =>
    !p.disabled &&
    css`
      &:hover {
        text-decoration: underline;
      }
    `};
`;
