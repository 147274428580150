import { FC, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import LanguageSelector from "./components/LanguageSelector";
import { HeaderComp, Logo } from "./style";

import ArrowLeft from "assets/svg/ArrowLeft";
import { scrollTop } from "common/helpers/helper";
import Button from "Components/Button";
import { useWindowDimensions } from "customHooks/useWindowDimension";
import { fetchUserDetailsAPI } from "services/axios";

import { getWalletAPI } from "services/walletServices";
import { getLoggedInStatus, getRole } from "store/slices/authSlice";
import { getPublicUser } from "store/slices/global/previewProfileSlice";
import { getUser } from "store/slices/global/userSlice";

interface Props {
  hasButtons?: boolean;
  className?: string;
  blackLogo?: boolean;
}

// Header component for Bio page and unloggined users
const NewHeader: FC<Props> = ({ hasButtons = true, className, blackLogo = false }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef<HTMLHeadingElement>(null);
  const userDetails = useSelector(getUser);
  const userBio = useSelector(getPublicUser);
  const accountType = useSelector(getRole);
  const isLoggedIn = useSelector(getLoggedInStatus);
  const { width } = useWindowDimensions();

  const scrollFunction = () => {
    if (ref.current && blackLogo) {
      if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
        ref.current.classList.add("withBlur");
      } else {
        ref.current.classList.remove("withBlur");
      }
      return;
    }
    if (width && ref.current && width > 767) {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        ref.current.classList.add("withBlur");
      } else {
        ref.current.classList.remove("withBlur");
      }
    } else if (width && ref.current && width <= 767 && width > 430) {
      if (document.body.scrollTop > 160 || document.documentElement.scrollTop > 160) {
        ref.current.classList.add("withBlur");
      } else {
        ref.current.classList.remove("withBlur");
      }
    } else if (width && ref.current && width <= 430) {
      if (document.body.scrollTop > 140 || document.documentElement.scrollTop > 140) {
        ref.current.classList.add("withBlur");
      } else {
        ref.current.classList.remove("withBlur");
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      getWalletAPI();
    }

    scrollFunction();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", scrollFunction);
    return () => {
      window.removeEventListener("scroll", scrollFunction);
    };
  }, [width]);

  useEffect(() => {
    if (userDetails) return;

    if (isLoggedIn) {
      fetchUserDetailsAPI();
    }
  }, [accountType, isLoggedIn]);

  const redirectToTheHome = () => {
    scrollTop();
    navigate("/");
  };

  const hasAbilityToGoBack = useMemo(() => {
    // default means we came to this page not from the internal routing
    return !!(isLoggedIn && location.key !== "default");
  }, [isLoggedIn, location]);

  return (
    <HeaderComp ref={ref} isLogin={isLoggedIn} className={className}>
      <div className="content">
        <Logo onClick={redirectToTheHome}>
          {hasAbilityToGoBack && (
            <div className="back" onClick={() => navigate(-1)}>
              <ArrowLeft />
            </div>
          )}
          {blackLogo ? (
            <img className="cursor-pointer" src="/logoDark.svg" alt="LEWK" />
          ) : (
            <>
              {userBio.bioTheme === "light" ? (
                <img className="cursor-pointer light" src="/logoLight.svg" alt="LEWK" />
              ) : (
                <img className="cursor-pointer light" src="/logoDark.svg" alt="LEWK" />
              )}
              {userBio.bioTheme === "light" ? (
                <img className="cursor-pointer dark" src="/logoDark.svg" alt="LEWK" />
              ) : (
                <img className="cursor-pointer dark" src="/logoLight.svg" alt="LEWK" />
              )}
            </>
          )}
        </Logo>
        {!isLoggedIn && (
          <div className="authButton">
            {hasButtons && (
              <>
                {userBio.username ? (
                  <>
                    <Button round variant="primary" size="sm" onClick={() => navigate("/register")}>
                      {t("auth.get_started")}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button round variant="secondary" size="sm" onClick={() => navigate("/login")}>
                      {t("common.log_in")}
                    </Button>
                    <Button round variant="primary" size="sm" onClick={() => navigate("/register")}>
                      {t("header.create")}
                    </Button>
                  </>
                )}
              </>
            )}
            <LanguageSelector />
          </div>
        )}
      </div>
    </HeaderComp>
  );
};

export default NewHeader;
