// import { THOUSANDSEPARATOR_REGEX } from "constants/regex";
import { IconOption, Option } from "Components/SelectField/types";
import { SocialIcons } from "constants/appConstants";
import i18n from "i18n";
import { setChatsTotalCount } from "store/slices/global/chatsSlice";
import store from "store/store";
import { ChatStatus, NewChatItem, TotalChatCount } from "types/new-messages.types";
import { INotification } from "types/notification";

/**
 * @param {IEarningType} earningReport - earning report array
 * @returns return number with suffix
 */
export const formatNumber = (number: number) => {
  const suffixes = ["", "K", "M", "B", "T"];
  const suffixIndex = Math.floor((number.toString().length - 1) / 3);

  if (number >= 1000 && number < 1000000) {
    return (number / 1000).toFixed(1) + suffixes[suffixIndex];
  }
  if (number >= 1000000 && number < 1000000000) {
    return (number / 1000000).toFixed(1) + suffixes[suffixIndex];
  }
  if (number >= 1000000000) {
    return (number / 1000000000).toFixed(1) + suffixes[suffixIndex];
  }
  return number.toString();
};

/**
 * @param {number} number - number
 * @returns return nunber with commas
 */
export const numberWithCommas = (number: number) => {
  // return number.toString().replace(THOUSANDSEPARATOR_REGEX, ",");
  return number.toLocaleString("ru-RU");
};

/**
 * @param {IconOption} icon - option of icon
 * @param {string} value - link url
 * @returns return social icon of url
 */
export const autoSelectBioLink = (icon: IconOption, value: string): IconOption => {
  if (!isHTTPSLink(value)) {
    // disabled false
    return SocialIcons[21];
  }

  if (value.includes("discord.com") && icon.value !== SocialIcons[0].value) {
    return SocialIcons[0];
  }
  if (value.includes("etsy.com") && icon.value !== SocialIcons[1].value) {
    return SocialIcons[1];
  }
  if (
    (value.includes("facebook.com") || value.includes("fb.me") || value.includes("fb.com")) &&
    icon.value !== SocialIcons[2].value
  ) {
    return SocialIcons[2];
  }
  if (value.includes("instagram.com") && icon.value !== SocialIcons[3].value) {
    return SocialIcons[3];
  }
  if (
    (value.includes("linkedin.com") || value.includes("lkdn.in")) &&
    icon.value !== SocialIcons[4].value
  ) {
    return SocialIcons[4];
  }
  if (value.includes("medium.com") && icon.value !== SocialIcons[5].value) {
    return SocialIcons[5];
  }
  if (
    (value.includes("messenger.com") || value.includes("m.me")) &&
    icon.value !== SocialIcons[6].value
  ) {
    return SocialIcons[6];
  }
  if (
    (value.includes("odnoklassniki.com") || value.includes("ok.me") || value.includes("ok.ru")) &&
    icon.value !== SocialIcons[7].value
  ) {
    return SocialIcons[7];
  }
  if (value.includes("onlyfans.com") && icon.value !== SocialIcons[8].value) {
    return SocialIcons[8];
  }
  if (
    (value.includes("pinterest.com") || value.includes("pin.it")) &&
    icon.value !== SocialIcons[9].value
  ) {
    return SocialIcons[9];
  }
  if (
    (value.includes("reddit.com") || value.includes("redd.it")) &&
    icon.value !== SocialIcons[10].value
  ) {
    return SocialIcons[10];
  }
  if (value.includes("snapchat.com") && icon.value !== SocialIcons[11].value) {
    return SocialIcons[11];
  }
  if (
    (value.includes("telegram.org") ||
      value.includes("t.me") ||
      value.includes("telegram.me") ||
      value.includes("telegram.dog")) &&
    icon.value !== SocialIcons[12].value
  ) {
    return SocialIcons[12];
  }
  if (value.includes("threads.net") && icon.value !== SocialIcons[13].value) {
    return SocialIcons[13];
  }
  if (value.includes("tiktok") && icon.value !== SocialIcons[14].value) {
    return SocialIcons[14];
  }
  if (value.includes("tumblr.com") && icon.value !== SocialIcons[15].value) {
    return SocialIcons[15];
  }
  if (
    (value.includes("twitter.com") || value.includes("t.co")) &&
    icon.value !== SocialIcons[16].value
  ) {
    return SocialIcons[16];
  }
  if (
    (value.includes("vkontacte.ru") || value.includes("vk.com") || value.includes("vk.cc")) &&
    icon.value !== SocialIcons[17].value
  ) {
    return SocialIcons[17];
  }
  if (value.includes("weibo.com") && icon.value !== SocialIcons[18].value) {
    return SocialIcons[18];
  }
  if (
    (value.includes("whatsapp.com") || value.includes("wa.me")) &&
    icon.value !== SocialIcons[19].value
  ) {
    return SocialIcons[19];
  }
  if (
    (value.includes("youtube.com") || value.includes("y2u.be") || value.includes("youtu.be")) &&
    icon.value !== SocialIcons[20].value
  ) {
    return SocialIcons[20];
  }

  return SocialIcons[21];
};

/**
 * @param {string} value - link url
 * @returns return https validation value
 */
const isHTTPSLink = (value: string) => {
  return value.includes("https://");
};

const svgMIMEType = "PD94bWwgdmV";

/**
 * @param {string} value - custom icon
 * @returns return image element with favicon
 */
export const getImageTagFromFaviconBase64 = (value: string) => {
  const mime = value.startsWith(svgMIMEType) ? "image/svg+xml" : "image/jpg";
  const iconToBase64 = `data:${mime};base64,${value}`;
  return <img src={iconToBase64} width={24} height={24} alt="custom_favicon" />;
};

/**
 * @param {number} seconds - seconds
 * @returns return minutes from seconds
 */
export const getMinutesDurationFromSeconds = (seconds: number) => {
  return new Date(seconds * 1000).toISOString().slice(14, 19);
};

/**
 * updates the total chat count based on the status of the opened chat
 *
 * @param {NewChatItem | null} openedChat - the chat item currently opened
 * @param {ChatStatus} status - the new status of the chat
 * @param {TotalChatCount} total - the current total chat counts
 */
export const changeTotalCounts = (
  openedChat: NewChatItem | null,
  status: ChatStatus,
  total: TotalChatCount
) => {
  if (!openedChat) return;

  switch (openedChat.status) {
    case "active":
      store.dispatch(
        setChatsTotalCount({
          ...total,
          active: total.active - 1,
          blocked: total.blocked + 1,
        })
      );
      break;

    case "pending_approval":
      if (status === "blocked") {
        store.dispatch(
          setChatsTotalCount({
            ...total,
            pending_approval: total.pending_approval - 1,
            blocked: total.blocked + 1,
          })
        );
      } else {
        store.dispatch(
          setChatsTotalCount({
            ...total,
            pending_approval: total.pending_approval - 1,
            active: total.active + 1,
          })
        );
      }
      break;

    case "blocked":
      store.dispatch(
        setChatsTotalCount({
          ...total,
          active: total.active + 1,
          blocked: total.blocked - 1,
        })
      );
      break;

    default:
      break;
  }
};

/**
 * formats a duration given in seconds into a human-readable string
 *
 * @param {number} duration - duration in seconds
 * @returns {string} - formatted duration string
 */
export const formatDuration = (duration: number) => {
  const hrs = Math.floor(duration / 3600);
  const mins = Math.floor((duration % 3600) / 60);
  const secs = Math.floor(duration % 60);

  const hrsStr = hrs > 0 ? `${hrs}:` : "";
  const minsStr = `${mins < 10 ? "0" : ""}${mins}:`;
  const secsStr = `${secs < 10 ? "0" : ""}${secs}`;

  return `${hrsStr}${minsStr}${secsStr}`;
};

/**
 * @param {INotification} item - notification item
 * @returns return translated notification title
 */
export const getNotificationTitle = (item: INotification) => {
  switch (item.title) {
    case "hello_admin_new_campaign_arrived":
    case "campaign_activated":
    case "campaign_deactivated":
    case "campaign_updated_by_admin":
    case "campaign_amount_credited":
    case "campaign_video_change_status":
    case "cancel_subscription":
    case "cancel_subscription_refund":
    case "refund_reported":
    case "create_idea_campaign":
    case "new_idea_campaign":
    case "idea_campaign_updated":
    case "change_campaign_item_status":
    case "sell_nft":
    case "buy_nft":
    case "subscription_refund_status":
    case "subscription_refund_status_creator":
    case "subscription_refund_credited":
    case "subscription_refund_rejected":
    case "subscription_refund_rejected_creator":
    case "campaign_delay_notification":
    case "amount_transferred_for_campaign_video":
    case "insufficient_funds_for_monthly_charge":
    case "video_approval_status_update":
    case "challenge_reward_declined_by_admin":
      return i18n.t(`notification.${item.title}.title`);

    case "campaign_video_uploaded":
    case "create_campaign_items":
      return item.messageParams
        ? i18n.t(`notification.${item.title}.title`, {
            username: item.messageParams.username,
          })
        : item.title;

    case "send_gift":
      return item.messageParams
        ? i18n.t(`notification.${item.title}.title`, {
            user: item.messageParams.userUsername,
          })
        : item.title;

    case "thank_message":
    case "trend_reward":
    case "paid_message":
    case "campaign_idea_reward":
      return item.messageParams
        ? i18n.t(`notification.${item.title}.title`, {
            user: item.messageParams.senderUsername,
          })
        : item.title;

    default:
      return item.title;
  }
};

/**
 * @param {INotification} item - notification item
 * @param {Option[]} currencies - currencies options
 * @returns return translated notification text
 */
export const getNotificationText = (item: INotification, currencies: Option[]) => {
  switch (item.message) {
    case "hello_admin_new_campaign_arrived":
    case "campaign_activated":
    case "campaign_deactivated":
    case "campaign_updated_by_admin":
    case "campaign_amount_credited":
      return item.messageParams
        ? i18n.t(`notification.${item.message}.text`, {
            username: item.messageParams?.username,
            title: item.messageParams.campaignTitle,
          })
        : item.message;

    case "campaign_video_change_status":
      return item.messageParams
        ? i18n.t(`notification.${item.message}.text`, {
            status: item.messageParams.status,
          })
        : item.message;

    case "cancel_subscription":
    case "cancel_subscription_refund":
    case "refund_reported":
      return item.messageParams
        ? i18n.t(`notification.${item.message}.text`, {
            username: item.messageParams.username,
            subscriptionName: item.messageParams.subscriptionName,
          })
        : item.message;

    case "send_gift":
      return item.messageParams
        ? i18n.t(`notification.${item.message}.text`, {
            creator: item.messageParams.creatorUsername,
            user: item.messageParams.userUsername,
            amount: item.messageParams.amount,
            token: currencies.find((currency) => currency.value === item.messageParams!.token)
              ?.label,
          })
        : item.message;

    case "thank_message":
      return item.messageParams
        ? i18n.t(`notification.${item.message}.text`, {
            user: item.messageParams.senderUsername,
            creator: item.messageParams.receiverUsername,
            message: item.messageParams.message,
          })
        : item.message;

    case "create_idea_campaign":
    case "new_idea_campaign":
    case "idea_campaign_updated":
    case "create_campaign_items":
      return item.messageParams
        ? i18n.t(`notification.${item.message}.text`, {
            username: item.messageParams.username,
            title: item.messageParams.campaignName,
          })
        : item.message;

    case "change_campaign_item_status":
      return item.messageParams
        ? i18n.t(`notification.${item.message}.text`, {
            status: item.messageParams.status,
            title: item.messageParams.title,
          })
        : item.message;

    case "campaign_idea_reward":
      return item.messageParams
        ? i18n.t(`notification.${item.message}.text`, {
            creator: item.messageParams.receiverUsername,
            user: item.messageParams.senderUsername,
            amount: item.messageParams.amount,
            title: item.messageParams.campaignName,
          })
        : item.message;

    case "sell_nft":
    case "buy_nft":
      return item.messageParams
        ? i18n.t(`notification.${item.message}.text`, {
            username: item.messageParams.username,
            title: item.messageParams.nftTitle,
          })
        : item.message;

    case "subscription_refund_status":
    case "subscription_refund_status_creator":
    case "subscription_refund_credited":
    case "subscription_refund_rejected":
    case "subscription_refund_rejected_creator":
      return item.messageParams
        ? i18n.t(`notification.${item.message}.text`, {
            username: item.messageParams.username,
            subscription: item.messageParams.subscriptionName,
            creator: item.messageParams.creatorUsername,
            fan: item.messageParams.fanUsername,
          })
        : item.message;

    case "trend_reward":
      return item.messageParams
        ? i18n.t(`notification.${item.message}.text`, {
            creator: item.messageParams.receiverUsername,
            user: item.messageParams.senderUsername,
            title: item.messageParams.title,
            amount: item.messageParams.amount,
            token: currencies.find((currency) => currency.value === item.messageParams!.asset)
              ?.label,
          })
        : item.message;

    case "challenge_reward_declined_by_admin":
    case "campaign_delay_notification":
      return item.messageParams
        ? i18n.t(`notification.${item.message}.text`, {
            title: item.messageParams.title,
          })
        : item.message;

    case "amount_transferred_for_campaign_video":
      return item.messageParams
        ? i18n.t(`notification.${item.message}.text`, {
            username: item.messageParams.username,
            title: item.messageParams.title,
          })
        : item.message;

    case "insufficient_funds_for_monthly_charge":
      return item.messageParams
        ? i18n.t(`notification.${item.message}.text`, {
            username: item.messageParams.username,
            subscription: item.messageParams.subscriptionName,
          })
        : item.message;

    case "video_approval_status_update":
      return i18n.t(`notification.${item.message}.text`);

    case "campaign_video_uploaded":
      return item.messageParams
        ? i18n.t(`notification.${item.message}.text`, {
            username: item.messageParams.username,
          })
        : item.message;

    case "paid_message":
      return item.messageParams
        ? i18n.t(`notification.${item.message}.text`, {
            creator: item.messageParams.receiverUsername,
            user: item.messageParams.senderUsername,
            amount: item.messageParams.amount,
            assetId: currencies.find((currency) => currency.value === item.messageParams!.asset)
              ?.label,
          })
        : item.message;

    default:
      return item.message;
  }
};
