import styled, { css } from "styled-components";

import Button from "Components/Button";

import { deviceQuery } from "styles/mediaSizes";

export const FooterContainer = styled.footer<{ isLogin: boolean }>`
  ${(p) =>
    p.isLogin
      ? css`
          display: none;
        `
      : css`
          display: flex;
        `}
  justify-content: center;
  background: ${(p) => p.theme.colors.background.primary};
  width: 100%;

  .content {
    padding: 60px 24px;
    width: 100%;
    max-width: 1012px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .categoryContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 16px;
    }

    .divider {
      width: 100%;
      height: 1px;
      background: ${(p) => p.theme.colors.divider};
    }

    .logo {
      color: ${(p) => p.theme.colors.text.primary};
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  ${deviceQuery.laptopM} {
    .content {
      padding: 40px 20px;
      max-width: 1004px;

      .categoryContainer {
        flex-wrap: wrap;
        gap: 32px;
      }
    }
  }

  ${deviceQuery.tabletM} {
    .content {
      padding: 20px 16px;
      max-width: 996px;
    }
  }

  ${deviceQuery.mobileL} {
    .content {
      gap: 24px;

      .categoryContainer {
        justify-content: center;
      }
    }
    .logo {
      flex-direction: column;
      gap: 16px;
      align-items: center;
    }
  }
`;

export const JoinButton = styled(Button)`
  background: ${(p) => p.theme.colors.background.secondary};
  color: ${(p) => p.theme.colors.text.primary};
  font-size: ${(p) => p.theme.typography.text3};
  border-radius: 12px;
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;

  ${deviceQuery.desktop} {
    gap: 4px;
  }
`;
