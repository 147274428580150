import { useState, MouseEvent, useEffect, FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { LanguageSelectContainer } from "./style";

import ArrowDownSmall from "assets/svg/ArrowDownSmall";
import Check from "assets/svg/Check";
import { LANGUAGES } from "constants/appConstants";
import { updateProfileInfoAPI } from "services/UserServices";
import { getUser } from "store/slices/global/userSlice";
import { LANGUAGES as LANGUAGESTYPE } from "types/language.types";

interface Props {
  onSelect: () => void;
}

const LanguageSelect: FC<Props> = ({ onSelect }) => {
  const { t } = useTranslation();
  const [openLanguages, setOpenLanguages] = useState(false);
  const user = useSelector(getUser);
  const [language, setLanguage] = useState(LANGUAGES[0]);

  const handleChange = (value: string) => {
    updateProfileInfoAPI({
      data: {
        languages: value as LANGUAGESTYPE,
      },
      part: "general",
    });
    setOpenLanguages(false);
    onSelect();
  };

  const handleLanguages = (event: MouseEvent) => {
    event.stopPropagation();
    setOpenLanguages(!openLanguages);
  };

  useEffect(() => {
    if (!user) return;
    setLanguage(LANGUAGES.find((item) => item.value === user.languages) || LANGUAGES[0]);
  }, [user]);

  return (
    <LanguageSelectContainer
      className="languages"
      show={openLanguages}
      onClick={(event) => handleLanguages(event)}
    >
      <div className="item">
        <div className="itemContent">
          <div>{language.icon}</div>
          <p>{t("common.Languages")}</p>
        </div>
        <div className="action">
          <span className="selected">{t(language?.label)}</span>
          <div className="arrow">
            <ArrowDownSmall />
          </div>
        </div>
      </div>
      <div className="options">
        <p className="expandable">
          {LANGUAGES.map((item) => (
            <div
              key={item.value}
              className={`option ${item.value === language.value ? "active" : ""}`}
              onClick={() => handleChange(item.value)}
            >
              <span>{t(item.label)}</span>
              <div className="icon">
                <Check />
              </div>
            </div>
          ))}
        </p>
      </div>
    </LanguageSelectContainer>
  );
};

export default LanguageSelect;
