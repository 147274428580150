export const sizeNumbers = {
  mobileXS: 320,
  mobileSE: 350,
  mobile: 360,
  mobileS: 380,
  mobileMS: 430,
  mobileM: 480,
  mobileSM: 575, // small
  mobileL: 600,
  tabletS: 650,
  tabletM: 767, // medium
  tabletL: 900,
  laptopS: 991, // large
  laptopM: 1024,
  laptopL: 1200, // extra large
  laptopXL: 1250,
  laptopXXL: 1280,
  desktopS: 1366,
  desktop: 1440, // extra extra large
  desktopL: 1600,
  desktopXL: 1920,
};

export const size = {
  mobileXS: `${sizeNumbers.mobileXS}px`,
  mobileSE: `${sizeNumbers.mobileSE}px`,
  mobile: `${sizeNumbers.mobile}px`,
  mobileS: `${sizeNumbers.mobileS}px`,
  mobileMS: `${sizeNumbers.mobileMS}px`,
  mobileM: `${sizeNumbers.mobileM}px`,
  mobileSM: `${sizeNumbers.mobileSM}px`,
  mobileL: `${sizeNumbers.mobileL}px`,
  tabletS: `${sizeNumbers.tabletS}px`,
  tabletM: `${sizeNumbers.tabletM}px`,
  tabletL: `${sizeNumbers.tabletL}px`,
  laptopS: `${sizeNumbers.laptopS}px`,
  laptopM: `${sizeNumbers.laptopM}px`,
  laptopL: `${sizeNumbers.laptopL}px`,
  laptopXL: `${sizeNumbers.laptopXL}px`,
  laptopXXL: `${sizeNumbers.laptopXXL}px`,
  desktopS: `${sizeNumbers.desktopS}px`,
  desktop: `${sizeNumbers.desktop}px`,
  desktopL: `${sizeNumbers.desktopL}px`,
  desktopXL: `${sizeNumbers.desktopXL}px`,
};

export const deviceQuery = {
  mobileXS: `@media (max-width: ${size.mobileXS})`,
  mobileSE: `@media (max-width: ${size.mobileSE})`,
  mobileS: `@media (max-width: ${size.mobileS})`,
  mobileMS: `@media (max-width: ${size.mobileMS})`,
  mobileM: `@media (max-width: ${size.mobileM})`,
  mobileSM: `@media (max-width: ${size.mobileSM})`,
  mobileL: `@media (max-width: ${size.mobileL})`,
  tabletS: `@media (max-width: ${size.tabletS})`,
  tabletM: `@media (max-width: ${size.tabletM})`,
  tabletL: `@media (max-width: ${size.tabletL})`,
  laptopS: `@media (max-width: ${size.laptopS})`,
  laptopM: `@media (max-width: ${size.laptopM})`,
  laptopL: `@media (max-width: ${size.laptopL})`,
  laptopXL: `@media (max-width: ${size.laptopXL})`,
  desktopS: `@media (max-width: ${size.desktopS})`,
  desktop: `@media (max-width: ${size.desktop})`,
  desktopL: `@media (max-width: ${size.desktopL})`,
  desktopXL: `@media (max-width: ${size.desktopXL})`,
};

export const mediaQueries = {
  mobileL: `@media (max-width: ${size.mobileMS})`,
  mobileS: `@media (max-width: ${size.mobile})`,
  tabletM: `@media (max-width: ${size.tabletM})`,
  tabletL: `@media (max-width: ${size.laptopXXL})`,
  desktopS: `@media (max-width: ${size.desktopS})`,
  desktopM: `@media (max-width: ${size.desktopXL})`,
};
