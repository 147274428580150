const TelegramColor = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
      fill="url(#paint0_linear_17049_85819)"
    />
    <path
      d="M8.45001 12.81L9.76001 16.42C9.76001 16.42 9.92002 16.76 10.1 16.76C10.28 16.76 12.87 14.06 12.87 14.06L15.76 8.47998L8.50002 11.88L8.45001 12.81Z"
      fill="#C8DAEA"
    />
    <path
      d="M10.18 13.73L9.93001 16.39C9.93001 16.39 9.83001 17.21 10.64 16.39C11.46 15.57 12.24 14.95 12.24 14.95"
      fill="#A9C6D8"
    />
    <path
      d="M8.47003 12.93L5.79002 12.06C5.79002 12.06 5.47002 11.93 5.57002 11.63C5.59002 11.57 5.63002 11.52 5.76002 11.43C6.35002 11.02 16.77 7.26996 16.77 7.26996C16.77 7.26996 17.06 7.16996 17.24 7.23996C17.33 7.26996 17.39 7.33996 17.41 7.42996C17.43 7.50996 17.44 7.58996 17.43 7.66996C17.43 7.73996 17.43 7.79996 17.41 7.89996C17.35 8.91996 15.45 16.56 15.45 16.56C15.45 16.56 15.34 17.01 14.93 17.02C14.73 17.02 14.53 16.95 14.39 16.81C13.59 16.12 10.83 14.27 10.22 13.86C10.19 13.84 10.17 13.81 10.17 13.78C10.17 13.74 10.21 13.68 10.21 13.68C10.21 13.68 15.02 9.40996 15.14 8.95996C15.14 8.92996 15.11 8.90996 15.06 8.91996C14.74 9.03996 9.21003 12.53 8.60003 12.92C8.56003 12.93 8.51002 12.94 8.46002 12.92L8.47003 12.93Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_17049_85819"
        x1="12"
        y1="23.01"
        x2="12"
        y2="1.01"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1D93D2" />
        <stop offset="1" stopColor="#38B0E3" />
      </linearGradient>
    </defs>
  </svg>
);

export default TelegramColor;
