/* eslint-disable no-use-before-define */

import getAxiosInst, { apiHandler, endpoints, getCustomizeLevel } from "./axios";

import { makeToast } from "common/helpers/helper";
import { getLocalStorageItem } from "common/helpers/localStorage";
import { ROLES, STORAGES, STRINGS } from "constants/appConstants";
import {
  removePeopleData,
  setAllPeoples,
  setCategoryReports,
  setCustomizePlatforms,
  setDefaultLandingPages,
  setMaintenanceInputs,
  setMonetizationLimits,
  setReferralCodes,
  setUserLevel,
  setCategories,
  setNfts,
  setCampaigns,
  setCampaignDetails,
  setCreatorVideos,
  setTimeIntervel,
  setTimeIntervelLowerLevel,
  setAverageView,
  setMinimumView,
  setMessageReport,
  setTransactionExplore,
  setFeeInformation,
  setBalance,
  setMonthlyFeeInformation,
  setTemplates,
} from "store/slices/admin/adminSlice";
import { setAdminChallenges, setAdminParticipants } from "store/slices/admin/challengeRewardSlice";
import {
  deleteOnboarding,
  setOnboarding,
  setOnboardings,
  setRoles,
  unsetOnboarding,
} from "store/slices/admin/onboardingsSlice";
import { setUsersForRequests } from "store/slices/admin/signupRequestsSlice";
import { setRegistrationStatus } from "store/slices/authSlice";
import { setChallenge, setParticipates } from "store/slices/creator/challengeDetailSlice";
import { setEarnings } from "store/slices/creator/creatorSlice";
import { setLoader } from "store/slices/featureSlice";
import store from "store/store";

// TODO - refactoring this file to typescript, remove unused imports

// TODO - add typing for function params, response
export const getActiveUsersStatus = () => {
  return getAxiosInst().get(`/admin/ActiveUserstats`);
};

// TODO - add typing for function params, response
export const getNewlyRegisteredUsers = async () => {
  return getAxiosInst().get(`/admin/newRegisteredUser`);
};

// TODO - add typing for function params, response
export const notificationAPI = (apiData, callback = () => {}) => {
  apiHandler(() => getAxiosInst().post("/notification/createNotification", apiData), {
    onSuccess: (data) => {
      makeToast({
        message: "Notification sent",
        type: STRINGS.toastType.success,
      });
      callback();
    },
    onError: (error) => {
      console.log("catch error ===>>>", error.response);
      callback();
    },
  });
};

// TODO - add typing for function params, response
export const getCampaignVideoDetailsById = ({ id }, callback = () => {}) => {
  apiHandler(
    () => getAxiosInst().post(`${endpoints.campaignVideoDetails}?campaign_video_id=${id}`),
    {
      onSuccess: (data) => {
        callback(data);
      },
      onError: (error) => {
        callback(error);
      },
    }
  );
};

// TODO - add typing for function params, response
export const changeVideoStatus = ({ id, apiData }, callback = () => {}) => {
  apiHandler(
    () =>
      getAxiosInst().post(
        `${endpoints.campaignVideoChangeStatus}?campaign_video_id=${id}`,
        apiData
      ),
    {
      onSuccess: (response) => {
        makeToast({
          message: response?.data || "Video status changed successfully",
          type: STRINGS.toastType.success,
        });

        callback(response);
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message || "Unable to change the video status",
          type: STRINGS.toastType.error,
        });
        callback(error?.response);
      },
    }
  );
};

// TODO - add typing for function params, response
export const increaseCampaignBudget = ({ id, apiData }, callback = () => {}) => {
  apiHandler(
    () => getAxiosInst().post(`${endpoints.increaseCampaignBudget}?campaign_id=${id}`, apiData),
    {
      onSuccess: (response) => {
        makeToast({
          message: response?.data || "Request submitted successfully",
          type: STRINGS.toastType.success,
        });

        callback(response);
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message || "Unable to submit the request",
          type: STRINGS.toastType.error,
        });
        callback(error?.response);
      },
    }
  );
};

// TODO - add typing for function params, response
export const getCampaignPaymentStatus = ({ id }, callback = () => {}) => {
  apiHandler(() => getAxiosInst().get(`${endpoints.paymentStatus}?campaign_id=${id}`), {
    onSuccess: (data) => {
      callback(data);
    },
    onError: (error) => {
      callback(error);
    },
  });
};

// TODO - add typing for function params, response
export const getMaintenanceStatusAPI = ({ type, callback = () => {} }) => {
  apiHandler(
    () =>
      getAxiosInst().get("/status/getRegistrationStatus", {
        params: { type },
      }),
    {
      onSuccess: (data) => {
        store.dispatch(
          setMaintenanceInputs({
            msg: data.data.message,
            switch: data.data.maintenance,
            registration: data.data.status,
            id: data.data._id,
          })
        );
        callback();
      },
      onError: (error) => {
        console.log("catch error ===>>>", error.response);
        callback();
      },
    }
  );
};

// TODO - add typing for function params, response
export const updateMaintenanceStatusAPI = ({ id, data, callback = () => {} }) => {
  apiHandler(
    () =>
      getAxiosInst().put("/status/updateRegistrationStatus", data, {
        params: { id },
      }),
    {
      onSuccess: (data) => {
        makeToast({
          message: "Settings saved",
          type: STRINGS.toastType.success,
        });
        callback();
      },
      onError: (error) => {
        console.log("catch error ===>>>", error.response);
        callback();
      },
    }
  );
};

// TODO - add typing for function params, response
export const getRegistrationStatusAPI = ({ type, callback = () => {} }) => {
  apiHandler(
    () =>
      getAxiosInst().get("/status/getRegistrationStatus", {
        params: { type },
      }),
    {
      onSuccess: (data) => {
        store.dispatch(
          setRegistrationStatus({
            type: data.data.type,
            status: data.data.status,
            maintenance: data.data.maintenance,
            message: data.data.message,
            createdAt: data.data.createdAt,
          })
        );
        callback();
      },
      onError: (error) => {
        console.log("catch error ===>>>", error.response);
        callback();
      },
    }
  );
};

// TODO - add typing for function params, response
export const getUsernamesBasedOnRoleAPI = ({ params }) =>
  getAxiosInst().get("/notification/getusersfornotification", { params });

// TODO - add typing for function params, response
export const getCreatorByUsernameAPI = ({ name }) =>
  getAxiosInst().get("/notification/getusersfornotification", {
    params: {
      limit: 1,
      skip: 0,
      role: "creator",
      username: name,
    },
  });

// TODO - add typing for function params, response
export const getReferralCodesAPI = (data, callback = () => {}) => {
  const id = getLocalStorageItem({ key: STORAGES.userId });

  apiHandler(() => getAxiosInst().get(`/admin/referralcodes/${id}`), {
    onSuccess: (data) => {
      data.codes.length === 0
        ? setReferralCodesAPI()
        : store.dispatch(setReferralCodes(data.codes.splice(0, 2)));
      callback();
    },
    onError: (error) => {
      console.log("catch error ===>>>", error.response);
    },
  });
};

// TODO - add typing for function params, response
export const setReferralCodesAPI = (data, callback = () => {}) => {
  const id = getLocalStorageItem({ key: STORAGES.userId });

  apiHandler(() => getAxiosInst().post("/admin/referralcodes", { id }), {
    onSuccess: (data) => {
      getReferralCodesAPI({}, callback);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

// TODO - add typing for function params, response

export const updateCustomizeLevelAPI = ({ data, id }) =>
  Promise.resolve(
    getAxiosInst().put("/level/updatelevel", data, {
      params: {
        id,
      },
    })
  );

// TODO - add typing for function params, response

export const activateUserAccount = ({ apiData, name, page, totalRows }, callback = () => {}) => {
  apiHandler(() => getAxiosInst().post("/admin/activateuserbyAdmin", apiData), {
    onSuccess: () => {
      callback();
      makeToast({ message: `${name} Successfully activated` });
      store.dispatch(removePeopleData({ id: apiData.id, page, totalRows }));
    },
    onError: (error) => {
      console.log("catch error ===>>>", error.response);
    },
  });
};

// TODO - add typing for function params, response

export const deactivateUserAccount = ({ apiData, name, page, totalRows }, callback = () => {}) => {
  apiHandler(() => getAxiosInst().post("/admin/deactivateuserbyAdmin", apiData), {
    onSuccess: () => {
      callback();
      makeToast({ message: `${name} Successfully De-activated` });
      store.dispatch(removePeopleData({ id: apiData.id, page, totalRows }));
    },
    onError: (error) => {
      console.log("catch error ===>>>", error.response);
    },
  });
};

// TODO - add typing for function params, response

export const approveUser = ({ id, role, name }, callback = () => {}) => {
  store.dispatch(setLoader({ name: "signUpManageLoader", value: true }));
  apiHandler(() => getAxiosInst().post("/admin/activateuserbyAdmin", { id, role }), {
    onSuccess: () => {
      makeToast({ message: `${name} Successfully activated` });
      callback();
    },
    onError: (error) => {
      console.log("catch error ===>>>", error.response);
    },
    final: () => {
      store.dispatch(setLoader({ name: "signUpManageLoader", value: false }));
    },
  });
};

// TODO - add typing for function params, response

export const declaineUser = ({ id, role, name }, callback = () => {}) => {
  store.dispatch(setLoader({ name: "signUpManageLoader", value: true }));
  apiHandler(() => getAxiosInst().post("/admin/deactivateuserbyAdmin", { id, role }), {
    onSuccess: () => {
      makeToast({ message: `${name} Successfully De-activated` });
      callback();
    },
    onError: (error) => {
      console.log("catch error ===>>>", error.response);
    },
    final: () => {
      store.dispatch(setLoader({ name: "signUpManageLoader", value: false }));
    },
  });
};

// TODO - add typing for function params, response

export const changeUsername = ({ id, name }, callback) => {
  apiHandler(() => getAxiosInst().post(endpoints.adminRenameUser, { userId: id, username: name }), {
    onSuccess: () => {
      makeToast({
        message: "Username updated",
        type: STRINGS.toastType.success,
      });
      callback();
    },
    onError: (error) => {
      makeToast({
        message: error?.response?.data?.message,
        type: STRINGS.toastType.error,
      });
      callback();
    },
  });
};

// TODO - add typing for function params, response

export const changeUserEmail = ({ id, email }, callback) => {
  apiHandler(() => getAxiosInst().post(endpoints.adminChangeUserEmail, { id, email }), {
    onSuccess: () => {
      makeToast({
        message: "Email updated",
        type: STRINGS.toastType.success,
      });
      callback();
    },
    onError: (error) => {
      makeToast({
        message: error?.response?.data?.message,
        type: STRINGS.toastType.error,
      });
      callback();
    },
  });
};

// TODO - add typing for function params, response

export const changeUserPattern = ({ id, wallpaper }, callback) => {
  apiHandler(
    () =>
      getAxiosInst().post(endpoints.adminUpdateUserCustomization, {
        userId: id,
        wallpaper,
      }),
    {
      onSuccess: () => {
        makeToast({
          message: "User Pattern Updated",
          type: STRINGS.toastType.success,
        });
        callback();
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message,
          type: STRINGS.toastType.error,
        });
        callback();
      },
    }
  );
};

// TODO - add typing for function params, response

export const resetUserPassword = ({ id }, callback) => {
  apiHandler(() => getAxiosInst().post(endpoints.adminResetUserPassword, { id }), {
    onSuccess: (res) => {
      makeToast({
        message: "Password reset",
        type: STRINGS.toastType.success,
      });
      callback(res);
    },
    onError: (error) => {
      makeToast({
        message: error?.response?.data?.message,
        type: STRINGS.toastType.error,
      });
    },
  });
};

// TODO - add typing for function params, response

export const resetUserMFA = ({ id }, callback) => {
  apiHandler(() => getAxiosInst().post(endpoints.adminResetMfa, { id }), {
    onSuccess: () => {
      makeToast({
        message: "MFA reset",
        type: STRINGS.toastType.success,
      });
      callback();
    },
    onError: (error) => {
      makeToast({
        message: error?.response?.data?.message,
        type: STRINGS.toastType.error,
      });
    },
  });
};

// TODO - add typing for function params, response

export const fetchUsersAPI = ({ limit, skip, apiData }, callback = () => {}) => {
  delete apiData.sort;
  store.dispatch(setLoader({ name: "allUsersLoader", value: true }));
  apiHandler(
    () =>
      getAxiosInst().post(
        "/admin/usersbyfilter",
        { ...apiData, search: apiData.search || undefined },
        {
          params: {
            limit,
            skip,
          },
        }
      ),
    {
      onSuccess: (data) => {
        callback();
        store.dispatch(
          setAllPeoples({ data: data.result, total: data.Totaluser, all: data.allUsers })
        );
      },
      onError: (error) => {
        console.log("catch error ===>>>", error, error.response);
      },
      final: () => {
        store.dispatch(setLoader({ name: "allUsersLoader", value: false }));
      },
    }
  );
};

// TODO - add typing for function params, response

export const getUsersForRequestAPI = ({ limit, skip, apiData }, callback = () => {}) => {
  const { order, sort, search, social } = apiData;
  store.dispatch(setLoader({ name: "searchUserLoader", value: true }));
  apiHandler(
    () =>
      getAxiosInst().post(
        "/admin/usersbyfilter",
        {
          role: ROLES.creator,
          search: search || undefined,
          status: "null",
          socialmedianame: social === "all" ? undefined : social,
          order,
          sort,
        },
        {
          params: {
            limit,
            skip,
          },
        }
      ),
    {
      onSuccess: (data) => {
        callback();
        store.dispatch(setUsersForRequests({ data: data.result, total: data.Totaluser }));
      },
      onError: (error) => {
        console.log("catch error ===>>>", error, error.response);
      },
      final: () => {
        store.dispatch(setLoader({ name: "searchUserLoader", value: false }));
      },
    }
  );
};

// TODO - add typing for function params, response

export const setTimeIntervelData = ({ data, callback = () => {} }) => {
  apiHandler(() => getAxiosInst().post("/configuration/timeInterval", data), {
    onSuccess: () => {
      callback();
      makeToast({ message: " Time Interval Updated Successfully." });
      store.dispatch(
        setTimeIntervel({
          _id: data._id,
          reference: data.reference,
          reference_value: data.reference_value,
          format: data.format,
        })
      );
    },
    onError: (error) => {
      console.log("catch error ===>>>", error.response);
      callback();
    },
  });
};

// TODO - add typing for function params, response

export const setAverageViewData = ({ data, callback = () => {} }) => {
  apiHandler(() => getAxiosInst().post("/configuration/setAverageViews", data), {
    onSuccess: () => {
      callback();
      makeToast({ message: "Average View Updated Successfully." });
      store.dispatch(
        setAverageView({
          reference: "average_views",
          reference_value: data.reference_value,
        })
      );
    },
    onError: (error) => {
      console.log("catch error ===>>>", error.response);
      callback();
    },
  });
};

// TODO - add typing for function params, response

export const setMinimumViewData = ({ data, callback = () => {} }) => {
  apiHandler(() => getAxiosInst().post("/configuration/setMinimumViews", data), {
    onSuccess: () => {
      callback();
      makeToast({ message: " Successfully Update Minimum View" });
      store.dispatch(
        setMinimumView({
          reference: "minimum_views",
          reference_value: data.reference_value,
        })
      );
    },
    onError: (error) => {
      console.log("catch error ===>>>", error.response);
      callback();
    },
  });
};

// TODO - add typing for function params, response

export const updateCampaign = ({ apiData, id }, callback = () => {}) => {
  apiHandler(() => getAxiosInst().post(`${endpoints.adminEditCampaign}?id=${id}`, apiData), {
    onSuccess: (data) => {
      makeToast({
        message: "Campaign updated successfully",
        type: STRINGS.toastType.success,
      });
      callback(data);
    },
    onError: (error) => {
      makeToast({
        message: error?.response?.data?.message || "Unable to update Campaign",
        type: STRINGS.toastType.error,
      });
      callback(error);
    },
  });
};

// TODO - add typing for function params, response

export const setTimeIntervelLowerLevelData = ({ data, callback = () => {} }) => {
  apiHandler(() => getAxiosInst().post("/configuration/campaignInterval", data), {
    onSuccess: () => {
      callback();
      makeToast({
        message: "Notify to Low Level Creator Updated Successfully",
      });
      store.dispatch(
        setTimeIntervelLowerLevel({
          reference_value: data.timeInterval,
        })
      );
    },
    onError: (error) => {
      makeToast({
        message: error.response.data.message,
        type: STRINGS.toastType.error,
      });
      console.log("catch error ===>>>", error.response);
      callback();
    },
  });
};

// TODO - add typing for function params, response

export const getTimeIntervelAPI = () => {
  apiHandler(() => getAxiosInst().get("/configuration/getTimeInterval"), {
    onSuccess: (res) => {
      store.dispatch(
        setTimeIntervel({
          _id: res.data._id,
          reference: res.data.reference,
          reference_value: res.data.reference_value,
          format: res.data.format,
        })
      );
    },
    onError: (error) => {
      console.log("catch error ===>>>", error);
    },
  });
};

// TODO - add typing for function params, response

export const getTimeIntervelLowerLevelAPI = () => {
  apiHandler(() => getAxiosInst().get("/configuration/getCampaignInterval"), {
    onSuccess: (res) => {
      store.dispatch(
        setTimeIntervelLowerLevel({
          _id: res.data._id,
          reference: res.data.reference,
          reference_value: res.data.reference_value,
        })
      );
    },
    onError: (error) => {
      console.log("catch error ===>>>", error);
    },
  });
};

// TODO - add typing for function params, response

export const getAverageView = () => {
  apiHandler(() => getAxiosInst().get("/configuration/getAverageViews"), {
    onSuccess: (res) => {
      store.dispatch(
        setAverageView({
          reference: "average_views",
          reference_value: res.data.reference_value,
        })
      );
    },
    onError: (error) => {
      console.log("catch error ===>>>", error);
    },
  });
};

// TODO - add typing for function params, response

export const getMinimumView = () => {
  apiHandler(() => getAxiosInst().get("/configuration/getMinimumViews"), {
    onSuccess: (res) => {
      store.dispatch(
        setMinimumView({
          reference: "minimum_views",
          reference_value: res.data.reference_value,
        })
      );
    },
    onError: (error) => {
      console.log("catch error ===>>>", error);
    },
  });
};

// TODO - add typing for function params, response

export const getSettingsDataAPI = () => {
  apiHandler(
    () =>
      getAxiosInst().get("/admin/Getdataforadminreport", {
        params: {
          sort: "-1",
        },
      }),
    {
      onSuccess: (res) => {
        store.dispatch(setUserLevel(res.level));
        store.dispatch(
          setMonetizationLimits({
            _id: res.monetization[0]._id,
            minimumviews: res.monetization[0].minimumviews,
            tier1_maxlimit: res.monetization[0].tier1.maxlimit,
            tier1_prize: res.monetization[0].tier1.prize,
            tier2_maxlimit: res.monetization[0].tier2.maxlimit,
            tier2_prize: res.monetization[0].tier2.prize,
            thresholdperiod: res.monetization[0].thresholdperiod,
            distribution: res.monetization[0].distribution,
          })
        );
        store.dispatch(setDefaultLandingPages(res.defaultrRoute));
        store.dispatch(setCustomizePlatforms(res.platform));
      },
      onError: (error) => {
        console.log("catch error ===>>>", error);
      },
    }
  );
};

// TODO - add typing for function params, response

export const getCategoriesReportAPI = () => {
  apiHandler(() => getAxiosInst().get(endpoints.getVideoCategoryReports), {
    onSuccess: (res) => {
      store.dispatch(
        setCategoryReports(
          res.data[0].metadata.length > 3 ? res.data[0].metadata.slice(0, 3) : res.data[0].metadata
        )
      );
    },
    onError: (error) => {
      console.log("catch error ===>>>", error.response);
    },
  });
};

// TODO - add typing for function params, response

export const getCategories = () => {
  apiHandler(() => getAxiosInst().get(endpoints.getAllCategories), {
    onSuccess: (res) => {
      const filteredData = res.data.map((x) => ({
        label: x.category,
        value: x.category,
      }));
      store.dispatch(setCategories(filteredData));
    },
    onError: (error) => {
      console.log("Error:", error.response);
    },
  });
};

// TODO - add typing for function params, response

export const createNewCategory = (apiData, callback = () => {}) => {
  apiHandler(() => getAxiosInst().post(endpoints.createCategory, apiData), {
    onSuccess: (data) => {
      makeToast({
        message: "Category added successfully",
        type: STRINGS.toastType.success,
      });
      callback(data);
    },
    onError: (error) => {
      makeToast({
        message: error?.response?.data?.message || "Unable to add category",
        type: STRINGS.toastType.error,
      });
      callback(error);
    },
  });
};

// TODO - add typing for function params, response

export const deleteCategoryFromServer = (apiData, callback = () => {}) => {
  apiHandler(() => getAxiosInst().delete(`${endpoints.deleteCategory}?id=${apiData.id}`), {
    onSuccess: (data) => {
      makeToast({
        message: "Category deleted successfully",
        type: STRINGS.toastType.success,
      });
      callback(data);
    },
    onError: (error) => {
      makeToast({
        message: error?.response?.data?.message || "Unable to delete category",
        type: STRINGS.toastType.error,
      });
      callback(error);
    },
  });
};

// TODO - add typing for function params, response

export const createNewNft = (apiData, callback = () => {}) => {
  apiHandler(() => getAxiosInst().post(endpoints.createNft, apiData), {
    onSuccess: (data) => {
      makeToast({
        message: "Nft added successfully",
        type: STRINGS.toastType.success,
      });
      callback(data);
    },
    onError: (error) => {
      makeToast({
        message: error?.response?.data?.message || "Unable to add NFT",
        type: STRINGS.toastType.error,
      });
      callback(error);
    },
  });
};

// TODO - add typing for function params, response

export const getAllNfts = (apiData, callback = () => {}) => {
  apiHandler(
    () =>
      getAxiosInst().post(
        `${endpoints.getAllNfts}?limit=${apiData.limit}&skip=${apiData.skip}`,
        apiData.filterParams
      ),
    {
      onSuccess: (res) => {
        store.dispatch(setNfts(res));
        callback(res);
      },
      onError: (error) => {
        callback(error);
      },
    }
  );
};

// TODO - add typing for function params, response

export const deleteNftFromServer = (apiData, callback = () => {}) => {
  apiHandler(() => getAxiosInst().post(`${endpoints.deleteNft}?id=${apiData.id}`), {
    onSuccess: (data) => {
      makeToast({
        message: "NFT deleted successfully",
        type: STRINGS.toastType.success,
      });
      callback(data);
    },
    onError: (error) => {
      makeToast({
        message: error?.response?.data?.message || "Unable to delete NFT",
        type: STRINGS.toastType.error,
      });
      callback(error);
    },
  });
};

// TODO - add typing for function params, response

export const editNftFromServer = (apiData, callback = () => {}) => {
  apiHandler(() => getAxiosInst().post(`${endpoints.editNft}?id=${apiData.id}`, apiData.data), {
    onSuccess: (data) => {
      makeToast({
        message: "Nft updated successfully",
        type: STRINGS.toastType.success,
      });
      callback(data);
    },
    onError: (error) => {
      makeToast({
        message: error?.response?.data?.message || "Unable to update NFT",
        type: STRINGS.toastType.error,
      });
      callback(error);
    },
  });
};

// TODO - add typing for function params, response

export const getAllNftFees = (callback = () => {}) => {
  apiHandler(() => getAxiosInst().get(endpoints.getAllCustomFees), {
    onSuccess: (res) => {
      callback(res);
    },
    onError: (error) => {
      callback(error);
    },
  });
};

// TODO - add typing for function params, response

export const updateCustomFees = (apiData, callback = () => {}) => {
  apiHandler(
    () => getAxiosInst().post(`${endpoints.updateCustomFee}?id=${apiData.id}`, apiData.data),
    {
      onSuccess: (data) => {
        makeToast({
          message: "Fee updated successfully",
          type: STRINGS.toastType.success,
        });
        callback(data);
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message || "Unable to update fee",
          type: STRINGS.toastType.error,
        });
        callback(error);
      },
    }
  );
};

// TODO - add typing for function params, response

export const getExchangeRate = (callback = () => {}) => {
  apiHandler(() => getAxiosInst().get(endpoints.getExchangeRate), {
    onSuccess: (res) => {
      callback(res);
    },
    onError: (error) => {
      callback(error);
    },
  });
};

// TODO - add typing for function params, response

export const getCampaigns = (apiData, callback = () => {}) => {
  apiHandler(
    () =>
      getAxiosInst().post(
        `${endpoints.getAdminCampaigns}?limit=${apiData.limit}&skip=${apiData.skip}`,
        apiData.filterParams
      ),
    {
      onSuccess: (res) => {
        store.dispatch(setCampaigns(res));
        callback(res);
      },
      onError: (error) => {
        callback(error);
      },
    }
  );
};

// TODO - add typing for function params, response

export const campaignStatusUpdate = (apiData, callback = () => {}) => {
  apiHandler(
    () => getAxiosInst().post(`${endpoints.adminCampaignStatus}?id=${apiData.id}`, apiData.data),
    {
      onSuccess: (res) => {
        callback(res);
      },
      onError: (error) => {
        callback(error.response);
      },
    }
  );
};

// TODO - add typing for function params, response

export const singleCampaignDetails = (apiData, callback = () => {}) => {
  apiHandler(() => getAxiosInst().get(`${endpoints.adminSingleCampaign}?id=${apiData.id}`), {
    onSuccess: (res) => {
      store.dispatch(setCampaignDetails(res));
      callback(res);
    },
    onError: (error) => {
      callback(error.response);
    },
  });
};

// TODO - add typing for function params, response

export const getCreatorVideos = (apiData, callback = () => {}) => {
  apiHandler(
    () =>
      getAxiosInst().post(
        `${endpoints.creatorVideos}?limit=${apiData.limit}&skip=${apiData.skip}`,
        apiData.filterParams
      ),
    {
      onSuccess: (res) => {
        store.dispatch(setCreatorVideos(res));
        callback(res);
      },
      onError: (error) => {
        callback(error);
      },
    }
  );
};

// TODO - add typing for function params, response

export const getPaymentList = ({ limit, skip, filterParams }, callback = () => {}) => {
  apiHandler(
    () => getAxiosInst().post(`${endpoints.paymentList}?limit=${limit}&skip=${skip}`, filterParams),
    {
      onSuccess: (res) => {
        callback(res);
      },
      onError: (error) => {
        callback(error);
      },
    }
  );
};

// TODO - add typing for function params, response

export const singleNftDetailsById = ({ id }, callback = () => {}) => {
  apiHandler(() => getAxiosInst().get(`${endpoints.getSingleNftById}?id=${id}`), {
    onSuccess: (res) => {
      callback(res);
    },
    onError: (error) => {
      callback(error.response);
    },
  });
};

// TODO - add typing for function params, response

export const getEarningsList = (apiData, callback = () => {}) => {
  store.dispatch(setLoader({ name: "earningsLoader", value: true }));
  apiHandler(
    () =>
      getAxiosInst().post(
        `${endpoints.earnings}?limit=${apiData.limit}&skip=${apiData.skip}`,
        apiData.filterParams
      ),
    {
      onSuccess: (res) => {
        store.dispatch(setEarnings(res));
        callback(res);
      },
      onError: (error) => {
        callback(error);
      },
      final: () => {
        store.dispatch(setLoader({ name: "earningsLoader", value: false }));
      },
    }
  );
};

// TODO - add typing for function params, response

export const updateCreatorLevel = (apiData, callback = () => {}) => {
  apiHandler(
    () => getAxiosInst().put(`${endpoints.updateCustomizeLevel}?id=${apiData.id}`, apiData.data),
    {
      onSuccess: (res) => {
        getCustomizeLevel();
        callback(res);
      },
      onError: (error) => {
        callback(error);
      },
    }
  );
};

// TODO - add typing for function params, response

export const getMessageReportList = (apiData, callback = () => {}) => {
  apiHandler(
    () =>
      getAxiosInst().get(`${endpoints.messageReport}?limit=${apiData.limit}&skip=${apiData.skip}`),
    {
      onSuccess: (res) => {
        store.dispatch(setMessageReport(res));
        callback(res);
      },
      onError: (error) => {
        callback(error);
      },
    }
  );
};

// TODO - add typing for function params, response

export const messageRespond = ({ apiData }, callback = () => {}) => {
  apiHandler(() => getAxiosInst().post(endpoints.messageRespondReport, apiData), {
    onSuccess: (response) => {
      makeToast({
        message: response?.data || "Updated successfully",
        type: STRINGS.toastType.success,
      });

      callback(response);
    },
    onError: (error) => {
      makeToast({
        message: error?.response?.data?.message || "Unable to update",
        type: STRINGS.toastType.error,
      });
      callback(error?.response);
    },
  });
};

// TODO - add typing for function params, response

export const getTransactionExplore = (apiData, callback = () => {}) => {
  apiHandler(
    () =>
      getAxiosInst().post(
        `${endpoints.gettransactionExplore}?limit=${apiData.limit}&skip=${apiData.skip}`,
        apiData.filterParams
      ),
    {
      onSuccess: (res) => {
        store.dispatch(setTransactionExplore(res));
        callback(res);
      },
      onError: (error) => {
        callback(error);
      },
    }
  );
};

// TODO - add typing for function params, response

export const getFeeInformation = (apiData, callback = () => {}) => {
  apiHandler(
    () =>
      getAxiosInst().post(
        `${endpoints.getfeeinformation}?limit=${apiData.limit}&skip=${apiData.skip}`,
        apiData.filterParams
      ),
    {
      onSuccess: (res) => {
        store.dispatch(setFeeInformation(res));
        callback(res);
      },
      onError: (error) => {
        callback(error);
      },
    }
  );
};

// TODO - add typing for function params, response

export const getMonthlyFeeInformation = (apiData, callback = () => {}) => {
  apiHandler(() => getAxiosInst().post(endpoints.getmonthlyfeeinformation, apiData.filterParams), {
    onSuccess: (res) => {
      store.dispatch(setMonthlyFeeInformation(res));
      callback(res);
    },
    onError: (error) => {
      callback(error);
    },
  });
};

// TODO - add typing for function params, response

export const getBalance = (apiData, callback = () => {}) => {
  apiHandler(
    () =>
      getAxiosInst().post(
        `${endpoints.getBalance}?limit=${apiData.limit}&skip=${apiData.skip}`,
        apiData.filterParams
      ),
    {
      onSuccess: (res) => {
        store.dispatch(setBalance(res));
        callback(res);
      },
      onError: (error) => {
        callback(error);
      },
    }
  );
};

// TODO - add typing for function params, response

export const sweepUsers = (apiData, callback = () => {}) => {
  apiHandler(() => getAxiosInst().post(endpoints.sweepUsers, apiData.filterItems), {
    onSuccess: (res) => {
      if (res?.data?.insuff_fund) {
        const insufficiantFundData = res?.data?.insuff_fund?.toString();
        if (res?.data?.insuff_fund?.length === 1) {
          makeToast({
            message: "Insufficient fund",
            type: STRINGS.toastType.error,
          });
        } else {
          makeToast({
            message: `Transaction Initiated except for ${insufficiantFundData} because of insufficient balance`,
            type: STRINGS.toastType.success,
          });
        }
      } else {
        makeToast({
          message: "Transaction Initiated",
          type: STRINGS.toastType.success,
        });
      }
      callback(res);
    },
    onError: (error) => {
      makeToast({
        message: "Unable to sweep amount. Please try after sometime",
        type: STRINGS.toastType.error,
      });
      callback(error);
    },
  });
};

// TODO - add typing for function params, response

export const getAllTemplates = (apiData, callback = () => {}) => {
  apiHandler(
    () =>
      getAxiosInst().get(
        `${endpoints.getAllTemplates}?limit=${apiData.limit}&skip=${apiData.skip}`
      ),
    {
      onSuccess: (res) => {
        store.dispatch(setTemplates(res));
        callback(res);
      },
      onError: (error) => {
        callback(error);
      },
    }
  );
};

// TODO - add typing for function params, response

export const createEmailTemplate = (apiData, callback = () => {}) => {
  apiHandler(() => getAxiosInst().post(endpoints.getAllTemplates, apiData), {
    onSuccess: (data) => {
      makeToast({
        message: "Template added successfully",
        type: STRINGS.toastType.success,
      });
      callback(data);
    },
    onError: (error) => {
      makeToast({
        message: error?.response?.data?.message || "Unable to add Email template",
        type: STRINGS.toastType.error,
      });
      callback(error);
    },
  });
};

// TODO - add typing for function params, response

export const updateEmailTemplate = (apiData, callback = () => {}) => {
  apiHandler(
    () => getAxiosInst().put(`${endpoints.getAllTemplates}?id=${apiData.id}`, apiData.data),
    {
      onSuccess: (data) => {
        makeToast({
          message: "Template updated successfully",
          type: STRINGS.toastType.success,
        });
        callback(data);
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message || "Unable to update Email template",
          type: STRINGS.toastType.error,
        });
        callback(error);
      },
    }
  );
};

// TODO - add typing for function params, response

export const getEmailTemplateById = (apiData, callback = () => {}) => {
  apiHandler(() => getAxiosInst().get(`${endpoints.getAllTemplates}?id=${apiData.id}`), {
    onSuccess: (data) => {
      callback(data);
    },
    onError: (error) => {
      makeToast({
        message: error?.response?.data?.message || "Unable to fetch Email template",
        type: STRINGS.toastType.error,
      });
      callback(error);
    },
  });
};

// TODO - add typing for function params, response

export const getAllOnBoardingScreens = () => {
  store.dispatch(setLoader({ name: "nftLoader", value: true }));
  apiHandler(() => getAxiosInst().get(`${endpoints.getOnBoardings}`), {
    onSuccess: (res) => {
      store.dispatch(setOnboardings(res.templates));
      makeToast({
        message: res.message,
        type: STRINGS.toastType.success,
      });
    },
    onError: (error) => {
      console.log("catch error ===>>>", error);
    },
    final: () => {
      store.dispatch(setLoader({ name: "nftLoader", value: false }));
    },
  });
};

// TODO - add typing for function params, response

export const createOnBoardingScreen = (apiData, callback) => {
  store.dispatch(setLoader({ name: "nftAddLoader", value: true }));
  const onboardingData = new FormData();
  onboardingData.append("title", apiData.title);
  onboardingData.append("status", apiData.status);
  onboardingData.append("assignedTo", apiData.assignedTo);
  apiData.pages.forEach((item, index) => {
    onboardingData.append(`pages[${index}][title]`, item.title);
    onboardingData.append(`pages[${index}][description]`, item.description);
    onboardingData.append(`pages[${index}][image]`, item.imageUrl);
  });

  apiHandler(() => getAxiosInst().post(endpoints.getAllOnBoardingScreens, onboardingData), {
    onSuccess: () => {
      makeToast({
        message: "Onboarding screen created successfully",
        type: STRINGS.toastType.success,
      });
      callback();
      store.dispatch(unsetOnboarding());
    },
    onError: (error) => {
      makeToast({
        message: error?.response?.data?.message || "Unable to create onboarding screen",
        type: STRINGS.toastType.error,
      });
    },
    final: () => {
      store.dispatch(setLoader({ name: "nftAddLoader", value: false }));
    },
  });
};

// TODO - add typing for function params, response

export const getOnBoardingScreenById = (apiData, callback) => {
  store.dispatch(setLoader({ name: "nftAddLoader", value: true }));
  apiHandler(() => getAxiosInst().get(`${endpoints.getAllOnBoardingScreens}/${apiData.id}`), {
    onSuccess: (data) => {
      store.dispatch(setOnboarding(data.template));
      callback();
    },
    onError: (error) => {
      makeToast({
        message: error?.response?.data?.message || "Unable to fetch onboarding screen",
        type: STRINGS.toastType.error,
      });
    },
    final: () => {
      store.dispatch(setLoader({ name: "nftAddLoader", value: false }));
    },
  });
};

// TODO - add typing for function params, response

export const deleteOnBoardingScreenById = (template) => {
  store.dispatch(setLoader({ name: "nftAddLoader", value: true }));
  apiHandler(() => getAxiosInst().delete(`${endpoints.getAllOnBoardingScreens}/${template._id}`), {
    onSuccess: (data) => {
      store.dispatch(deleteOnboarding(template._id));
      makeToast({
        message: data.message,
        type: STRINGS.toastType.error,
      });
    },
    onError: (error) => {
      makeToast({
        message: error?.response?.data?.message || "Unable to fetch onboarding screen",
        type: STRINGS.toastType.error,
      });
    },
    final: () => {
      store.dispatch(setLoader({ name: "nftAddLoader", value: false }));
    },
  });
};

// TODO - add typing for function params, response

export const getOnBoardingRolesApi = () => {
  apiHandler(
    () => getAxiosInst().get(`${endpoints.getAllOnBoardingScreens}${endpoints.getBoardingRoles}`),
    {
      onSuccess: (data) => {
        store.dispatch(setRoles(data.roles));
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message || "Unable to fetch onboarding screen",
          type: STRINGS.toastType.error,
        });
      },
    }
  );
};

// TODO - add typing for function params, response

export const getOnBoardingScreenByRole = () => {
  store.dispatch(setLoader({ name: "nftAddLoader", value: true }));
  apiHandler(
    () => getAxiosInst().get(`${endpoints.getAllOnBoardingScreens}${endpoints.getBoardingByRole}`),
    {
      onSuccess: (data) => {
        store.dispatch(setOnboarding(data.template));
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message || "Unable to fetch onboarding screen",
          type: STRINGS.toastType.error,
        });
      },
      final: () => {
        store.dispatch(setLoader({ name: "nftAddLoader", value: false }));
      },
    }
  );
};

// TODO - add typing for function params, response

export const updateOnBoardingScreen = (apiData, callback) => {
  store.dispatch(setLoader({ name: "nftAddLoader", value: true }));
  const { data } = apiData;
  const onboardingData = new FormData();
  onboardingData.append("title", data.title);
  onboardingData.append("status", data.status);
  onboardingData.append("assignedTo", data.assignedTo);
  data.pages.forEach((item, index) => {
    onboardingData.append(`pages[${index}][title]`, item.title);
    onboardingData.append(`pages[${index}][description]`, item.description);
    item.imageUrl?.size
      ? onboardingData.append(`pages[${index}][image]`, item.imageUrl)
      : onboardingData.append(`pages[${index}][image]`, item.image);
  });

  apiHandler(
    () => getAxiosInst().put(`${endpoints.getAllOnBoardingScreens}/${apiData.id}`, onboardingData),
    {
      onSuccess: () => {
        makeToast({
          message: "Template updated successfully",
          type: STRINGS.toastType.success,
        });
        callback();
        store.dispatch(unsetOnboarding());
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message || "Unable to update template",
          type: STRINGS.toastType.error,
        });
      },
      final: () => {
        store.dispatch(setLoader({ name: "nftAddLoader", value: false }));
      },
    }
  );
};

// TODO - add typing for function params, response

export const getAdminChallengesApi = (apiData) => {
  const { skip, limit } = apiData;
  store.dispatch(setLoader({ name: "challengeLoader", value: true }));

  apiHandler(
    () =>
      getAxiosInst().get(`${endpoints.getAdminChallenges}`, {
        params: {
          skip,
          limit,
        },
      }),
    {
      onSuccess: (data) => {
        store.dispatch(setAdminChallenges({ data: data.result, total: data.total }));
        store.dispatch(setChallenge(data.result[0]));
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message || "Unable to fetch onboarding screen",
          type: STRINGS.toastType.error,
        });
      },
      final: () => {
        store.dispatch(setLoader({ name: "challengeLoader", value: false }));
      },
    }
  );
};

// TODO - add typing for function params, response

export const getAdminChallengeParticipants = (apiData) => {
  const { challengeId, limit, skip } = apiData;
  store.dispatch(setLoader({ name: "participantsLoader", value: true }));
  apiHandler(
    () =>
      getAxiosInst().get(
        `${endpoints.getChallenge}/${challengeId}${endpoints.getAdminParticipants}`,
        {
          params: {
            limit,
            skip,
          },
        }
      ),
    {
      onSuccess: (res) => {
        store.dispatch(setAdminParticipants({ total: res.total, data: res.result }));
      },
      onError: (error) => {
        console.log("Error:", error.response);
        makeToast({
          message: error?.response?.data?.message || "Unable to load participants",
          type: STRINGS.toastType.error,
        });
      },
      final: () => {
        store.dispatch(setLoader({ name: "participantsLoader", value: false }));
      },
    }
  );
};

// TODO - add typing for function params, response

export const updateChallengeStatus = (apiData, callback = () => {}) => {
  const { id, status, participants, comment } = apiData;
  store.dispatch(setLoader({ name: "participantsLoader", value: true }));
  apiHandler(
    () =>
      getAxiosInst().post(
        `${endpoints.getChallenge}/${id}${endpoints.updateParticipantPayStatus}`,
        {
          status,
          comment,
          participants,
        }
      ),
    {
      onSuccess: () => {
        callback();
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message || "Update failed",
          type: STRINGS.toastType.error,
        });
      },
      final: () => {
        store.dispatch(setLoader({ name: "participantsLoader", value: false }));
      },
    }
  );
};
