import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const ProfilePreviewContainer = styled.div<{
  mobile?: boolean;
  gradient: {
    color1: string;
    color2: string;
    theme: string;
  };
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  aspect-ratio: ${(p) => (p.mobile ? "1 / 1.83" : "1.6 / 1")};
  overflow: hidden;
  border-radius: ${(p) => (p.mobile ? "35px 35px 0px 0px" : "26px 26px 0px 0px")};
  z-index: ${(p) => (p.mobile ? "3" : "1")};
  color: ${(p) => p.theme.colors.text.primary};
  padding: ${(p) => (p.mobile ? "0px" : "24px 16px 0px")};
  max-height: ${(p) => (p.mobile ? "360px" : "404px")};
  position: relative;

  ${(p) =>
    p.gradient?.color1
      ? css`
          background: linear-gradient(90deg, ${p.gradient.color1} 0%, ${p.gradient.color2} 100%);
        `
      : css`
          background: ${p.theme.colors.background.primary};

          .goBack {
            border-bottom: 1px solid ${p.theme.colors.divider};
          }
        `}

  .banner {
    position: relative;
    display: flex;
    justify-content: center;

    .overlay {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
      width: 100%;
      top: 0px;
      position: absolute;
    }

    img {
      object-fit: cover;
    }

    ${(p) =>
      !p.mobile &&
      css`
        max-width: 1248px;
        width: 100%;
        padding: 0px 16px;
        aspect-ratio: 6 / 1;
        justify-content: center;

        img {
          height: 100%;
          width: 100%;
          border-radius: 16px;
        }

        .overlay {
          border-radius: 16px;
          top: 0px;
          height: 100%;
          width: calc(100% - 32px);
        }
      `}

    ${(p) =>
      p.mobile &&
      css`
        height: 100px;

        img,
        .overlay {
          height: 100%;
        }
      `}
  }

  .profile {
    padding: ${(p) => (p.mobile ? "0px 12px" : "0px 16px")};
    ${(p) =>
      p.mobile &&
      css`
        flex-direction: column;
      `};
    display: flex;
    gap: 16px;

    .unitTabs {
      padding-top: ${(p) => (p.mobile ? "0px" : "16px")};
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: ${(p) => (p.mobile ? "100%" : "65%")};

      .tabs .tab .button {
        padding: ${(p) => (p.mobile ? "8px 8px 8px 12px" : "10px 10px 10px 16px")};

        .text {
          font-size: ${(p) =>
            p.mobile ? p.theme.typography.subText2 : p.theme.typography.subText5};
        }
      }

      .links {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }

  ${deviceQuery.laptopM} {
    padding: ${(p) => (p.mobile ? "0px" : "16px 12px 0px")};
    border-radius: ${(p) => (p.mobile ? "24px 24px 0px 0px" : "26px 26px 0px 0px")};
    aspect-ratio: ${(p) => (p.mobile ? "1 / 1.84" : "1.6 / 1")};

    .profile {
      .unitTabs {
        padding-top: ${(p) => (p.mobile ? "0px" : "8px")};
        gap: 8px;

        .tabs {
          .tab {
            button {
              padding: 8px 8px 8px 12px;
            }
          }
        }
      }
    }
  }

  ${deviceQuery.tabletM} {
    border-radius: ${(p) => (p.mobile ? "35px 35px 0px 0px" : "26px 26px 0px 0px")};
    padding: ${(p) => (p.mobile ? "0px 5px" : "35px 5px 0px")};
    margin: ${(p) => (p.mobile ? "0px" : "5px 23px")};
    max-height: unset;

    .profile {
      .unitTabs {
        width: 100%;
      }
    }
  }

  ${deviceQuery.mobileS} {
    border-radius: ${(p) => (p.mobile ? "30px 30px 0px 0px" : "26px")};
  }
`;
