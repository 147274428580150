import { FC, MouseEvent, MouseEventHandler, ReactNode } from "react";

import { ButtonContainer } from "./style";
import { ButtonSizes, ButtonType, ButtonVariants } from "./types";

interface Props {
  type?: ButtonType;
  icon?: ReactNode;
  full?: boolean;
  disabled?: boolean;
  round?: boolean;
  fontSize?: string;
  size?: ButtonSizes;
  variant: ButtonVariants;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

// Main Button component for project
const Button: FC<Props> = ({
  type = "button",
  variant,
  fontSize,
  size = "md",
  round = false,
  icon,
  full = false,
  children,
  onClick,
  disabled,
  className,
}) => {
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClick && onClick(event);
  };

  return (
    <ButtonContainer
      className={className}
      disabled={disabled}
      type={type}
      round={round}
      onClick={handleClick}
      size={size}
      variant={variant}
      fontSize={fontSize}
      full={full}
    >
      {children}
      {icon}
    </ButtonContainer>
  );
};

export default Button;
