import { FC } from "react";

interface Props {
  size?: number;
}

const CatThin: FC<Props> = ({ size = 32 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.0897 12.4447C4.1597 12.4447 4.2197 12.4247 4.2797 12.3747C4.2997 12.3647 5.7797 11.2047 7.2997 10.6647C7.9597 10.4347 8.5697 10.2747 9.0497 10.1547C9.9497 9.92468 10.5497 9.77468 10.7697 9.30468C10.9297 8.94468 10.7697 8.56468 10.6497 8.30468C9.3097 5.62468 7.0797 2.59468 4.8697 2.11468C4.7097 2.08468 4.5397 2.18468 4.4997 2.34468C4.4597 2.50468 4.5697 2.67468 4.7297 2.71468C6.7297 3.15468 8.8697 6.14468 10.0897 8.57468C10.1997 8.79468 10.2397 8.96468 10.1997 9.04468C10.1097 9.24468 9.5197 9.39468 8.8897 9.54468C8.3897 9.67468 7.7697 9.82468 7.0897 10.0747C5.4797 10.6447 3.9597 11.8347 3.8997 11.8847C3.7697 11.9847 3.7397 12.1847 3.8497 12.3147C3.9097 12.3947 3.9997 12.4347 4.0897 12.4347V12.4447Z"
      fill="currentColor"
    />
    <path
      d="M22.8696 10.146C23.4096 10.276 24.0196 10.426 24.7096 10.666C26.2196 11.206 27.7096 12.366 27.7296 12.376C27.7896 12.416 27.8496 12.446 27.9196 12.446C28.0096 12.446 28.0996 12.406 28.1596 12.326C28.2696 12.196 28.2396 11.996 28.1096 11.896C28.0496 11.846 26.5296 10.656 24.9196 10.086C24.2096 9.83597 23.5496 9.67597 23.0196 9.54597C22.3596 9.38597 21.7396 9.23598 21.6496 9.02598C21.5996 8.91598 21.6796 8.72597 21.7496 8.58597C23.0496 5.98597 25.3696 3.13597 27.2796 2.72597C27.4496 2.68597 27.5496 2.52597 27.5096 2.35597C27.4696 2.18597 27.3096 2.08597 27.1396 2.12597C24.9396 2.60597 22.5196 5.66598 21.1896 8.31598C20.9896 8.70598 20.9596 9.02597 21.0796 9.29597C21.2996 9.77597 21.9196 9.92597 22.8696 10.156V10.146Z"
      fill="currentColor"
    />
    <path
      d="M12.6996 18.6998C12.7896 18.6998 12.8795 18.6598 12.9395 18.5798C13.0395 18.4498 13.0296 18.2698 12.8995 18.1598C11.2895 16.7398 8.84955 16.2198 6.52955 16.8298C6.21955 16.9098 6.03955 17.2098 6.11955 17.5198C6.18955 17.8298 6.47955 18.0198 6.78955 17.9498C6.94955 17.9198 7.12955 17.8698 7.32955 17.8198C8.57955 17.4998 10.2896 17.0598 12.5196 18.6398C12.5696 18.6798 12.6396 18.6998 12.6996 18.6998Z"
      fill="currentColor"
    />
    <path
      d="M8.30042 23.1935C8.30042 23.1935 5.71042 24.0035 3.17042 23.1935C3.01042 23.1435 2.84042 23.2335 2.78042 23.3935C2.73042 23.5535 2.82042 23.7335 2.98042 23.7835C3.91042 24.0835 4.83042 24.1735 5.64042 24.1735C7.22042 24.1735 8.41042 23.8035 8.48042 23.7835C8.64042 23.7335 8.73042 23.5635 8.68042 23.3935C8.63042 23.2335 8.45042 23.1435 8.29042 23.1935H8.30042Z"
      fill="currentColor"
    />
    <path
      d="M4.08047 21.054C4.00047 21.204 4.06047 21.394 4.21047 21.474C5.21047 22.014 6.29047 22.144 7.11047 22.144C7.82047 22.144 8.32047 22.044 8.36047 22.034C8.53047 22.004 8.63047 21.834 8.60047 21.674C8.57047 21.504 8.40047 21.404 8.24047 21.434C8.22047 21.434 6.18047 21.834 4.50047 20.934C4.35047 20.854 4.16047 20.914 4.08047 21.064V21.054Z"
      fill="currentColor"
    />
    <path
      d="M25.2101 17.9611C25.5201 18.0311 25.8101 17.8411 25.8801 17.5311C25.9501 17.2211 25.7801 16.9211 25.4701 16.8411C23.1501 16.2411 20.7101 16.7511 19.1001 18.1711C18.9801 18.2811 18.9601 18.4611 19.0601 18.5911C19.1201 18.6711 19.2101 18.7111 19.3001 18.7111C19.3601 18.7111 19.4201 18.6911 19.4801 18.6511C21.7101 17.0711 23.4201 17.5111 24.6701 17.8311C24.8601 17.8811 25.0401 17.9311 25.2101 17.9611Z"
      fill="currentColor"
    />
    <path
      d="M28.8297 23.1935C26.2997 24.0035 23.7197 23.1935 23.6997 23.1935C23.5397 23.1435 23.3697 23.2335 23.3097 23.3935C23.2597 23.5535 23.3497 23.7235 23.5097 23.7835C23.5797 23.8035 24.7697 24.1735 26.3497 24.1735C27.1697 24.1735 28.0897 24.0735 29.0097 23.7835C29.1697 23.7335 29.2597 23.5535 29.2097 23.3935C29.1597 23.2335 28.9797 23.1435 28.8197 23.1935H28.8297Z"
      fill="currentColor"
    />
    <path
      d="M27.4998 20.924C25.8298 21.824 23.7798 21.424 23.7598 21.424C23.5998 21.394 23.4298 21.494 23.3998 21.664C23.3698 21.834 23.4698 21.994 23.6398 22.024C23.6798 22.024 24.1798 22.134 24.8898 22.134C25.6998 22.134 26.7898 22.004 27.7898 21.464C27.9398 21.384 27.9998 21.194 27.9198 21.044C27.8398 20.894 27.6498 20.844 27.4998 20.914V20.924Z"
      fill="currentColor"
    />
    <path
      d="M16.0004 21.8625C16.4004 21.8625 17.1004 21.1625 17.1004 20.7625C17.1004 20.3625 16.3104 20.3125 16.0004 20.3125C15.7004 20.3125 14.9004 20.3625 14.9004 20.7625C14.9004 21.1625 15.6004 21.8625 16.0004 21.8625Z"
      fill="currentColor"
    />
    <path
      d="M19.3296 23.6509C18.9296 24.1009 18.5396 24.3209 18.1496 24.3209H18.1396C17.5896 24.3209 16.9496 23.8909 16.2396 23.0609C16.2396 23.0609 16.2296 23.0609 16.2196 23.0509C16.2196 23.0509 16.2196 23.0409 16.2096 23.0309C16.2096 23.0309 16.1896 23.0209 16.1796 23.0109C16.1596 23.0009 16.1496 22.9909 16.1296 22.9809C16.1096 22.9809 16.0896 22.9709 16.0696 22.9609C16.0496 22.9609 16.0296 22.9609 16.0096 22.9609C15.9896 22.9609 15.9696 22.9609 15.9496 22.9609C15.9296 22.9609 15.9096 22.9609 15.8896 22.9809C15.8696 22.9809 15.8496 23.0009 15.8396 23.0109C15.8296 23.0109 15.8196 23.0109 15.8096 23.0309C15.8096 23.0309 15.8096 23.0409 15.7896 23.0509C15.7896 23.0509 15.7796 23.0509 15.7696 23.0609C15.0596 23.8909 14.4196 24.3209 13.8696 24.3209H13.8596C13.4696 24.3209 13.0796 24.1009 12.6796 23.6509C12.5696 23.5209 12.3696 23.5109 12.2496 23.6309C12.1196 23.7409 12.1096 23.9409 12.2296 24.0609C12.7596 24.6509 13.2896 24.9309 13.8696 24.9309H13.8896C14.5596 24.9309 15.2596 24.5209 16.0096 23.7109C16.7596 24.5209 17.4596 24.9209 18.1296 24.9309H18.1496C18.7296 24.9309 19.2596 24.6409 19.7896 24.0609C19.8996 23.9309 19.8896 23.7409 19.7696 23.6309C19.6496 23.5209 19.4496 23.5309 19.3396 23.6509H19.3296Z"
      fill="currentColor"
    />
    <path
      d="M31.15 17.7335C30.84 16.3535 30.51 14.9235 30.54 12.8535C30.54 12.4135 30.56 11.9535 30.57 11.4735C30.67 6.78353 30.79 0.943527 27.92 0.0835266C25 -0.776473 19.78 6.32353 18.79 7.71353C16.87 7.48353 15 7.47353 13.23 7.71353C12.23 6.31353 7.01003 -0.796473 4.09003 0.0735266C1.22003 0.933527 1.34003 6.77353 1.43003 11.4635C1.44003 11.9435 1.45003 12.4035 1.46003 12.8435C1.49003 14.9135 1.16003 16.3435 0.850031 17.7235C0.480031 19.3435 0.140031 20.8635 0.400031 23.1635C1.06003 28.8535 6.60003 31.9935 16 32.0035C25.39 32.0035 30.93 28.8635 31.6 23.1635C31.87 20.8635 31.52 19.3435 31.15 17.7235V17.7335ZM30.98 23.1035C30.36 28.4435 25.03 31.3935 15.99 31.3935C6.95003 31.3935 1.63003 28.4435 1.00003 23.1035C0.740031 20.9135 1.08003 19.4335 1.43003 17.8735C1.75003 16.4535 2.09003 14.9835 2.06003 12.8435C2.06003 12.4035 2.04003 11.9335 2.03003 11.4535C1.94003 7.20353 1.82003 1.39353 4.24003 0.673527C4.36003 0.633527 4.48003 0.623527 4.61003 0.623527C6.95003 0.623527 11.26 6.02353 12.79 8.22353C12.86 8.32353 12.97 8.36353 13.09 8.35353C14.92 8.09353 16.86 8.09353 18.86 8.35353C18.97 8.36353 19.09 8.31353 19.15 8.22353C20.8 5.89353 25.51 0.00352657 27.74 0.663527C30.16 1.39353 30.04 7.20353 29.95 11.4435C29.95 11.9235 29.93 12.3935 29.92 12.8335C29.89 14.9735 30.23 16.4435 30.55 17.8635C30.91 19.4235 31.24 20.9035 30.98 23.0935V23.1035Z"
      fill="currentColor"
    />
  </svg>
);

export default CatThin;
