import { FC, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ThemeProvider } from "styled-components";

import { BioBlockContainer, Color } from "./style";

import Pattern from "../ProfilePreview/components/Pattern";

import Delete from "assets/svg/Delete";
import Edit from "assets/svg/Edit";
import Eye from "assets/svg/Eye";
import Avatar from "Components/Avatar";
import Button from "Components/Button";
import { ErrorMsg } from "Components/Input/style";
import { getUser } from "store/slices/global/userSlice";
import { darkTheme, lightTheme } from "styles/newThemes";

type BlockType = "banner" | "wallpaper";

interface Props {
  title: string;
  description: string;
  buttonText: string;
  buttonsDisabled?: boolean;
  errorMessage?: string;
  type: BlockType;
  onClick: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onPreview: () => void;
}

const BioBlock: FC<Props> = ({
  title,
  description,
  buttonText,
  type,
  onClick,
  onDelete,
  onEdit,
  onPreview,
  buttonsDisabled = false,
  errorMessage,
}) => {
  const user = useSelector(getUser);
  const { t } = useTranslation();
  const bannerMockup = useMemo(() => {
    if (!user) return "/MockupBannerLight.svg";
    switch (user.bioTheme) {
      case "light":
        return "/MockupBannerLight.svg";

      case "dark":
        return "/MockupBannerDark.svg";

      default:
        return "/MockupBannerLight.svg";
    }
  }, [user]);

  const wallpaperMockup = useMemo(() => {
    if (!user) return "/MockupLight.svg";
    switch (user.bioTheme) {
      case "light":
        return "/MockupLight.svg";

      case "dark":
        return "/MockupDark.svg";

      default:
        return "/MockupLight.svg";
    }
  }, [user]);

  if (!user) return null;
  return (
    <BioBlockContainer>
      <div className="previewContainer">
        <div className="preview">
          {type === "banner" && user.banner && (
            <>
              <img
                className="banner"
                src={user.banner.imageUrl_1200_0}
                alt="banner"
                loading="lazy"
              />
              <Avatar className="owl" border size="md" src="" alt="owl" />
            </>
          )}
          {type === "banner" && (
            <img src={bannerMockup} className="mockupBanner" alt="mockupBanner" loading="lazy" />
          )}
          {type === "wallpaper" && (
            <div className="wallpaperMockup">
              <Color gradient={user.wallpaper?.background}>
                {user.wallpaper?.pattern && <Pattern pattern={user.wallpaper.pattern} mobile />}
              </Color>
              <img src={wallpaperMockup} alt="mockupWallpaper" loading="lazy" />
            </div>
          )}
        </div>
      </div>
      <div className="bio">
        <div className="info">
          <p className="title">{title}</p>
          <p className="description">{description}</p>
        </div>
        {type === "banner" && user.banner && (
          <div className="mobile">
            <img className="banner" src={user.banner.imageUrl_1200_0} alt="banner" loading="lazy" />
          </div>
        )}
        {type === "wallpaper" && user.wallpaper && (
          <div className="mobile">
            <div className="wallpaper">
              <ThemeProvider theme={user.bioTheme === "light" ? lightTheme : darkTheme}>
                <Color gradient={user.wallpaper?.background}>
                  {user.wallpaper?.pattern && <Pattern pattern={user.wallpaper.pattern} mobile />}
                </Color>
              </ThemeProvider>
            </div>
          </div>
        )}
        <div className="blockActions">
          {(user.banner && type === "banner") || (user.wallpaper && type === "wallpaper") ? (
            <>
              <div className="buttons">
                <Button size="sm" variant="primary" onClick={onEdit} disabled={buttonsDisabled}>
                  {t("common.edit")}
                  <div className="icon">
                    <Edit />
                  </div>
                </Button>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={onPreview}
                  disabled={buttonsDisabled}
                >
                  {t("common.preview")}
                  <div className="icon">
                    <Eye size={20} />
                  </div>
                </Button>
              </div>
              <Button size="sm" variant="third" onClick={onDelete} disabled={buttonsDisabled}>
                {t("common.delete")}
                <div className="icon">
                  <Delete />
                </div>
              </Button>
            </>
          ) : (
            <Button size="sm" variant="primary" onClick={onClick} disabled={buttonsDisabled}>
              {buttonText}
            </Button>
          )}
        </div>
        {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
      </div>
    </BioBlockContainer>
  );
};

export default BioBlock;
