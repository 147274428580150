import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const PhoneAnimationContainer = styled.div`
  .phone_animation_container {
    width: 100%;
    height: 880px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 750px) {
    .phone_animation_container {
      justify-content: flex-end;
    }
    .phone_animation_tabs {
      display: none;
    }
  }

  ${deviceQuery.laptopM} {
    .phone_animation_container {
      height: 770px;
    }
  }

  ${deviceQuery.tabletM} {
    .phone_animation_container {
      height: 660px;
    }
  }

  ${deviceQuery.mobileM} {
    .phone_animation_container {
      height: 640px;
    }
  }
`;
