import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import SettingMenu from "./components/SettingMenu";
import { ChatActionsContainer } from "./style";

import Close from "assets/svg/Close";
import Search from "assets/svg/Search";
import Input from "Components/Input";
import RollingTabs from "Components/RollingTabs";
import { CHAT_SETTINGS, ROLES } from "constants/appConstants";
import { useFirstRender } from "customHooks/useFirstRender";
import { getChatStatusesCounter } from "services/newMessagesService";
import { updateProfileInfoAPI } from "services/UserServices";
import {
  CHATS_LIMIT,
  getChatsCount,
  getChatsSearch,
  getCurrentTab,
  removeAllChats,
  setChatsSearch,
  setChatsTotalCount,
  setCurrentStatusTab,
} from "store/slices/global/chatsSlice";
import { getUser } from "store/slices/global/userSlice";
import {
  getChatsByStatusAndRole,
  getChatsCountByStatus,
  getChatsWithSearchThunk,
} from "store/thunks/messages-thunks";
import { ChatStatus } from "types/new-messages.types";
import { TabItem } from "types/tabs.types";
import { AllowedMessagesFrom } from "types/user";

const ChatStatusTabs = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isFirstRender = useFirstRender();
  const chatsCountByType = useSelector(getChatsCount);
  const currentTab = useSelector(getCurrentTab);
  const user = useSelector(getUser);
  const search = useSelector(getChatsSearch);
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [showSearch, setShowSearch] = useState(false);
  const [setting, setSetting] = useState(CHAT_SETTINGS[0].value);

  const isCreator = useMemo<boolean>(() => {
    return !!user && user.role === "creator";
  }, [user]);

  const options = useMemo<TabItem<ChatStatus>[]>(() => {
    if (showSearch) {
      return [
        {
          id: 0,
          name: t("new_chat.chat_statuses.active"),
          count: chatsCountByType.active,
          value: "active",
        },
      ];
    }
    if (!isCreator) {
      return [
        {
          id: 0,
          name: t("new_chat.chat_statuses.active"),
          count: chatsCountByType.active,
          value: "active",
        },
        {
          id: 2,
          name: t("new_chat.chat_statuses.blocked"),
          count: chatsCountByType.blocked,
          value: "blocked",
        },
      ];
    }
    return [
      {
        id: 0,
        name: t("new_chat.chat_statuses.active"),
        count: chatsCountByType.active,
        value: "active",
      },
      {
        id: 1,
        name: t("new_chat.chat_statuses.pending_approval"),
        count: chatsCountByType.pending_approval,
        value: "pending_approval",
      },
      {
        id: 2,
        name: t("new_chat.chat_statuses.blocked"),
        count: chatsCountByType.blocked,
        value: "blocked",
      },
    ];
  }, [chatsCountByType, showSearch, i18n.language]);

  const [title, setTitle] = useState<string>(options[0].name);

  const onTabSelected = (tabIndex: number, item: TabItem<ChatStatus>) => {
    const newTabValue = item.value!;
    if (newTabValue === currentTab.status) return;

    dispatch(setCurrentStatusTab({ tabIndex, status: newTabValue }));
    setTitle(item.name);
  };

  const changeActiveTab = (chatStatus: ChatStatus) => {
    if (!user) return;
    dispatch(removeAllChats());
    dispatch(
      getChatsByStatusAndRole({
        status: chatStatus,
        role: user.role === "fan" ? "creator" : "fan",
        paginationData: { limit: CHATS_LIMIT },
      })
    );
  };

  const debouncedRequestAfterChange = useCallback(
    debounce((text) => {
      dispatch(setChatsSearch(text));
    }, 1000),
    []
  );

  const updateChatSettings = (allowMessagesFrom: AllowedMessagesFrom) => {
    updateProfileInfoAPI({
      data: {
        allowFanMessages: allowMessagesFrom,
        followerThreshold: user?.messageSettings.followerThreshold,
      },
      part: "message_settings",
    });
  };

  useEffect(() => {
    setTitle(options.find((item) => item.value === currentTab.status)?.name || options[0].name);
  }, [i18n.language]);

  useEffect(() => {
    if (!user) return;
    setSetting(user.messageSettings.allowFanMessages);
  }, [user]);

  useEffect(() => {
    if (user) {
      dispatch(getChatsCountByStatus());
    }
  }, [user?.role]);

  useEffect(() => {
    if (isFirstRender) return;
    changeActiveTab(currentTab.status);
  }, [currentTab]);

  useEffect(() => {
    if (!user) return;

    if (!search) {
      dispatch(removeAllChats());
      dispatch(
        getChatsByStatusAndRole({
          status: currentTab.status,
          role: user.role === "fan" ? "creator" : "fan",
          paginationData: { limit: CHATS_LIMIT },
        })
      );
      if (user.role === ROLES.creator)
        getChatStatusesCounter((data) => dispatch(setChatsTotalCount(data)));
      return;
    }

    if (isFirstRender) return;

    dispatch(removeAllChats());
    dispatch(
      getChatsWithSearchThunk({
        status: "active",
        limit: CHATS_LIMIT,
        role: user.role === "fan" ? "creator" : "fan",
        search,
      })
    );
  }, [search, user?.role]);

  return (
    <ChatActionsContainer>
      {!showSearch ? (
        <div className="title">
          <p>{title}</p>
          <div className="icons">
            <div
              className="search"
              onClick={() => {
                setShowSearch(true);
                dispatch(setCurrentStatusTab({ tabIndex: 0, status: "active" }));
              }}
            >
              <Search />
            </div>
            {isCreator && (
              <SettingMenu
                value={setting}
                handleChange={(value) => {
                  setSetting(value);
                  updateChatSettings(value);
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <Input
          value={searchInputValue}
          autoFocus
          maxLength={541}
          handleChange={(event) => {
            const searchValue = event.target.value || "";
            setSearchInputValue(searchValue);
            if (searchValue.length < 2) return;
            debouncedRequestAfterChange(searchValue.trim());
          }}
          right={
            <div
              className={`close ${search ? "active" : ""}`}
              onClick={() => {
                setSearchInputValue("");
                dispatch(setChatsSearch(""));
                setShowSearch(false);
              }}
            >
              <Close size={24} />
            </div>
          }
        />
      )}
      <RollingTabs
        className={showSearch ? "oneTab" : ""}
        full
        options={options}
        selectedAction={currentTab.tabIndex}
        onTabClicked={onTabSelected}
      />
    </ChatActionsContainer>
  );
};

export default ChatStatusTabs;
