import { FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { Content, RemoveModal } from "./style";

import Button from "Components/Button";

interface Props {
  show: boolean;
  closeModal: () => void;
  onConfirmDelete: () => void;
}

const RemoveAvatarModal: FC<Props> = memo(({ show, closeModal, onConfirmDelete }) => {
  const { t } = useTranslation();

  return (
    <RemoveModal show={show} size="sm" onHide={() => closeModal()} showCloseButton>
      <p className="title">{t("common.remove_photo")}</p>

      <Content>
        <p className="description">{t("common.remove_photo_confirmation")}</p>
        <div className="actions">
          <Button variant="third" full onClick={() => onConfirmDelete()}>
            {t("common.remove_it")}
          </Button>
          <Button variant="secondary" full onClick={() => closeModal()}>
            {t("common.cancel")}
          </Button>
        </div>
      </Content>
    </RemoveModal>
  );
});

export default RemoveAvatarModal;
