import { FC } from "react";
import { createPortal } from "react-dom";

import { OverlayContainer } from "./style";

interface Props {
  show: boolean;
  onClick?: () => void;
}

const FilterOverlay: FC<Props> = ({ show, onClick }) =>
  createPortal(
    <OverlayContainer onClick={onClick} show={show} />,
    document.getElementById("root") as HTMLElement
  );

export default FilterOverlay;
