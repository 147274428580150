import { FC, Suspense, memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { ThemeProvider } from "styled-components";

import { Content, PageContainer } from "../HomeLayout/style";

import AfterLoadImage from "Components/AfterLoadImage";
import NewHeader from "Components/NewHeader";
import Pattern from "pages/creator/Bio/BioCustomization/components/ProfilePreview/components/Pattern";
import { getPublicUser } from "store/slices/global/previewProfileSlice";
import { getUser } from "store/slices/global/userSlice";
import { darkTheme, lightTheme } from "styles/newThemes";

interface Props {
  withoutBanner?: boolean;
}

const ProfileLayout: FC<Props> = memo(({ withoutBanner = false }) => {
  const user = useSelector(getPublicUser);
  const userDetails = useSelector(getUser);
  const theme = useMemo(() => {
    switch (user.bioTheme) {
      case "light":
        return lightTheme;

      case "dark":
        return darkTheme;

      default:
        return lightTheme;
    }
  }, [user, userDetails]);

  const getProfileBanner = () => {
    if (!user._id) return ""; // user state is empty there is no user profile loaded yet
    if (user.banner && user.banner.imageUrl_1200_0) return user.banner.imageUrl_1200_0;
    return "/banner.png";
  };

  return (
    <ThemeProvider theme={theme}>
      <PageContainer gradient={user.wallpaper?.background}>
        <NewHeader blackLogo={withoutBanner} />
        {!withoutBanner && (
          <div className="banner">
            <div className="overlay" />
            <AfterLoadImage src={getProfileBanner()} alt="banner" loading="lazy" />
          </div>
        )}
        {user.wallpaper?.pattern && <Pattern pattern={user.wallpaper.pattern} profile />}
        <Content userId={!!user._id}>
          <Suspense fallback={<></>}>
            <div className="content">
              <Outlet />
            </div>
          </Suspense>
        </Content>
      </PageContainer>
    </ThemeProvider>
  );
});

export default ProfileLayout;
