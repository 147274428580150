const FacebookColor = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.28 22.86C9.87001 22.81 9.48001 22.72 9.08001 22.61C5.14001 21.52 2.12001 18.36 1.25001 14.37C-0.0899906 8.22997 3.88001 2.47997 9.69001 1.23997C15.35 0.0399656 20.98 3.41997 22.56 8.95997C23.66 12.82 22.59 16.95 19.75 19.78C18.08 21.44 16.08 22.46 13.77 22.85C13.75 22.85 13.73 22.85 13.71 22.85C13.67 22.84 13.66 22.8 13.66 22.76C13.66 22.71 13.66 22.67 13.66 22.62C13.66 20.21 13.66 17.8 13.66 15.39C13.66 15.21 13.75 15.12 13.93 15.12C14.61 15.12 15.3 15.12 15.98 15.12C16.02 15.12 16.05 15.12 16.09 15.12C16.2 15.12 16.24 15.09 16.26 14.98C16.31 14.71 16.34 14.44 16.39 14.17C16.49 13.54 16.58 12.9 16.68 12.27C16.72 12.04 16.71 12.03 16.47 12.03C15.62 12.03 14.76 12.03 13.91 12.03C13.66 12.03 13.66 12.03 13.66 11.79C13.66 11.18 13.66 10.57 13.66 9.96997C13.66 9.62997 13.71 9.28997 13.88 8.97997C14.09 8.58997 14.42 8.35997 14.85 8.24997C15.04 8.19997 15.23 8.16997 15.42 8.16997C15.83 8.16997 16.25 8.16997 16.66 8.16997C16.82 8.16997 16.84 8.13997 16.84 7.98997C16.84 7.23997 16.84 6.47997 16.84 5.72997C16.84 5.56997 16.82 5.54997 16.66 5.51997C16.2 5.44997 15.74 5.39997 15.27 5.36997C14.94 5.34997 14.6 5.32997 14.27 5.33997C13.59 5.35997 12.94 5.48997 12.33 5.78997C11.46 6.22997 10.89 6.92997 10.58 7.83997C10.39 8.38997 10.32 8.95997 10.32 9.53997C10.32 10.29 10.32 11.04 10.32 11.79C10.32 11.9566 10.2367 12.04 10.07 12.04C9.3 12.04 8.53 12.04 7.76 12.04C7.72 12.04 7.69 12.04 7.65 12.04C7.56 12.04 7.53001 12.08 7.53001 12.17C7.53001 12.2 7.53001 12.23 7.53001 12.26C7.53001 13.14 7.53001 14.03 7.53001 14.91C7.53001 14.93 7.53001 14.94 7.53001 14.96C7.53001 15.11 7.56 15.14 7.71 15.14C8.5 15.14 9.28 15.14 10.07 15.14C10.13 15.14 10.18 15.14 10.24 15.14C10.3 15.14 10.33 15.18 10.33 15.23C10.33 15.27 10.33 15.31 10.33 15.35C10.33 17.79 10.33 20.23 10.33 22.67C10.33 22.73 10.35 22.81 10.28 22.86Z"
      fill="#1876F0"
    />
    <path
      d="M10.28 22.86C10.28 20.33 10.28 17.8 10.28 15.27C10.28 15.19 10.26 15.17 10.18 15.17C9.31002 15.17 8.45003 15.17 7.58003 15.17C7.49003 15.17 7.47003 15.14 7.47003 15.06C7.47003 14.07 7.47003 13.08 7.47003 12.09C7.47003 12 7.50003 11.99 7.58003 11.99C8.44003 11.99 9.30003 11.99 10.16 11.99C10.26 11.99 10.27 11.96 10.27 11.87C10.27 11.02 10.26 10.17 10.27 9.31995C10.28 8.55995 10.45 7.81995 10.83 7.14995C11.36 6.20995 12.18 5.65995 13.22 5.41995C13.81 5.27995 14.4 5.26995 15 5.29995C15.6 5.32995 16.2 5.39995 16.8 5.47995C16.87 5.47995 16.88 5.50995 16.88 5.57995C16.88 6.42995 16.88 7.26995 16.88 8.11995C16.88 8.18995 16.86 8.20995 16.79 8.20995C16.33 8.20995 15.87 8.20995 15.41 8.20995C15.07 8.20995 14.74 8.27995 14.45 8.44995C14.03 8.68995 13.82 9.07995 13.74 9.53995C13.72 9.65995 13.71 9.77995 13.71 9.90995C13.71 10.57 13.71 11.23 13.71 11.89C13.71 11.97 13.73 11.99 13.81 11.99C14.75 11.99 15.69 11.99 16.64 11.99C16.76 11.99 16.76 11.99 16.75 12.11C16.6 13.1 16.45 14.09 16.3 15.08C16.29 15.15 16.26 15.17 16.2 15.17C15.41 15.17 14.61 15.17 13.82 15.17C13.75 15.17 13.73 15.18 13.73 15.26C13.73 17.79 13.73 20.32 13.73 22.85C13.43 22.91 13.13 22.94 12.83 22.96C12.01 23.02 11.19 22.99 10.37 22.87C10.34 22.87 10.32 22.86 10.29 22.85L10.28 22.86Z"
      fill="#FEFEFE"
    />
  </svg>
);

export default FacebookColor;
