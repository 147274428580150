import { FC } from "react";

interface Props {
  size?: number;
  color?: string;
}

const Chat: FC<Props> = ({ size = 24, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.69614 12.6506C7.56291 12.6517 7.43237 12.6131 7.3212 12.5396C7.21002 12.4662 7.12327 12.3612 7.07203 12.2382C7.02079 12.1153 7.00739 11.9798 7.03353 11.8491C7.05968 11.7185 7.12418 11.5986 7.2188 11.5048C7.31343 11.411 7.43387 11.3475 7.56474 11.3225C7.69561 11.2975 7.83098 11.3121 7.95352 11.3644C8.07607 11.4167 8.18024 11.5043 8.25272 11.6161C8.32519 11.7279 8.36269 11.8588 8.36042 11.992C8.35744 12.1667 8.28632 12.3334 8.16223 12.4564C8.03813 12.5794 7.87088 12.6491 7.69614 12.6506ZM12.2404 12.6505C12.1074 12.6505 11.9774 12.6109 11.867 12.5368C11.7566 12.4626 11.6707 12.3573 11.6204 12.2342C11.57 12.1111 11.5575 11.9759 11.5843 11.8456C11.6111 11.7153 11.676 11.5959 11.7709 11.5027C11.8657 11.4095 11.9862 11.3466 12.1169 11.3221C12.2476 11.2975 12.3826 11.3124 12.5048 11.3649C12.6271 11.4174 12.7309 11.505 12.8031 11.6167C12.8753 11.7284 12.9127 11.859 12.9104 11.992C12.9074 12.1677 12.8355 12.3352 12.7102 12.4584C12.5848 12.5816 12.4161 12.6506 12.2404 12.6505ZM16.2233 12.3728C16.1496 12.2626 16.1103 12.1331 16.1103 12.0005C16.1103 11.8228 16.1809 11.6524 16.3066 11.5268C16.4322 11.4011 16.6026 11.3305 16.7803 11.3305C16.9129 11.3305 17.0424 11.3698 17.1526 11.4435C17.2628 11.5171 17.3486 11.6217 17.3994 11.7441C17.4501 11.8666 17.4633 12.0013 17.4375 12.1313C17.4116 12.2612 17.3478 12.3806 17.2541 12.4743C17.1604 12.568 17.041 12.6318 16.9111 12.6577C16.7811 12.6835 16.6464 12.6703 16.5239 12.6195C16.4015 12.5688 16.2969 12.483 16.2233 12.3728Z"
      fill={color || "currentColor"}
      stroke={color || "currentColor"}
    />
    <path
      d="M12 1.7598C9.34784 1.7598 6.80429 2.81337 4.92892 4.68873C3.05356 6.56409 2 9.10763 2 11.7598C2.02882 13.5126 2.49273 15.2307 3.35001 16.7598C3.42787 16.8952 3.47454 17.0462 3.48665 17.2019C3.49876 17.3576 3.47599 17.514 3.42 17.6598L2.75 19.8998C2.71017 20.0126 2.70384 20.1345 2.73175 20.2509C2.75966 20.3672 2.82063 20.473 2.90732 20.5554C2.994 20.6379 3.10269 20.6935 3.22028 20.7156C3.33786 20.7376 3.45933 20.7252 3.57001 20.6798L5.57001 20.0798C5.82921 20.0189 6.09908 20.0198 6.35788 20.0823C6.61668 20.1449 6.85718 20.2673 7.06 20.4398C8.39089 21.2012 9.87904 21.6463 11.4094 21.7408C12.9398 21.8352 14.4714 21.5765 15.8858 20.9847C17.3002 20.3928 18.5596 19.4836 19.5666 18.3273C20.5736 17.1711 21.3012 15.7987 21.6932 14.3164C22.0852 12.8341 22.1311 11.2815 21.8274 9.77859C21.5236 8.2757 20.8783 6.86279 19.9414 5.64908C19.0044 4.43537 17.8009 3.45337 16.4239 2.77898C15.0469 2.10459 13.5333 1.75588 12 1.7598V1.7598Z"
      stroke={color || "currentColor"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Chat;
