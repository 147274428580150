import { FC } from "react";

import { Wrapper } from "./style";

import CoinLoading from "../CoinLoading";

interface Props {
  type: string;
}

// Component of Coin loader on full page
const FullCoinLoader: FC<Props> = ({ type }) => (
  <Wrapper type={type}>
    <CoinLoading />
  </Wrapper>
);

export default FullCoinLoader;
