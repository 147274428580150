import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

import { OutfitRegular } from "styles/mixins";

export const Content = styled.main`
  flex: 1;
  position: relative;
  background-color: ${(p) => p.theme.colors.background.secondary};
  min-height: 100vh;
  color: ${(p) => p.theme.colors.text.primary};
  ${OutfitRegular}
  display: flex;

  .back {
    background: ${(p) => p.theme.colors.background.primary};
    color: ${(p) => p.theme.colors.text.primary};
    position: absolute;
    top: 40px;
    left: 40px;
    border-radius: 50%;
    padding: 16px;
    border: unset;

    &:hover {
      background: transparent;

      svg {
        path {
          stroke: ${(p) => p.theme.colors.text.primary} !important;
        }
      }
    }
  }

  ${deviceQuery.mobileM} {
    .back {
      left: 20px;
      top: 20px;
    }
  }
`;
