import { FC } from "react";

interface Props {
  size?: number;
}

const Lewk: FC<Props> = ({ size = 28 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
      fill="#25C374"
    />
    <path
      className="lewkIcon"
      d="M11.7725 4.87891L7.893 7.50391C7.29048 7.92196 6.9309 8.65113 6.94062 9.42891V18.072L14.6413 22.8747C14.9523 23.0692 15.3021 23.1664 15.652 23.1567C16.0018 23.147 16.3517 23.04 16.6627 22.8261L20.4061 20.2789L11.7725 14.922V4.87891Z"
      fill="#1C1E2B"
    />
    <path
      className="lewkIcon"
      d="M14.2627 5.97656L14.2919 13.9099L20.4162 9.74879L14.2627 5.97656Z"
      fill="#1C1E2B"
    />
  </svg>
);

export default Lewk;
