/* eslint-disable camelcase */
interface ISender {
  displayname: string;
  isVerified: boolean;
  pictureUrl_80_80: string;
  role: string;
  username: string;
  _id: string;
}

export enum SendGiftType {
  CLOSE = "close",
  CRYPTO = "crypto",
  CARD = "card",
  WALLET = "wallet",
}

type PaymentType = "content_subscription" | "send_gift";

export interface IGifting {
  amount: number;
  createdAt: string;
  message?: string;
  total_amount: boolean;
  transaction: string;
  updatedAt: string;
  _id: string;
}

export interface IGiftingCreator extends IGifting {
  receiver_data: ISender;
  sender_data: ISender;
  is_thanked: boolean;
  gifting_fee: number;
  sender: string;
  receiver: string;
  token_type: string;
}

export interface IMyTransactionFan extends IGifting {
  receiverData: ISender;
  senderData: ISender;
  asset: string;
  payment_type: PaymentType;
}
