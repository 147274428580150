import styled from "styled-components";

import Button from "Components/Button";

import { deviceQuery } from "styles/mediaSizes";

export const SliderContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
  border-bottom: 1px solid ${(p) => p.theme.colors.divider};

  .slides-container {
    transition: all 0.8s ease-out;
    display: flex;
    gap: 100px;
    position: relative;
  }
`;

export const SliderActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;

  .buttons {
    display: flex;
    gap: 20px;
  }

  .dots {
    display: flex;
    gap: 12px;

    .dot {
      height: 8px;
      width: 8px;
      background: ${(p) => p.theme.colors.green.primary20};
      border-radius: 50%;

      &.active {
        background: ${(p) => p.theme.colors.green.primary};
      }
    }
  }

  ${deviceQuery.tabletM} {
    flex-direction: column-reverse;
    gap: 20px;
  }

  ${deviceQuery.mobileM} {
    .buttons {
      width: 100%;
      flex-direction: column;

      > * {
        width: 100%;
      }
    }
  }
`;

export const ContinueButton = styled(Button)`
  padding: 10px 24px;
  font-size: ${(p) => p.theme.typography.text3};
  border-radius: 12px;
  width: 146px;
  min-height: 48px;
`;

export const SkipButton = styled(Button)`
  padding: 10px 0px;
  background: transparent;
  border: none;
  font-size: ${(p) => p.theme.typography.text3};
  border-radius: 12px;
  width: fit-content;
  min-height: 48px;

  &:hover {
    box-shadow: unset;
  }
`;

export const BackButton = styled(Button)`
  padding: 10px 24px;
  width: 146px;
  font-size: ${(p) => p.theme.typography.text3};
  min-height: 48px;
  border-radius: 12px;
`;

export const SlideContainer = styled.div`
  display: flex;
  gap: 20px;
  padding-bottom: 20px;

  & > div {
    width: 50%;
    display: flex;
    justify-content: center;
  }

  .left {
    flex-direction: column;

    .title {
      font-size: ${(p) => p.theme.typography.subTitle5};
    }
    .text {
      font-size: ${(p) => p.theme.typography.subTitle4};
      color: ${(p) => p.theme.colors.text.secondary};
    }
  }

  .right {
    align-items: center;
    padding: 68px 0px;
    background: ${(p) => p.theme.colors.background.secondary};
    border-radius: 20px;

    img {
      max-width: 420px;
    }
  }

  ${deviceQuery.tabletM} {
    flex-direction: column-reverse;

    .right {
      height: 100%;
      width: 100%;
    }

    .left {
      width: 100%;
      text-align: center;
    }
  }
`;
