import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { getLocalStorageItem } from "common/helpers/localStorage";

import { ROLES, STORAGES } from "constants/appConstants";
import { ROUTES } from "constants/routes";

function AdminPrivateRoute() {
  const token = getLocalStorageItem({ key: STORAGES.token });
  const accountType = getLocalStorageItem({ key: STORAGES.accountType });

  if (token && accountType === ROLES.admin) {
    return <Outlet />;
  }
  return <Navigate to={ROUTES.home} />;
}

export default AdminPrivateRoute;
