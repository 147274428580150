import moment from "moment/moment";
import { memo } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Reply } from "assets/svg/Reply";
import { MessageSubSectionStyles } from "pages/public/chat/Components/Chat/MessageSubSection/styles";
import { getUser } from "store/slices/global/userSlice";
import { MOMENT_TO_I18N } from "types/language.types";

interface Props {
  date: string;
  replyClicked: () => void;
}

export const MessageSubSection = memo(({ date, replyClicked }: Props) => {
  const user = useSelector(getUser);
  const { t } = useTranslation();

  if (!user) return null;

  return (
    <MessageSubSectionStyles>
      <div className="reply_btn" onClick={() => replyClicked()}>
        <Reply className="reply_icon" />
        <span>{t("new_chat.reply")}</span>
      </div>
      <span className="divider" />
      <span>{moment(date).locale(MOMENT_TO_I18N[user.languages]).format("hh:mm a")}</span>
    </MessageSubSectionStyles>
  );
});
