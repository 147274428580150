import { FC } from "react";

import { PlayBlock } from "./style";

import PlayIcon from "assets/svg/Play";

interface Props {
  withoutCircle?: boolean;
}

// Component for display play video icon
const Play: FC<Props> = ({ withoutCircle }) => (
  <PlayBlock>
    <div className={withoutCircle ? "" : "circle"}>
      <PlayIcon />
    </div>
  </PlayBlock>
);

export default Play;
