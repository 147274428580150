import styled from "styled-components";

export const ShowContentConteiner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100%;
  color: ${(p) => p.theme.colors.text.third};
  border-radius: 12px;
  border: unset;

  .thumbnail {
    cursor: pointer;
  }

  .remove_content {
    position: absolute;
    top: 8px;
    right: 8px;

    padding: 4px;
    border-radius: 8px;
    color: ${(p) => p.theme.colors.white.primary};
    cursor: pointer;
    background-color: rgba(7, 7, 17, 0.8);
  }
`;
