import styled, { css } from "styled-components";

import { STRINGS } from "constants/appConstants";
import { deviceQuery } from "styles/mediaSizes";

export const ToastsContainer = styled.div<{ clicked: boolean }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 1rem;
  gap: 12px;
  z-index: 9999;
  right: 2rem;

  ${(p) =>
    p.clicked
      ? css`
          pointer-events: all;
        `
      : css`
          pointer-events: none;
        `}

  ${deviceQuery.tabletM} {
    right: 1em;
    left: 1em;
    margin-left: 0;
  }
`;

export const ToastContainer = styled.div<{ type: string }>`
  display: flex;
  align-items: center;
  gap: 12px;

  padding: 8px 12px;
  border-radius: 12px;
  color: ${(p) => p.theme.colors.white.primary};
  font-family: ${(p) => p.theme.ff.outfit.semiBold};
  background: ${(p) =>
    p.type === STRINGS.success
      ? p.theme.colors.green.primary
      : p.type === STRINGS.error && p.theme.colors.text.error};
  transition: transform 0.1s ease-in-out;

  .icon_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(255, 255, 255, 0.2);
    padding: 8px;
    border-radius: 50%;
  }
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
