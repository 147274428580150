import { STRINGS } from "constants/appConstants";
import titles from "constants/titles";
import useTitle from "customHooks/useTitle";
import LoginForm from "pages/auth/Login/components/LoginForm";
import { AuthWrapper } from "styles/auth";

// admin login page
const Login = () => {
  // set title of the page
  useTitle(titles.login);

  return (
    <AuthWrapper>
      <div className="content">
        <LoginForm loginFor={STRINGS.admin} />
      </div>
    </AuthWrapper>
  );
};

export default Login;
