import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const SidebarFooterContainer = styled.div<{ show: boolean }>`
  display: flex;
  width: 100%;
  justify-content: center;
  background: ${(p) => p.theme.colors.background.secondary};
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 20px;
  align-items: center;
  z-index: 10;

  ${deviceQuery.desktopS} {
    ${(p) =>
      p.show
        ? css`
            display: flex;
            width: 90%;
            margin: 0px auto 20px;
          `
        : css`
            display: none;
          `}
  }
`;
