const GoldCrown = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.40802 1.25026L8.75802 2.85859L6.36635 8.77526L2.74135 6.00026C2.63243 5.91467 2.49929 5.86563 2.36088 5.8601C2.22247 5.85457 2.08584 5.89284 1.97044 5.96947C1.85504 6.04609 1.76675 6.15717 1.71814 6.28688C1.66953 6.41659 1.66307 6.55833 1.69969 6.69193L3.79969 15.5253H3.70802C3.46177 15.5479 3.23287 15.6617 3.06621 15.8444C2.89955 16.0271 2.80716 16.2655 2.80716 16.5128C2.80716 16.76 2.89955 16.9984 3.06621 17.1811C3.23287 17.3638 3.46177 17.4776 3.70802 17.5003H10.0164V0.808594C9.88169 0.810125 9.75084 0.853443 9.64186 0.932562C9.53289 1.01168 9.45117 1.12269 9.40802 1.25026Z"
      fill="#ED892E"
    />
    <path
      d="M16.3249 15.5258H16.2333L18.3249 6.69244C18.3522 6.56525 18.3412 6.43282 18.2933 6.31188C18.2454 6.19094 18.1627 6.08691 18.0557 6.01291C17.9488 5.93891 17.8222 5.89826 17.6922 5.8961C17.5621 5.89394 17.4343 5.93037 17.3249 6.00077L13.7083 8.77577L11.7666 3.98411L11.3083 2.85911L10.6583 1.25077C10.6134 1.11772 10.5266 1.00279 10.4109 0.92317C10.2953 0.843553 10.1569 0.803535 10.0166 0.809106V17.4758H16.3249C16.4622 17.4884 16.6007 17.4723 16.7314 17.4284C16.8621 17.3845 16.9822 17.3138 17.0841 17.2209C17.1859 17.128 17.2673 17.0148 17.323 16.8887C17.3787 16.7625 17.4074 16.6262 17.4074 16.4883C17.4074 16.3504 17.3787 16.214 17.323 16.0879C17.2673 15.9617 17.1859 15.8486 17.0841 15.7557C16.9822 15.6627 16.8621 15.5921 16.7314 15.5482C16.6007 15.5043 16.4622 15.4881 16.3249 15.5008V15.5258Z"
      fill="#FFB140"
    />
  </svg>
);

export default GoldCrown;
