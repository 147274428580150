const PLACEHOLDER = "|___|";
const NAME_REQUIRED_TEXT = "Name is required";
const FILE_FORMAT_UNSUPPORTED_TEXT = "Unsupported file format";

export const LOGIN_MESSAGE = {
  invalidSignature: {
    message:
      "Please sign the message in your wallet to continue.And also Select the Rinkeby Test Network",
    id: "invalid-signature",
    type: "error",
  },
};

export const WALLET_MESSAGE = {
  noWallet: {
    message: "Install MetaMask to connect to Moments.",
    id: "no-wallet",
    type: "error",
  },
  wrongNetwork: {
    message: `Your wallet is currently connected to a different network. Please change it to the ${PLACEHOLDER} to continue.`,
    id: "wrong-network",
    type: "error",
  },
};

export const PROFILE_VALIDATION_MESSAGE = {
  longName: {
    message: "Name must be 100 characters or less",
    id: "profile-long-name",
    type: "error",
  },
  requiredName: {
    message: NAME_REQUIRED_TEXT,
    id: "profile-name-required",
    type: "error",
  },
  requiredUsername: {
    message: "Username is required",
    id: "profile-username-required",
    type: "error",
  },
  contractAddress: {
    message: "contract address is required",
    id: "profile-username-required",
    type: "error",
  },
  shortUsername: {
    message: "Username must be at least 5 characters",
    id: "profile-username-short",
    type: "error",
  },
  longUsername: {
    message: "Username must be 100 characters or less",
    id: "profile-username-long",
    type: "error",
  },
  invalidUsername: {
    message: "Username is invalid",
    id: "profile-username-invalid",
    type: "error",
  },
  invalidEmail: {
    message: "Email is invalid",
    id: "profile-email-invalid",
    type: "error",
  },
  unSupportedFile: {
    message: FILE_FORMAT_UNSUPPORTED_TEXT,
    id: "profile-file-unsupported",
    type: "error",
  },
};
export const SIGNATURE_REQUEST_LOADING_MODAL_MESSAGE = {
  title: {
    message: "Please sign the message in your wallet to continue",
    id: "signature-request-loading-message-title",
    type: "message",
  },
  body: {
    message: "Wethio uses this signature to verify that you're the owner of this wallet address",
    id: "signature-request-loading-message-body",
    type: "message",
  },
};

export const COMMON_MESSAGE = {
  unsavedChanges: {
    message: "Changes you have done might not be saved",
    id: "unsaved-changes",
    type: "warning",
  },
};

export const MESSAGES = {
  registrationSuccessfull: "Registration Successful",
  networkErr: "Network Error",
  usernameAlreadyRegistered: "Username already exist",
  emailAlreadyRegistered: "Email already exist",
  jwtExpired: "JWT expired",
  invalidReferral: "Invalid referral code",
};

const Messages = {
  LOGIN_MESSAGE,
  WALLET_MESSAGE,
  SIGNATURE_REQUEST_LOADING_MODAL_MESSAGE,
};

export default Messages;
