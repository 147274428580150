import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { ROUTES } from "constants/routes";

const CreatorProfileRoute = () => {
  const location = useLocation();
  if (location?.pathname?.slice(1, 2) === "@") {
    return <Outlet />;
  }
  return <Navigate to={ROUTES.home} />;
};

export default CreatorProfileRoute;
