import { forwardRef, useMemo } from "react";

import { useDispatch } from "react-redux";

import { ChatReply } from "pages/public/chat/Components/Chat/ChatReply";
import { ContentMessage } from "pages/public/chat/Components/Chat/ContentMessage";
import {
  MessageContainerStyles,
  MessageContainerWrapper,
} from "pages/public/chat/Components/Chat/MessageContainer/styles";
import { MessageSubSection } from "pages/public/chat/Components/Chat/MessageSubSection";
import { SingleContentMessage } from "pages/public/chat/Components/Chat/SingleContentMessage";
import { TextMessage } from "pages/public/chat/Components/Chat/TextMessage";
import { setViewPicture } from "store/slices/global/globalSlice";
import { MessageMediaFile, NewMessageItem } from "types/new-messages.types";

interface Props {
  isYours: boolean;
  message: NewMessageItem;
  highlighted: boolean;
  search: string;
  replyToMessage: (message: NewMessageItem) => void;
  goToReplied: (messageId: string) => void;
}

export const MessageContainer = forwardRef<HTMLDivElement, Props>(
  ({ isYours, message, search, highlighted, replyToMessage, goToReplied }, ref) => {
    const dispatch = useDispatch();

    const onContentClick = (content: MessageMediaFile) => {
      dispatch(setViewPicture({ src: content.url, show: true, type: content.mediaType }));
    };

    const messageComponent = useMemo(() => {
      if (message.messageType === "text")
        return <TextMessage isYours={isYours} text={message.text} search={search} />;

      if (message.messageType === "media")
        return <SingleContentMessage message={message} onContentClick={onContentClick} />;

      if (message.messageType === "media_card") return <ContentMessage contentMessage={message} />;
    }, [message, search]);

    return (
      <MessageContainerWrapper highlighted={highlighted}>
        <MessageContainerStyles isYours={isYours} id={message._id} ref={ref}>
          {message.replyToMessage && (
            <ChatReply
              messageToReply={message.replyToMessage}
              inChatStyles
              goToReply={goToReplied}
            />
          )}

          {messageComponent}
          <MessageSubSection
            date={message.createdAt}
            replyClicked={() => replyToMessage(message)}
          />
        </MessageContainerStyles>
      </MessageContainerWrapper>
    );
  }
);
