import { ReactNode } from "react";

export enum BROWSER {
  UNKNOWN = "Unknown",
  CHROME = "Chrome",
  FIREFOX = "Firefox",
  SAFARI = "Safari",
  SAFARI_IOS = "Safari (iOS)",
  EDGE = "Edge",
  OPERA = "Opera",
  INTERNET_EXPLORER = "Internet Explorer",
}

export type DateIso = string;

export interface IErrorState {
  [key: string]: string;
}

export interface IFilterOption {
  icon: ReactNode | string;
  name: string;
}
