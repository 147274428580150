import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const SocialContainer = styled.div`
  .accordion_wr {
    background: ${(p) => p.theme.colors.background.secondary};
    padding: 16px;
    border-radius: 24px;
  }
  .collapsed_link {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: ${(p) => p.theme.typography.subText5};
  }
  .drag {
    cursor: move;
    color: ${(p) => p.theme.colors.text.secondary};
    display: flex;
    align-items: center;
    gap: 16px;
    flex-direction: row;
  }

  .icon {
    padding: 20px;
    border-radius: 50%;
    background: ${(p) => p.theme.colors.background.fifth};
  }

  .actions {
    color: ${(p) => p.theme.colors.text.secondary};
    display: flex;
    gap: 16px;

    .divider {
      background: ${(p) => p.theme.colors.divider};
      width: 1px;
    }

    .arrow {
      cursor: pointer;
      color: ${(p) => p.theme.colors.text.primary};
      transition: transform 0.3s;
    }

    .arrow_opened {
      transform: rotate(-180deg);
    }

    .arrow_disabled {
      cursor: default;
      color: ${(p) => p.theme.colors.text.secondary};
    }

    .close {
      cursor: pointer;
    }
  }

  .info {
    display: flex;
    width: 100%;
    gap: 4px;
    flex-direction: column;
    font-size: ${(p) => p.theme.typography.text3};
    overflow: hidden;
    text-overflow: ellipsis;

    .title {
      text-transform: capitalize;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .link {
      text-decoration: none;
      align-items: center;
      font-size: ${(p) => p.theme.typography.subText5};
      color: ${(p) => p.theme.colors.green.primary};
      gap: 4px;
      display: inline-flex;
      cursor: pointer;
    }
  }

  ${deviceQuery.desktopS} {
    .drag {
      cursor: pointer;
    }
  }

  ${deviceQuery.laptopM} {
    .info {
      .title {
        max-width: 300px;
      }
    }
  }

  ${deviceQuery.tabletM} {
    gap: 12px;

    .icon {
      padding: 16px;
    }
    .info {
      font-size: ${(p) => p.theme.typography.subText5};
    }
    .subscribers {
      padding-right: 12px;
    }
    .spoiler {
      gap: 12px;
    }
  }

  ${deviceQuery.mobileMS} {
    .drag {
      gap: 12px;
    }

    .icon {
      padding: 12px;
    }
    .subscribers {
      padding-right: 0px;
    }
  }
`;
