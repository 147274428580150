import styled from "styled-components";

import { OutfitLight } from "styles/mixins";

export const SubHeading = styled.h4`
  font-size: 0.9rem;
  color: ${(p) => p.theme.colors.icon.secondary};
  ${OutfitLight};
  display: flex;
  margin-top: 5px;

  p {
    cursor: pointer;
  }

  p:hover {
    color: ${(p) => p.theme.colors.text.third};
  }
`;
