import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";

import { BannerContainer } from "./style";

import ProfilePreview from "../ProfilePreview";

import TrackGoogleAnalyticsEvent from "common/googleAnalytics";
import Button from "Components/Button";
import OverlayLoader from "Components/OverlayLoader";
import { updateProfileInfoAPI } from "services/UserServices";
import { getCreatorProfileLoader, setLoader } from "store/slices/featureSlice";
import { getPublicUser, resetPublicUser } from "store/slices/global/previewProfileSlice";

const Banner = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(getCreatorProfileLoader);
  const creatorData = useSelector(getPublicUser);
  const { state } = useLocation();

  const handleDeleteBanner = () => {
    dispatch(setLoader({ name: "editProfileLoader", value: true }));

    updateProfileInfoAPI(
      {
        data: {
          banner: null,
        },
        part: "customizations",
      },
      () => navigate("../../creator/bio/customization")
    );
  };

  useEffect(
    () => () => {
      dispatch(resetPublicUser());
    },
    []
  );

  return (
    <BannerContainer>
      <p className="pageTitle">{t("common.bannerDisplay")}</p>
      {isLoading && !creatorData._id ? (
        <OverlayLoader isLoading={isLoading} />
      ) : creatorData._id ? (
        <div className="content">
          <div className="preview">
            <div className="block mobile">
              <img className="imgBorder" src="/phone.png" alt="phone" />
              <ProfilePreview mobile />
            </div>
            <div className="block desktop">
              <img className="imgBorder" src="/desctop.png" alt="desctop" />
              <ProfilePreview />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {!state && (
        <div className="bannerActions">
          <Button variant="secondary" onClick={handleDeleteBanner}>
            {t("common.cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (state) {
                TrackGoogleAnalyticsEvent("Bio Customization", "banner_edit");
              } else {
                TrackGoogleAnalyticsEvent("Bio Customization", "banner_upload");
              }
              navigate("../../creator/bio/customization");
            }}
          >
            {t("common.done")}
          </Button>
        </div>
      )}
    </BannerContainer>
  );
};

export default Banner;
