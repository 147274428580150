import styled from "styled-components";

export const PhoneAnimationTabsContainer = styled.div`
  .phone_animation_tabs {
    display: flex;
    flex-direction: column;
    min-height: 575px;
  }

  .phone_animation_tab {
    margin-top: 12px;
    padding: 14px 20px;
    font-size: 19px;
    font-weight: 500;
    line-height: 28px;
    width: fit-content;
    cursor: pointer;
    overflow: hidden;
    color: #8f8fa8;
  }

  .phone_animation_subTab {
    margin-left: 20px;
    font-size: 15px;
    line-height: 22px;
    padding: 13px 16px;
  }

  .phone_animation_tab span {
    position: relative;
    z-index: 2;
  }

  .phone_animation_tab_active {
    position: relative;
    border-radius: 80px;
    border: 1px solid #8f8fa8;
    color: #151529;
  }

  .phone_animation_tab::before {
    content: " ";
    position: absolute;
    inset: 0;
    border-radius: 80px;
    z-index: 1;
    background: white;
    transform: translateX(-100%);
    transition: all 0s;
  }

  .phone_animation_tab_active::before {
    transition: transform 5s;
    content: " ";
    position: absolute;
    inset: 0;
    border-radius: 80px;
    z-index: 1;
    background: white;
    transform: translateX(0%);
  }

  .phone_animation_tab_childActive {
    border: none;
    color: #151529;
  }

  .phone_animation_tab_hidden {
    visibility: hidden;
    height: 0;
    padding: 0;
    margin: 0;
  }

  @media (max-width: 750px) {
    .phone_animation_tabs {
      display: none;
    }
  }
`;
