import { createListenerMiddleware } from "@reduxjs/toolkit";

import { markMessagesAsReadById } from "services/newMessagesService";
import { setUnreadChats, setUnreadMessages, updateOneChat } from "store/slices/global/chatsSlice";
import { RootState } from "store/store";
import { getChatMessagesByChatId } from "store/thunks/messages-thunks";
import { NewMessageItem } from "types/new-messages.types";

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: getChatMessagesByChatId.fulfilled,
  effect: async (action, listenerApi) => {
    // Can cancel other running instances
    listenerApi.cancelActiveListeners();

    const allMessages = action.payload.data;
    if (!allMessages.length) return;

    const unreadMessages = allMessages.reduce<string[]>(
      (acc: string[], message: NewMessageItem) => {
        if (message.unread) {
          acc.push(message._id);
        }
        return acc;
      },
      []
    );

    if (!unreadMessages.length) {
      return;
    }
    const chatId = action.payload.requestData.chatId;
    const state = listenerApi.getState() as unknown as RootState;

    markMessagesAsReadById(chatId, unreadMessages);

    if (
      (window.location.pathname === "/creator/chat" || window.location.pathname === "/fan/chat") &&
      state.global.chats.openedChat?.status !== "blocked"
    )
      listenerApi.dispatch(setUnreadChats(state.global.chats.unread - unreadMessages.length));
    if (window.location.pathname === "/creator/messages")
      listenerApi.dispatch(
        setUnreadMessages(state.global.chats.unreadMessages - unreadMessages.length)
      );

    const openedChat = state.global.chats.openedChat;
    if (!openedChat) return;
    const lastChatMessageId = openedChat.lastMessage._id;

    if (lastChatMessageId && unreadMessages.includes(lastChatMessageId)) {
      // lastMessage has been read, update left side chat to have also read state
      listenerApi.dispatch(
        updateOneChat({
          id: openedChat._id,
          changes: {
            unreadMark: false,
          },
        })
      );
    }
  },
});
