import { FC, useEffect } from "react";
import YouTube from "react-youtube";

import { YoutubeContentPreviewContainer } from "pages/creator/Bio/BioLink/components/Social/SocialExpanded/YoutubeContentPreviewCard/style";

interface Props {
  videoId: string;
  errorStateChanged?: (hasError: boolean) => void;
  className?: string;
  iframeClassName?: string;
}

const YoutubeContentPreviewCard: FC<Props> = ({
  videoId,
  className,
  iframeClassName,
  errorStateChanged,
}) => {
  useEffect(() => {
    errorStateChanged && errorStateChanged(false);
  }, [videoId]);

  return (
    <YoutubeContentPreviewContainer>
      <YouTube
        videoId={videoId}
        className={className}
        iframeClassName={iframeClassName}
        onError={() => errorStateChanged && errorStateChanged(true)}
      />
    </YoutubeContentPreviewContainer>
  );
};

export default YoutubeContentPreviewCard;
