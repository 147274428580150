import moment from "moment";
import { forwardRef, JSX, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import Text from "./components/Text";
import { ChatContainer } from "./style";

import { getDaysTillNow } from "common/helpers/helper";
import { getHighlightedSearchedText } from "common/helpers/highlight-searched-text";
import Avatar from "Components/Avatar";
import { getUser } from "store/slices/global/userSlice";
import { MOMENT_TO_I18N } from "types/language.types";
import { NewChatItem } from "types/new-messages.types";

interface Props {
  item: NewChatItem;
  active: boolean;
  search: string;
  onChatClicked: (chatItem: NewChatItem) => void;
}

const ChatItem = forwardRef<HTMLDivElement, Props>(
  ({ item, onChatClicked, search, active }, ref) => {
    const { t } = useTranslation();
    const user = useSelector(getUser);

    const lastMessageTextOrPlaceHolder = useMemo<string | JSX.Element>(() => {
      const lastMessageType = item.lastMessage.messageType;

      if (lastMessageType === "media_card" || lastMessageType === "media")
        return t("new_chat.user_sent_content");

      return getHighlightedSearchedText(item.lastMessage.text, search);
    }, [item, t]);

    if (!user) return null;

    return (
      <ChatContainer
        ref={ref}
        onClick={() => onChatClicked(item)}
        read={!item.unreadMark}
        active={active}
      >
        <div>
          <Avatar
            role={item.anotherUser.role}
            src={item.anotherUser.pictureUrl_80_80}
            alt={item.anotherUser.username}
          />
        </div>
        <div className="info">
          <p className="main">
            <span className="author">
              {getHighlightedSearchedText(
                item.anotherUser.displayname || item.anotherUser.username,
                search
              )}
            </span>
            <span className="time">
              {getDaysTillNow({
                time: item.lastMessage.createdAt,
              }) === 1
                ? moment(item.lastMessage.createdAt)
                    .locale(MOMENT_TO_I18N[user.languages])
                    .format("hh:mm a")
                : moment(item.lastMessage.createdAt)
                    .locale(MOMENT_TO_I18N[user.languages])
                    .format("DD/MM/YYYY")}
            </span>
          </p>
          <p className="message">
            {item.unreadMark && (
              <span>
                <div className="dot" />
              </span>
            )}
            <Text preview content={lastMessageTextOrPlaceHolder} />

            {item.searchedMessagesId && (
              <div className="searchedCount">
                <span>{item.searchedMessagesId?.length}</span>
              </div>
            )}
          </p>
        </div>
      </ChatContainer>
    );
  }
);

export default ChatItem;
