import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { getLocalStorageItem } from "common/helpers/localStorage";

import { STORAGES } from "constants/appConstants";
import { ROUTES } from "constants/routes";

const AdminPrivateRoute = () => {
  const token = getLocalStorageItem({ key: STORAGES.token });

  if (token) {
    return <Outlet />;
  }
  return <Navigate to={ROUTES.home} />;
};

export default AdminPrivateRoute;
