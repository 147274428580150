import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";
import { HideScrollBar } from "styles/mixins";

export const WallpaperContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 16px;
  gap: 20px;
  position: relative;
  flex: 1;

  .pageTitle {
    position: absolute;
    top: 54px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    font-size: ${(p) => p.theme.typography.text3};

    .title {
      padding: 4px 8px;
      border-radius: 8px;
      background: ${(p) => p.theme.colors.background.secondary};
    }

    .tabsContainer {
      font-size: ${(p) => p.theme.typography.subText5};
    }
  }

  .mainActions {
    position: absolute;
    bottom: 40px;
    z-index: 6;
    display: flex;
    bottom: 40px;
    gap: 16px;

    button {
      width: 160px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    height: calc(100vh - 450px);
    max-height: 404px;
    overflow: visible;

    .preview {
      width: 100%;
      padding: 0px;
      display: flex;
      justify-content: center;
      position: relative;
      border-bottom: 1px solid ${(p) => p.theme.colors.divider};
      flex: 1;
      height: 100%;
      max-height: 534px;

      .block {
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 0px 3px;

        .imgBorder {
          width: 100%;
          z-index: 2;
          position: absolute;
          pointer-events: none;
        }

        &.desktop {
          width: 59%;

          .imgBorder {
            max-height: 404px;
            aspect-ratio: 1.6 / 1;
          }
        }

        &.mobile {
          margin-right: -50px;
          width: 18.5%;

          .imgBorder {
            z-index: 4;
            max-height: 360px;
            aspect-ratio: 1 / 1.8;
          }
        }
      }
    }

    & > .actions {
      display: flex;
      position: absolute;
      bottom: 120px;
      gap: 32px;
      flex-direction: column;
      align-items: center;

      .patternsSmall,
      .selectedColor,
      .colorsSmall {
        display: none;
      }

      .patterns {
        display: flex;
        gap: 20px;

        .icon {
          color: ${(p) => p.theme.colors.text.secondary};
          cursor: pointer;

          &.active {
            color: ${(p) => p.theme.colors.text.primary};
          }
        }
      }

      .colors {
        display: flex;
        gap: 12px;
      }
    }
  }

  ${deviceQuery.tabletM} {
    .content {
      max-height: unset;

      .preview {
        height: 100%;
        padding: 0px 20px;
        justify-content: center;

        .block {
          &.mobile {
            margin-right: 0px;
            width: 250px;

            .imgBorder {
              max-height: unset;
            }
          }
          &.desktop {
            display: none;
          }
        }
      }
      & > .actions {
        .patterns {
          gap: 16px;
        }
      }
    }
  }

  ${deviceQuery.mobileM} {
    .content {
      align-items: flex-start;

      .preview {
        padding: 0px;
      }

      & > .actions {
        padding: 0px;
        height: 40px;
        bottom: 90px;
        flex-direction: row;
        gap: 20px;
        width: calc(100% - 32px);

        .patterns,
        .colors {
          display: none;
        }
        .selectedColor {
          display: flex;
          padding-right: 20px;
          padding-left: 2px;
          border-right: 1px solid ${(p) => p.theme.colors.divider};
        }
        .patternsSmall {
          padding-left: 2px;
          position: absolute;
          display: flex;
          top: 0px;
          left: 68px;
          width: calc(100vw - 108px);
          overflow-x: scroll;
          background: ${(p) => p.theme.colors.background.secondary};
          height: 100%;
          ${HideScrollBar}

          .scrollContainer {
            display: flex;
            padding: 20px 0px;
            gap: 12px;
            align-items: center;
          }
        }

        .colorsSmall {
          position: absolute;
          display: flex;
          top: 0px;
          width: 100%;
          justify-content: center;
          overflow-x: scroll;
          background: ${(p) => p.theme.colors.background.secondary};
          height: 100%;
          ${HideScrollBar}

          .scrollContainer {
            display: flex;
            padding: 20px 0px;
            gap: 12px;
            align-items: center;
          }
        }

        .colorsSmall {
          display: none;
          &.show {
            display: flex;
          }
        }
      }
    }
    .mainActions {
      bottom: 20px;
    }
  }

  ${deviceQuery.mobileMS} {
    .content {
      align-items: flex-start;

      .preview {
        padding: 0px;

        .block {
          &.mobile {
            width: 200px;
          }
        }
      }
    }
  }

  ${deviceQuery.mobileS} {
    .content {
      align-items: flex-start;

      .preview {
        padding: 0px;

        .block {
          &.mobile {
            width: 180px;
          }
        }
      }
    }
  }
`;

export const Icon = styled.div<{ active: boolean }>`
  color: ${(p) => (p.active ? p.theme.colors.text.primary : p.theme.colors.text.secondary)};
  cursor: pointer;
`;

export const Color = styled.div<{
  color?: string;
  gradient?: { color1: string; color2: string };
  active: boolean;
}>`
  ${(p) =>
    p.color &&
    css`
      background: ${p.color};
    `}

  ${(p) =>
    p.gradient &&
    css`
      background: linear-gradient(90deg, ${p.gradient.color1} 0%, ${p.gradient.color2} 100%);
    `}
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;

  ${(p) =>
    p.active &&
    css`
      box-shadow: 0px 0px 0px 2px ${p.theme.colors.text.primary};
    `}
`;
