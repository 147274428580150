import Axios, { AxiosError, AxiosResponse } from "axios";

import { getCreator } from "./UserServices";

import { clearSlicesData, makeToast } from "common/helpers/helper";
import { getLocalStorageItem, setLocalStorageItem } from "common/helpers/localStorage";
import defaultConfig, {
  REACT_APP_ARI10_API_BASE_URL,
  REACT_APP_ARI10_WIDGET_ID,
} from "configuration/config";
import { STORAGES, STRINGS } from "constants/appConstants";
import { MESSAGES } from "constants/messages";
import {
  setCustomizePlatforms,
  setDefaultLandingPages,
  setNewPeoples,
  setUserLevel,
} from "store/slices/admin/adminSlice";
import {
  logout,
  setforgotPwdUserType,
  setUserId,
  startTimer,
  stopTimer,
} from "store/slices/authSlice";
import { setVideos } from "store/slices/creator/creatorSlice";
import { emitUserLogoutEvent } from "store/slices/eventBusSlice";
import { setLoader } from "store/slices/featureSlice";
import { setUser } from "store/slices/global/userSlice";
import store from "store/store";
import { IUser } from "types/admin/people";
import { GetDefaultRouteApiResp, GetUserDetailsApiResp } from "types/api-response-types";
import { UserRole } from "types/user-role";

export default function getAxiosInst() {
  const jwtToken = store.getState().auth.token;
  const token = getLocalStorageItem({ key: STORAGES.token });
  return Axios.create({
    baseURL: defaultConfig.apiBaseUrl,
    headers: { Authorization: `Bearer ${jwtToken || token}` },
  });
}

export const getAriInst = () =>
  Axios.create({
    baseURL: REACT_APP_ARI10_API_BASE_URL,
    headers: {
      "Content-type": " application/json",
      "Ari10-Widget-Id": REACT_APP_ARI10_WIDGET_ID,
    },
  });

export const endpoints = {
  // 2FA
  validate2FA: "/tfa/validate",
  twoFactorAuthRecovery: "/tfa/recover",

  // login
  loginUser: "/user/userlogin",
  adminLogin: "/user/adminlogin",

  // catcha
  verifyCaptcha: "/captcha/verifycaptcha",

  // registrations
  createUser: "/user",
  createNewUser: "/user/signup",
  createUserByAdmin: "/user/createusersByAdmin",

  getAllUsers: "/admin/getallusers",
  getPendingVideos: "/admin/getAllvideo",
  uploadVideo: "/creator/uploadvideo",
  checkVideoUploadAllowance: "/creator/getweekvideocount",
  approveVideo: "/admin/ApproveVideobyAdmin",
  rejectVideo: "/admin/DisapproveVideobyAdmin",
  getCreatorVideos: "/creator/getvideobycreator",
  getAllCreatorForJudge: "/judge/getallcreator",

  // get user details
  creatorDetails: "/creator/getcreator",
  newCreatorDetails: "/user/own",
  deleteAccount: "/user/own/delete",
  cancelDeleteAccount: "/user/own/cancelDelete",
  judgeDetails: "/judge/getjudge",
  fanDetails: "/fan/getfan",
  publisherDetails: "/publisher/getpublisher",
  adminDetails: "/admin/getadmindetails",
  removeProPic: "/user/removeProfilePic",
  skipMFA: "/user/skipMfa",

  countLink: "/analytics/countLink",
  creatorAnalytics: "/analytics/creatorAnalytics",

  // forgot-password
  sendVerificationEmail: "/user/resetpasswordlink",
  resetPassword: "/user/verifyresetpassword",
  changePassword: "/user/changepassword",

  getNewUsers: "/admin/getRecentUsers",
  updateSocialLink: "/creator/updateSocialmediaLink",

  activateUserAccount: "/admin/activateuserbyAdmin",
  deactivateUserAccount: "/admin/deactivateuserbyAdmin",

  updateCustomizeLevel: "/level/updatelevel",
  getCustomizeLevel: "/level/getalllevels",

  getCustomizePlatforms: "/platform/getallplatform",
  updateCustomizePlatforms: "/platform/updateplatform",

  getAllDefaultLandingPages: "/defaultRoute/getalldefaultroute",
  updateDefaultLandingPages: "/defaultRoute/updatedefaultroute",

  updateMonetizationLimit: "/monetization/updatemonetization",

  getAccountType: "/publisher/getAccountType",
  /** **** notifications ****** */
  getNotificationCount: "/notification/getunreadnotificationcount",
  getNotifications: "/notification/getnotification",
  updateNotification: "/notification/updatenotificationstatus",

  /** **** challenges ****** */
  addChallenge: "/challenge",
  updateChallenge: "/challenge",
  getChallenges: "/challenge",
  getChallenge: "/challenge",
  getSubmissions: "/challenge/creator_submissions",
  getEarnings: "/user/earnings",
  challenge: "/challenge",
  participateChallenge: "/join",
  // removeParticipants: "/creator/trends/removeParticipant",
  // deleteChallenge: "creator/trends/deleteTrend",
  getParticipantList: "creator/trends/participants",
  getAdminParticipants: "/participants",
  updateParticipantPayStatus: "/updateParticipantPayStatus",

  /** **** admin reports ****** */
  getVideoCategoryReports: "/admin/getDataForVideoCategory",

  /** **** wallet ****** */
  wallet: "/wallet",
  getTransactions: "/transaction/gettransaction",
  withdraw: "/wallet/withdraw",
  withdrawFee: "/wallet/fee",
  refreshLewkBalance: "/wallet/refershLewkBalance",
  getWithdrawalHistory: "/transaction/getWithrawTransaction",
  getAriHistory: "/transaction/getAri10Transactions",
  unstake: "/wallet/unstake",
  increaseCampaignBudget: "campaign/increaseCampaignBudget",

  /** **** judge ****** */
  getAllCreatorsForJudge: "/judge/getallcreator",

  /** **** creator ****** */
  getCreatorEarningReport: "/creator/earningReports",
  getUserByFilter: "/creator/getUserByFilter",
  getcountTrendCampaign: "/creator/countTrendCampaign",
  /** ****gifting ******* */
  gifting: "creator/gifting",
  admingifting: "admin/gifting",
  sendGift: "gift/sendGift",
  sendReward: "ideaCampaignItem/paycampaignItemPublisher",
  sendTrendReward: "creator/trends/reward",

  /** ****** update profile ******** */
  getWallOfFame: "/user",
  updateProfile: "/user/own/details",
  getWallPreview: "/user/wallOfFame/preview",
  verifySocial: "/auth",
  disconnectSocial: "/disconnect",
  updateFanProfile: "/fan/profile",
  updateProfilePic: "/user/updateProfilePic",
  updateProfilePicture: "/utils/mediaUpload",
  updateUsername: "/user/renameUser",

  /** ****** category apis ******** */
  getAllCategories: "/category/getAllCategory",
  createCategory: "/category/createCategory",
  deleteCategory: "/category/deleteCategory",

  /** ****** message ********** */
  messageReport: "chat/getReport",
  messageRespondReport: "chat/respondReport",
  /** *** admin analytics ******** */
  gettransactionExplore: "/analytics/transctionExplore",
  getfeeinformation: "/analytics/feeTransactionList",
  getmonthlyfeeinformation: "/analytics/feeChart",
  getBalance: "/analytics/userBalance",
  sweepUsers: "/wallet/sweep",
  getAdminChallenges: "/challenge/challengesForPay",

  /** *** admin campaigns ******** */
  getAdminCampaigns: "/campaign",
  adminCampaignStatus: "/campaign/activateDeactivate",
  adminSingleCampaign: "/campaign/adminCampaignDetails",
  creatorVideos: "/campaignVideo/listAllVideos",
  paymentList: "/campaign/campainPayInfo",
  adminEditCampaign: "campaign/updateCampaign",
  /** ***creator campaigns***** */
  getCreatorCampaigns: "/campaign/listOfAllCampaign",
  getCreatorSignleCampaign: "/campaign/campaignDetails",
  getCreatorSingleVideo: "/campaignVideo/listSingleVideo",
  getCreatorCreateVideo: "/campaignVideo/uploadVideo",
  getCreatorCreateVideoList: "/campaignVideo/listAllVideos",
  getCreatorCreateVideoLinkDetails: "/campaignVideo/videoUrlInfo",
  getUpdateCreatorCreateVideoLinkDetails: "/campaignVideo/updateApprovedCampaign",
  getDeleteCreatorVideo: "/campaignVideo/deleteVideo",
  getDeleteCreateVideo: "campaignVideo/deleteVideo",
  getReviewCreateVideo: "campaignVideo/changeVideoStatus",
  getCreatorCampaignIdeas: "/ideaCampaign/listOfAllCampaign",
  getCampaignIdeasProposal: "/ideaCampaignItem/createCampaignItem",
  getCreatorActiveCampaignIdeas: "/ideaCampaign/listAPublisherCampaign",
  getCreatorActiveCampaignIdeasPublisher: "/creator/publisherProfile",
  getCreatorPublisherRanking: "/ideaCampaign/publisherRanking",
  getMyProposals: "/ideaCampaignItem/listCreatorProposal",
  deleteMyProposal: "ideaCampaignItem/deleteCreatorProposal",
  contributorStandaloneCampaign: "/publisherAnalytics/listStandaloneCampaign",
  contributorIdeaCampaign: "/publisherAnalytics/listIdeaCampaign",
  /** ****creator subscription ********* */
  createSubscription: "subscription/createSubscriptionNew",
  creatorSubscriptionPlan: "subscription/listAllPlan",
  deleteSubscriptionPlan: "subscription/deleteSubscription",
  editSubscriptionPlan: "subscription/editSubscription",
  creatorSubscriber: "creator/getSubscribers",
  createContent: "/feed/create",
  getCreatorContent: "/feed/creatorFeedList",
  getUpdateContent: "/feed/updatePost",
  deleteContent: "/feed/deleteFeed",
  earnings: "subscription/feedSubscriptionStats",

  rewards: "/ideaCampaignItem/listReward",
  sendMsg: "/ideaCampaignItem/thankMessage",
  sendGiftMsg: "/gift/thankMessage",
  /** ******subscription refund request  ******* */
  subscriptionRefundReq: "subscription/refundRequstList",
  subscriptionRefundReqStatus: "subscription/refundRequstStatus",

  /** ******** creator chat *********** */
  unreadMessagesCounter: "chats/unreadMessagesCounter",
  getMessages: "messages",
  chats: "chats",
  sendMessage: "chats/sendMessage",
  searchInChat: "chats/search",
  markMessagesAsRead: "messages/markRead",
  chatStatusesCounter: "chats/statusesCounter",
  updateStatus: "updateStatus",
  addMessage: "addMessage",
  createThread: "messages/create",
  createChat: "chats/create",
  creatorchatupdate: "chat/fanChatEnable",
  reportChat: "chat/reportChat",
  /** ****** admin nft apis ******** */
  createNft: "/nft/createNft",
  getAllNfts: "/nft/getAllNfts",
  deleteNft: "/nft/deleteNft",
  editNft: "nft/updateNft",
  getAllCustomFees: "customFee/getAll",
  updateCustomFee: "customFee/update",
  getExchangeRate: "configuration/getExchangePrice",
  getSingleNftById: "nft/getNftById",
  getAllTemplates: "emailTemplate",
  getAllOnBoardingScreens: "/onboarding",
  getBoardingByRole: "/user/own",
  getBoardingRoles: "/roles/all",
  getOnBoardings: "/onboarding",
  /** ****** creator nft apis ******** */
  getCreatorNfts: "/nft/nftCreatorListing",
  hatchEgg: "nft/nftImageHatching",
  getSingleNft: "nft/getCreatorNft",
  buyNft: "nft/payNft",
  sellNft: "nft/reSellNft",
  upgradeNft: "nft/upgradeNft",
  findFan: "chat/findFan",
  findSubscribedCreator: "chat/findSubscribedCreator",
  getChatStatus: "/getAChatStatus",
  getPublisherChatStatus: "/creatorPublisherChat",
  chat: "chat",
  /** ********** fan ************* */
  getFanPaymentHistory: "/fan/transactionHistory",
  fanSubscriptionRefundList: "",
  fanSubscriptionList: "/fan/subscribedPlanByFan",
  deleteSubscriptionPlanAsFan: "/fan/cancelSubscription",
  fanRefundList: "/fan/refundRequestList",
  fanRefundNote: "/fan/refundReported",
  getGiftingHistory: "/gift/transactionHistory",

  /** ****** publisher apis ******** */
  createCampaign: "/campaign/createCampaign",
  createIdeaBasedCampaign: "/ideaCampaign/createCampaign",
  campaignList: "campaign/listPublisherCampaigns",
  campaignIdeaList: "ideaCampaign/listPublisherCampaigns",
  proposedIdeaList: "ideaCampaignItem/listAllCampaignItemsPublisher",
  updateCampaign: "campaign/updateCampaign",
  deleteCampaign: "campaign/deleteCampaign",
  deleteIdeaBasedCampaign: "ideaCampaign/deleteCampaign",
  campaignDetails: "campaign/campaignDetails",
  campaignIdeaDetailsById: "ideaCampaign/campaignDetails",
  campaignVideoList: "campaignVideo/listAllVideos",
  campaignVideoDetails: "campaignVideo/listSingleVideo",
  campaignVideoChangeStatus: "campaignVideo/changeVideoStatus",
  paymentStatus: "campaign/budgetPayStatus",
  updateIdeabasedCampaign: "ideaCampaign/updateCampaign",
  campaignIdeaChangeStatus: "ideaCampaignItem/changeStatusPublisher",
  enabledCreators: "chat/publisherCreatorList",
  enabledPublishers: "chat/creatorPublisherList",

  /** ****** fan apis ******** */
  fanFeeds: "/fan/feedPageListing",
  fanSingleFeed: "/feed/feedById",
  fanSinglePublicFeed: "/public/creatorFeed",
  feedCount: "/feed/updateviews",
  fanFeedsByCreator: "/feed/feedByFan",
  creatorFeedsByCreator: "/feed/feedByCreator",
  fanFeedsBySubscription: "/feed/feedBasedSubscription",
  findCreators: "/fan/searchCreators",
  planList: "/fan/listAllPlan",
  creatorPlanList: "/subscription/getCreatorPlans",
  creatorProfile: "/creatorProfile",
  buySubscription: "/subscription/subscribePlanNew",
  suggestedCreators: "/fan/suggestedCreator",
  subscribedCreators: "/fan/subscribeCreators",
  feedsBySubscriptionPlan: "fan/creatorSubscribedPlan",
  allSubscriptions: "fan/allPlans",

  getCurrencyStatus: "/currency/getalltoken",
  updateCurrencyStatus: "/currency/updatetokenstatus",
  updateAvailableCurreny: "/configuration/updateConfiguration",
  getAvaiableCurrency: "/configuration/getSingleConfiguration",
  getAllLevels: "/level/getalllevels",
  getAccountBalance: "/wallet/account",
  getEnabledCurrency: "/configuration/getSingleUserConfiguration",
  getMfaList: "/user/getMfaList",
  getCountries: "/utils/getAllCountries",
  publisherRewards: "/publisher/rewardHistory",
  trendsRewards: "/creator/trend/rewardList",
  publisherDashboardBudget: "/publisherAnalytics/campaignAnalytics",
  publisherDashboardCampaignInsight: "/publisherAnalytics/campaignInsight",
  publisherDashboardYourCampaignView: "/publisherAnalytics/campaignViews",
  publisherDashboardContributors: "/topParticaipants",
  publisherDashboardContributorsVideo: "/campaign",
  publisherDashboardContributorsIdea: "/ideaCampaign",
  publisherDashboardBiggestContributors: "/publisherAnalytics/biggestContributors",

  adminResetUserPassword: "/admin/resetPassword",
  adminChangeUserEmail: "/admin/changeEmail",
  adminRenameUser: "/admin/renameUser",
  adminResetMfa: "/admin/resetMfa",
  adminUpdateUserCustomization: "/admin/updateUserCustomizations",

  // content links uutils
  getUnShortenedUrl: "/utils/unshortUrl",
  getFaviconAsBase64FromUrl: "/utils/fetchFavicon",

  competitions: "/competitions",
  competitionsRefresh: "/competitions/refresh",

  // ari
  calculateAriUsdt: "/currencies/USDT/calculate",
};

// todo check type
export const updateDefaultLandingPagesAPI = ({ data, id }: { data: any; id: string }) =>
  Promise.resolve(
    getAxiosInst().put(endpoints.updateDefaultLandingPages, data, {
      params: {
        id,
      },
    })
  );

export const calculateAriUsdtAPI = ({
  amount,
  currency,
  callback = (data) => {},
}: {
  amount: number;
  currency: string;
  callback: (data: number) => void;
}) =>
  apiHandler(
    () =>
      getAriInst().post(endpoints.calculateAriUsdt, {
        offeredAmount: amount,
        offeredCurrencyCode: currency,
      }),
    {
      onSuccess: (data) => {
        callback(data.amount);
      },
    }
  );

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const updateMonetizationLimitAPI = ({ data, id }) =>
  Promise.resolve(
    getAxiosInst().put(endpoints.updateMonetizationLimit, data, {
      params: {
        id,
      },
    })
  );

export const sessionExpired = (err: AxiosError) => {
  if (err.response?.data?.message.toLowerCase() === MESSAGES.jwtExpired.toLowerCase()) {
    clearSlicesData();
    store.dispatch(logout());
    store.dispatch(emitUserLogoutEvent({ randomId: Math.random() }));
  }
};

export interface ApiActions<SuccessReturnType = any, ErrorReturnType = AxiosError> {
  onSuccess?: (resp: SuccessReturnType) => void;
  onError?: (resp: ErrorReturnType) => void;
  final?: () => void;
}

export const apiHandler = async <C extends () => Promise<AxiosResponse>, R = any>(
  apiCall: C,
  {
    onSuccess,
    onError,
    final,
  }: ApiActions<
    Awaited<ReturnType<C>>["data"],
    AxiosError<{ message: string; error?: string }>
  > = {},
  options = { sync: false }
): Promise<void> => {
  let response;
  try {
    response = await apiCall();

    const data = response?.data;
    if (response?.status === 200 || response?.status === 201) {
      if (onSuccess && options.sync) onSuccess(data);
      else if (onSuccess) await onSuccess(data);
    } else {
      const regularError = new Error("Some Error");
      const axiosError = {
        ...regularError,
        code: response?.status as unknown as string,
        isAxiosError: true,
      } as AxiosError;

      throw axiosError;
    }
  } catch (error: unknown) {
    const typedError = error as AxiosError;

    typedError.message?.includes(STRINGS.networkErr) &&
      makeToast({
        message: MESSAGES.networkErr,
        type: STRINGS.error,
      });
    sessionExpired(typedError);
    if (Axios.isCancel(typedError)) {
      console.error("Request canceled", typedError.message);
    } else {
      onError && onError(typedError);
    }
  } finally {
    final && final();
  }
};

export const getAllDefaultLandingPageAPI = () => {
  apiHandler(
    () =>
      getAxiosInst().get<GetDefaultRouteApiResp>(endpoints.getAllDefaultLandingPages, {
        params: {
          sort: 1,
        },
      }),
    {
      onSuccess: (data) => {
        store.dispatch(setDefaultLandingPages(data.data));
      },
      onError: (error) => {
        console.log("catch error ===>>>", error.response);
      },
    }
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const updateCustomizePlatfomsAPI = ({ data, id }) =>
  Promise.resolve(
    getAxiosInst().put(endpoints.updateCustomizePlatforms, data, {
      params: {
        id,
      },
    })
  );

export const getCustomizePlatformsAPI = () => {
  apiHandler(
    () =>
      getAxiosInst().get(endpoints.getCustomizePlatforms, {
        params: {
          sort: 1,
        },
      }),
    {
      onSuccess: (data) => {
        store.dispatch(setCustomizePlatforms(data.data));
      },
      onError: (error) => {
        console.log("catch error ===>>>", error.response);
      },
    }
  );
};

export const getCustomizeLevelAPI = () =>
  getAxiosInst().get(endpoints.getAllLevels, {
    params: {
      sort: 1,
    },
  });

export const getCustomizeLevel = () => {
  apiHandler(getCustomizeLevelAPI, {
    onSuccess: (data) => {
      store.dispatch(setUserLevel(data.data));
    },
    onError: (error) => {
      console.log("catch error ===>>>", error.response);
    },
  });
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const updateSocialLinkAPI = ({ data }) => {
  return getAxiosInst().post(endpoints.updateSocialLink, data);
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const fetchNewlyUsers = ({ limit, skip, apiData, page }, callback = () => {}) => {
  apiHandler(
    () =>
      getAxiosInst().post<{ result: IUser; Totaluser: number }>(endpoints.getNewUsers, apiData, {
        params: {
          limit,
          skip,
        },
      }),
    {
      onSuccess: (data) => {
        store.dispatch(
          setNewPeoples({
            data: data.result,
            totalUser: data.Totaluser,
            page,
          })
        );
        callback();
      },
      onError: (error) => {
        callback();
        console.log("catch error ===>>>", error.response);
        console.log(
          error?.response?.data?.message,
          error?.response?.data?.message.includes(MESSAGES.jwtExpired)
        );
      },
    }
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const recoverNewCodeAPI = ({ data }) => {
  return getAxiosInst().post(endpoints.twoFactorAuthRecovery, data);
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const sendVerificationEmailAPI = ({ data }, callback) => {
  store.dispatch(setLoader({ name: "passwordLoader", value: true }));

  apiHandler(
    () =>
      getAxiosInst().post<{ data: { msg: string; role: UserRole } }>(
        endpoints.sendVerificationEmail,
        data
      ),
    {
      onSuccess: (res) => {
        makeToast({
          message: res?.data?.msg || "Verification code has been sent",
          type: STRINGS.toastType.success,
        });
        store.dispatch(setforgotPwdUserType(res?.data?.role));
        store.dispatch(startTimer());
        callback();
      },
      onError: (error) => {
        if (error.response?.status === 400 || error.response?.status === 404) {
          makeToast({
            message: "This email is not registered",
            type: STRINGS.toastType.error,
          });
        }
      },
      final: () => {
        store.dispatch(setLoader({ name: "passwordLoader", value: false }));
      },
    }
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const resetPasswordAPI = ({ data }, callback) => {
  store.dispatch(setLoader({ name: "passwordLoader", value: true }));

  apiHandler(() => getAxiosInst().post(endpoints.resetPassword, data), {
    onSuccess: () => {
      makeToast({
        message: "Password changed successfully",
        type: STRINGS.toastType.success,
      });
      callback();
    },
    onError: (error) => {
      if (error.response?.status === 400) {
        makeToast({
          message: error.response?.data.message,
          type: STRINGS.toastType.error,
        });
      }

      store.dispatch(stopTimer());
    },
    final: () => {
      store.dispatch(setLoader({ name: "passwordLoader", value: false }));
    },
  });
};

export const fetchUserDetailsAPI = (callback = (res: GetUserDetailsApiResp) => {}) => {
  apiHandler(() => getCreator(), {
    onSuccess: (res) => {
      store.dispatch(setUser(res.user));
      store.dispatch(setUserId(res.user._id));
      setLocalStorageItem({
        key: STORAGES.userId,
        value: res.user._id,
      });
      callback(res);
    },
    onError: (error) => {
      store.dispatch(setUser(STRINGS.noData));
      console.log("catch error ===>>>", error, error.response);
    },
  });
};
