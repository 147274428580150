const Unread = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 7.77344L12.9867 12.282C12.3911 12.6677 11.6089 12.6677 11.0133 12.282L4 7.77344"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.2222 6H5.77778C4.79594 6 4 6.76751 4 7.71429V16.2857C4 17.2325 4.79594 18 5.77778 18H18.2222C19.2041 18 20 17.2325 20 16.2857V7.71429C20 6.76751 19.2041 6 18.2222 6Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Unread;
