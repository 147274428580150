import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const SocialExpandedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .preview_cards {
    display: grid;
    gap: 16px 12px;
    grid-template-columns: repeat(2, minmax(190px, 1fr));
  }

  ${deviceQuery.mobileM} {
    .preview_cards {
      grid-template-columns: repeat(1, minmax(100%, 1fr));
    }
  }

  .youtube_card {
    width: 100%;
    height: 200px;
  }
`;
