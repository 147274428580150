const Play = () => (
  <svg width="20" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 1.80421C0.5 1.01878 1.36394 0.539941 2.02999 0.956206L15.1432 9.15166C15.7699 9.54332 15.7699 10.456 15.1432 10.8477L2.03001 19.0437C1.36396 19.46 0.5 18.9812 0.5 18.1957V1.80421Z"
      fill="white"
    />
  </svg>
);

export default Play;
