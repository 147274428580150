import styled, { css, keyframes } from "styled-components";

import { deviceQuery } from "./mediaSizes";

export const GreenDot = css`
  position: absolute;
  content: "";
  bottom: 5px;
  right: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(p) => p.theme.colors.green.primary};
`;
export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const flexBetween = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const OutfitRegular = css`
  font-family: "Outfit-Regular", sans-serif !important;
`;
export const OutfitBold = css`
  font-family: "Outfit-Bold", sans-serif !important;
`;
export const OutfitSemibold = css`
  font-family: "Outfit-SemiBold", sans-serif !important;
`;
export const OutfitMedium = css`
  font-family: "Outfit-Medium", sans-serif !important;
`;
export const OutfitLight = css`
  font-family: "Outfit-Light", sans-serif !important;
`;
export const OutfitThin = css`
  font-family: "Outfit-Thin", sans-serif !important;
`;
export const OutfitExtraBold = css`
  font-family: "Outfit-ExtraBold", sans-serif !important;
`;
export const OutfitBlack = css`
  font-family: "Outfit-Black", sans-serif !important;
`;
export const OutfitExtraLight = css`
  font-family: "Outfit-ExtraLight", sans-serif !important;
`;

export const Btn = css`
  border: none;
  outline: none;
  border-radius: 30px;
  font-family: ${(p) => p.theme.ff.outfit.medium};
  position: relative;
`;

export const textGradient90deg = css`
  color: unset;
  background: linear-gradient(#1ac6b9, #25c374);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
`;

export const textGradient = css`
  color: unset;
  background: linear-gradient(45deg, #1ac6b9, #25c374);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
`;

export const HideScrollBar = css`
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ShowScrollBar = css`
  &::-webkit-scrollbar {
    display: block;
  }
`;

export const rotate = keyframes`
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
`;
export const rightToLeftSlide = keyframes`
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0%);
    }
`;
export const hidePhoneInputArrows = css`
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;
export const ErrorMsg = styled.p`
  ${OutfitRegular};
  text-align: right;
  padding: 4px 1rem 0 0;
  color: ${(p) => p.theme.colors.red.primary};
  &.height {
    height: 28px;
  }
`;

export const ScreenViewWrapper = styled.section`
  > :last-child {
    display: none;
  }

  ${deviceQuery.tabletM} {
    > :first-child {
      display: none;
    }
    > :last-child {
      display: block;
    }
  }
`;

export const CommonWrapper = styled.main<{ mwAuto?: boolean }>`
  padding: 2rem 2rem;
  margin: 0 auto;
  max-width: ${(p) => (p.mwAuto ? "auto" : "1300px")};

  ${deviceQuery.laptopL} {
    padding: 3rem;
  }

  ${deviceQuery.laptopM} {
    padding: 2rem;
  }

  ${deviceQuery.mobileSM} {
    padding: 1rem;
  }
`;

export const GoBackContainter = styled.div<{ gradientTheme?: string }>`
  padding: 20px;

  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 8px;

  ${(p) =>
    p.gradientTheme === "dark"
      ? css`
          border-left: 1px solid #ffffff33;
        `
      : css`
          border-left: 1px solid ${p.theme.colors.divider};
          border-bottom: 1px solid ${p.theme.colors.divider};
        `}

  ${deviceQuery.laptopM} {
    border-left: none;
  }
`;

export const NewCommonWrapper = styled.main<{
  withoutPadding?: boolean;
  withoutFilter?: boolean;
  isLogin?: boolean;
  relative?: boolean;
  gradientTheme?: string;
}>`
  margin: 0 auto;
  width: 100%;
  height: 100%;

  ${(p) =>
    p.relative &&
    css`
      position: relative;
      z-index: 2;
    `}

  .topic {
    .number {
      align-items: flex-end;
      min-width: 27px;
      display: inline-flex;
      padding: 4px 8px;
      border-radius: 16px;
      justify-content: center;
      font-size: ${(p) => p.theme.typography.subText3};
      color: ${(p) => p.theme.colors.text.secondary};
      background: ${(p) => p.theme.colors.background.secondary};

      .green {
        color: ${(p) => p.theme.colors.green.primary};
      }
    }
  }
`;

// the green message with left border
export const Notify = styled.p`
  color: ${(p) => p.theme.colors.green.primary};
  padding-left: 12px;
  font-size: ${(p) => p.theme.typography.subText5};
  border-left: 3px solid ${(p) => p.theme.colors.green.primary};
  ${OutfitLight};
  ${textGradient90deg};
  text-align: left;

  ${deviceQuery.tabletM} {
    padding: 0px 30px;
  }

  ${deviceQuery.mobileL} {
    text-align: center;
    border: none;
  }
`;

export const TableBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  width: 100%;
  gap: 16px;
`;

// heading above the tables and cards
export const CommonSubHeading = styled.h3`
  display: inline-flex;
  font-size: ${(p) => p.theme.typography.text3};
  align-items: center;
  position: relative;
  white-space: nowrap;
  overflow-x: auto;
  gap: 4px;

  .green {
    color: ${(p) => p.theme.colors.green.primary};
  }

  ${HideScrollBar};

  ${deviceQuery.mobileL} {
    font-size: 1.3rem;
    text-align: center;
  }
`;

export const MainHeading = styled.h2`
  ${OutfitExtraLight};
  font-size: 1.7rem;
  font-weight: 600;
  margin-bottom: 0.7rem;
  padding-left: 5px;
  position: relative;
  white-space: nowrap;
  overflow-x: auto;

  .green {
    color: ${(p) => p.theme.colors.green.primary};
  }
  ${HideScrollBar};

  ${deviceQuery.mobileL} {
    font-size: 1.4rem;
    text-align: center;
  }
`;

// container for violet cards with border radius
export const CommonVioletCard = styled.div<{
  padding?: string;
  mh?: string;
  flexCol?: boolean;
  scrollable?: boolean;
  center?: boolean;
  hideScroll?: boolean;
}>`
  padding: ${(p) => (p.padding ? p.padding : "1rem")};
  border-radius: 1.5rem;
  background: ${(p) => p.theme.colors.background.secondary};
  max-height: ${(p) => (p.mh ? p.mh : "100%")};

  ${(p) =>
    p.flexCol &&
    css`
      display: flex;
      flex-direction: column;
    `}

  ${(p) =>
    p.scrollable &&
    css`
      overflow-y: scroll;
    `}

  ${(p) =>
    p.center &&
    css`
      ${flexCenter};
    `}

  ${(p) =>
    p.hideScroll &&
    css`
      ${HideScrollBar};
    `}
`;

// styling like errors message for formik
export const FormikErrorMsg = styled.span`
  display: block;
  color: ${(p) => p.theme.colors.red.primary};
  font-size: 0.8rem;
  height: 19px;
`;

// mobile view filter green btn
export const MobileFiterBtn = styled.button`
  border: none;
  outline: none;
  background: transparent;
  color: ${(p) => p.theme.colors.text.third};
  ${OutfitRegular};
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 40%;
    right: -10px;
    transform: translateY(-50%) rotate(135deg);
    border: 4px solid ${(p) => p.theme.colors.text.third};
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
`;

export const ellipsisText = css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const oneLineText = css`
  ${ellipsisText};
  -webkit-line-clamp: 1;
`;

export const twoLineText = css`
  ${ellipsisText};
  -webkit-line-clamp: 2;
`;

export const threeLineText = css`
  ${ellipsisText};
  -webkit-line-clamp: 3;
`;

export const FilterBrn = styled.button<{
  full?: boolean;
  wide?: boolean;
  size?: string;
  maxWidth?: string;
  reverse?: boolean;
}>`
  ${Btn};
  width: ${(p) => (p.full ? "100%" : p.wide && "fit-content")};
  background: ${(p) =>
    p.reverse
      ? "transparent"
      : p.disabled
      ? p.theme.colors.text.secondary
      : p.theme.colors.green.primary};
  color: ${(p) => p.theme.colors.text.primary};
  transition: 0.1s linear;
  padding: ${(p) => (p.wide ? "10px 100px" : "10px 30px")};
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  white-space: nowrap;
  border-radius: 5px !important;
  margin: 0px 5px;
  justify-content: center;
  display: flex;
  max-width: ${(p) => p.maxWidth ?? "100%"}
    ${(p) =>
      p.size === "md" &&
      css`
        padding: 10px 80px;
      `};

  ${(p) =>
    p.size === "sm" &&
    css`
      padding: 5px 20px;

      ${deviceQuery.mobileM} {
        padding: 3px 15px;
        font-size: 0.9rem;
      }
    `};

  ${(p) =>
    p.reverse &&
    css`
      box-shadow: inset 0 0 0 2px ${(p) => p.theme.colors.green.primary}};
    `};
  ${deviceQuery.laptopM} {
    width: ${(p) => p.full && "100%"};
  }
`;

export const CampaignFilterBrn = styled.button<{
  full?: boolean;
  wide?: boolean;
  size?: string;
  maxWidth?: string;
  reverse?: boolean;
}>`
  ${Btn};
  width: ${(p) => (p.full ? "100%" : p.wide && "fit-content")};
  background: ${(p) =>
    p.reverse
      ? "transparent"
      : p.disabled
      ? p.theme.colors.field.empty
      : p.theme.colors.green.primary};
  color: ${(p) => p.theme.colors.text.primary};
  transition: 0.1s linear;
  padding: ${(p) => (p.wide ? "10px 100px" : "10px 20px")};
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  white-space: nowrap;
  border-radius: 5px !important;
  margin: 0px 5px;
  min-width: 110px;

  ${(p) =>
    p.size === "md" &&
    css`
      padding: 10px 80px;
    `};

  ${(p) =>
    p.size === "sm" &&
    css`
      padding: 5px 20px;

      ${deviceQuery.mobileM} {
        padding: 3px 15px;
        font-size: 0.9rem;
      }
    `};

  ${(p) =>
    p.reverse &&
    css`
      box-shadow: inset 0 0 0 2px ${(p) => p.theme.colors.green.primary}};
    `};
  ${deviceQuery.laptopM} {
    width: ${(p) => p.full && "100%"};
  }
`;

export const CompanyCtgry = styled.div<{ mr?: string }>`
  display: inline-block;
  color: ${(p) => p.theme.colors.text.third};
  border-radius: 10px;
  border: 1px solid ${(p) => p.theme.colors.text.third};
  padding: 10px 30px;
  background-color: transparent;
  margin-right: ${(p) => p.mr ?? "0px"};
  text-align: center;
  border-radius: 200px;

  height: fit-content;
  p {
    color: ${(p) => p.theme.colors.green.primary};
    font-size: 16px;
    word-break: break-all;
  }
  .head {
    color: ${(p) => p.theme.colors.text.secondary};
    font-size: 14px;
  }
  .ideaHead {
    color: ${(p) => p.theme.colors.text.secondary};
    font-size: 14px;
    padding-bottom: 0.7rem;
  }

  .cursorPointer {
    cursor: pointer !important;
  }
`;

export const FilterTab = styled.div`
  display: flex;
  justify-content: space-between;

  ${deviceQuery.tabletM} {
    flex-direction: column;
  }
`;

export const TagWrapper = styled.div<{
  error?: boolean;
  isValue: string[];
  readonly?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .tagSection {
    background: ${(p) => p.theme.colors.background.secondary};
    border-radius: 12px;
    ${(p) =>
      p.readonly
        ? css`
            border: 1px solid ${p.theme.colors.field.empty};
          `
        : css`
            border: 1px solid
              ${p.isValue
                ? p.theme.colors.field.full
                : p.error
                ? p.theme.colors.text.error
                : p.theme.colors.field.empty};
          `}
    position: relative;
    overflow: hidden;
    height: 90px;
  }

  .inputWrapper {
    border: none !important;

    input {
      padding: 12px 16px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      box-shadow: 0 0 0 30px ${(p) => p.theme.colors.dark} inset;
    }
  }

  .tag-list {
    height: 38px;
    overflow-y: scroll;
    padding: 0px 12px;

    .tag-container {
      height: fit-content;

      .list {
        margin-right: 8px;
        margin-bottom: 8px;
        width: fit-content;
        height: fit-content;
      }
    }
  }
`;

export const TagCustomWrapper = styled.div`
  .tagSection {
    border-radius: 3px;
    position: relative;
  }
  .inputWrapper {
    border: none !important;
  }
  .tag-list {
    .tag-container {
      height: fit-content;
      .list {
        margin-right: 8px;
        margin-bottom: 8px;
        width: fit-content;
        height: fit-content;
      }
    }
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-self: flex-end;
  overflow-x: scroll;
  ${HideScrollBar}

  ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    list-style-type: none;
    padding: 0px;
    background: ${(p) => p.theme.colors.background.secondary};
    font-size: ${(p) => p.theme.typography.subText5};
    border-radius: 8px;
    height: 40px;

    li {
      display: flex;
      justify-content: center;
      min-width: 40px;

      &:hover {
        background: ${(p) => p.theme.colors.background.third};
      }

      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 8px 12px;
        cursor: pointer;
        flex-grow: 1;
        text-decoration: none;
        color: ${(p) => p.theme.colors.text.primary};
      }

      &.previous,
      &.next {
        text-align: center;
        max-width: 40px;
      }

      &.previous {
        border-right: 1px solid ${(p) => p.theme.colors.divider};

        &:hover {
          border-radius: 8px 0px 0px 8px;
        }
      }

      &.next {
        border-left: 1px solid ${(p) => p.theme.colors.divider};
        &:hover {
          border-radius: 0px 8px 8px 0px;
        }
      }

      &.active a {
        color: white;
        min-width: 32px;
      }

      &.disabled a {
        color: ${(p) => p.theme.colors.text.secondary};
      }

      &.disable,
      &.disabled a {
        color: ${(p) => p.theme.colors.text.secondary};
        cursor: default;
      }

      &.selected {
        cursor: default;
        border-color: transparent;
        box-shadow: inset 0 0 0 1px ${(p) => p.theme.colors.green.primary20};
        color: ${(p) => p.theme.colors.green.primary};
      }
    }
  }

  ${deviceQuery.mobileL} {
    width: 100%;
    justify-content: center;
    flex-wrap: nowrap;

    &.top_pagination {
      display: none;
    }
  }

  ${deviceQuery.mobileMS} {
    justify-content: flex-start;
  }
`;

export const SideList = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: 20px;
  ${HideScrollBar}

  ${deviceQuery.tabletM} {
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: scroll;
    padding-bottom: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const PageWithContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  gap: 20px;

  .actions {
    display: flex;
    gap: 16px;
    align-items: center;
    flex-direction: row-reverse;
    flex-wrap: wrap;

    .selectContainer {
      border-radius: 8px;
      background: ${(p) => p.theme.colors.background.secondary};
      display: flex;
      gap: 8px;
      padding: 8px 12px;

      .show {
        color: ${(p) => p.theme.colors.text.secondary};
      }
    }
  }

  ${deviceQuery.tabletM} {
    .actions {
      width: 100%;
    }
  }

  ${deviceQuery.mobileL} {
    .actions {
      width: unset;

      .selectContainer {
        .show {
          display: none;
        }
      }

      .bottom_select_container {
        display: none;
      }
    }
  }

  ${deviceQuery.mobileS} {
    .actions {
      width: 100%;
    }
  }
`;

export const PageWithTableTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  .topic {
    display: flex;
    gap: 12px;

    .title {
      font-size: ${(p) => p.theme.typography.text3};
    }
  }
`;

export const InputFieldWrapper = styled.div<{ withoutLabel?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(p) => (p.withoutLabel ? "-23px" : "0px")};
`;

export const InputFieldText = styled.div<{ error?: boolean; textarea?: string }>`
  background: ${(p) => p.theme.colors.background.secondary};
  border-radius: 3px;
  border: 1px solid ${(p) => (p.error ? p.theme.colors.red.primary : p.theme.colors.field.full)};
  position: relative;
  overflow: hidden;
  height: ${(p) => (p.textarea === "true" ? "85%" : "47px")};
  min-height: ${(p) => (p.textarea === "true" ? "120px" : "unset")};
  width: 25%;
  color: ${(p) => p.theme.colors.icon.secondary};
  text-align: center;
  padding-top: 10px;
`;

export const HeaderContainer = styled.section`
  display: flex;
  gap: 16px;
  flex: 1;
  position: relative;

  ${deviceQuery.desktopS} {
    max-width: 1036px;
    width: 100%;
  }

  ${deviceQuery.laptopM} {
    border-left: none;
  }
`;

export const BlockCard = styled.div<{
  borderRadius?: number;
  padding?: number;
  paddingSmall?: number;
  borderColor?: string;
  hideOn?: string;
  full?: string;
}>`
  display: flex;
  background: ${(p) => p.theme.colors.background.secondary};
  border-radius: ${(p) => (p.borderRadius ? p.borderRadius : 16)}px;
  border: ${(p) => (p.borderColor ? `1px solid ${p.borderColor}` : "unset")};
  padding: ${(p) => (typeof p.padding === "number" ? p.padding : "16")}px;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);

  & > * {
    flex: 1;
  }

  ${(p) =>
    p.hideOn &&
    css`
      ${p.hideOn} {
        background: none;
        padding: 0px;
      }
    `}

  ${(p) =>
    p.full &&
    css`
      flex: 1;
    `}

  ${deviceQuery.mobileM} {
    padding: ${(p) => (typeof p.paddingSmall === "number" ? p.paddingSmall : "12")}px;
  }
`;
