const English = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.39 19.08C8.38 18.43 7.59 15.88 7.49 12.73H19V12C19 7.04 14.96 3 10 3C5.04 3 1 7.04 1 12C1 16.96 4.71 20.65 9.39 20.97V19.08ZM17.5 11.27H13.97C13.9 8.92 13.47 6.46 12.53 4.91C15.23 5.87 17.22 8.33 17.5 11.27ZM9.9 4.75C9.9 4.75 9.97 4.73 10 4.73C10.03 4.73 10.06 4.75 10.1 4.75C11.35 4.75 12.39 7.61 12.51 11.27H7.49C7.61 7.62 8.65 4.75 9.9 4.75ZM2.5 12.72H6.03C6.1 15.07 6.53 17.53 7.47 19.08C4.77 18.12 2.78 15.66 2.5 12.72ZM6.85 6.27C6.38 7.62 6.11 9.38 6.05 11.27H2.5C2.79 8.31 4.79 5.86 7.49 4.9C7.26 5.27 7.04 5.72 6.85 6.28V6.27Z"
      fill="currentColor"
    />
    <path
      d="M22.88 14H21.71C21.64 14 21.59 14.05 21.59 14.12V18.44L18.04 14.05C18.04 14.05 17.98 14 17.94 14H17.13C17.06 14 17.01 14.05 17.01 14.12V20.88C17.01 20.95 17.07 21 17.13 21H18.3C18.37 21 18.42 20.95 18.42 20.88V16.56L21.97 20.95C21.97 20.95 22.03 21 22.07 21H22.88C22.95 21 23 20.95 23 20.88V14.12C23 14.05 22.94 14 22.88 14Z"
      fill="currentColor"
    />
    <path
      d="M15.92 19.75H12.42V18.04H15.58C15.65 18.04 15.7 17.99 15.7 17.92V16.94C15.7 16.87 15.64 16.82 15.58 16.82H12.42V15.25H15.87C15.94 15.25 15.99 15.2 15.99 15.13V14.12C15.99 14.05 15.93 14 15.87 14H11.13C11.06 14 11.01 14.05 11.01 14.12V20.88C11.01 20.95 11.07 21 11.13 21H15.92C15.99 21 16.04 20.95 16.04 20.88V19.87C16.04 19.8 15.98 19.75 15.92 19.75Z"
      fill="currentColor"
    />
  </svg>
);

export default English;
