import { Btn } from "./style";

import Telegram from "assets/svg/Telegram";
import TikTok from "assets/svg/TikTok";
import Twitter from "assets/svg/Twitter";
import Youtube from "assets/svg/Youtube";

// Component for display links for LEWK's account in other social networks
const SocialIcons = () => {
  const goToSocialMedia = (media: string) => {
    let url = "";
    if (media === "tiktok") {
      url = "https://tiktok.com/@lewkcom";
    } else if (media === "twitter") {
      url = "https://twitter.com/lewkcom";
    } else if (media === "telegram") {
      url = "https://t.me/lewkcom";
    } else if (media === "youtube") {
      url = "https://youtube.com/lewkcom";
    }
    window.open(url, "_blank");
  };

  return (
    <>
      <Btn type="button" onClick={() => goToSocialMedia("tiktok")}>
        <TikTok width={24} height={24} />
      </Btn>

      <Btn type="button" onClick={() => goToSocialMedia("twitter")}>
        <Twitter width={24} height={24} />
      </Btn>

      <Btn type="button" onClick={() => goToSocialMedia("telegram")}>
        <Telegram width={24} height={24} />
      </Btn>

      <Btn type="button" onClick={() => goToSocialMedia("youtube")}>
        <Youtube width={24} height={24} />
      </Btn>
    </>
  );
};

export default SocialIcons;
