import { Formik } from "formik";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import { Info, MFAButton } from "./style";

import { ProfileForm, SaveButton, SubPageContainer } from "../../style";

import CheckboxField from "Components/CheckboxField";
import { STRINGS } from "constants/appConstants";
import { skipMFAApi, updateProfileInfoAPI } from "services/UserServices";
import { setIsProfileUpdated } from "store/slices/global/globalSlice";
import { getUser } from "store/slices/global/userSlice";
import { BlockCard } from "styles/mixins";
import { IMfa } from "types/user";

const MFA = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userDetails = useSelector(getUser);
  const [skip, setSkip] = useState(false);

  const isDisabled = useCallback((dirty: boolean) => {
    dispatch(setIsProfileUpdated(!dirty));
    return dirty;
  }, []);

  const onRedirectMFA = () => {
    navigate("/authentication", {
      state: { page: STRINGS.loggedin, afterFirstEnable: true },
    });
  };

  const onSetMfa = (skip: boolean) => {
    if (!userDetails) return;
    skipMFAApi({ skip, _id: userDetails._id });
  };

  const handleSubmit = (values: IMfa) => {
    updateProfileInfoAPI({
      data: {
        withdraw: values.withdraw,
        gifting: values.gifting,
        reward: values.reward,
      },
      part: "mfa_settings",
    });
  };

  useEffect(() => {
    if (!userDetails) return;
    setSkip(userDetails.mfaSkip);
  }, [userDetails]);

  if (!userDetails) return null;
  return (
    <SubPageContainer>
      <p className="title">{t("creator.profile.mfa")}</p>
      <Info>
        <p className="title">{`${t("common.set")} ${t("auth.2FA")}`}</p>
        <span className="description">{t("creator.profile.mfa_description")}</span>
        <MFAButton
          size="sm"
          variant={
            !userDetails.twoFactorAuthEnabled
              ? "primary"
              : !userDetails.mfaSkip
              ? "secondary"
              : "primary"
          }
          onClick={() =>
            !userDetails.twoFactorAuthEnabled
              ? onRedirectMFA()
              : !userDetails.mfaSkip
              ? onSetMfa(true)
              : onSetMfa(false)
          }
        >
          {!userDetails.twoFactorAuthEnabled
            ? t("common.set")
            : !userDetails.mfaSkip
            ? t("creator.profile.disable")
            : t("common.enable")}
        </MFAButton>
      </Info>
      <Formik
        enableReinitialize
        initialValues={{
          withdraw: !!userDetails.mfaSettings?.withdraw,
          gifting: !!userDetails.mfaSettings?.gifting,
          reward: !!userDetails.mfaSettings?.reward,
        }}
        onSubmit={handleSubmit}
      >
        {({ values, dirty, handleChange, handleSubmit }) => (
          <ProfileForm onSubmit={handleSubmit}>
            <BlockCard className="card">
              <p className="title">{t("creator.profile.two")}</p>

              <div className="boxes">
                <CheckboxField
                  name="withdraw"
                  handleChange={handleChange}
                  value={values.withdraw}
                  errMsg={false}
                  label={t("common.withdraw")}
                />
                <CheckboxField
                  handleChange={handleChange}
                  value={values.gifting}
                  name="gifting"
                  errMsg={false}
                  label={t("creator.gifting.gifting")}
                />
                <CheckboxField
                  name="reward"
                  value={values.reward}
                  handleChange={handleChange}
                  errMsg={false}
                  label={t("common.reward")}
                />
              </div>
              <div className="saveContainer">
                <SaveButton disabled={isDisabled(!dirty)} variant="primary" type="submit">
                  {t("creator.profile.update")}
                </SaveButton>
              </div>
            </BlockCard>
          </ProfileForm>
        )}
      </Formik>
    </SubPageContainer>
  );
});

export default MFA;
