import styled from "styled-components";

import { ProfileForm } from "pages/creator/EditProfile/style";
import { deviceQuery } from "styles/mediaSizes";

export const BioPageContainer = styled.div`
  display: flex;
  justify-content: center;

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 1036px;
    max-width: 1036px;

    .pageTitle {
      font-size: ${(p) => p.theme.typography.text3};
    }
  }
`;

export const SocialsForm = styled(ProfileForm)`
  gap: 32px;
  width: 100%;
  .socials {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .draggable_list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .desctop {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .title {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
    }

    .dragHere {
      color: ${(p) => p.theme.colors.text.secondary};
      font-size: ${(p) => p.theme.typography.subText5};
    }

    .block {
      display: flex;
      border: 1px solid ${(p) => p.theme.colors.divider};
      border-radius: 16px;
    }
  }

  .sensitiveBlock {
    display: none;
  }

  ${deviceQuery.desktopS} {
    gap: 24px;

    .sensitiveBlock {
      gap: 12px;
      align-items: center;
      padding-bottom: 24px;
      display: flex;
      border-bottom: 1px solid ${(p) => p.theme.colors.divider};
    }
  }

  ${deviceQuery.desktopS} {
    padding-bottom: 0;
  }
`;
