import styled from "styled-components";

import { flexCenter } from "styles/mixins";

export const Label = styled.label<{ reverse: boolean; fontSize?: string }>`
  display: flex;
  gap: 16px;
  flex-direction: ${(p) => (p.reverse ? "row-reverse" : "row")};
  align-items: center;
  position: relative;
  color: ${(p) => (p.color ? p.color : p.theme.colors.text.primary)};
  font-size: ${(p) => (p.fontSize ? p.fontSize : p.theme.typography.subText5)};
  font-family: ${(p) => p.theme.ff.outfit.regular};
  text-align: start;

  & > div {
    position: relative;
  }
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  height: 24px;
  width: 24px;
  z-index: 10;
  cursor: pointer;
`;

export const Checkmark = styled.div<{ checked: boolean; disabled: boolean }>`
  height: 24px;
  width: 24px;
  background-color: ${(p) =>
    p.disabled
      ? p.theme.colors.button.primary.inactive
      : p.checked
      ? p.theme.colors.green.primary
      : "transparent"};

  border: 1px solid
    ${(p) =>
      p.disabled ? "none" : p.checked ? p.theme.colors.green.primary : p.theme.colors.field.empty};
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  ${flexCenter};
`;

export const ErrorMsg = styled.p`
  color: ${(p) => p.theme.colors.red.primary};
  font-size: 0.8rem;
  height: 19px;
  text-align: left;
`;
