import { ChangeEventHandler, FC, FocusEventHandler, memo } from "react";

import { Checkmark, ErrorMsg, Input, Label } from "./style";

import Right from "assets/svg/Right";
import { colors } from "styles/theme";

interface Props {
  color: string;
  name: string;
  fontSize?: string;
  value: boolean;
  disabled: boolean;
  reverse: boolean;
  errMsg: boolean;
  error: string;
  label: string;
  className: string;
  handleBlur: FocusEventHandler;
  handleChange: ChangeEventHandler;
}

// Main checkbox component for project
const CheckboxField: FC<Partial<Props>> = memo(
  ({
    disabled,
    className,
    color,
    fontSize,
    name,
    reverse = false,
    value,
    errMsg,
    error,
    label,
    handleBlur,
    handleChange,
  }) => (
    <>
      <Label className={className} color={color} reverse={reverse} fontSize={fontSize}>
        <div>
          <Input
            type="checkbox"
            name={name}
            onBlur={handleBlur}
            onChange={handleChange}
            checked={value}
            disabled={disabled}
          />
          <Checkmark checked={value!} disabled={Boolean(disabled)}>
            <Right className={value ? "d-block" : "d-none"} color={color || colors.white.primary} />
          </Checkmark>
        </div>
        {label}
      </Label>
      {errMsg && <ErrorMsg>{error}</ErrorMsg>}
    </>
  )
);

export default CheckboxField;
