import { useFormikContext } from "formik";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import CurrencySelector from "../CurrencySelector";

import Send from "assets/svg/Send";
import Button from "Components/Button";
import Input from "Components/Input";
import { AddContentModalForm } from "pages/public/chat/Components/Chat/AddContentModal";
import { ContentPriceBlockStyles } from "pages/public/chat/Components/Chat/AddContentModal/ContentPrice/styles";

interface Props {
  sendContent: () => void;
}

export const ContentPrice: FC<Props> = ({ sendContent }) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<AddContentModalForm>();
  const [show, setShow] = useState(false);

  return (
    <ContentPriceBlockStyles>
      <div className={`overlay ${show ? "show" : ""}`} onClick={() => setShow(false)} />
      <span className="price">{t("new_chat.add_content_modal.price")}</span>
      <div className="price-input-wr">
        <Input
          className="price_input"
          value={values.price}
          type="number"
          placeholder={t("common.free")}
          handleBlur={(event) => {
            if (!event.target.value) setFieldValue("price", 0);
          }}
          handleChange={(event) => {
            const reg = /^\d*\.?\d{0,2}$/;
            const newText = event.target.value;

            if (+newText < 0 || !reg.test(newText)) return;
            setFieldValue("price", newText);
          }}
        />
        <CurrencySelector show={show} setShow={setShow} />
      </div>
      <Button className="send_message_btn" variant="secondary" onClick={sendContent}>
        <Send />
      </Button>
    </ContentPriceBlockStyles>
  );
};
