import styled from "styled-components";

export const TabPanelContainer = styled.div`
  width: 100%;
  height: 100%;
  display: none;

  &.isShow {
    display: block;
  }
`;
