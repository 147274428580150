import { FormikErrors } from "formik";
import { FC, memo, ReactNode } from "react";

import { ContentInfoItemStyles } from "pages/public/chat/Components/Chat/ContentInfoitem/styles";
import { ContentItem } from "types/new-messages.types";

interface Props {
  icon: ReactNode;
  contentInfo?: string;
  contentCount?: number;
  duration?: string;
  className?: string;
  error?: string | FormikErrors<ContentItem>;
}

export const ContentInfoItem: FC<Props> = memo(
  ({ icon, contentInfo, duration, className, contentCount, error }) => (
    <ContentInfoItemStyles className={className}>
      <div className="icon_container">
        {icon}
        {contentCount !== undefined && !error && (
          <div className="content_count">
            <span>{contentCount}</span>
          </div>
        )}
      </div>
      {!error && (
        <div className={`info ${duration ? "withDuration" : ""}`}>
          {duration && <div className="duration">{duration}</div>}
          {contentInfo && <div className="content_info_text">{contentInfo}</div>}
        </div>
      )}
      {error && <div className="error">{error as string}</div>}
    </ContentInfoItemStyles>
  )
);
