import { FC } from "react";

interface Props {
  size?: number;
}

const ArrowLeft: FC<Props> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 12.0001C21 12.4205 20.6922 12.7679 20.2928 12.8229L20.1818 12.8304L5.799 12.83L10.9953 18.0813C11.3155 18.4049 11.3166 18.9306 10.9978 19.2555C10.7079 19.551 10.2534 19.5787 9.9326 19.3383L9.84067 19.2581L3.24067 12.5889L3.22449 12.5715C3.20773 12.5535 3.19176 12.5348 3.17661 12.5154L3.24067 12.5889C3.20887 12.5568 3.18022 12.5227 3.15472 12.487C3.1393 12.4645 3.12434 12.4412 3.11054 12.4172C3.07936 12.3636 3.0552 12.3076 3.03737 12.2499C3.03137 12.2296 3.02579 12.2089 3.02099 12.1878C3.0171 12.1717 3.01389 12.1554 3.01117 12.139C3.00845 12.1212 3.00608 12.1035 3.00427 12.0855C3.00223 12.0675 3.00097 12.0491 3.00031 12.0306C3.00018 12.0204 3 12.0103 3 12.0001L3.00024 11.9725C3.00092 11.9521 3.00234 11.9317 3.00449 11.9113L3 12.0001C3 11.9527 3.00392 11.9062 3.01144 11.861C3.0148 11.8404 3.01906 11.8197 3.0241 11.7991C3.02814 11.7828 3.03258 11.7668 3.03748 11.7509C3.04327 11.7321 3.04988 11.7132 3.05718 11.6945C3.06477 11.6751 3.07297 11.6562 3.08183 11.6377C3.08855 11.6235 3.09607 11.6089 3.10404 11.5945C3.11908 11.5675 3.135 11.5421 3.15219 11.5177C3.1549 11.5139 3.15793 11.5097 3.161 11.5055C3.18829 11.4684 3.21657 11.4357 3.24718 11.4054L9.84062 4.74198C10.1608 4.41839 10.6788 4.41948 10.9977 4.74441C11.2876 5.0398 11.313 5.50114 11.0747 5.8257L10.9953 5.91866L5.8 11.169L20.1818 11.1698C20.6337 11.1698 21 11.5415 21 12.0001Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowLeft;
