import { FieldArray, FormikTouched, useFormikContext } from "formik";

import { Dispatch, memo, SetStateAction, useState } from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import { LinksContainer, SaveButton } from "./style";

import { SocialsForm } from "../../../style";
import SensitiveModal from "../SensitiveModal";

import DraggableList from "Components/DraggableList";
import Social from "pages/creator/Bio/BioLink/components/Social";
import { IFormikPublikLinks, IPublicLink } from "types/user";

interface Props {
  setPreviewErrors: Dispatch<SetStateAction<Record<string, boolean>>>;
}

export const BioLinkListForm = memo(({ setPreviewErrors }: Props) => {
  const { t } = useTranslation();
  const [showSensitive, setShowSensitive] = useState(false);

  const { values, touched, dirty, handleSubmit, setFieldValue, isValid, setFieldTouched } =
    useFormikContext<IFormikPublikLinks>();

  const [isDragDisabled, setIsDragDisabled] = useState(false);

  const isDisabled = (
    touched: FormikTouched<IFormikPublikLinks>,
    dirty: boolean,
    isValid: boolean
  ) => {
    if (!isValid) return true;
    if (!dirty) return true;

    return false;
  };

  const renderItem = (
    item: IPublicLink,
    publicLinks: IPublicLink[],
    elementToDragFor: DraggableProvidedDragHandleProps | null | undefined,
    index: number,
    remove: (index: number) => void
  ) => (
    <Social
      item={item}
      elementToDragFor={elementToDragFor}
      socialItemIndex={index}
      socialOpenedEvent={(opened) => {
        setIsDragDisabled(opened);
      }}
      onDelete={() => {
        setFieldValue(
          "publicLinks",
          publicLinks.filter((links) => links._id !== item._id)
        );
      }}
      previewErrorStateChanged={(key: string, hasError: boolean) => {
        setPreviewErrors((prev) => ({ ...prev, [key]: hasError }));
      }}
    />
  );

  return (
    <LinksContainer>
      <div className="formContainer">
        <SocialsForm onSubmit={handleSubmit}>
          <div className="desctop">
            <p className="title">{t("creator.profile.links")}</p>
            <div className="socials">
              {values.publicLinks.length ? (
                <FieldArray name="publicLinks" validateOnChange={false}>
                  {({ remove }) => (
                    <DraggableList<IPublicLink>
                      items={values.publicLinks}
                      trackByKey="_id"
                      className="draggable_list"
                      isDragDisabled={isDragDisabled}
                      renderItem={(item, index, elementToDragFor) =>
                        renderItem(item, values.publicLinks, elementToDragFor, index, remove)
                      }
                      orderChanged={(newList) => {
                        setFieldValue("publicLinks", newList);
                        setFieldTouched("publicLinks", true);
                      }}
                    />
                  )}
                </FieldArray>
              ) : (
                <p>{t("common.no_data_found")}</p>
              )}
            </div>
          </div>
          <div className="saveContainer">
            <SaveButton
              disabled={isDisabled(touched, dirty, isValid)}
              variant="primary"
              type="submit"
            >
              {t("creator.profile.save")}
            </SaveButton>
          </div>
          <SensitiveModal show={showSensitive} closeModal={() => setShowSensitive(false)} />
        </SocialsForm>
      </div>
    </LinksContainer>
  );
});
