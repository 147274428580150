import { FC, ReactNode } from "react";

import { useNavigate } from "react-router-dom";

import { FooterCategoryContainer } from "./style";

interface Props {
  title: string;
  subTitle: string;
  items?: {
    label: string;
    link?: string;
  }[];
  join?: ReactNode;
  className?: string;
}

const FooterCategory: FC<Props> = ({ title, subTitle, items, join, className }) => {
  const navigate = useNavigate();

  return (
    <FooterCategoryContainer className={className}>
      <div className="block">
        <p className="title">{title}</p>
        <p className="subTitle">{subTitle}</p>
      </div>
      <div className="block">
        {items &&
          items.map((item, index) => (
            <p
              key={index}
              className={item.link ? "pointer" : ""}
              onClick={item.link ? () => navigate(item.link!) : () => {}}
            >
              {item.label}
            </p>
          ))}
        {join}
      </div>
    </FooterCategoryContainer>
  );
};

export default FooterCategory;
