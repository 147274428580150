import { Dispatch, FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import YouTube from "react-youtube";

import ArrowDownSmall from "assets/svg/ArrowDownSmall";
import CirclePlay from "assets/svg/CirclePlay";
import Accordion2 from "Components/Accordion2";
import { ContentPreviewCardContainer } from "pages/creator/Bio/BioLink/components/Social/SocialExpanded/ContentPreviewCard/style";
import { YoutubeContentPreviewContainer } from "pages/creator/Bio/BioLink/components/Social/SocialExpanded/YoutubeContentPreviewCard/style";
import {
  CollapsedContentContainer,
  ExpandedContentContainer,
  TutorialAccordion,
} from "pages/creator/Bio/BioLink/components/TutorialBlock/style";
import { getUser } from "store/slices/global/userSlice";

interface CollapsedProps {
  show: boolean;
  setIsTutorialOpened: Dispatch<SetStateAction<boolean>>;
}

export const CollapsedContent: FC<CollapsedProps> = ({ show, setIsTutorialOpened }) => {
  const { t } = useTranslation();

  return (
    <CollapsedContentContainer onClick={() => setIsTutorialOpened((prevState) => !prevState)}>
      <div className="left_block">
        <CirclePlay />
        <p>{t("common.tutorial")}</p>
      </div>

      <div className={`arrow ${show ? "active" : ""}`}>
        <ArrowDownSmall />
      </div>
    </CollapsedContentContainer>
  );
};

export const ExpandedContent = () => {
  const { t } = useTranslation();

  return (
    <ExpandedContentContainer>
      <ContentPreviewCardContainer isValid profile={false}>
        <div className="main_preview_content">
          <YoutubeContentPreviewContainer>
            <YouTube
              videoId=""
              className="w-100 h-100"
              iframeClassName="w-100 h-100 youtube_iframe"
            />
          </YoutubeContentPreviewContainer>
        </div>
        <div className="bottom_content">
          <div className="url">{t("common.howtoAddLink")}</div>
        </div>
      </ContentPreviewCardContainer>
      <ContentPreviewCardContainer isValid profile={false}>
        <div className="main_preview_content">
          <YoutubeContentPreviewContainer>
            <YouTube
              videoId=""
              className="w-100 h-100"
              iframeClassName="w-100 h-100 youtube_iframe"
            />
          </YoutubeContentPreviewContainer>
        </div>
        <div className="bottom_content">
          <div className="url">{t("common.howtoChangeLinkLocation")}</div>
        </div>
      </ContentPreviewCardContainer>
    </ExpandedContentContainer>
  );
};

export const TutorialBlock = () => {
  const [isTutorialOpened, setIsTutorialOpened] = useState<boolean>(false);
  const user = useSelector(getUser);

  if (!user) return null;

  return (
    <TutorialAccordion>
      <Accordion2
        show={isTutorialOpened}
        collapsedContent={
          <CollapsedContent show={isTutorialOpened} setIsTutorialOpened={setIsTutorialOpened} />
        }
        expandedContent={<ExpandedContent />}
      />
    </TutorialAccordion>
  );
};
