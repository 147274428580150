import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store/store";
import {
  getChatsByStatusAndRole,
  getChatsCountByStatus,
  getChatsWithSearchThunk,
} from "store/thunks/messages-thunks";
import {
  ChatStatus,
  GetChatPaginationData,
  NewChatItem,
  TotalChatCount,
} from "types/new-messages.types";

interface ActiveStatusTab {
  tabIndex: number;
  status: ChatStatus;
}

interface ExtraState {
  loading: boolean;
  openedChat: NewChatItem | null;
  total: TotalChatCount;
  unread: number;
  unreadMessages: number;
  currentTab: ActiveStatusTab;
  paginationData: GetChatPaginationData;
  canLoadMore: boolean;
  search: string;
}

export const CHATS_LIMIT = 20;

const chatsAdapter = createEntityAdapter({
  selectId: (chat: NewChatItem) => chat._id,
  sortComparer: (a, b) => {
    const aLastMessageCreatedAt = a.lastMessage.createdAt;
    const bLastMessageCreatedAt = b.lastMessage.createdAt;

    if (aLastMessageCreatedAt > bLastMessageCreatedAt) {
      return -1;
    }
    if (aLastMessageCreatedAt < bLastMessageCreatedAt) {
      return 1;
    }
    return 0;
  },
});

const initialExtraState: ExtraState = {
  loading: false,
  openedChat: null,
  canLoadMore: false,
  search: "",
  unread: 0,
  unreadMessages: 0,
  total: {
    active: 0,
    pending_approval: 0,
    blocked: 0,
  },
  currentTab: {
    tabIndex: 0,
    status: "active",
  },
  paginationData: {
    limit: CHATS_LIMIT,
    offsetId: undefined,
  },
};

const chatsSlice = createSlice({
  name: "chats",
  initialState: chatsAdapter.getInitialState<ExtraState>(initialExtraState),
  reducers: {
    addChat: (state, action: PayloadAction<NewChatItem>) => {
      chatsAdapter.addOne(state, action.payload);
    },
    addChats: chatsAdapter.addMany,
    removeAllChats: chatsAdapter.removeAll,
    removeOneChat: (state, action: PayloadAction<{ id: string }>) => {
      chatsAdapter.removeOne(state, action.payload.id);
    },
    updateOneChat: chatsAdapter.updateOne,
    setOpenedChat: (state, action: PayloadAction<NewChatItem | null>) => {
      state.openedChat = action.payload;
    },
    setChatsTotalCount: (state, action) => {
      state.total = action.payload;
    },
    setCurrentStatusTab: (state, action: PayloadAction<ActiveStatusTab>) => {
      state.currentTab = action.payload;
    },
    setChatsSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setUnreadChats: (state, action: PayloadAction<number>) => {
      state.unread = action.payload;
    },
    setUnreadMessages: (state, action: PayloadAction<number>) => {
      state.unreadMessages = action.payload;
    },
    resetChatSearch: (state) => {
      state.search = "";
    },
    resetChatCounters: (state) => {
      state.total = {
        active: 0,
        pending_approval: 0,
        blocked: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChatsByStatusAndRole.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getChatsByStatusAndRole.fulfilled, (state, { payload }) => {
      state.loading = false;
      const { data } = payload;

      state.canLoadMore = data.length === CHATS_LIMIT;
      chatsAdapter.addMany(state, data);
    });

    builder.addCase(getChatsByStatusAndRole.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getChatsCountByStatus.fulfilled, (state, { payload }) => {
      state.total = payload;
    });

    builder.addCase(getChatsWithSearchThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getChatsWithSearchThunk.fulfilled, (state, { payload }) => {
      state.loading = false;
      const { data } = payload;
      state.canLoadMore = data.length === CHATS_LIMIT;
      chatsAdapter.addMany(state, data);
      state.total = { ...initialExtraState.total, [state.currentTab.status]: data.length };
      if (state.openedChat) {
        state.openedChat.searchedMessagesId = data.find(
          (item) => item._id === state.openedChat?._id
        )?.searchedMessagesId;
      }
    });

    builder.addCase(getChatsWithSearchThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  addChat,
  removeOneChat,
  addChats,
  updateOneChat,
  removeAllChats,
  setOpenedChat,
  resetChatCounters,
  setChatsTotalCount,
  setCurrentStatusTab,
  setChatsSearch,
  setUnreadChats,
  setUnreadMessages,
  resetChatSearch,
} = chatsSlice.actions;

export default chatsSlice.reducer;

export const chatsSelectors = chatsAdapter.getSelectors((state: RootState) => state.global.chats);

export const getChatsCount = ({ global }: RootState): TotalChatCount => global.chats.total;
export const getUnreadChats = ({ global }: RootState): number => global.chats.unread;
export const getUnreadMessages = ({ global }: RootState): number => global.chats.unreadMessages;
export const getOpenedChat = ({ global }: RootState): NewChatItem | null => global.chats.openedChat;
export const getIsChatsLoading = ({ global }: RootState): boolean => global.chats.loading;
export const getCanLoadMoreChats = ({ global }: RootState): boolean => global.chats.canLoadMore;
export const getCurrentTab = ({ global }: RootState): ActiveStatusTab => global.chats.currentTab;
export const getChatsSearch = ({ global }: RootState): string => global.chats.search;
