const LinkDisconnect = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.0205 7.12946L13.9405 4.20945C15.5505 2.59945 18.1705 2.59945 19.7805 4.20945C21.3905 5.81945 21.3905 8.43945 19.7805 10.0495L16.8605 12.9695"
      stroke="#FF6131"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9704 16.8693L10.0504 19.7893C8.44043 21.3993 5.82043 21.3993 4.21043 19.7893C2.60043 18.1793 2.60043 15.5593 4.21043 13.9493L7.13043 11.0293"
      stroke="#FF6131"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 16H20"
      stroke="#FF6131"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 18V20"
      stroke="#FF6131"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 8H4"
      stroke="#FF6131"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 6V4"
      stroke="#FF6131"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LinkDisconnect;
