import styled, { css } from "styled-components";

import { deviceQuery } from "styles/mediaSizes";
import { hidePhoneInputArrows, HideScrollBar, ShowScrollBar } from "styles/mixins";

export const Wrapper = styled.div<{ textarea: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${(p) =>
    p.textarea &&
    css`
      flex: 1;
    `};
`;

export const Label = styled.label<{ fontSize?: string }>`
  color: ${(p) => p.theme.colors.text.primary};
  font-size: ${(p) => (p.fontSize ? p.fontSize : p.theme.typography.subText5)};
  font-family: ${(p) => p.theme.ff.outfit.regular};
  width: 100%;
`;

export const InputArea = styled.div<{
  textarea: boolean;
  color?: string;
  error: boolean;
  isValue: string | number | readonly string[] | undefined;
}>`
  display: flex;
  align-items: center;
  background: ${(p) => (p.color ? p.color : p.theme.colors.background.secondary)};
  border-radius: 12px;
  border: 1px solid
    ${(p) =>
      p.isValue
        ? p.theme.colors.field.full
        : p.error
        ? p.theme.colors.text.error
        : p.theme.colors.field.empty};
  position: relative;
  overflow: hidden;

  .eye {
    cursor: pointer;
    user-select: none;
    display: flex;
    padding: 12px 12px 12px 0px;
  }

  input {
    width: 100%;
    &::placeholder {
      color: ${(p) => p.theme.colors.text.placehorder};
    }
  }

  input {
    ${hidePhoneInputArrows};
    min-width: 0;
    padding: 12px 16px;
    caret-color: ${(p) => p.theme.colors.text.primary};
    flex: 1;
    background: transparent;
    outline: none;
    border: none;
    color: ${(p) => p.theme.colors.text.primary};
    font-family: ${(p) => p.theme.ff.outfit.regular};

    &::placeholder {
      font-family: ${(p) => p.theme.ff.outfit.regular};
      font-weight: normal;
    }
  }

  textarea {
    ${hidePhoneInputArrows};
    ${HideScrollBar};
    display: flex;
    flex-direction: column;
    resize: none;
    min-width: 0;
    padding: 10px;
    height: 90px;
    caret-color: ${(p) => p.theme.colors.text.primary};
    flex: 1;
    background: transparent;
    outline: none;
    border: none;
    color: ${(p) => p.theme.colors.text.primary};
    font-family: ${(p) => p.theme.ff.outfit.regular};

    &::placeholder {
      font-family: ${(p) => p.theme.ff.outfit.regular};
      font-weight: normal;
    }
  }

  &.textareaWrap {
    textarea {
      ${ShowScrollBar}
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px
      ${(p) => (p.color ? p.color : p.theme.colors.background.secondary)} inset;
    box-shadow: 0 0 0 30px ${(p) => (p.color ? p.color : p.theme.colors.background.secondary)} inset;
    -webkit-text-fill-color: ${(p) => p.theme.colors.text.primary};
  }

  ${deviceQuery.tabletS} {
    min-height: ${(p) => (p.textarea ? "90px" : "unset")};
  }
`;

export const ErrorMsg = styled.p`
  color: ${(p) => p.theme.colors.text.error};
  font-size: ${(p) => p.theme.typography.subText3};
`;

export const Info = styled.p`
  color: ${(p) => p.theme.colors.text.secondary};
  font-size: ${(p) => p.theme.typography.subText3};
`;
