import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const ChatAreaContainerStyles = styled.div`
  overflow: hidden;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ChatAreaScrollableStyles = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  padding: 16px;
  overflow: auto;
  overflow-anchor: none;

  .message_render_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  ${deviceQuery.tabletM} {
    margin: 0px -16px;
  }
`;

export const ChatAreaMessagesListStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
