import React, { FC } from "react";

const YoutubeColor: FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11012_88427)">
      <path
        d="M31.3627 8.24727C31.1817 7.56649 30.8252 6.94517 30.3287 6.44548C29.8322 5.9458 29.2132 5.58528 28.5336 5.4C26.0317 4.72727 16.0317 4.72727 16.0317 4.72727C16.0317 4.72727 6.03174 4.72727 3.52992 5.4C2.85031 5.58528 2.23128 5.9458 1.73479 6.44548C1.23829 6.94517 0.881746 7.56649 0.700829 8.24727C0.0317384 10.76 0.0317383 16 0.0317383 16C0.0317383 16 0.0317384 21.24 0.700829 23.7527C0.881746 24.4335 1.23829 25.0548 1.73479 25.5545C2.23128 26.0542 2.85031 26.4147 3.52992 26.6C6.03174 27.2727 16.0317 27.2727 16.0317 27.2727C16.0317 27.2727 26.0317 27.2727 28.5336 26.6C29.2132 26.4147 29.8322 26.0542 30.3287 25.5545C30.8252 25.0548 31.1817 24.4335 31.3627 23.7527C32.0317 21.24 32.0317 16 32.0317 16C32.0317 16 32.0317 10.76 31.3627 8.24727Z"
        fill="#FF0302"
      />
      <path d="M12.759 20.7582V11.2419L21.1227 16L12.759 20.7582Z" fill="#FEFEFE" />
    </g>
    <defs>
      <clipPath id="clip0_11012_88427">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default YoutubeColor;
