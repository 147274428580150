import { FieldArray, FormikErrors, FormikHandlers, FormikTouched, useFormikContext } from "formik";
import { ChangeEvent, memo, useCallback } from "react";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import { SocialContentLinksFormContainer } from "./style";

import Close from "assets/svg/Close";
import Drag from "assets/svg/Drag";
import {
  getYoutubeVideoIdFromLink,
  validateAndExtractInstagramPostID,
  validateAndExtractReelPostID,
  validateAndExtractTikTokID,
  validateFaceBookPostUrl,
  validateFaceBookReelUrl,
  validateIfShortenedFaceBookUrl,
  validateIfShortenedTiktokUrl,
} from "common/helpers/helper";
import DraggableList from "Components/DraggableList";
import Input from "Components/Input";
import { getLetterByIndex } from "constants/letters";
import AddSocialContentLink from "pages/creator/Bio/BioLink/components/Social/SocialExpanded/AddSocialContentLink";
import { IFormikPublikLinks, IPublicLink, IPublicLinkContent, Platform } from "types/user";

interface BioExpandedLinksProps {
  socialItemIndex: number;
  platform: Platform;
}

const SocialContentLinksForm = memo<BioExpandedLinksProps>(({ socialItemIndex, platform }) => {
  const { values, errors, touched, handleBlur, handleChange, setFieldValue, getFieldMeta } =
    useFormikContext<IFormikPublikLinks>();

  const addNewLink = (values: IFormikPublikLinks, push: (obj: IPublicLinkContent) => void) => {
    if (values.publicLinks[socialItemIndex].contents.length >= 6) return; // only 6 or fewer links allowed
    const randomId = Math.random().toString(16).slice(2);

    const nextIndex = values.publicLinks[socialItemIndex].contents.length;
    const newContentField: IPublicLinkContent = {
      url: "",
      _id: randomId,
      letterId: getLetterByIndex(nextIndex),
    };
    push(newContentField);
  };

  const handleAllPlatformLinkReplacement = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (platform === "instagram") {
      instagramReplacement(event);
      return;
    }

    if (platform === "tiktok") {
      tiktokReplacement(event);
      return;
    }

    if (platform === "youtube") {
      youtubeReplacement(event);
      return;
    }

    if (platform === "facebook") {
      faceBookReplacement(event);
    }
  };

  const faceBookReplacement = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let faceBookLink = event.target.value;

    const linkRegex = /href=([^&"]+)/;
    const match = faceBookLink.match(linkRegex);

    const url = match ? match[1] : null;

    if (url) {
      faceBookLink = decodeURIComponent(url);
    }

    const faceBookPostUrl = validateFaceBookPostUrl(faceBookLink);
    const faceBookReelUrl = validateFaceBookReelUrl(faceBookLink);
    const faceBookShortenedUrl = validateIfShortenedFaceBookUrl(faceBookLink);

    if (!faceBookPostUrl && !faceBookReelUrl && !faceBookShortenedUrl) return;

    event.target.value = faceBookLink;
  };

  const instagramReplacement = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let instagramLink = event.target.value;

    if (event.target.value.includes("reels")) {
      instagramLink = event.target.value.replace("reels", "reel");
    }

    const regex = /data-instgrm-permalink="([^"]+)"/;

    const match = instagramLink.match(regex);

    const url = match ? match[1] : null;

    if (!url) return;

    const instagramPostUrl = validateAndExtractInstagramPostID(url);
    const instagramReelUrl = validateAndExtractReelPostID(url);

    if (!instagramPostUrl && !instagramReelUrl) return;

    event.target.value = url;
  };

  const tiktokReplacement = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const tiktokLink = event.target.value;
    // check if value is an embed html and grabs link from it
    const regex = /cite="([^"]+)"/;

    const match = tiktokLink.match(regex);

    const url = match ? match[1] : null;

    if (!url) return;

    const tiktokUrl = validateAndExtractTikTokID(url);
    const tiktokShortenedUrl = validateIfShortenedTiktokUrl(url);

    if (!tiktokUrl && !tiktokShortenedUrl) return;

    event.target.value = url;
  };

  const youtubeReplacement = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const youtubeLink = event.target.value;
    // check if value is an embed html and grabs link from it
    const regex = /src="([^"]+)"/;

    const match = youtubeLink.match(regex);

    const url = match ? match[1] : null;

    if (!url) return;

    const videoId = getYoutubeVideoIdFromLink(url);
    if (!videoId) return;

    event.target.value = url;
  };

  const getRenderItemError = useCallback(
    (errors: FormikErrors<IFormikPublikLinks>, index: number): boolean => {
      if (!errors.publicLinks) return false;
      const publicLinksErrorObject = errors.publicLinks![
        socialItemIndex
      ] as FormikErrors<IPublicLink>;

      return Boolean(publicLinksErrorObject?.contents?.[index]);
    },
    [errors]
  );

  const getRenderItemTouched = useCallback(
    (touched: FormikTouched<IFormikPublikLinks>, index: number) => {
      if (!touched.publicLinks) return false;
      const publicLinksTouchedObject = touched.publicLinks![
        socialItemIndex
      ] as FormikErrors<IPublicLink>;

      return Boolean(publicLinksTouchedObject?.contents?.[index]);
    },
    [touched]
  );

  const renderItem = (
    handleChange: FormikHandlers["handleChange"],
    handleBlur: FormikHandlers["handleBlur"],
    remove: (index: number) => void,
    item: IPublicLinkContent,
    index: number,
    elementToDragFor: DraggableProvidedDragHandleProps | null | undefined,
    errors: FormikErrors<IFormikPublikLinks>,
    touched: FormikTouched<IFormikPublikLinks>
  ) => {
    const hasError = getRenderItemError(errors, index);

    const hasBeenTouched = getRenderItemTouched(touched, index);

    const isFirst = index === 0;
    return (
      <div
        className={`draggable_link ${isFirst && "first_draggable_link"} ${
          hasBeenTouched && hasError && "error_border"
        }`}
      >
        <div {...elementToDragFor}>
          <Drag />
        </div>
        <div>{item.letterId}</div>
        <Input
          type="text"
          className="inline"
          required
          autoFocus
          name={`publicLinks.${socialItemIndex}.contents.${index}.url`}
          handleChange={(event) => {
            handleAllPlatformLinkReplacement(event);
            handleChange(event);
          }}
          handleBlur={handleBlur}
          value={item.url || ""}
        />

        <div
          className="cursor-pointer"
          onClick={() => {
            remove(index);
          }}
        >
          <Close size={24} />
        </div>
      </div>
    );
  };
  return (
    <SocialContentLinksFormContainer>
      {Boolean(getFieldMeta(`publicLinks.${socialItemIndex}`).value) && (
        <FieldArray name={`publicLinks.${socialItemIndex}.contents`}>
          {({ push, remove }) => (
            <div>
              <DraggableList<IPublicLinkContent>
                items={values.publicLinks[socialItemIndex].contents}
                trackByKey="_id"
                renderItem={(item, index, elementToDragFor) => {
                  return renderItem(
                    handleChange,
                    handleBlur,
                    remove,
                    item,
                    index,
                    elementToDragFor,
                    errors,
                    touched
                  );
                }}
                orderChanged={(newList, prevIndex, newIndex) => {
                  setFieldValue(`publicLinks.${socialItemIndex}.contents`, newList);
                }}
              />

              <div onClick={() => addNewLink(values, push)}>
                <AddSocialContentLink
                  hasTopRadius={!values.publicLinks[socialItemIndex].contents.length}
                />
              </div>
            </div>
          )}
        </FieldArray>
      )}
    </SocialContentLinksFormContainer>
  );
});

export default SocialContentLinksForm;
