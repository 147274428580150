import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";

import { Wrapper } from "../home/style";

import Footer from "Components/Footer";

import NewHeader from "Components/NewHeader";
import titles from "constants/titles";
import useTitle from "customHooks/useTitle";
import { Content } from "layouts/HomeLayout/style";
import { lightTheme } from "styles/newThemes";

const Terms = () => {
  const { t } = useTranslation();
  useTitle(titles.terms);

  return (
    <ThemeProvider theme={lightTheme}>
      <NewHeader blackLogo />
      <Wrapper>
        <Content>
          <div className="content">
            <div className="hero-area">
              <div className="row">
                <div className="col-lg-6 col-sm-12" style={{ zIndex: 1 }}>
                  <div className="hero-content">
                    <h1>{t("home.terms_service")}</h1>
                    <h4>{t("home.clicked_our_terms_service")}</h4>
                    <p>{t("home.agreement_with_us")}</p>
                    <p>{t("home.do_not_agree_with")}</p>
                  </div>
                </div>
              </div>
              <div className="banner-img">
                <img src="/img/home/termsbanner.png" alt="termsbanner" loading="lazy" />
              </div>
            </div>

            <div className="why-us">
              <div className="fon" />
              <div className="row">
                <div className="col-lg-12" style={{ zIndex: 1 }}>
                  <div className="content-l">
                    <h2>{t("home.welcome_to_lewk")}</h2>
                    <div className="row">
                      <div className="col-md">
                        <p>
                          <b>1. {t("home.introduction")}</b>
                        </p>
                        <hr />
                        <p>{t("home.use_of_our_web_pages")}</p>
                        <br />
                        <p>{t("home.our_privacy_policy_also_governs")}</p>
                        <br />
                        <p>
                          <b>2. {t("communications")}</b>
                        </p>
                        <hr />
                        <p>{t("home.communication_description_1")}</p>
                        <br />
                        <p>
                          <b>3. {t("home.contests_promotions")}</b>
                        </p>
                        <hr />
                        <p> {t("home.contests_promotions_description")}</p>
                        <br />
                        <p>
                          <b>4. {t("home.subscriptions")}</b>
                        </p>
                        <hr />
                        <p> {t("home.subscription_description_1")}</p>
                        <br />
                        <p>{t("home.subscription_description_2")}</p>
                        <br />
                        <p>{t("home.subscription_description_3")}</p>
                        <br />
                        <p>{t("home.subscription_description_4")}</p>
                        <br />
                        <p>
                          <b>5. {t("home.fee_changes")}</b>
                        </p>
                        <hr />
                        <p>{t("home.fee_changes_description_1")} </p>
                        <br />
                        <p>{t("home.fee_changes_description_2")}</p>
                        <br />
                        <p>{t("home.fee_changes_description_3")}</p>
                        <br />
                        <p>
                          <b>6.{t("home.refunds")}</b>
                        </p>
                        <hr />
                        <p> {t("home.refund_description")}</p>
                        <br />
                        <p>
                          <b>7. {t("home.content_terms")}</b>
                        </p>
                        <hr />
                        <p>{t("home.content_description")} </p>
                        <br />
                        <p>
                          <b>8. {t("home.prohibited_uses")}</b>
                        </p>
                        <hr />
                        <p> {t("home.prohibited_user_description_1")} </p>
                        <br />
                        <p>{t("home.prohibited_user_description_2")}</p>
                        <br />
                        <p>{t("home.prohibited_user_description_3")}</p>
                        <br />
                        <p>{t("home.prohibited_user_description_4")}</p>
                        <br />
                        <p>{t("home.prohibited_user_description_5")}</p>
                        <br />
                        <p>{t("home.prohibited_user_description_6")}</p>
                        <br />
                        <p>{t("home.prohibited_user_description_7")}</p>
                        <br />
                        <p>{t("home.prohibited_user_description_8")}</p>
                        <br />
                        <p>{t("home.prohibited_user_description_9")}</p>
                        <br />
                        <p>{t("home.prohibited_user_description_10")}</p>
                        <br />
                        <p>{t("home.prohibited_user_description_11")}</p>
                        <br />
                        <p>{t("home.prohibited_user_description_12")}</p>
                        <br />
                        <p>{t("home.prohibited_user_description_13")}</p>
                        <br />
                        <p>{t("home.prohibited_user_description_14")}</p>
                        <br />
                        <p>{t("home.prohibited_user_description_15")}</p>
                        <br />
                        <p>{t("home.prohibited_user_description_16")}</p>
                        <br />
                        <p>{t("home.prohibited_user_description_17")}</p>
                        <p>
                          <b>9. {t("home.analytics")}</b>
                        </p>
                        <hr />
                        <p>{t("home.analytics_description_1")} </p>
                        <br />
                        <p>{t("home.analytics_description_2")}</p>
                        <br />
                        <p>{t("home.analytics_description_3")}</p>
                        <br />
                        <p>{t("home.analytics_description_4")}</p>
                        <br />
                        <p>
                          <b>10. {t("home.no_use_minors")}</b>
                        </p>
                        <hr />
                        <p> {t("home.no_use_minors_description")}</p>
                        <br />
                        <p>
                          <b>11. {t("home.accounts")}</b>
                        </p>
                        <hr />
                        <p> {t("home.accounts_description_1")} </p>
                        <br />
                        <p>{t("home.accounts_description_2")}</p>
                        <br />
                        <p>{t("home.accounts_description_3")}</p>
                        <br />
                        <p>{t("home.accounts_description_4")}</p>
                        <br />
                        <p>
                          <b>12. {t("home.intellectual_property")}</b>
                        </p>
                        <hr />
                        <p>{t("home.intellectual_property_description")} </p>
                        <br />
                        <p>
                          <b>13. {t("home.copyright_policy")}</b>
                        </p>
                        <hr />
                        <p> {t("home.copyright_policy_description_1")} </p>
                        <br />
                        <p>{t("home.copyright_policy_description_2")}</p>
                        <br />
                        <p>{t("home.copyright_policy_description_3")}</p>
                        <br />
                        <p>
                          <b>14. {t("home.dcma_notice_and_procedure")}</b>
                        </p>
                        <hr />
                        <p> {t("home.dcma_notice_and_procedure_description_1")} </p>
                        <br />
                        <p>{t("home.dcma_notice_and_procedure_description_2")}</p>
                        <br />
                        <p>{t("home.dcma_notice_and_procedure_description_3")}</p>
                        <br />
                        <p>{t("home.dcma_notice_and_procedure_description_4")}</p>
                        <br />
                        <p>{t("home.dcma_notice_and_procedure_description_5")}</p>
                        <br />
                        <p>{t("home.dcma_notice_and_procedure_description_6")}</p>
                        <br />
                        <p>{t("home.dcma_notice_and_procedure_description_7")}</p>
                        <br />
                        <p>{t("home.dcma_notice_and_procedure_description_8")}</p>
                        <br />
                        <p>
                          <b>15. {t("home.error_and_feedback")}</b>
                        </p>
                        <hr />
                        <p>{t("home.error_and_feedback_description_1")} </p>
                        <br />
                        <p>{t("home.error_and_feedback_description_2")}</p>
                        <br />
                        <p>{t("home.error_and_feedback_description_3")}</p>
                        <br />
                        <p>{t("home.error_and_feedback_description_4")}</p>
                        <br />
                        <p>{t("home.error_and_feedback_description_5")}</p>
                        <br />
                        <p>
                          <b>16. {t("home.other_websites")}</b>
                        </p>
                        <hr />
                        <p> {t("home.other_websites_description_1")} </p>
                        <br />
                        <p> {t("home.other_websites_description_2")}</p>
                        <br />
                        <p> {t("home.other_websites_description_3")}</p>
                        <br />
                        <p> {t("home.other_websites_description_4")}</p>
                        <br />
                        <p>
                          <b>17. {t("home.disclaimer_of_warranty")}</b>
                        </p>
                        <hr />
                        <p> {t("home.disclaimer_of_warranty_description_1")} </p>
                        <br />
                        <p>{t("home.disclaimer_of_warranty_description_2")}</p>
                        <br />
                        <p>{t("home.disclaimer_of_warranty_description_3")}</p>
                        <br />
                        <p>{t("home.disclaimer_of_warranty_description_4")}</p>
                        <br />
                        <p>
                          <b>18. {t("home.limitation_liability")}</b>
                        </p>
                        <hr />
                        <p> {t("home.limitation_liability_description_1")}</p>
                        <br />
                        <p>
                          <b>19. {t("home.termination")}</b>
                        </p>
                        <hr />
                        <p> {t("home.termination_1")} </p>
                        <br />
                        <p>{t("home.termination_2")}</p>
                        <br />
                        <p>{t("home.termination_3")}</p>
                        <br />
                        <p>
                          <b>20. {t("home.governing_law")}</b>
                        </p>
                        <hr />
                        <p> {t("home.governing_law_description_1")} </p>
                        <br />
                        <p> {t("home.governing_law_description_2")}</p>
                        <br />
                        <p>
                          <b>21. {t("home.changes_to_service")}</b>
                        </p>
                        <hr />
                        <p> {t("home.changes_to_service_description")} </p>
                        <br />
                        <p>
                          <b>22. {t("home.amendments_to_terms")}</b>
                        </p>
                        <hr />
                        <p> {t("home.amendments_to_terms_description_1")} </p>
                        <br />
                        <p>{t("home.amendments_to_terms_description_2")}</p>
                        <br />
                        <p>{t("home.amendments_to_terms_description_3")}</p>
                        <br />
                        <p>
                          <b>23. {t("home.waiver_and_severability")}</b>
                        </p>
                        <hr />
                        <p> {t("home.waiver_and_severability_description_1")} </p>
                        <br />
                        <p>{t("home.waiver_and_severability_description_2")}</p>
                        <br />
                        <p>
                          <b>24. {t("home.acknowledgement")}</b>
                        </p>
                        <hr />
                        <p>{t("home.acknowledgement_description_1")} </p>
                        <br />
                        <p>
                          <b>25. {t("home.contact_us_footer")}</b>
                        </p>
                        <hr />
                        <p> {t("home.contact_us_footer_description")} </p>
                        <br />
                        <p>{t("home.by_email")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>
        <Footer />
      </Wrapper>
    </ThemeProvider>
  );
};

export default Terms;
