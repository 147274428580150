import { FC } from "react";

import { GroupedMessagesDateLabelStyles } from "pages/public/chat/Components/Chat/GroupedMessagesDateLabel/styles";

interface Props {
  date: string;
}
export const GroupedMessagesDateLabel: FC<Props> = ({ date }) => (
  <GroupedMessagesDateLabelStyles>{date}</GroupedMessagesDateLabelStyles>
);
