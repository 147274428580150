import styled from "styled-components";

import { flexCenter } from "styles/mixins";

export const Wrapper = styled.div<{ type: string }>`
  background-color: ${(p) =>
    p.type === "transparent" ? "transparent" : p.theme.colors.background.primary};
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  ${flexCenter};
`;
