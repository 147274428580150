import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store/store";

interface UserVisitedPagesSliceState {
  dashboard: boolean;
  messages: boolean;
}
const initialState: UserVisitedPagesSliceState = {
  dashboard: false,
  messages: false,
};

const userVisitedPagesSlice = createSlice({
  name: "userVisitedPages",
  initialState,
  reducers: {
    setPageVisited: (
      state: Draft<UserVisitedPagesSliceState>,
      {
        payload: { page, isVisited },
      }: PayloadAction<{ page: keyof UserVisitedPagesSliceState; isVisited: boolean }>
    ) => {
      state[page] = isVisited;
    },
    resetUserVisitedPagesSlice: () => {
      return initialState;
    },
  },
});

export const { setPageVisited, resetUserVisitedPagesSlice } = userVisitedPagesSlice.actions;

export default userVisitedPagesSlice.reducer;

export const getIsPageVisited = (page: keyof UserVisitedPagesSliceState) => (state: RootState) =>
  state.userVisitedPages[page];
