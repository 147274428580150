import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { PayStatus } from "constants/appConstants";

import { RootState } from "store/store";
import { IAdminParticipant } from "types/admin/challengeReward";
import { IChallenges } from "types/challenges";

interface IInitialState {
  challenges: {
    data: IChallenges[];
    total: number;
  };
  participants: {
    data: IAdminParticipant[];
    total: number;
  };
}

const initialState: IInitialState = {
  challenges: {
    data: [],
    total: 0,
  },
  participants: {
    data: [],
    total: 0,
  },
};

const challengeRewardSlice = createSlice({
  name: "admin/challengeReward",
  initialState,
  reducers: {
    setAdminChallenges: (state, action: PayloadAction<{ data: IChallenges[]; total: number }>) => {
      state.challenges.data = action.payload.data;
      state.challenges.total = action.payload.total;
    },
    setAdminParticipants: (
      state,
      action: PayloadAction<{ data: IAdminParticipant[]; total: number }>
    ) => {
      state.participants.data = action.payload.data;
      state.participants.total = action.payload.total;
    },
    setParticipateStatus: (state, action: PayloadAction<{ id: string; status: string }>) => {
      state.participants.data = state.participants.data.map((item) => {
        if (action.payload.id === item._id) {
          return {
            ...item,
            status: action.payload.status,
          };
        }
        return item;
      });
    },
    setParticipateMessage: (state, action: PayloadAction<{ id: string; message: string }>) => {
      state.participants.data = state.participants.data.map((item) => {
        if (action.payload.id === item._id) {
          return {
            ...item,
            comment: action.payload.message,
          };
        }
        return item;
      });
    },
    setPayParticipateStatus: (state) => {
      state.participants.data = state.participants.data.map((item) => {
        return { ...item, status: PayStatus.pay_pending };
      });
    },
    setRejectParticipateStatus: (state) => {
      state.participants.data = state.participants.data.map((item) => {
        return { ...item, status: PayStatus.declined };
      });
    },
  },
});

export const {
  setAdminChallenges,
  setAdminParticipants,
  setPayParticipateStatus,
  setParticipateStatus,
  setRejectParticipateStatus,
  setParticipateMessage,
} = challengeRewardSlice.actions;

export default challengeRewardSlice.reducer;

export const getAdminChallengeReward = ({ admin }: RootState) =>
  admin.manageChallengeRewards.challenges;
export const getAdminParticipants = ({ admin }: RootState) =>
  admin.manageChallengeRewards.participants;
