import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

import { flexCenter, OutfitRegular } from "styles/mixins";

export const Main = styled.main<{ withPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: -82px;
  padding-top: ${(p) => (p.withPadding ? "82px" : "0px")};

  a {
    color: ${(p) => p.theme.colors.green.primary};
  }

  .top {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex: 1;
    width: 100%;
    height: 100vh;

    video {
      object-fit: cover;
      object-position: top;
      height: 100vh;
      width: 100vw;
    }

    .overlay {
      height: 100vh;
      width: 100vw;
      position: absolute;
      background: ${(p) => p.theme.colors.background.secondary};
      opacity: 0;
      z-index: 1;
    }

    .creativity {
      position: absolute;
      z-index: 3;
      color: ${(p) => p.theme.colors.text.primary};
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 48px;
      padding: 0px 400px;
      text-align: center;
      top: 57vh;

      .text {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .title {
          font-size: ${(p) => p.theme.typography.subTitle5};
        }
        .subTitle {
          font-size: ${(p) => p.theme.typography.subTitle3};
        }
      }

      .arrow {
        display: flex;
        align-items: center;
        transform: rotate(180deg);
      }
    }

    a {
      text-decoration: none;
    }

    button {
      padding: 16px 40px;
    }

    iframe {
      position: absolute;
      top: 0px;
    }
  }

  .content {
    padding: 0px 120px;
    display: flex;
    flex-direction: column;
    background: ${(p) => p.theme.colors.dark};

    &.gradient {
      background: radial-gradient(
        166.65% 32.11% at 60.87% 66.46%,
        #5d4b67 0%,
        #1a2860 39.43%,
        #070711 100%
      );
    }

    align-items: center;
  }

  ${deviceQuery.laptopL} {
    .top {
      .creativity {
        padding: 0px 160px;
      }
    }
    .content {
      padding: 0px 90px;
    }
  }

  ${deviceQuery.tabletM} {
    .top {
      .creativity {
        padding: 0px 80px;
      }
    }
    .content {
      padding: 0px 60px;
    }
  }

  ${deviceQuery.mobileM} {
    .top {
      .creativity {
        padding: 0px 20px;
      }
    }
    .content {
      padding: 0px 20px;
    }
  }
`;

export const Section = styled.section`
  min-height: 88vh;
  ${flexCenter};
  background: ${(p) => p.theme.colors.background.secondary};
  color: ${(p) => p.theme.colors.text.primary};

  h1 {
    color: ${(p) => p.theme.colors.text.primary};
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
    text-transform: uppercase;
    font-size: 3rem;
    letter-spacing: 3px;
    ${OutfitRegular};
  }
`;

export const Wrapper = styled.div`
  overflow: hidden;
  img {
    max-width: 100%;
    height: auto;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 15px;
    font-weight: 700;
  }
  html,
  body {
    height: 100%;
  }
  body {
    font-family: "Outfit", sans-serif;
  }
  a:hover {
    text-decoration: none;
  }

  /* Remove Chrome Input Field's Unwanted Yellow Background Color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  }

  /*Header Area*/

  .header-area {
    background: transparent;
    padding: 30px 0;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .logo {
    display: flex;
    align-items: center;
  }
  a.btn-n:hover,
  a.btn-p:hover {
    color: #fff !important;
    background: rgb(37, 195, 116)
      linear-gradient(
        136.89deg,
        rgb(0, 121, 135) -10.63%,
        rgb(0, 147, 142) -10.63%,
        rgb(0, 171, 134) 38.11%,
        rgb(37, 194, 115) 89.65%
      )
      repeat scroll 0% 0%;
  }
  .logo h1 {
    margin: 0;
    color: #25c167;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
  }
  .logo img {
    width: 150px;
    padding-right: 10px;
    border-right: 1px solid #3e3e53;
  }
  .btn-n {
    color: #fff;
  }
  .header-btn {
    display: flex;
    justify-content: end;
    gap: 20px;
    align-items: center;
  }
  a.btn-m,
  div.btn-m {
    cursor: pointer;
    color: #fff;
    background: rgb(37, 195, 116)
      linear-gradient(
        136.89deg,
        rgb(0, 121, 135) -10.63%,
        rgb(0, 147, 142) -10.63%,
        rgb(0, 171, 134) 38.11%,
        rgb(37, 194, 115) 89.65%
      )
      repeat scroll 0% 0%;
    padding: 5px 25px;
    border-radius: 12px;
    border: 1px solid #25c374;
    text-decoration: none;

    &:hover {
      background: ${(p) => p.theme.colors.dark};
      border: 1px solid #25c374;
    }
  }

  /*Banner-area*/

  .hero-area {
    background: ${(p) => p.theme.colors.background.primary};
    color: ${(p) => p.theme.colors.text.primary};
    padding: 50px 0px;

    .banner-img img {
      position: absolute;
      width: 480px;
      right: 0;
      top: 0;
    }

    @media (max-width: 991px) {
      .banner-img img {
        display: none;
      }
    }
  }
  .hero-content h1 {
    font-size: 60px;
    line-height: 72px;
    word-break: break-word;
  }
  .hero-area .row {
    align-items: center;
  }
  .hero-content h4 {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
  }
  .hero-content a.btn-m,
  .hero-content div.btn-m {
    padding: 15px 60px;
    display: inline-block;
    margin: 15px 0px;
  }
  .social-bar a {
    color: #fff;
    font-size: 28px;
    display: inline-block;
  }
  .social-bar {
    display: flex;
    gap: 20px;
  }
  span.colored {
    color: #25c167;
  }

  .hero-content .social-bar {
    margin-bottom: 11px;
  }

  .waitlist-area {
    background: #0fb57f;
    padding: 130px 0;
    position: relative;

    .roleBlock {
      display: flex;
      gap: 20px;
    }

    @media (max-width: 768px) {
      padding: 70px 0px;

      .roleBlock {
        flex-direction: column;
      }
    }
  }

  .waitlist-box {
    display: flex;
    flex-direction: column;
    background: #fff;
    height: 100%;
    padding: 70px;
    gap: 12px;
    border-radius: 15px;
    justify-content: space-between;
    align-items: center;

    ${deviceQuery.tabletM} {
      padding: 50px;
    }

    @media (max-width: 500px) {
      padding: 30px;
    }

    .waitlist-info {
      display: flex;
      flex-direction: column;
    }
  }
  .waitlist-area h4 {
    text-align: center;
    font-size: 18px;
  }
  .waitlist-box a.btn-m {
    text-align: center;
    padding: 12px 24px;
    font-size: 14px;
  }
  .waitlist-img-r {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 25%;
  }
  .waitlist-img-l {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 24%;
  }

  /*why-us area*/

  .counter-wrap {
    display: flex;
    justify-content: space-around;
    padding: 90px 0;
    height: 100%;
    gap: 25px;
    flex-wrap: wrap;
  }
  .counter-box {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 179px;
    align-items: center;
    justify-content: space-between;
  }
  .why-us {
    color: ${(p) => p.theme.colors.text.primary};
    padding: 50px 0px 75px;
    position: relative;

    .fon {
      position: absolute;
      z-index: -1;
      background: ${(p) => p.theme.colors.background.secondary};
      width: 200vw;
      height: 100%;
      left: -50vw;
      top: 0px;
    }
  }
  .counter-box h2 {
    font-size: 35px;
    margin-bottom: 4px;
  }

  .counter-box h3 {
    color: #25c374;
    margin-bottom: 0px;
  }

  .counter-box img {
    margin-bottom: 20px;
    width: 90px;
  }
  .counter-box p {
    -webkit-text-fill-color: transparent;
  }
  .content-l h2 {
    text-align: center;
    font-size: 45px;
    margin-bottom: 30px;
    word-break: break-word;
  }
  .l-box p {
    font-size: 20px;
    border-left: 2px solid #00938e;
    padding-left: 15px;
    line-height: 24px;
    margin-top: 30px;
  }

  /*features*/
  .features {
    padding: 70px 0;
    position: relative;
    padding-bottom: 147px;
    overflow: hidden;
    background-color: #ffffff;
    z-index: 0;
  }
  .features h2 {
    text-align: center;
    font-size: 45px;
  }
  .features h3 {
    font-size: 45px;
  }
  .icon-box {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-top: 25px;
  }
  .icon-box p {
    margin: 0;
  }
  .feature-img-l {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .feature-img-r {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  /*publishers*/
  .publishers {
    background: ${(p) => p.theme.colors.dark};
    color: #fff;
    padding: 90px 0;
    position: relative;
  }
  .publishers-img {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 42.5%;
  }
  .creators h2 {
    font-size: 45px;
  }
  .creators {
    position: relative;
    padding: 130px 0;
    background-color: #ffffff;
  }
  .creators-r-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 51%;
  }
  .creators-l-img {
    position: absolute;
    top: 133px;
    left: -21px;
  }

  /*account-area*/
  .creators-l-img {
    position: absolute;
    top: 133px;
    left: -21px;
  }
  .account-area {
    background: #0fb57f;
    padding: 130px 0;
    .account-type {
      gap: 25px;
    }
  }

  ${deviceQuery.tabletM} {
    .account-area {
      padding: 100px 0 150px;
    }
  }

  .account-area h2 {
    font-size: 44px;
    color: #fff;
    margin: 0;
  }
  a.btn-o {
    background: #fff;
    padding: 15px 24px;
    border-radius: 10px;
    color: #0fb57f;
    display: inline-block;
    text-decoration: none;
  }
  .d-box {
    display: flex;
    gap: 20px;
    margin-top: 55px;
  }
  .d-box img {
    object-fit: contain;
    align-self: baseline;
  }
  .d-box-content {
    color: #fff;
  }

  /*monetize-area*/
  .monetize-area {
    background: ${(p) => p.theme.colors.dark};
    padding: 130px 0;
    color: #fff;
    padding-bottom: 60px;
  }
  .monitize-t h2 {
    font-size: 36px;
    color: #25c374;
  }
  .monitize-t h4 {
    font-size: 20px;
    font-weight: 500;
  }
  .monitize-t span {
    background: #fff;
    color: #191b1d;
    padding: 3px 11px;
    border-radius: 23px;
  }
  .monitize-t p {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .monetize-options-item {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 15px;
  }
  .monetize-options-item span {
    flex-grow: 0;
    object-fit: contain;
  }
  .monetize-options-item span {
    height: 30px;
  }
  .monetize-options-item p {
    margin: 0;
  }
  .monitize-t img {
    position: absolute;
    right: -70px;
    top: 74px;
    width: 20%;
    @media (max-width: 800px) {
      right: -50px;
    }
    @media (max-width: 580px) {
      right: -27px;
    }
  }
  .monitize-t {
    margin-top: -245px;
    background: #191b1d;
    padding: 50px;
    border-radius: 25px;
    position: relative;
  }

  /*partners-area*/
  .partners-area {
    background: ${(p) => p.theme.colors.dark};
    padding: 80px 130px 130px;
    color: #fff;
    text-align: center;
    @media (max-width: 768px) {
      padding: 25px 0px 40px 0px;
    }
  }
  .single-partners {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    flex-wrap: wrap;
    gap: 16px;
  }
  .partners-area h2 {
    font-size: 45px;
    margin-bottom: 0;
  }
  .single-partners img {
    flex-grow: 0;
    height: 55px;
  }

  /*footer-area*/
  .footer-area {
    background: #191b1d;
    color: #fff;
    padding: 30px 0;
  }
  .footer-wid p {
    color: #68687e;
    margin: 0;
  }
  .footer-wid .logo {
    margin-bottom: 20px;
  }
  .footer-menu a {
    color: #fff;
    margin-bottom: 20px;
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
  }
  a.btn-p,
  input.form-control,
  select.custom-select {
    font-size: 14px;
    color: #0fb57f !important;
    border: 1px solid #0fb57f !important;
    padding: 6px 10px;
    display: inline-block;
    margin-top: 20px;
    border-radius: 10px !important;
    text-decoration: none;
  }
  .footer-c-link a {
    color: #0fb57f;
    display: block;
    text-decoration: none;
  }
  .footer-c-link {
    margin-top: 10px;
  }

  .single-partners img:hover,
  i.fa:hover,
  .bi:hover {
    opacity: 0.5;
  }

  .counter-box img:hover,
  .icon-box img:hover {
    opacity: 0.5;
    transform: rotate(5deg);
  }
`;
