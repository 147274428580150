import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";
import { HideScrollBar, OutfitRegular } from "styles/mixins";

export const DashBoardStyled = styled.div`
  color: ${(p) => p.theme.colors.text.primary};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  ${OutfitRegular}

  ${deviceQuery.laptopM} {
    .min-vh-100 {
      min-height: 100% !important;
    }
    border-radius: 0;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  position: relative;
  max-width: 1036px;
`;

export const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  flex: 1;

  .mainContent {
    display: flex;
    width: 100%;
    max-width: 1366px;
    gap: 20px;
    padding: 20px 25px;
  }

  ${deviceQuery.desktopS} {
    .mainContent {
      padding: 20px;
      justify-content: center;
    }
  }

  ${deviceQuery.mobileL} {
    .mainContent {
      padding: 16px;
    }
  }
`;

export const Sidebar = styled.div`
  top: 60px;
  margin: -20px;
  padding: 20px;
  position: sticky;
  max-width: 300px;
  min-width: 300px;
  z-index: 2;
  height: calc(100vh - 60px);
  left: 0px;
  overflow-y: scroll;
  ${HideScrollBar}

  ${deviceQuery.desktopS} {
    display: none;
  }
`;
