import { FC } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { SidebarLabel, SidebarLink, TakeALewkHiglihted } from "../../style";

import { GreenBackgroundedTitle } from "Components/PublicLinkGuidePopup/style";
import { ISidebarData } from "types/sidebar";

interface Props {
  drawer: boolean;
  clickHandler?: () => void;
  onClick?: (value: ISidebarData) => void;
  item: ISidebarData;
  className?: string;
}

const TabLink: FC<Props> = ({
  drawer,
  clickHandler = () => {},
  onClick = () => {},
  item,
  className,
}) => {
  const { t } = useTranslation();
  const onClickHandler = () => {
    clickHandler();
    onClick(item);
  };

  return (
    <SidebarLink
      className={className}
      drawer={drawer}
      onClick={(event) => {
        event.stopPropagation();
        onClickHandler();
      }}
      to={item?.path && item.path}
    >
      <div className="icon">{item.icon}</div>
      <SidebarLabel drawer={drawer}>{t(item.title)}</SidebarLabel>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {item.highlightedToLook && <TakeALewkHiglihted>{t("common.lewkAtMe")}</TakeALewkHiglihted>}
    </SidebarLink>
  );
};

export default TabLink;
