const RedditColor = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
      fill="#FE4500"
    />
    <path
      d="M12.01 18.32C10.25 18.29 8.60998 17.88 7.21998 16.73C6.29998 15.97 5.75998 15 5.79998 13.77C5.79998 13.6 5.74998 13.5 5.59998 13.4C4.84998 12.88 4.66998 11.95 5.15998 11.22C5.64998 10.51 6.58998 10.33 7.31998 10.83C7.49998 10.95 7.59998 10.91 7.74998 10.82C8.86998 10.13 10.08 9.73004 11.4 9.68004C11.63 9.68004 11.69 9.58004 11.73 9.38004C11.95 8.31004 12.18 7.25004 12.41 6.19004C12.51 5.71004 12.59 5.66004 13.06 5.75004C13.77 5.89004 14.48 6.03004 15.18 6.18004C15.36 6.22004 15.48 6.19004 15.61 6.05004C15.97 5.67004 16.47 5.60004 16.9 5.84004C17.31 6.07004 17.53 6.54004 17.43 6.99004C17.33 7.45004 16.93 7.81004 16.46 7.84004C15.99 7.87004 15.55 7.60004 15.39 7.13004C15.32 6.93004 15.22 6.86004 15.03 6.82004C14.45 6.71004 13.87 6.59004 13.29 6.45004C13.08 6.40004 13.02 6.47004 12.98 6.65004C12.79 7.59004 12.6 8.53004 12.39 9.46004C12.34 9.69004 12.41 9.74004 12.63 9.75004C13.94 9.81004 15.14 10.21 16.24 10.91C16.4 11.01 16.49 11 16.64 10.9C17.38 10.4 18.31 10.57 18.81 11.28C19.3 11.98 19.15 12.88 18.42 13.41C18.28 13.51 18.22 13.61 18.23 13.78C18.27 15.01 17.73 15.98 16.81 16.74C15.41 17.9 13.76 18.31 11.98 18.33L12.01 18.32Z"
      fill="#FEFEFE"
    />
    <path
      d="M9.49995 12.1001C10.08 12.1001 10.57 12.5601 10.58 13.1401C10.59 13.7401 10.11 14.2301 9.50995 14.2301C8.92995 14.2301 8.44995 13.7501 8.44995 13.1701C8.44995 12.5901 8.91995 12.1101 9.49995 12.1001Z"
      fill="#FE4500"
    />
    <path
      d="M15.51 13.22C15.51 13.79 15.06 14.3 14.56 14.31C13.92 14.31 13.4 13.83 13.39 13.22C13.39 12.63 13.84 12.16 14.43 12.15C15.04 12.15 15.5 12.6 15.51 13.22Z"
      fill="#FE4500"
    />
    <path
      d="M12.01 16.9301C11.12 16.9501 10.3 16.7301 9.55998 16.2301C9.39998 16.1201 9.20998 16.0101 9.34998 15.7801C9.49998 15.5401 9.67998 15.6401 9.86998 15.7501C11.28 16.6001 12.7 16.6001 14.13 15.7801C14.17 15.7601 14.21 15.7301 14.25 15.7001C14.42 15.6001 14.58 15.6101 14.68 15.7801C14.77 15.9401 14.7 16.0601 14.55 16.1601C13.78 16.6901 12.94 16.9301 12.01 16.9101V16.9301Z"
      fill="#FE4301"
    />
  </svg>
);

export default RedditColor;
