// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import getAxiosInst, { apiHandler, endpoints } from "./axios";

import { makeToast } from "common/helpers/helper";
import { STRINGS } from "constants/appConstants";
import i18n from "i18n";
import { setAdminGifting } from "store/slices/admin/adminSlice";
import { setGifting } from "store/slices/creator/giftingSlice";
import { setLoader } from "store/slices/featureSlice";
import {
  setAvailableCurrencies,
  setCountries,
  setCurrencies,
} from "store/slices/global/globalSlice";
import { setWallOfFame, setWallSettings } from "store/slices/global/previewProfileSlice";
import {
  editUsername,
  setCompetition,
  setSkipMFA,
  setUser,
  setUserSocials,
} from "store/slices/global/userSlice";
import store from "store/store";
import { GetUserDetailsApiResp } from "types/api-response-types";
import { IAvailableCurrencies, ICurrencies } from "types/currencies";
import { UploadedMediaItem } from "types/file.types";
import { IGiftingCreator } from "types/gifting";
import { IWallOfFame } from "types/previewProfile";
import {
  IMessageSettings,
  IMfa,
  INotifications,
  IUser,
  IUserCompetition,
  IWallSettings,
  Platform,
} from "types/user";

export const twoFactorAuthQR = async ({ data }) => {
  return getAxiosInst().post("/tfa/auth", data);
};

export const getCreator = () =>
  getAxiosInst().get<GetUserDetailsApiResp>(endpoints.newCreatorDetails);

export const twoFactorAuthVerify = async ({ apiData }) => {
  return getAxiosInst().post("/tfa/verify", apiData);
};

export const skipMFA = async ({ apiData }) => {
  return getAxiosInst().post(`${endpoints.skipMFA}`, apiData);
};

export const onFanImageRemove = () => {
  return getAxiosInst().get(endpoints.removeProPic);
};

export const changePasswordAPI = (
  { apiData },
  callback = (res: { status: boolean; data: string }) => {}
) => {
  apiHandler(
    () => getAxiosInst().post<{ status: boolean; data: string }>(endpoints.changePassword, apiData),
    {
      onSuccess: (res) => {
        callback(res);
      },
      onError: (error) => {
        makeToast({
          message: error?.response?.data?.message || "Failed",
          type: STRINGS.toastType.error,
        });
      },
    }
  );
};

export const updateProfilePic = ({ apiData }, callback = (res: { key: string }) => {}) => {
  apiHandler(() => getAxiosInst().post<{ key: string }>(endpoints.updateProfilePicture, apiData), {
    onSuccess: (res) => {
      callback(res);
    },
    onError: (error) => {
      makeToast({
        message: error.response.data.message,
        type: STRINGS.toastType.error,
      });
      callback(false);
      console.log("catch error ===>>>", error.response);
    },
  });
};

export const uploadFileWithProgress = (
  { apiData, reason }: { apiData: FormData },
  onNewBytesUpload: (uploadedBytes: number) => void
): Promise<UploadedMediaItem> => {
  return getAxiosInst()
    .post<UploadedMediaItem>(endpoints.updateProfilePicture, apiData, {
      onUploadProgress: (progressEvent: number) => {
        onNewBytesUpload(progressEvent.loaded);
      },
    })
    .then((res) => res.data);
};

export const getFaviconAsBase64ByUrl = (url, onSuccess = (res) => {}, onError = (error) => {}) => {
  apiHandler(
    () => getAxiosInst().post<{ favicon: string }>(endpoints.getFaviconAsBase64FromUrl, { url }),
    {
      onSuccess: (res) => {
        onSuccess(res);
      },
      onError: (error) => {
        onError(error);
        console.log("catch error ===>>>", error.response);
      },
    }
  );
};

export const deleteUserAccount = ({ mfa }, callback = () => {}) => {
  apiHandler(
    () =>
      getAxiosInst().delete(endpoints.deleteAccount, {
        data: {
          mfaCode: mfa,
          reason: "sick",
        },
      }),
    {
      onSuccess: () => {
        callback();
      },
      onError: (error) => {
        makeToast({
          message: error.response.data.message,
          type: STRINGS.toastType.error,
        });
      },
    }
  );
};

export const cancelDeleteUserAccount = (callback = () => {}) => {
  apiHandler(() => getAxiosInst().post(endpoints.cancelDeleteAccount), {
    onSuccess: () => {
      callback();
    },
    onError: (error) => {
      makeToast({
        message: error.response.data.message,
        type: STRINGS.toastType.error,
      });
    },
  });
};

export const skipMFAApi = (apiData, callback = () => {}) => {
  apiHandler(() => skipMFA({ apiData }), {
    onSuccess: () => {
      store.dispatch(setSkipMFA(apiData.skip));
      callback();
    },
    onError: (error) => {
      makeToast({
        message: error.response.data.message,
        type: STRINGS.toastType.error,
      });
    },
  });
};

interface UpdateUserInfoData extends IUser {
  banner: { image: string } | null;
  cardBackground: string | null;
  wallpaper: {
    background?: {
      color1: string;
      color2: string;
      theme: string;
    } | null;
    pattern?: {
      icon: string;
      color: string;
      customIconUrl?: string;
    } | null;
  } | null;
  publicLinks: {
    title: string;
    platform: Platform;
    link: string;
    contents: { url: string }[];
    customIcon?: string;
  }[];
}
export const updateProfileInfoAPI = (
  {
    data,
    part,
  }: {
    data:
      | Partial<UpdateUserInfoData>
      | Partial<IWallSettings>
      | Partial<IMfa>
      | Partial<INotifications>
      | Partial<IMessageSettings>;
    part:
      | "customizations"
      | "general"
      | "publicLinks"
      | "wallOfFameSettings"
      | "mfa_settings"
      | "notification"
      | "message_settings";
  },
  callback = () => {}
) => {
  apiHandler(
    () => getAxiosInst().patch<GetUserDetailsApiResp>(endpoints.updateProfile, { ...data, part }),
    {
      onSuccess: (data) => {
        callback(data.user);
        store.dispatch(setUser(data.user));
        if (part === "wallOfFameSettings") {
          makeToast({
            message: i18n.t("common.bioConfirm"),
            type: STRINGS.toastType.success,
          });
        }
      },
      onError: (error) => {
        makeToast({
          message: error.response.data.message,
          type: STRINGS.toastType.error,
        });
      },
      final: () => {
        store.dispatch(setLoader({ name: "editProfileLoader", value: false }));
      },
    }
  );
};

export const getWallPreview = ({ period }) => {
  apiHandler(
    () =>
      getAxiosInst().get<{ data: IWallOfFame[] }>(endpoints.getWallPreview, {
        params: {
          period,
        },
      }),
    {
      onSuccess: (data) => {
        store.dispatch(setWallOfFame(data.data));
      },
      onError: (error) => {
        makeToast({
          message: error.response.data.message,
          type: STRINGS.toastType.error,
        });
      },
    }
  );
};

export const getWallOfFameApi = ({ id }) => {
  store.dispatch(setLoader({ name: "wallOfFameLoader", value: true }));

  apiHandler(
    () =>
      getAxiosInst().get<{ data: { supporters: IWallOfFame[]; settings: IWallSettings } }>(
        `${endpoints.getWallOfFame}/${id}/wallOfFame`
      ),
    {
      onSuccess: (data) => {
        store.dispatch(setWallOfFame(data.data.supporters));
        if (!Array.isArray(data.data)) store.dispatch(setWallSettings(data.data.settings));
      },
      onError: (error) => {
        makeToast({
          message: error.response.data.message,
          type: STRINGS.toastType.error,
        });
      },
      final: () => {
        store.dispatch(setLoader({ name: "wallOfFameLoader", value: false }));
      },
    }
  );
};

export const updateUsername = ({ username }) => {
  apiHandler(() => getAxiosInst().post(endpoints.updateUsername, { username }), {
    onSuccess: () => {
      store.dispatch(editUsername(username));
      window.location.reload();
    },
    onError: (error) => {
      makeToast({
        message: error.response.data.message,
        type: STRINGS.toastType.error,
      });
    },
  });
};

export const verifySocialApi = (social) => {
  apiHandler(() => getAxiosInst().get<{ url: string }>(`/${social}${endpoints.verifySocial}`), {
    onSuccess: (res) => {
      window.location.assign(res.url);
    },
    onError: (error) => {
      makeToast({
        message: error.response.data.message,
        type: STRINGS.toastType.error,
      });
      console.log("catch error ===>>>", error.response);
    },
  });
};

export const disconnectSocialAPI = (social) => {
  apiHandler(
    () =>
      getAxiosInst().post<{ data: IUser; message: string }>(
        `/${social}${endpoints.disconnectSocial}`
      ),
    {
      onSuccess: (res) => {
        store.dispatch(setUserSocials(res.data));
        makeToast({
          message: res.message,
          type: STRINGS.toastType.success,
        });
      },
      onError: (error) => {
        makeToast({
          message: error.response.data.message,
          type: STRINGS.toastType.error,
        });
        console.log("catch error ===>>>", error.response);
      },
    }
  );
};

export const updateFanProfielInfoAPI = (apiData, callback = () => {}) => {
  store.dispatch(setLoader({ name: "editProfileLoader", value: true }));

  apiHandler(() => getAxiosInst().put<{ user: IUser }>(endpoints.updateFanProfile, apiData), {
    onSuccess: (data) => {
      callback(true);
      store.dispatch(setUser(data.user));
    },
    onError: (error) => {
      makeToast({
        message: error.response.data.message,
        type: STRINGS.toastType.error,
      });
      callback(false);
      console.log("catch error ===>>>", error.response);
    },
    final: () => {
      store.dispatch(setLoader({ name: "editProfileLoader", value: false }));
    },
  });
};

export const getAvailableCurrency = () => {
  apiHandler(
    () =>
      getAxiosInst().get<{ data: IAvailableCurrencies }>(endpoints.getAvaiableCurrency, {
        params: {
          reference: "platform_currencies",
        },
      }),
    {
      onSuccess: (res) => {
        store.dispatch(setAvailableCurrencies(res.data));
      },
      onError: (error) => {
        console.log("catch error ===>>>", error.response);
      },
    }
  );
};

export const getCurrencyStatusAPI = () => {
  apiHandler(
    () =>
      getAxiosInst().get<{ data: ICurrencies }>(endpoints.getCurrencyStatus, {
        params: {
          sort: 1,
        },
      }),
    {
      onSuccess: (res) => {
        store.dispatch(setCurrencies(res.data));
      },
      onError: (error) => {
        console.log("catch error ===>>>", error.response);
      },
    }
  );
};

export const updateCurrenciesStatusAPI = ({ id, apiData }, callback = () => {}) => {
  apiHandler(
    () =>
      getAxiosInst().put<{ data: ICurrencies; status: boolean }>(
        endpoints.updateCurrencyStatus,
        apiData,
        {
          params: {
            id,
          },
        }
      ),
    {
      onSuccess: (res) => {
        callback(res);
        makeToast({
          message: res?.data || "Updated Successfully",
          type: STRINGS.toastType.success,
        });
      },
      onError: (error) => {
        makeToast({
          message: error.response?.data.message || "Updation Failed",
          type: STRINGS.toastType.error,
        });
      },
    }
  );
};

export const updateAvailableCurrencies = (data, callback = () => {}) => {
  apiHandler(() => getAxiosInst().post(endpoints.updateAvailableCurreny, data), {
    onSuccess: (res) => {
      makeToast({
        message: res?.data || "Updated Successfully",
        type: STRINGS.toastType.success,
      });
      callback(res);
    },
    onError: (error) => {
      makeToast({
        message: error?.response?.data?.message || "Updation Failed",
        type: STRINGS.toastType.error,
      });
    },
  });
};

export const getGiftingtHistoryList = (apiData, callback = () => {}) => {
  store.dispatch(setLoader({ name: "giftingLoader", value: true }));
  apiHandler(
    () =>
      getAxiosInst().post<{
        result: IGiftingCreator[];
        totalCount: number;
      }>(`${endpoints.getGiftingHistory}?limit=${apiData.limit}&skip=${apiData.skip}`, {
        isSent: apiData.filter.type === "sent" ? 1 : undefined,
        isReceived: apiData.filter.type === "received" ? 1 : undefined,
        search: apiData.filter.search,
        amountLte: apiData.filter.to,
        amountGte: apiData.filter.from,
      }),
    {
      onSuccess: (res) => {
        callback(res);
        if (apiData?.role === "creator") {
          store.dispatch(setGifting(res));
        } else if (apiData?.role === "admin") {
          store.dispatch(setAdminGifting(res));
        }
      },
      onError: (error) => {
        callback(error);
      },
      final: () => {
        store.dispatch(setLoader({ name: "giftingLoader", value: false }));
      },
    }
  );
};

export const getCountries = () => {
  apiHandler(() => getAxiosInst().get(endpoints.getCountries), {
    onSuccess: (res) => {
      store.dispatch(setCountries(res.data));
    },
    onError: (error) => {
      console.log(error);
      console.log("catch error ===>>>", error);
    },
  });
};

export const getUnShortenedUrl = (
  url: string,
  successCallback = (res: { status: boolean; data: { url: string } }) => {},
  errorCallback = () => {}
) => {
  apiHandler(
    () =>
      getAxiosInst().get<{ status: boolean; data: { url: string } }>(endpoints.getUnShortenedUrl, {
        params: {
          url,
        },
      }),
    {
      onSuccess: (res) => {
        successCallback(res);
      },
      onError: () => {
        errorCallback();
      },
    }
  );
};

export const getCompetitionWorth = () => {
  apiHandler(() => getAxiosInst().get<IUserCompetition>(endpoints.competitions), {
    onSuccess: (res) => {
      store.dispatch(setCompetition(res));
    },
    onError: (error) => {
      makeToast({
        message: error.response.data.message,
        type: STRINGS.toastType.error,
      });
    },
  });
};

export const getCompetitionRefresh = () => {
  apiHandler(() => getAxiosInst().get<IUserCompetition>(endpoints.competitionsRefresh), {
    onSuccess: (res) => {
      store.dispatch(setCompetition(res));
    },
    onError: (error) => {
      makeToast({
        message: error.response.data.message,
        type: STRINGS.toastType.error,
      });
    },
  });
};
