import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getMediaTypeFromName } from "common/helpers/file-helpers";
import { makeToast } from "common/helpers/helper";
import { changeTotalCounts } from "common/helpers/helpers";
import { STRINGS } from "constants/appConstants";
import {
  MESSAGES_MAX_AUDIO_SIZE,
  MESSAGES_MAX_IMAGE_SIZE,
  MESSAGES_MAX_VIDEO_SIZE,
} from "constants/file-sizes";
import { AddContentModal } from "pages/public/chat/Components/Chat/AddContentModal";
import { ChatArea } from "pages/public/chat/Components/Chat/ChatArea";
import { ChatHeader } from "pages/public/chat/Components/Chat/ChatHeader";
import { ChatInput } from "pages/public/chat/Components/Chat/ChatInput";
import { ChatRequestActions } from "pages/public/chat/Components/Chat/ChatRequestActions";
import { ChatSearchMessageNavigation } from "pages/public/chat/Components/Chat/ChatSearchMessageNavigation";
import { ChatWrapper } from "pages/public/chat/Components/Chat/styles";
import {
  sendMessageToUser,
  updateChatStatusById,
  uploadMessageContent,
} from "services/newMessagesService";
import {
  getChatsCount,
  getChatsSearch,
  getOpenedChat,
  setCurrentStatusTab,
  setOpenedChat,
  updateOneChat,
} from "store/slices/global/chatsSlice";
import {
  addMessage,
  contentUploadProgressChanged,
  MESSAGES_LIMIT,
  messagesSelectors,
  removeAllMessages,
  selectMessagesLoading,
  selectRepliedMessage,
  selectSearchMessageId,
  setRepliedMessage,
  setSearchedMessageId,
} from "store/slices/newMessages/messagesSlice";
import { getChatMessagesByChatId } from "store/thunks/messages-thunks";
import { UploadedMediaItem } from "types/file.types";
import { AddContentModalSendData, ChatStatus, MessageType } from "types/new-messages.types";
import { UserRole } from "types/user-role";

interface MediaCardSendParams {
  price: number;
  currency?: string;
  contentPreviewId?: string;
}

const getMessageType = (
  role: UserRole,
  mediaItems: UploadedMediaItem[],
  isPreview: string | undefined,
  price: number | undefined
): MessageType => {
  if (
    (role === "creator" && mediaItems.length === 1) ||
    (mediaItems.length === 1 && !isPreview && price === 0)
  )
    return "media";
  if (mediaItems.length) return "media_card";
  return "text";
};
// todo check how to improve and do not hardcode
const TAB_INDEX_BY_STATUS: Record<ChatStatus, number> = {
  active: 0,
  pending_approval: 1,
  blocked: 2,
};

interface Props {
  role: UserRole;
}

export const Chat: FC<Props> = memo(({ role }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [addContentModalVisible, setAddContentModalVisible] = useState<boolean>(false);
  const messages = useSelector(messagesSelectors.selectAll);
  const isMessagesLoading = useSelector(selectMessagesLoading);
  const openedChat = useSelector(getOpenedChat);
  const search = useSelector(getChatsSearch);
  const total = useSelector(getChatsCount);
  const repliedMessage = useSelector(selectRepliedMessage);
  const searchMessageId = useSelector(selectSearchMessageId);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const messageIds = useSelector(messagesSelectors.selectIds);

  useEffect(() => {
    let timeout: number;
    if (searchMessageId) {
      goToMessage(searchMessageId);

      // 2s is a duration of animation that highlights message after it has been found
      // after this animation we set searched message back to nothing
      timeout = setTimeout(() => {
        dispatch(setSearchedMessageId(""));
      }, 2000) as unknown as number;
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [searchMessageId]);

  const goToMessage = (searchMessageId: string) => {
    document.getElementById(searchMessageId)?.scrollIntoView({
      behavior: "auto",
      block: "center",
    });
  };

  const sendMessage = useCallback(
    async (
      textMessage: string,
      mediaItems: UploadedMediaItem[],
      mediaCardParams?: MediaCardSendParams
    ) => {
      if (!openedChat) return;
      sendMessageToUser(
        {
          chatId: openedChat._id,
          messageType: getMessageType(
            openedChat.anotherUser.role,
            mediaItems,
            mediaCardParams?.contentPreviewId,
            mediaCardParams?.price
          ),
          text: textMessage || undefined,
          mediaFilesIds: mediaItems.map((item) => item.id),
          replyToMessageId: repliedMessage ? repliedMessage._id : undefined,
          previewMediaId: mediaCardParams?.contentPreviewId,
          tokenGroup: mediaCardParams?.currency,
          price: mediaCardParams?.price ? mediaCardParams?.price : undefined,
        },
        (message) => {
          dispatch(setOpenedChat({ ...openedChat, lastMessage: { ...message } }));
          dispatch(
            updateOneChat({
              id: openedChat._id,
              changes: {
                lastMessage: { ...message },
              },
            })
          );
          dispatch(addMessage(message));

          scrollToBottom();
        }
      );
    },
    [openedChat, repliedMessage, messages]
  );

  const scrollToBottom = (behavior: "auto" | "smooth" = "auto"): void => {
    const scrollContainerElement = scrollContainerRef.current;
    if (!scrollContainerElement) return;

    scrollContainerElement.scrollTo({
      top: scrollContainerElement.scrollHeight,
      behavior,
    });
  };

  const updateChatStatus = (status: ChatStatus) => {
    if (!openedChat) return;

    updateChatStatusById(openedChat._id, status, () => {
      changeTotalCounts(openedChat, status, total);
      dispatch(setOpenedChat({ ...openedChat, status }));
      dispatch(setCurrentStatusTab({ status, tabIndex: TAB_INDEX_BY_STATUS[status] }));
    });
  };

  const onFileSelectFile = async (file: File) => {
    const fileType = getMediaTypeFromName(file.name);

    if (fileType === "image" && file.size > MESSAGES_MAX_IMAGE_SIZE) {
      makeToast({
        message: t("new_chat.imageError"),
        type: STRINGS.error,
      });
      return;
    }
    if (fileType === "video" && file.size > MESSAGES_MAX_VIDEO_SIZE) {
      makeToast({
        message: t("new_chat.videoError"),
        type: STRINGS.error,
      });
      return;
    }
    if (fileType === "audio" && file.size > MESSAGES_MAX_AUDIO_SIZE) {
      makeToast({
        message: t("new_chat.audioError"),
        type: STRINGS.error,
      });
      return;
    }

    const uploadedContent = await uploadMessageContent([file], (percentage) => {
      dispatch(contentUploadProgressChanged(percentage));
    });

    // something when wrong during upload
    if (!uploadedContent) return;

    sendMessage("", uploadedContent);
  };

  const onMediaCardUpload = async (content: AddContentModalSendData, resetForm: () => void) => {
    const uploadedContent = await uploadMessageContent(content.content, (percentage) => {
      dispatch(contentUploadProgressChanged(percentage));
    });
    // something when wrong during upload
    if (!uploadedContent) return;

    let uploadedPreviewData: UploadedMediaItem[] | undefined;
    if (content.contentPreview) {
      uploadedPreviewData = await uploadMessageContent([content.contentPreview], (percentage) => {
        dispatch(contentUploadProgressChanged(percentage));
      });
    }
    // something when wrong during upload the preview
    if (!uploadedPreviewData) {
      const mediaCardParams: MediaCardSendParams = {
        price: content.price,
        currency: content.currency,
      };
      sendMessage("", uploadedContent, mediaCardParams);
      resetForm();
    } else {
      const mediaCardPreviewId = uploadedPreviewData[0].id;

      const mediaCardParams: MediaCardSendParams = {
        contentPreviewId: mediaCardPreviewId,
        price: content.price,
        currency: content.currency,
      };
      sendMessage("", uploadedContent, mediaCardParams);
      resetForm();
    }
  };

  const removeRepliedMessage = () => {
    dispatch(setRepliedMessage(null));
  };

  const bottomActionMenu = useMemo<React.JSX.Element>(() => {
    if (!openedChat) return <></>;

    if (openedChat && openedChat.searchedMessagesId?.length && search) {
      return (
        <>
          <div className="divider" />
          <ChatSearchMessageNavigation
            elementsLength={openedChat.searchedMessagesId.length}
            indexChanged={(newIndex) => {
              const searchedMessageId = openedChat.searchedMessagesId?.[newIndex];
              if (!searchedMessageId) return;
              goToSearchedMessage(searchedMessageId);
            }}
          />
        </>
      );
    }
    if (!openedChat || openedChat.status === "active")
      return (
        <>
          <div className="divider" />
          <ChatInput
            sendTextMessage={(textMessage) => {
              sendMessage(textMessage, []);
            }}
            sendFileMessage={async (uploadedMediaItems) => {
              onFileSelectFile(uploadedMediaItems);
            }}
            sendMediaCard={() => {
              setAddContentModalVisible(true);
            }}
            removeRepliedMessage={removeRepliedMessage}
          />
        </>
      );
    if (!openedChat || openedChat.status === "blocked") {
      return (
        <>
          <div className="divider" />
          <ChatInput
            blocked
            sendTextMessage={() => {}}
            sendFileMessage={() => {}}
            sendMediaCard={() => {}}
            removeRepliedMessage={() => {}}
          />
        </>
      );
    }

    return (
      <>
        <div className="divider" />
        <ChatRequestActions status={openedChat.status} onStatusUpdate={updateChatStatus} />
      </>
    );
  }, [openedChat, search, repliedMessage, messages]);

  const goToSearchedMessage = (searchMessageId: string) => {
    if (messageIds.includes(searchMessageId)) {
      // this will automatically go into view if message already in list
      dispatch(setSearchedMessageId(searchMessageId));
      return;
    }

    loadMessagesWithSearch(searchMessageId);
  };

  const loadMessagesWithSearch = (searchMessageId: string) => {
    if (!openedChat) return;
    dispatch(removeAllMessages());
    dispatch(
      getChatMessagesByChatId({
        chatId: openedChat._id,
        limit: MESSAGES_LIMIT,
        aroundOffsetId: searchMessageId,
      })
    );
  };

  return (
    <ChatWrapper>
      <ChatHeader role={role} />
      <div className="divider" />
      <ChatArea
        messages={messages}
        isLoading={isMessagesLoading}
        scrollContainerRef={scrollContainerRef}
        goToSearchedMessage={goToSearchedMessage}
      />

      {bottomActionMenu}
      <AddContentModal
        show={addContentModalVisible}
        onCloseModal={(resetForm, content) => {
          setAddContentModalVisible(false);
          if (content && resetForm) {
            onMediaCardUpload(content, resetForm);
          } else if (resetForm) {
            resetForm();
          }
        }}
        openModal={() => setAddContentModalVisible(true)}
      />
    </ChatWrapper>
  );
});
