import styled from "styled-components";

export const UploadProgressBarStyles = styled.div`
  width: 100%;
  background-color: ${(p) => p.theme.colors.background.fifth};

  .message {
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }
`;

export const Progress = styled.div<{ progress: number }>`
  width: ${(p) => `${p.progress}%`};
  height: 1px;
  background-color: ${(p) => p.theme.colors.background.fourth};
`;
