import * as React from "react";

const TikTokColor: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.2688 12.1097C24.1658 13.4012 26.49 14.1611 29 14.1611V9.56096C28.5249 9.56114 28.0511 9.51392 27.5864 9.42007V13.0411C25.0766 13.0411 22.7528 12.2812 20.8552 10.9897V20.3772C20.8552 25.0733 16.8579 28.88 11.9273 28.88C10.0876 28.88 8.37756 28.3503 6.95703 27.4418C8.57829 29.0205 10.8392 30 13.3405 30C18.2715 30 22.269 26.1933 22.269 21.497V12.1097H22.2688ZM24.0126 7.46874C23.0431 6.46005 22.4066 5.15635 22.2688 3.71508V3.12343H20.9292C21.2664 4.95518 22.4165 6.52017 24.0126 7.46874ZM10.0756 23.8383C9.534 23.1619 9.24123 22.3344 9.24254 21.4836C9.24254 19.3358 11.0709 17.5944 13.3266 17.5944C13.747 17.5941 14.1648 17.6555 14.5655 17.7765V13.0736C14.0973 13.0125 13.6249 12.9866 13.1524 12.9962V16.6567C12.7516 16.5357 12.3335 16.4742 11.913 16.4745C9.65734 16.4745 7.82911 18.2159 7.82911 20.3639C7.82911 21.8827 8.74297 23.1978 10.0756 23.8383Z"
      fill="#EE1D52"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8566 10.9897C22.7542 12.2812 25.078 13.0411 27.5878 13.0411V9.42007C26.1869 9.13598 24.9466 8.43863 24.0141 7.46874C22.4179 6.52017 21.2678 4.95518 20.9307 3.12343H17.412V21.4969C17.404 23.6388 15.5787 25.3731 13.3279 25.3731C12.0015 25.3731 10.8231 24.771 10.0769 23.8383C8.74423 23.1978 7.83038 21.8827 7.83038 20.3639C7.83038 18.2159 9.65861 16.4745 11.9143 16.4745C12.3464 16.4745 12.763 16.5386 13.1537 16.6567V12.9962C8.30967 13.0915 4.41382 16.861 4.41382 21.497C4.41382 23.8112 5.38401 25.9092 6.95847 27.442C8.379 28.3504 10.089 28.8801 11.9287 28.8801C16.8594 28.8801 20.8566 25.0733 20.8566 20.3773V10.9897Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5874 9.41649V8.43736C26.324 8.43918 25.0856 8.10222 24.0137 7.465C24.9625 8.45434 26.2119 9.1366 27.5874 9.41649ZM20.9303 3.11986C20.8981 2.94482 20.8734 2.7686 20.8562 2.59164V2H15.9978V20.3736C15.99 22.5154 14.1649 24.2496 11.9139 24.2496C11.253 24.2496 10.6291 24.1003 10.0765 23.8347C10.8227 24.7675 12.0011 25.3695 13.3275 25.3695C15.5782 25.3695 17.4036 23.6352 17.4116 21.4933V3.11986H20.9303ZM13.1535 12.9926V11.9502C12.7475 11.8973 12.3382 11.8709 11.9285 11.8711C6.99725 11.8711 3 15.6779 3 20.3736C3 23.3176 4.57099 25.9121 6.95825 27.4383C5.38379 25.9055 4.4136 23.8075 4.4136 21.4933C4.4136 16.8574 8.30927 13.0879 13.1535 12.9926Z"
      fill="#69C9D0"
    />
  </svg>
);

export default TikTokColor;
