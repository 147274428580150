import moment from "moment";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons/lib";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

import SidebarFooter from "./components/SidebarFooter";
import SubMenu from "./components/SubMenu";
import { Date, ProfileSection, SidebarNav, SidebarWrap } from "./style";

import { getSideBarData } from "../../constants/sidebarData";

import Copy from "assets/svg/Copy";
import InfoCircle from "assets/svg/InfoCircle";
import Profile from "assets/svg/Profile";
import Share from "assets/svg/Share";
import { makeToast } from "common/helpers/helper";
import Avatar from "Components/Avatar";
import Button from "Components/Button";
import { frontendDomain } from "configuration/config";
import { ROLES, STRINGS } from "constants/appConstants";
import { getLoggedInStatus, getRole } from "store/slices/authSlice";
import { setViewPicture } from "store/slices/global/globalSlice";
import { getUser } from "store/slices/global/userSlice";
import { BlockCard } from "styles/mixins";
import { colors } from "styles/theme";
import { MOMENT_TO_I18N } from "types/language.types";

interface Props {
  drawer?: boolean;
  closeDrawer?: () => void;
}

// Side bar component
const SideBarMenu: FC<Props> = ({ drawer = false, closeDrawer = () => {} }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [subTab, setSubTab] = useState({ title: "", status: false });
  const [mainTab, setMainTab] = useState({ keyPath: "" });
  const isLoggedIn = useSelector(getLoggedInStatus);
  const accountType = useSelector(getRole);
  const userDetails = useSelector(getUser);
  const showImage = () => {
    if (!userDetails) return;
    const defaultImage =
      userDetails.role === "creator" ? "/defaultAvatarCreator.png" : "/defaultAvatarFan.png";
    dispatch(setViewPicture({ src: userDetails.pictureUrl || defaultImage, show: true }));
  };
  const publicLink = userDetails ? `${frontendDomain}/@${userDetails.username}` : "";

  const copyTextToClipboard = async () => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(publicLink);
      makeToast({ message: "Copied ", type: STRINGS.toastType.success });
    }
  };

  const hasHighlightedLinksTab = useMemo(() => {
    return accountType === ROLES.creator && userDetails && userDetails.addPublicLinkPopup;
  }, [accountType, userDetails]);

  const sidebarData = useMemo(() => {
    return getSideBarData(accountType, hasHighlightedLinksTab ? "common.links" : undefined);
  }, [hasHighlightedLinksTab, accountType, userDetails?.languages]);

  return (
    <SkeletonTheme baseColor={colors.blue.primary} highlightColor={colors.blue.border}>
      <IconContext.Provider value={{ color: colors.white.primary }}>
        <SidebarNav drawer={drawer}>
          {!isLoggedIn ? (
            <></>
          ) : (
            <>
              {userDetails && (
                <>
                  {userDetails.deletionStarted && (
                    <BlockCard padding={0} paddingSmall={0}>
                      <ProfileSection
                        level={!!userDetails.level}
                        allowUpdateUsername={
                          userDetails.allowUpdateUsername && userDetails.role === ROLES.creator
                        }
                      >
                        <div className="cancel">
                          <div className="info">
                            <div className="icon">
                              <InfoCircle />
                            </div>
                            <p>
                              {t("common.deleteAfter")}
                              <span className="yellow">{` ${
                                moment(
                                  moment(userDetails.deletionStarted).add(30, "d").valueOf() -
                                    moment().valueOf()
                                ).date() - 1
                              } ${t("common.daysSmall")}`}</span>
                            </p>
                          </div>
                          <Button
                            full
                            variant="primary"
                            size="sm"
                            onClick={() =>
                              navigate(
                                userDetails.role === ROLES.creator
                                  ? `/creator/editProfile/general`
                                  : `/fan/editProfile/general`,
                                {
                                  state: { cancel: true },
                                  replace: true,
                                }
                              )
                            }
                          >
                            {t("common.cancelAccountDeletion")}
                          </Button>
                        </div>
                      </ProfileSection>
                    </BlockCard>
                  )}

                  <BlockCard padding={0} paddingSmall={0}>
                    <ProfileSection
                      level={!!userDetails.level}
                      allowUpdateUsername={
                        userDetails.allowUpdateUsername && userDetails.role === ROLES.creator
                      }
                      hasPublicLinkSection={userDetails.role !== ROLES.creator}
                    >
                      <div className="profile">
                        {userDetails.allowUpdateUsername && userDetails.role === ROLES.creator && (
                          <div className="random">
                            <div className="icon">
                              <InfoCircle />
                            </div>
                            <p>
                              {t("common.randomUsername")}{" "}
                              <Link
                                to="/creator/editProfile/general"
                                relative="path"
                                onClick={() => closeDrawer && closeDrawer()}
                              >
                                {t("common.profileSettings")}
                              </Link>
                            </p>
                          </div>
                        )}
                        <div className="mainInfo">
                          <div className="picture" onClick={() => showImage()}>
                            <Avatar
                              size="md"
                              src={userDetails.pictureUrl}
                              role={userDetails.role}
                              alt="Your avatar"
                            />
                          </div>
                          <div className="info">
                            <p className="name">
                              {userDetails.displayname || userDetails.username}
                            </p>
                            <span className="text-capitalize role">
                              {t(`common.${userDetails.role}`)}
                            </span>
                            <Date>
                              {t("common.joined")}{" "}
                              {userDetails ? (
                                moment(userDetails?.createdAt)
                                  .locale(MOMENT_TO_I18N[userDetails.languages])
                                  .format("DD/MM/YYYY hh:mm")
                              ) : (
                                <Skeleton
                                  style={{ borderRadius: "10px" }}
                                  width={170}
                                  height={17}
                                />
                              )}
                            </Date>
                          </div>
                        </div>
                        {userDetails.role === ROLES.creator && (
                          <>
                            <div className="divider" />
                            <div className="public">
                              <p>{t("common.profileLink")}</p>
                              <div className="link">
                                <p className="text">{publicLink}</p>
                                <div className="icon" onClick={() => copyTextToClipboard()}>
                                  <Copy />
                                </div>
                              </div>
                            </div>
                            <div className="publicSmall">
                              <Link
                                to="/creator/editProfile/general"
                                relative="path"
                                onClick={() => closeDrawer && closeDrawer()}
                              >
                                <Profile />
                                <p>{t("common.editProfile")}</p>
                              </Link>
                              <div className="divider" />
                              <div className="link" onClick={() => copyTextToClipboard()}>
                                <Share />
                                <p>{t("common.shareProfile")}</p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </ProfileSection>
                  </BlockCard>
                </>
              )}
            </>
          )}
          <SidebarWrap sidebarData={!!sidebarData.length || userDetails !== null} drawer={drawer}>
            {sidebarData.map((item, index) => (
              <SubMenu
                className="side_bar_sub_menu"
                drawer={drawer}
                closeDrawer={closeDrawer}
                item={item}
                key={index}
                subTab={subTab}
                setSubTab={setSubTab}
                mainTab={mainTab}
                setMainTab={setMainTab}
                sidebarData={sidebarData}
              />
            ))}
          </SidebarWrap>
          <SidebarFooter />
        </SidebarNav>
      </IconContext.Provider>
    </SkeletonTheme>
  );
};

export default SideBarMenu;
