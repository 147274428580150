import styled from "styled-components";

import { deviceQuery } from "styles/mediaSizes";

export const ChangeAvatarContainer = styled.div`
  .card {
    flex-direction: row;
    display: flex;
  }

  .preview {
    display: flex;
    gap: 24px;
    flex-direction: column;
    flex: 1;

    & > .title {
      font-size: ${(p) => p.theme.typography.text3};
    }

    .infoContainer {
      flex: 1;
      color: ${(p) => p.theme.colors.text.primary};
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }

  .image {
    flex: 1;
    display: flex;
    justify-content: center;
    gap: 4px;
    padding-top: 24px;

    & > .title {
      font-size: ${(p) => p.theme.typography.text3};
      display: none;
    }

    .mockup {
      position: relative;

      & > img {
        margin-bottom: -16px;
        width: 240px;
      }

      .avatarContainer {
        position: absolute;
        left: 18px;
        top: 110px;
        z-index: 2;

        .avatar {
          width: 62px;
          height: 62px;
        }
      }

      .banner {
        position: absolute;
        left: 9px;
        top: 46px;
        width: calc(100% - 19px);
        height: 83px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .userInfo {
        position: absolute;
        left: 18px;
        top: 180px;
        display: flex;
        gap: 8px;
        flex-direction: column;
        background: ${(p) => p.theme.colors.background.secondary};
        box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
        z-index: 2;
        border-radius: 8px;
        padding: 8px;
        width: calc(100% - 36px);
        font-size: ${(p) => p.theme.typography.smallText3};
        color: ${(p) => p.theme.colors.text.primary};

        .active {
          font-size: ${(p) => p.theme.typography.smallText2};
          color: ${(p) => p.theme.colors.text.secondary};
        }
      }
    }
  }

  ${deviceQuery.mobileL} {
    .card {
      gap: 16px;
      flex-direction: column-reverse;
    }
    .image {
      padding-top: 0px;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      border-bottom: 1px solid ${(p) => p.theme.colors.divider};

      & > .title {
        display: block;
        width: 100%;
        text-align: start;
      }
      .mockup {
        width: 240px;

        & > img {
          margin-bottom: 0px;
        }
      }
    }
    .preview {
      & > .title {
        display: none;
      }
      .infoContainer {
        gap: 16px;
      }
    }
  }
`;
