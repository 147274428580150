import { FC } from "react";

interface Props {
  className?: string;
  size?: number;
}

const Lock: FC<Props> = ({ className, size = 32 }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      d="M16.5575 9.44828V7.29828C16.5575 6.08889 16.0771 4.92904 15.2219 4.07387C14.3667 3.21871 13.2069 2.73828 11.9975 2.73828C10.7881 2.73828 9.62826 3.21871 8.77309 4.07387C7.91793 4.92904 7.4375 6.08889 7.4375 7.29828V9.46828"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8206 21.2489H8.18063C7.17546 21.2489 6.21145 20.8496 5.50069 20.1388C4.78993 19.4281 4.39062 18.4641 4.39062 17.4589V13.1689C4.39062 12.1637 4.78993 11.1997 5.50069 10.489C6.21145 9.77821 7.17546 9.37891 8.18063 9.37891H15.8206C16.8258 9.37891 17.7898 9.77821 18.5006 10.489C19.2113 11.1997 19.6106 12.1637 19.6106 13.1689V17.4589C19.6106 18.4641 19.2113 19.4281 18.5006 20.1388C17.7898 20.8496 16.8258 21.2489 15.8206 21.2489V21.2489Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 14.1992V16.4192"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Lock;
